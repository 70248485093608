import a1statLogo from '@assets/images/a1statLogo.png';
import logo from '@assets/images/logo.svg';
import useStore from '@hooks/useStore';
import CloseIcon from '@mui/icons-material/Close';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {useMediaQuery, useTheme} from '@mui/material';
import {StudentSubroles} from '@src/types';
import {IconButton} from '@ui/MUI';
import DrawerNew from '@ui/MUI/DrawerNew';
import React, {useState} from 'react';

import MenuContent from './components/MenuContent';
import {DrawerStyleOverride, LogoImage, LogoImageWrapper, MenuContentHeader, Wrapper} from './Menu';

function Menu({active}: {active: string}) {
  const theme = useTheme();
  const isMdResolution = useMediaQuery(theme.breakpoints.down('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  return (
    <Wrapper>
      <LogoImageWrapper>
        <LogoImage className={isAmateur && 'Logo_amateur'} to="/">
          {!isAmateur ? <img alt="логотип авангард" src={logo} /> : <img alt="логотип a1stat" src={a1statLogo} />}
        </LogoImage>
      </LogoImageWrapper>

      {isMdResolution ? (
        <>
          <IconButton color="secondary" onClick={() => setIsMenuOpen(true)}>
            <MenuRoundedIcon color="inherit" />
          </IconButton>
          <DrawerStyleOverride />
          <DrawerNew
            contentWrapperProps={{sx: {p: '0'}}}
            hasButtons={false}
            hasTitle={false}
            open={isMenuOpen}
            width="320px !important"
            onClose={setIsMenuOpen}
          >
            <MenuContentHeader>
              <IconButton color="secondary" onClick={() => setIsMenuOpen(false)}>
                <CloseIcon color="inherit" />
              </IconButton>
            </MenuContentHeader>
            <MenuContent isMobile active={active} />
          </DrawerNew>
        </>
      ) : (
        <MenuContent active={active} />
      )}
    </Wrapper>
  );
}

export default React.memo(Menu);
