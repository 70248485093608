import {Close} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {Button, IconButton, TextField} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React from 'react';
import styled from 'styled-components';

import TrainingPlanLessonList from './TrainingPlanLessonList';

export default function TrainingPlan(props) {
  let {
    trainingPlan = {},
    index = 0,
    addNewTrainingPlan = () => {},
    deleteTrainingPlan = () => {},
    handleTrainingPlanChange = () => {},
    emptyLesson = {},
    formatDateTime,
    parseDateTime,
    showErrorMessage,
  } = props;

  const handleLessonsChange = (newLessonsList) => {
    handleTrainingPlanChange(index, 'tasks', newLessonsList);
  };

  return (
    <Plan>
      <PlanInfo>
        <IconButton
          className={'delete-training-plan'}
          onClick={() => {
            deleteTrainingPlan(index);
          }}
        >
          <Close color="primary" fontSize="small" />
        </IconButton>
        <PlanTitle>{`Задача №${index + 1}`}</PlanTitle>
        <PlanLabel>Название задачи</PlanLabel>
        <TextField
          InputProps={{sx: {maxWidth: 240}}}
          error={!trainingPlan.title?.trim() && showErrorMessage}
          helperText={!trainingPlan.title?.trim() && showErrorMessage && 'Обязательное поле для заполнения'}
          placeholder={'Введите название задачи'}
          value={trainingPlan.title}
          onChange={(a) => {
            handleTrainingPlanChange(index, 'title', a.target.value);
          }}
        />
        <TrainingPlanLessonList
          emptyLesson={emptyLesson}
          formatDateTime={formatDateTime}
          lessonsList={trainingPlan.tasks}
          parseDateTime={parseDateTime}
          onChange={handleLessonsChange}
        />

        <Button
          startIcon={<AddIcon />}
          sx={{mt: 2, borderColor: grey[8], color: grey[54]}}
          onClick={addNewTrainingPlan}
        >
          Еще одна задача
        </Button>
      </PlanInfo>
    </Plan>
  );
}
const Plan = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;
const PlanInfo = styled.div`
  min-width: 280px;
  max-width: 80%;

  @media (min-width: 481px) and (max-width: 1200px) {
    min-width: 300px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }

  .delete-training-plan {
    position: absolute;
    top: -12px;
    right: 18px;
    display: block;
    padding-right: 0;
    padding-left: 0;
    width: 17px;
    height: 30px;
    background-color: transparent;
    border-radius: 0;
    @media (min-width: 769px) and (max-width: 1200px) {
      right: 0;
    }
    @media screen and (max-width: 768px) {
      right: 0;
    }
  }
`;
const PlanTitle = styled.p`
  margin-top: 0;
  margin-bottom: 22px;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
const PlanLabel = styled.label`
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;

  & + .SelectContainer {
    margin-top: 9px;
    margin-bottom: 12px;
  }
`;
