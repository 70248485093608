import ReactTableComponent from '@common/ReactTableComponent';
import date from '@helpers/date';
import {grey} from '@ui/MUI/colors';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {playerHandFullNames, playerStatus} from '../../../../../constants';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import PlayersHorizontalCard from '../../../../students/tools/PlayersHorizontalCard';
import TableEmptyBlock from '../../../../ui/TableEmptyBlock';
import Table from '../table/index';

export default function PlayersComposition({group, loading}) {
  const players = group?.students;

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  const columns = [
    {
      id: 'player',
      accessorKey: 'player',
      header: 'Игрок',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cell: ({getValue}) => {
        const player = getValue();
        if (window.innerWidth <= 360) {
          player.firstName = player.firstName[0] + '.';
        }
        return (
          <Link className="TableColumnLink" to={`/student/${player.id}`}>
            <PlayersHorizontalCard student={player} />
          </Link>
        );
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      sortingFn: 'defaultSorting',
    },
    {
      accessorKey: 'birthdayTimestamp',
      header: 'Дата рождения',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => {
        return getValue() || 'Не указана';
      },
    },
    {
      accessorKey: 'status',
      header: 'Статус',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => {
        return getValue() || 'Не указан';
      },
    },
    {
      accessorKey: 'hand',
      header: 'Хват',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => {
        return getValue() || 'Не указан';
      },
    },
  ];

  const playersTable = (hockeyRole) => {
    return (
      <ReactTableComponent
        isAvailableHeight
        columns={columns}
        data={players
          ?.filter((student) => student.hockeyRole === hockeyRole)
          ?.map((student) => {
            return {
              id: student.id,
              player: student,
              birthdayTimestamp: date(student.birthdayTimestamp).format('DD.MM.YYYY'),
              status: playerStatus[student.status],
              hand: playerHandFullNames[student.hand],
            };
          })}
        setSorting={setSorting}
        sorting={sorting}
      />
    );
  };

  return (
    <Wrapper loading={loading}>
      <LocalPreloader visible={loading} />
      {players?.length > 0 ? (
        <>
          {players?.filter((student) => student.hockeyRole === 'winger').length > 0 && (
            <Table table={playersTable('winger')} title="Нападающие" />
          )}
          {players?.filter((student) => student.hockeyRole === 'defenseman').length > 0 && (
            <Table table={playersTable('defenseman')} title="Защитники" />
          )}
          {players?.filter((student) => student.hockeyRole === 'goaltender').length > 0 && (
            <Table table={playersTable('goaltender')} title="Вратари" />
          )}
          {players?.filter((student) => {
            return student.hockeyRole === '' || student.hockeyRole === null;
          }).length > 0 && <Table table={playersTable(null)} title="Без амплуа" />}
        </>
      ) : (
        <TableEmptyBlock text="К команде пока не привязано ни одного игрока." />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  padding: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
