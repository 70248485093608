import {SelectChangeEvent, Theme, useMediaQuery} from '@mui/material';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import {CustomTabsProps, TabPanelProps} from '@src/types/uikit/tabs';
import Box from '@ui/MUI/Box';
import {StyledSelect, Wrapper} from '@ui/MUI/TabsNew/Tabs';
import React, {useState} from 'react';

const TabPanel = React.memo(function TabPanel({children, value, index, ...props}: TabPanelProps) {
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...props}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Tabs({
  onChange,
  mobileViewBreakpoint = 'xxs',
  withoutWrapper,
  tabs = [{value: <></>, label: ''}],
  selectProps = {},
  hasMobileView,
  tabsWrapperProps = {},
  defaultSelectedTab = 0,
  withoutPx = false,
  ...props
}: CustomTabsProps) {
  const isMobile = useMediaQuery((theme: Theme) => (hasMobileView ? theme.breakpoints.down(mobileViewBreakpoint) : ''));
  const [value, setValue] = useState<number>(defaultSelectedTab);

  const handleChange = (event: React.SyntheticEvent<Element, Event> | SelectChangeEvent<unknown>, newValue: number) => {
    onChange ? onChange(newValue, tabs[newValue]) : setValue(newValue);
  };

  return (
    <Wrapper
      className="TabsWrapper"
      labelColor={props.labelColor || (!withoutWrapper && 'primary')}
      withoutWrapper={withoutWrapper}
    >
      <Box>
        <Box
          boxShadow={
            !isMobile && !withoutWrapper ? '0px 4px 4px rgba(0, 0, 0, 0.12), 0px -2px 12px rgba(0, 0, 0, 0.1)' : ''
          }
          pb={withoutWrapper ? 0 : 3}
          position={'relative'}
          px={!withoutWrapper ? {xxs: !withoutPx && 2, sm: !withoutPx && 4} : ''}
          zIndex="1"
          {...(typeof tabsWrapperProps === 'function' ? tabsWrapperProps?.(isMobile) : tabsWrapperProps)}
        >
          {isMobile && hasMobileView ? (
            <Box minWidth={275}>
              <StyledSelect
                withoutWrapper={withoutWrapper}
                {...selectProps}
                controlProps={{
                  sx: {
                    '.MuiInput-root::before, .MuiInput-root::after': {
                      content: 'none !important',
                    },
                  },
                }}
                options={tabs.map((item, index) => ({...item, value: index.toString()}))}
                value={(props.value || value).toString()}
                onChange={(e) => handleChange(e, Number(e.target.value))}
              />
            </Box>
          ) : (
            <MuiTabs
              indicatorColor="secondary"
              scrollButtons="auto"
              variant="scrollable"
              onChange={handleChange}
              {...props}
              value={props.value || value}
            >
              {tabs?.map((item, index) => {
                return <Tab key={index} label={item.label} {...a11yProps(index)} />;
              })}
            </MuiTabs>
          )}
        </Box>
        {tabs?.map((item, index) => {
          return (
            <TabPanel index={index} key={index} value={props.value || value}>
              {item?.value}
            </TabPanel>
          );
        })}
      </Box>
    </Wrapper>
  );
}

export default React.memo(Tabs);
