import {stageOptions, typeOfTestsOptions} from '@components/apps/PhysicalTesting/constants';
import {useSeasons} from '@hooks/seasonsHooks';
import {Autocomplete, Box, Chip, Stack} from '@ui/MUI';
import React from 'react';

const PlayerProgressFilters = ({isStudent, filtersState, setFiltersState}) => {
  const seasons = useSeasons(isStudent, ({currentSeason}) => {
    setFiltersState({season: [{value: currentSeason?.value, label: currentSeason?.label}]});
  });
  return (
    <Box sx={{p: 3, backgroundColor: 'white.main', borderRadius: '6px'}}>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Box maxWidth={{xxs: '100%', sm: 270}} width="100%">
          <Autocomplete
            isSelectableAll={true}
            label="Сезоны"
            limitTags={2}
            multiple={true}
            options={seasons?.options}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option?.label} size="small" variant="outlined" {...getTagProps({index})} key={index} />
              ))
            }
            value={filtersState?.season}
            onChange={(_, newValue) => {
              setFiltersState({...filtersState, season: newValue});
            }}
          />
        </Box>
        <Box maxWidth={{xxs: '100%', sm: 200}} width="100%">
          <Autocomplete
            isSelectableAll={true}
            label="Этапы"
            limitTags={2}
            multiple={true}
            options={stageOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option?.label} size="small" variant="outlined" {...getTagProps({index})} key={index} />
              ))
            }
            value={filtersState?.stage}
            onChange={(_, newValue) => {
              setFiltersState({...filtersState, stage: newValue});
            }}
          />
        </Box>
        <Box maxWidth={{xxs: '100%', sm: 200}} width="100%">
          <Autocomplete
            inputProps={{sx: {minHeight: '39px'}}}
            label="Вид тестов"
            multiple={false}
            options={typeOfTestsOptions}
            value={filtersState?.typeOfTests}
            onChange={(_, newValue) => {
              setFiltersState({...filtersState, typeOfTests: newValue});
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default PlayerProgressFilters;
