import MatchesFilter from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter';
import Wrapper from '@components/apps/StatisticsWithMkcUral/components/Wrapper';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import Classic from '@components/common/PlayersStatistics/components/Classiс';
import Corsi from '@components/common/PlayersStatistics/components/Corsi';
import Dekings from '@components/common/PlayersStatistics/components/Dekings';
import Fouls from '@components/common/PlayersStatistics/components/Fouls';
import Passes from '@components/common/PlayersStatistics/components/Passes';
import PowerStruggle from '@components/common/PlayersStatistics/components/PowerStruggle';
import PuckBattle from '@components/common/PlayersStatistics/components/PuckBattle';
import Shots from '@components/common/PlayersStatistics/components/Shots';
import Time from '@components/common/PlayersStatistics/components/Time';
import Filter from '@components/common/Statistics/Filter';
import useDeepMemo from '@hooks/useDeepMemo';
import useDefaultFilterValues from '@hooks/useDefaultFilterValues';
import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, STATISTICS_PLAYERS_TABS as TABS} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useMemo, useState} from 'react';

const tabsData = [
  {
    ...TABS.classic,
    Component: (props) => <Classic {...props} />,
  },
  {
    ...TABS.time,
    Component: (props) => <Time {...props} />,
  },
  {
    ...TABS.shots,
    Component: (props) => <Shots {...props} />,
  },
  {
    ...TABS.dekings,
    Component: (props) => <Dekings {...props} />,
  },
  {
    ...TABS.fouls,
    Component: (props) => <Fouls {...props} />,
  },
  {
    ...TABS.passes,
    Component: (props) => <Passes {...props} />,
  },
  {
    ...TABS.puckBattle,
    Component: (props) => <PuckBattle {...props} />,
  },
  {
    ...TABS.powerStruggle,
    Component: (props) => <PowerStruggle {...props} />,
  },
  {
    ...TABS.corsi,
    Component: (props) => <Corsi {...props} />,
    customFilters: (hasOT) => [
      {
        name: 'importance',
        options: [...IMPORTANCE_OPTIONS],
        multiple: false,
        label: 'Важность',
        isSelectableAll: false,
      },
      {
        name: 'half',
        options: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
        multiple: true,
        label: 'Период',
        isSelectableAll: true,
      },
    ],
  },
];
export default function PlayersStatistics({hasOT, weAtHome}) {
  const defaultFilterValues = useDefaultFilterValues(hasOT, weAtHome);
  const {statisticIds} = useMatches();
  const [selectedTab, setSelectedTab] = useState(0);

  const [matchFilter, setMatchFilter] = useState(defaultFilterValues);

  const customFilters = useMemo(() => tabsData?.[selectedTab]?.customFilters?.(hasOT), [hasOT, selectedTab]);
  const tabs = useDeepMemo(
    () =>
      tabsData.map(({Component, value, label}, index) => {
        return {
          label: label,
          value: (
            <Component
              filter={{statisticIds, ...matchFilter.forFetch}}
              key={index}
              matchFilter={{statisticIds, ...matchFilter}}
              tab={value}
            />
          ),
        };
      }),
    [statisticIds, matchFilter],
  );
  return (
    <>
      <MatchesFilter />
      <Wrapper mb={1}>
        <Filter {...{hasOT, weAtHome}} customFilters={customFilters} filter={matchFilter} setFilter={setMatchFilter} />
      </Wrapper>

      <Wrapper pl="0 !important" pr="0 !important" pt="0 !important">
        <TabsNew
          hasMobileView
          sx={{'.MuiTab-root': {color: 'rgba(0, 0, 0, 0.87) !important'}}}
          tabs={tabs}
          value={selectedTab}
          onChange={setSelectedTab}
        />
      </Wrapper>
    </>
  );
}
