import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsV2';
import {cloneDeep, sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import styled from 'styled-components';

import GoalVideoModal from '../GoalVideoModal';
import ProtocolItem from '../ProtocolItem';

Protocol.propTypes = {
  away: PropTypes.array,
  home: PropTypes.array,
};

function Protocol({away, home, linkToVideo = '', isV2 = false}) {
  const [isToggled, setIsToggled] = useState(false);
  const avalCheckedAway = away?.length ? away : [];
  const avalCheckedHome = home?.length ? home : [];
  const fullProtocol = [...avalCheckedAway, ...avalCheckedHome];
  const {protocol, allGoals} = useMemo(() => {
    const fullResult = [
      ...avalCheckedAway.map((item) => ({...item, isAway: true})),
      ...avalCheckedHome.map((item) => ({...item, isAway: false})),
    ]
      .sort((a, b) => a?.time - b?.time)
      .map((item, index) => ({...item, index}));
    let protocol = cloneDeep(fullResult);
    const finalGoalIndex = fullResult.findIndex((item) => item?.isFinalGoal);
    if (fullResult?.length > 5 && !isToggled) {
      protocol = finalGoalIndex <= 4 ? [...protocol.slice(0, 5)] : [...protocol.slice(0, 4), protocol[finalGoalIndex]];
      protocol[4].inFirstFiveGoals = fullResult.index;
    }
    return {protocol, allGoals: sortBy([...fullResult], 'time')};
  }, [away?.length, home?.length, isToggled]);

  return (
    <Stack
      alignItems="center"
      bgcolor={grey[4]}
      borderRadius="6px"
      direction="column"
      mt={4}
      px={{xxs: 3, xs: '0px'}}
      py={3}
      width="100%"
    >
      <Typography display="block" mb={3} variant={isV2 ? 'h3' : 'subtitle1'}>
        Протокол
      </Typography>
      <ProtocolWrapper cellPadding={0} toggled={(fullProtocol?.length < 5 || isToggled)?.toString()}>
        {(!away?.length || !home?.length) && (
          <NoGoals sx={{left: !home?.length ? '10%' : 'unset', right: !away?.length ? '10%' : 'unset'}} variant="body2">
            Нет голов
          </NoGoals>
        )}
        {protocol.map((item, index) => (
          <ProtocolItem
            {...item}
            awayLength={away?.length}
            currentIndex={index}
            homeLength={home?.length}
            isAway={item?.isAway}
            isToggled={isToggled || fullProtocol?.length <= 5}
            isV2={isV2}
            key={index}
            linkToVideo={linkToVideo}
          />
        ))}
      </ProtocolWrapper>

      {fullProtocol?.length > 5 && (
        <Button
          disableRipple
          endIcon={isToggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          size="small"
          sx={{
            '&:hover': {background: 'transparent', textDecoration: 'none'},
            textTransform: 'none',
            color: isV2 ? black[78] : grey[68],
            textDecoration: 'underline',
            fontWeight: isV2 ? 400 : 600,
          }}
          variant="text"
          onClick={() => setIsToggled((prevState) => !prevState)}
        >
          {isToggled ? 'Свернуть' : 'Развернуть'}
        </Button>
      )}
      <GoalVideoModal goals={allGoals?.length ? allGoals : []} />
    </Stack>
  );
}

export default Protocol;

const ProtocolWrapper = styled.table`
  position: relative;
  border-collapse: collapse;
  margin-bottom: ${({theme}) => theme.spacing(1)};
  ${({theme}) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
  .protocol-item {
    border-left: 2px solid ${grey['24']};
    ${({theme}) => theme.breakpoints.down('xs')} {
      border-left: 0;
    }
  }
  .protocol-item-wrapper {
    ${({theme}) => theme.breakpoints.down('xs')} {
      display: flex;
    }
    .protocol-item {
      position: relative;
      z-index: 1;
    }
    &:first-child .protocol-item {
      &::after {
        ${({theme}) => theme.breakpoints.up('xs')} {
          position: absolute;
          content: '';
          height: 50%;
          width: 2px;
          background-color: ${grey['4']};
          left: -2px;
          top: -1px;
          z-index: 22;
        }
      }
    }
    &:last-child .protocol-item {
      &::after {
        ${({theme}) => theme.breakpoints.up('xs')} {
          position: absolute;
          content: '';
          height: 50%;
          width: ${({toggled}) => (toggled === 'true' ? '2px' : '0')};
          background-color: ${grey['4']};
          left: -2px;
          bottom: -1px;
          z-index: 22;
        }
      }
    }
  }
`;

const NoGoals = styled(Typography)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
