import ReactTableComponent from '@common/ReactTableComponent';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import {useGroup} from '@components/modules/group/queries';
import {Group, Student} from '@components/modules/group/types/group.types';
import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Autocomplete, Avatar, Box, Button, IconButton, Stack, TextField, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {HOCKEY_ROLES} from '@src/constants';
import {ProspectUserDto} from '@src/types/generated';
import Drawer from '@ui/MUI/DrawerNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';

import {useCreateProspectMutation} from '../../mutation/create-prospect.mutation';
import {useProspectsQuery} from '../../queries/prospect.query';
import {useProspectGroup} from '../../queries/prospect-group.query';
import {prospectModal} from './view-modals/modal.view-modal';

const breadCrumbs = [
  {label: 'Главная', path: '/'},
  {label: 'Проспекты', path: '/prospects'},
];

export interface TableProps {
  teamId: string;
}

export interface HeaderProps {
  groupName: string;
}

const Header = ({groupName}: HeaderProps) => {
  return (
    <Box
      alignItems="center"
      boxShadow="0px 4px 8px 0px #00000026"
      display="flex"
      justifyContent="space-between"
      mb={2}
      p={4}
    >
      <Typography variant="h5">{groupName}</Typography>
      <Button color="primary" variant="contained" onClick={() => prospectModal.open()}>
        Добавить игрока
      </Button>
    </Box>
  );
};

const columns = [
  {
    accessorKey: 'id',
    header: 'Игрок',
    headerStyle: {width: '50%'},
    cellStyle: {width: '50%'},
    cell: ({row}) => {
      const student = row.original as Student;
      const fullName = `${student.firstName} ${student.lastName}`;
      const history = useHistory();
      const {teamId} = useParams<{teamId: string}>();

      const handleClick = () => {
        history.push(`/prospects/${teamId}/${student.id}`);
      };
      return (
        <Box alignItems="center" display="flex" sx={{cursor: 'pointer'}} onClick={handleClick}>
          <Avatar alt={fullName} src={student.avatar} sx={{mr: 2, width: 28, height: 28}} />
          <Typography sx={{color: '#97896B'}} variant="subtitle2">
            {student.playerNumber}
          </Typography>
          <Typography sx={{ml: 1}}>{fullName}</Typography>
        </Box>
      );
    },
  },
  {
    accessorKey: 'hockeyRole',
    header: 'Амплуа',
    headerStyle: {width: '50%'},
    cellStyle: {width: '50%'},
    cell: ({getValue}) => {
      const role = getValue();
      const text = HOCKEY_ROLES[role as keyof typeof HOCKEY_ROLES];
      return <Typography>{text}</Typography>;
    },
  },
];

const Table = ({teamId}: TableProps) => {
  const {data, isLoading} = useGroup(teamId);
  const prospectsQuery = useProspectsQuery(teamId, {enabled: !!teamId});
  // TODO: Fix me!
  const prospects = prospectsQuery.data ?? [];

  if (isLoading || prospectsQuery.isLoading) {
    return '...loading';
  }

  return (
    <Box borderRadius={1} sx={{background: 'white'}}>
      <Header groupName={data?.name} />
      <ReactTableComponent columns={columns} data={prospects} />
    </Box>
  );
};

const toOptions = (group: Group) => {
  return group.students.map((student) => {
    return {
      ...student,
      label: `${student.lastName} ${student.firstName}`,
      value: student.id,
    };
  });
};

interface FormProps {
  teamId: string;
}

interface FormValues {
  players: Student[];
}
const Form = ({teamId}: FormProps) => {
  useSignals();
  const modal = prospectModal;

  const {data} = useGroup(teamId);
  const {data: groups} = useProspectGroup();
  const prospects = (groups?.find((group) => group.id === teamId)?.prospects ?? []) as ProspectUserDto[];

  const options = data ? toOptions(data) : [];

  const {handleSubmit, control, watch, setValue, formState} = useForm<FormValues>({
    defaultValues: {
      players: [],
    },
  });

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'players',
  });

  const createInput = () => {
    append(null);
  };

  const players = watch('players');
  const isAddButtonDisabled = players.some((player) => player === null);
  const uniqOptions = options.filter((option) => {
    const isExist = prospects.some((prospect) => prospect.id === option.id);
    const isSelected = players.some((player) => player?.id === option.id);
    return !isSelected && !isExist;
  });

  const {mutate, isLoading: isCreating} = useCreateProspectMutation(() => {
    modal.close();
    setValue('players', []);
  });

  const createProspects = (data: FormValues) => {
    const ids = data.players.map((player) => player.id);
    mutate(ids);
  };

  return (
    <Drawer
      buttons={
        <Stack direction="row" justifyContent="space-between">
          <LoadingButton
            color="primary"
            loading={isCreating}
            variant="contained"
            disabled={!formState.isValid || !players.length}
            onClick={handleSubmit(createProspects)}
          >
            Сохранить
          </LoadingButton>
          <Button variant="contained" onClick={close}>
            Отменить
          </Button>
        </Stack>
      }
      open={modal.isOpen}
      title={`Добавление проспектов. ${data?.name}`}
      onClose={() => {
        modal.close();
        setValue('players', []);
      }}
    >
      <Stack mt={4} spacing={4}>
        {fields.map((field, index) => (
          <Box alignItems="center" display="flex" key={field.id}>
            <Controller
              control={control}
              name={`players.${index}`}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  getOptionLabel={(option) => option.label}
                  options={uniqOptions}
                  renderInput={(params) => (
                    <TextField {...params} label={`Игрок ${index + 1}`} size="small" variant="outlined" />
                  )}
                  onChange={(_, value) => field.onChange(value)}
                />
              )}
            />
            <IconButton sx={{ml: 2}} onClick={() => remove(index)}>
              <Close />
            </IconButton>
          </Box>
        ))}
        <Button disabled={isAddButtonDisabled || isCreating} onClick={createInput}>
          Добавить игрока
        </Button>
      </Stack>
    </Drawer>
  );
};

export const ProspectTeamPage = () => {
  const {teamId} = useParams<{teamId: string}>();
  const group = useGroup(teamId);
  const data = group.data;
  return (
    <ThemeLayout active="prospects">
      <Form teamId={teamId} />
      <PageTitleDefault
        breadCrumbs={[...breadCrumbs, {label: data?.name, path: null, isLoading: group.isLoading}]}
        isLoading={group.isLoading}
      >
        {data?.name}
      </PageTitleDefault>
      <Table teamId={teamId} />
    </ThemeLayout>
  );
};
