import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function createTournament(params) {
  return axios.post(`${API_ENDPOINT}/v1/tournament`, params, {
    success: {message: 'Турнир успешно сохранен'},
    failed: {message: 'Ошибка при сохранении турнира'},
  });
}

export function deleteTournament(id) {
  return axios.delete(`${API_ENDPOINT}/v1/tournament/${id}`, {
    success: {message: 'Турнир успешно удален'},
    failed: {message: 'Ошибка при удалении турнира'},
  });
}

export function getTournamentsList(params) {
  return axios.get(`${API_ENDPOINT}/v1/tournament`, {params});
}

export function getTournamentById(id) {
  return axios.get(`${API_ENDPOINT}/v1/tournament/${id}`, {failed: {message: 'Ошибка при загрузке турнира'}});
}

export function getTournamentsStats(params) {
  return axios.get(`${API_ENDPOINT}/v1/tournament/stats`, {params});
}

export function getGames(params) {
  return axios.get(`${API_ENDPOINT}/v1/tournament/games`, {params});
}

export function postGames(params) {
  return axios.post(`${API_ENDPOINT}/v1/tournament/games`, params, {
    success: {message: 'Игры успешно сохранены'},
    failed: {message: 'Ошибка при сохранении игр'},
  });
}

export function editTournament(id, params, isArchive = false) {
  return axios.put(`${API_ENDPOINT}/v1/tournament/${id}`, params, {
    success: {message: !isArchive ? 'Турнир успешно обновлен' : 'Турнир успешно удален'},
    failed: {message: !isArchive ? 'Ошибка при обновлении турнира' : 'Ошибка при удалении турнира'},
  });
}
