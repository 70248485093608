import PlayerNumberAvatar from '@components/modules/AmateurPlayerDashboard/components/PlayerNumberAvatar';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import {Theme, useMediaQuery} from '@mui/material';
import {HOCKEY_ROLES, longDash} from '@src/constants';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsV2';
import moment from 'moment/moment';
import React from 'react';

type MainInfoProps = {
  isLoading: boolean;
  //TODO добавить dto из generated/
  student: object;
};
function MainInfo({isLoading = true, student}: MainInfoProps) {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down(400));
  const hockeyRole = HOCKEY_ROLES?.[student?.hockeyRole];
  const inGameSince = useDeepMemo(() => {
    let year = null;
    const previousCommands = student?.previousCommands;
    const dateJoinedAcademy = student?.dateJoinedAcademy;
    if (previousCommands?.length) {
      year = previousCommands[0].startYear;
    } else {
      year = moment(dateJoinedAcademy).format('YYYY');
    }
    return year;
  }, [student]);

  return (
    <Stack alignItems="center" direction="row" gap={5}>
      {!isXs && <PlayerNumberAvatar isLoading={isLoading} student={student} />}
      <Box width="fill-available">
        <Stack alignItems="flex-start" direction="row" gap={3}>
          {isXs && <PlayerNumberAvatar isLoading={isLoading} student={student} />}
          <Box width="fill-available">
            <Typography
              useAvailableWidth
              isLoading={isLoading}
              mb={1}
              preloaderWidthBetween={[100, 120]}
              variant="h1"
            >{`${student?.lastName}`}</Typography>
            <Typography
              useAvailableWidth
              isLoading={isLoading}
              mb={1}
              preloaderWidthBetween={[100, 120]}
              variant="h1"
            >{`${student?.firstName}`}</Typography>
            {hockeyRole && (
              <Typography useAvailableWidth isLoading={isLoading} preloaderWidthBetween={[50, 90]} variant="body1">
                {hockeyRole}
              </Typography>
            )}
          </Box>
        </Stack>

        <Divider flexItem sx={{my: 3}} />
        <Stack direction="row" gap={1} mb={1}>
          <Typography color={black['66']} variant="body1">
            В игре с:
          </Typography>
          <Typography useAvailableWidth isLoading={isLoading} preloaderWidthBetween={[50, 90]} variant="body1">
            {inGameSince ? date(inGameSince).format('YYYY') + ' года' : longDash}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography color={black['66']} variant="body1">
            Команда:
          </Typography>
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 90]} variant="body1">
            {student?.team?.name || longDash}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
}

export default MainInfo;
