import ThemeLayout from '@components/modules/common/ThemeLayout';
import EastIcon from '@mui/icons-material/East';
import {Box, Button, Card, CardContent, Divider, Grid, Skeleton, Stack, Typography} from '@mui/material';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';
import {Link} from 'react-router-dom';

import {useProspectGroup} from '../../queries/prospect-group.query';

const breadCrumbs = [
  {label: 'Главная', path: '/'},
  {label: 'Проспекты', path: null},
];

const GroupCardSkeleton = () => {
  return (
    <Grid container spacing={3}>
      {[...Array(4)].map((_, index) => (
        <Grid item key={index} lg={3} md={4} sm={6} xs={12}>
          <Card style={{marginBottom: '20px'}}>
            <CardContent sx={{'&:last-child': {paddingBottom: 3}}}>
              <Skeleton variant="text" height={40} width="80%" />
              <Stack spacing={3} mt={3}>
                <Divider />
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Skeleton variant="text" width="40%" />
                    <Skeleton variant="text" width="20%" />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Skeleton variant="text" width="40%" />
                    <Skeleton variant="text" width="20%" />
                  </Stack>
                </Stack>
                <Divider />
              </Stack>
              <Box mt={1}>
                <Skeleton variant="rectangular" height={36} width="100%" />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const ProspectPage = () => {
  const {data: groups, isLoading} = useProspectGroup();

  return (
    <ThemeLayout active="prospects">
      <PageTitleDefault breadCrumbs={breadCrumbs}>Проспекты</PageTitleDefault>
      {isLoading && <GroupCardSkeleton />}
      <Grid container spacing={3}>
        {groups?.map((group) => (
          <Grid item key={group.id} lg={3} md={4} sm={6} xs={12}>
            <Card style={{marginBottom: '20px'}}>
              <CardContent sx={{'&:last-child': {paddingBottom: 3}}}>
                <Typography mb={3} variant="h3">
                  {group.name}
                </Typography>
                <Stack spacing={3}>
                  <Divider />
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body1">Нападающие:</Typography>
                      <Typography variant="subtitle1">{group.wingersCount}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body1">Защитники:</Typography>
                      <Typography variant="subtitle1">{group.defencemansCount}</Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                </Stack>
                <Box mt={1}>
                  <Button
                    fullWidth
                    color="primary"
                    component={Link}
                    endIcon={<EastIcon />}
                    to={`/prospects/${group.id}`}
                    variant="text"
                  >
                    Перейти к ИГРОКАМ
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </ThemeLayout>
  );
};
