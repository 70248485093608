import {Player} from '@components/apps/A1TagAddingRosters';
import {Signal} from '@preact/signals-react';
import {A1TagEventDto} from '@src/types/generated';
// TODO: Вынести в отдельный файл
export type Team = {
  color: string;
  name: string;
  players: {
    goalies: Player[];
    fieldPlayers: Player[];
  };
};

// TODO: Вынести в отдельный файл
export type Roasters = {
  homeTeam: Team;
  awayTeam: Team;
};

export enum EventType {
  Events = 'events',
  Additional = 'additional',
}

export interface Event extends A1TagEventDto {
  type: EventType;
}
export interface A1tagStatistic {
  id?: string;
  action_id: number;
  half: string;
  action_name: string;
  player_name?: string;
  player_id?: string | number;
  team_name?: string;
  opponent_name?: string;
  opponent_id?: string | number;
  opponent_team_name?: string;
  second_clear: number;
  zone_name?: string;
  pos_x?: number;
  pos_y?: number;
  plrs_team1: string;
  plrs_team2: string;
  goals_team1: number;
  goals_team2: number;
  jersey_player?: string;
  jersey_opponent?: string;
  video_time_start: string;
  video_time_end: string;
  time?: string;
  action_type?: string;
  assistants?: Array<Player & {value: string; label: string}>;
  another_player?: Player & {value: string; label: string};
}

export type Period = '1' | '2' | '3' | 'OT';

export type Lines = '5-5' | '4-4' | '3-3';

export interface A1tagViewModel {
  isTimerStart?: Signal<boolean>;
  isGodMode: Signal<boolean>;
  isGameStopped: Signal<boolean>;
  homeScore: Signal<number>;
  awayScore: Signal<number>;
  cleanTime: Signal<number>;
  period: Signal<Period>;
  lines: Signal<Lines>;
  rosters: Signal<Roasters | null>;
  statistics: Signal<A1tagStatistic[]>;
  events: Signal<Event[]>;
}

export interface A1TagChooseTeamModalViewModal {
  // fix me!
  payload: Signal<any>;
}

export interface A1TagModalViewModel {
  isOpen: Signal<boolean>;
  isEdit: Signal<boolean>;
  // fix me!
  type: Signal<string>;
  payload: Signal<any>;
}

export interface A1TagTableFilters {
  playerId?: number;
  eventId?: number;
  teamName?: string;
}
