// Function to convert pixel coordinates to Cartesian coordinates
export function convertToCartesian(
  realX: number,
  realY: number,
  width: number,
  height: number,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
): {cartesianX: number; cartesianY: number} {
  const cartesianX: number = minX + (realX / width) * (maxX - minX);
  const cartesianY: number = minY + ((height - realY) / height) * (maxY - minY);
  return {cartesianX, cartesianY};
}

// Function to convert Cartesian coordinates to pixel coordinates
export function convertToPixels(
  cartesianX: number,
  cartesianY: number,
  width: number,
  height: number,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
): {clickX: number; clickY: number} {
  // Reverse X conversion
  const clickX: number = ((cartesianX - minX) / (maxX - minX)) * width;

  // Reverse Y conversion (and invert it back)
  const clickY: number = ((cartesianY - minY) / (maxY - minY)) * height;

  return {clickX, clickY};
}

export function getCoordsFromEvent(
  e: React.MouseEvent<SVGSVGElement>,
  maxX: number,
  maxY: number,
  minX: number = 0,
  minY: number = 0,
): {
  realX: number;
  realY: number;
  cartesianX: number;
  cartesianY: number;
} {
  const rect = e.currentTarget.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  const width = rect.width;
  const height = rect.height;

  const {cartesianX, cartesianY} = convertToCartesian(x, y, width, height, minX, maxX, minY, maxY);
  const {clickX, clickY} = convertToPixels(cartesianX, cartesianY, width, height, minX, maxX, minY, maxY);

  return {realX: clickX, realY: clickY, cartesianX, cartesianY};
}
