export const mock = [...Array(5)].fill({
  _id: 'hQmrxYQoBJ',
  gameStudentsIds: [],
  type: 'game',
  description: '',
  fullName: 'Игра команды (домашняя)',
  groupId: 'GKJnMGoC1iho',
  lockerRoom: '101',
  name: 'Игра команды (домашняя)',
  playerRole: null,
  schoolId: 'tGFSkv5TuJ',
  opponent: 'G-Drive',
  startTimestamp: 1712474100000,
  streamingURL: '',
  control: false,
  isRepeat: false,
  tournamentId: 'dIYvsyIYBZD7',
  statisticId: '4be1ddd7-f942-4edc-a3c2-e77fe6ddd7f2',
  statisticsIsReady: true,
  _created_at: '2024-04-07T07:08:07.300Z',
  _updated_at: '2024-04-07T13:47:01.165Z',
  group: {
    _id: 'GKJnMGoC1iho',
    active: true,
    birthYear: '2000',
    schoolId: 'tGFSkv5TuJ',
    description: '',
    name: 'ГАЗПРОМ НЕФТЬ',
    avangardId: 82736937,
    franchiseeId: 'H6KiRBmxLL',
    _created_at: '2024-02-26T11:09:51.514Z',
    _updated_at: '2024-04-07T07:03:37.308Z',
    version: 0,
  },
  isCompletedEstimating: false,
  statusEstimateGame: 'new',
});
