import './style.scss';

import {errorMessageNotification} from '@helpers/CommonHelper';
import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {ROOMS} from '../../constants';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as lessonsActions from '../../redux/actions/LessonsActions';
import * as roomsActions from '../../redux/actions/RoomsActions';
import * as usersActions from '../../redux/actions/UsersActions';
import LocalPreloader from '../preloader/LocalPreloader';
import {ReactComponentNotification} from '../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import CalendarFilter from './components/filter';
import FullCalendar from './components/fullcalendar';
import Sidebar from './components/sidebar';
import {EVENT_GROUP_TYPES_COLOR, filterUsersConfig, ymEvenTypes} from './constants';
import {parseLessonForCreate, parseLessonForUpdate} from './helper';

const Calendar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(roomsActions.loadAllDressingRooms());
    return () => {
      dispatch(lessonsActions.cleanCalendarLessons());
    };
  }, []);

  useEffect(() => {
    dispatch(usersActions.loadAllAmplua());
  }, []);

  const {currentUser, loading, allAmplua, schools, lockerRooms, userRole, childrens} = useSelector((state) => {
    const childrens = state.users.usersMap
      .toArray()
      .filter((el) => state.users?.currentUser?.childrenIds?.includes(el.id));
    return {
      currentUser: state.users.currentUser,
      loading: state.rooms.loading,
      allAmplua: state.users.amplua,
      schools: state.schools.schoolsMap.toArray(),
      lockerRooms: state.rooms.dressingRooms || [],
      childrens,
    };
  }, shallowEqual);

  const formattedAllAmplua = allAmplua?.map((amplua) => {
    return {
      value: amplua.id,
      label: amplua.label,
    };
  });

  //ToDo убрать костыль (перенести сайдбар в компонент, где лежит ref от календаря или использовать forwardRef и передавать пропсом)
  //Костыль для обновления календаря из внешнего компонента
  const [count, setCount] = useState(0);

  const refetchCalendarLessons = () => {
    setCount((prev) => ++prev);
  };

  const [filterState, setFilterState] = useState({
    isLoading: false,
  });

  const [studentsIds, setStudentsIds] = useState([]);

  const [sidebarState, setSidebarState] = useState({
    visible: {top: false, right: false, bottom: false, left: false},
    isAdd: true,
    selectedLesson: null,
    defaultValue: null,
  });

  const setLoadingState = (isLoading) => {
    setFilterState((prev) => ({...prev, isLoading: isLoading}));
  };

  const handleUpdateLesson = (data) => {
    const parsedObject = parseLessonForUpdate({
      ...data,
      school: data.school,
      id: sidebarState.selectedLesson.id,
    });
    window.ym(80266396, 'reachGoal', 'editing_event');
    return dispatch(lessonsActions.updateLesson(parsedObject)).then((pld) => {
      if (pld?.error) {
        errorMessageNotification(pld.error.message);
      } else {
        setSidebarState({
          visible: {...sidebarState.visible, right: false},
          isAdd: true,
          selectedLesson: null,
          defaultValue: null,
        });
        refetchCalendarLessons();
        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Мероприятие изменено`);
      }
    });
  };

  const handleDeleteLessonFrom = (selectedLessonId) => {
    try {
      return dispatch(lessonsActions.deleteLesson(selectedLessonId)).then((pld) => {
        if (pld.error !== undefined) {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], `${JSON.stringify(pld.error)}`);
        } else {
          setSidebarState({
            visible: {...sidebarState.visible, right: false},
            isAdd: true,
            selectedLesson: null,
            defaultValue: null,
          });
          refetchCalendarLessons();
          ReactComponentNotification(NOTIFICATION_TYPES['success'], `Мероприятие удалено`);
        }
      });
    } catch (error) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], `Произошла ошибка, попробуйте позже`);
    }
  };

  const addEventMetrik = (eventType) => {
    window.ym(80266396, 'reachGoal', ymEvenTypes?.[eventType] || 'Add_technical');
  };

  const createLesson = (data) => {
    const parsedObject = parseLessonForCreate({
      ...data,
      school: data.school,
    });
    addEventMetrik(parsedObject.type);
    return dispatch(lessonsActions.createLesson(parsedObject)).then((pld) => {
      if (pld?.error) {
        errorMessageNotification(pld.error.message);
      } else {
        setSidebarVisible({right: false});
        refetchCalendarLessons();
        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Мероприятие добавлено`);
      }
    });
  };

  const onCalendarEventClick = (lesson) => {
    setSidebarState({
      visible: {...sidebarState.visible, right: true},
      isAdd: false,
      selectedLesson: lesson,
    });
  };

  const createEvent = (data) => {
    if (currentUser.userRole !== 'student') {
      setSidebarState({
        visible: {...sidebarState.visible, right: true},
        defaultValue: data ? {startTime: data.start, endTime: data.end} : null,
        isAdd: true,
      });
    }
  };

  const setSidebarVisible = (visibleState) => {
    setSidebarState((prev) => ({
      ...prev,
      visible: visibleState,
    }));
  };

  const sortedChildrens = useMemo(() => {
    if (childrens) {
      return childrens.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }
  }, [childrens]);

  return (
    <Wrapper className={currentUser?.userRole !== 'student' ? 'mainCalendar' : 'mainCalendar mainCalendar_student'}>
      <CalendarFilter
        childrens={sortedChildrens}
        currentUser={currentUser}
        rooms={ROOMS}
        schools={schools}
        setLoadingState={setLoadingState}
        setStudentsIds={(studentsIds) => setStudentsIds(studentsIds)}
        onChange={setFilterState}
      />

      <div className="mainCalendar__preloaderWrapper">
        <>
          <LocalPreloader visible={filterState?.isLoading || loading} />
          <FullCalendar
            count={count} //Костыль для обновления календаря из внешнего компонента
            createEvent={createEvent}
            currentUser={currentUser}
            filter={filterState}
            hasFilter={filterUsersConfig[currentUser.userRole]?.hasFilter}
            isParentLoading={filterState.isLoading || loading}
            studentsIds={studentsIds}
            userRole={userRole}
            onCalendarEventClick={onCalendarEventClick}
          />

          <div className="mainCalendar__footer">
            {EVENT_GROUP_TYPES_COLOR.map((eventKind) => {
              return (
                <p className="mainCalendar__footer-item" key={eventKind?.id}>
                  <span className="mainCalendar__footer-item-indicator" style={{backgroundColor: eventKind?.color}} />
                  {eventKind?.name}
                </p>
              );
            })}
          </div>
        </>
      </div>

      <Sidebar
        allAmplua={formattedAllAmplua}
        handleDeleteLessonFrom={handleDeleteLessonFrom}
        lockerRooms={lockerRooms}
        schools={schools}
        setSidebarVisible={setSidebarVisible}
        sidebarVisible={sidebarState.visible}
        updateLesson={handleUpdateLesson}
        onFormSubmit={(formData) => {
          return createLesson(formData);
        }}
        {...sidebarState}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default Calendar;
