import {Stack, Typography} from '@ui/MUI';
import React from 'react';

export const NoData = () => {
  return (
    <Stack
      alignItems="center"
      borderRadius={2}
      height="100%"
      justifyContent="center"
      minHeight={200}
      sx={{background: 'white'}}
    >
      <Typography>Нет данных</Typography>
    </Stack>
  );
};
