import IconButton from '@mui/material/IconButton';
import {Button} from '@ui/MUI';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import ChevronLeftArrow from '../../../../assets/images/left_arrow_mui.svg';
import ChevronRightArrow from '../../../../assets/images/right_arrow_mui.svg';
import Grid from '../../../Grid/Grid';

export default function DashboardsBlock(props) {
  const {
    title = React.ReactNode,
    titleSpan,
    content,
    isButtonsShowed = true,
    buttonsBlockType = 'link',
    link = '',
    linkText = '',
    onButtonClick = () => {},
    buttonText = '',
    isWithoutButtons = false,
    className,
    rows,
    withLargeBorder,
    withLargePadding,
    withLargeBorderBottom,
    openDatePicker,
    setNextDay,
    setPrevDay,
    datePicker,
    marginBottom,
  } = props;

  return (
    <Wrapper className={className} marginBottom={marginBottom}>
      {title && !datePicker && (
        <Headline className="Headline" withLargePadding={withLargePadding}>
          <HeadlineTitle>
            {title}
            {titleSpan && <span>{titleSpan}</span>}
          </HeadlineTitle>
        </Headline>
      )}
      {title && datePicker && (
        <Headline className="Headline" withLargePadding={withLargePadding}>
          <HeadlineTitle>
            {title}
            <DateContainer style={{display: 'flex', alignItems: 'center'}}>
              <IconButton sx={{padding: '4px'}} onClick={() => setPrevDay()}>
                <img alt="fireSpot" src={ChevronLeftArrow} />
              </IconButton>
              {titleSpan && <span onClick={openDatePicker ? () => openDatePicker() : null}>{titleSpan}</span>}
              <IconButton sx={{padding: '4px'}} onClick={() => setNextDay()}>
                <img alt="fireSpot" src={ChevronRightArrow} />
              </IconButton>
            </DateContainer>
          </HeadlineTitle>
        </Headline>
      )}
      {rows && (
        <Rows>
          {rows.map((row) => {
            return (
              <Grid align={row.align} flexSize={row.flexSize} justify={row.justify} key={row.key}>
                {row.label}
              </Grid>
            );
          })}
        </Rows>
      )}
      <Content
        className="dashboard-content"
        withLargeBorder={withLargeBorder}
        withLargeBorderBottom={withLargeBorderBottom}
      >
        {content}
      </Content>
      {!isWithoutButtons && (
        <ButtonsBlock className="DashboardButtonsBlock" withLargePadding={withLargePadding}>
          <ButtonsBlockContainer className={isButtonsShowed ? 'ButtonsBlockContainer show' : 'ButtonsBlockContainer'}>
            {buttonsBlockType === 'link' ? (
              <Link to={link}>{linkText}</Link>
            ) : (
              <Button color="primary" sx={{px: 0, textTransform: 'none'}} variant="text" onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}
          </ButtonsBlockContainer>
        </ButtonsBlock>
      )}
    </Wrapper>
  );
}

const Rows = styled.div`
  display: flex;
  padding: 4px 24px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  font-size: 14px;
  color: #747474;
  font-weight: normal;
`;

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};

  @media screen and (max-width: 1440px) {
    margin-bottom: 0;
  }
`;

const Headline = styled.div`
  padding: 12px 24px;
  background-color: #fffaed;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
  padding-left: ${(props) => props.withLargePadding && '34px'};
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;

const HeadlineTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 20px/1 'Bebas Neue';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @media screen and (max-width: 360px) {
    font-size: 18px;
  }

  > span {
    font-weight: 600;
    font-size: 16px;
    font-family: 'Proxima Nova', serif;
    @media screen and (max-width: 360px) {
      font-size: 14px;
    }
  }
`;

const Content = styled.div`
  background-color: white;
`;

const ButtonsBlock = styled.div`
  padding: 12px 24px;
  padding-left: ${(props) => props.withLargePadding && '34px'};
  background-color: white;
  border-radius: 0 0 6px 6px;
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;

const ButtonsBlockContainer = styled.div`
  display: none;

  &.show {
    display: block;
  }

  > a {
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font: 600 normal 14px/16px 'Proxima Nova';
    color: #990011;
    text-decoration: none;
    transition: text-decoration 0.33s;
    cursor: pointer;

    &:focus {
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const DateContainer = styled.div`
  cursor: pointer;
`;
