import ThemeLayout from '@components/modules/common/ThemeLayout';
import {Stack} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';

import {SectionsTabs} from './components/sections-tabs.component';
import {UserDrawer} from './components/user-drawer.component';

export const AccessesMainPage = () => {
  return (
    <>
      <UserDrawer />
      <ThemeLayout active="accesses">
        <PageTitleDefault
          breadCrumbs={[
            {label: 'Главная', path: '/'},
            {label: 'Права доступа', path: null},
          ]}
        >
          Права доступа
        </PageTitleDefault>
        <Stack>
          <SectionsTabs />
        </Stack>
      </ThemeLayout>
    </>
  );
};
