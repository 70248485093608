import GameField from '@components/apps/StatisticsWithMkcUral/components/StatisticsCard/GameField';
import {DIAGRAM_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import {MiniDiagram} from '@components/apps/StatisticsWithMkcUral/UralStatistics';
import EastIcon from '@mui/icons-material/East';
import {Theme} from '@mui/material';
import {longDash} from '@src/constants';
import {StatisticMKCMainPageTeamGameItemDto, TournamentGameDto} from '@src/types/generated';
import {Box, Button, Skeleton} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Divider from '@ui/MUI/Divider';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import Wrapper from '../Wrapper';

type Opponent = {
  date: string;
  teamName: string;
  home: string | number;
  away: string | number;
};

type StatisticsCardProps = {
  teamMKCId?: string;
  tournamentId?: string;
  tournamentName?: string;
  isLoading: boolean;
  teamName: string;
  description: string;
  place: string | number;
  score: string;
  topThree: string;
  opponents: Array<Opponent>;
  isFinal: boolean;
  isV2?: boolean;
  notMkcTournament?: boolean;
  season?: string;
  games?: Array<TournamentGameDto & StatisticMKCMainPageTeamGameItemDto>;
  rank?: string | number;
  points?: string | number;
  potentialPoints?: string | number;
  againstTopThree?: string | number;
  groupName?: string;
};

function StatisticsCard({
  teamMKCId,
  teamName,
  tournamentName,
  rank,
  points,
  potentialPoints,
  againstTopThree,
  games,
  isLoading,
  isFinal,
  season,
  notMkcTournament,
  tournamentId,
  groupName,
  isV2 = false,
}: StatisticsCardProps) {
  const metrics = useMemo(
    () => [
      {
        label: 'Место: ',
        value: rank || longDash,
      },
      {
        label: 'Очки: ',
        value: points || potentialPoints ? `${points || 0} (${potentialPoints || 0}%)` : longDash,
      },
      {
        label: 'Против топ-3: ',
        value: againstTopThree ? againstTopThree + '%' : longDash,
      },
    ],
    [rank, points, potentialPoints, againstTopThree],
  );

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      maxWidth={(theme: Theme) => ({
        xxs: '100%',
        xs: `calc(50% - ${theme.spacing(2)} / 2)`,
        sm: `calc(50% - ${theme.spacing(3)} / 2)`,
        smallLaptop: `calc(100% / 3 - ${theme.spacing(3)} * 2 / 3)`,
        xl: `calc(100% / 4 - ${theme.spacing(3)} * 3 / 4)`,
      })}
      width="-webkit-fill-available"
    >
      <Typography isLoading={isLoading} preloaderProps={{width: '50%'}} variant="subtitle2">
        {teamName}
      </Typography>
      <Typography isLoading={isLoading} variant={isV2 ? 'subtitle1' : 'caption1'}>
        {tournamentName}
      </Typography>
      <Divider
        sx={{my: {xxs: 2, sm: 3}, mx: {xxs: isV2 ? '0px' : -2, sm: isV2 ? '0px' : -3}, backgroundColor: grey[24]}}
      />
      {!notMkcTournament && (
        <>
          <Stack direction="row" flexWrap="wrap" gap={{xxs: 0, md: 2}}>
            {metrics.map(({label, value}, index) => (
              <Stack alignItems="center" direction="row" gap={0} key={index}>
                <Typography variant="body2">{label}</Typography>
                <Typography
                  component="span"
                  isLoading={isLoading}
                  maxWidth="max-content"
                  preloaderProps={{width: '30px'}}
                  variant="subtitle2"
                >
                  {value}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Divider sx={{my: 1, mx: {xxs: -2, sm: '0'}, backgroundColor: grey[24]}} />
        </>
      )}
      <Box sx={{overflowY: 'auto', maxHeight: notMkcTournament && 150, mb: 'auto'}}>
        <Box display="table" my="auto" width="100%">
          {games?.length ? (
            games?.map((item, index, arr) => {
              const opponents = notMkcTournament
                ? [item?.team1?.name, item?.team2?.name].join(` ${longDash} `)
                : item?.opponent;
              const score = [item?.homeScore || item?.score1 || 0, item?.awayScore || item?.score2 || 0];
              return isLoading ? (
                <Skeleton key={index} />
              ) : (
                <GameField
                  diagram={
                    notMkcTournament ? null : (
                      <MiniDiagram mr="auto !important" type={DIAGRAM_TYPES?.[item?.resultDiagram]} />
                    )
                  }
                  gameDate={item?.date}
                  isFinal={typeof isFinal === 'boolean' ? isFinal : ''}
                  isLastItem={index === arr.length - 1}
                  isV2={isV2}
                  key={index}
                  opponents={opponents}
                  score={score}
                  season={season}
                  statisticId={item?.statisticId}
                  teamMKCId={teamMKCId}
                />
              );
            })
          ) : (
            <Stack alignItems="center" height={100} justifyContent="center">
              <Typography variant="body2">Первенство еще не стартовало.</Typography>
            </Stack>
          )}
        </Box>
      </Box>
      <Divider
        sx={{my: {xxs: 2, sm: 3}, mx: {xxs: isV2 ? '0px' : -2, sm: isV2 ? '0px' : -3}, backgroundColor: grey[24]}}
      />
      <Button
        color="primary"
        component={Link}
        disabled={(notMkcTournament ? !tournamentId : !teamMKCId) || isLoading}
        endIcon={<EastIcon />}
        sx={{mr: 'auto', px: 0}}
        to={
          notMkcTournament
            ? `/playoff/${tournamentId}`
            : `/statistics/${teamMKCId}?isFinal=${isFinal}&season=${season}&groupName=${encodeURIComponent(groupName)}`
        }
        variant="text"
      >
        к расширенной статистике
      </Button>
    </Wrapper>
  );
}

export default StatisticsCard;
