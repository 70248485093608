import {createProspectGoals, getProspectGoals, updateProspectGoal} from '@api/prospect.api';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export const useProspectGoals = (
  prospectId: string,
  query: {
    date?: string;
    finalDate?: string;
    isCompleted?: boolean;
    seasonId?: string;
  },
) => {
  return useQuery({
    queryKey: ['prospect', 'goals', prospectId, query],
    queryFn: () => getProspectGoals(prospectId, query),
  });
};

export const useSaveProspectGoals = (prospectId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any[]) => {
      return createProspectGoals(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'goals', prospectId]});
    },
  });
};

export const useUpdateProspectGoals = (prospectId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return updateProspectGoal(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'goals', prospectId]});
    },
  });
};
