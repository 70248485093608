import {Cell} from '@common/ScoutReport/CommonScoutReport';
import {Tooltip} from '@mui/material';
import {longDash} from '@src/constants';
import PropTypes from 'prop-types';
import React from 'react';

function PlayersTableTooltip({associations = [], data = {}}) {
  return data?.length ? (
    <Tooltip
      arrow
      title={data?.map((item, index) => (
        <div key={index}>{associations?.[item] || longDash}</div>
      ))}
    >
      <Cell>{data?.join(', ')}</Cell>
    </Tooltip>
  ) : (
    '\u2013'
  );
}

PlayersTableTooltip.propTypes = {
  associations: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default PlayersTableTooltip;
