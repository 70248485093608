import ReactTableComponent from '@common/ReactTableComponent';
import React from 'react';
import styled from 'styled-components';

import {columns} from './constants';

const StyledReactTable = styled(ReactTableComponent)`
  border: 1px solid #e0e0e0;
`;

const Table = ({estimates}) => {
  return (
    <StyledReactTable
      isAvailableHeight
      columns={columns}
      customScrollBarHeight={estimates?.length * 70}
      data={estimates}
    />
  );
};

export default Table;
