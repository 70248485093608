import {CellType} from '@common/ReactTableComponent/index';
import {TableCell, TableHead, TableRow, Theme} from '@mui/material';
import styled from 'styled-components';

export const StyledTableRow = styled(TableRow)``;

export const StyledTableCell = styled(TableCell)<{theme: Theme; isWithoutWhiteSpace: boolean} & CellType>`
  background: ${(props) => props?.cellbackground};
  overflow: hidden;
  white-space: ${({isWithoutWhiteSpace}) => (isWithoutWhiteSpace ? 'normal' : 'nowrap')};
  text-overflow: ${({isWithOutOverflow}) => !isWithOutOverflow && 'ellipsis'};
  &.sticky-right {
    position: sticky !important;
    right: 0;
    background-color: white;
    z-index: 2;
    border-collapse: collapse;
    overflow: unset;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.sticky-left {
    position: sticky !important;
    left: 0;
    background-color: white;
    z-index: 2;
    border-collapse: collapse;
    overflow: unset;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
  ${({theme}) => theme.typography.body2}
`;

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  ${StyledTableCell} {
    ${({theme}) => theme.typography.subtitle2}
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  background-color: white;
  overflow: auto;
  border-radius: 6px;

  .ScrollbarsCustom {
    &-TrackY {
      top: 2px !important;
      right: 2px !important;
      width: 8px !important;
    }
    &-TrackX {
      bottom: 2px !important;
      height: 8px !important;
    }
    &-Scroller {
      ${({theme}) => theme.breakpoints.up('md')} {
        padding-right: 0 !important;
      }
      @media screen and (max-width: 480px) {
        margin-right: -4px !important;
      }
    }
  }
`;
