import FunctionalTestingAPI from '@api/FunctionalTestingAPI';
import UsersAPI from '@api/UsersAPI';
import DeleteModal from '@common/DeleteModal';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {
  ButtonsWrapper,
  PlayerCellContainer,
  Wrapper,
} from '@components/apps/FunctionalTesting/components/CreateEditReport/EditCreateTable/EditCreateTable';
import {
  formatStatesOptions,
  formatTableDataForSubmitStagedTestingForm,
} from '@components/apps/FunctionalTesting/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {errorMessageNotification} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {selectStagedTests, selectTableEditCreate} from '@selectors/functionalTesting';
import {editStagedTestingReport, fetchStates, saveStagedTestingReport} from '@slices/functionalTesting';
import {Box, DatePicker} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import ButtonDefault from '@ui/MUI/Button';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import Typography from '@ui/MUI/Typography';
import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';

import {TableContainer} from './EditCreateStagedTestingTable';

const StagedTestingTableEditCreate = ({isEdit = false}) => {
  const {teamId, reportDate, typeAction} = useParams();

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);

  const [dateOfTesting, setDateOfTesting] = useState(reportDate);

  const [data, setData] = useState();

  const [wasEdit, setWasEdit] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [bottomBlockRef, {height}] = useElementSize();

  const {handleSubmit, ...form} = useForm();

  const history = useHistory();
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const {states} = useStore(selectTableEditCreate);
  const functionalStateOptions = states && formatStatesOptions(states.functional);

  useEffect(() => {
    if (!isEdit && teamId) {
      UsersAPI.getStudentsV2({
        active: true,
        groupId: teamId,
        page: 1,
        limit: 999,
      }).then((players) => {
        setData(
          players?.result?.map((player) => {
            return {
              player: {
                id: player.id,
                firstName: player.firstName,
                lastName: player.lastName,
                playerNumber: player.number,
                avatar: player.avatar,
              },
              functional: 0,
            };
          }),
        );
      });
    }
  }, [isEdit, teamId]);

  const {items} = useStore(selectStagedTests);

  useEffect(() => {
    if (isEdit && items?.length > 0) {
      setData(
        items?.map((item) => {
          return {
            id: item.id,
            player: {
              id: item.playerId,
              firstName: item.firstName,
              lastName: item.lastName,
              playerNumber: item.playerNumber,
              avatar: item.avatar,
            },
            functional: item?.functional || 0,
          };
        }),
      );
    }
  }, [isEdit, items?.length]);

  const EditableCell = ({getValue, table, row: {index}, column: {id}}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    const onChange = (_, newValue) => {
      setWasEdit(true);
      const value = !isEdit ? newValue : newValue?.value;
      setValue(value);
      table.options.meta.updateData(index, id, value);
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (id !== 'player') {
      const valueForEditingMode =
        functionalStateOptions?.find((option) => {
          return Number(option?.value) === Number(value);
        }) || null;
      const defaultValue =
        functionalStateOptions?.find((option) => Number(option?.value) === Number(value?.value)) || null;
      return (
        <Controller
          name={id}
          render={() => {
            return (
              <AutocompleteDefault
                multiple={false}
                options={functionalStateOptions}
                placeholder="Выберите"
                value={isEdit ? valueForEditingMode : defaultValue}
                onChange={onChange}
              />
            );
          }}
        />
      );
    } else {
      return (
        <PlayerCellContainer isToggled={isToggled}>
          <PlayersHorizontalCard
            hasImage={!isToggled}
            isShortName={isSmResolution}
            onlyNumber={isToggled}
            student={value}
          />
        </PlayerCellContainer>
      );
    }
  };

  const defaultColumn = {
    cell: EditableCell,
  };

  const columns = [
    {
      accessorKey: 'player',
      header: (
        <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
          Игрок
        </ToggledHeader>
      ),
      headerStyle: {
        '.MuiBox-root': {
          display: 'flex',
          boxShadow: 'none',
          padding: '0px',
        },
      },
      sortingFn: 'defaultSorting',
      sticky: {left: !isSmResolution},
    },
    {
      accessorKey: 'functional',
      header: 'Функциональное состояние',
      sortable: false,
    },
  ];

  const submitForm = () => {
    if (!dateOfTesting) {
      const title = null;
      errorMessageNotification('Для сохранения отчета, заполните дату контроля', title);
      return false;
    }
    const isDataEmpty = !data.filter((item) => {
      const isAllEmpty = !item.functional;
      if (!isAllEmpty) {
        return item;
      }
    }).length;
    if (isDataEmpty) {
      const title = null;
      errorMessageNotification('Для сохранения отчета, внесите данные в таблицу', title);
      return false;
    }
    if (!isEdit) {
      dispatch(
        saveStagedTestingReport(
          formatTableDataForSubmitStagedTestingForm({
            tableData: data.map((test) => ({...test, groupId: teamId})),
            date: reportDate,
          }),
        ),
      ).then(() => {
        history.push(`/functional_testing/${teamId}`);
      });
    } else {
      dispatch(
        editStagedTestingReport(
          formatTableDataForSubmitStagedTestingForm({
            tableData: data.map((test) => ({...test, groupId: teamId})),
            date: reportDate,
          }),
        ),
      ).then(() => {
        history.push(`/functional_testing/${teamId}`);
      });
    }
  };

  const deleteStagedTestingReport = (teamId, dateOfTesting) => {
    FunctionalTestingAPI.deleteStagedTesting(teamId, dateOfTesting);
  };

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Wrapper>
            <Box sx={{maxWidth: 200}}>
              <DatePicker
                label="Дата контроля"
                value={dateOfTesting}
                onChange={(newValue) => {
                  setDateOfTesting(date(newValue).format('YYYY-MM-DD'));
                  history.push(
                    `/functional_testing_create/staged_testing/${teamId}/${typeAction}/${date(newValue).format(
                      'YYYY-MM-DD',
                    )}`,
                  );
                }}
              />
            </Box>
          </Wrapper>
          {data?.length > 0 && (
            <TableContainer>
              <ReactTableComponent
                bottomBlockHeight={height + 16}
                columns={columns}
                data={data}
                defaultColumn={defaultColumn}
                isAvailableHeight={true}
                setSorting={setSorting}
                sorting={sorting}
                tableBodyCellProps={{
                  sx: {
                    minWidth: 200,
                    width: '100%',
                    '&:first-child.sticky-left': {
                      width: '300px',
                    },
                    '@media screen and (max-width: 767px)': {
                      minWidth: 'initial',
                      width: !isToggled ? '200px !important' : '56px !important',
                      maxWidth: !isToggled ? 200 : 56,
                      fontSize: '12px !important',
                      '&:not(:first-child)': {
                        minWidth: 'initial',
                        width: '200px !important',
                        maxWidth: 200,
                      },
                    },
                  },
                }}
                tableHeaderCellProps={{
                  sx: {
                    minWidth: 200,
                    width: '100%',
                    '&:first-child.sticky-left': {
                      width: '300px',
                    },
                    '@media screen and (max-width: 767px)': {
                      minWidth: 'initial',
                      width: !isToggled ? '200px !important' : '56px !important',
                      maxWidth: !isToggled ? 200 : 56,
                      fontSize: '12px !important',
                      '&:not(:first-child)': {
                        minWidth: 'initial',
                        width: '200px !important',
                        maxWidth: 200,
                      },
                    },
                  },
                }}
                updateData={(rowIndex, columnId, value) => {
                  setData((old) =>
                    old.map((row, index) => {
                      if (index === rowIndex) {
                        return {...old[rowIndex], [columnId]: value};
                      }
                      return row;
                    }),
                  );
                }}
              />
            </TableContainer>
          )}
          <ButtonsWrapper ref={bottomBlockRef}>
            <ButtonDefault color="primary" type="submit" variant="contained">
              Сохранить
            </ButtonDefault>
            <div>
              {isEdit && (
                <ButtonDefault
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                  }}
                >
                  Удалить
                </ButtonDefault>
              )}
              <ButtonDefault
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (wasEdit) {
                    setIsConfirmModalVisible(true);
                  } else {
                    history.goBack();
                  }
                }}
              >
                Отменить
              </ButtonDefault>
            </div>
          </ButtonsWrapper>
        </form>
      </FormProvider>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        text={`Вы уверены, что хотите удалить отчет за ${dateOfTesting} ?`}
        title="Удаление отчета"
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={() => {
          deleteStagedTestingReport(teamId, dateOfTesting);
          setIsDeleteModalVisible(false);
          history.push(`/functional_testing/${teamId}`);
        }}
      />
      <DialogDefault
        actionText="Продолжить"
        closeText="Отменить"
        content={<Typography>При отмене, изменения не будут сохранены</Typography>}
        open={isConfirmModalVisible}
        title="Отменить изменения"
        onActionButtonClick={() => {
          setIsConfirmModalVisible(false);
          history.push(`/functional_testing/${teamId}`);
        }}
        onCloseDefault={() => {
          setIsConfirmModalVisible(false);
        }}
      />
    </>
  );
};

export default StagedTestingTableEditCreate;
