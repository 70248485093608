import {A1TagAddPlayerModal} from '@components/A1TagAddPlayerModal';
import {AddOutlined} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {HockeyRoles} from '@src/types/student';
import {Box, Button, Typography} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import React, {useState} from 'react';

import {StyledTable, TableContainer} from './A1TagRostersTable';

export function A1TagRostersTable({
  isMainWindow = false,
  isHomeTeam,
  columns,
  data,
  isAddingButtonsDisabled = false,
  sorting,
  setSorting,
}) {
  useSignals();

  const defendersNumber = data?.filter((player: {hockeyRole: string}) => player.hockeyRole === 'defenseman')?.length;
  const forwardsNumber = data?.filter((player: {hockeyRole: string}) => player.hockeyRole === 'winger')?.length;
  const goaliensNumber = data?.filter((player: {hockeyRole: string}) => player.hockeyRole === 'goaltender')?.length;

  const [isA1TagAddPlayerModalShowed, setIsA1TagAddPlayerModalShowed] = useState(false);
  const [activePlayerPositon, setActivePlayerPosition] = useState(HockeyRoles.winger);

  return (
    <>
      <TableContainer>
        <StyledTable
          isAvailableHeight
          columns={columns?.map((column) => {
            return {
              ...column,
              headerStyle: {
                ...column?.headerStyle,
                py: 1,
                px: 2,
                backgroundColor: `${black[8]} !important`,
                boxShadow: `${black[20]} -1px -1px 0px 0px inset`,
                fontSize: 14,
                lineHeight: 20,
                color: `${white.main}`,
                '&:last-child': {
                  boxShadow: `${black[20]} -1px -1px 0px 0px inset`,
                },
                zIndex: 2,
              },
              cellStyle: {
                ...column?.cellStyle,
                position: 'relative',
                py: 1,
                px: 2,
                boxShadow: `${black[20]} -1px -1px 0px 0px inset`,
                fontSize: 14,
                lineHeight: 20,
                color: `${black[60]}`,
                '&:last-child': {
                  boxShadow: `${black[20]} -1px -1px 0px 0px inset`,
                },
              },
            };
          })}
          customScrollBarHeight="calc(100vh - 326px)"
          data={data}
          setSorting={setSorting}
          sorting={sorting}
        />
        <Box
          sx={{
            py: 2,
            px: 4,
            display: 'flex',
            backgroundColor: black[10],
            border: `1px solid ${black[20]}`,
            borderBottomRightRadius: 2,
            borderBottomLeftRadius: 2,
          }}
        >
          <Box
            sx={{
              pr: 4,
              flex: '0 0 33.333%',
              maxWidth: '33.333%',
              borderRight: `1px solid ${black[25]}`,
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              color={black[60]}
              component="p"
              display="inline-block"
              lineHeight="28px"
              mr={2}
              textTransform="uppercase"
              variant="overline2"
            >
              Defenders:{' '}
              <Box color={white.main} component="span">
                {defendersNumber || 0}
              </Box>
            </Typography>
            <Button
              color="primary"
              disabled={isAddingButtonsDisabled}
              endIcon={<AddOutlined />}
              size="small"
              variant="text"
              onClick={() => {
                setIsA1TagAddPlayerModalShowed(true);
                setActivePlayerPosition(HockeyRoles.defenseman);
              }}
            >
              Add
            </Button>
          </Box>
          <Box
            sx={{
              px: 3,
              flex: '0 0 33.333%',
              maxWidth: '33.333%',
              borderRight: `1px solid ${black[25]}`,
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              color={black[60]}
              component="p"
              display="inline-block"
              lineHeight="28px"
              textTransform="uppercase"
              variant="overline2"
            >
              Forwards:{' '}
              <Box color={white.main} component="span" mr={2}>
                {forwardsNumber || 0}
              </Box>
            </Typography>
            <Button
              color="primary"
              disabled={isAddingButtonsDisabled}
              endIcon={<AddOutlined />}
              size="small"
              variant="text"
              onClick={() => {
                setIsA1TagAddPlayerModalShowed(true);
                setActivePlayerPosition(HockeyRoles.winger);
              }}
            >
              Add
            </Button>
          </Box>
          <Box sx={{pl: 4, flex: '0 0 33.333%', maxWidth: '33.333%', whiteSpace: 'nowrap'}}>
            <Typography
              color={black[60]}
              component="p"
              display="inline-block"
              lineHeight="28px"
              textTransform="uppercase"
              variant="overline2"
            >
              Goaliens:{' '}
              <Box color={white.main} component="span" mr={2}>
                {goaliensNumber || 0}
              </Box>
            </Typography>
            <Button
              color="primary"
              disabled={isAddingButtonsDisabled}
              endIcon={<AddOutlined />}
              size="small"
              variant="text"
              onClick={() => {
                setIsA1TagAddPlayerModalShowed(true);
                setActivePlayerPosition(HockeyRoles.goaltender);
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </TableContainer>
      <A1TagAddPlayerModal
        isA1TagAddPlayerModalShowed={isA1TagAddPlayerModalShowed}
        isHomeTeam={isHomeTeam}
        isMainWindow={isMainWindow}
        playerPosition={activePlayerPositon}
        onClose={() => setIsA1TagAddPlayerModalShowed(false)}
      />
    </>
  );
}
