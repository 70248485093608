import ContentWrapper from '@common/ContentWrapper';
import DesktopViewStages from '@common/Statistics/PlayOff/components/DesktopViewStages';
import MobileViewStages from '@common/Statistics/PlayOff/components/MobileViewStages';
import {Stages} from '@common/Statistics/PlayOff/types';
import {Theme, useMediaQuery} from '@mui/material';
import {ConsolationStages, StagesEnum} from '@src/types/tournament';
import {Stack} from '@ui/MUI';
import React from 'react';

interface MatchesProps<StagesArr> {
  stages: Stages;
  isLoading: boolean;
  stagesArr?: StagesArr;
  isConsolationMatches?: boolean;
}

function Matches<StagesArr extends StagesEnum[] | ConsolationStages[]>({
  stages,
  isLoading,
  stagesArr,
  isConsolationMatches = false,
}: MatchesProps<StagesArr>) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <ContentWrapper overflow={{xxs: 'hidden', sm: 'auto'}} width="100%">
      <Stack
        alignItems="stretch"
        direction="row"
        justifyContent={{xxs: 'center', sm: stages?.length && stages?.length <= 2 ? 'center' : 'unset'}}
        minHeight={500}
        p={3}
        width="100%"
      >
        {isSm ? (
          <MobileViewStages isConsolationMatches={isConsolationMatches} isLoading={isLoading} stages={stages} />
        ) : (
          <DesktopViewStages
            isConsolationMatches={isConsolationMatches}
            isLoading={isLoading}
            stages={stages}
            stagesArr={stagesArr}
          />
        )}
      </Stack>
    </ContentWrapper>
  );
}

export default Matches;
