import * as dashboardsActions from '@actions/DashboardsActions';
import {loadStudentById} from '@actions/UsersActions';
import MainInfo from '@components/modules/AmateurPlayerDashboard/components/MainInfo';
import PastGames from '@components/modules/AmateurPlayerDashboard/components/PastGames';
import VideoAnalitycs from '@components/modules/AmateurPlayerDashboard/components/VideoAnalitycs';
import DashboardBlock from '@components/modules/common/DashboardBlock';
import Layout from '@components/modules/Layout';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Theme, useMediaQuery} from '@mui/material';
import {Stack} from '@ui/MUI';
import PageTitle from '@ui/v2/PageTitle';
import React, {useEffect} from 'react';

const dashboardBlocksPaddings = {
  py: {xxs: 2, sm: 3},
  px: {xxs: 3, sm: 4},
};
export default function AmateurPlayerDashboard() {
  const dispatch = useStoreDispatch();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {currentUserId, student, isLoading} = useStore((state) => {
    return {
      isLoading: state?.users?.loading,
      student: state?.users?.student,
      currentUserId: state?.users?.currentUserId,
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(dashboardsActions.getDashboard());
  }, []);

  useEffect(() => {
    if (currentUserId) {
      dispatch(loadStudentById(currentUserId));
    }
  }, [currentUserId]);

  return (
    <>
      <Layout active="index">
        {!isXs && (
          <PageTitle breadCrumbs={[{label: student?.school?.name || '', path: null}]} showBreadcrumbs={isMd}>
            Рабочий стол игрока
          </PageTitle>
        )}

        <Stack direction="column" gap={{xxs: 2, sm: 3}}>
          <Stack direction={{xxs: 'column', sm: 'row'}} gap={{xxs: 2, sm: 3}}>
            <DashboardBlock {...dashboardBlocksPaddings} width={{xxs: '100%', sm: '50%'}}>
              <MainInfo isLoading={isLoading} student={student} />
            </DashboardBlock>
            <DashboardBlock
              {...dashboardBlocksPaddings}
              display="flex"
              flexDirection="column"
              position="relative"
              width={{xxs: '100%', sm: '50%'}}
            >
              <PastGames />
            </DashboardBlock>
          </Stack>
          <DashboardBlock>
            <VideoAnalitycs />
          </DashboardBlock>
        </Stack>
      </Layout>
    </>
  );
}
