import LocalPreloader from '@components/preloader/LocalPreloader';
import date from '@helpers/date';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {IconButton} from '@mui/material';
import {loadTodaySchedule} from '@slices/managerDashboard';
import {DEFAULT_DATE_SEND_FORMAT, GAME_TYPES, longDash} from '@src/constants';
import Box from '@ui/MUI/Box';
import Drawer from '@ui/MUI/DrawerNew';
import Link from '@ui/MUI/Link';
import DatePickerMobile from '@ui/MUI/MobileDatePicker';
import Stack from '@ui/MUI/Stack';
import TabsNew from '@ui/MUI/TabsNew';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {BoxShadow, TitleText} from '../../ManagerDashboard';
import Card from '../Card';
import LessonView from '../LessonView';
import NoData from '../NoData';

const third = 100 / 3;
const headlines = [
  {
    title: 'Время',
    width: {xxs: '50%', sm: third + '%'},
  },
  {title: 'Тип мероприятия', width: {xxs: '100%', sm: third + '%'}, order: {xxs: 3, sm: 2}},
  {
    title: 'Помещение',
    width: {xxs: '50%', sm: third + '%'},
    order: {xxs: 2, sm: 3},
    textAlign: 'right',
  },
];
export default function Schedule({groupSelect}) {
  const [scheduleDate, setScheduleDate] = useState(date()?.currentDate);
  const [groupId, setGroupId] = useState({index: 0, id: groupSelect?.groups?.[0]?.id});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [watchInfoIndex, setWatchInfoIndex] = useState(null);
  const dispatch = useDispatch();
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.todaySchedule);

  useEffect(() => {
    groupId.id &&
      dispatch(
        loadTodaySchedule({
          scheduleFilter: groupId.id,
          scheduleDate: date(scheduleDate).format(DEFAULT_DATE_SEND_FORMAT),
        }),
      );
  }, [scheduleDate, groupId]);

  const setGroupIdToLocalStorage = (groupId) => {
    window.localStorage.setItem('managerSelectedGroupIdForSchedule', JSON.stringify(groupId));
  };

  useEffect(() => {
    if (!window?.localStorage?.managerSelectedGroupIdForSchedule && groupSelect?.groups?.length) {
      setGroupId({index: 0, id: groupSelect?.groups?.[0]?.id});
    }
    if (window?.localStorage?.managerSelectedGroupIdForSchedule && groupSelect?.groups?.length) {
      setGroupId(JSON.parse(window?.localStorage?.managerSelectedGroupIdForSchedule));
    }
  }, [groupSelect?.groups?.length, window?.localStorage?.managerSelectedGroupIdForSchedule]);

  return (
    <>
      <Card
        height={(isLoading || groupSelect?.loading) && '250px'}
        titleComponent={
          <Stack direction="row" justifyContent="space-between">
            <TitleText variant="h2">расписание на сегодня</TitleText>
            <Stack alignItems="center" direction="row" flexShrink="0">
              <IconButton
                color="darkText"
                size="small"
                onClick={() => setScheduleDate(date(scheduleDate).add(-1, 'days'))}
              >
                <ChevronLeftIcon />
              </IconButton>
              <TitleText cursor="pointer" variant="h2" onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
                {date(scheduleDate).format('DD MMMM, dd')}
              </TitleText>
              <IconButton
                color="darkText"
                size="small"
                onClick={() => setScheduleDate(date(scheduleDate).add(1, 'days'))}
              >
                <ChevronRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <LocalPreloader visible={isLoading || groupSelect?.loading} />
        <Box>
          <DatePickerMobile
            allowSameDateSelection={false}
            closeDataPicker={() => setIsDatePickerOpen(false)}
            handleChange={(value) => {
              value && setScheduleDate(value);
              setIsDatePickerOpen(false);
            }}
            isDatePickerOpen={isDatePickerOpen}
            okText=""
            value={date(scheduleDate)}
          />
          <BoxShadow sx={{background: '#fff', '.TabsWrapper .MuiBox-root .MuiBox-root': {pb: '0px'}}}>
            <TabsNew
              sx={{
                '.MuiTabs-scroller': {minHeight: 40},
              }}
              tabs={
                groupSelect?.groups?.map((group) => ({
                  label: group.name,
                })) || [{label: ''}]
              }
              value={groupId.index}
              onChange={(index) => {
                setGroupId({index, id: groupSelect?.groups[index].id});
                setGroupIdToLocalStorage({index, id: groupSelect?.groups[index].id});
              }}
            />
            {isLoad && Boolean(data?.length) && (
              <Box p={3} pb={0} pt={2} sx={{backgroundColor: '#fff'}}>
                <Stack direction="row" gap={1} justifyContent="space-between">
                  {headlines.map((item, index) => (
                    <Box display={item.display} key={index} width={item.width}>
                      <TypographyDefault textAlign={item.textAlign} variant="body2">
                        {item.title}
                      </TypographyDefault>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </BoxShadow>
        </Box>
        {(isLoad && !data?.length) || error ? (
          <NoData>На сегодня нет мероприятий в расписании команды</NoData>
        ) : (
          <Box>
            {data.map(({timeStart, timeEnd, type, room}, index) => (
              <Stack
                alignItems="center"
                backgroundColor={index % 2 !== 0 && '#F3F3F3'}
                cursor="pointer"
                direction="row"
                gap={1}
                justifyContent="space-between"
                key={index}
                p={3}
                pb={2}
                pt={2}
                onClick={() => {
                  setWatchInfoIndex(index);
                  setIsModalOpen(true);
                }}
              >
                <Box overflow="hidden" width={headlines[0].width}>
                  <TypographyDefault fontWeight="600" variant="body2">
                    {`${date(timeStart).format('HH:mm')}-${date(timeEnd).format('HH:mm')}`}
                  </TypographyDefault>
                </Box>
                <Box overflow="hidden" width={headlines[1].width}>
                  <TypographyDefault fontWeight="600" variant="body2">
                    {GAME_TYPES[type]}
                  </TypographyDefault>
                </Box>
                <Box overflow="hidden" textAlign="right" width={headlines[2].width}>
                  <TypographyDefault variant="body2">{room || longDash}</TypographyDefault>
                </Box>
              </Stack>
            ))}
          </Box>
        )}
        {groupId?.id && (
          <Box p={3} pb={0} pt={2}>
            <Link to={`/schedule?groupIds=${groupId.id}&viewMode=week`}>Посмотреть расписание на неделю</Link>
          </Box>
        )}
      </Card>
      <Drawer
        open={isModalOpen}
        title="Просмотр мероприятия"
        onClose={() => {
          setIsModalOpen(false);
          setWatchInfoIndex(null);
        }}
      >
        {watchInfoIndex >= 0 && <LessonView {...data[watchInfoIndex]} />}
      </Drawer>
    </>
  );
}
