import {Stack, Typography} from '@mui/material';
import React from 'react';

import {Filters} from './components/filters.component';
import {SkillDrawer} from './components/skill-drawer.component';
import {Table} from './components/table.component';

export const SkillAnalyticsTab = () => {
  return (
    <>
      <SkillDrawer />
      <Stack spacing={2}>
        <Filters />
        <Stack borderRadius={1} pt={2} spacing={2} sx={{background: 'white'}}>
          <Typography pl={4} variant="subtitle1">
            Авангард-ЮХЛ, Авангард 2008
          </Typography>
          <Table />
        </Stack>
      </Stack>
    </>
  );
};
