import {
  AnalyticsOutlined,
  AssignmentTurnedInOutlined,
  CalendarMonthOutlined,
  EditNoteOutlined,
  FitnessCenterOutlined,
  GroupsOutlined,
  HomeOutlined,
  MenuBookOutlined,
  MissedVideoCallOutlined,
  MonitorWeightOutlined,
  NoteAltOutlined,
  OpenInNewRounded,
  PeopleAltOutlined,
  PsychologyOutlined,
  SchoolOutlined,
  SpeedOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {MENU_CATEGORIES_ENUM} from '@src/types/menu';

const {sport, health, control} = MENU_CATEGORIES_ENUM;
export const MENU_CATEGORIES_LABELS = {
  [sport]: 'Спорт',
  [health]: 'Здоровье',
  [control]: 'Управление',
};

const categ = MENU_CATEGORIES_ENUM;

export const homePage = {
  name: 'main',
  label: 'Главная',
  Icon: HomeOutlined,
  category: categ.default,
  url: '/',
};
export const schedule = {
  name: 'schedule',
  label: 'Расписание',
  Icon: CalendarMonthOutlined,
  category: categ.default,
};
export const materials = {
  name: 'materials',
  label: 'Материалы',
  Icon: MenuBookOutlined,
  category: categ.default,
};
export const developmentJournal = {
  name: 'development_journal',
  label: 'Журнал развития',
  Icon: StairsOutlinedIcon,
  category: categ.sport,
};
export const trainingControlProcess = {
  name: 'training_control_process',
  label: 'КТП',
  Icon: AssignmentTurnedInOutlined,
  url: '/training_control_process/1',
  category: categ.sport,
};
export const psychotestReport = {
  name: 'psychotest_report',
  label: 'Отчет психологического профиля',
  Icon: EditNoteOutlined,
  category: categ.health,
};
export const statistics = {
  name: 'statistics',
  label: 'Статистика',
  Icon: AnalyticsOutlined,
  category: categ.sport,
};
export const tournaments = {
  isLink: true,
  name: 'tournaments',
  label: 'Турнир',
  Icon: TrackChangesIcon,
  category: categ.sport,
};
export const lines = {
  name: 'lines',
  label: 'Состав',
  Icon: GroupsOutlined,
  category: categ.sport,
};
export const users = {
  name: 'users',
  label: 'Пользователи',
  Icon: PeopleAltOutlined,
  category: categ.control,
};
export const video = {
  name: 'video',
  label: 'Видеоаналитика',
  Icon: MissedVideoCallOutlined,
  category: categ.sport,
};
export const trainerReport = {
  name: 'trainer_report',
  label: 'Характеристика игрока',
  Icon: EditNoteOutlined,
  url: '/trainer_report/inner/teams',
  category: categ.sport,
};
export const functionalTesting = {
  name: 'functional_testing',
  label: 'Функциональное тестирование',
  Icon: SpeedOutlined,
  category: categ.health,
};
export const schools = {
  name: 'schools',
  label: 'Школы',
  Icon: SchoolOutlined,
  category: categ.control,
};
export const teams = {
  name: 'teams',
  label: 'Команды',
  Icon: GroupsOutlined,
  category: categ.control,
};
export const physicalTesting = {
  name: 'physical_testing_new',
  label: 'ОФП/СФП',
  Icon: FitnessCenterOutlined,
  url: '/physical_testing_new/overall_report',
  category: categ.sport,
};
export const students = {
  name: 'students',
  label: 'Игроки',
  Icon: GroupsOutlined,
  category: categ.control,
};
export const bodyMassEstimation = {
  name: 'body-mass-estimation',
  label: 'Антропометрия',
  Icon: MonitorWeightOutlined,
  category: categ.health,
};
export const psychotest = {
  name: 'psychotest',
  label: 'Психологический профиль',
  Icon: PsychologyOutlined,
  category: categ.health,
};
export const addStats = {
  name: 'add_stats',
  label: 'Загрузка статистики',
  Icon: UploadFileOutlined,
  category: categ.control,
};
export const editStats = {
  name: 'edit_stats',
  label: 'Редактирование статистики',
  Icon: NoteAltOutlined,
  category: categ.control,
};
export const a1tag = {
  name: 'a1tag',
  label: 'Открыть A1TAG',
  Icon: OpenInNewRounded,
  category: categ.others,
};
export const prospects = {
  name: 'prospects',
  label: 'Проспекты',
  Icon: PeopleAltOutlined,
  category: categ.control,
};
export const accesses = {
  name: 'accesses',
  label: 'Доступы',
  Icon: PeopleAltOutlined,
  category: categ.control,
};
