import {Typography} from '@mui/material';
import {longDash} from '@src/constants';
import React from 'react';

import {CellDef} from '../a1Table.types';

export const DefaultCell = ({getValue}: CellDef<string | number>) => {
  const value = getValue();
  return (
    <Typography fontSize={14} lineHeight={1}>
      {value ?? longDash}
    </Typography>
  );
};
