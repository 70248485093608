import {a1TagVideoPlayerHotKeysModal} from '@components/A1TagVideoPlayerHotKeys/models/a1TagVideoPlayerHotKeys.model';
import {
  a1tagViewModel,
  updateGodMode,
  updateRoasters,
  updateScore,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {CompareArrowsOutlined, FontDownloadRounded} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {Button, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import Switch from '@ui/MUI/Switch';
import React from 'react';

export function A1TagMainWindowAdditionalMenu() {
  useSignals();
  const {homeScore, awayScore, rosters, isGodMode} = a1tagViewModel;
  const swapTeams = () => {
    const score = [homeScore?.value, awayScore?.value];
    updateScore('home', score?.[1]);
    updateScore('away', score?.[0]);
    const homeTeam = rosters.value.homeTeam;
    const awayTeam = rosters.value.awayTeam;
    updateRoasters({homeTeam: awayTeam, awayTeam: homeTeam});
  };
  return (
    <>
      <Button
        endIcon={<FontDownloadRounded />}
        size="small"
        sx={{
          mr: 2,
          color: black[75],
          textTransform: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: black[20],
            color: black[95],
          },
        }}
        variant="contained"
      >
        <Typography color={black[75]} variant="caption" onClick={() => a1TagVideoPlayerHotKeysModal.open()}>
          Show hotkeys
        </Typography>
      </Button>
      <Button
        endIcon={<CompareArrowsOutlined />}
        size="small"
        sx={{
          mr: 2,
          color: black[75],
          textTransform: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: black[20],
            color: black[95],
          },
        }}
        variant="contained"
        onClick={() => {
          swapTeams();
        }}
      >
        <Typography color={black[75]} variant="caption">
          Swap Teams
        </Typography>
      </Button>
      <FormControlLabel
        control={
          <Switch
            checked={isGodMode.value}
            color="primary"
            size="small"
            sx={{mx: '0px'}}
            onChange={(_, v) => {
              updateGodMode(v);
            }}
          />
        }
        label="God mode"
      />
    </>
  );
}
