import {deleteCommonReport, postCommonReport} from '@api/bmi';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {Wrapper} from '@components/apps/NewBodyMassIndex/BodyMassIndex';
import ErrorModal from '@components/apps/NewBodyMassIndex/components/CommonReportEditCreate/components/ErrorModal';
import {ACTION_TYPES} from '@components/apps/NewBodyMassIndex/constants';
import {noDataCheck} from '@components/apps/NewBodyMassIndex/helpers';
import LoadingBox from '@components/common/LoadingBox';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useElementSize from '@hooks/useElementSize';
import useFatAccess from '@hooks/useFatAccess';
import DeleteIcon from '@mui/icons-material/Delete';
import {useMediaQuery} from '@mui/material';
import {noDataNumberValue} from '@src/helpers';
import {Box, Button, IconButton, Stack, TextField, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import LoadingButton from '@ui/MUI/LoadingButton';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';

Table.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  date: PropTypes.number,
  type: PropTypes.oneOf([ACTION_TYPES.add, ACTION_TYPES.edit]),
};

const canNotSaveMessage = {
  [ACTION_TYPES.add]: 'Введите значение для того, чтобы сохранить',
  [ACTION_TYPES.edit]: 'Отредактируйте таблицу для того, чтобы сохранить',
};

function hasParamsCheck(obj) {
  return obj?.weight || obj?.height || obj?.caliperometry;
}
function Table({isLoading, data = [], date, type}) {
  const {
    handleSubmit,
    reset,
    getValues,
    formState: {isDirty},
  } = useFormContext();
  const {groupId} = useParams();
  const [ref, {height}] = useElementSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isToggled, setToggleUser] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {hasAccess} = useFatAccess(groupId);
  const history = useHistory();
  const bodyParamsCells = useMemo(
    () =>
      [
        {label: 'Рост', param: 'height', threshold: 0, min: 100, max: 300},
        {label: 'Вес', param: 'weight', threshold: void 0, min: 0, max: 250},
        {label: '% жира', param: 'caliperometry', threshold: void 0, min: 0, max: 100},
      ].slice(0, hasAccess ? 3 : 2),
    [hasAccess],
  );
  const columns = useMemo(
    () => [
      {
        header: (
          <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
            Игрок
          </ToggledHeader>
        ),
        id: 'player',
        enableRowSpan: true,
        sticky: {left: true},
        columns: [
          {
            header: 'Not visible header',
            accessorFn: ({params, ...data}) => data,
            headerStyle: {display: 'none !important'},
            cellStyle: {transition: '.3s ease-in-out'},
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  onClick={() => (student?.playerId ? history.push(`/student/${student?.playerId}`) : void 0)}
                >
                  <PlayersHorizontalCard isLoading={isLoading} onlyNumber={isToggled} student={student} />
                </Stack>
              );
            },
          },
        ],
        rowSpan: '2',
      },
      {
        header: <Typography variant="subtitle2">Телосложение</Typography>,
        id: 'typeOfBody',
        enableRowSpan: true,
        columns: [
          {
            header: 'Not visible header 2',
            accessorFn: (paramas) => paramas?.previous?.typeOfBody?.name,
            headerStyle: {display: 'none'},
            cell: (params) => {
              const value = params?.getValue() || 'Не выбрано';
              return (
                <Typography color={grey['68']} isLoading={isLoading} preloaderWidthBetween={[40, 100]} variant="body2">
                  {value}
                </Typography>
              );
            },
          },
        ],
        rowSpan: '2',
      },
      ...bodyParamsCells.map(({min, max, threshold, ...item}) => ({
        header: item.label,
        headerStyle: {textAlign: 'center'},

        columns: [
          {value: 'current', label: 'Текущее знач.', editable: true},
          {value: 'previous', label: 'Предыд. знач.', editable: type === ACTION_TYPES.edit},
        ].map(({label, value, editable}, index, arr) => {
          const column = {
            accessorFn: (params) => {
              return params?.[value]?.[item.param];
            },
            id: `${value}.${item.param}`,
            header: () => <Typography variant="caption1">{label}</Typography>,
            headerStyle: {
              textAlign: 'center',
              color: grey['54'],
            },
            cellStyle: {
              textAlign: 'center',
            },

            cell: ({getValue, row}) => {
              const current = `form.${row.id}.${arr[0].value}.${item.param}`;
              const previous = `form.${row.id}.${arr[1].value}.${item.param}`;
              const cellValue = getValue();
              return editable && !isLoading ? (
                <Controller
                  name={value === arr[0].value ? current : previous}
                  render={({field: {onChange, ...field}, fieldState: {error}}) => {
                    return (
                      <Tooltip open={!!error} placement="bottom" title={error?.message}>
                        <TextField
                          data-testid={value + '-value-data'}
                          disabled={value === 'previous' && !row?.original?.previous?.date}
                          helperText=""
                          inputProps={{
                            step: 0.1,
                            min,
                            max,
                            onBlur: (e) => {
                              let value = e.target.value;
                              if (!noDataCheck(value)) return onChange(null);
                              if (value > max) value = max;
                              if (value < min) value = min;
                              onChange(parseFloat(value));
                            },
                          }}
                          type="number"
                          onChange={(e) => onChange(e.target.value)}
                          {...field}
                          error={!!error}
                        />
                      </Tooltip>
                    );
                  }}
                  rules={{
                    validate: (value, formState) => {
                      const prevValue = get(formState, previous);
                      const diff = value - prevValue;
                      if (value > 0 && diff < threshold) {
                        if (!isModalOpen) setIsModalOpen(true);
                        return false;
                      }
                    },
                  }}
                />
              ) : (
                <Typography isLoading={isLoading} preloaderWidthBetween={[40, 100]} variant="body2">
                  {noDataNumberValue(cellValue)}
                </Typography>
              );
            },
          };
          if (value !== 'previous') {
            column.headerStyle.boxShadow = `inset 0px -1px 0px 0px ${grey[12]}`;
            column.cellStyle.boxShadow = `inset 0px -1px 0px 0px ${grey[12]}`;
          }
          return column;
        }),
      })),
    ],
    [isLoading, isModalOpen, type, isToggled],
  );

  function handleData(formData, onlyCurrent) {
    return formData.form?.flatMap(({previous, current, avatar, id, ...player}) => {
      const result = [];
      const newCurrent = hasParamsCheck(current)
        ? {playerId: id, ...player, ...current, date: current?.date || date}
        : null;
      const newPrevious =
        hasParamsCheck(previous) && type === ACTION_TYPES.edit
          ? {playerId: id, ...player, ...previous, date: previous?.date || date}
          : null;
      if (newCurrent) result.push(newCurrent);
      if (newPrevious && !onlyCurrent && previous?.date) result.push(newPrevious);
      return result;
    });
  }
  function onSubmit(formData) {
    postCommonReport(handleData(formData))
      .then(() => {
        history.push(`/bmi?groupId=${groupId}`);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  function onDelete() {
    setIsDeleting(true);
    deleteCommonReport(handleData(getValues(), true))
      .then(() => history.push(`/bmi?groupId=${groupId}`))
      .finally(() => setIsDeleting(false));
  }
  return (
    <>
      <Wrapper>
        <LoadingBox isLoading={isSaving || isDeleting}>
          <ReactTableComponent
            isAvailableHeight
            bottomBlockHeight={height + 10}
            columns={columns}
            data={data}
            data-testid="edit-create-table"
            tableBodyCellProps={{sx: {py: {xxs: 1, sm: 3}, px: {xxs: 2, sm: 4}, height: 'auto !important'}}}
            tableHeaderCellProps={{sx: {py: 0, px: {xxs: 2, sm: 4}, height: 'auto !important'}}}
          />
        </LoadingBox>
      </Wrapper>
      <Wrapper mt={0} px={{xxs: 2, sm: 4}} py={{xxs: 1, sm: 3}} ref={ref}>
        <Stack alignItems="center" direction="row" gap={1} justifyContent="space-between">
          <Tooltip title={!isDirty ? canNotSaveMessage[type] : ''}>
            <Box>
              <LoadingButton
                color="primary"
                data-testid="save-common-report-button"
                disabled={isDeleting || isLoading || isSaving || !isDirty}
                loading={isSaving}
                variant="contained"
                onClick={() => {
                  setIsSaving(true);
                  handleSubmit(
                    (data) => onSubmit(data),
                    () => setIsSaving(false),
                  )();
                }}
              >
                Сохранить
              </LoadingButton>
            </Box>
          </Tooltip>

          <Stack direction="row" gap={{xxs: 1, sm: 3}}>
            {type === ACTION_TYPES.edit &&
              (isMobile ? (
                <Tooltip title="Удалить отчет">
                  <Box>
                    <IconButton
                      color="primary"
                      data-testid="delete-common-report-button"
                      size="middle"
                      onClick={onDelete}
                    >
                      <DeleteIcon color="inherit" fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <LoadingButton
                  color="primary"
                  data-testid="delete-common-report-button"
                  disabled={isSaving || isDeleting || isLoading}
                  loading={isDeleting}
                  variant="outlined"
                  onClick={onDelete}
                >
                  Удалить
                </LoadingButton>
              ))}
            <Button
              color="secondary"
              disabled={isSaving || isDeleting || isLoading}
              variant="outlined"
              onClick={() => history.push(`/bmi?groupId=${groupId}`)}
            >
              Отменить
            </Button>
          </Stack>
        </Stack>
        <ErrorModal
          actionButtonProps={{'data-testid': 'save-button-modal'}}
          isLoading={isSaving}
          isSaving={isSaving}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={() => {
            setIsModalOpen(false);
            reset((formValues) => formValues, {keepErrors: false});
            handleSubmit(onSubmit)();
          }}
        />
      </Wrapper>
    </>
  );
}

export default Table;
