import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Autocomplete, Box, Button, IconButton, TextField} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import DatePickerDefault from '@ui/MUI/DatePicker';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {NOTIFICATION_TYPES} from '../../../constants/config';
import {generateId} from '../../../helpers/MedicalProfileHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

export default function Nutrition() {
  const medicalProfileForm = useSelector((state) => state.medicalProfile.medicalProfileForm || {});

  const dispatch = useDispatch();
  const [typesOptions, setTypesOptions] = useState([]);
  useEffect(() => {
    MedicalProfileAPI.getNutritionRecommendations()
      .then((data) => {
        setTypesOptions(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке рекомендаций к тренировкам');
      });
  }, []);

  const handleDeleteNutrition = (nutritionId) => {
    if (!nutritionId) return;

    let cloneNutrition = medicalProfileForm.nutrition ? [...medicalProfileForm.nutrition] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    cloneNutrition.splice(
      cloneNutrition.findIndex((i) => i.idFront === nutritionId),
      1,
    );

    currentMedicalProfileForm.nutrition = cloneNutrition;
    dispatch(handleChangeForm(currentMedicalProfileForm));
  };

  const handleAddNutrtition = () => {
    const cloneNutrition = medicalProfileForm?.nutrition?.length ? [...medicalProfileForm.nutrition] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        nutrition: [
          ...cloneNutrition,
          {
            idFront: generateId(),
            type: '',
            startDate: '',
            comments: '',
          },
        ],
      }),
    );
  };
  const handleNutrition = (nutritionId, name, value) => {
    let cloneNutrition = medicalProfileForm.nutrition ? [...medicalProfileForm.nutrition] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};
    const nutrition = cloneNutrition.map((i) => {
      if (i.idFront === nutritionId) return {...i, [name]: value};
      return i;
    });

    dispatch(handleChangeForm({...currentMedicalProfileForm, nutrition}));
  };

  const selectedDate = (date, id) => {
    if (date) {
      return new Date(date);
    } else {
      handleNutrition(id, 'startDate', new Date().getTime());
      return new Date();
    }
  };

  return (
    <Box py={3} sx={{pb: {xxs: '120px', sm: 3}}}>
      <Title>Рекомендации по системе питания</Title>
      {medicalProfileForm.nutrition
        ? medicalProfileForm.nutrition.map((nutrition, index) => {
            return (
              <Recommendation key={index}>
                <Fields>
                  <FieldsWrapper>
                    <Field>
                      <Autocomplete
                        error={!typesOptions.filter((option) => option.value === nutrition.type)[0]}
                        helperText={
                          !typesOptions.filter((option) => option.value === nutrition.type)[0] &&
                          'Обязательное поле для заполнения'
                        }
                        label="Тип рекомендации"
                        multiple={false}
                        options={typesOptions}
                        placeholder="Выберите тип рекомендации"
                        value={
                          typesOptions.filter((option) => option.value === nutrition.type).length
                            ? typesOptions.filter((option) => option.value === nutrition.type)[0]
                            : ''
                        }
                        onChange={(_, type) => {
                          handleNutrition(nutrition.idFront, 'type', type?.value);
                        }}
                      />
                    </Field>
                    <Field>
                      <DatePickerDefault
                        inputFormat="DD.MM.yyyy"
                        label="Дата начала рекомендации"
                        value={selectedDate(nutrition.startDate, nutrition.idFront)}
                        onChange={(newDate) => {
                          handleNutrition(nutrition.idFront, 'startDate', new Date(newDate).getTime());
                        }}
                      />
                    </Field>
                  </FieldsWrapper>
                </Fields>
                <Fields>
                  <FieldsWrapper>
                    <Field>
                      <TextField
                        InputProps={{sx: {minHeight: 40, maxWidth: 450}}}
                        error={!nutrition.comments}
                        helperText={!nutrition.comments && 'Обязательное поле для заполнения'}
                        label="Комментарий"
                        multiline={true}
                        value={nutrition.comments ? nutrition.comments : ''}
                        onChange={(evt) => {
                          handleNutrition(nutrition.idFront, 'comments', evt.target.value);
                        }}
                      />
                    </Field>
                  </FieldsWrapper>
                </Fields>
                <IconButton
                  className="DeleteIcon"
                  onClick={() => {
                    handleDeleteNutrition(nutrition.idFront);
                  }}
                >
                  <HighlightOffIcon color="primary" />
                </IconButton>
              </Recommendation>
            );
          })
        : null}
      <Button
        startIcon={<AddIcon />}
        sx={{mt: 2, borderColor: grey[8], color: grey[54]}}
        onClick={() => handleAddNutrtition()}
      >
        Добавить рекомендацию по питанию
      </Button>
    </Box>
  );
}

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const Recommendation = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .DeleteIcon {
    position: absolute;
    top: 32px;
    right: 18px;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      right: 0;
    }
    @media screen and (max-width: 768px) {
      top: auto;
      bottom: 20px;
      right: -24px;
    }
  }
`;
const Fields = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  box-sizing: border-box;
  :first-of-type {
    padding-right: 20px;
    padding-left: 0;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }
  :last-of-type {
    padding-right: 0;
    padding-left: 20px;
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
`;
const FieldsWrapper = styled.div``;
const Field = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
`;
