import {A1TagChooseColorPopover} from '@components/A1TagChooseColorPopover';
import {A1TagChooseTeamModal} from '@components/A1TagChooseTeamModal';
import {A1TagDeletePaper} from '@components/A1TagDeletePaper';
import {A1TagRostersTable} from '@components/A1TagRostersTable';
import {deletePlayerByAvangardId} from '@components/apps/A1TagAddingRosters/utils';
import {
  chooseTeamModalViewModel,
  updateChooseTeamPayload,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {sortColumn} from '@components/apps/PhysicalTesting/helpers';
import {defaultSort} from '@helpers/index';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import {ModeEditOutlineRounded, SettingsRounded} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {HAND_MAP, HAND_OPTIONS_EN, HOCKEY_ROLES, HOCKEY_ROLES_EN_OPTIONS} from '@src/constants';
import {Autocomplete, Box, Button, Grid, IconButton, TextField, Typography} from '@ui/MUI';
import {black, secondary, white} from '@ui/MUI/colorsA1Tag';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import Switch from '@ui/MUI/Switch';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, FieldValues, UseFormReturn} from 'react-hook-form';

import {TeamHead} from './A1TagRoastersTeamBlock';

const sortPositionColumn = (rowA, rowB, columnId) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  if (a !== 'goaltender' && b === 'goaltender') return -1;
  if (a === 'goaltender' && b !== 'goaltender') return 1;
  return defaultSort(a, b);
};

type A1TagRoastersTeamBlockProps = {
  isSelectedTeamHome: boolean;
  editTeamRoasterForm: UseFormReturn<FieldValues, unknown, undefined>;
};

export function A1TagRoastersTeamBlock({isSelectedTeamHome, editTeamRoasterForm}: A1TagRoastersTeamBlockProps) {
  useSignals();

  const payload = chooseTeamModalViewModel?.payload?.value ?? {};

  const homeTeamName = payload?.homeTeam?.players?.[0]?.group?.name || 'Team №1';
  const awayTeamName = payload?.awayTeam?.players?.[0]?.group?.name || 'Team №2';

  useEffect(() => {
    editTeamRoasterForm.setValue('homeTeamName', homeTeamName);
    editTeamRoasterForm.setValue('awayTeamName', awayTeamName);
  }, [homeTeamName, awayTeamName]);

  const [isLoading, setIsLoading] = useState(false);

  const [showDeleteContext, setShowDeleteContext] = useState({isShow: false, rowId: 0});

  const homeTeamTableColumns = useMemo(
    () => [
      {
        accessorKey: 'rowNumber',
        header: '',
        headerStyle: {width: 50, maxWidth: 50},
        cell: ({row}: {row: {id: string}}) => parseInt(row.id) + 1,
        cellStyle: {width: 50, maxWidth: 50},
        enableSorting: false,
      },
      {
        accessorKey: 'playerNumber',
        header: '№',
        headerStyle: {width: 80, maxWidth: 80},
        cell: ({getValue, row}: {getValue: () => number; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`homeTeamPlayers.${row.id}.playerNumber`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {color: white.main}}}
                    type="number"
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 80, maxWidth: 80},
        sortingFn: sortColumn,
      },
      {
        accessorKey: 'avangardId',
        header: 'ID',
        headerStyle: {width: 96, maxWidth: 96},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`homeTeamPlayers.${row.id}.avangardId`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 120, maxWidth: 120},
      },
      {
        accessorKey: 'lastName',
        header: 'SURNAME',
        headerStyle: {width: 145, maxWidth: 145},
        cell: ({
          getValue,
          row,
        }: {
          getValue: () => string;
          row: {
            original: {id: string; avangardId: string};
            id: string;
          };
        }) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`homeTeamPlayers.${row.id}.lastName`}
              render={({field: {onChange, value}}) => {
                return (
                  <>
                    <TextField
                      sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                      }}
                      onClick={(e) => {
                        if (showDeleteContext?.isShow) {
                          e.preventDefault();
                          setShowDeleteContext({...showDeleteContext, isShow: false});
                        }
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                      }}
                    />
                    {showDeleteContext?.isShow && showDeleteContext?.rowId === Number(row?.id) && (
                      <A1TagDeletePaper
                        onDelete={() => {
                          deletePlayerByAvangardId({
                            isMainWindow: false,
                            isHomeTeam: true,
                            payload: payload,
                            avangardId: row?.original?.avangardId,
                          });
                          setShowDeleteContext({...showDeleteContext, isShow: false});
                        }}
                      />
                    )}
                  </>
                );
              }}
            />
          );
        },
        cellStyle: {width: 145, maxWidth: 145},
      },
      {
        accessorKey: 'firstName',
        header: 'NAME',
        headerStyle: {width: 115, maxWidth: 115},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`homeTeamPlayers.${row.id}.firstName`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 115, maxWidth: 115},
      },
      {
        accessorKey: 'hockeyRole',
        header: 'POSITION',
        headerStyle: {width: 130, maxWidth: 130},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === getValue())?.value}
              name={`homeTeamPlayers.${row.id}.hockeyRole`}
              render={({field: {onChange, value}}) => {
                return (
                  <Autocomplete
                    disableClearable={true}
                    multiple={false}
                    options={HOCKEY_ROLES_EN_OPTIONS}
                    sx={{'.MuiAutocomplete-input': {color: white.main}}}
                    value={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === value)}
                    onChange={(_, newValue) => {
                      onChange(newValue?.value);
                      updateChooseTeamPayload({
                        homeTeam: {
                          ...payload?.homeTeam,
                          players: payload?.homeTeam?.players?.map((player, index) => {
                            if (index === Number(row.id)) {
                              return {...player, hockeyRole: newValue?.value};
                            } else {
                              return player;
                            }
                          }),
                        },
                      });
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 140, maxWidth: 140},
        sortingFn: sortPositionColumn,
      },
      {
        accessorKey: 'hand',
        header: 'HAND',
        headerStyle: {width: 88, maxWidth: 88},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={HAND_OPTIONS_EN?.find((option) => option.value === getValue())?.value}
              name={`homeTeamPlayers.${row.id}.hand`}
              render={({field: {onChange, value}}) => {
                return (
                  <Autocomplete
                    disableClearable={true}
                    multiple={false}
                    options={HAND_OPTIONS_EN}
                    sx={{'.MuiAutocomplete-input': {color: white.main}}}
                    value={HAND_OPTIONS_EN?.find((option) => option.value === value)}
                    onChange={(_, newValue) => {
                      onChange(newValue?.value);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 88, maxWidth: 88},
      },
    ],
    [payload?.homeTeam?.players, showDeleteContext?.isShow],
  );

  const awayTeamTableColumns = useMemo(
    () => [
      {
        accessorKey: 'rowNumber',
        header: '',
        headerStyle: {width: 50, maxWidth: 50},
        cell: ({row}: {row: {id: string}}) => parseInt(row.id) + 1,
        cellStyle: {width: 50, maxWidth: 50},
        enableSorting: false,
      },
      {
        accessorKey: 'playerNumber',
        header: '№',
        headerStyle: {width: 80, maxWidth: 80},
        cell: ({getValue, row}: {getValue: () => number; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`awayTeamPlayers.${row.id}.playerNumber`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {color: white.main}}}
                    type="number"
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 80, maxWidth: 80},
        sortingFn: sortColumn,
      },
      {
        accessorKey: 'avangardId',
        header: 'ID',
        headerStyle: {width: 96, maxWidth: 96},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`awayTeamPlayers.${row.id}.avangardId`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 120, maxWidth: 120},
      },
      {
        accessorKey: 'lastName',
        header: 'SURNAME',
        headerStyle: {width: 145, maxWidth: 145},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string; avangardId: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`awayTeamPlayers.${row.id}.lastName`}
              render={({field: {onChange, value}}) => {
                return (
                  <>
                    <TextField
                      sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                      }}
                      onClick={(e) => {
                        if (showDeleteContext?.isShow) {
                          e.preventDefault();
                          setShowDeleteContext({...showDeleteContext, isShow: false});
                        }
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                      }}
                    />
                    {showDeleteContext?.isShow && showDeleteContext?.rowId === Number(row?.id) && (
                      <A1TagDeletePaper
                        onDelete={() => {
                          deletePlayerByAvangardId({
                            isMainWindow: false,
                            isHomeTeam: false,
                            payload: payload,
                            avangardId: row?.original?.avangardId,
                          });
                          setShowDeleteContext({...showDeleteContext, isShow: false});
                        }}
                      />
                    )}
                  </>
                );
              }}
            />
          );
        },
        cellStyle: {width: 145, maxWidth: 145},
      },
      {
        accessorKey: 'firstName',
        header: 'NAME',
        headerStyle: {width: 115, maxWidth: 115},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={getValue()}
              name={`awayTeamPlayers.${row.id}.firstName`}
              render={({field: {onChange, value}}) => {
                return (
                  <TextField
                    sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 115, maxWidth: 115},
      },
      {
        accessorKey: 'hockeyRole',
        header: 'POSITION',
        headerStyle: {width: 130, maxWidth: 130},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === getValue())?.value}
              name={`awayTeamPlayers.${row.id}.hockeyRole`}
              render={({field: {onChange, value}}) => {
                return (
                  <Autocomplete
                    disableClearable={true}
                    multiple={false}
                    options={HOCKEY_ROLES_EN_OPTIONS}
                    sx={{'.MuiAutocomplete-input': {color: white.main}}}
                    value={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === value)}
                    onChange={(_, newValue) => {
                      onChange(newValue?.value);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 140, maxWidth: 140},
        sortingFn: sortPositionColumn,
      },
      {
        accessorKey: 'hand',
        header: 'HAND',
        headerStyle: {width: 88, maxWidth: 88},
        cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
          return (
            <Controller
              defaultValue={HAND_OPTIONS_EN?.find((option) => option.value === getValue())?.value}
              name={`awayTeamPlayers.${row.id}.hand`}
              render={({field: {onChange, value}}) => {
                return (
                  <Autocomplete
                    disableClearable={true}
                    multiple={false}
                    options={HAND_OPTIONS_EN}
                    sx={{'.MuiAutocomplete-input': {color: white.main}}}
                    value={HAND_OPTIONS_EN?.find((option) => option.value === value)}
                    onChange={(_, newValue) => {
                      onChange(newValue?.value);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                );
              }}
            />
          );
        },
        cellStyle: {width: 88, maxWidth: 88},
      },
    ],
    [payload?.awayTeam?.players, showDeleteContext?.isShow],
  );

  const [isChoosingTeamShowed, setIsChoosingTeamShowed] = useState({isHomeTeam: true, isShowed: false});
  const handleChoosingTeamOpen = ({isHomeTeam}: {isHomeTeam: boolean}) =>
    setIsChoosingTeamShowed({isHomeTeam, isShowed: true});
  const hasEditIcon = isSelectedTeamHome
    ? payload?.homeTeam?.players?.length > 0
    : payload?.awayTeam?.players?.length > 0;

  const memoHomeTeamPlayers = useDeepMemo(() => {
    return !isLoading ? payload?.homeTeam?.players : [];
  }, [payload?.homeTeam?.players, isLoading]);

  const memoAwayTeamPlayers = useDeepMemo(() => {
    return !isLoading ? payload?.awayTeam?.players : [];
  }, [payload?.awayTeam?.players, isLoading]);

  const [isHomeAuto, setIsHomeAuto] = useState(false);
  const [isAwayAuto, setIsAwayAuto] = useState(false);

  const randomPlayers = [...Array(23).fill({})].map((_, index) => {
    const indexesForGoaltenders = [0, 1, 2];
    const hockeyRolesWithoutGoaltenders = Object.keys(HOCKEY_ROLES).filter((key) => key !== 'goaltender');
    const lastIndexOfHomeAuto = 23;
    return {
      id: Math.floor(Math.random() * Date.now()).toString(12),
      firstName: `Name${index + 1}`,
      lastName: `Surname${index + 1}`,
      playerNumber: `${index + 1}`,
      hockeyRole: indexesForGoaltenders.includes(index)
        ? 'goaltender'
        : hockeyRolesWithoutGoaltenders[Math.floor(Math.random() * hockeyRolesWithoutGoaltenders.length)],
      hand: Object.keys(HAND_MAP)[Math.floor(Math.random() * Object.keys(HAND_MAP).length)],
      avangardId: isHomeAuto ? index.toString() : (lastIndexOfHomeAuto + index).toString(),
      group: {name: isHomeAuto ? homeTeamName : awayTeamName},
    };
  });

  useEffect(() => {
    if (isHomeAuto) {
      updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, name: 'Team №1', players: randomPlayers}});
      editTeamRoasterForm.setValue('homeTeamPlayers', randomPlayers);
    } else {
      updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, name: 'Team №1', players: []}});
      editTeamRoasterForm.setValue('homeTeamPlayers', []);
    }
  }, [isHomeAuto]);

  useEffect(() => {
    if (isAwayAuto) {
      updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, name: 'Team №2', players: randomPlayers}});
      editTeamRoasterForm.setValue('awayTeamPlayers', randomPlayers);
    } else {
      updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, name: 'Team №2', players: []}});
      editTeamRoasterForm.setValue('awayTeamPlayers', []);
    }
  }, [isAwayAuto]);

  const isHomeAutoSwitchDisabled = !isHomeAuto && memoHomeTeamPlayers?.length > 0;

  const isAwayAutoSwitchDisabled = !isAwayAuto && memoAwayTeamPlayers?.length > 0;

  const [chooseColor, setChooseColor] = useState({isShowed: false, isHomeTeam: isSelectedTeamHome, anchorEl: null});

  useEffect(() => {
    updateChooseTeamPayload({
      ...payload,
      homeTeam: {...payload?.homeTeam, color: secondary.dark.dark},
      awayTeam: {...payload?.awayTeam, color: secondary.blue.dark},
    });
  }, []);

  useEffect(() => {
    editTeamRoasterForm.setValue('homeTeamColor', payload?.homeTeam?.color);
    editTeamRoasterForm.setValue('awayTeamColor', payload?.awayTeam?.color);
  }, [payload?.homeTeam?.color, payload?.awayTeam?.color]);

  useDeepEffect(() => {
    editTeamRoasterForm.setValue('homeTeamPlayers', payload?.homeTeam?.players);
    editTeamRoasterForm.setValue('awayTeamPlayers', payload?.awayTeam?.players);
  }, [payload?.homeTeam?.players, payload?.awayTeam?.players]);

  const handleChooseColorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setChooseColor({...chooseColor, anchorEl: event.currentTarget});
  };

  const [sorting, setSorting] = useState([{id: 'playerNumber', desc: true}]);

  return (
    <>
      <Grid item pr={isSelectedTeamHome && 7} xs={6}>
        <TeamHead>
          <Box alignItems="center" display="flex">
            <Box
              height={18}
              sx={{
                position: 'relative',
                display: 'block',
                mr: 1,
                backgroundColor: isSelectedTeamHome ? payload?.homeTeam?.color : payload?.awayTeam?.color,
                borderRadius: '50%',
              }}
              width={18}
              onClick={handleChooseColorClick}
            />
            <Typography color={black[60]} variant="h2">
              {isSelectedTeamHome ? homeTeamName : awayTeamName}
            </Typography>
            {hasEditIcon && (
              <IconButton
                size="small"
                sx={{ml: 3}}
                onClick={() => {
                  handleChoosingTeamOpen({isHomeTeam: isSelectedTeamHome});
                }}
              >
                <ModeEditOutlineRounded />
              </IconButton>
            )}
            <A1TagChooseColorPopover chooseColor={chooseColor} setChooseColor={setChooseColor} />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={isSelectedTeamHome ? isHomeAuto : isAwayAuto}
                  color="primary"
                  disabled={isSelectedTeamHome ? isHomeAutoSwitchDisabled : isAwayAutoSwitchDisabled}
                  size="small"
                  onChange={() => {
                    if (isSelectedTeamHome) {
                      setIsHomeAuto(!isHomeAuto);
                    } else {
                      setIsAwayAuto(!isAwayAuto);
                    }
                  }}
                />
              }
              label="Auto"
            />
            <IconButton size="small">
              <SettingsRounded />
            </IconButton>
          </Box>
        </TeamHead>
        {isSelectedTeamHome ? (
          <>
            {!payload?.homeTeam?.players?.length ? (
              <Box
                alignItems="center"
                display="flex"
                height="calc(100vh - 270px)"
                justifyContent="center"
                sx={{backgroundColor: black[12], border: `1px solid ${black[20]}`, borderRadius: 2}}
                width="100%"
              >
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    handleChoosingTeamOpen({isHomeTeam: true});
                  }}
                >
                  Choose a team
                </Button>
              </Box>
            ) : (
              <A1TagRostersTable
                columns={homeTeamTableColumns}
                data={memoHomeTeamPlayers}
                isAddingButtonsDisabled={isHomeAuto}
                isHomeTeam={true}
                setSorting={setSorting}
                sorting={sorting}
              />
            )}
          </>
        ) : (
          <>
            {!payload?.awayTeam?.players?.length ? (
              <Box
                alignItems="center"
                display="flex"
                height="calc(100vh - 270px)"
                justifyContent="center"
                sx={{backgroundColor: black[12], border: `1px solid ${black[20]}`, borderRadius: 2}}
                width="100%"
              >
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    handleChoosingTeamOpen({isHomeTeam: false});
                  }}
                >
                  Choose a team
                </Button>
              </Box>
            ) : (
              <A1TagRostersTable
                columns={awayTeamTableColumns}
                data={memoAwayTeamPlayers}
                isAddingButtonsDisabled={isAwayAuto}
                isHomeTeam={false}
                setSorting={setSorting}
                sorting={sorting}
              />
            )}
          </>
        )}
      </Grid>
      <A1TagChooseTeamModal
        editTeamRoasterForm={editTeamRoasterForm}
        isChoosingTeamShowed={isChoosingTeamShowed}
        isSelectedTeamHome={isSelectedTeamHome}
        setIsAwayAuto={setIsAwayAuto}
        setIsChoosingTeamShowed={setIsChoosingTeamShowed}
        setIsHomeAuto={setIsHomeAuto}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
