import {HOT_KEYS} from './constants';

const getKeys = (): {key: string; slug: string}[] => {
  const keys = window.localStorage.getItem('a1TagVideoPlayerHotKeys') ?? '[]';
  return JSON.parse(keys);
};

export const getKeyBySlug = (slug: string) => {
  const keys = getKeys();
  const findedKey = keys.find((item: {key: string; slug: string}) => item.slug === slug);
  const defaultKey = HOT_KEYS.find((item) => item.slug === slug);

  return (
    findedKey ?? {
      key: defaultKey?.defaultKey,
      slug,
    }
  );
};

export const updateKey = (key: string, slug: string) => {
  const keys = getKeys();
  const updatedKeys = keys.map((item: {key: string; slug: string}) => {
    if (item.slug === slug) {
      return {...item, key};
    }
    return item;
  });

  const isKeyExists = updatedKeys.find((item: {key: string; slug: string}) => item.slug === slug);

  window.localStorage.setItem(
    'a1TagVideoPlayerHotKeys',
    JSON.stringify(isKeyExists ? updatedKeys : [...updatedKeys, {key, slug}]),
  );
};
