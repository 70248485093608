import {a1tagViewModel, updateCleanTime} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {
  onTimeChange,
  pause,
  play,
  playerViewModel,
} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {Box} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import React, {memo, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';

import {useVideoPlayerHotkeys} from './hooks/use-video-player-hootkeys';
import {VideoUpload} from './video-upload.component';

const useResize = (ref: {current?: HTMLElement}, options: {step?: number; axis?: string}) => {
  ref = ref || {};
  const {step = 1, axis = 'both'} = options || {};
  const [coords, setCoords] = React.useState({x: Infinity, y: Infinity});
  const [dims, setDims] = React.useState({width: Infinity, height: Infinity});
  const [size, setSize] = React.useState({width: Infinity, height: Infinity});

  const initResize = (event: {clientX: number; clientY: number}) => {
    if (!ref.current) return;
    setCoords({x: event.clientX, y: event.clientY});
    const {width, height} = window.getComputedStyle(ref.current);
    setDims({width: parseInt(width, 10), height: parseInt(height, 10)});
  };

  useEffect(() => {
    const getValue = (value: number) => Math.ceil(value / step) * step;

    const doDrag = (event: {clientX: number; clientY: number}) => {
      if (!ref.current) return;

      const width = getValue(dims.width + event.clientX - coords.x);
      const height = getValue(dims.height + event.clientY - coords.y);

      if (axis === 'both') {
        ref.current.style.width = width + 'px';
        ref.current.style.height = height + 'px';
      }
      if (axis === 'horizontal') ref.current.style.width = width + 'px';
      if (axis === 'vertical') ref.current.style.height = height + 'px';
      setSize({width, height});
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', doDrag, false);
      document.removeEventListener('mouseup', stopDrag, false);
    };

    document.addEventListener('mousemove', doDrag, false);
    document.addEventListener('mouseup', stopDrag, false);
  }, [dims, coords, step, ref, axis]);

  return {initResize, size};
};

export const A1TagVideoPlayer = memo(() => {
  useSignals();
  useVideoPlayerHotkeys();
  const isFileExists = playerViewModel.file.value !== null;
  const onReady = (player: ReactPlayer) => {
    playerViewModel.player = player;
    playerViewModel.isReady.value = true;
  };

  const onDurationChange = (state: OnProgressProps) => {
    const prevState = playerViewModel.currentVideoTime.value;
    const differenceBetweenPrevAndNextState =
      state.playedSeconds > prevState ? state.playedSeconds - prevState : prevState - state.playedSeconds;
    if (!a1tagViewModel.isGameStopped.value) {
      const newCleanTime =
        state.playedSeconds > prevState
          ? a1tagViewModel.cleanTime.value + differenceBetweenPrevAndNextState
          : a1tagViewModel.cleanTime.value - differenceBetweenPrevAndNextState;
      updateCleanTime(newCleanTime);
    }
    onTimeChange(state.playedSeconds);
  };

  const onPlay = () => {
    play();
  };

  const onPause = () => {
    pause();
  };

  const resizableBlockRef = useRef<HTMLElement>(null);

  const {initResize} = useResize(resizableBlockRef, {step: 40, axis: 'vertical'});

  return isFileExists ? (
    <Box id="resizable" ref={resizableBlockRef} sx={{position: 'relative'}}>
      <ReactPlayer
        controls
        height="100%"
        playing={false}
        progressInterval={500}
        url={playerViewModel.file.value}
        width="100%"
        onPause={onPause}
        onPlay={onPlay}
        onProgress={onDurationChange}
        onReady={onReady}
      />
      <Box
        id="resizer"
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          display: 'inline-block',
          borderBottom: '0 solid transparent',
          borderRight: `15px solid ${white.main}`,
          borderTop: '15px solid transparent',
          background: black[10],
          width: 0,
          height: 0,
          cursor: 'ns-resize',
          zIndex: 10,
        }}
        onMouseDown={initResize}
      ></Box>
    </Box>
  ) : (
    <VideoUpload />
  );
});

A1TagVideoPlayer.displayName = 'A1TagVideoPlayer';
