import TeamCards from '@components/apps/FunctionalTesting/components/TeamCards';
import LocalPreloader from '@components/preloader/LocalPreloader';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {selectGeneralState} from '@selectors/functionalTesting';
import {getFunctionalTestingGeneralState} from '@slices/functionalTesting';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';

import {LocalPreloaderWrapper} from './FunctionalTesting';

const FunctionalTesting = () => {
  const {items, isLoading} = useStore(selectGeneralState);
  const dispatch = useStoreDispatch();
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getFunctionalTestingGeneralState());
  }, []);

  const tabs = Object.keys(items)?.map((schoolName, index) => {
    return {
      label: schoolName,
      value: <TeamCards cards={items[schoolName]} key={index} schoolName={schoolName} />,
    };
  });

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
        ]}
        className="PageTitleDefault"
      >
        Функциональное тестирование
      </PageTitleDefault>
      {isLoading ? (
        <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
          <LocalPreloader visible={true} />
        </LocalPreloaderWrapper>
      ) : (
        <>
          {!isSmResolution ? (
            Object.keys(items)?.map((schoolName, index) => (
              <TeamCards cards={items[schoolName]} key={index} schoolName={schoolName} />
            ))
          ) : (
            <TabsNew tabs={tabs} />
          )}
        </>
      )}
    </>
  );
};

export default FunctionalTesting;
