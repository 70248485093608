import {TextField} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

import CommonHelper from '../../../helpers/CommonHelper';

export default function UpdateSchoolForm(props) {
  let {addModalVisible, setAddModalVisible, onSubmit = () => {}, loading = false, canEdit = true} = props;

  const [name, setName] = useState(!props.name ? '' : props.name);
  const [city, setCity] = useState(!props.city ? '' : props.city);
  const [address, setAddress] = useState(!props.address ? '' : props.address);
  const [description, setDescription] = useState(!props.description ? '' : props.description);
  const [teachersIds, setTeachersIds] = useState(!props.teachersIds ? [] : props.teachersIds);

  const {allTrainers, selectedTeachers} = useSelector((state) => {
    const trainers = state.users.usersMap.toArray().filter((a) => a.userRole === 'teacher');

    const selectedTeachers = trainers
      .map((trainer) => {
        if (teachersIds.indexOf(trainer.id) > -1) {
          return trainer;
        }
      })
      .filter((el) => el !== null);

    return {
      allTrainers: trainers ? trainers : [],
      selectedTeachers: selectedTeachers,
    };
  }, shallowEqual);

  return (
    <DrawerDefault
      buttons={
        <ButtonsWrapper>
          <StyledButton
            color="primary"
            size="medium"
            variant="contained"
            onClick={() => {
              if (loading === true) {
                return;
              }
              onSubmit({name, description, city, address, teachersIds: teachersIds});
            }}
          >
            Сохранить
          </StyledButton>
          <StyledButton
            color="neutral"
            size="dark"
            variant="contained"
            onClick={() => {
              setAddModalVisible({...addModalVisible, right: false});
            }}
          >
            Отменить
          </StyledButton>
        </ButtonsWrapper>
      }
      content={
        <Wrapper>
          <ModalTitle>Добавление новой школы</ModalTitle>
          <Field>
            <Label>Название</Label>
            {canEdit ? (
              <TextField
                value={name}
                variant="outlined"
                onChange={(evt) => {
                  setName(evt.target.value);
                }}
              />
            ) : (
              <div>{name}</div>
            )}
          </Field>

          <Field>
            <Label>Город</Label>
            {canEdit ? (
              <TextField
                value={city}
                variant="outlined"
                onChange={(evt) => {
                  setCity(evt.target.value);
                }}
              />
            ) : (
              <div>{city}</div>
            )}
          </Field>

          <Field>
            <Label>Адрес</Label>
            {canEdit ? (
              <TextField
                value={address}
                variant="outlined"
                onChange={(evt) => {
                  setAddress(evt.target.value);
                }}
              />
            ) : (
              <div>{address}</div>
            )}
          </Field>

          <Field>
            <Label>Описание</Label>
            {canEdit ? (
              <TextField
                multiline
                rows={3}
                value={description}
                variant="outlined"
                onChange={(evt) => {
                  setDescription(evt.target.value);
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
            )}
          </Field>

          <Field>
            <Label>Тренеры</Label>
            {canEdit ? (
              <Select
                isMulti={true}
                isSearchable={true}
                options={CommonHelper.getDropDownItemsByUsers(allTrainers)}
                placeholder={'Выбрать тренеров'}
                value={CommonHelper.getDropDownItemsByUsers(selectedTeachers)}
                onChange={(a) => {
                  setTeachersIds(a?.map((aa) => aa.value));
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
            )}
          </Field>
        </Wrapper>
      }
      drawerState={addModalVisible}
      setDrawerState={setAddModalVisible}
    />
  );
}

const Wrapper = styled.div``;

const ModalTitle = styled.p`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #2c2c2c;
  margin: 0;
  margin-bottom: 24px;
`;

const Field = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(ButtonDefault)`
  margin-top: 12px !important;
  margin-bottom: 16px !important;
`;
