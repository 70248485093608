import VideoList from '@components/apps/TechnicalSkillsVideoEstimation/components/VideoList';
import Button from '@ui/MUI/Button';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React from 'react';
import {useHistory} from 'react-router-dom';

function VideoAnalitycs() {
  const history = useHistory<'/'>();

  return (
    <>
      <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between" mb={4}>
        <Typography variant="h2">Видеоаналитика</Typography>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            history.push('/video');
          }}
        >
          Все видео
        </Button>
      </Stack>
      <VideoList isDashboard isPersonalFile isStudent />
    </>
  );
}

export default VideoAnalitycs;
