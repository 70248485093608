import {VideoPlayer} from '@common/VideoPlayer';
import {Box} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import {bool, func, string} from 'prop-types';
import React from 'react';

HighlightVideoModal.propTypes = {
  url: string.isRequired,
  onClose: func.isRequired,
  isOpen: bool,
};

function HighlightVideoModal({onClose = () => {}, url = '', isOpen = false}) {
  return (
    <Drawer open={isOpen} title="Хайлайты" onClose={onClose}>
      <Box borderRadius="6px" mt={6} overflow="hidden">
        <VideoPlayer
          controls
          light
          playing
          config={{
            youtube: {
              playerVars: {
                version: 3,
                showinfo: 0,
                modestbranding: 1,
                controls: 1,
              },
            },
          }}
          url={url}
          width="100%"
        />
      </Box>
    </Drawer>
  );
}

export default HighlightVideoModal;
