import {createGroup} from '@components/modules/group/constants/group.mock';
import {Group} from '@components/modules/group/types/group.types';
import {Season} from '@components/modules/season/types/season.type';
import {ColumnDef} from '@tanstack/react-table';

export interface GameReport {
  id: string;
  season: Season;
  date: string;
  team: Group;
  opponent: string;
  score: string;
  comment: string;
}

export const gamesReportMock: GameReport[] = [
  {
    id: '1',
    season: {
      id: '24-25',
      name: '24-25',
      startDate: '2024-09-01',
      endDate: '2025-08-31',
    },
    date: '2024-10-10',
    team: createGroup(),
    opponent: 'Юниоры',
    score: '1:0',
    comment: 'Хорошая игра',
  },
];

export const gamesReportTableColumns: ColumnDef<GameReport>[] = [
  {
    accessorKey: 'season.name',
    header: 'Сезон',
  },
  {
    accessorKey: 'date',
    header: 'Дата',
  },
  {
    accessorKey: 'group.name',
    header: 'Команда',
  },
  {
    accessorKey: 'opponent',
    header: 'Соперник',
  },
  {
    accessorKey: 'score',
    header: 'Счёт',
  },
  {
    accessorKey: 'comment',
    header: 'Комментарий',
  },
];
