import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Autocomplete, Box, Button, IconButton, TextField} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import DateTimePicker from '@ui/MUI/DateTimePicker';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {NOTIFICATION_TYPES} from '../../../constants/config';
import {generateId} from '../../../helpers/MedicalProfileHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

export default function Consultation() {
  const medicalProfileForm = useSelector((state) => state.medicalProfile?.medicalProfileForm || {});
  const [typesOptions, setTypesOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    MedicalProfileAPI.getConsultationRecommendations()
      .then((data) => {
        setTypesOptions(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке рекомендаций к тренировкам');
      });
  }, []);

  typesOptions.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  const handleDeleteConsultation = (consultationId) => {
    if (!consultationId) return;

    let cloneConsultations = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    cloneConsultations.splice(
      cloneConsultations.findIndex((i) => i.idFront === consultationId),
      1,
    );

    currentMedicalProfileForm.consultations = cloneConsultations;
    dispatch(handleChangeForm(currentMedicalProfileForm));
  };

  const handleAddConsultation = () => {
    const cloneConsultation = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        consultations: [
          ...cloneConsultation,
          {
            idFront: generateId(),
            type: '',
            date: '',
            time: '',
            comments: '',
          },
        ],
      }),
    );
  };
  const handleConsultation = (consultationsId, name, value) => {
    const cloneConsultation = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        consultations: cloneConsultation.map((item) => {
          if (item.idFront === consultationsId) return {...item, [name]: value};
          return item;
        }),
      }),
    );
  };

  const selectedDate = (date, id) => {
    if (date) {
      return new Date(date);
    } else {
      handleConsultation(id, 'date', new Date().getTime());
      return new Date();
    }
  };

  return (
    <Box py={3} sx={{pb: {xxs: '120px', sm: 3}}}>
      <Title>Рекомендации по посещению/консультированию</Title>

      <RecommendationContainer>
        {medicalProfileForm.consultations
          ? medicalProfileForm.consultations.map((consultation, index) => {
              return (
                <Recommendation key={index}>
                  <Field>
                    <Autocomplete
                      error={!typesOptions.filter((option) => option.value === consultation.type)[0]}
                      helperText={
                        !typesOptions.filter((option) => option.value === consultation.type)[0] &&
                        'Обязательное поле для заполнения'
                      }
                      label="Тип рекомендации"
                      multiple={false}
                      options={typesOptions}
                      placeholder="Выберите тип рекомендации"
                      value={
                        typesOptions.filter((option) => option.value === consultation.type).length
                          ? typesOptions.filter((option) => option.value === consultation.type)[0]
                          : ''
                      }
                      onChange={(_, type) => {
                        handleConsultation(consultation.idFront, 'type', type?.value);
                      }}
                    />
                  </Field>
                  <FieldSmall>
                    <DateTimePicker
                      InputProps={{sx: {minHeight: 38}}}
                      inputFormat="DD.MM.YYYY HH:mm"
                      label="Дата"
                      value={selectedDate(consultation.date, consultation.idFront)}
                      onChange={(newDate) => {
                        handleConsultation(consultation.idFront, 'date', new Date(newDate).getTime());
                      }}
                    />
                  </FieldSmall>
                  <FieldMiddle style={{paddingRight: 100}}>
                    <TextField
                      InputProps={{sx: {minHeight: 38}}}
                      error={!consultation.comments}
                      helperText={!consultation.comments && 'Обязательное поле для заполнения'}
                      label="Комментарий"
                      multiline={true}
                      value={consultation.comments ? consultation.comments : ''}
                      onChange={(evt) => {
                        handleConsultation(consultation.idFront, 'comments', evt.target.value);
                      }}
                    />
                  </FieldMiddle>
                  <IconButton
                    className="DeleteIcon"
                    onClick={() => {
                      handleDeleteConsultation(consultation.idFront);
                    }}
                  >
                    <HighlightOffIcon color="primary" />
                  </IconButton>
                </Recommendation>
              );
            })
          : null}
        <FieldRecommendation>
          <Button
            startIcon={<AddIcon />}
            sx={{mt: 2, borderColor: grey[8], color: grey[54]}}
            onClick={() => handleAddConsultation()}
          >
            Добавить рекомендацию к посещению
          </Button>
        </FieldRecommendation>
      </RecommendationContainer>
    </Box>
  );
}

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const RecommendationContainer = styled.div`
  margin: 0 -20px;
`;
const Recommendation = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .DeleteIcon {
    position: absolute;
    top: 32px;
    right: 32px;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      right: 0;
    }
    @media screen and (max-width: 768px) {
      top: auto;
      bottom: 20px;
      right: -24px;
    }
  }
`;
const Field = styled.div`
  margin-top: 12px;
  margin-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 30%;
  max-width: 30%;

  @media (min-width: 769px) and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const FieldRecommendation = styled(Field)`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const FieldMiddle = styled(Field)`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const FieldSmall = styled(Field)`
  flex: 0 0 20%;
  max-width: 20%;
  .react-datepicker__time-list-item--selected {
    background-color: #e12 !important;
  }
  @media screen and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;
