import {ConsolationStages, StagesEnum} from '@src/types/tournament';

export const playOffStages = {
  [StagesEnum.SIXTEENTH]: '1/16 финала',
  [StagesEnum.EIGHTH]: '1/8 финала',
  [StagesEnum.FOURTH]: '1/4 финала',
  [StagesEnum.HALF]: '1/2 финала',
  [StagesEnum.FINAL]: 'Финал',
};

export const consolationMatchesStages = {
  [ConsolationStages.fiveEight]: 'за 5-8 место',
  [ConsolationStages.seventh]: 'за 7 место',
  [ConsolationStages.fifth]: 'за 5 место',
};
