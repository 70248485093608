import * as usersActions from '@actions/UsersActions';
import {uploadImg} from '@api/upload';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import React, {useState} from 'react';

import ImageCropPanel from './ImageCropPanel';

interface ModalCropUploadPanelProps {
  cropModalState: boolean;
  onClose: () => void;
}

export default function ModalCropUploadPanel({cropModalState = false, onClose = () => {}}: ModalCropUploadPanelProps) {
  const dispatch = useStoreDispatch();
  const currentUserId = useStore((state) => state?.users?.currentUser?.id);
  const [cropFormData, setCropFormData] = useState(null);
  return (
    <DialogDefault
      content={
        <ImageCropPanel
          onChange={(fd: string) => {
            setCropFormData(fd);
          }}
        />
      }
      open={cropModalState}
      title="Выберите изображение"
      onActionButtonClick={() => {
        if (!cropFormData) return;
        uploadImg(cropFormData).then((d) => {
          dispatch(usersActions.updateUser({id: currentUserId, avatar: d.data.url || d.data.path})).then(() => {});
        });
      }}
      onCloseDefault={onClose}
    />
  );
}
