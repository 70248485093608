import {useTimer} from '@components/A1TagEventModal/hooks';
import {A1TagRoastersTeamBlock} from '@components/A1TagRoastersTeamBlock';
import {A1TagLayout} from '@components/modules/A1TagLayout';
import {CompareArrowsOutlined} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {HockeyRoles} from '@src/types/student';
import {Box, Button, Grid, IconButton} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory} from 'react-router';

import {Roasters} from '../A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  chooseTeamModalViewModel,
  cleanUpStatistics,
  updateRoasters,
} from '../A1TagMainWindow/viewmodels/a1tag.view-model';
import {Container, TablesContainer, Team} from './A1TagAddingRoasters';

export type Player = {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  playerNumber: string;
  cityOfBirth: string;
  hockeyRole: string;
  hand: string;
  status: string;
  subStatus: string;
  birthdayTimestamp: number;
  group: {
    id: string;
    name: string;
    description: string;
    studentsCount: number;
    birthYear: string;
    ageGroup: string;
    avangardId: number;
  };
  avangardId: string;
  onIce?: boolean;
  position?: string;
};
type EditTeamRoasterFormType = {
  homeTeamColor?: string;
  awayTeamColor?: string;
  homeTeamName?: string;
  awayTeamName?: string;
  homeTeamPlayers: {
    fieldPlayers: Player[];
    goalies: Player[];
  };
  awayTeamPlayers: {
    fieldPlayers: Player[];
    goalies: Player[];
  };
};

export function A1TagAddingRosters() {
  useSignals();
  const editTeamRoasterForm = useForm();

  const history = useHistory();

  const payload = chooseTeamModalViewModel.payload?.value ?? {};

  const {resetTimer} = useTimer();

  const handleEditTeamRoasterFormSubmit = (form: EditTeamRoasterFormType) => {
    const formattedForm: Roasters = {
      homeTeam: {
        color: form.homeTeamColor,
        name: form.homeTeamName,
        players: {
          fieldPlayers: form.homeTeamPlayers
            .filter((player: Player) => player.hockeyRole !== HockeyRoles.goaltender)
            .map((player: Player, index: number) => {
              return {...player, onIce: index <= 4};
            }),
          goalies: form.homeTeamPlayers
            .filter((player: Player) => player.hockeyRole === HockeyRoles.goaltender)
            .map((player: Player, index: number) => {
              return {...player, onIce: index === 0};
            }),
        },
      },
      awayTeam: {
        color: form.awayTeamColor,
        name: form.awayTeamName,
        players: {
          fieldPlayers: form.awayTeamPlayers
            .filter((player: Player) => player.hockeyRole !== HockeyRoles.goaltender)
            .map((player: Player, index: number) => {
              return {...player, onIce: index <= 4};
            }),
          goalies: form.awayTeamPlayers
            .filter((player: Player) => player.hockeyRole === HockeyRoles.goaltender)
            .map((player: Player, index: number) => {
              return {...player, onIce: index === 0};
            }),
        },
      },
    };
    updateRoasters(formattedForm);
    cleanUpStatistics();
    resetTimer();
    history.push('/a1tag');
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    setIsButtonDisabled(!payload?.homeTeam?.players?.length || !payload?.awayTeam?.players?.length);
  }, [payload?.homeTeam?.players?.length, payload?.awayTeam?.players?.length]);

  return (
    <A1TagLayout>
      <Container>
        <Team>
          <FormProvider {...editTeamRoasterForm}>
            <TablesContainer>
              <Grid container pb={5}>
                <A1TagRoastersTeamBlock editTeamRoasterForm={editTeamRoasterForm} isSelectedTeamHome={true} />
                <IconButton
                  size="small"
                  sx={{position: 'absolute', top: 60, right: '50%', transform: 'translateX(-40%)'}}
                >
                  <CompareArrowsOutlined />
                </IconButton>
                <A1TagRoastersTeamBlock editTeamRoasterForm={editTeamRoasterForm} isSelectedTeamHome={false} />
              </Grid>
              <Box
                sx={{
                  mb: -4,
                  mx: -4,
                  py: 4,
                  px: 5,
                  display: 'flex',
                  backgroundColor: black[12],
                  borderTop: `1px solid ${black[20]}`,
                }}
              >
                <Button
                  color="primary"
                  disabled={isButtonDisabled}
                  size="large"
                  sx={{ml: 'auto', width: 98}}
                  type="submit"
                  variant="contained"
                  onClick={editTeamRoasterForm.handleSubmit(handleEditTeamRoasterFormSubmit)}
                >
                  Enter
                </Button>
              </Box>
            </TablesContainer>
          </FormProvider>
        </Team>
      </Container>
    </A1TagLayout>
  );
}
