export enum StagesEnum {
  GROUP = 'group',
  HALF = '1/2',
  FOURTH = '1/4',
  EIGHTH = '1/8',
  SIXTEENTH = '1/16',
  FINAL = 'final',
}

export enum ConsolationStages {
  fiveEight = 'consolation/5-8',
  seventh = 'consolation/7',
  fifth = 'consolation/5',
}
