import moment from 'moment';

const date = (date, format) => {
  const mDate = moment(date, format);
  return {
    currentDate: mDate,
    format: (format = '') => {
      return mDate.format(format);
    },
    isBefore: (date, granularity) => {
      return mDate.isBefore(date, granularity);
    },
    isAfter: (date, granularity) => {
      return mDate.isAfter(date, granularity);
    },
    isSame: (date, granularity) => {
      return mDate.isSame(date, granularity);
    },
    isBetween: (dateOne, dateTwo, granularity) => {
      return mDate.isBetween(dateOne, dateTwo, granularity);
    },
    subtract: (unit, granularity) => {
      return mDate.subtract(unit, granularity);
    },
    valueOf: () => {
      return mDate.valueOf();
    },
    startOf: (unit) => {
      return mDate.startOf(unit);
    },
    endOf: (unit) => {
      return mDate.endOf(unit);
    },
    toDate: () => {
      return mDate.toDate();
    },
    clone: () => {
      return mDate.clone();
    },
    add: (amount, unit) => {
      return mDate.add(amount, unit);
    },
    utcOffset: (b, keepLocalTime) => {
      return mDate.utcOffset(b, keepLocalTime);
    },
    locale: (language) => {
      return mDate.locale(language);
    },
    diff: (b, unitOfTime, precise) => {
      return mDate.diff(b, unitOfTime, precise);
    },
    local: (keepLocalTime) => {
      return mDate.local(keepLocalTime);
    },
    utc: (keepLocalTime) => {
      return mDate.utc(keepLocalTime);
    },
    isValid: () => {
      return mDate.isValid();
    },
    hours: () => {
      return mDate.hours();
    },
    hour: () => {
      return mDate.hour();
    },
    minutes: () => {
      return mDate.minutes();
    },
    seconds: () => {
      return mDate.seconds();
    },
    isoWeek: (d) => {
      return mDate.isoWeek(d);
    },
    week: () => {
      return mDate.week();
    },
    toJSON: () => {
      return mDate.toJSON();
    },
    unix: () => {
      return mDate.unix();
    },
    daysInMonth: () => {
      return mDate.daysInMonth();
    },
    get: (unit) => {
      return mDate.get(unit);
    },
    set: (unit, value) => {
      return mDate.set(unit, value);
    },
    innerDate: mDate,
  };
};

date.months = () => {
  const start = moment().startOf('year');
  return new Array(12).fill(start).map((m, index) => {
    return m.clone().add('month', index);
  });
};

date.locale = (locale) => {
  return moment.locale(locale);
};

date.min = (moments) => {
  return moment.min(moments);
};

date.max = (moments) => {
  return moment.max(moments);
};

date.isMoment = (moment) => {
  return moment.isDate(moment);
};

date.unix = (timestamp) => {
  return moment.unix(timestamp);
};

date.tz = (timeZone) => {
  return moment.tz(timeZone);
};

date.duration = (inp, unit) => {
  return moment.duration(inp, unit);
};

date.weekdays = () => {
  return moment.weekdays();
};

date.utc = (keepLocalTime) => {
  return moment.utc(keepLocalTime);
};

date.generateMonthDays = (startDate = moment(), maxDays = 30) => {
  const start = moment(startDate).startOf('month').startOf('week');
  return new Array(maxDays).fill(start).map((date, index) => {
    const currentDate = moment(date).clone().add(index, 'day');
    const isSameMonth = currentDate.isSame(startDate, 'month');
    const isToday = currentDate.isSame(startDate, 'day');
    return {date: currentDate, isSameMonth, isToday};
  });
};

export default date;

/**
 * интерфейс для валидации даты на нахождение в интервале и на валидность даты
 * @param date дата для проверки
 * @param dateInterval ['date2', 'date1'] интервал нахождения даты (от и до)
 * @param format формат даты в каком формате валидировать
 * @param precision ['day', 'year'] точность в определении интервала (от и до)
 * @returns bool
 */
export function isDateValid(
  date,
  dateInterval = ['01.01.1990', moment()],
  format = 'DD.MM.YYYY',
  precision = ['year', 'year'],
) {
  if (dateInterval?.length < 2 && dateInterval?.length > 0) {
    console.error('dateInterval argument must have length equal to 2');
    return false;
  }
  const formatValue = moment(date, format, true);
  const isAfter = moment(date, format).isAfter(moment(dateInterval[0], format), precision[0]);
  const isBefore = moment(date, format).isBefore(moment(dateInterval[1], format), precision[1]);
  return isAfter && isBefore && formatValue.isValid();
}
