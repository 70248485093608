import {signal} from '@preact/signals-react';

import {GameReport} from '../constants/games-report-table.constant';

type DrawerType = 'create' | 'edit';
export const createDrawerModel = <T>() => {
  return {
    type: signal<DrawerType>('create'),
    _isOpen: signal(false),
    _payload: signal<T | null>(null),

    get isOpen() {
      return this._isOpen.value;
    },

    get payload() {
      return this._payload.value;
    },

    get isEdit() {
      return this.type.value === 'edit';
    },

    open(type: DrawerType, payload?: T) {
      this._isOpen.value = true;
      this.type.value = type;
      this._payload.value = payload ?? null;
    },
    close() {
      this._isOpen.value = false;
      this.type.value = 'create';
      this._payload.value = null;
    },
  };
};

export const reportDrawer = createDrawerModel<GameReport>();
