import {fiftyOneSecInMs, minuteInMs} from '@components/apps/StatsReportForOneGame/constants';
import {convertTimeMMSS} from '@helpers/dateHelper';
import {PERIOD_VALUES} from '@src/constants';
import {grey, yellow} from '@ui/MUI/colors';
import {black, yellowV2} from '@ui/MUI/colorsV2';
import * as echarts from 'echarts';
import {isEmpty} from 'lodash';

export const renderGoalTooltip = (item, players, teamNamesOurFirst) => {
  const [our, away] = teamNamesOurFirst;
  let result = `<span style="color:#fff;font-weight:600;">Гол ${item?.isOur ? our : away}</span><br>
Счет: <span style="color:#fff;">${item.currentScore}</span><br>
Время: <span style="color:#fff;">${convertTimeMMSS(item?.time)}</span>`;
  const currentPlayer = players?.find((player) => player?.value === item?.playerId);
  if (item?.isOur && !isEmpty(currentPlayer))
    result += `<br>Игрок: <span style="color:#fff;">${currentPlayer?.firstName || ''} ${
      currentPlayer?.lastName || ''
    }</span>
`;
  return result;
};

export function renderMarkArea(item, name, itemColor = '#E7E7E7') {
  return {
    0: {
      xAxis: item.start,
      itemStyle: {
        color: itemColor,
      },
      emphasis: {
        disabled: true,
      },
    },
    1: {
      xAxis: item.end,
      tooltip: {
        formatter: `<span style="color:#fff;">Игра в ${name}</span>
         <br> 
         Время: <span style="color:#fff;">${convertTimeMMSS(item.start)} - ${convertTimeMMSS(item.end)}</span>`,
      },
    },
  };
}

export function renderTimelineItem(params, api, shifts, isV2 = false, isElderAgeGroup, isSmallerAgeGroup) {
  const categoryIndex = api.value(0);
  const start = api.coord([api.value(1), categoryIndex]);
  const end = api.coord([api.value(2), categoryIndex]);
  const height = api.size([0, 1])[1] * 0.8;
  // eslint-disable-next-line import/namespace
  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height: height,
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    },
  );
  const currentItem = shifts[params.dataIndex];
  const shiftColorCondition = () => {
    if (isElderAgeGroup) {
      return currentItem.value[3] > fiftyOneSecInMs ? yellow[60] : grey[24];
    }
    if (isSmallerAgeGroup) {
      return currentItem.value[3] > minuteInMs ? yellow[60] : grey[24];
    }
    return currentItem.value[3] > minuteInMs ? yellow[60] : grey[24];
  };
  const shiftColorConditionV2 = () => {
    if (isElderAgeGroup) {
      return currentItem.value[3] > fiftyOneSecInMs ? yellowV2[100] : black[36];
    }
    if (isSmallerAgeGroup) {
      return currentItem.value[3] > minuteInMs ? yellowV2[100] : black[36];
    }
    return currentItem.value[3] > minuteInMs ? yellowV2[100] : black[36];
  };
  let itemColor = shiftColorCondition();
  if (currentItem?.goalWhenReplayingShift) itemColor = yellow[100];
  const itemColorV2 = shiftColorConditionV2();
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style({fill: isV2 ? itemColorV2 : itemColor, stroke: isV2 ? itemColorV2 : itemColor}),
    }
  );
}

export function calculateZoom(selectedValue) {
  const zoomParams = {
    startValue: null,
    endValue: null,
  };
  if (selectedValue !== PERIOD_VALUES.all && PERIOD_VALUES[selectedValue]) {
    zoomParams.startValue = selectedValue * (minuteInMs * 20) - minuteInMs * 20;
    zoomParams.endValue = selectedValue * (minuteInMs * 20);
  }
  if (selectedValue === PERIOD_VALUES.OT) {
    zoomParams.startValue = minuteInMs * 60;
    zoomParams.endValue = null;
  }

  return zoomParams;
}
