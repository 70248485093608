import eagle_bg from '@assets/images/eagle_bg.svg';
import {black} from '@ui/MUI/colorsV2';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${black['8']};
  //background-image: url(${eagle_bg});
  background-position: top right;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  ${({theme}) => [theme.breakpoints.down('md')]} {
    background-position: right 60px;
    flex-direction: column;
    justify-content: initial;
  }
  ${({theme}) => [theme.breakpoints.down('sm')]} {
    background-position: right 44px;
    flex-direction: column;
    justify-content: initial;
  }
  ${({theme}) => [theme.breakpoints.down('xs')]} {
    background-image: none;
  }
`;

const leftWidth = 240;

export const Left = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: ${leftWidth}px;
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  z-index: 1;
  flex-shrink: 0;
  ${({theme}) => [theme.breakpoints.down('md')]} {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
    height: 60px;
    width: 100%;
  }
  ${({theme}) => [theme.breakpoints.down('sm')]} {
    height: 44px;
  }
`;

export const Right = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  max-width: ${({theme}) => `calc(${theme.breakpoints.values?.xl}px - ${leftWidth}px)`};
  width: calc(100vw - ${leftWidth}px);
  box-sizing: border-box;
  padding: ${({theme}) => theme.spacing(6, 5, 5)};
  overflow-y: auto;

  ${({theme}) => theme.breakpoints.down('md')} {
    width: 100%;
    min-height: calc(100% - 60px);
  }

  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: ${({theme}) => theme.spacing(6, 3)};
    min-height: calc(100% - 44px);
  }

  ${({theme}) => theme.breakpoints.down('xs')} {
    padding: ${({theme}) => theme.spacing(2, '0')};
  }
`;
