import {Signal, signal} from '@preact/signals-react';
import ReactPlayer from 'react-player';

export interface PlayerViewModel {
  player: ReactPlayer | null;
  currentVideoTime: Signal<number>;
  file: Signal<string>;
  isPlaying: Signal<boolean>;
  isReady: Signal<boolean>;
}

export const playerViewModel: PlayerViewModel = {
  player: null,
  currentVideoTime: signal<number>(0),
  file: signal<string>(null),
  isPlaying: signal<boolean>(false),
  isReady: signal<boolean>(false),
};

export const seekTo = (time: number, type?: 'seconds' | 'fraction') => {
  playerViewModel.player.seekTo(time, type);
  pause();
};

export const onTimeChange = (time: number) => {
  playerViewModel.currentVideoTime.value = time;
};

export const uploadFile = (file: File) => {
  const url = URL.createObjectURL(file);

  playerViewModel.file.value = url;
};

export const togglePlayPause = () => {
  playerViewModel.isPlaying.value = !playerViewModel.isPlaying.value;
};

export const pause = () => {
  playerViewModel.isPlaying.value = false;
  // making sure that the player is paused
  const internalPlayer = playerViewModel?.player?.getInternalPlayer();
  if (internalPlayer) {
    internalPlayer.pause();
  }
};

export const play = () => {
  playerViewModel.isPlaying.value = true;
};
