import ReactTableComponent from '@common/ReactTableComponent';
import {defaultSort} from '@helpers/index';
import AddIcon from '@mui/icons-material/Add';
import {longDash, userRoles} from '@src/constants';
import {Button, Typography} from '@ui/MUI';
import Pagination from '@ui/MUI/Pagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import GroupsAPI from '../../../api/GroupsAPI';
import LocalPreloader from '../../preloader/LocalPreloader';
import CreateModal from '../components/group/modals/CreateModal';

function sortColumn(rowA, rowB, columnId) {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  return defaultSort(a, b);
}

export default function GroupsPanel() {
  const history = useHistory();

  const userRole = useSelector((state) => state.users.currentUser?.userRole);

  const [addModalState, setAddModalState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleAddModal = (anchor, open) => {
    setAddModalState({...addModalState, [anchor]: open});
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const [selectedCount, setSelectedCount] = useState(20);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState({result: [], total: 0});

  const getGroupsList = async () => {
    setIsLoading(true);
    const groups = await GroupsAPI.getAllGroupsV2({limit: selectedCount, page: selectedPage});
    setGroups({result: groups.data.result, total: groups.data.total});
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedPage) {
      getGroupsList();
    }
  }, [selectedPage]);

  let pagesCount = 1;
  const groupsCount = groups.total;
  if (groupsCount > 0) {
    pagesCount = Math.ceil(groupsCount / selectedCount);
  }
  const onPaginationChange = (event, value) => {
    setSelectedPage(value);
  };

  const formatGroupsForRows = (groups) => {
    if (groups?.length > 0) {
      return groups?.map((group) => {
        return {
          id: group.id,
          name: group.name,
          school: group.school?.name,
          studentsCount: group.studentsCount,
          ageGroup: group.ageGroup,
        };
      });
    }
  };

  const columns = [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Команда',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cell: ({getValue, row}) => {
        return (
          <Typography
            variant="subtitle2"
            onClick={() => {
              history.push(`/teams/${row.original.id}`);
            }}
          >
            {getValue() || longDash}
          </Typography>
        );
      },
      cellStyle: {
        fontWeight: 'bold !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
      },
      sortingFn: sortColumn,
    },
    {
      accessorKey: 'school',
      header: 'Школа',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cell: ({getValue}) => {
        return <Typography variant="body2">{getValue() || longDash}</Typography>;
      },
      cellStyle: {
        boxShadow: 'none !important',
      },
    },
    {
      accessorKey: 'studentsCount',
      header: 'Кол-во игроков',
      headerStyle: {
        boxShadow: 'none !important',
        textAlign: 'center',
      },
      cellStyle: {
        boxShadow: 'none !important',
        textAlign: 'center',
      },
      cell: ({getValue}) => {
        return <Typography variant="body2">{getValue() || 0}</Typography>;
      },
    },
    {
      accessorKey: 'ageGroup',
      header: 'Возрастная группа',
      headerStyle: {
        boxShadow: 'none !important',
        textAlign: 'center',
      },
      cellStyle: {
        boxShadow: 'none !important',
        textAlign: 'center',
      },
      cell: ({getValue}) => {
        return <Typography variant="body2">{getValue() || longDash}</Typography>;
      },
    },
  ];

  const [sorting, setSorting] = useState([{id: 'name', asc: true}]);

  return (
    <Wrapper>
      <PageTitleDefault text={'Команды'} />
      <TableWrapper loading={isLoading}>
        <LocalPreloader visible={isLoading} />
        <TableWrapperHeadline>
          <TableWrapperHeadlineTitle>Команды академии</TableWrapperHeadlineTitle>
          {![userRoles.video_analyst, userRoles.teacher].includes(userRole) && (
            <Button
              disableElevation
              color="primary"
              startIcon={<AddIcon />}
              text="Добавить команду"
              variant="contained"
              onClick={() => {
                toggleAddModal('right', true);
              }}
            >
              Добавить команду
            </Button>
          )}
        </TableWrapperHeadline>
        {groups?.result?.length && (
          <ReactTableComponent
            columns={columns}
            data={formatGroupsForRows(groups?.result)}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
        <CreateModal
          getGroupsList={getGroupsList}
          modalState={addModalState}
          setModalState={setAddModalState}
          toggleModal={toggleAddModal}
        />
        {pagesCount > 1 && <Pagination count={pagesCount} page={selectedPage} onChange={onPaginationChange} />}
      </TableWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const TableWrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  padding-top: 18px;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
const TableWrapperHeadline = styled.div`
  padding: 0 12px 20px;
  display: flex;
  justify-content: space-between;
`;
const TableWrapperHeadlineTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 18px/28px 'Proxima Nova';
  color: black;
`;
