import VideoModalTeamPick from '@common/Statistics/PlayerStatistics/VideoModalTeamPick';
import Buttons from '@common/Statistics/ThrowsMap/Buttons';
import PlayersFilter from '@common/Statistics/ThrowsMap/PlayersFilter';
import FieldScatterChart from '@components/apps/StatsReportForOneGame/components/FieldScatterChart';
import {shallowEqual} from '@helpers/shallowEqual';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {useMediaQuery} from '@mui/material';
import {selectShotsMapButtonTypes, selectShotsMapData} from '@selectors/gameStatistics';
import {setTeamActionsSide, setVideoModal} from '@slices/statistics';
import {clearButtonTypes, clearShotsMap, loadButtonTypes, loadShotsMap} from '@slices/statistics/shotsMap';
import {MAPS_SIZE} from '@src/constants/stitictics';
import {StudentSubroles} from '@src/types';
import {grey} from '@ui/MUI/colors';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {DATA_TYPES, ICON_TYPES} from '../../../../apps/StatsReportForOneGame/constants';
import {ThrowsCardWrapper} from './ThrowsCard';

const colors = {
  our: ['#83000E', '#EC6A6A', '#EB0C25'],
  away: [grey['78'], grey['38'], grey['54']],
};
const legendArr = [
  {name: 'наша команда', color: colors.our[2]},
  {name: 'соперник', color: grey['54']},
];

const reverseArr = (arr, condition) => (condition ? [...arr] : [...arr].reverse());
const setColor = (type, isOur) => {
  return colors[isOur ? 'our' : 'away'][type === DATA_TYPES.SG ? 2 : type === DATA_TYPES.GL ? 0 : 1];
};
const ThrowsCard = ({statisticsIds, filters, weAtHome, setModalState = () => {}}) => {
  const dispatch = useDispatch();
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [actionIds, setActionIds] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const {data, isLoading, isLoad, error} = useStore(selectShotsMapData, shallowEqual);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [actionIds, usersFilter]});
  useDeepEffect(() => {
    dispatch(
      loadShotsMap(
        {
          statisticsIds,
          ...filters,
          actionIds,
          playerIds: usersFilter?.map((item) => item?.avangardId),
          cancelToken: newCancelToken(),
        },
        isCancel,
      ),
    );
    return () => {
      dispatch(clearShotsMap());
    };
  }, [filters, statisticsIds, usersFilter, actionIds]);

  const {series, tooltip} = useDeepMemo(() => {
    const series = [
      {
        id: 1,
        name: legendArr[weAtHome ? 0 : 1].name,
        color: legendArr[weAtHome ? 0 : 1].color,
        symbolSize: (value, {data}) => (DATA_TYPES.GL.toLocaleLowerCase() === data?.type?.toLocaleLowerCase ? 35 : 20),
        data:
          data?.home?.map((item) => ({
            ...item,
            itemStyle: {color: setColor(item?.engNameShort, item?.isOur), opacity: 1},
            symbol: ICON_TYPES?.[item?.engNameShort || DATA_TYPES.SW],
            value: reverseArr([item.posX, item.posY], !isXsResolution),
            tooltip: {
              show: true,
            },
          })) || [],
        type: 'scatter',
        symbol: 'circle',
      },
      {
        id: 2,
        name: legendArr[!weAtHome ? 0 : 1].name,
        color: legendArr[!weAtHome ? 0 : 1].color,
        symbolSize: (value, {data}) => (DATA_TYPES.GL.toLocaleLowerCase() === data?.type?.toLocaleLowerCase ? 35 : 20),
        data:
          data?.away?.map((item) => ({
            ...item,
            itemStyle: {
              opacity: 1,
              color: setColor(item?.engNameShort, item?.isOur),
            },
            symbol: ICON_TYPES?.[item?.engNameShort || DATA_TYPES.SW] || 'circle',
            value: reverseArr([MAPS_SIZE.x - item.posX, MAPS_SIZE.y - item.posY], !isXsResolution),
            tooltip: {
              show: true,
            },
          })) || [],
        type: 'scatter',
        symbol: 'circle',
      },
    ];
    const tooltip = {
      backgroundColor: grey['68'],
      borderColor: grey['68'],
      enterable: true,
      show: true,
      triggerOn: 'click',
      extraCssText: 'text-align: left; border: none; overflow: hidden; min-width: 152px; width: fit-content',
      textStyle: {
        color: '#fff',
        fontSize: 12,
        fontFamily: 'Proxima Nova',
        fontWeight: 600,
      },
      formatter: ({data}) => {
        window.showGoals = function () {
          dispatch(setModalState({isOpen: true, data}));
        };

        return `
            <div style="padding-bottom: ${data?.linkToVideo ? '30px' : ''}">
                    <span style="color:${grey['14']};">Тип броска: </span><span>${data.name}${
                      data?.type?.name ? ` (${data?.type?.name})` : ''
                    }</span> </br>
                    <span style="color:${grey['14']};">Игрок: </span><span>${data?.playerName}</span> </br>
                    <span style="color:${grey['14']};">Время: </span><span>${data?.time}</span> </br>
                    ${
                      data?.linkToVideo
                        ? `<button
                              style="position: absolute;
                                       left: 0; bottom: 0;
                                       text-align: center;
                                       min-width: 100%;
                                       display: block;
                                       padding: 6px 8px;
                                       color: #fff;
                                       text-transform: uppercase;
                                       background-color:${grey['100']};
                                       border: none;
                                       font-weight: 600;
                                       line-height: 14px;
                                       cursor: ${data?.linkToVideo ? 'pointer' : 'not-allowed'};
                                       pointer-events: ${data?.linkToVideo ? 'all' : 'none'};"
                              onClick="showGoals()"
                            >
                              смотреть видео
                            </button>`
                        : ''
                    }</div>
    `;
      },
    };
    return {series, tooltip};
  }, [data]);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  return (
    <ThrowsCardWrapper>
      <PlayersFilter setUsersFilter={setUsersFilter} statisticsIds={statisticsIds} usersFilter={usersFilter} />
      <Buttons
        cleanup={clearButtonTypes}
        filters={actionIds}
        getter={selectShotsMapButtonTypes}
        loader={loadButtonTypes}
        setFilters={setActionIds}
      />
      <VideoModalTeamPick
        isV2={isAmateur}
        wrapperProps={{mt: 6}}
        onClick={(value) => {
          dispatch(setVideoModal({isOpen: true}));
          dispatch(setTeamActionsSide(value));
        }}
      />
      <FieldScatterChart
        error={error}
        isLoad={isLoad}
        isLoading={isLoading}
        legendData={{data: reverseArr(legendArr, weAtHome)}}
        series={series}
        tooltip={tooltip}
        weAtHome={weAtHome}
        yAxis={{max: MAPS_SIZE.y}}
      />
    </ThrowsCardWrapper>
  );
};

export default React.memo(ThrowsCard);
