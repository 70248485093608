import EstimateControlDate from '@common/DevelopmentJournal/components/EstimateControlDate';
import EstimateNumber from '@common/DevelopmentJournal/components/EstimateNumber';
import PlayerInfo from '@common/DevelopmentJournal/components/PlayerInfo';
import {longDash} from '@src/constants';
import {Button, Stack} from '@ui/MUI';
import React from 'react';
import {useHistory} from 'react-router-dom';

const ActionButton = ({student}) => {
  const history = useHistory();
  const view = () => {
    history.push(`/development_journal/player_skills/${student.id}?groupId=${student?.group?.id}`);
  };
  return (
    <Button color="primary" variant="text" onClick={view}>
      Смотреть
    </Button>
  );
};

export const columns = [
  {
    header: 'Игрок',
    accessorFn: (row) => row.student,
    headerStyle: {width: 250, maxWidth: 250},
    cellStyle: {width: 250, maxWidth: 250},
    cell: (info) => {
      const value = info.getValue();
      return (
        <PlayerInfo
          avatar={value?.avatar ?? ''}
          firstName={value.firstName}
          lastName={value.lastName}
          playerNumber={value.playerNumber}
        />
      );
    },
  },
  {
    header: 'Команда',
    headerStyle: {width: 200, maxWidth: 200},
    cellStyle: {width: 200, maxWidth: 200},
    accessorFn: (row) => row.student?.group?.name || longDash,
  },
  {
    header: 'Улучшаемый навык',
    accessorFn: (row) => row.skill.name,
  },
  {
    header: 'Текущий результат',
    accessorFn: (row) => row.score,
    headerStyle: {width: 200, maxWidth: 200},
    cellStyle: {width: 200, maxWidth: 200},
    cell: (info) => {
      const value = info.getValue();
      return (
        <Stack alignItems="center" justifyContent="center">
          <EstimateNumber isPrimary>{value}</EstimateNumber>
        </Stack>
      );
    },
  },
  {
    header: 'Целевой результат',
    accessorFn: (row) => row.expectedScore,
    headerStyle: {width: 200, maxWidth: 200},
    cellStyle: {width: 200, maxWidth: 200},
    cell: (info) => {
      const value = info.getValue();
      return (
        <Stack alignItems="center" justifyContent="center">
          <EstimateNumber isPrimary>{value}</EstimateNumber>
        </Stack>
      );
    },
  },
  {
    header: 'Дата контроля',
    accessorFn: (row) => row.controlDate,
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 150},
    cell: (info) => {
      const value = info.getValue();
      return <EstimateControlDate date={value} />;
    },
  },
  {
    id: 'action',
    header: '',
    accessorFn: (row) => row.student,
    isWithOutOverflow: true,
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 150},
    cell: (info) => {
      const value = info.getValue();
      return <ActionButton student={value} />;
    },
  },
];
