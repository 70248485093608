import React from 'react';

export const A1TAG_HORIZONTAL_ARENA_SCHEMA = (
  <svg fill="none" height="350" viewBox="0 0 651 350" width="651" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_291_22302)">
      <rect
        fill="#F1F1F3"
        height="348"
        rx="44"
        transform="rotate(-180 649.748 349)"
        width="648.748"
        x="649.748"
        y="349"
      />
      <rect
        height="348"
        rx="44"
        stroke="#4D4D4D"
        strokeWidth="2"
        transform="rotate(-180 649.748 349)"
        width="648.748"
        x="649.748"
        y="349"
      />
      <path d="M325.373 1.19209e-05L325.373 350" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M243.871 350L243.871 1.19209e-05" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M42.673 194.835C47.4832 194.835 52.096 192.746 55.497 189.026C58.898 185.307 60.8086 180.262 60.8086 175.002C60.8086 169.741 58.898 164.697 55.497 160.977C52.096 157.258 47.4832 155.168 42.673 155.168V194.835Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M608.076 194.835C603.266 194.835 598.653 192.746 595.252 189.026C591.851 185.307 589.941 180.262 589.941 175.002C589.941 169.741 591.851 164.697 595.252 160.977C598.653 157.258 603.266 155.168 608.076 155.168V194.835Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path d="M42.6719 348.226L42.6719 2.36418" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M406.877 350L406.877 1.19209e-05" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M109.836 274.323C107.227 274.323 105.112 272.206 105.112 269.593C105.112 266.981 107.227 264.864 109.836 264.864C112.445 264.864 114.561 266.981 114.561 269.593C114.561 272.206 112.445 274.323 109.836 274.323Z"
        fill="#999999"
      />
      <path
        d="M109.837 316.89C83.7463 316.89 62.5957 295.714 62.5957 269.593C62.5957 243.471 83.7463 222.295 109.837 222.295C135.928 222.295 157.078 243.471 157.078 269.593C157.078 295.714 135.928 316.89 109.837 316.89Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path d="M119.285 326.351V316.892" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M100.389 326.351V316.892" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M119.285 223.479V214.02" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M100.389 223.479V214.02" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M109.837 127.702C83.7463 127.702 62.5957 106.526 62.5957 80.4044C62.5957 54.2828 83.7463 33.1071 109.837 33.1071C135.928 33.1071 157.078 54.2828 157.078 80.4044C157.078 106.526 135.928 127.702 109.837 127.702Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M109.836 85.1349C107.227 85.1349 105.112 83.0173 105.112 80.4051C105.112 77.793 107.227 75.6754 109.836 75.6754C112.445 75.6754 114.561 77.793 114.561 80.4051C114.561 83.0173 112.445 85.1349 109.836 85.1349Z"
        fill="#999999"
      />
      <path d="M119.285 137.162V127.703" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M100.389 137.162V127.703" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M119.285 34.2904V24.8315" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M100.389 34.2904V24.8315" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M542.092 274.323C539.483 274.323 537.368 272.206 537.368 269.593C537.368 266.981 539.483 264.864 542.092 264.864C544.701 264.864 546.816 266.981 546.816 269.593C546.816 272.206 544.701 274.323 542.092 274.323Z"
        fill="#999999"
      />
      <path
        d="M542.093 316.89C516.002 316.89 494.852 295.714 494.852 269.593C494.852 243.471 516.002 222.295 542.093 222.295C568.183 222.295 589.334 243.471 589.334 269.593C589.334 295.714 568.183 316.89 542.093 316.89Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path d="M551.541 326.351V316.892" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M532.645 326.351V316.892" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M551.541 223.479V214.02" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M532.645 223.479V214.02" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M542.093 127.702C516.002 127.702 494.852 106.526 494.852 80.4044C494.852 54.2828 516.002 33.1071 542.093 33.1071C568.183 33.1071 589.334 54.2828 589.334 80.4044C589.334 106.526 568.183 127.702 542.093 127.702Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M542.092 85.1349C539.483 85.1349 537.368 83.0173 537.368 80.4051C537.368 77.793 539.483 75.6754 542.092 75.6754C544.701 75.6754 546.816 77.793 546.816 80.4051C546.816 83.0173 544.701 85.1349 542.092 85.1349Z"
        fill="#999999"
      />
      <path d="M551.541 137.162V127.703" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M532.645 137.162V127.703" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M551.541 34.2904V24.8315" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M532.645 34.2904V24.8315" stroke="#4D4D4D" strokeWidth="2" />
      <path d="M608.076 348.695V1.63005" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M262.189 274.325C264.798 274.325 266.913 272.207 266.913 269.595C266.913 266.983 264.798 264.865 262.189 264.865C259.58 264.865 257.465 266.983 257.465 269.595C257.465 272.207 259.58 274.325 262.189 274.325Z"
        fill="#999999"
      />
      <path
        d="M389.195 274.325C391.804 274.325 393.919 272.207 393.919 269.595C393.919 266.983 391.804 264.865 389.195 264.865C386.586 264.865 384.471 266.983 384.471 269.595C384.471 272.207 386.586 274.325 389.195 274.325Z"
        fill="#999999"
      />
      <path
        d="M262.189 85.1348C264.798 85.1348 266.913 83.0172 266.913 80.405C266.913 77.7929 264.798 75.6753 262.189 75.6753C259.58 75.6753 257.465 77.7929 257.465 80.405C257.465 83.0172 259.58 85.1348 262.189 85.1348Z"
        fill="#999999"
      />
      <path
        d="M389.195 85.1348C391.804 85.1348 393.919 83.0172 393.919 80.405C393.919 77.7929 391.804 75.6753 389.195 75.6753C386.586 75.6753 384.471 77.7929 384.471 80.405C384.471 83.0172 386.586 85.1348 389.195 85.1348Z"
        fill="#999999"
      />
      <path
        d="M324.783 140C305.453 140 289.783 155.67 289.783 175C289.783 194.33 305.453 210 324.783 210C344.113 210 359.783 194.33 359.783 175C359.783 155.67 344.113 140 324.783 140Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_291_22302">
        <rect fill="white" height="651" transform="matrix(0 -1 1 0 0 350)" width="350" />
      </clipPath>
    </defs>
  </svg>
);

const A1TAG_EVENT_TYPES_ENUM = {
  FACE_OFF: 'Face-off',
  STOP_GAME: 'Stop game',
  ICING: 'Icing',
  HIT: 'Hit',
  GIVE_AWAY: 'Give away',
  TAKE_AWAY: 'Take away',
  SHOT: 'Shot',
  GOAL: 'Goal',
  BREAKOUT: 'Breakout',
  ENTRY: 'Entry',
  POSSESSION_TAKE: 'Posession take',
  DEKING: 'Deking',
  PUCK_BATTLE: 'Puck battle',
  KEY_PASS: 'Key pass',
  SHOOTOUT: 'Shootout',
  DISPUTE: 'Dispute',
  END_OF_PERIOD: 'End of period',
  ADDITIONAL: 'Additional',
  PENALTY: 'Penalty',
  INJURY: 'Injury',
  GOALIE_EXIT: 'Goalie exit',
};

const generateRandomId = () => Math.floor(Math.random() * 100000);

const A1TAG_EVENTS = {
  [A1TAG_EVENT_TYPES_ENUM.FACE_OFF]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.STOP_GAME]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.ICING]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.HIT]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.GIVE_AWAY]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.TAKE_AWAY]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.SHOT]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.BREAKOUT]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.ENTRY]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.POSSESSION_TAKE]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.DEKING]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.PUCK_BATTLE]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.KEY_PASS]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.SHOOTOUT]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.DISPUTE]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.END_OF_PERIOD]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.PENALTY]: generateRandomId(),
  [A1TAG_EVENT_TYPES_ENUM.GOALIE_EXIT]: generateRandomId(),
};

const A1TAG_EVENTS_ADDITIONAL = {
  [A1TAG_EVENT_TYPES_ENUM.PENALTY]: generateRandomId(),
};

export const A1TAG_EVENTS_LIST = Object.keys(A1TAG_EVENTS).map((key) => {
  return {
    id: A1TAG_EVENTS[key],
    name: key,
  };
});

export const A1TAG_EVENTS_LIST_ADDITIONAL = Object.keys(A1TAG_EVENTS_ADDITIONAL).map((key) => {
  return {
    id: A1TAG_EVENTS_ADDITIONAL[key],
    name: key,
  };
});
