import ConditionSkeleton from '@components/modules/common/ConditionSkeleton';
import {Avatar as MuiAvatar} from '@mui/material';
import {AvatarProps} from '@mui/material/Avatar/Avatar';
import {BoxProps} from '@mui/material/Box/Box';
import {convertImgTo200} from '@src/helpers';
import React from 'react';

type CustomAvatarProps = AvatarProps & {
  width: BoxProps['width'];
  height?: BoxProps['height'];
  isHighResolution?: boolean;
  isLoading?: boolean;
};

function Avatar({children, width, height, sx, variant, src, isHighResolution, isLoading, ...props}: CustomAvatarProps) {
  return (
    <ConditionSkeleton isLoading={isLoading}>
      <MuiAvatar
        src={isHighResolution ? src : convertImgTo200(src)}
        sx={{...sx, width: width || height || 20, height: height || width || 20, fontSize: 16}}
        variant={variant}
        {...props}
      >
        {children}
      </MuiAvatar>
    </ConditionSkeleton>
  );
}

export default Avatar;
