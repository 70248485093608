import {convertSecondsToHHMMSS} from '@components/apps/A1TagMainWindow';
import {a1tagViewModel, updateCleanTime} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {useEffect, useMemo, useState} from 'react';

export const useTimer = () => {
  useSignals();
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      updateCleanTime(seconds);
    }
  }, [seconds]);

  function startTimer() {
    setIsActive(true);
  }
  function pauseTimer() {
    setIsActive(false);
  }

  function resetTimer() {
    setSeconds(0);
    setIsActive(false);
  }

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return {startTimer, pauseTimer, resetTimer};
};

export const useCurrentTime = () => {
  useSignals();
  const payload = modalViewModel.payload.value ?? {};
  const currentTime = useMemo(() => {
    return !modalViewModel.isEdit?.value
      ? convertSecondsToHHMMSS(a1tagViewModel?.cleanTime?.value || 0)
      : convertSecondsToHHMMSS(payload?.second_clear);
  }, [!modalViewModel.isEdit?.value, a1tagViewModel?.cleanTime?.value, payload?.value?.time]);
  return {currentTime: currentTime ?? 0};
};
