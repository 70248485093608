import {effect, signal} from '@preact/signals-react';
import moment, {Moment} from 'moment';

const startOfMonth = () => moment().startOf('month');
const endOfMonth = () => moment().endOf('month');

export const filtersViewModal = {
  _seasonId: signal<string | null>(null),
  _dateRange: signal<Moment[]>([startOfMonth(), endOfMonth()]),

  get seasonId() {
    return this._seasonId.value;
  },

  get dateRange() {
    return this._dateRange.value;
  },

  updateSeasonId(seasonId: string) {
    this._seasonId.value = seasonId;
  },

  updateDateRange(dateRange: Moment[]) {
    this._dateRange.value = dateRange;
  },
};
