import {TextField} from '@ui/MUI';
import {throttle} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

function SearchInput({time = 700, onChange, label = '', inputProps, InputProps, placeholder = 'Поиск'}) {
  const [searchValue, setSearchValue] = useState('');
  const throttledFilter = useCallback(throttle(onChange, time), []);
  return (
    <TextField
      InputProps={InputProps}
      label={label}
      placeholder={placeholder}
      sx={{borderRadius: 60}}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.currentTarget.value);
        throttledFilter(e.currentTarget.value);
      }}
      {...inputProps}
    />
  );
}

SearchInput.propTypes = {
  time: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
};

export default SearchInput;
