import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

function HockeyStick({...props}) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <g clipPath="url(#clip0)">
        <path
          d="M10.0129 2.50838L7.21166 8.85712C6.9143 9.53482 6.21281 9.94227 5.47675 9.86474L3.46957 9.6577L2.65479 12.835L6.95065 12.4544C7.41776 12.4133 7.82497 12.1199 8.01189 11.6899L11.6901 3.23841L10.0129 2.50838Z"
          fill="black"
        />
        <path d="M12.7842 0.724579L11.1197 0L10.1842 2.12023L11.8595 2.84943L12.7842 0.724579Z" fill="black" />
        <path
          d="M0.814764 12.998L2.20663 12.8748L3.04295 9.61354L1.4856 9.45279C1.46808 9.45101 1.45056 9.45007 1.43292 9.45007C1.206 9.44959 1.00772 9.60336 0.951725 9.8233L0.288229 12.3758C0.247507 12.5328 0.285506 12.6997 0.390269 12.8234C0.495031 12.9472 0.653418 13.0123 0.814883 12.998H0.814764Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="13" width="13" />
        </clipPath>
      </defs>
      ;
    </SvgIcon>
  );
}

export default HockeyStick;
