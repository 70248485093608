import {PageTitleProps} from '@src/types/uikit/pageTitle';
import Box from '@ui/MUI/Box';
import Breadcrumbs from '@ui/MUI/Breadcrumbs';
import {themeV2Conf} from '@ui/MUI/themeConfV2';
import Typography from '@ui/MUI/Typography';
import React from 'react';
import {useRouteMatch} from 'react-router-dom';

function PageTitle({breadCrumbs = [], title, showBreadcrumbs, children, ...props}: PageTitleProps) {
  const match = useRouteMatch();

  return (
    <Box mb={4} px={{xs: '0px', xxs: 3}} {...props}>
      <Typography mb={1} sx={{fontFamily: themeV2Conf?.typography?.h1.fontFamily}} variant="h1">
        {title || children}
      </Typography>
      {!showBreadcrumbs && breadCrumbs?.length > 0 && (
        <Breadcrumbs
          breadCrumbs={match.path === '/' && !breadCrumbs ? [{label: 'Главная', path: null}] : breadCrumbs}
        />
      )}
    </Box>
  );
}

export default PageTitle;
