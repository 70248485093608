import Button from '@ui/MUI/Button';
import IconButton from '@ui/MUI/IconButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing(4, 3)};
  border-top: 1px solid ${({theme}) => theme.palette.backgroundsAndOther.secondary};
  border-bottom: 1px solid ${({theme}) => theme.palette.backgroundsAndOther.secondary};
`;

export const Name = styled.div`
  max-width: 120px;
  margin-left: ${({theme}) => theme.spacing(3)};
`;

export const StyledButton = styled(IconButton)`
  margin-left: auto;
`;
export const PopoverContent = styled.div`
  width: 141px;
  padding: ${({theme}) => theme.spacing(1, '0')};
`;
export const PopoverButton = styled(Button)`
  ${({theme}) => theme.typography.body1}
  display: block;
  width: 100%;
  border-radius: 0;
  text-align: start;
  &:hover {
    background: ${({theme}) => theme.palette.backgroundsAndOther.background};
  }
`;
