import {ErrorBoundary} from '@components/ErrorBoundary';
import TutorialButton from '@components/templates/TutorialButton';
import {StudentSubroles} from '@src/types';
import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';

import Menu from '../Menu';
import {Left, Right, Wrapper} from './Layout';

type LayoutProps = {
  children: ReactNode;
  active: string;
};
function Layout({children, active}: LayoutProps) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const isGuest = !currentUser?.userRole;

  return (
    <Wrapper>
      {!isGuest && (
        <Left>
          <Menu active={active} />
        </Left>
      )}
      <Right>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Right>
      {currentUser?.subRole === StudentSubroles.AMATEUR && <TutorialButton />}
    </Wrapper>
  );
}

export default Layout;
