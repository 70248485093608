import * as locales from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {primary} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsV2';

const themeV2Options = {
  spacing: [4, 8, 12, 16, 24, 32, 40, 48, 64, 80, 96],
  breakpoints: {
    values: {
      xxs: 0,
      xs: 576,
      sm: 768,
      md: 1024,
      smallLaptop: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#B63830',
      dark: '#8C1A1A',
      light: '#D38883',
      contrastText: '#ffffff',
    },
    secondary: {
      main: black[8],
      dark: '#9e9e9e',
      light: '#82A1D0',
      contrastText: black[100],
    },
    secondaryInverse: {
      contrastText: '#EFEFEF',
      dark: '#D1D1D1',
      main: '#2C2C2C',
      light: '#B3B3B3',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#4D4D4D',
      caption: '#666666',
      other: '#808080',
      error: '#DB2222',
    },
    backgroundsAndOther: {
      main: '',
      primary: '#AAACAF',
      secondary: '#DFE0E3',
      background: '#EFF0F1',
      menuBackground: '#FBFBFC',
    },
  },
  borderRadiuses: [4, 8, 12, 16],
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          borderRadius: theme.borderRadiuses[1],
          ...(ownerState.size === 'large' && {
            padding: theme.spacing(2, 4),
          }),
          ...(ownerState.size === 'medium' && {
            padding: theme.spacing(1, 3),
          }),
          ...(ownerState.size === 'small' && {
            padding: theme.spacing(1, 2),
          }),
          ...(ownerState.icon_button_type === 'icon' && {
            minWidth: 'unset',
            boxShadow: 'none !important',
            ...(ownerState.size === 'large' && {
              width: 42,
              height: 42,
              fontSize: 21,
            }),
            ...(ownerState.size === 'medium' && {
              width: 36,
              height: 36,
              fontSize: 18,
            }),
            ...(ownerState.size === 'small' && {
              width: 28,
              height: 28,
              fontSize: 15,
            }),

            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              margin: 0,
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          ...(ownerState.color === 'secondary' && {
            color: theme.palette.text.secondary,
          }),
          ...(ownerState.size === 'large' && {
            width: 42,
            height: 42,
            fontSize: 21,
          }),
          ...(ownerState.size === 'medium' && {
            width: 36,
            height: 36,
            fontSize: 18,
          }),
          ...(ownerState.size === 'small' && {
            width: 28,
            height: 28,
            fontSize: 15,
          }),
          ...(ownerState.variant === 'contained' && {
            'MuiIcon-root': {
              borderRadius: theme.borderRadiuses[1],
              backgroundColor: theme.palette[ownerState.color],
            },
          }),
        }),
      },
      variants: [
        {
          props: {variant: 'contained'},
          style: {},
        },
        {
          props: {variant: 'outlined'},
          style: {},
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTab': {
            '&-root': {
              color: `${black[66]} !important`,
              font: "600 normal 14px/16px 'Inter'",
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
            },
          },
          '.Mui-selected': {
            color: `${black[100]} !important`,
          },
        },
        indicator: {
          backgroundColor: primary.dark,
        },
      },
    },
  },
};

export const themeV2Conf = createTheme(themeV2Options, locales['ruRU']);
const smRes = themeV2Conf.breakpoints.down('sm');
themeV2Conf.typography = {
  ...themeV2Conf.typography,
  fontFamily: 'Inter, Arial, sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '26px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  h2Caps: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '26px',
    fontFamily: 'Inter',
    color: black['100'],
    textTransform: 'uppercase',
    [smRes]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  h4: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 13,
      lineHeight: '17px',
    },
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  link: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black['100'],
    textDecoration: 'underline',
    cursor: 'pointer',
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  button: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  caption: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: black['100'],
    [smRes]: {
      fontSize: 11,
      lineHeight: '12px',
    },
  },
};
