import {
  chooseTeamModalViewModel,
  updateChooseTeamPayload,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {PopoverVirtualElement} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, Typography} from '@ui/MUI';
import {black, secondary} from '@ui/MUI/colorsA1Tag';
import Popover from '@ui/MUI/Popover';
import React from 'react';

import {Color, ColorContainer, ColorsPaper} from './A1TagChooseColorPopover';

type ChooseColorProps = {
  isShowed: boolean;
  isHomeTeam: boolean;
  anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null;
};

export function A1TagChooseColorPopover({
  chooseColor,
  setChooseColor,
}: {
  chooseColor: ChooseColorProps;
  setChooseColor: React.Dispatch<React.SetStateAction<ChooseColorProps>>;
}) {
  useSignals();

  const handleClose = () => {
    setChooseColor({...chooseColor, anchorEl: null});
  };

  const payload = chooseTeamModalViewModel?.payload?.value ?? {};

  const open = Boolean(chooseColor?.anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      anchorEl={chooseColor?.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id={id}
      open={open}
      onClose={handleClose}
    >
      <ColorsPaper
        sx={{
          minWidth: 220,
          minHeight: 80,
          p: 2,
          backgroundColor: black[20],
          boxShadow: `0px 1px 18px 0px #00000024`,
          cursor: 'pointer',
        }}
      >
        <Typography display="block" fontWeight={700} mb={1} variant="caption2">
          Select a color
        </Typography>
        <Box display="flex" gap={2}>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: '#757575'}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: '#757575'}});
              }
            }}
          >
            <Color sx={{backgroundColor: `#757575`}} />
          </ColorContainer>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: secondary.yellow.dark}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: secondary.yellow.dark}});
              }
            }}
          >
            <Color sx={{backgroundColor: secondary.yellow.dark}} />
          </ColorContainer>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: secondary.green.light}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: secondary.green.light}});
              }
            }}
          >
            <Color sx={{backgroundColor: secondary.green.light}} />
          </ColorContainer>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: secondary.blue.dark}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: secondary.blue.dark}});
              }
            }}
          >
            <Color sx={{backgroundColor: secondary.blue.dark}} />
          </ColorContainer>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: secondary.purple.light}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: secondary.purple.light}});
              }
            }}
          >
            <Color sx={{backgroundColor: secondary.purple.light}} />
          </ColorContainer>
          <ColorContainer
            onClick={() => {
              if (chooseColor?.isHomeTeam) {
                updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, color: secondary.dark.dark}});
              } else {
                updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, color: secondary.dark.dark}});
              }
            }}
          >
            <Color sx={{backgroundColor: secondary.dark.dark}} />
          </ColorContainer>
        </Box>
      </ColorsPaper>
    </Popover>
  );
}
