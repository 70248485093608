import {HOT_KEYS_SLUG} from '@components/A1TagVideoPlayerHotKeys/constants';
import {getKeyBySlug} from '@components/A1TagVideoPlayerHotKeys/helpers';
import {a1tagViewModel, updateCleanTime} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {playerViewModel, seekTo, togglePlayPause} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {useHotkeys} from 'react-hotkeys-hook';

export const useVideoPlayerHotkeys = () => {
  const frameDuration = 1 / 30; // Assuming 30 fps

  const focusPlayer = () => {
    const player = playerViewModel?.player?.getInternalPlayer();

    if (player) {
      player.focus();
    }
  };

  const frameBackwardKey = getKeyBySlug(HOT_KEYS_SLUG.PREVIOUS_FRAME);
  const frameForwardKey = getKeyBySlug(HOT_KEYS_SLUG.NEXT_FRAME);
  const rewindKey = getKeyBySlug(HOT_KEYS_SLUG.REWIND);
  const fastForwardKey = getKeyBySlug(HOT_KEYS_SLUG.FAST_FORWARD);

  // q => 1 frame backward
  useHotkeys(frameBackwardKey.key, () => {
    focusPlayer();
    if (playerViewModel.player === null) return;
    const currentTime = playerViewModel.player.getCurrentTime();
    seekTo(currentTime - frameDuration);
    if (a1tagViewModel?.cleanTime?.value > 0 && !a1tagViewModel?.isGameStopped?.value) {
      updateCleanTime(a1tagViewModel.cleanTime.value - frameDuration);
    }
  });

  // e => 1 frame forward
  useHotkeys(frameForwardKey.key, () => {
    focusPlayer();
    if (playerViewModel.player === null) return;
    const currentTime = playerViewModel.player.getCurrentTime();
    seekTo(currentTime + frameDuration);
    if (!a1tagViewModel?.isGameStopped?.value) {
      updateCleanTime(a1tagViewModel.cleanTime.value + frameDuration);
    }
  });

  // a => 2 seconds backward
  useHotkeys(rewindKey.key, () => {
    focusPlayer();
    if (playerViewModel.player === null) return;
    const currentTime = playerViewModel.player.getCurrentTime();
    seekTo(currentTime - 2);
    if (a1tagViewModel?.cleanTime?.value > 0 && !a1tagViewModel?.isGameStopped?.value) {
      updateCleanTime(a1tagViewModel.cleanTime.value - 2);
    }
  });

  // d => 2 seconds forward
  useHotkeys(fastForwardKey.key, () => {
    focusPlayer();
    if (playerViewModel.player === null) return;
    const currentTime = playerViewModel.player.getCurrentTime();
    seekTo(currentTime + 2);
    if (!a1tagViewModel?.isGameStopped?.value) {
      updateCleanTime(a1tagViewModel.cleanTime.value + 2);
    }
  });

  // space => play/pause
  useHotkeys('Space', () => {
    focusPlayer();
    if (playerViewModel.player === null) return;
    togglePlayPause();
  });
};
