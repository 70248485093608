import IconButtonGroup from '@common/IconButtonGroup';
import ExerciseItem from '@common/TrainingControl/ExerciseItem';
import {useRoleAccess} from '@hooks/useRoleAccess';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const ExercisesList = ({exercises, onEdit, onDelete}) => {
  const {id} = useStore((state) => state.users.currentUser);
  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <Stack bgcolor="#fff" spacing={4}>
      {exercises.map((exercise, index) => (
        <Stack direction="row" key={index} spacing={4}>
          <ExerciseItem {...exercise} isExpendable flex="1" number={index + 1} />
          <Box minWidth="92px">
            {(canEdit || exercise?.author?.id === id) && (
              <IconButtonGroup onDelete={() => onDelete(exercise.id)} onEdit={() => onEdit(exercise)} />
            )}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

export default ExercisesList;
