import * as usersActions from '@actions/UsersActions';
import {loadStudentsV2} from '@actions/UsersActions';
import ReactTableComponent from '@common/ReactTableComponent';
import SearchInput from '@common/SearchInput';
import StateHelper from '@helpers/StateHelper';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import useDeepEffect from '@hooks/useDeepEffect';
import {useCurrentUserRole} from '@hooks/user';
import useStore from '@hooks/useStore';
import {AMPLUA_OPTIONS, longDash, userRoles} from '@src/constants';
import {UserRoles} from '@src/types';
import {Autocomplete, Box, Button, Stack} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import TablePaginationDefault from '@ui/MUI/TablePagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import TabsDefault from '@ui/ReactComponent/ReactComponentTabs/TabsDefault/TabsDefault';
import TableDefault from '@ui/TableDefault';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import {isEmpty} from 'lodash';
import queryString from 'query-string';
import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import LocalPreloader from '../../preloader/LocalPreloader';
import PlayersHorizontalCard from '../../students/tools/PlayersHorizontalCard';
import ReturnFromArchiveModal from '../../students/tools/returnFromArchiveModal';

const Filters = ({filter, setFilter}) => {
  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [
      UserRoles.ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
  });
  const groups = useGroupsV2({schoolIds: filter?.school?.value ? [filter?.school?.value] : []});

  const currentUser = useStore((state) => state.users.currentUser);
  const isTeacher = currentUser?.userRole === UserRoles.TEACHER;

  const userSchoolsIds = currentUser?.schools?.map((school) => school?.objectId);
  const filteredSchoolsOptions = schools?.options?.filter((school) => {
    return userSchoolsIds?.includes(school?.value);
  });

  const userGroupsIds = currentUser?.groups?.map((group) => group?.objectId);
  const filteredGroupOptions = groups?.options?.filter((group) => {
    return userGroupsIds?.includes(group?.value);
  });

  const schoolsOptions = isTeacher ? filteredSchoolsOptions : schools?.options;
  const groupsOptions = isTeacher ? filteredGroupOptions : groups?.options;

  useDeepEffect(() => {
    if (schoolsOptions?.[0]) setFilter({...filter, school: schoolsOptions?.[0]});
  }, [schoolsOptions]);

  useEffect(() => {
    if (schoolsOptions?.length === 1) {
      setFilter({...filter, school: schoolsOptions?.[0]});
    }
    if (groupsOptions?.length === 1) {
      setFilter({...filter, group: groupsOptions?.[0]});
    }
  }, [schoolsOptions?.length, groupsOptions?.length]);

  return (
    <Box mb={1} px={5} py={2} sx={{backgroundColor: '#fff', borderRadius: '6px', overflow: 'hidden'}}>
      <Stack alignItems="flex-end" direction="row" flexWrap={{xxs: 'wrap', sm: 'nowrap'}} gap={3}>
        <Box width={{xxs: '100%', sm: '33.333%'}}>
          <Autocomplete
            disabled={schools?.isDisabled || groups?.isLoading || schoolsOptions?.length === 1}
            isLoading={schools.isLoading}
            label="Школа"
            multiple={false}
            options={schools?.options}
            value={filter?.school}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                school: v,
                group: '',
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: '33.333%'}}>
          <Autocomplete
            disabled={groups?.isDisabled || schools?.isLoading || groupsOptions?.length === 1}
            isLoading={groups.isLoading}
            label="Команда"
            multiple={false}
            options={groupsOptions}
            value={filter?.group}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                group: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: '33.333%'}}>
          <Autocomplete
            label="Амплуа"
            multiple={false}
            options={AMPLUA_OPTIONS}
            value={filter?.amplua}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                amplua: v,
              }));
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default function AllStudentsPanel(props) {
  const {titleVisible} = props;
  const apiRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const qs = queryString.parse(history.location.search);
  const [activeTab, setActiveTab] = useState(qs.tab ? (qs.tab === 'active' ? '0' : '1') : '0');
  const [searchString, setSearchString] = useState('');
  const [filterData, setFilterData] = useState({selectedPage: 0});
  const [selectedCount, setSelectedCount] = useState(25);
  const [returnFromArchiveModalState, setReturnFromArchiveModalState] = useState(false);
  const [activePlayer, setActivePlayer] = useState({});
  const {selectedPage} = filterData;
  const [debouncedSearch] = useDebouncedCallback(
    (a) => {
      setSearchString(a);
    },
    1000,
    [],
  );
  const [filter, setFilter] = useState({});
  const [activeFilter, setActiveFilter] = useState({});

  const userRole = useCurrentUserRole();

  const schools = useStore((state) => StateHelper.getSchools(state), shallowEqual);
  const schoolsOptions = schools?.map((school) => {
    return {value: school.id, label: school.name};
  });

  useEffect(() => {
    if (userRole === userRoles.methodist && apiRef?.current) {
      apiRef?.current?.updateOptions('school', schoolsOptions);
    }
  }, [userRole, apiRef?.current, schools?.length]);

  useEffect(() => {
    if (userRole === userRoles.methodist && apiRef?.current) {
      apiRef?.current?.updateValue('school', schoolsOptions?.[0]);
    }
  }, []);

  useEffect(() => {}, [userRole, schools?.length]);

  const {loading, isTeacherHasSchool, students, studentsCount} = useSelector((state) => {
    const currentUser = state.users.usersMap.get(state.users.currentUserId);
    const isTeacherHasSchool =
      userRole === userRoles.teacher ? StateHelper.isTeacherHasSchool(state, currentUser.id) : false;

    return {
      loading: state.users.loading,
      isTeacherHasSchool,
      students: state.users.students,
      studentsCount: state.users.studentsCount,
    };
  }, shallowEqual);

  useEffect(() => {
    setFilterData({selectedPage: 0});
  }, [filter?.group?.value, activeFilter?.group?.value]);

  useEffect(() => {
    const page_size = selectedCount;
    const page = selectedPage + 1;
    if (activeTab === '0') {
      if (userRole !== UserRoles.ADMIN && !isEmpty(activeFilter) && activeFilter?.school?.value) {
        dispatch(
          loadStudentsV2({
            active: true,
            schoolId: activeFilter?.school?.value,
            groupId: activeFilter?.group?.value,
            hockeyRole: activeFilter?.amplua?.value,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }

      if (userRole === UserRoles.ADMIN) {
        dispatch(
          loadStudentsV2({
            active: true,
            schoolId: activeFilter?.school?.value,
            groupId: activeFilter?.group?.value,
            hockeyRole: activeFilter?.amplua?.value,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }
    }
    if (activeTab === '1') {
      if (userRole === UserRoles.ADMIN) {
        dispatch(
          loadStudentsV2({
            active: false,
            schoolId: filter?.school?.value,
            groupId: filter?.group?.value,
            hockeyRole: filter?.amplua?.value,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      } else {
        dispatch(
          loadStudentsV2({
            active: false,
            schoolId: filter?.school?.value,
            groupId: filter?.group?.value,
            hockeyRole: filter?.amplua?.value,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }
    }
  }, [filter, activeFilter, selectedPage, selectedCount, searchString, activeTab]);

  const createTableTitle = () => {
    let result = 'Все игроки';
    if (userRole === UserRoles.ADMIN || userRole === UserRoles.SCHOOLS_ADMIN) {
      result = (
        <TabsDefault
          defaultActiveKey={activeTab}
          tabs={[
            {
              label: 'Активные игроки',
            },
            {
              label: 'Архивированные игроки',
            },
          ]}
          onActiveKeyChange={(newTabIndex) => {
            if (newTabIndex === '0') {
              history.push('/students?tab=active');
            } else {
              history.push('/students?tab=archive');
            }
            setActiveTab(newTabIndex);
          }}
        />
      );
    } else {
      result += ` (${studentsCount || 0})`;
    }

    return result;
  };

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  const columns = [
    {
      id: 'player',
      accessorKey: 'player',
      header: 'ФИО',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none',
        width: 310,
      },
      cell: ({getValue}) => {
        if (window.innerWidth <= 360) {
          getValue().firstName = getValue().firstName[0] + '.';
        }
        return (
          <Link className="TableColumnLink" to={`/student/${getValue().id}`}>
            <PlayersHorizontalCard student={getValue()} />
          </Link>
        );
      },
      cellStyle: {
        boxShadow: 'none',
        borderTop: `1px solid ${grey[12]}`,
        width: 310,
        py: 2,
      },
      sortingFn: 'defaultSorting',
    },
    {
      accessorKey: 'school',
      header: 'Школа',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none',
        textAlign: 'center',
      },
      cell: ({getValue}) => {
        return getValue()?.name || longDash;
      },
      cellStyle: {
        boxShadow: 'none',
        borderTop: `1px solid ${grey[12]}`,
        textAlign: 'center',
        py: 2,
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.getValue(columnId);
        const b = rowB.getValue(columnId);
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      },
    },
    {
      accessorKey: 'group',
      header: 'Команда',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none !important',
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA?.getValue(columnId);
        const b = rowB?.getValue(columnId);
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1;
        }
        if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
          return -1;
        }
        return 0;
      },
      cell: ({getValue}) => {
        return getValue()?.name || longDash;
      },
      cellStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none !important',
        py: 2,
      },
    },
  ];

  return (
    <Wrapper>
      {userRole !== userRoles.teacher || (userRole === userRoles.teacher && isTeacherHasSchool) ? (
        <>
          {titleVisible ? <PageTitleDefault text={'Игроки'} /> : null}
          <TableDefault
            className={'players-table'}
            content={
              <StudentsBlock loading={loading}>
                <LocalPreloader visible={loading} />
                <TopSearchPlaceholder>
                  <SearchInput
                    inputProps={{variant: 'outlined'}}
                    onChange={(value) => {
                      debouncedSearch(value);
                    }}
                  />
                </TopSearchPlaceholder>
                {activeTab === '0' ? <Filters filter={activeFilter} setFilter={setActiveFilter} /> : null}
                {activeTab === '1' ? <Filters filter={filter} setFilter={setFilter} /> : null}

                {studentsCount > 0 ? (
                  <>
                    <ReactTableComponent
                      columns={
                        activeTab === '0'
                          ? columns
                          : [
                              ...columns,
                              {
                                id: 'action',
                                header: '',
                                headerStyle: {
                                  borderTop: `1px solid ${grey[12]}`,
                                  boxShadow: 'none !important',
                                },
                                cell: (params) => {
                                  return (
                                    <Button
                                      color="primary"
                                      key="0"
                                      sx={{textTransform: 'none'}}
                                      variant="text"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setActivePlayer(params.row.original);
                                        setReturnFromArchiveModalState(true);
                                      }}
                                    >
                                      Разархивировать
                                    </Button>
                                  );
                                },
                                cellStyle: {
                                  borderTop: `1px solid ${grey[12]}`,
                                  boxShadow: 'none !important',
                                  textAlign: 'right',
                                },
                              },
                            ]
                      }
                      data={students?.map((student) => {
                        return {
                          id: student.id,
                          player: {
                            id: student.id,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            playerNumber: student.playerNumber,
                            avatar: student.avatar,
                          },
                          school: student.school,
                          group: student.group,
                        };
                      })}
                      setSorting={setSorting}
                      sorting={sorting}
                    />
                    <TablePaginationDefault
                      count={studentsCount}
                      page={selectedPage}
                      rowsPerPage={selectedCount}
                      onPageChange={(event, newPage) => {
                        setFilterData((prev) => {
                          return {
                            ...prev,
                            selectedPage: newPage,
                          };
                        });
                      }}
                      onRowsPerPageChange={(e) => {
                        setSelectedCount(parseInt(e.target.value, 10));
                        setFilterData({selectedPage: 0});
                      }}
                    />
                  </>
                ) : (
                  <TableEmptyBlock text="Список игроков пуст" />
                )}
              </StudentsBlock>
            }
            title={createTableTitle()}
          />
        </>
      ) : (
        <TableEmptyBlock text="Список игроков пуст" />
      )}
      <ReturnFromArchiveModal
        returnFromArchiveModalState={returnFromArchiveModalState}
        onClose={() => {
          setReturnFromArchiveModalState(false);
        }}
        onSave={async () => {
          setReturnFromArchiveModalState(false);
          await dispatch(
            usersActions.updateStudent({
              active: true,
              id: activePlayer.id,
              schoolId: activePlayer?.school?.id,
              groupId: activePlayer?.group?.id,
            }),
          );
          await dispatch(
            loadStudentsV2({
              active: false,
              schoolId: activeFilter?.school?.value,
              groupId: activeFilter?.group?.value,
              hockeyRole: activeFilter?.amplua?.value,
              q: searchString || null,
              page: 1,
              limit: selectedCount,
            }),
          );
          setActivePlayer({});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .TableColumnLink {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;
const StudentsBlock = styled.div`
  position: relative;
  max-height: ${(props) => (props.loading ? '60vh' : '100%')};
  border-radius: ${(props) => (props.loading ? '0 0 6px 6px' : '6px')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
`;

const TopSearchPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 34px 10px;
  background: white;

  & > .Search {
    flex: 0 0 70%;
    max-width: 70%;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 60px;
    font: normal normal 14px/20px 'Proxima Nova';
    color: #cab791;
    @media (min-width: 576px) and (max-width: 767px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media screen and (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:focus {
      border: 1px solid #cab791;
    }

    &:hover {
      border: 1px solid #cab791;
    }

    &::placeholder {
      font: normal normal 14px/20px 'Proxima Nova';
      color: #cab791;
      opacity: 1;
    }
  }
`;
