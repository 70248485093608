import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';
import moment from 'moment';

const baseUrl = `${API_ENDPOINT}/v1/training-process`;
const defaultErrorMessage = 'Что-то пошло не так';

export const getSkills = (params) => {
  return axios
    .get(`${baseUrl}/skill`, {params}, {failed: (err) => ({message: err?.data?.message || defaultErrorMessage})})
    .then((res) =>
      res.data.map((skill) => ({
        ...skill,
        section: skill?.category?.section,
      })),
    );
};

export const createSkill = (payload) => {
  return axios.post(`${baseUrl}/skill`, payload, {
    success: {message: 'Навык успешно создан'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateSkill = (payload, id) => {
  return axios.put(`${baseUrl}/skill/${id}`, payload, {
    success: {message: 'Навык успешно обновлен'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteSkill = (id) => {
  return axios.delete(`${baseUrl}/skill/${id}`, {
    success: {message: 'Навык успешно удален'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getExercises = (params) => {
  return axios
    .get(`${baseUrl}/exercise`, {params}, {failed: (err) => ({message: err?.data?.message || defaultErrorMessage})})
    .then((res) =>
      res.data.map((exercise) => ({
        ...exercise,
        skills: exercise.skills.map((skill) => ({
          ...skill,
          section: skill?.category?.section,
        })),
      })),
    );
};

export const createExercise = (payload) => {
  return axios.post(`${baseUrl}/exercise`, payload, {
    success: {message: 'Упражнение успешно создано'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateExercise = (payload, id) => {
  return axios.post(`${baseUrl}/exercise/${id}`, payload, {
    success: {message: 'Упражнение успешно обновлено'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteExercise = (id) => {
  return axios.delete(`${baseUrl}/exercise/${id}`, {
    success: {message: 'Упражнение успешно удалено'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getThemes = (params) => {
  return axios
    .get(
      `${baseUrl}/theme`,
      {params},
      {
        failed: (err) => {
          return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
        },
      },
    )
    .then((res) => res.data);
};

export const createTheme = (payload) => {
  return axios.post(`${baseUrl}/theme`, payload, {
    success: {message: 'Тематика успешно создана'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateTheme = (payload, id) => {
  return axios.put(`${baseUrl}/theme/${id}`, payload, {
    success: {message: 'Тематика успешно обновлена'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteTheme = (id) => {
  return axios.delete(`${baseUrl}/theme/${id}`, {
    success: {message: 'Тематика успешно удалена'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getAges = (params) => {
  return axios
    .get(`${baseUrl}/age`, {params}, {failed: (err) => ({message: err?.data?.message || defaultErrorMessage})})
    .then((res) => res.data);
};

export const createAge = (payload) => {
  return axios.post(`${baseUrl}/age`, payload, {
    success: {message: 'Возраст успешно создан'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateAge = (payload, id) => {
  return axios.put(`${baseUrl}/age/${id}`, payload, {
    success: {message: 'Возраст успешно обновлен'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteAge = (id) => {
  return axios.delete(`${baseUrl}/age/${id}`, {
    success: {message: 'Возраст успешно удален'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getSections = (params) => {
  return axios
    .get(
      `${baseUrl}/skill-section`,
      {params},
      {
        failed: (err) => {
          return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
        },
      },
    )
    .then((res) => res.data);
};

export const createSections = (payload) => {
  return axios.post(`${baseUrl}/skill-section`, payload, {
    success: {message: 'Раздел успешно создан'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateSections = (payload, id) => {
  return axios.put(`${baseUrl}/skill-section/${id}`, payload, {
    success: {message: 'Раздел успешно обновлен'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteSections = (id) => {
  return axios.delete(`${baseUrl}/skill-section/${id}`, {
    success: {message: 'Раздел успешно удален'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getCategories = (params) => {
  return axios
    .get(
      `${baseUrl}/skill-category`,
      {params},
      {
        failed: (err) => {
          return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
        },
      },
    )
    .then((res) => res.data);
};

export const createCategory = (payload) => {
  return axios.post(`${baseUrl}/skill-category`, payload, {
    success: {message: 'Категория успешно создана'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const updateCategory = (payload, id) => {
  return axios.put(`${baseUrl}/skill-category/${id}`, payload, {
    success: {message: 'Категория успешно обновлена'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const deleteCategory = (id) => {
  return axios.delete(`${baseUrl}/skill-category/${id}`, {
    success: {message: 'Категория успешно удалена'},
    failed: (err) => {
      return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
    },
  });
};

export const getTrainingList = (params) => {
  return axios.get(`${baseUrl}/training`, {params}).then((res) =>
    res.data.map((training) => ({
      ...training,
      exercises: training.exercises.map(({exercise, id, ...rest}) => ({...exercise, ...rest, innerId: id})),
    })),
  );
};

export const getEstimatedTrainingList = (params) => {
  return axios.get(`${baseUrl}/training`, {params: {...params, isEstimated: true}}).then((res) =>
    res.data.map((training) => ({
      ...training,
      exercises: training.exercises.map(({exercise, id, ...rest}) => ({...exercise, ...rest, innerId: id})),
      fullTime: `${training.startTime} - ${training.endTime}`,
    })),
  );
};

export const getNotEstimatedTrainingList = (params) => {
  return axios.get(`${baseUrl}/training`, {params: {...params, isEstimated: false}}).then((res) =>
    res.data.map((training) => ({
      ...training,
      exercises: training.exercises.map(({exercise, id, ...rest}) => ({...exercise, ...rest, innerId: id})),
      formatedDate: moment(training.date).format('DD.MM.YYYY'),
      fullTime: `${training.startTime} - ${training.endTime}`,
    })),
  );
};

export const getOneTraining = (id) => {
  return axios
    .get(`${baseUrl}/training/${id}`)
    .then((res) => res.data)
    .then((training) => ({
      ...training,
      exercises: training.exercises.map(({exercise, id, ...rest}) => ({...exercise, ...rest, innerId: id})),
    }));
};

export const createTraining = (payload) => {
  return axios
    .post(`${baseUrl}/training`, payload, {
      success: {message: 'Тренировка успешно создана'},
      failed: (err) => {
        return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
      },
    })
    .then((res) => res.data);
};

export const estimateTraining = (payload) => {
  return axios
    .post(`${baseUrl}/training/${payload.id}/estimate`, payload, {
      success: {message: 'Тренировка успешно оценена'},
      failed: (err) => {
        return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
      },
    })
    .then((res) => res.data);
};

export const updateTraining = (payload, id) => {
  return axios
    .put(`${baseUrl}/training/${id}`, payload, {
      success: {message: 'Тренировка успешно обновлена'},
      failed: (err) => {
        return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
      },
    })
    .then((res) => res.data);
};

export const deleteTraining = (id) => {
  return axios
    .delete(`${baseUrl}/training/${id}`, id, {
      success: {message: 'Тренировка успешно удалена'},
      failed: (err) => {
        return {message: err?.data?.message || err?.response?.data?.message || defaultErrorMessage};
      },
    })
    .then((res) => res.data);
};

export const getTrainingSchedule = (params) => {
  return axios.get(`${baseUrl}/training/schedule`, {params}).then((res) => res.data);
};

export const getSkillsImprovementReport = (params) => {
  return axios.get(`${baseUrl}/training/report-training-estimated`, {params}).then((res) => res.data);
};
