import {formatPlayer} from '@helpers/a1tag.helper';
import isObjectEmpty from '@helpers/isObjectEmpty';
import {MAPS_SIZE} from '@src/constants/stitictics';

import {convertSecondsToHHMMSS} from '../..';
import {A1tagStatistic} from '../a1tag.view-mode.types';
import {a1tagViewModel, allPlayers} from '../a1tag.view-model';
import {createCoordsModel} from '../coords.view-model';
import {modalViewModel} from '../modal-view-model';
import {playerViewModel} from '../player.view-model';

export const minX = 0;
export const maxX = MAPS_SIZE.x;
export const minY = 0;
export const maxY = MAPS_SIZE.y;

export const coords = createCoordsModel();

export const gatesCoords = createCoordsModel();

export const gatesMaxX = 0.91;
export const gatesMaxY = 1.21;
export const gatesMinX = -0.91;
export const gatesMinY = 0;

export const openModal = (payload?: any, isEdit?: boolean) => {
  modalViewModel.isOpen.value = true;
  modalViewModel.isEdit.value = isEdit || false;

  modalViewModel.payload.value = payload ?? null;

  if (isEdit) {
    coords.updateCoords(payload.pos_x, payload.pos_y);
    coords.updateEclipses([{x: payload.pos_x, y: payload.pos_y}]);
  }

  if (isEdit && payload?.goal_spot_x && payload?.goal_spot_y) {
    gatesCoords.updateCoords(payload.goal_spot_x, payload.goal_spot_y);
    gatesCoords.updateEclipses([{x: payload.goal_spot_x, y: payload.goal_spot_y}]);
  }

  // если playerId и это редактирование, то заполняем player
  if (payload.player_id && isEdit) {
    const player = allPlayers.value.find((item) => item.avangardId === payload.player_id);
    modalViewModel.payload.value.player = player ? formatPlayer(player) : null;
  }

  if (payload.opponent_id && isEdit) {
    const player = allPlayers.value.find((item) => item.avangardId === payload.opponent_id);
    modalViewModel.payload.value.opponent = player ? formatPlayer(player) : null;
  }
};

export const closeModal = () => {
  modalViewModel.isOpen.value = false;
  modalViewModel.isEdit.value = false;
  modalViewModel.payload.value = null;
  coords.cleanUp();
};

export const updatePayload = (payload: any) => {
  modalViewModel.payload.value = payload;
};

export const getFormattedStatistics = (): A1tagStatistic => {
  const action = a1tagViewModel.events.value.find((event) =>
    [modalViewModel?.payload?.value?.action_name, modalViewModel?.payload?.value?.eventName].includes(event.name),
  );

  const player = modalViewModel?.payload.value?.player;
  const opponent = modalViewModel?.payload.value?.opponent;
  const isEdit = modalViewModel?.isEdit;
  const currentVideoTimeSec = playerViewModel.currentVideoTime.value;
  const half = a1tagViewModel.period.value;
  const lines = a1tagViewModel.lines.value;
  const cleanTime = !isEdit?.value ? a1tagViewModel.cleanTime.value : modalViewModel?.payload?.value?.second_clear;
  const timeStart = convertSecondsToHHMMSS(currentVideoTimeSec > 1 ? currentVideoTimeSec - 1 : currentVideoTimeSec);
  const timeEnd = convertSecondsToHHMMSS(currentVideoTimeSec + 5);

  const assistants = [
    {...modalViewModel?.payload?.value?.assistant1},
    {...modalViewModel?.payload?.value?.assistant2},
  ]?.filter((item) => !isObjectEmpty(item));

  const result = {
    id: modalViewModel?.payload?.value?.id ?? Math.floor(Math.random() * Date.now()).toString(12),
    action_id: action?.id ?? 0,
    eventName: modalViewModel?.payload?.value?.eventName,
    action_name: modalViewModel?.payload.value?.action_name || modalViewModel?.payload?.value?.eventName,
    player_name: player ? `${player?.lastName} ${player?.firstName}` : null,
    player_id: player?.value,
    team_name: player?.group?.name,
    opponent_name: opponent ? `${opponent?.lastName} ${opponent?.firstName}` : null,
    opponent_id: opponent?.value ?? null,
    opponent_team_name: opponent?.group?.name ?? null,
    half,
    second_clear: cleanTime,
    zone_name: modalViewModel.payload?.value?.zone_name ?? null,
    opponent_zone_name: modalViewModel.payload?.value?.opponent_zone_name ?? null,
    pos_x: modalViewModel?.payload?.value?.pos_x ?? null,
    pos_y: modalViewModel.payload?.value?.pos_y ?? null,
    opponent_pos_x: modalViewModel?.payload?.value?.opponent_pos_x ?? null,
    opponent_pos_y: modalViewModel?.payload?.value?.opponent_pos_y ?? null,
    plrs_team1: lines?.[0],
    plrs_team2: lines?.[lines?.length - 1],
    goals_team1: a1tagViewModel.homeScore.value,
    goals_team2: a1tagViewModel.awayScore.value,
    action_type: modalViewModel?.payload?.value?.action_type ?? null,
    goalie_view: modalViewModel?.payload?.value?.goalie_view ?? null,
    one_timer: modalViewModel?.payload?.value?.one_timer ?? null,
    from_rebound: modalViewModel?.payload?.value?.from_rebound ?? null,
    goal_spot_x: modalViewModel?.payload?.value?.goal_spot_x ?? null,
    goal_spot_y: modalViewModel?.payload?.value?.goal_spot_y ?? null,
    penalty_time: modalViewModel?.payload?.value?.penalty_time ?? null,
    penalty_add_time: modalViewModel?.payload?.value?.penalty_add_time ?? null,
    penalty_index_text: modalViewModel?.payload?.value?.penalty_index_text ?? null,
    jersey_player: player?.playerNumber,
    jersey_opponent: opponent?.playerNumber ?? null,
    video_time_start: timeStart,
    video_time_end: timeEnd,
    time: convertSecondsToHHMMSS(cleanTime),
    assistants: assistants || [],
    another_player: modalViewModel?.payload?.value?.another_player ?? null,
  };

  if (['Goalie exit'].includes(action?.name ?? '') && !result['action_type']) {
    result['action_type'] = 'Unsuccessfull';
  }

  return result;
};
