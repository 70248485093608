import React, {useEffect, useRef} from 'react';

import {grabAId, grabOId} from '../video-player.helpers';
import {VkontakteInterfaceProps} from '../video-player.types';

const SDK = 'https://vk.com/js/api/videoplayer.js';

// Загрузка SDK ВКонтакте, для управления плеером
const loadSdk = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = SDK;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

interface VkState {
  state: string;
  volume: number;
  muted: boolean;
  time: number;
  duration: number;
}

export const VkontakteInterface = (props: VkontakteInterfaceProps) => {
  const width = props.width ?? '100%';
  const height = props.height ?? '200px';
  const oId = grabOId(props.url);
  const aId = grabAId(props.url);
  const src = `https://vk.com/video_ext.php?oid=${oId}&id=${aId}&hd=2&js_api=1`;
  const frameRef = useRef<HTMLIFrameElement>(null);
  const player = useRef<unknown>(null);

  const loadScript = async () => {
    if (!frameRef.current) return;
    // после загрузки SDK создает в глобальной области видимости объект VK в котором находится объект VK
    // @ts-expect-error - VK is not defined
    let vk = window.VK;

    if (!vk && !player.current) {
      await loadSdk();
      // @ts-expect-error - VK is not defined
      vk = window.VK;
    }

    const vkPlayer = vk.VideoPlayer(frameRef.current);
    // подписываемся на событие timeupdate
    vkPlayer.on('timeupdate', onTimeUpdate);
    player.current = vkPlayer;
  };

  useEffect(() => {
    // если плеер не загружен, то загружаем его
    if (!player.current) {
      loadScript();
    }
    return () => {
      // если плеер загружен, то при размонтировании компонента уничтожаем его
      if (player.current) {
        player.current.destroy();
      }
    };
  }, [player.current]);

  useEffect(() => {
    // если плеер загружен и установлено начальное время воспроизведения, то перематываем его
    if (player.current && props?.config?.start) {
      player.current.seek(props?.config?.start);
    }
  }, [player.current, props?.config?.start]);

  const onTimeUpdate = (state: VkState) => {
    if (!props?.config?.end) return;
    // если время воспроизведения равно конечному времени, то останавливаем плеер
    if (state?.time >= props.config.end) {
      player.current.pause();
    }
  };

  return (
    <iframe
      allowFullScreen
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
      frameBorder="0"
      height={height}
      ref={frameRef}
      src={src}
      style={props.style}
      width={width}
    />
  );
};
