import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {getZone} from '@components/A1TagEventModal/utils';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {
  coords,
  gatesCoords,
  gatesMaxX,
  gatesMaxY,
  gatesMinX,
  gatesMinY,
  maxX,
  maxY,
  updatePayload,
} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {GatesSchema} from '@components/GatesSchema';
import {formatPlayer} from '@helpers/a1tag.helper';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsA1Tag';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import RadioGroup from '@ui/MUI/RadioGroup';
import Switch from '@ui/MUI/Switch';
import React, {useEffect, useState} from 'react';

const actionNameOptions = [
  {value: 'Shot wide', label: 'Missed'},
  {value: 'Shot on goal', label: 'Shot on goal'},
  {value: 'Goal', label: 'Goal'},
];

const actionTypesOptions = [
  {value: 'None', label: 'None'},
  {value: 'Slap', label: 'Slap'},
  {value: 'Wrist', label: 'Wrist'},
  {value: 'Snap', label: 'Snap'},
  {value: 'Backhand', label: 'Backhand'},
  {value: 'Wrap around', label: 'Wrap around'},
];

export const A1TagShootOutModalContent = () => {
  useSignals();
  const [isFixed, setIsFixed] = useState(true);
  const payload = modalViewModel.payload.value ?? {};
  const {x, y} = coords.getCoords();
  const {x: gateX, y: gateY} = gatesCoords.getCoords();

  const isShotOnGoal = payload.action_name === 'Shot on goal';
  const isGoal = payload.action_name === 'Goal';

  useEffect(() => {
    if (isFixed) {
      coords.updateEclipses(coords?.getEclipses()?.[0] ? [coords?.getEclipses()?.[0]] : []);
    }
  }, [isFixed]);

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const isSelectedPlayerHome = uniqHomePlayers?.value
    ?.map((player) => player.avangardId)
    ?.includes(payload?.player?.avangardId);

  const options = isSelectedPlayerHome
    ? uniqHomePlayers.value.map(formatPlayer)
    : uniqAwayPlayers.value.map(formatPlayer);

  const {currentTime} = useCurrentTime();

  return (
    <Box>
      <Box sx={{mt: -3, mx: -4, borderBottom: `1px solid ${black[25]}`}}>
        <Box px={4} py={1} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={250} pl={2}>
            <Autocomplete
              multiple={false}
              options={options}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.player ?? null}
              width="100%"
              onChange={(_, v) => {
                const player = allPlayers.value.find((p) => p.avangardId === v?.value);

                updatePayload({
                  ...payload,
                  player: player ? formatPlayer(player) : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{display: 'flex'}}>
        <Box sx={{flex: '0 0 62%', maxWidth: '62%'}}>
          <Box mt={0}>
            <Typography color={grey[54]} py={0} variant="subtitle2">
              Type 1
            </Typography>
            <Box>
              <Stack direction="column">
                <RadioGroup
                  row
                  checkboxes={actionNameOptions}
                  color="primary"
                  formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
                  name="type"
                  size="small"
                  sx={{
                    width: '100%',
                    '.MuiFormControlLabel-root': {
                      mr: 2,
                    },
                  }}
                  value={payload.action_name ?? null}
                  onChange={(e, v) => {
                    updatePayload({
                      ...payload,
                      action_name: v,
                    });
                  }}
                />
              </Stack>
            </Box>
          </Box>
          <Box>
            <Typography color={grey[54]} py={0} variant="subtitle2">
              Type 2
            </Typography>
            <Box>
              <RadioGroup
                row
                checkboxes={actionTypesOptions}
                color="primary"
                formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
                name="type"
                size="small"
                sx={{
                  width: '100%',
                  '.MuiFormControlLabel-root': {
                    mr: 2,
                  },
                }}
                value={payload.action_type ?? null}
                onChange={(e, v) => {
                  updatePayload({
                    ...payload,
                    action_type: v,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{pt: 1, flex: '0 0 38%', maxWidth: '38%'}}>
          {(isShotOnGoal || isGoal) && (
            <Stack direction="row" mt={0} spacing={2}>
              <GatesSchema
                eclipses={gatesCoords.getEclipses()}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(
                    e,
                    gatesMaxX,
                    gatesMaxY,
                    gatesMinX,
                    gatesMinY,
                  );
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  gatesCoords.updateEclipses([eclipse]);
                  updatePayload({...payload, goal_spot_x: cartesianX, goal_spot_y: cartesianY});
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, gatesMaxX, gatesMaxY, gatesMinX, gatesMinY);
                  gatesCoords.updateCoords(cartesianX, cartesianY);
                }}
              />
              <Box>
                {isShotOnGoal && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isFixed}
                        color="primary"
                        size="small"
                        sx={{mx: '0px'}}
                        onChange={(_, v) => {
                          setIsFixed(v);
                        }}
                      />
                    }
                    label="Fixed"
                    sx={{ml: '0px'}}
                  />
                )}
                <Typography sx={{mt: 0, textAlign: 'center', minWidth: '100px'}} variant="subtitle1">
                  ({gateX.toFixed(2)}, {gateY.toFixed(2)})
                </Typography>
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
      <Box mx={-4}>
        <Box px={2}>
          <Box sx={{position: 'relative', mt: 1}}>
            <Box
              mx="auto"
              px={4}
              sx={{
                position: 'relative',
                maxWidth: 650,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: -44,
                  transform: 'translateY(-50%) rotate(-90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {homeTeam?.name}
              </Typography>
              <ArenaSchema
                eclipses={coords.getEclipses()}
                isShotOnGoal={isShotOnGoal}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                  const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                  if (coords?.getEclipses()?.length <= 1) {
                    coords.updateEclipses([eclipse]);
                  } else {
                    coords.updateEclipses([eclipse, coords?.getEclipses()?.[1]]);
                  }
                  if (!zone?.length) {
                    updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY});
                  } else {
                    updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY, zone_name: zone});
                  }
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  if (isShotOnGoal && !isFixed) {
                    const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                    const eclipse = {
                      realX,
                      realY,
                      x: cartesianX,
                      y: cartesianY,
                    };
                    const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                    const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                    coords.updateEclipses([coords.getEclipses()?.[0], eclipse]);

                    if (!zone?.length) {
                      updatePayload({...payload, opponent_pos_x: cartesianX, opponent_pos_y: cartesianY});
                    } else {
                      updatePayload({
                        ...payload,
                        opponent_pos_x: cartesianX,
                        opponent_pos_y: cartesianY,
                        zone_name: zone,
                      });
                    }
                  }
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                  coords.updateCoords(cartesianX, cartesianY);
                }}
              />
              <Typography sx={{mt: 0, textAlign: 'center'}} variant="subtitle1">
                ({x.toFixed(1)}, {y.toFixed(1)})
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  right: -22,
                  transform: 'translateY(-50%) rotate(90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {awayTeam?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
