import {Header} from '@components/modules/prospects/components/Header';
import {useProspectGoals} from '@components/modules/prospects/queries/prospect-goals.query';
import {useCurrentSeason} from '@components/modules/season/queries/current-season.query';
import {Add} from '@mui/icons-material';
import {Button, colors, Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router';

import {Goal, goals} from '../mock/goals.mock';
import {completedGoal, newGoal} from '../models/drawer.models';

const GoalCard = ({goal, expectedResult, startDate, endDate, number, id}: Goal & {number: number; id: string}) => {
  const handleCompleteGoal = () => {
    completedGoal.open({goal, expectedResult, id});
  };
  return (
    <>
      <Stack alignItems="flex-start" direction="row" spacing={2}>
        <Typography variant="subtitle2">{number}</Typography>
        <Stack spacing={2}>
          <Stack>
            <Typography color="#525252" variant="subtitle2">
              Цель
            </Typography>
            <Typography variant="body1">{goal}</Typography>
          </Stack>
          <Stack>
            <Typography color="#525252" variant="subtitle2">
              Ожидаемый результат:
            </Typography>
            <Typography variant="body1">{expectedResult}</Typography>
          </Stack>
          <Stack>
            <Typography color="#525252" variant="subtitle2">
              Период вылолнения:
            </Typography>
            <Typography variant="body1">
              {startDate} - {endDate}
            </Typography>
          </Stack>
          <Button sx={{alignSelf: 'flex-start'}} variant="outlined" onClick={handleCompleteGoal}>
            Завершить
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export interface GoalsListProps {
  title: string;
  type: 'next-4-games' | 'full-season';
}
const GoalsList = ({title, type}: GoalsListProps) => {
  const {playerId} = useParams<{playerId: string}>();
  const handleOpenNewGoal = () => {
    newGoal.open();
  };

  const currentSeasonQuery = useCurrentSeason();

  const goalsQuery = useProspectGoals(playerId, {
    isCompleted: false,
    seasonId: type === 'full-season' ? currentSeasonQuery?.data?.id : undefined,
  });

  const goalsData = goalsQuery.data ?? [];
  const cuttedGoals = type === 'next-4-games' ? goalsData.slice(0, 4) : goalsData;
  const data = type === 'next-4-games' ? cuttedGoals : goalsData;

  return (
    <Stack flex={1} sx={{background: 'white'}}>
      <Header>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h4">{title}</Typography>
          <Button size="small" startIcon={<Add />} variant="contained" onClick={handleOpenNewGoal}>
            Добавить
          </Button>
        </Stack>
      </Header>
      <Stack p={3} spacing={3}>
        {data.length ? (
          goalsData.map((goal, index) => (
            <>
              <GoalCard key={index} number={index + 1} {...goal} id={goal.id} />
              {index !== goals.length - 1 && <Divider />}
            </>
          ))
        ) : (
          <Typography>Цели не найдены</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const ActiveGoals = () => {
  return (
    <Stack spacing={3}>
      <Typography color={colors.grey[50]} variant="h3">
        актуальные цели
      </Typography>
      <Stack direction="row" spacing={3}>
        <GoalsList title="Цели на ближайшие 4 игры" type="next-4-games" />
        <GoalsList title="Цели на пол сезона" type="full-season" />
      </Stack>
    </Stack>
  );
};
