import {Player} from '@components/apps/A1TagAddingRosters';
import {A1tagStatistic} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
  updateStatistic,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete} from '@ui/MUI';
import React, {memo} from 'react';

import {CellDef} from '../../a1Table.types';

export const PlayerCell = memo(({cell, getValue}: CellDef<string>) => {
  useSignals();
  const id = getValue();
  const players = allPlayers.value.map((player) => ({
    label: `${player.lastName} ${player.firstName}`,
    value: player.avangardId,
  }));

  const player = players.find((player) => player.value === id);
  const isHomeMember = uniqHomePlayers.value.some((player) => player.avangardId === id);
  const teamMates = isHomeMember ? uniqHomePlayers.value : uniqAwayPlayers.value;
  const teamMatesWithoutCurrent = teamMates.filter((player) => player.avangardId !== id);
  const options = teamMatesWithoutCurrent.map((player) => ({
    label: `${player.lastName} ${player.firstName}`,
    value: player.avangardId,
  }));

  return (
    <Autocomplete
      multiple={false}
      options={options}
      sx={{
        '.MuiAutocomplete-inputRoot': {
          minHeight: 20,
          fontSize: 14,
        },
      }}
      value={player}
      onChange={(_, newValue: Player & {value: string; label: string}) => {
        const key = cell.column.id as keyof A1tagStatistic;
        updateStatistic({...cell.row.original, [key]: newValue.value});
      }}
    />
  );
});

PlayerCell.displayName = 'PlayerCell';
