import GroupsAPI from '@api/GroupsAPI';
import ChessTemplate from '@components/templates/ChessTemplate';
import {clearDashboardData, loadNextGames} from '@slices/managerDashboard';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import IllnessPlayers from './components/IllnessPlayers';
import InjuredPlayers from './components/InjuredPlayers';
import MissedPlayers from './components/MissedPlayers';
import NextGames from './components/NextGames';
import PastGames from './components/PastGames';
import Schedule from './components/Schedule';
import {Half, Wrapper} from './ManagerDashboard';

export default function ManagerDashboard() {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.users.currentUser);

  const [groupSelect, setGroupSelect] = useState({
    isLoading: false,
    groups: [],
  });
  const setGroupsBySchool = async (schoolId) => {
    setGroupSelect({
      isLoading: true,
      groups: [],
    });
    try {
      const newGroups = await GroupsAPI.getAllGroupsV2({schoolId: schoolId});
      const sortedGroups = newGroups?.data.result.sort((a, b) => a.birthYear - b.birthYear);
      const oldAgesGroups = sortedGroups.filter((group) => Number(group?.birthYear) <= 2006);
      const othersGroups = sortedGroups.filter((group) => Number(group?.birthYear) >= 2007);
      setGroupSelect({
        isLoading: false,
        groups: [...othersGroups, ...oldAgesGroups],
      });
    } catch (error) {
      //вывод ошибки
      setGroupSelect({
        isLoading: false,
        groups: [],
      });
    }
  };

  useEffect(() => {
    if (currentUser.schoolsIds?.length) {
      setGroupsBySchool(currentUser.schoolsIds[0]);
    }
    dispatch(loadNextGames());
    return () => dispatch(clearDashboardData());
  }, []);
  return (
    <ChessTemplate active={'index'}>
      <PageTitleDefault breadCrumbs={[{label: 'Главная', path: null}]}>Рабочий стол менеджера</PageTitleDefault>
      <Wrapper>
        <Box mb={1}>
          <NextGames />
        </Box>
        <Stack direction={{xxs: 'column', lg: 'row'}} flexWrap="wrap" justifyContent="space-between" spacing={1}>
          <Half gap={1}>
            <Schedule groupSelect={groupSelect} />
            <InjuredPlayers />
            <MissedPlayers />
            <IllnessPlayers />
          </Half>

          <Half gap={1} sx={{mt: {lg: '8px !important'}, ml: {lg: '0px !important'}}}>
            <PastGames groupSelect={groupSelect} />
          </Half>
        </Stack>
      </Wrapper>
    </ChessTemplate>
  );
}
