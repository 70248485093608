import {createProspectReport} from '@api/prospect.api';
import {CreateReportDto} from '@src/types/generated';
import {useMutation, useQueryClient} from '@tanstack/react-query';

export const useCreateProspectReportMutation = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateReportDto) => {
      return createProspectReport(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'report', userId]});
    },
  });
};
