import {signal} from '@preact/signals-react';

export enum ModalMode {
  ADD = 'add',
  EDIT = 'edit',
}

export const createModalViewModel = <T, M = ModalMode>() => {
  return {
    _isOpen: signal<boolean>(false),
    _mode: signal<M | null>(null),
    _payload: signal<T | null>(null),

    get isOpen() {
      return this._isOpen.value;
    },

    get mode() {
      return this._mode.value;
    },

    get payload() {
      return this._payload.value;
    },

    open(mode: M, payload?: T | null) {
      this._isOpen.value = true;
      this._mode.value = mode;
      this._payload.value = payload ?? null;
    },

    close() {
      this._isOpen.value = false;
      this._mode.value = null;
      this._payload.value = null;
    },
  };
};
