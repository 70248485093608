import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({theme}) => theme.spacing(4)};
`;

export const Team = styled.div``;

export const TablesContainer = styled.div`
  position: relative;
`;
