import * as FileSaver from 'file-saver';
import moment from 'moment';
import * as XLSX from 'xlsx';

import CommonHelper from '../../helpers/CommonHelper';
import {
  ADDITIONAL_EDUCATION_LESSONS,
  ARCHIVED_SCHOOL_LESSONS,
  EVENT_TYPES,
  EVENT_TYPES_COLOR,
  SCHOOL_LESSONS,
} from './constants';

const parseEventsForXLSX = (events) => {
  return events.map((event) => ({
    A: moment(event.start).format('DD.MM.YYYY'),
    B: moment(event.start).format('HH:mm'),
    C: event.extendedProps?.roomName,
    D: moment(event.start - event.end).format('Hч. mmм.'),
    E: event.extendedProps?.roomName,
    F: event.title,
    G: event.extendedProps?.groupName,
    //H: lockerRoomValue,
    //I: eventTeachers
  }));
};

export const formatFilterDataForRequest = (filter) => {
  const formattedFilter = filter.schoolId
    ? {
        schoolIds: filter.schoolId.value,
        startDate: filter.startDate,
        endDate: filter.endDate,
      }
    : {
        startDate: filter.startDate,
        endDate: filter.endDate,
      };

  const {studentIds, roomIds, groupIds} = filter;

  const clearFilter = {
    studentIds,
    roomIds,
    groupIds,
  };

  Object.entries(clearFilter).map(([key, value]) => {
    if (value?.length) {
      formattedFilter[key] = value.map((el) => el.value).join(',');
    }
  });

  return formattedFilter;
};

export const exportCSV = (filteredEvents, filter) => {
  const {groupIds, roomIds, studentIds, teacherIds} = filter;
  const xlsxFilteredLessons = parseEventsForXLSX(filteredEvents);
  const xlsxFormat = [
    {
      A: 'Расписание ',
      B: filter.schoolId?.label,
    },
    {},
    {
      A: 'Фильтры:',
    },
    roomIds.length && {
      A: 'Выбранные помещения:',
      B: roomIds.map((roomItem) => roomItem.label).join(', '),
    },
    groupIds.length && {
      A: 'Выбранные команды:',
      B: groupIds.map((groupItem) => groupItem.label).join(', '),
    },
    teacherIds.length && {
      A: 'Выбранные тренеры:',
      B: teacherIds.map((teacherItem) => teacherItem.label).join(', '),
    },
    studentIds.length && {
      A: 'Выбранные игроки:',
      B: studentIds.map((studentItem) => studentItem.label).join(', '),
    },
    {
      A: 'Дата',
      B: 'Время начала',
      C: 'Помещение',
      D: 'Длительность',
      E: 'Тип',
      F: 'Мероприятие (название)',
      G: 'Команда',
      // H: 'Раздевалка',
      // I: 'Тренер',
    },
    ...xlsxFilteredLessons,
  ];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(xlsxFormat, {skipHeader: true});
  const wb = {Sheets: {data: ws}, SheetNames: ['data']};
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, 'Расписание Академии' + fileExtension);
};

export const parseLessonsForCalendar = (lessons) => {
  return lessons?.reduce((acc, lesson) => {
    let constructedTitle = '';
    if (!lesson.fullName && lesson?.type === 'game') {
      constructedTitle = `Игра команды (домашняя)`;
    }
    if (!lesson.fullName && lesson?.type === 'away_game') {
      constructedTitle = `Игра команды (выездная)`;
    }
    return acc.concat(
      lesson.rooms.map((room) => ({
        title: lesson?.fullName || constructedTitle,
        description: lesson?.description || '',
        start: new Date(room.startTimestamp),
        end: new Date(room.startTimestamp + room.duration),
        allDay: false,
        id: `${lesson.id}${moment(room.startTimestamp).format('DD-MM-YYYY HH:mm')}`,
        backgroundColor: EVENT_TYPES_COLOR[lesson.type] || '#990011',
        extendedProps: {
          type: lesson.type,
          roomName: room.name,
          groupId: lesson.groupId,
          groupName: lesson?.group?.name || '',
          id: lesson.id,
          lesson: lesson,
        },
      })),
    );
  }, []);
};

export const getNearest15Minutes = () => {
  const date = new Date();
  const nearestMinutes = Math.ceil(date.getMinutes() / 15) * 15;
  date.setMinutes(nearestMinutes, 0, 0);
  return date;
};

export const parseLessonForCreate = (formData) => {
  const formattedFormData = {
    type: formData.type.value,
    rooms: [
      {
        roomId: formData.room?.value || '',
        name: formData.room?.label,
        startTimestamp: new Date(formData.startTime).getTime(),
        duration: new Date(formData.endTime).getTime() - new Date(formData.startTime).getTime(),
      },
    ],
    description: formData?.description || formData?.lesson?.value || '',
    fullName: formData.name || formData.type.label,
    groupId: formData?.team ? formData?.team?.value || formData?.team?.map((item) => item?.value)?.join(',') : '',
    lockerRoom: formData.lockerRoom?.map((room) => room.label).join(', '),
    name: formData.name || formData.type.label,
    playerRole: formData?.playerRole || null,
    schoolId: formData.school?.value || '',
    opponent: formData?.rival || '',
    startTimestamp: new Date(formData.startTime).getTime(),
    timestamp: new Date().getTime(),
    studentsIds: formData.players?.map((player) => player.value),
    streamingURL: formData.streamingURL,
    missedGoals: [],
    removals: [],
    studentsScoresMap: {},
    teachersIds: [],
    goalkeepersAttempts: [],
    goals: [],
    fieldPlayersAttempts: [],
    control: false,
    isRepeat: false,
    link1: [],
    link2: [],
    link3: [],
    link4: [],
    gameStudentsIds: [],
  };
  const isGame = formData.type.value === 'game' || formData.type.value === 'away_game';
  const formDataWithTournamentId = !formData?.tournamentId?.isMKC
    ? {
        ...formattedFormData,
        tournamentId: formData?.tournamentId?.value || '',
      }
    : {
        ...formattedFormData,
        mkcTournamentId: formData?.tournamentId?.value || '',
      };
  return !isGame ? formattedFormData : formDataWithTournamentId;
};
export const parseLessonForEdit = (lessonObject, lessonTournament, schools, groups, players) => {
  const room = lessonObject.rooms[0];

  const getLessonPlayersIds = () => {
    if (lessonObject.studentsIds?.length) {
      return lessonObject.studentsIds;
    }
    if (lessonObject.link1?.length) {
      return lessonObject.link1;
    }
    if (lessonObject.link2?.length) {
      return lessonObject.link2;
    }
    if (lessonObject.link3?.length) {
      return lessonObject.link3;
    }
    if (lessonObject.link4?.length) {
      return lessonObject.link4;
    }
    if (lessonObject.gameStudentsIds?.length) {
      return lessonObject.gameStudentsIds;
    }
  };

  const schoolsOptions = CommonHelper.getDropDownItemsByNamedList(schools);
  const groupsOptions = CommonHelper.getDropDownItemsByNamedList(groups);
  const playersOptions = CommonHelper.getDropDownItemsByUsers(players);
  const lessonPlayersIds = getLessonPlayersIds();

  const formattedLesson = {
    type: EVENT_TYPES.find((t) => t.value === lessonObject.type) || '',
    startTime: new Date(room.startTimestamp),
    endTime: new Date(room.startTimestamp + room.duration),
    room: room
      ? {
          label: room.name,
          value: room.roomId,
        }
      : '',
    lesson:
      lessonObject.type === 'additional_education' ||
      lessonObject.type === 'school_lessons' ||
      lessonObject.type === 'group_consultation' ||
      lessonObject.type === 'personal_consultation'
        ? SCHOOL_LESSONS.find((l) => l.value === lessonObject.description) ||
          ADDITIONAL_EDUCATION_LESSONS.find((l) => l.value === lessonObject.description) ||
          ARCHIVED_SCHOOL_LESSONS.find((l) => l.value === lessonObject.description)
        : '',
    name: lessonObject.type === 'educational_event' || lessonObject.type === 'master' ? lessonObject.name : '',
    description: lessonObject?.description || '',
    rival: lessonObject.opponent,
    lockerRoom: lessonObject.lockerRoom?.split(', ').map((el) => ({label: el, value: el})),
    playerRole: lessonObject?.playerRole || null,
    players: playersOptions.filter((p) => lessonPlayersIds.includes(p.value)),
    team: lessonObject.groupId ? groupsOptions.find((g) => g.value === lessonObject.groupId) : '',
    school: lessonObject.schoolId ? schoolsOptions.find((s) => s.value === lessonObject.schoolId) : '',
    streamingURL: lessonObject.streamingURL,
  };

  return lessonObject.type === 'game' || lessonObject.type === 'away_game'
    ? {
        ...formattedLesson,
        tournamentId: {
          value: lessonTournament?.id || lessonTournament?.MKCId.toString(),
          label: lessonTournament?.name,
          isMKC: !!lessonTournament?.MKCId,
        },
      }
    : formattedLesson;
};

export const parseLessonForUpdate = (formData) => {
  const isGame = formData.type.value === 'game' || formData.type.value === 'away_game';
  const formattedFormData = {
    id: formData.id,
    type: formData.type.value,
    rooms: [
      {
        roomId: formData.room?.value || '',
        name: formData.room?.label,
        startTimestamp: new Date(formData.startTime).getTime(),
        duration: new Date(formData.endTime).getTime() - new Date(formData.startTime).getTime(),
      },
    ],
    description: formData?.description || formData?.lesson?.value || '',
    fullName: formData.name || formData.type.label,
    groupId: formData?.team ? formData?.team?.value || formData?.team?.map((item) => item?.value)?.join(',') : '',
    isRepeat: false,
    lockerRoom: formData.lockerRoom?.map((room) => room.label).join(', '),
    name: formData.name || formData.type.label,
    schoolId: formData.school.value,
    opponent: formData?.rival || '',
    playerRole: formData?.playerRole || null,
    startTimestamp: new Date(formData.startTime).getTime(),
    timestamp: new Date().getTime(),
    studentsIds: formData.players?.map((player) => player.value),
    streamingURL: formData.streamingURL,

    link1: [],
    link2: [],
    link3: [],
    link4: [],
    gameStudentsIds: [],
    missedGoals: [],
    removals: [],
    studentsScoresMap: {},
    teachersIds: [],
    goalkeepersAttempts: [],
    goals: [],
    fieldPlayersAttempts: [],
    control: false,
  };
  const formattedFormDataWithTournament = !formData?.tournamentId?.isMKC
    ? {...formattedFormData, tournamentId: formData?.tournamentId?.value || '', mkcTournamentId: ''}
    : {...formattedFormData, mkcTournamentId: formData?.tournamentId?.value || '', tournamentId: ''};
  return !isGame ? formattedFormData : formattedFormDataWithTournament;
};
