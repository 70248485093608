import {coords, maxX, maxY, minX, minY} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {convertToPixels} from '@helpers/coords.helper';
import useElementSize from '@hooks/useElementSize';
import {Box} from '@ui/MUI';
import {black, primary} from '@ui/MUI/colorsA1Tag';
import React, {memo, SVGProps, useEffect, useMemo} from 'react';

export interface ArenaSchemaProps extends SVGProps<SVGSVGElement> {
  eclipses?: Array<{x: number; y: number}>;
  isShotOnGoal?: boolean;
}

export const ArenaSchema = memo(({eclipses, isShotOnGoal, ...props}: ArenaSchemaProps) => {
  useEffect(() => {
    return () => {
      coords.cleanUp();
    };
  }, []);

  const [ref, {width, height}] = useElementSize();

  const formattedEclipses = useMemo(() => {
    if (width && height) {
      return eclipses?.map((eclipse) => {
        const {clickX, clickY} = convertToPixels(eclipse?.x, eclipse?.y, width, height, minX, maxX, minY, maxY);
        return {...eclipse, realX: clickX, realY: clickY};
      });
    }
    return [];
  }, [eclipses, width, height]);

  return (
    <Box ref={ref} sx={{position: 'relative'}}>
      {formattedEclipses?.map((eclipse, index) => {
        const bgColorForFewEclipses = index === 0 ? black[8] : primary.main;
        return (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              bottom: eclipse.realY,
              left: eclipse.realX,
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: isShotOnGoal ? bgColorForFewEclipses : primary.main,
            }}
          />
        );
      })}
      <svg {...props} fill="none" viewBox="0 0 1056 519" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_19888_99105)">
          <path
            d="M952.131 0H103.869C46.5037 0 0 46.4728 0 103.8V415.2C0 472.527 46.5037 519 103.869 519H952.131C1009.5 519 1056 472.527 1056 415.2V103.8C1056 46.4728 1009.5 0 952.131 0Z"
            fill="#F5F5F5"
          />
          <path d="M1056 0H0V519H1056V0Z" fill="#F5F5F5" />
          <path d="M528 519V0" stroke="#D5D5D5" strokeWidth="2" />
          <path
            d="M527.999 321.78C562.418 321.78 590.32 293.896 590.32 259.5C590.32 225.103 562.418 197.22 527.999 197.22C493.58 197.22 465.678 225.103 465.678 259.5C465.678 293.896 493.58 321.78 527.999 321.78Z"
            stroke="#D5D5D5"
            strokeWidth="2"
          />
          <path d="M660.26 0V519" stroke="#D5D5D5" strokeWidth="2" />
          <path d="M882.886 138.4L986.755 228.36V290.64L882.886 380.6H804.984V138.4H882.886Z" fill="#E1E1E1" />
          <path
            d="M986.754 230.09C978.948 230.09 971.463 233.188 965.944 238.704C960.425 244.219 957.324 251.699 957.324 259.5C957.324 267.3 960.425 274.781 965.944 280.296C971.463 285.811 978.948 288.91 986.754 288.91V230.09Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path d="M173.115 138.4L69.2461 228.36V290.64L173.115 380.6H251.017V138.4H173.115Z" fill="#E1E1E1" />
          <path
            d="M69.2461 230.09C77.0513 230.09 84.5368 233.188 90.0559 238.704C95.5749 244.219 98.6756 251.699 98.6756 259.5C98.6756 267.3 95.5749 274.781 90.0559 280.296C84.5368 285.811 77.0513 288.91 69.2461 288.91V230.09Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path d="M986.754 0V519" stroke="#D5D5D5" strokeWidth="2" />
          <path
            d="M882.886 60.55C925.91 60.55 960.788 95.4046 960.788 138.4C960.788 181.395 925.91 216.25 882.886 216.25C839.862 216.25 804.984 181.395 804.984 138.4C804.984 95.4046 839.862 60.55 882.886 60.55Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path
            d="M882.885 133.21C885.753 133.21 888.078 135.533 888.078 138.4C888.078 141.266 885.753 143.59 882.885 143.59C880.017 143.59 877.691 141.266 877.691 138.4C877.691 135.533 880.017 133.21 882.885 133.21Z"
            fill="#979797"
          />
          <path d="M986.754 214.52L1054.27 186.84" stroke="#D5D5D5" strokeWidth="2" />
          <path d="M986.754 304.48L1054.27 332.16" stroke="#D5D5D5" strokeWidth="2" />
          <path
            d="M882.886 302.75C925.91 302.75 960.788 337.605 960.788 380.6C960.788 423.596 925.91 458.45 882.886 458.45C839.862 458.45 804.984 423.596 804.984 380.6C804.984 337.605 839.862 302.75 882.886 302.75Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path
            d="M882.885 375.41C885.753 375.41 888.078 377.734 888.078 380.6C888.078 383.466 885.753 385.79 882.885 385.79C880.017 385.79 877.691 383.466 877.691 380.6C877.691 377.734 880.017 375.41 882.885 375.41Z"
            fill="#979797"
          />
          <path d="M395.74 0V519" stroke="#D5D5D5" strokeWidth="2" />
          <path d="M160.996 48.4404V62.2804" stroke="#979797" strokeWidth="2" />
          <path d="M160.996 214.52V228.36" stroke="#979797" strokeWidth="2" />
          <path d="M185.232 48.4404V62.2804" stroke="#979797" strokeWidth="2" />
          <path d="M185.232 214.52V228.36" stroke="#979797" strokeWidth="2" />
          <path d="M160.996 290.64V304.48" stroke="#979797" strokeWidth="2" />
          <path d="M160.996 456.72V470.56" stroke="#979797" strokeWidth="2" />
          <path d="M185.232 290.64V304.48" stroke="#979797" strokeWidth="2" />
          <path d="M185.232 456.72V470.56" stroke="#979797" strokeWidth="2" />
          <path d="M870.768 48.4404V62.2804" stroke="#979797" strokeWidth="2" />
          <path d="M870.768 214.52V228.36" stroke="#979797" strokeWidth="2" />
          <path d="M895.004 48.4404V62.2804" stroke="#979797" strokeWidth="2" />
          <path d="M895.004 214.52V228.36" stroke="#979797" strokeWidth="2" />
          <path d="M870.768 290.64V304.48" stroke="#979797" strokeWidth="2" />
          <path d="M870.768 456.72V470.56" stroke="#979797" strokeWidth="2" />
          <path d="M895.004 290.64V304.48" stroke="#979797" strokeWidth="2" />
          <path d="M895.004 456.72V470.56" stroke="#979797" strokeWidth="2" />
          <path d="M69.2452 214.52L1.73047 186.84" stroke="#D5D5D5" strokeWidth="2" />
          <path d="M69.2452 304.48L1.73047 332.16" stroke="#D5D5D5" strokeWidth="2" />
          <path
            d="M173.114 302.75C130.09 302.75 95.2123 337.605 95.2123 380.6C95.2123 423.596 130.09 458.45 173.114 458.45C216.138 458.45 251.016 423.596 251.016 380.6C251.016 337.605 216.138 302.75 173.114 302.75Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path
            d="M173.115 375.41C170.247 375.41 167.922 377.734 167.922 380.6C167.922 383.466 170.247 385.79 173.115 385.79C175.983 385.79 178.309 383.466 178.309 380.6C178.309 377.734 175.983 375.41 173.115 375.41Z"
            fill="#979797"
          />
          <path
            d="M173.114 60.55C130.09 60.55 95.2123 95.4046 95.2123 138.4C95.2123 181.395 130.09 216.25 173.114 216.25C216.138 216.25 251.016 181.395 251.016 138.4C251.016 95.4046 216.138 60.55 173.114 60.55Z"
            stroke="#979797"
            strokeWidth="2"
          />
          <path
            d="M173.115 133.21C170.247 133.21 167.922 135.533 167.922 138.4C167.922 141.266 170.247 143.59 173.115 143.59C175.983 143.59 178.309 141.266 178.309 138.4C178.309 135.533 175.983 133.21 173.115 133.21Z"
            fill="#979797"
          />
          <path d="M69.2461 0V519" stroke="#D5D5D5" strokeWidth="2" />
          <path
            d="M417.207 370.22C414.339 370.22 412.014 372.543 412.014 375.41C412.014 378.276 414.339 380.6 417.207 380.6C420.075 380.6 422.4 378.276 422.4 375.41C422.4 372.543 420.075 370.22 417.207 370.22Z"
            fill="#979797"
          />
          <path
            d="M417.207 128.02C414.339 128.02 412.014 130.344 412.014 133.21C412.014 136.077 414.339 138.4 417.207 138.4C420.075 138.4 422.4 136.077 422.4 133.21C422.4 130.344 420.075 128.02 417.207 128.02Z"
            fill="#979797"
          />
          <path
            d="M638.793 370.22C635.925 370.22 633.599 372.543 633.599 375.41C633.599 378.276 635.925 380.6 638.793 380.6C641.661 380.6 643.986 378.276 643.986 375.41C643.986 372.543 641.661 370.22 638.793 370.22Z"
            fill="#979797"
          />
          <path
            d="M638.793 128.02C635.925 128.02 633.599 130.344 633.599 133.21C633.599 136.077 635.925 138.4 638.793 138.4C641.661 138.4 643.986 136.077 643.986 133.21C643.986 130.344 641.661 128.02 638.793 128.02Z"
            fill="#979797"
          />
          <path
            d="M528 254.31C525.132 254.31 522.806 256.634 522.806 259.5C522.806 262.367 525.132 264.69 528 264.69C530.868 264.69 533.193 262.367 533.193 259.5C533.193 256.634 530.868 254.31 528 254.31Z"
            fill="#979797"
          />
        </g>
        <rect height="517" rx="59" stroke="#D5D5D5" strokeWidth="2" width="1054" x="1" y="1" />
        <defs>
          <clipPath id="clip0_19888_99105">
            <rect fill="white" height="519" rx="60" width="1056" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
});

ArenaSchema.displayName = 'ArenaSchema';
