import {Theme} from '@mui/material';
import {Box} from '@ui/MUI';
import Button from '@ui/MUI/Button';
import {black} from '@ui/MUI/colorsV2';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const OptionsListContainer = styled(Box)`
  position: relative;
  box-sizing: border-box;
  overflow-y: hidden;
  width: 100%;
  padding: ${({theme}) => theme.spacing('0px', 2, 1, 2)};
  ${({theme}) => theme.breakpoints.down('md')} {
    padding: ${({theme}) => theme.spacing(3, 5, 1, 4)};
  }
`;

export const OptionsListItem = styled(Button)<{selected: boolean; theme: Theme}>`
  ${({theme}) => theme.typography.body2}
  color: ${({theme, selected}) => (selected ? theme.palette.text.primary : theme.palette.text.secondary)};
  display: flex;
  justify-content: flex-start;
  text-transform: none;
  background: ${({selected}) => selected && black['14'] + ' !important'};
  border: none !important;
  &:hover {
    background: ${black['14']};
  }
`;

export const OptionItemTitle = styled.div`
  ${({theme}) => theme.typography.body2}
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.text.other};
  padding: ${({theme}) => theme.spacing(5, 1, 2, 1)};
`;
