import ReactTableComponent from '@common/ReactTableComponent';
import {getRoleLabel} from '@helpers/roles';
import useResize from '@hooks/useResize';
import {longDash} from '@src/constants';
import {FACELESS_AVATAR} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import {grey} from '@ui/MUI/colors';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const UsersTableContent = (props) => {
  const {items, userRole, schoolsIds} = props;

  const {screenWidth} = useResize();

  const [containerWidth, setContainerWidth] = useState(0);

  const formatedItems = useMemo(() => {
    const isAdmin = userRole === 'admin';

    if (items.length) {
      return items.map((user) => {
        const isVisableByRole = user.schools.map((school) => school.id).some((id) => schoolsIds.includes(id));
        return {
          id: user.id,
          user: {
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
            img: user.avatar ? convertImgTo200(user.avatar) : FACELESS_AVATAR,
            isVisable: isVisableByRole || isAdmin,
          },
          role: getRoleLabel(user.userRole),
          school: user.schools.length
            ? user.schools.map((school) => school.name).join(',')
            : 'Пользователь не привязан к школе',
        };
      });
    }

    return [];
  }, [items, userRole, schoolsIds]);

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      setContainerWidth(ref?.current.offsetWidth);
    }
  }, [ref?.current, screenWidth]);

  const columns = [
    {
      id: 'user',
      accessorKey: 'user',
      header: 'ФИО пользователя',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none',
        width: 310,
      },
      cell: ({getValue}) => {
        return (
          <UserCardLink to={`/users/${getValue().id}`}>
            <UserInfoContainer>
              <UserAvatarContainer>
                <UserAvatar src={getValue().img} />
              </UserAvatarContainer>
              <UserFullName>
                {getValue().lastName} {getValue().firstName}
              </UserFullName>
            </UserInfoContainer>
          </UserCardLink>
        );
      },
      cellStyle: {
        boxShadow: 'none',
        borderTop: `1px solid ${grey[12]}`,
        width: 310,
        py: 2,
      },
      sortingFn: 'defaultSorting',
    },
    {
      accessorKey: 'role',
      header: 'Роль',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none',
      },
      cellStyle: {
        boxShadow: 'none',
        borderTop: `1px solid ${grey[12]}`,
        py: 2,
      },
      cell: ({getValue}) => {
        return getValue() || longDash;
      },
    },
    {
      accessorKey: 'school',
      header: 'Школа',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none',
      },
      cellStyle: {
        boxShadow: 'none',
        borderTop: `1px solid ${grey[12]}`,
        py: 2,
      },
      cell: ({getValue}) => {
        return getValue() || longDash;
      },
    },
    {
      id: 'action',
      header: 'Действия',
      headerStyle: {
        borderTop: `1px solid ${grey[12]}`,
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
        py: 2,
      },
      cell: ({row}) => {
        if (row.original.user.isVisable) {
          return [
            <UserActionLink
              key={row.original.user.id}
              to={`/users/${row.original.user.id}?edit=true`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Редактировать
            </UserActionLink>,
          ];
        } else {
          return [<UserAction key={row.original.user.id}>{longDash}</UserAction>];
        }
      },
    },
  ];

  const [sorting, setSorting] = useState([{id: 'user', desc: true}]);

  return (
    <Container containerWidth={containerWidth} ref={ref}>
      <ReactTableComponent columns={columns} data={formatedItems} setSorting={setSorting} sorting={sorting} />
    </Container>
  );
};

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.35;
`;
const UserAvatarContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;
const UserAvatar = styled.div`
  width: 37px;
  height: 37px;
  background-color: #e7e7e7;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
const UserFullName = styled.div`
  font-weight: 600;
`;

const UserAction = styled.div`
  flex: 0.2;
  color: #990011;
  font-weight: 600;
  cursor: pointer;
`;
const UserCardLink = styled((props) => <Link {...props} />)`
  color: initial;
  text-decoration: none;
  cursor: pointer;
`;

const UserActionLink = styled((props) => <Link {...props} />)`
  flex: 0.2;
  color: #990011;
  font-weight: 600;
  cursor: pointer;
`;

const Container = styled.div`
  .MuiDataGrid {
    &-root {
      .MuiDataGrid {
        &-columnHeader {
          padding: 0 34px;
        }
        &-cell {
          padding: 0 34px;
        }
      }
    }
  }
`;

export default UsersTableContent;
