import {Section} from '../accesses.type';

export const sectionMock: Section = {
  id: 'VJmdDWpPK_Az',
  createdAt: '2024-11-04T20:45:57.709Z',
  updatedAt: '2024-11-04T20:45:57.709Z',
  name: 'Пользователи',
  url: 'users',
  subsections: [
    {
      id: 'RQLbCSzGNz6k',
      createdAt: '2024-11-04T20:45:57.809Z',
      updatedAt: '2024-11-04T20:45:57.809Z',
      name: 'Сотрудники',
      url: 'employees',
      sectionId: 'VJmdDWpPK_Az',
      users: [
        {
          id: '-7N2Pm6jyqaP',
          firstName: 'Лев ',
          lastName: 'Лабацеев ',
          middleName: 'Сергеевич',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: '2rZCGblbpv',
          firstName: 'Владимир',
          lastName: 'Малинин',
          middleName: 'Михайлович',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: '39eYvbwnVzD9',
          firstName: 'Кирилл',
          lastName: 'Мезенцев',
          middleName: 'Дмитриевич',
          userRole: 'student',
          school: {
            id: 'zAsFb02i53',
            name: '"ДЮСШ Авангард" ДХЦ',
          },
        },
        {
          id: 'HBsQ8II8pr',
          firstName: 'Илья',
          lastName: 'Рейнгардт',
          middleName: 'Андреевич',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: 'DPFyS5hQjp',
          firstName: 'Артем',
          lastName: 'Левкин',
          middleName: 'Викторович',
          userRole: 'franchisee',
          school: {},
        },
      ],
    },
    {
      id: '3weX332LPicm',
      createdAt: '2024-11-04T20:45:57.823Z',
      updatedAt: '2024-11-04T20:45:57.823Z',
      name: 'Права доступа',
      url: 'accesses',
      sectionId: 'VJmdDWpPK_Az',
      users: [
        {
          id: '-7N2Pm6jyqaP',
          firstName: 'Лев ',
          lastName: 'Лабацеев ',
          middleName: 'Сергеевич',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: '2rZCGblbpv',
          firstName: 'Владимир',
          lastName: 'Малинин',
          middleName: 'Михайлович',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: '39eYvbwnVzD9',
          firstName: 'Кирилл',
          lastName: 'Мезенцев',
          middleName: 'Дмитриевич',
          userRole: 'student',
          school: {
            id: 'zAsFb02i53',
            name: '"ДЮСШ Авангард" ДХЦ',
          },
        },
        {
          id: 'HBsQ8II8pr',
          firstName: 'Илья',
          lastName: 'Рейнгардт',
          middleName: 'Андреевич',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: 'DPFyS5hQjp',
          firstName: 'Артем',
          lastName: 'Левкин',
          middleName: 'Викторович',
          userRole: 'franchisee',
          school: {},
        },
      ],
    },
    {
      id: 'ZHUQU5OzPDx7',
      createdAt: '2024-11-04T20:45:57.823Z',
      updatedAt: '2024-11-04T20:45:57.823Z',
      name: 'Подразделы',
      url: 'sections',
      sectionId: 'VJmdDWpPK_Az',
      users: [
        {
          id: '2rZCGblbpv',
          firstName: 'Владимир',
          lastName: 'Малинин',
          middleName: 'Михайлович',
          userRole: 'student',
          school: {
            id: 'ITfD4PL0oy',
            name: '"Авангард" Академия ',
          },
        },
        {
          id: '39eYvbwnVzD9',
          firstName: 'Кирилл',
          lastName: 'Мезенцев',
          middleName: 'Дмитриевич',
          userRole: 'student',
          school: {
            id: 'zAsFb02i53',
            name: '"ДЮСШ Авангард" ДХЦ',
          },
        },
        {
          id: 'DPFyS5hQjp',
          firstName: 'Артем',
          lastName: 'Левкин',
          middleName: 'Викторович',
          userRole: 'franchisee',
          school: {},
        },
      ],
    },
    {
      id: 'wIbCvlGJX3EY',
      createdAt: '2024-11-04T20:45:57.825Z',
      updatedAt: '2024-11-04T20:45:57.825Z',
      name: 'Роли',
      url: 'roles',
      sectionId: 'VJmdDWpPK_Az',
      users: [
        {
          id: '39eYvbwnVzD9',
          firstName: 'Кирилл',
          lastName: 'Мезенцев',
          middleName: 'Дмитриевич',
          userRole: 'student',
          school: {
            id: 'zAsFb02i53',
            name: '"ДЮСШ Авангард" ДХЦ',
          },
        },
      ],
    },
    {
      id: 'ivWzmRusqDk1',
      createdAt: '2024-11-04T20:45:57.827Z',
      updatedAt: '2024-11-04T20:45:57.827Z',
      name: 'Главное',
      url: 'main',
      sectionId: 'VJmdDWpPK_Az',
      users: [],
    },
  ],
};
