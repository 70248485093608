import {Section, SubSectionUserWithSubsections} from '@components/modules/accesses/accesses.type';
import {ModalMode} from '@components/modules/common/modal-view-model';
import {rolesMap} from '@src/constants';
import {UserRoles} from '@src/types';
import {ColumnDef} from '@tanstack/react-table';
import {Button, Typography} from '@ui/MUI';
import React from 'react';

import {userDrawerModal} from '../models/main.view-model';

export const toTableData = (section: Section): SubSectionUserWithSubsections[] => {
  const subSections = section.subsections;

  const groupedByUsers: SubSectionUserWithSubsections[] = [];

  if (!subSections) return [];

  for (const subSection of subSections) {
    for (const user of subSection.users) {
      const existingGroup = groupedByUsers.find((group) => group.id === user.id);
      if (!existingGroup) {
        groupedByUsers.push({...user, userSubsections: [subSection]});
      } else {
        existingGroup.userSubsections.push(subSection);
      }
    }
  }

  return groupedByUsers;
};

export const generateColumns = (
  isLoading: boolean,
  totalSubsections: number,
): ColumnDef<SubSectionUserWithSubsections>[] => {
  return [
    {
      accessorFn: (row) => `${row.lastName} ${row.firstName}`,
      header: 'Пользователь',
      cell: ({getValue}) => {
        const name = getValue();
        return (
          <Typography isLoading={isLoading} preloaderWidthBetween={[70, 100]}>
            {name}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => rolesMap[row.userRole as UserRoles],
      header: 'Роль',
      cell: ({getValue}) => {
        const role = getValue();
        return (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]}>
            {role}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => row.school.name,
      header: 'Школа',
      cell: ({getValue}) => {
        const school = getValue();
        return (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]}>
            {school}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => row.userSubsections.length,
      header: 'Подразделы',
      cell: ({getValue}) => {
        const count = getValue();
        return (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]}>
            {count} / {totalSubsections}
          </Typography>
        );
      },
    },
    {
      id: 'edit',
      cell: ({row}) => {
        const originalRow = row.original;
        const openUserDrawer = () => {
          userDrawerModal.open(ModalMode.EDIT, {
            activeSubSectionsIds: originalRow.userSubsections.map((section) => section.id),
            userId: originalRow.id,
          });
        };
        return (
          <Button color="primary" size="small" variant="text" onClick={openUserDrawer}>
            Редактировать
          </Button>
        );
      },
    },
  ];
};
