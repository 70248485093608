import {BoxProps} from '@mui/material';
import {Box} from '@ui/MUI';
import React from 'react';

export const BoxShadow = ({children, ...props}: BoxProps) => {
  const bg = props.bgcolor ? props.bgcolor : 'white';
  const boxShadow = props.boxShadow ? props.boxShadow : '0px 4px 8px 0px #00000026';

  return (
    <Box {...props} bgcolor={bg} boxShadow={boxShadow}>
      {children}
    </Box>
  );
};
