import {getCurrentProspectReport, getProspectReport, GetProspectReportQuery} from '@api/prospect.api';
import {ReportDto} from '@src/types/generated';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';

export const useProspectReportQuery = (
  params?: UseQueryOptions<ReportDto[]>,
  playerId?: string,
  query?: GetProspectReportQuery,
) => {
  return useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['prospect', 'report', playerId, query],
    queryFn: () => {
      return getProspectReport(playerId ?? '', query ?? {});
    },
    enabled: params?.enabled ?? !!playerId,
    ...params,
  });
};

export const useCurrentProspectReportQuery = (playerId: string) => {
  return useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['prospect', 'report', playerId],
    queryFn: () => {
      return getCurrentProspectReport(playerId);
    },
  });
};
