import {TeacherSubroles} from '@src/types';

export function PrintElem(lines, linesTeamInfo) {
  let mywindow = window.open('', 'PRINT', 'height=800,width=600');

  const avangardSvgLogo =
    '<svg xmlns="http://www.w3.org/2000/svg" width="61" height="32" viewBox="0 0 61 32" fill="none">\n' +
    '' +
    '<path d="M60.7133 15.3063C60.5551 13.0541 58.9 10.7771 55.3734 7.97302V7.97193C55.5392 7.59676 55.6238 6.05702 53.8665 5.13318C49.4404 2.8149 39.9402 0.373008 32.8013 0C32.8716 0.189749 33.0386 0.718906 33.1572 1.1136C25.2836 0.793874 17.397 1.01198 9.55387 1.76636C7.40342 1.97564 0.719238 2.88863 0.719238 2.88863L15.8218 9.28288C14.2106 9.64288 12.5742 10.0311 10.9278 10.4539C9.24745 10.8877 4.27 12.5651 4.27 12.5651L10.3073 13.8685C13.3133 14.5147 21.7899 16.3082 25.4088 17.1345C24.9299 18.5983 21.8141 28.7399 21.8141 28.7399C21.8141 28.7399 25.8492 24.5154 27.1023 23.3357C28.6948 21.8339 29.222 21.3254 29.6998 20.9361C29.5164 23.096 29.11 27.7315 29.0057 28.8733C28.9222 29.7863 29.1023 30.7329 29.6361 31.2859C29.9268 31.5511 30.2725 31.7505 30.6492 31.8703C31.0259 31.9902 31.4245 32.0276 31.8173 31.9799C32.5627 31.8316 33.2557 31.4928 33.8272 30.9975C36.2588 29.0089 42.3499 23.6317 47.5009 18.685C47.7908 18.6417 48.0797 18.6015 48.3707 18.5712C51.9072 18.1841 55.3306 18.7414 55.916 20.4915C56.2982 21.6409 55.905 22.349 55.548 23.4495C56.1543 22.877 57.7204 21.4501 58.3322 20.691C59.3283 19.4571 60.8692 17.5411 60.7122 15.3063" fill="black"/>\n' +
    '<path d="M33.0943 6.38014L33.1635 6.37255C32.9548 5.97136 32.7791 5.55714 32.621 5.2557C35.5183 4.91091 42.2266 5.2557 44.9042 6.95267C47.5819 8.64963 50.0146 9.23191 51.8454 8.82638C51.6452 9.21779 51.3664 9.56494 51.0261 9.84674C50.3433 9.99407 49.6388 10.0154 48.9481 9.90963C48.1903 11.149 46.3287 10.9961 43.3908 12.4643C39.2703 14.5204 35.3086 16.8732 31.5381 19.5037C31.4195 20.9458 31.1658 23.7456 31.1317 24.1825L36.9548 22.0844L35.7797 21.0944L40.7505 19.9634C41.5556 18.6731 47.7159 12.7603 48.6911 12.0425C49.0181 11.9695 49.3488 11.9142 49.6818 11.8766C50.9698 10.716 52.3973 9.71603 53.9322 8.89902C53.9587 8.81692 53.98 8.73327 53.9959 8.64855C54.0838 8.11939 54.0453 7.35168 53.3457 6.98736C41.4974 0.845769 19.5832 2.82899 7.78101 3.77777L18.9111 8.62469C24.2377 7.54254 29.1625 6.80952 33.0944 6.38014" fill="#BFBD9C"/>\n' +
    '<path d="M35.78 21.0944L36.9562 22.0843L31.1309 24.1825C31.1309 24.1825 30.819 27.9234 30.7366 28.8039C30.6268 29.9999 31.6504 30.3686 32.6213 29.5759C34.7849 27.8052 39.8206 23.4647 44.5158 19.1079L35.78 21.0944Z" fill="#6F7757"/>\n' +
    '<path d="M46.1695 10.6134C43.9598 10.9625 42.8944 9.41953 42.6989 7.79088C41.9049 7.66076 41.1141 7.58486 40.4013 7.52089C40.3651 9.56375 41.7588 11.7888 45.1152 11.2867C46.3606 11.1024 46.9581 10.6817 47.2316 10.249C46.9069 10.4418 46.5453 10.5659 46.1695 10.6134" fill="#FEFEFE"/>\n' +
    '<path d="M51.6856 12.0501C51.9185 11.5838 52.3203 11.2208 52.8113 11.033C53.5699 11.3414 54.2437 11.823 54.7773 12.4383L51.6856 12.0501ZM53.9327 8.89905C49.3133 11.2737 45.5966 16.0859 44.3105 17.8371C48.1227 16.6108 55.9975 15.9428 57.2704 19.7694C59.3593 17.0672 61.4659 14.7186 53.9316 8.89904L53.9327 8.89905Z" fill="#F2F2D9"/>\n' +
    '<path d="M26.4704 11.9124L36.1189 10.8476L30.9064 14.6557L31.0184 13.1019L26.4704 11.9124ZM41.0996 11.8148C39.517 10.9332 39.0107 9.21024 39.1831 7.59785H39.1798C36.6515 7.78977 23.0514 8.85566 10.7561 12.0978C14.7264 12.7842 27.6203 15.7671 27.6203 15.7671C27.6203 15.7671 26.209 19.9244 25.482 22.5702C30.187 18.4649 33.2974 16.048 41.0996 11.8148Z" fill="#FF0000"/>\n' +
    '</svg>';

  const fieldPlayers = lines?.playersLines
    ?.map((line) => {
      const defensemen =
        line?.defensemansLine?.map((player) => {
          return (
            '<tr>' +
            '<td style="padding:4px;width:20px;border-color: transparent;border-right: 1px solid #BDBDBD"><span class="delete-cell"></span></td>' +
            '<td>' +
            (player?.playerNumber || '') +
            '</td>' +
            '<td style="text-align:center">З</td>' +
            '<td style="width:320px;">' +
            (player?.lastName || '') +
            ' ' +
            (player?.firstName || '') +
            ' ' +
            (player?.middleName || '') +
            '</td>' +
            '<td></td>' +
            '<td>' +
            (player?.birthday || '') +
            '</td>' +
            '</tr>'
          );
        }) || [];
      const wingers =
        line?.wingersLine?.map((player) => {
          return (
            '<tr>' +
            '<td style="padding:4px;width:20px;border-color: transparent;border-right: 1px solid #BDBDBD"><span class="delete-cell"></span></td>' +
            '<td>' +
            (player?.playerNumber || '') +
            '</td>' +
            '<td style="text-align:center">Н</td>' +
            '<td style="width:320px;">' +
            (player?.lastName || '') +
            ' ' +
            (player?.firstName || '') +
            ' ' +
            (player?.middleName || '') +
            '</td>' +
            '<td></td>' +
            '<td>' +
            (player?.birthday || '') +
            '</td>' +
            '</tr>'
          );
        }) || [];
      return [...defensemen, ...wingers]?.flat()?.join('');
    })
    ?.join('');

  const goaltenders = lines?.goaltendersLines
    ?.map((player) => {
      return (
        '<tr>' +
        '<td style="padding:4px;width:20px;border-color: transparent;border-right: 1px solid #BDBDBD"><span class="delete-cell"></span></td>' +
        '<td>' +
        (player?.playerNumber || '') +
        '</td>' +
        '<td style="text-align:center">ВР</td>' +
        '<td style="width:320px;">' +
        (player?.lastName || '') +
        ' ' +
        (player?.firstName || '') +
        ' ' +
        (player?.middleName || '') +
        '</td>' +
        '<td></td>' +
        '<td>' +
        (player?.birthday || '') +
        '</td>' +
        '</tr>'
      );
    })
    .join('');

  const emptyLines = [
    ...Array(8).fill('<tr><td style="border:0;"></td><td></td><td></td><td></td><td></td><td></td></tr>'),
  ].join('');

  const teachers = [
    ...linesTeamInfo.teachers,
    ...Array(5).fill({
      lastName: '',
      firstName: '',
      middleName: '',
      teacherRole: '',
    }),
  ];

  const linesBlock = lines?.playersLines
    .map(({wingersLine, defensemansLine}, index) => {
      return `<div class="right-block__item">
    <div class="right-block__head">
      <p class="right-block__title">${index + 1} звено</p>
    </div>
    <div class="right-block__body">
      <div class="right-block__body-block">
        <p class="right-block__title">ЛН</p>
        <div>
          <div class="right-block__body-block-value">${wingersLine?.[0]?.playerNumber || ''}</div>
          <div class="right-block__body-block-value"></div>
        </div>
      </div>
      <div class="right-block__body-block">
        <p class="right-block__title">Ц</p>
        <div>
          <div class="right-block__body-block-value">${wingersLine?.[1]?.playerNumber || ''}</div>
          <div class="right-block__body-block-value"></div>
        </div>
      </div>
      <div class="right-block__body-block">
        <p class="right-block__title">ПН</p>
        <div>
          <div class="right-block__body-block-value">${wingersLine?.[2]?.playerNumber || ''}</div>
          <div class="right-block__body-block-value"></div>
        </div>
      </div>
      <div class="right-block__body-block">
        <p class="right-block__title">ЛЗ</p>
        <div>
          <div class="right-block__body-block-value">${defensemansLine?.[0]?.playerNumber || ''}</div>
          <div class="right-block__body-block-value"></div>
        </div>
      </div>
      <div class="right-block__body-block">
        <p class="right-block__title">ПЗ</p>
        <div>
          <div class="right-block__body-block-value">${defensemansLine?.[1]?.playerNumber || ''}</div>
          <div class="right-block__body-block-value"></div>
        </div>
      </div>
    </div>
    </div>`;
    })
    ?.join('');
  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write(
    '<style>h1 {\n' +
      "    font: 600 normal 18px/20px 'Proxima Nova';\n" +
      '    color: #000;\n' +
      '    text-align: center;\n' +
      '}\n' +
      'p.title {\n' +
      "    font: 600 normal 14px/16px 'Proxima Nova';\n" +
      '    color: #000;\n' +
      '}\n' +
      'p.title:not(:last-child) {\n' +
      'margin-bottom: 4px;\n' +
      '}\n' +
      'p.title:not(:first-child) {\n' +
      'margin-top: 4px;\n' +
      '}\n' +
      'table {\n' +
      'border-collapse: collapse;\n' +
      '}\n' +
      'td {\n' +
      'padding: 4px 8px;\n' +
      'height: 18px;\n' +
      'border: 1px solid #BDBDBD;\n' +
      'font-size: 10px;\n' +
      '}\n' +
      'td > .delete-cell {\n' +
      'display: block;\n' +
      'width: 16px;\n' +
      'height: 12px;\n' +
      'border: 1px solid #BDBDBD;\n' +
      '}\n' +
      '.right-block {\n' +
      'position: relative;\n' +
      'width: 30%;\n' +
      '}\n' +
      '.right-block__item {\n' +
      'margin-bottom: 14px;\n' +
      '}\n' +
      '.right-block__head {\n' +
      'background-color: #BDBDBD;\n' +
      'padding: 4px;\n' +
      '}\n' +
      '.right-block__body {\n' +
      'padding: 8px 0;\n' +
      'display: flex;\n' +
      'justify-content: center;\n' +
      'flex-wrap: wrap;\n' +
      'gap: 12px;\n' +
      '}\n' +
      '.right-block__body-block {\n' +
      'display:flex;\n' +
      '}\n' +
      '.right-block__body-block > .right-block__title {\n' +
      'padding-top:4px;\n' +
      '}\n' +
      '.right-block__body-block-value {\n' +
      'margin-left: 4px;\n' +
      'display: block;\n' +
      'width: 32px;\n' +
      'height: 18px;\n' +
      'border: 1px solid #BDBDBD;\n' +
      'text-align: center;\n' +
      '}\n' +
      '.right-block__title {\n' +
      'margin: 0;\n' +
      "font: 600 normal 14px/16px 'Proxima Nova';\n'" +
      'color: #000000;\n' +
      '}\n' +
      '.hide-when-print{\n' +
      '\tdisplay: none;\n' +
      '}</style>',
  );
  mywindow.document.write('</head><body>');
  mywindow.document.write(
    '<div style="margin-bottom: 12px">' +
      '<h1>Заявочный лист на матч</h1>' +
      '<div style="display:flex;justify-content:space-between;">' +
      '<div>' +
      '<p class="title">Соревнование: <span>' +
      (linesTeamInfo?.nearestTournament || '') +
      '</span></p>' +
      '<p class="title">Город: <span style="font-weight:normal;">' +
      (linesTeamInfo?.city || '') +
      '</span></p>' +
      '<p class="title">Дата: <span style="font-weight:normal;">' +
      (linesTeamInfo?.upcomingGame?.date || '') +
      '</span></p>' +
      '<p class="title">Команда: <span style="font-weight:normal;">' +
      (linesTeamInfo?.teamName || '') +
      '</span></p>' +
      '</div>' +
      `<div style="padding-top: 30px;padding-bottom:30px;width:30%;text-align:center;">${avangardSvgLogo}</div>` +
      '</div>' +
      '</div>' +
      '<div style="display:flex;width:100%;">' +
      '<div style="margin-right: 8px;width:70%;">' +
      '<table>' +
      '<thead style="border-color:#757575;"><tr>' +
      '<td style="padding:4px;width:20px;border-color: transparent;border-right: 1px solid #BDBDBD"></td>' +
      '<td style="padding:4px;width:20px;background-color:#BDBDBD">№</td>' +
      '<td style="padding:4px;width:36px;background-color:#BDBDBD;text-align:center">Ампл.</td>' +
      '<td style="padding:4px;width:320px;background-color:#BDBDBD">ФИО</td>' +
      '<td style="padding:4px;width:50px;background-color:#BDBDBD;text-align:center">К/А</td>' +
      '<td style="padding:4px;width:94px;background-color:#BDBDBD">Дата рожд.</td>' +
      '</tr></thead>' +
      '<tbody style="border-color:#757575">' +
      `${fieldPlayers}` +
      `${goaltenders}` +
      `${emptyLines}` +
      '</tbody>' +
      '</table>' +
      '<p class="title" style="margin-top:14px;margin-bottom:8px;">Представители команды</p>' +
      '<table>' +
      '<thead style="border-color:#757575;"><tr>' +
      '<td style="padding:4px;width:20px;background-color:#BDBDBD">№</td>' +
      '<td style="padding:4px;width:65%;background-color:#BDBDBD">ФИО</td>' +
      '<td style="padding:4px;width:320px;background-color:#BDBDBD">Должность</td>' +
      '</tr></thead>' +
      `<tbody>
        ${teachers
          ?.map((teacher, index) => {
            return `<tr>
            <td>${index + 1}</td>
            <td>${teacher?.lastName || ''} ${teacher?.firstName || ''} ${teacher?.middleName || ''}</td>
            <td>${teacher?.teacherRole ? (teacher?.teacherRole === TeacherSubroles.MAIN ? 'Главный тренер' : 'Тренер') : ' '}</td>
            </tr>`;
          })
          ?.join('')}
      </tbody>` +
      '</table>' +
      '</div>',
  );
  mywindow.document.write(
    '<div class="right-block">' +
      '<div class="right-block__item">' +
      '<div class="right-block__head">' +
      '<p class="right-block__title">Состав по пятеркам</p>' +
      '</div>' +
      '<div class="right-block__body" style="justify-content: space-between;">' +
      '<div class="right-block__body-block" style="align-items:center;">' +
      '<p class="right-block__title" style="padding-top:0;">Вр. осн.</p>' +
      '<div class="right-block__body-block-value"></div>' +
      '</div>' +
      '<div class="right-block__body-block" style="align-items:center;">' +
      '<p class="right-block__title" style="padding-top:0;">Вр. зан.</p>' +
      '<div class="right-block__body-block-value"></div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      `${linesBlock}` +
      '<div style="position:absolute;bottom:0;width:100%;display:flex;">' +
      '<p style="padding-top:4px;margin:0 18px 0 0;border-top:1px solid #525252;width:45%;">Подпись</p>' +
      '<p style="padding-top:4px;margin: 0;border-top:1px solid #525252;width:55%;">Расшифровка</p>' +
      '</div>' +
      '</div>' +
      '</div>',
  );
  mywindow.document.write('</body></html>');

  mywindow.document.close(); //necessary for IE >= 10
  mywindow.focus(); //necessary for IE >= 10

  mywindow.print();

  return true;
}
