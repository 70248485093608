import * as usersActions from '@actions/UsersActions';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {MAIN_TABS} from '@src/constants/playerPersonalFile';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router';

function PlayerPersonalFile() {
  const [{tab}, set] = useQueryString();
  const {id} = useParams();
  const dispatch = useStoreDispatch();
  const {student, loading, currentUser} = useStore((state) => state.users);
  const studentName = student?.lastName && student?.firstName ? ` ${student.lastName} ${student.firstName}` : 'Игрок';
  const isGoaltender = student?.hockeyRole === 'goaltender';
  const tabs = useMemo(() => {
    return MAIN_TABS.filter(
      (item) =>
        (!item?.roleAccess || item.roleAccess.includes(currentUser?.userRole)) &&
        (isGoaltender ? item?.id !== 'videoanalytics' : item),
    );
  }, [currentUser?.userRole]);
  const queryTabIndex = useMemo(() => tabs.findIndex((item) => tab === item.id), [tab]);
  useEffect(() => {
    dispatch(usersActions.loadStudentByIdV2(id));
    return () => {
      dispatch(usersActions.cleanStudentState());
    };
  }, [id]);
  return (
    <>
      <PageTitleDefault isLoading={loading}>{studentName}</PageTitleDefault>
      <TabsNew
        withoutWrapper
        tabs={tabs}
        value={queryTabIndex >= 0 ? queryTabIndex : 0}
        onChange={(tabIndex) => set({tab: tabs[tabIndex].id})}
      />
    </>
  );
}

export default PlayerPersonalFile;
