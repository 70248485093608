import eagle from '@assets/img/logo.svg';
import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import {Score, TeamName} from '@common/Statistics/PlayerStatistics/Throwins/Metrics/Throwins';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {longDash, TEAM_COLORS} from '@src/constants';
import {StudentSubroles} from '@src/types';
import {Box, Divider} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';

Metrics.propTypes = {
  weAtHome: PropTypes.bool,
  statisticsIds: PropTypes.array,
  getter: PropTypes.func.isRequired,
  loader: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  filters: PropTypes.object,
  homeTeamNamePlaceholder: PropTypes.string,
};

function Metrics({
  weAtHome = false,
  statisticsIds = [],
  getter = () => {},
  loader = () => {},
  cleanup = () => {},
  filters = {},
  isMKC = false,
  homeTeamNamePlaceholder = 'Домашняя команда',
  ...props
}) {
  const dispatch = useStoreDispatch();
  const teamColors = useMemo(() => (weAtHome ? [...TEAM_COLORS].reverse() : TEAM_COLORS), [weAtHome]);

  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [statisticsIds]});

  const {data, isLoading} = useStore(getter);
  useDeepEffect(() => {
    statisticsIds?.length && dispatch(loader({statisticsIds, ...filters, cancelToken: newCancelToken()}, isCancel));
    return () => {
      dispatch(cleanup());
    };
  }, [loader, statisticsIds, filters]);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <Stack
        backgroundColor="#fff"
        borderRadius="6px"
        direction="row"
        justifyContent="center"
        overflow="hidden"
        pb={2}
        position="relative"
      >
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          maxWidth={940}
          pb={6}
          pt={4}
          px={{xxs: 2, xs: 4}}
          spacing={{xxs: 2, xs: 3}}
          width="100%"
        >
          <Stack
            alignItems="center"
            direction={{xxs: 'column-reverse', sm: 'row'}}
            spacing={{xxs: 0, sm: 1}}
            sx={{
              marginBottom: {sm: '0px !important', xxs: '12px !important'},
              maxWidth: {sm: 'calc(33.333% - 40px)', xs: '100%'},
              flex: {sm: '0 0 calc(33.333% - 40px)', xs: '0 0 100%'},
            }}
            width="100%"
          >
            {!isSmResolution && <TeamLogo src={isMKC && weAtHome ? eagle : data?.homeTeamLogo} width={40} />}
            <TeamName
              bgc={teamColors[0]}
              isLoading={isLoading || props?.isLoading}
              marginLeft="16px !important"
              minWidth={(isLoading || props?.isLoading) && {xxs: 50, xs: 100, sm: 200}}
              variant={isAmateur ? 'h2Caps' : 'h2'}
            >
              {data?.homeTeamName || homeTeamNamePlaceholder}
            </TeamName>
          </Stack>
          <Box flex={{sm: '0 0 33.333%', xs: '0 0 100%'}} maxWidth={{sm: '33.333%', xs: '100%'}}>
            <Stack direction="row" justifyContent="center" spacing={1}>
              <Score
                color={data?.home < data?.away && '#666666'}
                isLoading={isLoading || props?.isLoading}
                minWidth={40}
                percent={data?.homePercent ? data?.homePercent + '%' : ''}
              >
                {data?.home || 0}
              </Score>
              <Score>{longDash}</Score>
              <Score
                color={data?.away < data?.home && '#666666'}
                isLoading={isLoading || props?.isLoading}
                minWidth={40}
                percent={data?.awayPercent ? data?.awayPercent + '%' : ''}
              >
                {data?.away || 0}
              </Score>
            </Stack>
          </Box>
          <Stack
            alignItems="center"
            direction={{xxs: 'column-reverse', sm: 'row'}}
            spacing={{xxs: 0, sm: 1}}
            sx={{
              marginTop: {sm: '0px !important', xxs: '12px !important'},
              maxWidth: {sm: 'calc(33.333% - 40px)', xs: '100%'},
              flex: {sm: '0 0 calc(33.333% - 40px)', xs: '0 0 100%'},
            }}
            width="100%"
          >
            <TeamName
              bgc={teamColors[1]}
              className="lastTeamName"
              isLoading={isLoading || props?.isLoading}
              marginRight="16px !important"
              minWidth={(isLoading || props?.isLoading) && {xxs: 50, xs: 100, sm: 200}}
              variant={isAmateur ? 'h2Caps' : 'h2'}
            >
              {data?.awayTeamName || 'Cоперник'}
            </TeamName>
            {!isSmResolution && <TeamLogo src={data?.awayTeamLogo} width={40} />}
          </Stack>
        </Stack>
      </Stack>
      <Box maxWidth="70%" mx="auto" width="100%">
        <Divider />
      </Box>
    </>
  );
}

export default memo(Metrics);
