import {SCHOOL_LESSONS} from '@components/calendar/constants';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import date from '../../../../../helpers/date';

const mobileLayOut = (eventInfo, isGame, isMobile, type, eventType) => {
  const exceptionsTypes = ['additional_education', 'group_consultation', 'personal_consultation', 'school_lessons'];

  if (type === 'event_room') {
    if (isMobile && isGame) {
      const group = eventInfo.event.extendedProps.groupName || '';
      const opponent = eventInfo.event.extendedProps.lesson?.opponent;
      const groupAndOpponnentArray = eventType === 'game' ? [group, opponent] : [opponent, group];
      return (
        <p className="custom-event__item custom-event__room">
          {groupAndOpponnentArray.map((name, index, self) => (
            <>
              {index === self.length - 1 && <br />}
              {name}
              {index === 0 && (
                <>
                  <br /> -
                </>
              )}
            </>
          ))}
        </p>
      );
    }
    return <p className="custom-event__item custom-event__room">{eventInfo.event.extendedProps.roomName}</p>;
  }

  if (type === 'event_type') {
    if (isMobile) {
      if (exceptionsTypes.includes(eventType)) {
        const schoolLesson = SCHOOL_LESSONS.find(
          (lesson) => lesson.value === eventInfo.event.extendedProps.lesson.description,
        );
        return <p className="custom-event__item custom-event__title custom-event__title_list">{schoolLesson?.label}</p>;
      }
      if (eventType === 'educational_event' || eventType === 'master') {
        return (
          <p className="custom-event__item custom-event__title custom-event__title_list">
            {eventInfo.event.extendedProps.lesson.name}
          </p>
        );
      }
    }

    return <p className="custom-event__item custom-event__title custom-event__title_list">{eventInfo.event.title}</p>;
  }
};

const ListItem = ({eventInfo, currentUser, onCalendarEventClick, size}) => {
  const startDate = date(eventInfo.event.start);
  const endDate = date(eventInfo.event.end);
  const eventType = eventInfo.event.extendedProps.type;
  const isGame = eventType === 'game' || eventType === 'away_game';
  const rolesAvailableEvaluation = ['student', 'teacher', 'franchisee'];
  const isFuture = moment().diff(endDate) < 0;
  const isMobile = size.screenWidth <= 800;
  return (
    <div>
      <div
        className="custom-event-wrapper"
        onClick={() => {
          onCalendarEventClick(eventInfo.event.extendedProps.lesson);
        }}
      >
        <p className="custom-event__date custom-event__date_list">
          <span>{startDate.format('D')}</span>
          <span>{startDate.format('dd')}</span>
        </p>
        <span
          className="custom-event__type custom-event__type_list"
          style={{backgroundColor: eventInfo.backgroundColor}}
        />
        <p className="custom-event__item custom-event__time custom-event__time_list">
          {startDate.format('HH:mm')} {eventInfo.event.end && ` - ${endDate.format('HH:mm')}`}
        </p>
        {mobileLayOut(
          eventInfo,
          isGame,
          isMobile,
          currentUser,
          'event_room',
          eventType,
          rolesAvailableEvaluation,
          isFuture,
        )}
        {mobileLayOut(
          eventInfo,
          isGame,
          isMobile,
          currentUser,
          'event_type',
          eventType,
          rolesAvailableEvaluation,
          isFuture,
        )}
        {/* <p className="custom-event__item custom-event__title custom-event__title_list">
          {eventInfo.event.title}
        </p> */}
        <p className="custom-event__item custom-event__team">{eventInfo.event.extendedProps.groupName || ''}</p>
        {!isFuture && isGame && rolesAvailableEvaluation.includes(currentUser.userRole) && (
          <Link
            className="custom-event__item custom-event__estimation-link custom-event__estimation-link_list"
            data-estimator-name={currentUser.lastName}
            data-event-name={eventInfo.event.title}
            to={`/game_estimation/${eventInfo.event.extendedProps.id}`}
          >
            <ModeEditOutlineIcon sx={{width: 13, height: 13, color: '#626262'}} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ListItem;
