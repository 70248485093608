import {Stack} from '@ui/MUI';
import React from 'react';

import {EventFilter} from './event.filter';
import {PlayerFilter} from './player.filter';
import {TeamFilter} from './team.filter';

export const Filters = () => {
  return (
    <Stack direction="row" spacing={1}>
      <EventFilter />
      <TeamFilter />
      <PlayerFilter />
    </Stack>
  );
};
