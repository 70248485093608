import {A1tagStatistic} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  a1tagViewModel,
  appendStatistic,
  updateIsTimerStart,
  updateRoasters,
  updateScore,
  updateStatistic,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {closeModal, getFormattedStatistics} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {playerViewModel} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {
  MODAL_BODY_STYLE,
  MODAL_FOOTER_STYLE,
  MODAL_HEAD_STYLE,
  MODAL_STYLE,
} from '@components/modules/A1TagLayout/constants';
import {CloseRounded} from '@mui/icons-material';
import {Modal} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, Button, IconButton, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {memo} from 'react';

type A1TagEventModalProps = {
  children?: React.ReactNode;
  maxWidth?: number;
};

// TODO: хоткии повторяют стили модального окна, нужно вынести в отдельный компонент и передавать header, body, footer
export const A1TagEventModal = memo(({maxWidth = 850, children}: A1TagEventModalProps) => {
  useSignals();
  const {isOpen, payload, isEdit} = modalViewModel;

  const {homeTeam, awayTeam} = a1tagViewModel?.rosters?.value ?? {};

  const updateGoals = (statistics: A1tagStatistic) => {
    if (statistics?.team_name === homeTeam?.name) {
      updateScore('home', a1tagViewModel.homeScore.value + 1);
    }
    if (statistics?.team_name === awayTeam?.name) {
      updateScore('away', a1tagViewModel.awayScore.value + 1);
    }
  };

  return (
    <Modal open={isOpen.value} onClose={closeModal}>
      <Box
        sx={{
          ...MODAL_STYLE,
          maxWidth: maxWidth,
        }}
      >
        <Box sx={MODAL_HEAD_STYLE}>
          <Typography color={black[75]} textTransform="uppercase" variant="overline2">
            {payload?.value?.eventName}
          </Typography>
          <IconButton
            sx={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}}
            onClick={closeModal}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={MODAL_BODY_STYLE}>{children}</Box>
        <Box sx={MODAL_FOOTER_STYLE}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" size="medium" sx={{mr: 0}} variant="contained" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              id="a1tag_event_save_button"
              size="medium"
              sx={{ml: 0}}
              variant="contained"
              onClick={() => {
                const randomId = Math.floor(Math.random() * 1000).toString();
                const statistics = getFormattedStatistics();
                const hasFaceOff = a1tagViewModel.statistics.value
                  .map((item) => item?.action_name)
                  .includes('Face-off');
                if (
                  !isEdit?.value &&
                  payload.value.action_name !== 'Goal' &&
                  (payload.value.eventName === 'Face-off' || hasFaceOff)
                ) {
                  updateIsTimerStart(playerViewModel?.isPlaying?.value);
                }
                if (!a1tagViewModel?.isGodMode?.value && payload.value.pos_x && payload.value.pos_y) {
                  if (!isEdit?.value) {
                    if (statistics?.action_name === 'Goal') {
                      updateGoals(statistics);
                      if (statistics?.assistants?.[0]) {
                        appendStatistic({
                          ...statistics,
                          id: randomId,
                          action_name: 'First assist',
                          player_name: statistics?.assistants?.[0]?.label,
                          player_id: statistics?.assistants?.[0]?.value,
                          team_name: statistics?.assistants?.[0]?.group?.name,
                          jersey_player: statistics?.assistants?.[0]?.playerNumber,
                          goals_team1: a1tagViewModel?.homeScore?.value,
                          goals_team2: a1tagViewModel?.awayScore?.value,
                        });
                      }
                      if (statistics?.assistants?.[1]) {
                        appendStatistic({
                          ...statistics,
                          id: randomId,
                          action_name: 'Second assist',
                          player_name: statistics?.assistants?.[1]?.label,
                          player_id: statistics?.assistants?.[1]?.value,
                          team_name: statistics?.assistants?.[1]?.group?.name,
                          jersey_player: statistics?.assistants?.[1]?.playerNumber,
                          goals_team1: a1tagViewModel?.homeScore?.value,
                          goals_team2: a1tagViewModel?.awayScore?.value,
                        });
                      }
                    }
                    if (statistics?.action_name === 'Penalty' && statistics?.another_player?.value) {
                      appendStatistic({
                        ...statistics,
                        id: randomId,
                        action_name: 'LD substitution',
                        player_name: statistics?.another_player?.label,
                        player_id: statistics?.another_player?.value,
                        team_name: statistics?.another_player?.group?.name,
                        jersey_player: statistics?.another_player?.playerNumber,
                      });
                    }
                    appendStatistic({
                      ...statistics,
                      id: randomId,
                      goals_team1: a1tagViewModel?.homeScore?.value,
                      goals_team2: a1tagViewModel?.awayScore?.value,
                    });
                  } else {
                    if (statistics?.action_name === 'Goal') {
                      if (statistics?.assistants?.[0]) {
                        updateStatistic({
                          ...statistics,
                          id: payload?.value?.id,
                          action_name: 'First assist',
                          player_name: statistics?.assistants?.[0]?.label,
                          player_id: statistics?.assistants?.[0]?.value,
                          team_name: statistics?.assistants?.[0]?.group?.name,
                          jersey_player: statistics?.assistants?.[0]?.playerNumber,
                          goals_team1: a1tagViewModel?.homeScore?.value,
                          goals_team2: a1tagViewModel?.awayScore?.value,
                        });
                      }
                      if (statistics?.assistants?.[1]) {
                        updateStatistic({
                          ...statistics,
                          id: payload?.value?.id,
                          action_name: 'Second assist',
                          player_name: statistics?.assistants?.[1]?.label,
                          player_id: statistics?.assistants?.[1]?.value,
                          team_name: statistics?.assistants?.[1]?.group?.name,
                          jersey_player: statistics?.assistants?.[1]?.playerNumber,
                          goals_team1: a1tagViewModel?.homeScore?.value,
                          goals_team2: a1tagViewModel?.awayScore?.value,
                        });
                      }
                    }
                    if (statistics?.action_name === 'Penalty' && statistics?.another_player?.value) {
                      updateStatistic({
                        ...statistics,
                        id: payload?.value?.id,
                        action_name: 'LD substitution',
                        player_name: statistics?.another_player?.label,
                        player_id: statistics?.another_player?.value,
                        team_name: statistics?.another_player?.group?.name,
                        jersey_player: statistics?.another_player?.playerNumber,
                        goals_team1: a1tagViewModel?.homeScore?.value,
                        goals_team2: a1tagViewModel?.awayScore?.value,
                      });
                    }
                    updateStatistic({id: payload?.value?.id, ...statistics});
                  }
                }
                if (!a1tagViewModel?.isGodMode?.value && payload.value.eventName.includes('Edit')) {
                  updateRoasters(payload.value.rosters);
                }
                closeModal();

                return;
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
});

A1TagEventModal.displayName = 'A1TagEventModal';
