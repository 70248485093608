import Puck from '@common/Icons/PuckIcon';
import useDeepMemo from '@hooks/useDeepMemo';
import useStoreDispatch from '@hooks/useStoreDispatch';
import SlideshowRoundedIcon from '@mui/icons-material/SlideshowRounded';
import {useMediaQuery} from '@mui/material';
import {setVideoModal} from '@slices/statistics';
import {Divider, IconButton, Stack, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsV2';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

ProtocolItem.propTypes = {
  playerName: PropTypes.string,
  time: PropTypes.string,
  majority: PropTypes.bool,
  minority: PropTypes.bool,
  isFinalGoal: PropTypes.bool,
  isAway: PropTypes.bool,
  assists: PropTypes.arrayOf(
    PropTypes.shape({
      playerName: PropTypes.string,
    }),
  ),
  isToggled: PropTypes.bool,
  isV2: PropTypes.bool,
};

function ProtocolItem(props) {
  const {
    time,
    playerName,
    isFinalGoal,
    isAway,
    isToggled,
    assists,
    majority,
    minority,
    index,
    currentIndex,
    linkToVideo,
    isV2 = false,
  } = props;
  const dispatch = useStoreDispatch();
  const shortenedSurnameAssists = useDeepMemo(
    () =>
      assists?.length
        ? assists
            ?.map(({playerName}) => {
              const [name, surname] = playerName.split(' ');
              return `${surname} ${name ? name[0] + '.' : ''}`;
            })
            ?.join(' + ')
        : '',
    [assists],
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const desctopWrapperDirection = isAway ? 'row' : 'row-reverse';
  const mobileWrapperDirection = isAway ? 'row-reverse' : 'row';
  const item = (
    <Wrapper
      direction={!isMobile ? desctopWrapperDirection : mobileWrapperDirection}
      final={isFinalGoal}
      py={1}
      spacing={2}
    >
      {!isMobile && <Divider borderColor={grey[24]} borderWidth="1px" width={12} />}
      {linkToVideo && (
        <IconButton
          color={isV2 ? 'primary' : 'secondaryInverse'}
          size="small"
          onClick={() => dispatch(setVideoModal({isOpen: true, data: props}))}
        >
          <SlideshowRoundedIcon size="small" sx={{fill: isV2 ? primary?.main : grey['54']}} />
        </IconButton>
      )}
      <Puck fontSize="inherit" sx={{fill: isFinalGoal ? primary.main : grey['54']}} />
      <Typography color={isV2 ? black[78] : grey[68]} mr={isMobile && '8px !important'} variant="subtitle2">
        {time + '′'}
        {currentIndex === 4 && !isToggled && (
          <Spacer away={isAway} className="more-spacer" index={index} variant="body2">
            ...
          </Spacer>
        )}
      </Typography>
      <Stack
        alignItems={'center'}
        direction={!isMobile ? desctopWrapperDirection : mobileWrapperDirection}
        flexWrap={{xxs: 'wrap', xs: 'nowrap'}}
        mx={isMobile && '8px !important'}
        spacing={2}
      >
        <Stack
          alignItems={isAway ? 'flex-start' : {xxs: 'flex-start', xs: 'flex-end'}}
          direction="column"
          flexWrap={{xxs: 'wrap', xs: 'nowrap'}}
          width={{xxs: '100%', xs: 'auto'}}
        >
          {!isMobile ? (
            <Typography variant="subtitle2">{playerName || 'Соперник'}</Typography>
          ) : (
            playerName && <Typography variant="subtitle2">{playerName}</Typography>
          )}
          <Typography sx={{whiteSpace: {xxs: 'nowrap', xs: 'initial'}}} variant={isV2 ? 'caption' : 'caption2'}>
            {shortenedSurnameAssists}
          </Typography>
        </Stack>
        <LineupLabel>
          <Typography color="#fff" variant="overline2">
            {majority && 'БЛШ'}
            {minority && 'МНШ'}
          </Typography>
        </LineupLabel>
      </Stack>
    </Wrapper>
  );

  return (
    <tr className="protocol-item-wrapper">
      <Td width="50%">{isAway ? null : item}</Td>
      <Td className="protocol-item" width="50%">
        {isAway ? item : null}
      </Td>
    </tr>
  );
}

export default ProtocolItem;
const Td = styled.td`
  vertical-align: center;
`;
const Wrapper = styled(Stack)`
  align-items: center;
  position: relative;
  height: 100%;
`;

const Spacer = styled(Typography)`
  text-align: center;
  display: inline-flex;
  align-items: center;
  position: absolute;
  width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  left: ${({away}) => (away ? '-6px' : 'calc(100% - 6px)')};
  top: ${({index}) => (index === 4 ? '70%' : 0)};
  background-color: ${grey['4']};
  ${({theme}) => theme.breakpoints.down('xs')} {
    left: -6px;
  }
`;

const LineupLabel = styled(Stack)`
  border-radius: 4px;
  padding: ${({theme}) => theme.spacing('0', 0, '0', 0)};
  background-color: ${({theme}) => theme.palette.primary.main};

  ${({theme}) => theme.breakpoints.down('xs')} {
    margin-top: ${({theme}) => theme.spacing(1)} !important;
    margin-left: 0 !important;
  }
`;
