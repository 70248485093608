import {A1tagStatistic} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';

import {CheckBoxCell} from '../components/cells/checkbox.cell';
import {CheckBoxAllCell} from '../components/cells/checkboxAll.cell';
import {DefaultCell} from '../components/cells/default.cell';
import {EventCell} from '../components/cells/event.cell';
import {PlayerCell} from '../components/cells/player.cell';
import {TimeCell} from '../components/cells/time.cell';

export const columns = [
  {
    id: 'checkbox',
    header: CheckBoxAllCell,
    cell: CheckBoxCell,
  },
  {accessorKey: 'action_id', header: '№', cell: DefaultCell},
  {accessorKey: 'second_clear', header: 'Clean time', cell: TimeCell},
  {accessorKey: 'video_time_start', header: 'Start time', cell: TimeCell},
  {accessorKey: 'time', header: 'Time', cell: TimeCell},
  {accessorKey: 'video_time_end', header: 'End time', cell: TimeCell},
  {
    accessorKey: 'action_name',
    header: 'Event',
    cell: EventCell,
  },
  {accessorKey: 'player_id', header: 'Player', cell: PlayerCell},
  {accessorKey: 'opponent_id', header: 'Player2', cell: PlayerCell},
  {accessorKey: 'half', header: 'Period', cell: DefaultCell},
  {
    id: 'lines',
    accessorFn: (row: A1tagStatistic) => {
      return `${row.plrs_team1} - ${row.plrs_team2}`;
    },
    header: 'Lines',
    cell: DefaultCell,
  },
  {
    id: 'score',
    header: 'Score',
    accessorFn: (row: A1tagStatistic) => {
      return `${row?.goals_team1 || 0} - ${row?.goals_team2 || 0}`;
    },
    cell: DefaultCell,
  },
];
