import PlayOff from '@common/Statistics/PlayOff';
import {A1TagAddingRosters} from '@components/apps/A1TagAddingRosters';
import {A1TagMainWindow} from '@components/apps/A1TagMainWindow';
import AddStatsApp from '@components/apps/AddStats';
import DevelopmentJournal from '@components/apps/DevelopmentJournal';
import DevelopmentJournalDetails from '@components/apps/DevelopmentJournalDetails';
import DevelopmentJournalPlayer from '@components/apps/DevelopmentJournalPlayer';
import DevelopmentJournalPlayerSkills from '@components/apps/DevelopmentJournalPlayerSkills';
import EditStatsApp from '@components/apps/EditStats';
import EditStatsForm from '@components/apps/EditStats/components/EditStatsForm';
import FranchiseeSchoolsApp from '@components/apps/FranchiseeSchoolsApp';
import CreateEditReport from '@components/apps/FunctionalTesting/components/CreateEditReport';
import CreateEditStagedTestingReport from '@components/apps/FunctionalTesting/components/CreateEditStagedTestingReport';
import FunctionalStateDynamic from '@components/apps/FunctionalTesting/components/FunctionalStateDynamic';
import Report from '@components/apps/FunctionalTesting/components/Report';
import ReportsInformation from '@components/apps/FunctionalTesting/components/ReportsInformation';
import FunctionalTesting from '@components/apps/FunctionalTesting/main';
import GameStatisticsParsingCheck from '@components/apps/GameStatisticsParsingCheck/main';
import {ManagerDashboard} from '@components/apps/ManagerDashboard';
import {MaterialsPage, MaterialsSectionPage} from '@components/apps/MaterialsModule';
import MaterialPage from '@components/apps/MaterialsModule/pages/MaterialPage';
import {BodyMassIndex} from '@components/apps/NewBodyMassIndex';
import PhysicalTestingAppNew from '@components/apps/PhysicalTestingAppNew';
import PlayerPersonalFile from '@components/apps/PlayerPersonalFile';
import {Statistics, StatisticsWithMkcUral} from '@components/apps/StatisticsWithMkcUral';
import StudentDashboard from '@components/apps/StudentDashboard';
import Tournaments from '@components/apps/Tournaments';
import TournamentsView from '@components/apps/Tournaments/components/TournamentView';
import TrainingControlProcess from '@components/apps/TrainingControlProcess';
import TrainingPage from '@components/apps/TrainingControlProcess/components/TrainingPage';
import {Section} from '@components/modules/accesses/accesses.type';
import {isHaveAccessToSection} from '@components/modules/accesses/helpers';
import {AccessesMainPage} from '@components/modules/accesses/pages/main/accesses-main.page';
import AmateurPlayerDashboard from '@components/modules/AmateurPlayerDashboard';
import {ProspectPage, ProspectPlayerPage, ProspectTeamPage} from '@components/modules/prospects/pages';
import {
  a1tag,
  accesses,
  addStats,
  bodyMassEstimation,
  developmentJournal,
  editStats,
  functionalTesting,
  lines,
  materials,
  physicalTesting,
  prospects,
  psychotest,
  psychotestReport,
  schedule,
  schools,
  statistics,
  students,
  teams,
  tournaments,
  trainerReport,
  trainingControlProcess,
  users,
  video,
} from '@src/constants/menu';
import {StudentSubroles, TeacherSubroles, UserRoles} from '@src/types';
import {MenuItem} from '@src/types/menu';
import React, {JSX} from 'react';
import {RouteProps} from 'react-router';

import AdminIndexApp from './components/apps/AdminIndexApp';
import GroupApp from './components/apps/GroupApp';
import GroupsApp from './components/apps/GroupsApp';
import HockeyLinesApp from './components/apps/HockeyLinesApp';
import LoginPage from './components/apps/LoginPage';
import MedicalProfileApp from './components/apps/MedicalProfileApp';
import CommonReportEditCreate from './components/apps/NewBodyMassIndex/components/CommonReportEditCreate';
import PsychotestApp from './components/apps/PsychotestApp';
import PsychotestReportApp from './components/apps/PsychotestReportApp';
import ScheduleApp from './components/apps/ScheduleApp';
import SchoolsApp from './components/apps/SchoolsApp';
import {InnerPlayers, OuterPlayers, ScoutReport, TeamYears, UserReports} from './components/apps/ScoutReport';
import PsychoProfileApp from './components/apps/SkillReportApp/PsychoProfileApp';
import StatsReportForOneGameApp from './components/apps/StatsReportForOneGame';
import StudentsApp from './components/apps/StudentsApp';
import VideoApp from './components/apps/TechnicalSkillsVideoEstimation/components/VideoList/components/VideoApp';
import VideosGroupListApp from './components/apps/TechnicalSkillsVideoEstimation/GroupListApp';
import VideoListApp from './components/apps/TechnicalSkillsVideoEstimation/VideoListApp';
import User from './components/apps/User/User';
import Users from './components/apps/Users/Users';
import TrainersMainDashboardPanel from './components/dashboards/trainers-dashboard/panels/TrainersMainDashboardPanel';
import TrainersPhysicalDashboardPanel from './components/dashboards/trainers-dashboard/panels/TrainersPhysicalDashboardPanel';
import ParentProfileApp from './components/ParentProfile/ParentProfileApp';
import TeacherPersonalPanel from './components/teachers/panels/TeacherPersonalPanel';

/**
 * Описание структуры путей
 * В данном массиве описываются все пути на сайте.
 * Массив содержит в себе обьекты в котором следующая струкура:
 * @path: String | обязательное поле! | путь до страницы
 * @isExact: Bool | не обязательное поле | -
 * @subRoles: {teacher: [подроли тренера],} | не обязательное поле | содержащее объект с названиями ролей и подролей для доступа к роуту
 * @component: REACT.NODE | обязательное поле! | Компонент который будет отображать контент страницы
 * @accessRoles: Array | обязательное поле! | Массив ролей которые имеют доступ к этой странице.
 * @breadCrumbs: Object | не обязательное поле | - Хлебные крошки которые будут находится над заголовком имеют следующию структуру:
 * ----
 * breadCrumbs: {label: string | не обязательное поле | - Название хлебной крошки, может быть пустым например для динамического названия
 * path: string | не обязательное поле | - Путь до страницы
 * isDynamic: Bool | не обязательное поле | - Если true тогда будет браться название с заголовка или если заголовок null будет брать label}
 */

export type RolesWithSubroles = {
  [UserRoles.TEACHER]?: TeacherSubroles[] | unknown[];
  [UserRoles.STUDENT]?: StudentSubroles[] | unknown[];
};
type Route = {
  path: RouteProps['path'];
  isExact?: boolean;
  component: JSX.Element;
  accessRoles: UserRoles[] | 'all' | 'guest' | ((sections: Section[]) => boolean);
  subRoles?: RolesWithSubroles;
  menuItem?: MenuItem;
  breadCrumbs?: {label: string; path: string | null; isDynamic?: boolean}[];
  isNewUI?: boolean;
  isA1Tag?: boolean;
  chessTemplate?: {
    isNewUI?: boolean;
    active: string;
  };
  pageTitle?: {title: string};
};
export const routes: Route[] = [
  {
    path: '/schools',
    isExact: true,
    component: <SchoolsApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Школы', path: null},
    ],
    menuItem: schools,
  },
  {
    path: '/students',
    isExact: true,
    component: <StudentsApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Игроки', path: '/students'},
    ],
    menuItem: students,
  },
  {
    path: '/teams',
    isExact: true,
    component: <GroupsApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Команды', path: null},
    ],
    menuItem: teams,
  },
  {
    path: '/teams/:id',
    isExact: true,
    component: <GroupApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Команды', path: '/teams'},
      {label: 'Команда', path: null, isDynamic: true},
    ],
  },
  {
    path: '/reports/stats_report/match_info/:statisticId',
    isExact: true,
    component: <StatsReportForOneGameApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.VIDEO_ANALYST,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
      UserRoles.DOCTOR,
      UserRoles.STUDENT,
    ],
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/reports/stats_report/match_info/:statisticId',
    isExact: true,
    component: <StatsReportForOneGameApp />,
    accessRoles: [UserRoles.STUDENT],
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
    isNewUI: true,
  },
  {
    path: '/reports/stats_report/match_info/:statisticId',
    isExact: true,
    component: <StatsReportForOneGameApp />,
    accessRoles: 'all',
    isNewUI: true,
  },
  {
    path: '/add_stats',
    isExact: false,
    component: <AddStatsApp />,
    accessRoles: [UserRoles.VIDEO_ANALYST],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Загрузка статистики', path: null},
    ],
    chessTemplate: {
      active: 'add_stats',
    },
    menuItem: addStats,
  },
  {
    path: '/edit_stats',
    isExact: true,
    component: <EditStatsApp />,
    accessRoles: [UserRoles.VIDEO_ANALYST],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Редактирование статистики', path: null},
    ],
    chessTemplate: {
      active: 'edit_stats',
    },
    menuItem: editStats,
  },
  {
    path: '/edit_stats/:id',
    isExact: true,
    component: <EditStatsForm />,
    accessRoles: [UserRoles.VIDEO_ANALYST],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Редактирование статистики', path: '/edit_stats'},
      {label: 'Редактирование данных', path: null, isDynamic: true},
    ],
    chessTemplate: {
      active: 'edit_stats',
    },
  },
  {
    path: '/edit_stats/watch/:id',
    isExact: true,
    component: <GameStatisticsParsingCheck />,
    accessRoles: [UserRoles.VIDEO_ANALYST],
    chessTemplate: {
      active: 'edit_stats',
    },
  },
  {
    path: '/psychotest_report',
    isExact: false,
    component: <PsychotestReportApp />,
    accessRoles: [
      UserRoles.STUDENT,
      UserRoles.ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.PARENT,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Отчет психологического профиля', path: '/psychotest_report'},
    ],
    menuItem: psychotestReport,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/student/:id',
    isExact: true,
    component: <PlayerPersonalFile />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Игроки', path: '/students'},
      {label: 'Игрок', path: null, isDynamic: true},
    ],
    chessTemplate: {
      active: 'students',
    },
  },
  {
    path: '/psychotest',
    isExact: false,
    component: <PsychotestApp />,
    accessRoles: [UserRoles.DOCTOR, UserRoles.PSYCHO],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Психологический профиль', path: null},
    ],
    menuItem: psychotest,
  },
  {
    path: '/schedule',
    isExact: true,
    component: <ScheduleApp />,
    accessRoles: 'all',
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Расписание', path: '/schedule'},
    ],
    menuItem: schedule,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/lines',
    isExact: true,
    component: <HockeyLinesApp />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.TEACHER,
      UserRoles.STUDENT,
      UserRoles.FRANCHISEE,
      UserRoles.PARENT,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Состав', path: '/lines'},
    ],
    chessTemplate: {
      active: 'lines',
    },
    menuItem: lines,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/users',
    isExact: true,
    component: <Users />,
    accessRoles: [UserRoles.ADMIN, UserRoles.SCHOOLS_ADMIN],
    menuItem: users,
  },
  {
    path: '/users/:id',
    isExact: true,
    component: <User />,
    accessRoles: [UserRoles.ADMIN, UserRoles.SCHOOLS_ADMIN],
  },
  {
    path: '/parentProfile',
    isExact: true,
    component: <ParentProfileApp />,
    accessRoles: [UserRoles.PARENT],
  },
  {
    path: '/trainer_report/:type/teams',
    isExact: true,
    component: <ScoutReport />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Характеристика игрока', path: '/trainer_report/inner/teams'},
    ],
    menuItem: trainerReport,
  },
  {
    path: '/trainer_report/inner/teams/:teamid',
    isExact: true,
    component: <InnerPlayers />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
  },
  {
    path: '/trainer_report/outer/teams/:teamid',
    isExact: true,
    component: <OuterPlayers />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
  },
  {
    path: '/trainer_report/:teamid/years',
    isExact: true,
    component: <TeamYears />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
  },
  {
    path: '/trainer_report/inner/users/:userId',
    isExact: true,
    component: <UserReports reportType={'inner'} />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
    chessTemplate: {
      active: 'trainer_report',
    },
  },
  {
    path: '/trainer_report/outer/users/:userId',
    isExact: true,
    component: <UserReports reportType={'outer'} />,
    accessRoles: [UserRoles.ADMIN, UserRoles.TEACHER, UserRoles.SCOUT, UserRoles.FRANCHISEE, UserRoles.METHODIST],
    chessTemplate: {
      active: 'trainer_report',
    },
  },
  {
    path: '/video',
    isExact: true,
    component: <VideoListApp isStudent />,
    accessRoles: [UserRoles.STUDENT],
    menuItem: video,
    isNewUI: true,
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
  },
  {
    path: '/video',
    isExact: true,
    component: <VideosGroupListApp />,
    accessRoles: [
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.ADMIN,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Видеоаналитика', path: '/video'},
    ],
    menuItem: video,
  },
  {
    path: '/videos/:schoolId/:groupId',
    isExact: true,
    component: <VideoListApp />,
    accessRoles: [
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.STUDENT,
      UserRoles.ADMIN,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Видеоаналитика', path: '/video'},
      {label: 'Команда', path: null, isDynamic: true},
    ],
  },
  {
    path: '/videos/:schoolId/:groupId/:videoId',
    isExact: true,
    component: <VideoApp />,
    accessRoles: [
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.STUDENT,
      UserRoles.ADMIN,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
    ],
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
    isNewUI: true,
  },
  {
    path: '/recovery',
    isExact: true,
    component: <LoginPage isRecovery />,
    accessRoles: 'guest',
  },
  {
    path: '/bmi',
    isExact: true,
    component: <BodyMassIndex />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.STUDENT,
      UserRoles.METHODIST,
    ],
    chessTemplate: {
      active: 'body-mass-estimation',
    },
    pageTitle: {title: 'Антропометрия'},
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Антропометрия', path: '/bmi'},
    ],
    menuItem: bodyMassEstimation,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/bmi/add/:groupId',
    isExact: true,
    component: <CommonReportEditCreate type="add" />,
    accessRoles: [UserRoles.DOCTOR],
    chessTemplate: {
      active: 'body-mass-estimation',
    },
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/bmi/edit/:groupId',
    isExact: true,
    component: <CommonReportEditCreate type="edit" />,
    accessRoles: [UserRoles.DOCTOR],
    chessTemplate: {
      active: 'body-mass-estimation',
    },
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/materials/section/:type',
    accessRoles: 'all',
    component: <MaterialsSectionPage />,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/materials/:id',
    accessRoles: 'all',
    component: <MaterialPage />,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/materials',
    accessRoles: 'all',
    component: <MaterialsPage />,
    menuItem: materials,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/physical_testing_new/:type/',
    isExact: true,
    component: <PhysicalTestingAppNew />,
    chessTemplate: {
      active: 'physical_testing_new',
    },
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.TEACHER,
      UserRoles.SCOUT,
      UserRoles.FRANCHISEE,
      UserRoles.STUDENT,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
    menuItem: physicalTesting,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/functional_testing',
    isExact: true,
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {
        label: 'Функциональное тестирование',
        path: '/functional_testing',
      },
    ],
    component: <FunctionalTesting />,
    chessTemplate: {
      active: 'functional_testing',
    },
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.DOCTOR,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
    menuItem: functionalTesting,
  },
  {
    path: '/functional_testing/:teamId',
    isExact: true,
    component: <ReportsInformation />,
    chessTemplate: {
      active: 'functional_testing',
    },
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.DOCTOR,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
  },
  {
    path: '/functional_testing/:teamId/:reportDate',
    isExact: true,
    component: <Report />,
    chessTemplate: {active: 'functional_testing'},
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.DOCTOR,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
  },
  {
    path: '/functional_testing_create/:teamId/:typeAction?/:reportDate',
    isExact: true,
    component: <CreateEditReport />,
    chessTemplate: {
      active: 'functional_testing',
    },
    accessRoles: [UserRoles.ADMIN, UserRoles.DOCTOR],
  },
  {
    path: '/functional_testing_create/staged_testing/:teamId/:typeAction?/:reportDate',
    isExact: true,
    component: <CreateEditStagedTestingReport />,
    accessRoles: [UserRoles.ADMIN, UserRoles.DOCTOR],
  },
  {
    path: '/functional_testing_dynamic/:teamId',
    isExact: true,
    chessTemplate: {active: 'functional_testing'},
    component: <FunctionalStateDynamic />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.DOCTOR,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
  },
  {
    path: '/training_control_process/:tab',
    isExact: true,
    component: <TrainingControlProcess />,
    accessRoles: 'all',
    menuItem: trainingControlProcess,
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/training_control_process/training/:id',
    isExact: true,
    component: <TrainingPage />,
    chessTemplate: {active: 'training_control_process'},
    accessRoles: 'all',
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/statistics',
    isExact: true,
    component: <StatisticsWithMkcUral />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.VIDEO_ANALYST,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
      UserRoles.DOCTOR,
    ],
    menuItem: statistics,
  },
  {
    path: '/statistics',
    isExact: true,
    component: <StatisticsWithMkcUral />,
    accessRoles: [UserRoles.STUDENT],
    menuItem: statistics,
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
    isNewUI: true,
  },
  {
    path: '/statistics/:teamMKCId',
    isExact: true,
    component: <Statistics />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.VIDEO_ANALYST,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.STUDENT,
      UserRoles.METHODIST,
      UserRoles.DOCTOR,
    ],
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/statistics/:teamMKCId',
    isExact: true,
    component: <Statistics />,
    accessRoles: [UserRoles.STUDENT],
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
    isNewUI: true,
  },
  {
    path: '/development_journal',
    isExact: true,
    component: <DevelopmentJournal />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.METHODIST,
    ],
    chessTemplate: {
      active: 'development_journal',
    },
    pageTitle: {title: 'Журнал развития'},
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Журнал развития', path: null},
    ],
    menuItem: developmentJournal,
  },
  {
    path: '/development_journal/details',
    isExact: true,
    component: <DevelopmentJournalDetails />,
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.METHODIST,
    ],
    chessTemplate: {
      active: 'development_journal',
    },
  },
  {
    path: '/development_journal/player_skills/:id',
    isExact: true,
    component: <DevelopmentJournalPlayerSkills />,
    chessTemplate: {
      active: 'development_journal',
    },
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.METHODIST,
    ],
  },
  {
    path: '/development_journal/player/:id',
    isExact: true,
    component: <DevelopmentJournalPlayer />,
    chessTemplate: {
      active: 'development_journal',
    },
    accessRoles: [
      UserRoles.ADMIN,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.FRANCHISEE,
      UserRoles.TEACHER,
      UserRoles.PSYCHO,
      UserRoles.DOCTOR,
      UserRoles.METHODIST,
    ],
  },
  {
    path: '/tournaments',
    isExact: true,
    component: <Tournaments />,
    accessRoles: [UserRoles.ADMIN, UserRoles.VIDEO_ANALYST],
    chessTemplate: {
      active: 'tournaments',
    },
    pageTitle: {title: 'Турнир'},
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Турнир', path: null},
    ],
    menuItem: tournaments,
  },
  {
    path: '/tournaments/:id',
    isExact: true,
    component: <TournamentsView />,
    accessRoles: [UserRoles.ADMIN, UserRoles.VIDEO_ANALYST],
    breadCrumbs: [
      {label: 'Главная', path: '/'},
      {label: 'Турнир', path: '/tournaments'},
      {label: 'Команда', path: null, isDynamic: true},
    ],
  },
  {
    path: '/playoff/:id',
    isExact: true,
    component: <PlayOff />,
    accessRoles: [
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.PARENT,
      UserRoles.SCOUT,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
      UserRoles.STUDENT,
    ],
    subRoles: {[UserRoles.STUDENT]: []},
  },
  {
    path: '/playoff/:id',
    isExact: true,
    component: <PlayOff />,
    accessRoles: [
      UserRoles.FRANCHISEE,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.ADMIN,
      UserRoles.TEACHER,
      UserRoles.DOCTOR,
      UserRoles.PSYCHO,
      UserRoles.PARENT,
      UserRoles.SCOUT,
      UserRoles.VIDEO_ANALYST,
      UserRoles.METHODIST,
      UserRoles.STUDENT,
    ],
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
    isNewUI: true,
  },
  {
    path: '/playoff/:id',
    isExact: true,
    component: <PlayOff />,
    accessRoles: [UserRoles.STUDENT],
    isNewUI: true,
    subRoles: {[UserRoles.STUDENT]: [StudentSubroles.AMATEUR]},
  },
  {
    path: '/prospects',
    isExact: true,
    component: <ProspectPage />,
    accessRoles: (sections) => {
      console.log(sections);
      return isHaveAccessToSection(sections, 'prospects');
    },
    menuItem: prospects,
  },
  {
    path: '/prospects/:teamId',
    isExact: true,
    component: <ProspectTeamPage />,
    accessRoles: (sections) => isHaveAccessToSection(sections, 'prospects'),
  },
  {
    path: '/prospects/:teamId/:playerId',
    isExact: true,
    component: <ProspectPlayerPage />,
    accessRoles: (sections) => isHaveAccessToSection(sections, 'prospects'),
  },
  {
    path: '/a1tag/',
    isExact: true,
    isA1Tag: true,
    component: <A1TagMainWindow />,
    accessRoles: [UserRoles.VIDEO_ANALYST, UserRoles.ADMIN],
  },
  {
    path: '/a1tag/adding_rosters',
    isExact: true,
    isA1Tag: true,
    component: <A1TagAddingRosters />,
    accessRoles: [UserRoles.VIDEO_ANALYST, UserRoles.ADMIN],
    menuItem: a1tag,
  },
  {
    path: '/accesses',
    isExact: true,
    component: <AccessesMainPage />,
    accessRoles: [UserRoles.ADMIN],
    menuItem: accesses,
  },
];
/**
 * [userRole]: React.Node | {[teacherRole]: React.Node | others: React.Node}
 * ключ: userRole - роль пользователя, значение: компонент главной страницы роли
 * ключ: teacherRole - под роль пользователя, значение: компонент главной страницы под роли
 * others: если в обьекте не будет под роли то будет применятся это значение
 */

interface RoureValue {
  isNewUI?: boolean;
  component: JSX.Element;
}
interface KeyValueTypes {
  [UserRoles.TEACHER]: {[K in TeacherSubroles | 'others']: RoureValue};
  [UserRoles.STUDENT]: {[K in StudentSubroles | 'others']: RoureValue};
}
enum Guest {
  guest = 'guest',
}
type IndexRoutes = KeyValueTypes & {
  [K in UserRoles | Guest]: K extends keyof KeyValueTypes ? KeyValueTypes[K] : RoureValue;
};

export const indexRoutes: IndexRoutes = {
  [Guest.guest]: {component: <LoginPage />},
  [UserRoles.ADMIN]: {component: <AdminIndexApp />},
  [UserRoles.FRANCHISEE]: {component: <ManagerDashboard />},
  [UserRoles.SCHOOLS_ADMIN]: {component: <FranchiseeSchoolsApp />},
  [UserRoles.TEACHER]: {
    [TeacherSubroles.DEFAULT]: {component: <TrainersMainDashboardPanel />},
    [TeacherSubroles.MAIN]: {component: <TrainersMainDashboardPanel />},
    [TeacherSubroles.PHYSICAL]: {component: <TrainersPhysicalDashboardPanel />},
    [TeacherSubroles.WINGERS_TRAINER]: {component: <TrainersMainDashboardPanel />},
    [TeacherSubroles.DEFENSEMENS_TRAINER]: {component: <TrainersMainDashboardPanel />},
    [TeacherSubroles.GOALTENDER]: {component: <TrainersMainDashboardPanel />},
    others: {component: <TeacherPersonalPanel />},
  },
  [UserRoles.STUDENT]: {
    [StudentSubroles.AMATEUR]: {isNewUI: true, component: <AmateurPlayerDashboard />},
    others: {component: <StudentDashboard />},
  },
  [UserRoles.DOCTOR]: {component: <MedicalProfileApp />},
  [UserRoles.PARENT]: {component: <ParentProfileApp />},
  [UserRoles.PSYCHO]: {component: <PsychoProfileApp />}, //TODO поменять компонент на другой дашборд
  [UserRoles.SCOUT]: {component: <ScoutReport />},
  [UserRoles.VIDEO_ANALYST]: {component: <VideosGroupListApp />},
  [UserRoles.METHODIST]: {component: <TrainingControlProcess />},
};
