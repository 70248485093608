import ContentWrapper from '@common/ContentWrapper';
import GoaltendersStatistics from '@common/Statistics/PlayerStatistics/GoaltendersStatistics';
import Filters from '@common/Statistics/PlayerStatistics/GoaltendersStatistics/components/Filters';
import MatchesFilter from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import useStatsGoaltendersList from '@hooks/useStatsGoaltendersList';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectGoaltendersData, selectGoaltendersFilters} from '@selectors/gameStatisticsMkc';
import {
  clearGoaltendersFilters,
  clearGoaltendersStat,
  loadGoaltendersStat,
  setGoaltendersFilters,
} from '@slices/statisticsMKC/goaltenders';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React from 'react';

function GoaltendersStatisticsTab({hasOT}) {
  const {statisticIds, isMatchesLoading} = useMatches();
  const {playerIds, isplayerIdsLoading} = useStatsGoaltendersList(statisticIds);
  const dispatch = useStoreDispatch();

  return (
    <Stack direction="column" spacing={1}>
      <MatchesFilter
        wrapperProps={{mb: '0'}}
        onClear={() => {
          dispatch(setGoaltendersFilters({playerIds: []}));
          dispatch(clearGoaltendersStat());
        }}
      />
      <ContentWrapper>
        <Filters
          cleanup={clearGoaltendersFilters}
          getter={selectGoaltendersFilters}
          hasOT={hasOT}
          isPlayerIdsLoading={isplayerIdsLoading || isMatchesLoading}
          playerIds={playerIds}
          setter={setGoaltendersFilters}
        />
      </ContentWrapper>
      <GoaltendersStatistics
        cleaner={clearGoaltendersStat}
        loader={loadGoaltendersStat}
        loading={isplayerIdsLoading}
        selectData={selectGoaltendersData}
        selectFilters={selectGoaltendersFilters}
        statisticsIds={statisticIds}
      />
    </Stack>
  );
}

GoaltendersStatisticsTab.propTypes = {
  hasOT: PropTypes.bool,
};

export default GoaltendersStatisticsTab;
