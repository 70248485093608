import {signal} from '@preact/signals-react';

export const gamesReportModel = {
  filters: {
    _seasonId: signal<string | null>(null),

    get seasonId() {
      return this._seasonId.value;
    },

    updateSeasonId(id: string) {
      this._seasonId.value = id;
    },
  },
};
