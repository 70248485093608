import {mock} from '@components/modules/AmateurPlayerDashboard/components/PastGames/mock';
import NoData from '@components/modules/common/NoData';
import date from '@helpers/date';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import {Theme, useMediaQuery} from '@mui/material';
import {Breakpoint} from '@mui/system/createTheme/createBreakpoints';
import {longDash} from '@src/constants';
import {Stack, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsV2';
import LinkDefault from '@ui/MUI/Link';
import React from 'react';

const getQuery = (point: Breakpoint | number) => (theme: Theme) => theme.breakpoints.down(point);
function PastGames() {
  const isLg = useMediaQuery(getQuery('lg'));
  const isXs = useMediaQuery(getQuery('xs'));
  const {pastGames, isLoading} = useStore((state) => {
    return {
      isLoading: state?.dashboard?.loading,
      pastGames: state?.dashboard?.dashboard?.pastGames?.slice(0, 5),
    };
  }, shallowEqual);
  return (
    <>
      <Typography color={black['78']} mb={3} variant="h2">
        Прошедшие игры
      </Typography>
      {isLoading || pastGames?.length ? (
        <Stack direction="column" gap={1}>
          {(isLoading ? mock : pastGames)?.map((game, index) => (
            <Stack
              alignItems={{xxs: 'flex-start', xs: 'center'}}
              direction={{xxs: 'column', xs: 'row'}}
              flexWrap="wrap"
              gap={2}
              justifyContent="space-between"
              key={game?._id + index}
            >
              <Stack alignItems={{xxs: 'flex-start', xs: 'center'}} direction={{xxs: 'column', xs: 'row'}} gap={1}>
                {game?.startTimestamp && (
                  <Typography
                    color={black['66']}
                    isLoading={isLoading}
                    preloaderWidthBetween={[60, 90]}
                    variant="body2"
                  >
                    {date(game?.startTimestamp).format('DD.MM.YY')}
                  </Typography>
                )}
                <Typography
                  isLoading={isLoading}
                  preloaderWidthBetween={[90, 130]}
                  variant="body2"
                >{`${game?.group?.name} ${longDash} ${game?.opponent}`}</Typography>
              </Stack>
              <LinkDefault
                color="primary"
                disabled={!game.statisticsIsReady || isLoading}
                to={`/reports/stats_report/match_info/${game?.statisticId || ''}`}
              >
                {isLg && !isXs ? 'Статистика' : 'Посмотреть статистику'}
              </LinkDefault>
            </Stack>
          ))}
        </Stack>
      ) : (
        <NoData wrapperProps={{flex: 1}} />
      )}
    </>
  );
}

export default PastGames;
