import {getTournamentsStats} from '@api/TournamentsAPI';
import ChessTable from '@common/Statistics/PlayOff/components/ChessTable';
import Matches from '@common/Statistics/PlayOff/components/Matches';
import {Stages} from '@common/Statistics/PlayOff/types';
import {FormData} from '@components/apps/Tournaments/components/TournamentView/types';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectGames} from '@selectors/tournaments';
import {clearGames, loadGames} from '@slices/tournaments';
import {InitialStateIndicators, StudentSubroles} from '@src/types';
import {ConsolationStages, StagesEnum} from '@src/types/tournament';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

function getFractionBottom(str: string): number {
  return str.includes('/') ? Number(str.split('/')[1]) : 0;
}

type Games = {
  stages: {playOff: Stages; consolationMatches: Stages};
  groupStage: FormData['group'];
} & InitialStateIndicators<FormData>;

const {GROUP, FINAL, ...playOffStages} = StagesEnum;
const stagesArr = [
  ...Object.values(playOffStages)
    .sort((a, b) => Number(a.replace(/\\/g, '')) - Number(b.replace(/\\/g, '')))
    .reverse(),
  FINAL,
];

const consolationStages = [...Object.values(ConsolationStages)];

function PlayOff() {
  const dispatch = useStoreDispatch();
  const {stages, isLoading, groupStage} = useStore<Games>((state) => {
    const games = selectGames(state);
    const data = games.data;
    const {
      [StagesEnum.GROUP]: group,
      [ConsolationStages.fiveEight]: fiveEight,
      [ConsolationStages.seventh]: seventh,
      [ConsolationStages.fifth]: fifth,
      ...restStages
    } = data;

    const groupedStages: Games['stages'] = {
      playOff: [],
      consolationMatches: [],
    };

    const playOffEntries = Object.entries(restStages).sort((a, b) => getFractionBottom(b[0]) - getFractionBottom(a[0]));
    const maxStageIndex = stagesArr.findIndex((stage) => stage === playOffEntries?.[0]?.[0]);

    groupedStages.consolationMatches = consolationStages.map((stage) => {
      const games = data[stage] || [];

      return {
        stage,
        cellsCount: ConsolationStages.fiveEight === stage ? 2 : 1,
        games,
      };
    }) as Stages;

    groupedStages.playOff = stagesArr.slice(maxStageIndex >= 0 ? maxStageIndex : 0, stagesArr?.length).map((stage) => ({
      stage,
      cellsCount: stage?.includes('/') ? Number(stage.split('/')[1]) : 1,
      games: data[stage] || [],
    })) as Stages;

    return {...games, stages: groupedStages, groupStage: group};
  }, shallowEqual);

  const {id} = useParams<{id: string}>();

  useEffect(() => {
    dispatch(loadGames({tournament: id}));
    return () => {
      dispatch(clearGames());
    };
  }, [id]);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    if (id) {
      getTournamentsStats({tournament: id, sort: '-date'}).then((res) => {
        setTournaments(res?.data);
      });
    }
  }, [id]);

  return (
    <ThemeLayout active="statistics" isNew={isAmateur}>
      <TabsNew
        withoutWrapper
        tabs={[
          {
            label: 'шахматная',
            value: <ChessTable data={groupStage} isLoading={isLoading} tournaments={tournaments} />,
          },
          {label: 'Плей-офф', value: <Matches isLoading={isLoading} stages={stages?.playOff} />},
          {
            label: 'Утешительные матчи ',
            value: <Matches isConsolationMatches isLoading={isLoading} stages={stages?.consolationMatches} />,
          },
        ]}
      />
    </ThemeLayout>
  );
}

export default PlayOff;
