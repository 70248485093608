import {getProspectGroup} from '@api/prospect.api';
import {ProspectTeamDto} from '@src/types/generated';
import {useQuery, UseQueryResult} from '@tanstack/react-query';

export const useProspectGroup = (): UseQueryResult<ProspectTeamDto[]> => {
  return useQuery<ProspectTeamDto[]>({
    staleTime: 5 * 60 * 1000, // 5 min
    queryKey: ['prospect', 'group'],
    queryFn: async () => {
      return getProspectGroup();
    },
  });
};
