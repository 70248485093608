import {useSaveProspectGoals} from '@components/modules/prospects/queries/prospect-goals.query';
import {Add} from '@mui/icons-material';
import {Stack, TextField, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, Button, DatePicker} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import LoadingButton from '@ui/MUI/LoadingButton';
import moment from 'moment';
import React from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {Goal} from '../mock/goals.mock';
import {newGoal} from '../models/drawer.models';

export interface NewGoalForm {
  goals: Omit<Goal, 'id' | 'finalResult'>[];
}

export const NewGoalDrawer = () => {
  const {playerId} = useParams<{playerId: string}>();

  const form = useForm<NewGoalForm>({
    defaultValues: {
      goals: [{goal: null, expectedResult: null, startDate: null, endDate: null}],
    },
  });
  const {fields, append} = useFieldArray({control: form.control, name: 'goals'});
  const saveMutation = useSaveProspectGoals(playerId);

  useSignals();
  const handleClose = () => {
    newGoal.close();
    form.reset();
  };

  const handleSubmit = async (data: NewGoalForm) => {
    const fomatted = data.goals.map((goal) => ({
      ...goal,
      startDate: moment(goal.startDate).format('YYYY-MM-DD'),
      endDate: moment(goal.endDate).format('YYYY-MM-DD'),
      userId: playerId,
    }));

    await saveMutation.mutateAsync({goals: fomatted});
    handleClose();
  };

  return (
    <Drawer
      buttons={
        <Stack direction="row" justifyContent="space-between">
          <LoadingButton
            color="primary"
            disabled={!form.formState.isValid || saveMutation.isLoading}
            loading={saveMutation.isLoading}
            variant="contained"
            onClick={form.handleSubmit(handleSubmit)}
          >
            Сохранить
          </LoadingButton>
          <Button variant="contained" onClick={handleClose}>
            Отменить
          </Button>
        </Stack>
      }
      open={newGoal.isOpen}
      title="Новая цель"
      onClose={handleClose}
    >
      <Stack spacing={6}>
        {fields.map((field, index) => (
          <Stack key={field.id} spacing={4}>
            <Controller
              control={form.control}
              name={`goals.${index}.goal`}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Цель"
                  variant="standard"
                />
              )}
              rules={{
                required: 'Цель обязательна',
              }}
            />
            <Controller
              control={form.control}
              name={`goals.${index}.expectedResult`}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Ожидаемый результат"
                  variant="standard"
                />
              )}
              rules={{
                required: 'Ожидаемый результат обязателен',
              }}
            />
            <Box>
              <Typography mb={1}>Период вылолнения</Typography>
              <Stack direction="row" spacing={4}>
                <Box maxWidth={140}>
                  <Controller
                    control={form.control}
                    name={`goals.${index}.startDate`}
                    render={({field, fieldState}) => (
                      <DatePicker
                        {...field}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label="С"
                      />
                    )}
                    rules={{
                      required: 'Дата начала обязательна',
                    }}
                  />
                </Box>
                <Box maxWidth={140}>
                  <Controller
                    control={form.control}
                    name={`goals.${index}.endDate`}
                    render={({field, fieldState}) => (
                      <DatePicker
                        {...field}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label="По"
                      />
                    )}
                    rules={{
                      required: 'Дата окончания обязательна',
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Stack>
        ))}
        <Button
          color="primary"
          disabled={!form.formState.isValid}
          startIcon={<Add />}
          sx={{alignSelf: 'flex-start'}}
          variant="text"
          onClick={() => append({goal: '', expectedResult: null, startDate: null, endDate: null})}
        >
          Добавить еще цель
        </Button>
      </Stack>
    </Drawer>
  );
};
