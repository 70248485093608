import ReportsInformationTable from '@components/apps/FunctionalTesting/components/ReportsInformation/ReportsInformationTable';
import Table from '@components/apps/FunctionalTesting/components/ReportsInformation/ReportsInformationTable/ReportsInformation';
import {actions} from '@components/apps/FunctionalTesting/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {getTeamName} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Add} from '@mui/icons-material';
import {selectReportsInformation} from '@selectors/functionalTesting';
import {getReportsInformation} from '@slices/functionalTesting';
import {UserRoles} from '@src/types';
import {Typography} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import StagedTestingReport from '../StagedTestingReport';
import {ButtonsContainer, LocalPreloaderWrapper, TableWrapper, TabsWrapper} from './ReportsInformation';

const ReportsInformation = () => {
  const {items, isLoading} = useStore(selectReportsInformation);
  const tableData = items;
  const isDoctor = useStore((state) => state.users.currentUser.userRole === UserRoles.DOCTOR);
  const isAdmin = useStore((state) => state.users.currentUser.userRole === UserRoles.ADMIN);
  const canEdit = isDoctor || isAdmin;
  const dispatch = useStoreDispatch();

  const history = useHistory();
  const {teamId} = useParams();

  const [teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamName(teamId).then((res) => {
      setTeamName(res);
    });
  }, [teamId]);

  const currentDate = date().format('YYYY-MM-DD');

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    dispatch(getReportsInformation(teamId));
  }, []);

  const seasons = Object.keys(tableData);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
          {
            path: `/functional_testing/${teamId}`,
            label: teamName,
          },
        ]}
        className="PageTitleDefault"
      >
        Функциональное тестирование
      </PageTitleDefault>
      <TabsWrapper>
        <TabsNew
          withoutWrapper
          tabs={[
            {
              label: 'Текущее тестирование',
              value: (
                <>
                  {seasons?.length ? (
                    <>
                      <ButtonsContainer>
                        {canEdit && (
                          <ButtonDefault
                            color="primary"
                            size="medium"
                            startIcon={<Add />}
                            variant="contained"
                            onClick={() =>
                              history.push(`/functional_testing_create/${teamId}/${actions.actionAdd}/${currentDate}`)
                            }
                          >
                            Добавить отчет
                          </ButtonDefault>
                        )}
                        <ButtonDefault
                          color="secondary"
                          size="medium"
                          variant="contained"
                          onClick={() => {
                            history.push(`/functional_testing_dynamic/${teamId}`);
                          }}
                        >
                          Смотреть динамику
                        </ButtonDefault>
                      </ButtonsContainer>

                      {seasons.map((season) => {
                        return (
                          <React.Fragment key={`season ${season}`}>
                            <Typography color="#fff" variant="h4" weight={600}>
                              Сезон 20{season.split('-')[0]}-20{season.split('-')[1]}
                            </Typography>
                            <TableWrapper key={season}>
                              {isLoading ? (
                                <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
                                  <LocalPreloader visible={true} />
                                </LocalPreloaderWrapper>
                              ) : (
                                <Table>
                                  <ReportsInformationTable tableData={tableData[season]} teamId={teamId} />
                                </Table>
                              )}
                            </TableWrapper>
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <TableEmptyBlock
                      content={
                        <>
                          {canEdit && (
                            <ButtonDefault
                              color="primary"
                              size="medium"
                              startIcon={<Add />}
                              variant="contained"
                              onClick={() =>
                                history.push(`/functional_testing_create/${teamId}/${actions.actionAdd}/${currentDate}`)
                              }
                            >
                              Добавить отчет
                            </ButtonDefault>
                          )}
                        </>
                      }
                      text="Отчетов пока нет."
                    />
                  )}
                </>
              ),
            },
            {label: 'Этапное тестирование', value: <StagedTestingReport canEdit={canEdit} key="1" />},
          ]}
          value={tabIndex}
          onChange={(e) => {
            setTabIndex(e);
          }}
        />
      </TabsWrapper>
    </>
  );
};

export default ReportsInformation;
