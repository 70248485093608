export const mock = {
  weAtHome: true,
  winByOT: false,
  halfsScores: ['3:0', '1:2', '4:4'],
  score: {
    data: {
      home: 8,
      away: 6,
    },
    name: 'Счёт',
  },
  teamNames: ['Авангард-ЮХЛ', 'Соперники'],
  metrics: [
    {
      data: {
        home: '150',
        away: '83',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Броски',
    },
    {
      data: {
        home: '83',
        away: '54',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Броски в створ',
    },
    {
      data: {
        home: '19',
        away: '39',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Заблокированные броски',
    },
    {
      data: {
        home: '82',
        away: '41',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Вбрасывания',
    },
    {
      data: {
        home: '25',
        away: '35',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Хиты',
    },
    {
      data: {
        home: '51',
        away: '42',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Отборы',
    },
    {
      data: {
        home: '16',
        away: '5',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Обыгрыши',
    },
    {
      data: {
        home: '18:07',
        away: '11:18',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Время в большинстве',
    },
    {
      data: {
        home: '3/11',
        away: '3/8',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Реализация',
    },
    {
      data: {
        home: '3/11',
        away: '3/8',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Реализация',
    },
  ],
  numberOfMatches: 2,
  protocol: {
    home: [
      {
        playerName: 'Иван Карачев',
        time: '21',
        majority: false,
        minority: false,
        isFinalGoal: false,
        assists: [
          {
            playerName: 'Андрей Борисов',
          },
          {
            playerName: 'Максим Лучкин',
          },
        ],
        videoTime: '0:36:39',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: '7kEj0Fwh7Pt-',
      },
      {
        playerName: 'Алексей Холодков',
        time: '33',
        majority: true,
        minority: false,
        isFinalGoal: false,
        assists: [
          {
            playerName: 'Александр Букатин',
          },
          {
            playerName: 'Андрей Борисов',
          },
        ],
        videoTime: '0:53:55',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'n0LqLCMaPFnU',
      },
      {
        playerName: 'Иван Карачев',
        time: '43',
        majority: false,
        minority: true,
        isFinalGoal: false,
        assists: [
          {
            playerName: 'Андрей Борисов',
          },
        ],
        videoTime: '1:01:58',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'zj7ENUPYSIM3',
      },
      {
        playerName: 'Михаил Аверин',
        time: '52',
        majority: true,
        minority: false,
        isFinalGoal: false,
        assists: [
          {
            playerName: 'Андрей Борисов',
          },
          {
            playerName: 'Иван Карачев',
          },
        ],
        videoTime: '1:17:07',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'aj72FBNlV1fW',
      },
    ],
    away: [
      {
        playerName: '',
        time: '2',
        majority: false,
        minority: false,
        isFinalGoal: false,
        videoTime: '0:15:00',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'PXV2Gsb7ZXMO',
      },
      {
        playerName: '',
        time: '5',
        majority: false,
        minority: false,
        isFinalGoal: false,
        videoTime: '0:20:32',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'jEf9FG5t5-_B',
      },
      {
        playerName: '',
        time: '13',
        majority: true,
        minority: false,
        isFinalGoal: false,
        videoTime: '0:31:37',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'QzHaeMDw5o8S',
      },
      {
        playerName: '',
        time: '25',
        majority: false,
        minority: false,
        isFinalGoal: false,
        videoTime: '0:42:29',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'uH-3eWQv2c_3',
      },
      {
        playerName: '',
        time: '31',
        majority: false,
        minority: false,
        isFinalGoal: true,
        videoTime: '0:50:57',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'wTYRgqHn9Jwb',
      },
      {
        playerName: '',
        time: '44',
        majority: false,
        minority: false,
        isFinalGoal: false,
        videoTime: '1:04:38',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'zkXcH6aRxCMV',
      },
      {
        playerName: '',
        time: '49',
        majority: false,
        minority: false,
        isFinalGoal: false,
        videoTime: '1:10:57',
        linkToVideo: 'https://www.youtube.com/live/zk0JymUtX20?si=ykjtVK1KmVmBXK6c',
        videoOffsets: {
          start: 20,
          end: 3,
        },
        id: 'WIs0Ynd1vIsa',
      },
    ],
  },
};
