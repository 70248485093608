import {useSections} from '@components/modules/accesses/queries/section.query';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {useSignals} from '@preact/signals-react/runtime';
import {Stack, Typography} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import React, {memo, useEffect} from 'react';

import {tab} from '../models/main.view-model';
import {Filters} from './filters.component';
import {Table} from './table.component';

const MainContent = memo(() => {
  return (
    <Stack spacing={4}>
      <Filters />
      <Table />
    </Stack>
  );
});

MainContent.displayName = 'MainContent';

export const SectionsTabs = memo(() => {
  useSignals();
  const sectionsQuery = useSections();

  const tabsWithContent =
    sectionsQuery.data?.map((section) => ({
      label: section.name,
      value: <MainContent />,
      id: section.id,
    })) ?? [];

  const tabIndex = tabsWithContent.findIndex((currentTab) => currentTab.id === tab.value);

  useEffect(() => {
    if (!tab.value && sectionsQuery.data?.length) {
      tab.value = tabsWithContent[0].id;
    }
  }, [sectionsQuery.data, tab.value]);

  if (sectionsQuery.isLoading) {
    return (
      <Stack alignItems="center" height="300px" justifyContent="center" position="relative">
        <LocalPreloader visible={true} />;
      </Stack>
    );
  }

  if (!sectionsQuery.data?.length) {
    return (
      <Stack alignItems="center" borderRadius={2} height="300px" justifyContent="center" sx={{background: 'white'}}>
        <Typography>Разделы пока не добавлены, свяжитесь с командой разработки</Typography>
      </Stack>
    );
  }

  return (
    <TabsNew
      withoutWrapper
      tabs={tabsWithContent ?? []}
      value={tabIndex}
      onChange={(v) => {
        if (typeof v === 'number') {
          tab.value = tabsWithContent[v].id;
        }
      }}
    />
  );
});

SectionsTabs.displayName = 'SectionsTabs';
