import ActionsMap from '@components/apps/StatisticsWithMkcUral/components/ActionsMap';
import CommonTab from '@components/apps/StatisticsWithMkcUral/components/CommonTab';
import GoaltendersStatisticsTab from '@components/apps/StatisticsWithMkcUral/components/GoaltendersStatisticsTab';
import PlayersStatistics from '@components/apps/StatisticsWithMkcUral/components/PlayersStatistics';
import ShotsMap from '@components/apps/StatisticsWithMkcUral/components/ShotsMap';
import Throwins from '@components/apps/StatisticsWithMkcUral/components/Throwins';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import ThemeTitle from '@components/modules/common/ThemeTitle';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {clearMatches, clearTournaments, setMatchesFilterData} from '@slices/statisticsMKC';
import {StudentSubroles} from '@src/types';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useEffect} from 'react';

import PreparingForOpponent from '../PreparingForOpponent';
import TableTab from '../TournamentTab';

const TABS = [
  {label: 'Турнир', value: <TableTab />, id: 'tournament'},
  {label: 'Соперник', value: <PreparingForOpponent />, id: 'opponent'},
  {label: 'Командная', value: <CommonTab />, id: 'team'},
  {label: 'Действия', value: <ActionsMap weAtHome />, id: 'actions'},
  {label: 'Игроки', value: <PlayersStatistics />, id: 'players'},
  {label: 'Броски', value: <ShotsMap weAtHome />, id: 'shots'},
  {label: 'Вбрасывания', value: <Throwins weAtHome />, id: 'throwins'},
  {label: 'Вратари', value: <GoaltendersStatisticsTab weAtHome />, id: 'goaltenders'},
];
const TABS_V2 = [
  {label: 'Турнир', value: <TableTab />, id: 'tournament'},
  {label: 'Командная', value: <CommonTab />, id: 'team'},
  {label: 'Действия', value: <ActionsMap weAtHome />, id: 'actions'},
  {label: 'Игроки', value: <PlayersStatistics />, id: 'players'},
  {label: 'Броски', value: <ShotsMap weAtHome />, id: 'shots'},
  {label: 'Вбрасывания', value: <Throwins weAtHome />, id: 'throwins'},
  {label: 'Вратари', value: <GoaltendersStatisticsTab weAtHome />, id: 'goaltenders'},
];
function Statistics() {
  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  const {value, onChange} = useQueryStringTabs({tabs: isAmateur ? TABS_V2 : TABS});
  const dispatch = useStoreDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMatchesFilterData({}));
      dispatch(clearTournaments());
      dispatch(clearMatches());
    };
  }, []);

  return (
    <ThemeLayout active="statistics" isNew={isAmateur}>
      <ThemeTitle
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Статистика', path: '/statistics'},
          {label: 'Статистика команды'},
        ]}
        isNew={isAmateur}
        title="Статистика"
      />
      <TabsNew
        hasMobileView
        withoutWrapper
        sx={{'&.MuiTabs-root': {mb: 3}}}
        tabs={isAmateur ? TABS_V2 : TABS}
        value={value}
        onChange={onChange}
      />
    </ThemeLayout>
  );
}

export default React.memo(Statistics);
