import {ColumnDef} from '@tanstack/react-table';
import moment from 'moment';

import {Goal} from '../mock/goals.mock';

export const goalsTableColumns: ColumnDef<Goal>[] = [
  {
    accessorKey: 'startDate',
    header: 'Постановка',
    cell: ({row}) => {
      const {startDate} = row.original;
      return moment(startDate).format('DD.MM.YYYY');
    },
  },
  {
    accessorKey: 'finalDate',
    header: 'Завершение',
    cell: ({row}) => {
      const {finalDate} = row.original;
      return moment(finalDate).format('DD.MM.YYYY');
    },
  },
  {
    accessorKey: 'goal',
    header: 'Цель',
  },
  {
    accessorKey: 'expectedResult',
    header: 'Ожидаемый результат',
  },
  {
    accessorKey: 'finalResult',
    header: 'Результат',
  },
];
