import GroupsAPI from '@api/GroupsAPI';
import {
  chooseTeamModalViewModel,
  updateChooseTeamPayload,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {MODAL_BODY_STYLE, MODAL_HEAD_STYLE, MODAL_STYLE} from '@components/modules/A1TagLayout/constants';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import {CloseRounded} from '@mui/icons-material';
import {Modal} from '@mui/material';
import {UserRoles} from '@src/types';
import {Autocomplete, Box, Button, IconButton, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React from 'react';
import {Controller, FieldValues, FormProvider, useForm, UseFormReturn} from 'react-hook-form';

type A1TagChooseTeamModalProps = {
  isChoosingTeamShowed: {
    isHomeTeam: boolean;
    isShowed: boolean;
  };
  setIsChoosingTeamShowed: React.Dispatch<
    React.SetStateAction<{
      isHomeTeam: boolean;
      isShowed: boolean;
    }>
  >;
  isSelectedTeamHome: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHomeAuto: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAwayAuto: React.Dispatch<React.SetStateAction<boolean>>;
  editTeamRoasterForm: UseFormReturn<FieldValues, unknown, undefined>;
};

type ChooseTeamFormType = {
  school: {value: string; label: string};
  team: {value: string; label: string};
};

export function A1TagChooseTeamModal({
  isChoosingTeamShowed,
  setIsChoosingTeamShowed,
  isSelectedTeamHome,
  setIsLoading,
  setIsHomeAuto,
  setIsAwayAuto,
  editTeamRoasterForm,
}: A1TagChooseTeamModalProps) {
  const chooseTeamForm = useForm();

  const {watch, reset} = chooseTeamForm;

  const selectedSchool = watch('school')?.value;

  const payload = chooseTeamModalViewModel.payload?.value ?? {};

  const getTeamPlayers = async ({teamId, isHomeTeam}: {teamId: string; isHomeTeam: boolean}) => {
    setIsLoading(true);
    const result = await GroupsAPI.getGroupsStudents([teamId]);
    if (isHomeTeam) {
      updateChooseTeamPayload({
        ...payload,
        homeTeam: {...payload?.homeTeam, name: result?.[0]?.group?.name, players: result},
      });
      editTeamRoasterForm.setValue('homeTeamPlayers', result);
    } else {
      updateChooseTeamPayload({
        ...payload,
        awayTeam: {...payload?.awayTeam, name: result?.[0]?.group?.name, players: result},
      });
      editTeamRoasterForm.setValue('awayTeamPlayers', result);
    }
    setIsLoading(false);
  };

  const handleChooseTeamFormSubmit = (isHomeTeam: boolean) => (form: ChooseTeamFormType) => {
    if (isHomeTeam) {
      setIsHomeAuto(false);
      updateChooseTeamPayload({...payload, homeTeam: {...payload?.homeTeam, name: form?.team?.label}});
      editTeamRoasterForm.setValue('homeTeamName', form?.team?.label);
    } else {
      setIsAwayAuto(false);
      updateChooseTeamPayload({...payload, awayTeam: {...payload?.awayTeam, name: form?.team?.label}});
      editTeamRoasterForm.setValue('awayTeamName', form?.team?.label);
    }
    if (form?.team?.value) {
      getTeamPlayers({teamId: form?.team?.value, isHomeTeam});
      setIsChoosingTeamShowed({isHomeTeam, isShowed: false});
      reset({});
    }
  };

  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [UserRoles.VIDEO_ANALYST],
  });
  const groups = useGroupsV2({schoolIds: selectedSchool ? [selectedSchool] : []});

  const handleChoosingTeamClose = ({isHomeTeam}: {isHomeTeam: boolean}) =>
    setIsChoosingTeamShowed({isHomeTeam, isShowed: false});

  return (
    <Modal
      open={isChoosingTeamShowed?.isShowed}
      onClose={() => handleChoosingTeamClose({isHomeTeam: isSelectedTeamHome})}
    >
      <Box sx={{...MODAL_STYLE, maxWidth: 500}}>
        <Box sx={MODAL_HEAD_STYLE}>
          <Typography color={black[75]} textTransform="uppercase" variant="overline2">
            Choose a team №{isSelectedTeamHome ? 1 : 2}
          </Typography>
          <IconButton
            sx={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}}
            onClick={() => handleChoosingTeamClose({isHomeTeam: isSelectedTeamHome})}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={MODAL_BODY_STYLE}>
          <FormProvider {...chooseTeamForm}>
            <Controller
              name="school"
              render={({field: {onChange, value, ref, ...field}, fieldState: {error, ...fieldState}}) => {
                return (
                  <Box alignItems="center" display="flex" mb={2}>
                    <Typography color={black[60]} minWidth="54px" mr={2} variant="subtitle1">
                      School
                    </Typography>
                    <Autocomplete
                      multiple={false}
                      options={schools?.options || []}
                      value={value}
                      {...field}
                      {...fieldState}
                      sx={{width: '100%'}}
                      textFieldVariant="outlined"
                      width="100%"
                      onChange={(_, v) => {
                        onChange(v);
                      }}
                    />
                  </Box>
                );
              }}
            />
            <Controller
              name="team"
              render={({field: {onChange, value, ref, ...field}, fieldState: {error, ...fieldState}}) => {
                return (
                  <Box alignItems="center" display="flex">
                    <Typography color={black[60]} minWidth="54px" mr={2} variant="subtitle1">
                      Team
                    </Typography>
                    <Autocomplete
                      multiple={false}
                      options={groups?.options || []}
                      value={value}
                      {...field}
                      {...fieldState}
                      textFieldVariant="outlined"
                      width="100%"
                      onChange={(_, v) => {
                        onChange(v);
                      }}
                    />
                  </Box>
                );
              }}
            />
            <Button
              color="primary"
              size="small"
              sx={{mt: 2, width: '100%'}}
              type="submit"
              variant="contained"
              onClick={chooseTeamForm.handleSubmit(handleChooseTeamFormSubmit(isSelectedTeamHome))}
            >
              Save
            </Button>
          </FormProvider>
        </Box>
      </Box>
    </Modal>
  );
}
