import {ComponentType} from 'react';

export enum MENU_CATEGORIES_ENUM {
  default = 'default',
  sport = 'sport',
  health = 'health',
  control = 'control',
  others = 'others',
}

export type MenuItem = {
  name: string;
  label: string;
  Icon?: ComponentType;
  category: MENU_CATEGORIES_ENUM;
  url?: string;
};
