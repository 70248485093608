import {getStatisticsTeamNames} from '@api/StatsAPI';
import ActionsMap from '@components/apps/StatsReportForOneGame/components/ActionsMap';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import ThemeTitle from '@components/modules/common/ThemeTitle';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import useStore from '@hooks/useStore';
import {longDash} from '@src/constants';
import {StudentSubroles} from '@src/types';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import GoaltendersStatisticsTab from './components/GoaltendersStatisticsTab';
import Main from './components/Main';
import PlayersStatistics from './components/PlayersStatistics';
import ShiftsTimeline from './components/ShiftsTimeline';
import Throwins from './components/ThrowinsTab';
import ThrowsStatistics from './components/ThrowsStatistics';
import {Wrapper} from './StatsReportForOneGame';

const StatsReportForOneGameApp = () => {
  const {statisticId} = useParams();
  const [teams, setTeams] = useState({
    home: '',
    away: '',
    weAtHome: false,
    winByBuls: false,
    winByOT: false,
  });
  const hasOT = useMemo(() => teams?.winByOT || teams?.winByBuls, [teams]);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;
  const isGuest = !currentUser?.userRole;

  const tabs = useDeepMemo(
    () => [
      {
        label: 'Обзор',
        value: <Main hasOT={hasOT} isV2={isAmateur || isGuest} winByBuls={teams?.winByBuls} winByOT={teams?.winByOT} />,
        id: 'view',
      },
      {label: 'Смены', value: <ShiftsTimeline hasOT={hasOT} teams={teams} />, id: 'shifts'},
      {label: 'Броски', value: <ThrowsStatistics hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'shots'},
      {label: 'Действия', value: <ActionsMap hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'actions'},
      {label: 'Игроки', value: <PlayersStatistics hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'players'},
      {label: 'Вбрасывания', value: <Throwins hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'throwIns'},
      {
        label: 'Вратари',
        value: <GoaltendersStatisticsTab hasOT={hasOT} isV2={isAmateur || isGuest} weAtHome={teams.weAtHome} />,
        id: 'goaltenders',
      },
    ],
    [hasOT, teams],
  );

  const {value, onChange} = useQueryStringTabs({tabs});

  useDeepEffect(() => {
    getStatisticsTeamNames({statisticId}).then((data) => {
      setTeams(data.data);
    });
  }, [statisticId]);

  return (
    <ThemeLayout active="statistics" isNew={isAmateur || isGuest}>
      <Wrapper>
        <ThemeTitle
          breadCrumbs={
            !isGuest
              ? [
                  {label: 'Главная', path: '/'},
                  {label: 'Игровая статистика', path: '/statistics'},
                  {
                    label: teams?.home && teams?.away ? `${teams?.home} ${longDash} ${teams?.away}` : 'Игра',
                    path: null,
                  },
                ]
              : []
          }
          isNew={isAmateur || isGuest}
          title="Игровая статистика"
        />
        <TabsNew
          hasMobileView
          withoutWrapper
          tabs={tabs}
          tabsWrapperProps={{px: '0', pt: '0px'}}
          value={value}
          onChange={onChange}
        />
      </Wrapper>
    </ThemeLayout>
  );
};

export default StatsReportForOneGameApp;
