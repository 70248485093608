import {
  asingOrDeletUserFromSubSections,
  getSection,
  getSections,
  getUserSections,
  getUserSubSections,
} from '@api/sectionsApi';
import {useMutation, UseMutationOptions, useQuery, useQueryClient} from '@tanstack/react-query';

import {SectionFilters} from '../accesses.type';

const staleTime = 5 * 60 * 1000; // 5 minutes
export const useSections = () => {
  return useQuery({
    staleTime,
    queryKey: ['section'],
    queryFn: () => {
      return getSections();
    },
  });
};

export const useSection = (id: string, query?: SectionFilters) => {
  const formattedQuery = Object.keys(query ?? {}).length ? query : undefined;

  return useQuery({
    staleTime,
    queryKey: ['section', id, formattedQuery],
    queryFn: () => getSection(id, formattedQuery),
    enabled: !!id,
  });
};

export const useAsignOrDeletUserFromSubSections = (
  sectionId: string,
  options?: UseMutationOptions<void, unknown, {userId: string; newIds: string[]; oldIds?: string[]}>,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, {userId: string; newIds: string[]; oldIds?: string[]}>({
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({queryKey: ['section', sectionId]});
      options?.onSuccess?.(...args);
    },
    mutationFn: async ({userId, newIds, oldIds}) => {
      await asingOrDeletUserFromSubSections(userId, newIds, oldIds);
    },
  });
};

export const useUserSections = (userId?: string) => {
  return useQuery({
    staleTime,
    queryKey: ['section', 'user', userId],
    queryFn: () => getUserSections(userId),
    enabled: !!userId,
  });
};

export const useUserSubSections = (userId?: string) => {
  return useQuery({
    staleTime,
    queryKey: ['section', 'subsection', 'user', userId],
    queryFn: () => getUserSubSections(userId),
    enabled: !!userId,
  });
};
