import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

function WingerIcon({...props}) {
  return (
    <SvgIcon {...props}>
      <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32 1.55874L31.3913 2.75071L30.7826 3.94269L30.1739 5.13466L26.9565 11.553L23.8261 17.7879L18.5217 26.5902C18.5217 26.5902 16.7826 28.7908 16.5218 28.6991C16.4348 28.6074 16.2609 28.5157 16.1739 28.424C15.6522 27.9656 15.4783 27.0487 15.8261 26.4068L16.4348 25.1232L23.7391 10.7278L26.9565 4.49283L27.3913 3.57593L28 2.38395L28.6087 1.19198L29.2174 0"
          fill="#A5100E"
        />
        <path
          d="M31.3913 1.55874L30.7826 2.75071L30.1739 3.94269L29.5652 5.22635L26.3478 11.6447L23.2174 17.8796L17.8261 26.5902C17.8261 26.5902 16.7826 27.8739 16.1739 28.424C15.6522 27.9656 15.4783 27.0487 15.8261 26.4068L16.4348 25.1232L23.7391 10.7278L26.9565 4.49283L27.3913 3.57593L28 2.38395L28.6087 1.19198L29.2174 0"
          fill="#C52E25"
        />
        <path
          d="M26.4347 12.5616L18.9565 27.2321C17.8261 29.341 17.3043 29.9828 15.3913 30.533C15.0435 30.6247 13.4782 30.8997 13.1304 30.8997L3.3913 32H3.13043C1.3913 32 0 30.9914 0 29.1576C0 28.9742 0 28.7908 0.0869564 28.6075C0.347825 27.0487 1.65217 25.8568 3.21739 25.8568H14.0869C14.8695 25.8568 15.913 26.0401 16.5217 25.2149L23.826 10.8195L26.0869 12.3783L26.4347 12.5616Z"
          fill="#AD7C5E"
        />
        <path
          d="M26.0869 12.2865L19.0434 26.1318C17.913 28.2407 17.3913 28.8825 15.4782 29.4326C15.1304 29.5243 13.5652 29.7994 13.2174 29.7994L3.3913 30.8997H3.13043C1.56521 30.8997 0.260869 30.0745 0 28.5157C0.260869 26.957 1.56521 25.765 3.13043 25.765H14C14.7826 25.765 15.8261 25.9484 16.4347 25.1232L23.7391 10.7278L26.0869 12.2865Z"
          fill="#C2AA88"
        />
        <path d="M30.8694 3.94274L30.1738 5.22641L27.4781 3.57598L28.0868 2.384L30.8694 3.94274Z" fill="#FECCBD" />
        <path d="M32 1.55874L31.3913 2.75071L28.6087 1.19198L29.2174 0L32 1.55874Z" fill="#FECCBD" />
        <path
          d="M12.6961 18.7966C12.6961 19.8968 9.91346 20.9054 6.43521 20.9054C2.95695 20.9054 0.174347 19.9885 0.174347 18.7966C0.174347 18.1547 0.174347 15.1289 0.174347 15.1289C1.30478 14.8539 4.86999 16.7794 6.43521 16.7794C8.00042 16.7794 11.6526 14.8539 12.6961 15.1289C12.6961 15.0373 12.6961 18.1547 12.6961 18.7966Z"
          fill="#263536"
        />
        <path
          d="M12.6961 15.0373C12.6961 16.1375 9.91346 17.1461 6.43521 17.1461C2.95695 17.1461 0.174347 16.2292 0.174347 15.0373C0.174347 13.937 2.95695 12.9284 6.43521 12.9284C9.8265 13.0201 12.6961 13.937 12.6961 15.0373Z"
          fill="#354546"
        />
        <path
          d="M9.33332 14.7622C9.33332 15.1289 8.14814 15.4651 6.66666 15.4651C5.18518 15.4651 4 15.1595 4 14.7622C4 14.3954 5.18518 14.0592 6.66666 14.0592C8.1111 14.0898 9.33332 14.3954 9.33332 14.7622Z"
          fill="#EE1122"
        />
      </svg>
    </SvgIcon>
  );
}

export default WingerIcon;
