import SchoolsAPI from '@api/SchoolsAPI';
import {useQuery} from '@tanstack/react-query';

import {School} from '../types/school.types';

export const useSchools = () => {
  return useQuery({
    queryKey: ['schools'],
    queryFn: async () => {
      return SchoolsAPI.getAllSchools() as Promise<School[]>;
    },
  });
};
