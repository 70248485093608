import SiriusCompareTable from '@components/apps/PhysicalTesting/components/SiriusCompare/components/SiriusCompareTable';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectSiriusInfo} from '@selectors/physicalTesting';
import {fetchSiriusData} from '@slices/PhysicalTestingSirius';
import {ESTIMATION_STAGES} from '@src/constants/config';
import {clearPhysicalOverallState} from '@src/redux/slices/PhysicalTestingOverall';
import {Autocomplete, Box, Stack} from '@ui/MUI';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useState} from 'react';

import {siriusOptions, typeOfTestsOptions} from '../../constants';

const Filters = ({filter, setFilter, isLoading, sirius, setSirius}) => {
  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [],
  });
  const groups = useGroupsV2({schoolIds: filter?.school?.value ? [filter?.school?.value] : []});
  const seasons = useSeasons(true, ({currentSeason}) => {
    setFilter({season: {value: currentSeason?.value, label: currentSeason?.label}});
  });

  return (
    <Box mb={1} px={3} py={2} sx={{backgroundColor: '#fff', borderRadius: '6px', overflow: 'hidden'}}>
      <Stack alignItems="flex-end" direction="row" flexWrap="wrap" gap={3}>
        <Box width={{xxs: '100%', sm: 210}}>
          <Autocomplete
            disabled={schools?.isDisabled || groups?.isLoading}
            isLoading={schools.isLoading}
            label="Школа"
            multiple={false}
            options={schools.options}
            value={filter?.school}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                school: v,
                group: '',
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 230}}>
          <Autocomplete
            disabled={groups?.isDisabled || schools?.isLoading || !filter?.school}
            isLoading={groups.isLoading}
            label="Команда"
            multiple={false}
            options={filter?.school ? groups.options : []}
            value={filter?.group}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                group: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 120}}>
          <Autocomplete
            disabled={true}
            label="Вид тестов"
            multiple={false}
            options={typeOfTestsOptions}
            value={typeOfTestsOptions?.find((option) => option?.value === 'sfp')}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 180}}>
          <Autocomplete
            isLoading={seasons?.isLoading}
            label="Сезон"
            multiple={false}
            options={seasons?.options}
            value={filter?.season}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                season: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 180}}>
          <Autocomplete
            label="Этап"
            multiple={false}
            options={ESTIMATION_STAGES}
            value={filter?.stage}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                stage: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 140}}>
          <Autocomplete
            data-testid="age-autocomplete"
            isLoading={isLoading}
            label="Сравнивать с"
            multiple={false}
            name="sirius"
            options={siriusOptions}
            value={sirius?.value ? sirius : null}
            onChange={(_, newValue) => setSirius(newValue)}
          />
        </Box>
      </Stack>
    </Box>
  );
};
const SiriusCompare = (props) => {
  const dispatch = useStoreDispatch();
  const {items, isLoading, isLoad} = useStore(selectSiriusInfo);
  const [filters, setFilters] = useState({});
  const [sirius, setSirius] = useState({});

  const isAllFiltersValuesNotEmpty =
    !!filters?.group?.value && !!filters?.season?.value && !!filters?.stage?.value && !!sirius?.value;

  const fetchData = () => {
    if (isAllFiltersValuesNotEmpty && sirius?.value) {
      dispatch(
        fetchSiriusData({
          groupId: filters?.group?.value,
          season: filters?.season?.value,
          stage: Number(filters?.stage?.value),
          type: 'sfp',
          sirius: sirius?.value,
        }),
      );
    }
  };

  useDeepEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalOverallState());
    };
  }, [filters, isAllFiltersValuesNotEmpty, sirius]);

  return (
    <>
      <Filters filter={filters} isLoading={isLoading} setFilter={setFilters} setSirius={setSirius} sirius={sirius} />
      {!isAllFiltersValuesNotEmpty ? (
        <TableEmptyBlock text="Для отображения данных заполните фильтры." />
      ) : (
        <>
          {!isLoad && !items?.length ? (
            <TableEmptyBlock text="Нет данных." />
          ) : (
            <SiriusCompareTable isLoading={isLoading} tableData={items} />
          )}
        </>
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: setFilters})}
    </>
  );
};

export default SiriusCompare;
