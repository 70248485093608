import {Paper as MuiPaper} from '@mui/material';
import {PaperProps} from '@mui/material/Paper/Paper';
import React from 'react';
import styled from 'styled-components';

type CustomPaperProps = {
  borderRadius?: number;
} & PaperProps;

function Paper({elevation = 4, children, borderRadius = 2, ...props}: CustomPaperProps) {
  return (
    <StyledPaper borderRadius={borderRadius} elevation={elevation} {...props}>
      {children}
    </StyledPaper>
  );
}

export default Paper;

const StyledPaper = styled(MuiPaper)<{
  borderRadius: number;
}>`
  border-radius: ${({borderRadius, theme}) =>
    theme.shape.borderRadiuses?.[borderRadius] || theme.shape?.borderRadiuses?.[0]}px;
`;
