import {IconButtonProps, Theme} from '@mui/material';
import {BoxProps} from '@mui/material/Box/Box';
import {Box, IconButton} from '@ui/MUI';
import React from 'react';
import styled from 'styled-components';

export const DrawerInner = styled.div<{hasbuttons: boolean; ['buttons-block-height']: number}>`
  position: relative;
  height: ${({hasbuttons, ...props}) =>
    hasbuttons && props?.['buttons-block-height'] ? `calc(100% - ${props['buttons-block-height']}px)` : '100%'};
  width: 100%;
  box-sizing: border-box;
  display: block;
`;

export const DrawerContentContainer = styled(Box)`
  position: relative;
  width: 100%;
  min-height: 100%;
`;

export const TitleWrapper = styled(Box)<{theme: Theme} & BoxProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  padding-top: inherit;
  ${({theme}) => ({
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  })}
`;

export const DrawerCloser = styled(IconButton)<IconButtonProps>`
  margin-left: auto;
  padding: 0;
  z-index: 2;
  cursor: pointer;
`;
export const DrawerButtonsBlock = styled.div<{ref: React.Ref<HTMLDivElement> | undefined}>`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  background-color: white;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 100%;
  z-index: 999;
`;
export const DrawerButtonsBlockContent = styled.div<{theme: Theme}>`
  ${({theme}) => ({
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  })}
  width: 100%;
`;
