import {Link as MuiLink} from '@mui/material';
import {LinkProps} from '@mui/material/Link/Link';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import styled from 'styled-components';

export type CustomLinkProps = {
  to: string;
  cursor?: string;
  disabled?: boolean;
} & LinkProps;

function Link(props: CustomLinkProps, ref: React.Ref<HTMLAnchorElement>) {
  const {
    variant = 'subtitle2',
    color = 'primary',
    children,
    disabled = false,
    onClick = () => {},
    underline = 'hover',
    to,
    cursor = 'pointer',
    component,
    target,
    rel,
  } = props;
  return (
    <StyledLink
      {...props}
      color={disabled ? 'text.disabled' : color}
      component={to ? RouterLink : component}
      cursor={disabled ? 'not-allowed' : cursor}
      disabled={disabled}
      ref={ref}
      rel={target === '_blank' && !rel ? 'noopener' : rel}
      target={target}
      to={disabled ? '' : to}
      underline={disabled ? 'none' : underline}
      variant={variant}
      onClick={disabled ? void 0 : onClick}
    >
      {children}
    </StyledLink>
  );
}

export default React.forwardRef(Link);

const StyledLink = styled(MuiLink)<CustomLinkProps>`
  cursor: ${({cursor}) => cursor};
  opacity: ${({disabled}) => disabled && '0.5'};
`;
