import {MODAL_BODY_STYLE, MODAL_HEAD_STYLE, MODAL_STYLE} from '@components/modules/A1TagLayout/constants';
import useEventListener from '@hooks/useEventListener';
import {CloseRounded} from '@mui/icons-material';
import {Button, Modal, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, IconButton, Stack} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {memo} from 'react';

import {HOT_KEYS} from './constants';
import {getKeyBySlug, updateKey} from './helpers';
import {a1TagVideoPlayerHotKeysModal, a1TagVideoPlayerHotKeysViewModel} from './models/a1TagVideoPlayerHotKeys.model';

interface HotKeyProps {
  hotKey: string;
  label: string;
  slug: string;
}

const HotKey = memo(({hotKey, label, slug}: HotKeyProps) => {
  useSignals();
  const isSelected = a1TagVideoPlayerHotKeysViewModel.currentKey === hotKey;
  const key = getKeyBySlug(slug) ?? {key: hotKey, slug};
  return (
    <Stack
      alignItems="center"
      borderRadius="6px"
      direction="row"
      justifyContent="space-between"
      px={3}
      py="20px"
      sx={{
        background: '#262626',
      }}
    >
      <Typography color={black[95]} variant="subtitle1">
        {label}
      </Typography>
      <Button
        size="small"
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          a1TagVideoPlayerHotKeysViewModel.setCurrentKey(hotKey);
          a1TagVideoPlayerHotKeysViewModel.setCurrentSlug(slug);
        }}
        onKeyDown={(e) => {
          if (isSelected) {
            e.preventDefault();
          }
        }}
      >
        {isSelected ? 'Press any key' : key.key}
      </Button>
    </Stack>
  );
});

HotKey.displayName = 'HotKey';

export const A1TagVideoPlayerHotKeys = () => {
  useSignals();
  // listen to any key press after current key is pressed
  useEventListener('keydown', (event: KeyboardEvent) => {
    const key = event.key === ' ' ? 'Space' : event.key;

    if (a1TagVideoPlayerHotKeysViewModel.currentSlug) {
      if (key !== 'Escape') {
        updateKey(key, a1TagVideoPlayerHotKeysViewModel.currentSlug);
      }
      a1TagVideoPlayerHotKeysViewModel.setCurrentKey(null);
      a1TagVideoPlayerHotKeysViewModel.setCurrentSlug(null);
    }
  });

  return (
    <Modal
      disableEscapeKeyDown
      open={a1TagVideoPlayerHotKeysModal.isOpen}
      onClose={() => a1TagVideoPlayerHotKeysModal.close()}
    >
      <Box
        sx={{
          ...MODAL_STYLE,
          maxWidth: 850,
        }}
      >
        <Box sx={MODAL_HEAD_STYLE}>
          <Typography color={black[75]} textTransform="uppercase" variant="overline2">
            Hot keys
          </Typography>
          <IconButton
            sx={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}}
            onClick={() => a1TagVideoPlayerHotKeysModal.close()}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={MODAL_BODY_STYLE}>
          <Typography color={black[75]} mb={3} variant="h3">
            Media player
          </Typography>
          <Stack spacing="4px">
            {HOT_KEYS.map((hotKey) => (
              <HotKey hotKey={hotKey.defaultKey} key={hotKey.slug} label={hotKey.name} slug={hotKey.slug} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
