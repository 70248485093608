import ChildrenField from '@common/ChildrenField';
import {Subsection} from '@components/modules/accesses/accesses.type';
import {useAsignOrDeletUserFromSubSections, useSection} from '@components/modules/accesses/queries/section.query';
import {ModalMode} from '@components/modules/common/modal-view-model';
import {useSchools} from '@components/modules/school/queries/schools.query';
import {School} from '@components/modules/school/types/school.types';
import {useUsers} from '@components/modules/user/queries/user.query';
import {User} from '@components/modules/user/types/user.type';
import {useSignals} from '@preact/signals-react/runtime';
import {roleOptions} from '@src/constants';
import {Autocomplete, Button, Divider, Stack} from '@ui/MUI';
import Checkbox from '@ui/MUI/Checkbox';
import Drawer from '@ui/MUI/DrawerNew';
import LoadingButton from '@ui/MUI/LoadingButton';
import Dialog from '@ui/MUI/Modals/Dialog/Dialog';
import React, {useEffect} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';

import {confirmationModal, Option, tab, userDrawerFilters, userDrawerModal} from '../models/main.view-model';

export interface UserDrawerForm {
  subSections: Subsection & {accesses: boolean}[];
  user?: User;
}

const getDefaultValue = (subsections: Subsection[], activeSubSectionsIds?: string[]) => {
  if (!activeSubSectionsIds) return subsections.map((section) => ({...section, accesses: false}));

  return subsections.map((section) => ({
    ...section,
    accesses: activeSubSectionsIds.some((id) => id === section.id),
  }));
};

export const UserDrawer = () => {
  useSignals();
  const isEdit = userDrawerModal.mode === ModalMode.EDIT;
  const payload = userDrawerModal.payload;
  const sectionQuery = useSection(tab.value);

  const schoolsQuery = useSchools();
  const defaultValues = getDefaultValue(sectionQuery?.data?.subsections ?? [], payload?.activeSubSectionsIds);

  const form = useForm<UserDrawerForm>();

  const {fields} = useFieldArray({
    control: form.control,
    name: 'subSections',
  });

  const user = form.watch('user');
  const isUsersEnabled = !!userDrawerFilters.school && !!userDrawerFilters.role;

  const usersQuery = useUsers(
    {schoolId: userDrawerFilters.school?.id, userRole: userDrawerFilters.role?.value, limit: 999, active: true},
    {
      enabled: isUsersEnabled,
    },
  );

  const asignOrDeletUserFromSubSectionsMutation = useAsignOrDeletUserFromSubSections(tab.value, {
    onSuccess: () => {
      handleClose();
    },
  });

  const handleClose = () => {
    userDrawerModal.close();
    userDrawerFilters.reset();
    form.reset();
  };

  const openConfirmationModal = () => {
    confirmationModal.open(ModalMode.ADD);
  };

  const handleConfirmationModalClose = () => {
    confirmationModal.close();
  };

  const handleSubmit = (data: UserDrawerForm) => {
    const userId = user?.id || payload?.userId;
    const subSectionsIds = data.subSections
      .filter((section): section is Subsection & {accesses: boolean} => section.accesses)
      .map((section) => section.id);
    const oldSubSectionsIds = payload?.activeSubSectionsIds;

    asignOrDeletUserFromSubSectionsMutation.mutate({userId, newIds: subSectionsIds, oldIds: oldSubSectionsIds});
  };

  const handleDelete = () => {
    confirmationModal.close();
    // отвязываем пользователя от всех разделов на которые он был привязан
    asignOrDeletUserFromSubSectionsMutation.mutate({
      userId: payload?.userId,
      newIds: [],
      oldIds: payload?.activeSubSectionsIds,
    });
    handleClose();
  };

  useEffect(() => {
    if (userDrawerModal.isOpen) {
      form.reset({subSections: defaultValues});
    } else {
      form.reset();
    }
  }, [userDrawerModal.isOpen]);

  return (
    <>
      <Dialog
        actionText="ДА, отозвать"
        closeButtonProps={{color: 'secondary', variant: 'contained'}}
        closeText="отменить"
        content="Вы уверены, что хотите отозвать права доступа к разделу этому пользователю?"
        open={confirmationModal.isOpen}
        title="Отзыв прав доступа"
        onActionButtonClick={handleDelete}
        onCloseDefault={handleConfirmationModalClose}
      />
      <Drawer
        buttons={
          <Stack direction="row" justifyContent="space-between" onClick={form.handleSubmit(handleSubmit)}>
            <LoadingButton
              color="primary"
              disabled={!form.formState.isValid || !form.formState.isDirty}
              loading={asignOrDeletUserFromSubSectionsMutation.isLoading}
              variant="contained"
            >
              Сохранить
            </LoadingButton>
            <Button variant="contained">Отмена</Button>
          </Stack>
        }
        open={userDrawerModal.isOpen}
        title="Права доступа. проспекты
      добавление пользователя"
        onClose={handleClose}
      >
        <FormProvider {...form}>
          <Stack mt={5} spacing={5}>
            {!isEdit && (
              <Stack maxWidth="400px" spacing={3}>
                <Autocomplete
                  isLoading={schoolsQuery.isLoading}
                  label="Школа"
                  multiple={false}
                  // @ts-expect-error TODO: поправить типы в Autocomplete
                  options={schoolsQuery.data ?? []}
                  // @ts-expect-error TODO: поправить типы в Autocomplete
                  value={userDrawerFilters.school}
                  onChange={(_, value) => userDrawerFilters.updateSchool(value as unknown as School)}
                />
                {/* @ts-expect-error TODO: поправить типы в Autocomplete */}
                <Autocomplete
                  isLoading={schoolsQuery.isLoading}
                  label="Роль"
                  multiple={false}
                  options={roleOptions}
                  value={userDrawerFilters.role ?? null}
                  onChange={(_, value) => userDrawerFilters.updateRole(value as Option)}
                />
                {/* @ts-expect-error TODO: поправить типы в childrenField */}
                <ChildrenField isRequired name="user">
                  <Autocomplete
                    disabled={!userDrawerFilters.school || !userDrawerFilters.role || usersQuery.isFetching}
                    isLoading={usersQuery.isFetching}
                    label="Пользователь"
                    multiple={false}
                    // @ts-expect-error TODO: поправить типы в childrenField
                    options={usersQuery.data ?? []}
                  />
                </ChildrenField>
              </Stack>
            )}
            <Stack spacing="4px">
              {fields?.map((field, index) => (
                // @ts-expect-error TODO: поправить типы в childrenField
                <ChildrenField key={field.id} name={`subSections.${index}.accesses`}>
                  {
                    // @ts-expect-error TODO: поправить типы в childrenField
                    (props) => {
                      return (
                        <Checkbox
                          checked={props.value}
                          disabled={!user && !isEdit}
                          // @ts-expect-error TODO: поправить типы в childrenField
                          label={field.name}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      );
                    }
                  }
                </ChildrenField>
              ))}
            </Stack>
            {isEdit && (
              <>
                <Divider />
                <Button color="primary" sx={{alignSelf: 'flex-start'}} onClick={openConfirmationModal}>
                  Отозвать права доступа к разделу
                </Button>
              </>
            )}
          </Stack>
        </FormProvider>
      </Drawer>
    </>
  );
};
