import {useProspectSkills, useProspectSkillTypes} from '@components/modules/prospects/queries/prospect-skill.query';
import {useCurrentSeason} from '@components/modules/season/queries/current-season.query';
import {useSeasons} from '@components/modules/season/queries/seasons.query';
import {Season} from '@components/modules/season/types/season.type';
import {Box, Stack} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Button} from '@ui/MUI';
import React from 'react';
import {useParams} from 'react-router';

import {skillAnalyticsModel} from '../models/skill-analytics.model';
import {toSkills} from '../helpers/table.helper';

const toOption = (season: Season) => ({
  label: season.name,
  value: season.id,
});

export const Filters = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const {seasonId} = skillAnalyticsModel.filters;
  const skillsQuery = useProspectSkills({
    userId: playerId,
    isConstant: false,
  });

  const skillTypesQuery = useProspectSkillTypes({
    isConstant: false,
  });

  const skills = toSkills(skillTypesQuery.data ?? [], skillsQuery.data ?? []);

  const {data: seasons} = useSeasons();
  const options = seasons ? seasons.map(toOption) : [];

  const {data: currentSeason} = useCurrentSeason();

  const currentSeasonOption = currentSeason ? toOption(currentSeason) : null;
  const value = seasonId ? options.find((o) => o.value === seasonId) : currentSeasonOption;
  const isDisabled = skillAnalyticsModel.table.isEditMode;
  const isSomeSkillsDontHaveEndScore = skills.some((skill) => !skill.endScore);

  return (
    <Stack
      alignItems="center"
      borderRadius={1}
      direction="row"
      justifyContent="space-between"
      px={4}
      py={2}
      spacing={2}
      sx={{background: 'white'}}
    >
      <Box minWidth={130}>
        <Autocomplete
          label="Сезон"
          multiple={false}
          options={options}
          value={value}
          onChange={(_, v) => {
            skillAnalyticsModel.filters.seasonId = v?.value ?? null;
          }}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          disabled={isDisabled || !isSomeSkillsDontHaveEndScore}
          size="small"
          variant="contained"
          onClick={() => (skillAnalyticsModel.table.isEditMode = true)}
        >
          Заполнить отчет
        </Button>
        <Button
          color="primary"
          disabled={isDisabled}
          size="small"
          variant="outlined"
          onClick={() => skillAnalyticsModel.modal.open()}
        >
          Управлять списком навыков
        </Button>
      </Stack>
    </Stack>
  );
};
