import styled from 'styled-components';

export const WrapperTableContainer = styled.div`
  overflow: hidden;
  background: white;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  border-radius: 6px 6px 0 0;
`;
export const TableBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: flex-end;
  padding: ${({theme}) => theme.spacing(1, 4)};
  box-shadow: 0 -9px 10px -7px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
  z-index: 1000;
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 0 40px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;
