import {A1TagDeletePaper} from '@components/A1TagDeletePaper';
import {A1TagRostersTable} from '@components/A1TagRostersTable';
import {deletePlayerByAvangardId} from '@components/apps/A1TagAddingRosters/utils';
import {a1tagViewModel} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {sortColumn} from '@components/apps/PhysicalTesting/helpers';
import useDeepMemo from '@hooks/useDeepMemo';
import {useSignals} from '@preact/signals-react/runtime';
import {HAND_OPTIONS_EN, HOCKEY_ROLES_EN_OPTIONS} from '@src/constants';
import {Autocomplete, Box, TextField} from '@ui/MUI';
import {white} from '@ui/MUI/colorsA1Tag';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

export function A1TagEditRostersModalContent() {
  useSignals();

  const payload = modalViewModel.payload.value ?? {};

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value ?? {};

  useEffect(() => {
    if (!payload?.rosters?.length) {
      updatePayload({
        ...payload,
        rosters: {
          homeTeam: homeTeam,
          awayTeam: awayTeam,
        },
      });
    }
  }, [payload?.rosters?.length]);

  const players = useDeepMemo(
    () =>
      payload?.isHomeTeam
        ? [
            ...(payload?.rosters?.homeTeam?.players?.fieldPlayers || []),
            ...(payload?.rosters?.homeTeam?.players?.goalies || []),
          ]
        : [
            ...(payload?.rosters?.awayTeam?.players?.fieldPlayers || []),
            ...(payload?.rosters?.awayTeam?.players?.goalies || []),
          ],
    [payload?.isHomeTeam, payload?.rosters],
  );

  const editRostersForm = useForm();

  const [sorting, setSorting] = useState([{id: 'playerNumber', desc: true}]);

  const [showDeleteContext, setShowDeleteContext] = useState({isShow: false, rowId: 0});

  const columns = [
    {
      accessorKey: 'rowNumber',
      header: '',
      headerStyle: {width: 50, maxWidth: 50},
      cell: ({row}: {row: {id: string}}) => parseInt(row.id) + 1,
      cellStyle: {width: 50, maxWidth: 50},
      enableSorting: false,
    },
    {
      accessorKey: 'playerNumber',
      header: '№',
      headerStyle: {width: 80, maxWidth: 80},
      cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={getValue()}
            name={`${row.id}.playerNumber`}
            render={({field: {onChange, value}}) => {
              return (
                <TextField
                  sx={{input: {color: white.main}}}
                  type="number"
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  onClick={(e) => {
                    if (showDeleteContext?.isShow) {
                      e.preventDefault();
                      setShowDeleteContext({...showDeleteContext, isShow: false});
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                  }}
                />
              );
            }}
          />
        );
      },
      cellStyle: {width: 80, maxWidth: 80},
      sortingFn: sortColumn,
    },
    {
      accessorKey: 'avangardId',
      header: 'ID',
      headerStyle: {width: 96, maxWidth: 96},
      cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={getValue()}
            name={`homeTeamPlayers.${row.id}.avangardId`}
            render={({field: {onChange, value}}) => {
              return (
                <TextField
                  sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  onClick={(e) => {
                    if (showDeleteContext?.isShow) {
                      e.preventDefault();
                      setShowDeleteContext({...showDeleteContext, isShow: false});
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                  }}
                />
              );
            }}
          />
        );
      },
      cellStyle: {width: 120, maxWidth: 120},
    },
    {
      accessorKey: 'lastName',
      header: 'SURNAME',
      headerStyle: {width: 145, maxWidth: 145},
      cell: ({
        getValue,
        row,
      }: {
        getValue: () => string;
        row: {
          original: {id: string; avangardId: string};
          id: string;
        };
      }) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={getValue()}
            name={`homeTeamPlayers.${row.id}.lastName`}
            render={({field: {onChange, value}}) => {
              return (
                <>
                  <TextField
                    sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                    }}
                    onClick={(e) => {
                      if (showDeleteContext?.isShow) {
                        e.preventDefault();
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                    }}
                  />
                  {showDeleteContext?.isShow && showDeleteContext?.rowId === Number(row?.id) && (
                    <A1TagDeletePaper
                      onDelete={() => {
                        deletePlayerByAvangardId({
                          isMainWindow: true,
                          isHomeTeam: true,
                          payload: payload,
                          avangardId: row?.original?.avangardId,
                        });
                        setShowDeleteContext({...showDeleteContext, isShow: false});
                      }}
                    />
                  )}
                </>
              );
            }}
          />
        );
      },
      cellStyle: {width: 145, maxWidth: 145},
    },
    {
      accessorKey: 'firstName',
      header: 'NAME',
      headerStyle: {width: 115, maxWidth: 115},
      cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={getValue()}
            name={`homeTeamPlayers.${row.id}.firstName`}
            render={({field: {onChange, value}}) => {
              return (
                <TextField
                  sx={{input: {fontSize: 14, lineHeight: 20, color: white.main}}}
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  onClick={(e) => {
                    if (showDeleteContext?.isShow) {
                      e.preventDefault();
                      setShowDeleteContext({...showDeleteContext, isShow: false});
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                  }}
                />
              );
            }}
          />
        );
      },
      cellStyle: {width: 115, maxWidth: 115},
    },
    {
      accessorKey: 'hockeyRole',
      header: 'POSITION',
      headerStyle: {width: 130, maxWidth: 130},
      cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === getValue())?.value}
            name={`homeTeamPlayers.${row.id}.hockeyRole`}
            render={({field: {onChange, value}}) => {
              return (
                <Autocomplete
                  disableClearable={true}
                  multiple={false}
                  options={HOCKEY_ROLES_EN_OPTIONS}
                  sx={{'.MuiAutocomplete-input': {color: white.main}}}
                  value={HOCKEY_ROLES_EN_OPTIONS?.find((option) => option.value === value)}
                  onChange={(_, newValue) => {
                    onChange(newValue?.value);
                    // setTeamsPlayers({
                    //   ...teamsPlayers,
                    //   homeTeamPlayers: teamsPlayers?.homeTeamPlayers?.map((player, index) => {
                    //     if (index === Number(row.id)) {
                    //       return {...player, hockeyRole: newValue?.value};
                    //     } else {
                    //       return player;
                    //     }
                    //   }),
                    // });
                  }}
                  onClick={(e) => {
                    if (showDeleteContext?.isShow) {
                      e.preventDefault();
                      setShowDeleteContext({...showDeleteContext, isShow: false});
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                  }}
                />
              );
            }}
          />
        );
      },
      cellStyle: {width: 140, maxWidth: 140},
    },
    {
      accessorKey: 'hand',
      header: 'HAND',
      headerStyle: {width: 88, maxWidth: 88},
      cell: ({getValue, row}: {getValue: () => string; row: {id: string}}) => {
        return (
          <Controller
            control={editRostersForm.control}
            defaultValue={HAND_OPTIONS_EN?.find((option) => option.value === getValue())?.value}
            name={`homeTeamPlayers.${row.id}.hand`}
            render={({field: {onChange, value}}) => {
              return (
                <Autocomplete
                  disableClearable={true}
                  multiple={false}
                  options={HAND_OPTIONS_EN}
                  sx={{'.MuiAutocomplete-input': {color: white.main}}}
                  value={HAND_OPTIONS_EN?.find((option) => option.value === value)}
                  onChange={(_, newValue) => {
                    onChange(newValue?.value);
                  }}
                  onClick={(e) => {
                    if (showDeleteContext?.isShow) {
                      e.preventDefault();
                      setShowDeleteContext({...showDeleteContext, isShow: false});
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setShowDeleteContext({isShow: true, rowId: Number(row.id)});
                  }}
                />
              );
            }}
          />
        );
      },
      cellStyle: {width: 88, maxWidth: 88},
    },
  ];

  return (
    <Box mx={-4} my={-3} sx={{'.TableWrapper': {border: 'none !important', borderRadius: 0}}} {...editRostersForm}>
      <A1TagRostersTable
        columns={columns}
        data={!players?.length ? [] : players}
        isHomeTeam={payload?.isHomeTeam}
        isMainWindow={true}
        setSorting={setSorting}
        sorting={sorting}
      />
    </Box>
  );
}
