import {SCOUT_REPORT_TYPES} from '../../../constants';

export const tabs = [
  {
    link: SCOUT_REPORT_TYPES.inner,
  },
  {
    link: SCOUT_REPORT_TYPES.archive,
  },
];
