import {updateCleanTime} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {playerViewModel, seekTo} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {PlayCircle} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Stack} from '@ui/MUI';
import moment from 'moment';
import React from 'react';

import {CellDef} from '../a1Table.types';

const convertToSeconds = (time: string) => {
  const duration = moment.duration(time);

  return duration.asSeconds();
};

export const TimeCell = ({getValue, row}: CellDef<string>) => {
  useSignals();
  const value = getValue();
  const isReady = playerViewModel.isReady.value;

  const seekToTime = () => {
    const seconds = convertToSeconds(value);
    seekTo(seconds, 'seconds');

    updateCleanTime(row?.original?.second_clear || 0);
  };

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography fontSize="14px" lineHeight={1}>
        {value}
      </Typography>
      <IconButton disabled={!isReady} size="small" sx={{py: '0px'}} onClick={seekToTime}>
        <PlayCircle />
      </IconButton>
    </Stack>
  );
};
