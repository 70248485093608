import {signal} from '@preact/signals-react';

export const skillAnalyticsModel = {
  filters: {
    _seasonId: signal<string | null>(null),

    get seasonId() {
      return this._seasonId.value;
    },

    set seasonId(value: string | null) {
      this._seasonId.value = value;
    },
  },
  modal: {
    _isOpen: signal<boolean>(false),

    get isOpen() {
      return this._isOpen.value;
    },

    open() {
      this._isOpen.value = true;
    },

    close() {
      this._isOpen.value = false;
    },
  },
  table: {
    _isEditMode: signal<boolean>(false),

    get isEditMode() {
      return this._isEditMode.value;
    },

    set isEditMode(value: boolean) {
      this._isEditMode.value = value;
    },
  },
};
