import {loadStatsForOneGame} from '@actions/StatsActions';
import ScorePage from '@common/Statistics/PlayerStatistics/ScorePage';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import React, {useEffect} from 'react';
import {shallowEqual} from 'react-redux';
import {useParams} from 'react-router-dom';

function Main({hasOT = false, winByOT = false, winByBuls = false, isV2 = false}) {
  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'vhod_na_str_statistiki');
  }, []);

  const dispatch = useStoreDispatch();
  const {statisticId} = useParams();
  const [{teamMKCId}] = useQueryString();
  const gameResults = useStore((state) => state.stats.gameStatistics, shallowEqual);
  const isLoading = gameResults?.loading;
  return (
    <ScorePage
      fetchFunction={(params) => dispatch(loadStatsForOneGame({statisticId, ...params}))}
      fetchParams={{statisticId, mkcTeamId: teamMKCId}}
      isV2={isV2}
      {...{hasOT, winByOT, winByBuls, isLoading, gameResults}}
    />
  );
}

export default Main;
