import ChildrenField from '@common/ChildrenField';
import {useCreateProspectReportMutation} from '@components/modules/prospects/mutation/create-prospect-report.mutation';
import {Divider, Stack, TextField, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Button} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import LoadingButton from '@ui/MUI/LoadingButton';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {States} from '../../main/modal/modal.view-modal';
import {playerReportModal} from '../modals/modal.view-modal';
import {useCurrentProspectReportQuery} from '@components/modules/prospects/queries/prospect-report.query';
import {ReportDto} from '@src/types/generated';
import moment from 'moment';

const mock = [
  {
    prospect: {
      id: 'user-id',
      avatar: 'avatar-url',
      firstName: 'Имя',
      lastName: 'Фамилия',
      hockeyRole: 'winger',
    },
    advantages: 'Достоинства',
    focus: 'Фокус',
    createdAt: 'date',
    updatedAt: 'date',
  },
];

interface FormData {
  id?: string;
  advantages: string;
  focus: string;
}

export const FormModal = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const currentReportQuery = useCurrentProspectReportQuery(playerId);

  const actual = currentReportQuery.data as ReportDto;
  const isEdit = playerReportModal.state === States.EDIT;
  const form = useForm<FormData>();
  useEffect(() => {
    if (isEdit) {
      form.reset({
        advantages: actual.advantages,
        focus: actual.focus,
      });
    }
  }, [isEdit]);

  const handleClose = () => {
    playerReportModal.close();
    form.reset({
      id: null,
      advantages: null,
      focus: null,
    });
  };

  const prospectReportMutation = useCreateProspectReportMutation(playerId);

  const onSubmit = async (data: FormData) => {
    await prospectReportMutation.mutateAsync({
      ...data,
      userId: playerId,
    });
    handleClose();
  };

  return (
    <FormProvider {...form}>
      <Drawer
        buttons={
          <Stack direction="row" justifyContent="space-between">
            <LoadingButton
              color="primary"
              disabled={prospectReportMutation.isLoading || !form.formState.isValid}
              loading={prospectReportMutation.isLoading}
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
            >
              Сохранить
            </LoadingButton>
            <Button variant="contained" onClick={handleClose}>
              Отменить
            </Button>
          </Stack>
        }
        open={playerReportModal.isOpen}
        title="test"
        onClose={handleClose}
      >
        <Stack spacing={5}>
          {isEdit && (
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Дата добавления отчета:</Typography>
                <Typography color="#2C2C2C" variant="body2">
                  {moment(actual.createdAt).format('DD.MM.YYYY')}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Последнее редактирование:</Typography>
                <Typography color="#2C2C2C" variant="body2">
                  {moment(actual.updatedAt).format('DD.MM.YYYY')}
                </Typography>
              </Stack>
            </Stack>
          )}
          {isEdit && <Divider />}
          <ChildrenField isRequired name="advantages">
            <TextField multiline label="Преимущества" variant="standard" />
          </ChildrenField>
          <ChildrenField isRequired name="focus">
            <TextField multiline label="Фокус развития" variant="standard" />
          </ChildrenField>
        </Stack>
      </Drawer>
    </FormProvider>
  );
};
