export const PERIOD_OPTIONS = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: 'OT', label: 'OT'},
];

export const LINES_OPTIONS = [
  {value: '5-5', label: '5-5'},
  {value: '4-4', label: '4-4'},
  {value: '3-3', label: '3-3'},
];
