import {Section, SectionFilters, UserSections, UserSubSection} from '@components/modules/accesses/accesses.type';
import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export const getSections = async () => {
  return axios.get(`${API_ENDPOINT}/v1/section`).then((res) => res.data) as Promise<Section[]>;
};

export const createSection = async (data: Section) => {
  return axios.post(`${API_ENDPOINT}/v1/section`, data).then((res) => res.data) as Promise<Section>;
};

export const getSection = async (id: string, query?: SectionFilters) => {
  return axios.get(`${API_ENDPOINT}/v1/section/${id}`, {params: query}).then((res) => res.data) as Promise<Section>;
};

export const updateSection = async (id: string, data: Section) => {
  return axios.put(`${API_ENDPOINT}/v1/section/${id}`, data).then((res) => res.data) as Promise<Section>;
};

export const createSubSection = async (data: Omit<Section, 'subSections'>) => {
  return axios.post(`${API_ENDPOINT}/v1/section/subsection`, data).then((res) => res.data) as Promise<
    Omit<Section, 'subSections'>
  >;
};

export const asignUserToSubSections = async (userId: string, data: string[]) => {
  return axios
    .put(`${API_ENDPOINT}/v1/section/subsection/user/${userId}`, {
      subsectionIds: data,
    })
    .then((res) => res.data) as Promise<Section>;
};

export const deleteUserFromSubSections = async (userId: string, data: string[]) => {
  return axios
    .delete(`${API_ENDPOINT}/v1/section/subsection/user/${userId}`, {
      data: {
        subsectionIds: data,
      },
    })
    .then((res) => res.data) as Promise<Section>;
};

export const asingOrDeletUserFromSubSections = async (userId: string, newIds: string[], oldIds?: string[]) => {
  const toDelete = oldIds?.filter((id) => !newIds.includes(id)) ?? [];
  const toAdd = newIds.filter((id) => !oldIds?.includes(id)) ?? [];
  if (toDelete.length > 0) {
    await deleteUserFromSubSections(userId, toDelete);
  }

  if (toAdd.length > 0) {
    await asignUserToSubSections(userId, toAdd);
  }
};

export const getUserSections = async (userId: string) => {
  return axios.get<UserSections>(`${API_ENDPOINT}/v1/section/user/${userId}`).then((res) => res.data);
};

export const getUserSubSections = async (userId: string) => {
  return axios.get<UserSubSection[]>(`${API_ENDPOINT}/v1/section/subsection/user/${userId}`).then((res) => res.data);
};
