import {Link} from 'react-router-dom';
import styled, {createGlobalStyle, css} from 'styled-components';

export const Header = `
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  max-height: 60px;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 100vh;
  background-color: ${({theme}) => theme.palette.backgroundsAndOther.menuBackground};
  overflow: hidden;

  ${({theme}) => theme.breakpoints.down('md')} {
    ${css`
      ${Header}
    `}
    height: 100%;
    padding: ${({theme}) => theme.spacing(2, 5)};
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: ${({theme}) => theme.spacing(2, 3)};
  }
`;

export const MenuContentHeader = styled.div`
  ${css`
    ${Header}
  `}
  padding: ${({theme}) => theme.spacing('0', 5, '0', 4)};
  justify-content: flex-end;
  ${({theme}) => theme.breakpoints.down('sm')} {
    max-height: 44px;
    height: 44px;
  }
`;

export const LogoImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({theme}) => theme.spacing(4, '0', 6, '0')};
  ${({theme}) => theme.breakpoints.down('md')} {
    width: auto;
    margin: 0;
    height: 100%;
  }
`;

export const LogoImage = styled(Link)`
  display: block;
  text-decoration: none;
  height: 31px;
  &.Logo_amateur {
    height: 130px;
  }
  img {
    height: 100%;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    width: auto;
    margin: 0;
    height: 100%;
  }
`;

export const BottomUserPanelContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.backgroundsAndOther.menuBackground};
  z-index: 1;
`;

export const DrawerStyleOverride = createGlobalStyle`
  .MuiDrawer {
    &-paper {
      .content-container {
        padding: 0;
      }
    }
  }
`;
