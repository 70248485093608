import {RadioGroup as RadioGroupMUI, RadioPropsSizeOverrides} from '@mui/material';
import {FormControlLabelProps} from '@mui/material/FormControlLabel/FormControlLabel';
import {RadioGroupProps} from '@mui/material/RadioGroup/RadioGroup';
import {OverridableStringUnion} from '@mui/types';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import Radio from '@ui/MUI/Radio';
import React, {forwardRef} from 'react';

type RadioItem = Pick<FormControlLabelProps, 'label' | 'value'>;

type CustomRadioGroupProps = {
  checkboxes: RadioItem[];
  error?: boolean;
  size?: OverridableStringUnion<'small' | 'medium', RadioPropsSizeOverrides>;
  formControlLabelProps?: FormControlLabelProps;
} & RadioGroupProps;

const RadioGroup = forwardRef(function RadioGroup(
  {children, value, onChange, checkboxes = [], error, formControlLabelProps, ...props}: CustomRadioGroupProps,
  ref,
) {
  return (
    <RadioGroupMUI ref={ref} value={value} onChange={onChange} {...props}>
      {checkboxes?.length
        ? checkboxes.map((item, index) => (
            <FormControlLabel
              control={<Radio inputProps={{role: 'radio'}} size={props?.size || 'medium'} />}
              error={error}
              key={index}
              label={item?.label}
              value={item?.value}
              {...formControlLabelProps}
            />
          ))
        : children}
    </RadioGroupMUI>
  );
});

export default RadioGroup;
