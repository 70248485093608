import ReactTableComponent from '@common/ReactTableComponent';
import {sectionMock} from '@components/modules/accesses/constants/section.constant';
import {useSection} from '@components/modules/accesses/queries/section.query';
import {useSignals} from '@preact/signals-react/runtime';
import {Stack, Typography} from '@ui/MUI';
import React, {memo} from 'react';

import {generateColumns, toTableData} from '../constants/main-page.constant';
import {tab, tableFilters} from '../models/main.view-model';

export const Table = memo(() => {
  useSignals();
  const sectionQuery = useSection(tab.value, {
    schoolId: tableFilters.school?.id,
    userRole: tableFilters.role?.value,
  });
  const tableData = toTableData(sectionQuery?.data ?? sectionMock);
  const columns = generateColumns(sectionQuery.isLoading, sectionQuery.data?.subsections?.length ?? 0);
  return tableData.length ? (
    // @ts-expect-error TODO: поправить типы в columns
    <ReactTableComponent columns={columns} data={tableData} />
  ) : (
    <Stack
      alignItems="center"
      borderRadius={2}
      justifyContent="center"
      minHeight="200px"
      px={4}
      py={2}
      sx={{background: 'white'}}
    >
      <Typography>Данные не найдены</Typography>
    </Stack>
  );
});

Table.displayName = 'Table';
