import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {coords, maxX, maxY, updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsA1Tag';
import RadioGroup from '@ui/MUI/RadioGroup';
import React from 'react';

const types = [
  {value: 'None', label: 'None'},
  {value: 'Breakout', label: 'Breakout'},
  {value: 'Entry', label: 'Entry'},
];

export function A1TagGiveawayModalContent() {
  useSignals();
  const payload = modalViewModel.payload.value ?? {};
  const {x, y} = coords.getCoords();

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const homePlayersOptions =
    uniqHomePlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const awayPlayersOptions =
    uniqAwayPlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const isSelectedPlayerHome = homePlayersOptions?.map((option) => option.value).includes(payload?.player?.avangardId);

  const {currentTime} = useCurrentTime();

  return (
    <Box>
      <Box sx={{mx: -4, borderBottom: `1px solid ${black[25]}`}}>
        <Box pb={4} pt={1} px={4} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={218} pl={2}>
            <Autocomplete
              multiple={false}
              options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.player ?? null}
              width="100%"
              onChange={(_, v: {value: string; label: string}) => {
                const player = allPlayers.value.find((p) => p.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };

                updatePayload({
                  ...payload,
                  player: v ? merged : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mx={-4}>
        <Box p={4}>
          <Box display="flex" gap={2} sx={{pb: 0}}>
            <Typography color={grey[54]} py={1} variant="subtitle2">
              Type
            </Typography>
            <RadioGroup
              row
              checkboxes={types}
              color="primary"
              formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
              name="type"
              size="small"
              value={payload.action_type ?? null}
              onChange={(_, v) => {
                updatePayload({
                  ...payload,
                  action_type: v,
                });
              }}
            />
          </Box>
          <Box sx={{position: 'relative', mt: 3, mb: 2}}>
            <Box
              mx="auto"
              px={4}
              sx={{
                position: 'relative',
                maxWidth: 650,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: -44,
                  transform: 'translateY(-50%) rotate(-90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {homeTeam?.name}
              </Typography>
              <ArenaSchema
                eclipses={coords.getEclipses()}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  coords.updateEclipses([eclipse]);
                  updatePayload({
                    ...payload,
                    pos_x: cartesianX,
                    pos_y: cartesianY,
                  });
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                  coords.updateCoords(cartesianX, cartesianY);
                }}
              />
              <Typography sx={{mt: 1, textAlign: 'center'}} variant="subtitle1">
                ({x.toFixed(1)}, {y.toFixed(1)})
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  right: -22,
                  transform: 'translateY(-50%) rotate(90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {awayTeam?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
