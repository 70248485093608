import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {a1tagViewModel, allPlayers} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {coords, maxX, maxY, updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React from 'react';

export const A1TagGoalieExitModalContent = () => {
  useSignals();
  const payload = modalViewModel.payload.value ?? {};
  const {x, y} = coords.getCoords();

  const options = allPlayers.value.map((player) => ({
    label: `${player.lastName} ${player.firstName}`,
    value: player.avangardId,
  }));

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const {currentTime} = useCurrentTime();

  return (
    <Box>
      <Box sx={{mx: -4, borderBottom: `1px solid ${black[25]}`}}>
        <Box pb={4} pt={1} px={4} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={218} pl={2}>
            <Autocomplete
              multiple={false}
              options={options}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.player ?? null}
              width="100%"
              onChange={(_, v) => {
                const player = allPlayers.value.find((p) => p.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };

                updatePayload({
                  ...payload,
                  opponent: v ? merged : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mx={-4}>
        <Box p={4}>
          <Box sx={{position: 'relative', mt: 3, mb: 2}}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: -44,
                transform: 'translateY(-50%) rotate(-90deg)',
                mb: 2,
                color: black[75],
                textTransform: 'uppercase',
              }}
              variant="overline2"
            >
              {homeTeam?.name}
            </Typography>
            <Box
              px={4}
              sx={{
                position: 'relative',
              }}
            >
              <ArenaSchema
                eclipses={coords.getEclipses()}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  coords.updateEclipses([eclipse]);
                  updatePayload({
                    ...payload,
                    pos_x: cartesianX,
                    pos_y: cartesianY,
                  });
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                  coords.updateCoords(cartesianX, cartesianY);
                }}
              />
              <Typography sx={{mt: 1, textAlign: 'center'}} variant="subtitle1">
                ({x.toFixed(1)}, {y.toFixed(1)})
              </Typography>
            </Box>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                right: -22,
                transform: 'translateY(-50%) rotate(90deg)',
                mb: 2,
                color: black[75],
                textTransform: 'uppercase',
              }}
              variant="overline2"
            >
              {awayTeam?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
