import {createProspectSkills} from '@api/prospect.api';
import {SkillCreateDto} from '@src/types/generated';
import {useMutation, useQueryClient} from '@tanstack/react-query';

export const useCreateProspectSkills = (playerId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: SkillCreateDto[]) => createProspectSkills(data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'skills']});
    },
  });
};
