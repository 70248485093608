import a1tagHawkLogo from '@assets/icons/a1tagHawkLogo.svg';
import {ErrorBoundary} from '@components/ErrorBoundary';
import {OpenInNewRounded} from '@mui/icons-material';
import {Button, Divider, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {JSX, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {AdditionalMenuBlock, Head, Logo, Menu, Wrapper} from './A1TagLayout';

type A1TagLayoutProps = {
  children: ReactNode;
  additionalMenu?: () => JSX.Element;
};
export function A1TagLayout({children, additionalMenu}: A1TagLayoutProps) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const history = useHistory();

  if (!currentUser) {
    return null;
  }

  return (
    <Wrapper>
      <ErrorBoundary>
        <Menu>
          <Button
            size="small"
            sx={{
              color: black[75],
              textTransform: 'none',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: black[20],
                color: black[95],
              },
            }}
            variant="contained"
          >
            <Typography color={black[75]} variant="caption">
              File
            </Typography>
          </Button>
          <Button
            size="small"
            sx={{
              color: black[75],
              textTransform: 'none',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: black[20],
                color: black[95],
              },
            }}
            variant="contained"
          >
            <Typography color={black[75]} variant="caption">
              Game
            </Typography>
          </Button>
          <Button
            size="small"
            sx={{
              mr: 1,
              color: black[75],
              textTransform: 'none',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: black[20],
                color: black[95],
              },
            }}
            variant="contained"
          >
            <Typography color={black[75]} variant="caption">
              Help
            </Typography>
          </Button>
          <Divider borderColor={black[20]} />
          <Button
            endIcon={<OpenInNewRounded />}
            size="small"
            sx={{
              ml: 1,
              color: black[75],
              textTransform: 'none',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: black[20],
                color: black[95],
              },
            }}
            variant="contained"
            onClick={() => history.push('/')}
          >
            <Typography color={black[75]} variant="caption">
              ИТ-Платформа
            </Typography>
          </Button>
          <Head>
            <Logo alt="логотип Авангард" src={a1tagHawkLogo} />
            <Typography variant="caption">A1TAG</Typography>
          </Head>
          {additionalMenu && <AdditionalMenuBlock>{additionalMenu()}</AdditionalMenuBlock>}
        </Menu>
        {children}
      </ErrorBoundary>
    </Wrapper>
  );
}
