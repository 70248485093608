import {VideoPlayer} from '@common/VideoPlayer';
import dateHelper from '@helpers/date';
import {Box, Chip, Skeleton, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

Card.propTypes = {
  date: PropTypes.string,
  previewLink: PropTypes.string,
  videoLink: PropTypes.string,
  light: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isLoading: PropTypes.bool,
  isPersonalFile: PropTypes.bool,
  onCardClick: PropTypes.func,
};
function Card({video, previewLink, videoLink, light, isLoading, isPersonalFile, onCardClick, ...props}) {
  const history = useHistory();
  return (
    <Box {...props} mb={4}>
      <Skeleton height={148} isLoading={isLoading} sx={{transform: 'none'}} width="100%">
        <Box borderRadius="4px" mb={2} overflow="hidden">
          {!isPersonalFile ? (
            <VideoPlayer
              height={148}
              light={light}
              url={video.link}
              width="100%"
              onClickPreview={() => {
                previewLink && history.push(previewLink);
              }}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                minHeight: '150px',
                height: '100%',
                backgroundImage: `url(${light})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                onCardClick && onCardClick();
              }}
            />
          )}
        </Box>
      </Skeleton>

      <Box>
        <VideoName
          {...{isLoading}}
          preloaderWidthBetween={[60, 180]}
          variant="subtitle2"
          onClick={() => videoLink && history.push(videoLink)}
        >
          {video.name}
        </VideoName>
        <Box alignItems="center" display="flex" mt={0}>
          <Typography {...{isLoading}} color={grey[42]} preloaderProps={{width: 70}} variant="caption1">
            {dateHelper(video.createdAt).format('DD.MM.YYYY')}
          </Typography>
          {video?.opponent && (
            <Typography
              alignItems="center"
              display="flex"
              {...{isLoading}}
              color={grey[42]}
              preloaderProps={{width: 70}}
              variant="caption1"
            >
              <Box component="span" sx={{mx: 0, width: 4, height: 4, backgroundColor: grey[42], borderRadius: '50%'}} />
              {video.opponent}
            </Typography>
          )}
        </Box>
        {video?.tag?.name && (
          <Skeleton isLoading={isLoading}>
            <Chip label={video.tag.name} sx={{mt: 3}} variant="outlined" />
          </Skeleton>
        )}
      </Box>
    </Box>
  );
}

export default Card;

const VideoName = styled(Typography)`
  cursor: pointer;
  display: -webkit-box;
  word-wrap: anywhere;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({theme}) => theme.breakpoints.down('sm')} {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
`;
