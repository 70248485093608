import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {getZone} from '@components/A1TagEventModal/utils';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {coords, maxX, maxY, updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React from 'react';

export function A1TagPuckBattleModalContent() {
  useSignals();
  const payload = modalViewModel.payload.value ?? {};
  const {x, y} = coords.getCoords();

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const homePlayersOptions =
    uniqHomePlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const awayPlayersOptions =
    uniqAwayPlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const isSelectedPlayerHome = homePlayersOptions?.map((option) => option.value)?.includes(payload?.player?.avangardId);

  const {currentTime} = useCurrentTime();

  return (
    <Box>
      <Box sx={{mx: -4, borderBottom: `1px solid ${black[25]}`}}>
        <Box pb={4} pt={1} px={4} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={250} pl={2}>
            <Autocomplete
              multiple={false}
              options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.player ?? null}
              width="100%"
              onChange={(_, v: {value: string; label: string}) => {
                const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };
                updatePayload({
                  ...payload,
                  player: v ? merged : null,
                });
              }}
            />
          </Box>
          <Typography sx={{px: 1, color: black[60]}} variant="subtitle2">
            Won against
          </Typography>
          <Box minWidth={250}>
            <Autocomplete
              multiple={false}
              options={isSelectedPlayerHome ? awayPlayersOptions : homePlayersOptions}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.opponent ?? null}
              width="100%"
              onChange={(_, v: {value: string; label: string}) => {
                const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };
                updatePayload({
                  ...payload,
                  opponent: v ? merged : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mx={-4}>
        <Box pt={3} px={4}>
          <Box sx={{position: 'relative', mt: 3, mb: 2}}>
            <Box
              mx="auto"
              px={4}
              sx={{
                position: 'relative',
                maxWidth: 650,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: -44,
                  transform: 'translateY(-50%) rotate(-90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {homeTeam?.name}
              </Typography>
              <ArenaSchema
                eclipses={coords.getEclipses()}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  coords.updateEclipses([eclipse]);
                  const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                  const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                  if (!zone?.length) {
                    updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY});
                  } else {
                    updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY, zone_name: zone});
                  }
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                  coords.updateCoords(cartesianX, cartesianY);
                }}
              />
              <Typography sx={{mt: 1, textAlign: 'center'}} variant="subtitle1">
                ({x.toFixed(1)}, {y.toFixed(1)})
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  right: -22,
                  transform: 'translateY(-50%) rotate(90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {awayTeam?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
