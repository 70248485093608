import {A1TableColumnDef} from '@components/A1Table/a1Table.types';
import {a1TableViewModel} from '@components/A1Table/models/table.view-mode';
import {useSignals} from '@preact/signals-react/runtime';
import {Table} from '@tanstack/react-table';
import Checkbox from '@ui/MUI/Checkbox';
import React, {useState} from 'react';

export const CheckBoxAllCell = ({table}: {table: Table<A1TableColumnDef>}) => {
  useSignals();
  const [isSelected, setIsSelected] = useState(false);
  const handleChange = () => {
    setIsSelected(!isSelected);
    if (isSelected) {
      table.getRowModel().rows.forEach((row) => {
        a1TableViewModel.removeSelectedRow(row.original.id);
      });
    } else {
      table.getRowModel().rows.forEach((row) => {
        a1TableViewModel.appendSelectedRow(row.original);
      });
    }
  };

  return <Checkbox checked={isSelected} size="medium" onChange={handleChange} />;
};
