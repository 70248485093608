import Wrapper from '@components/apps/StatisticsWithMkcUral/components/Wrapper';
import {GAME_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {selectPastGames, selectTableTabNextGames} from '@selectors/gameStatisticsMkc';
import {clearPastGames, clearTableTabNextGames, loadPastGames, loadTableTabNextGames} from '@slices/statisticsMKC';
import {Box, Stack, Typography} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import Pagination from '@ui/MUI/Pagination';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

GamesList.propTypes = {
  Component: PropTypes.func.isRequired,
  hasWrapper: PropTypes.bool,
  wrapperProps: PropTypes.object,
  type: PropTypes.oneOf([GAME_TYPES.pastGames, GAME_TYPES.nextGames]),
  teamMKCId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  gamesWrapper: PropTypes.object,
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};
export const GAMES_API = {
  [GAME_TYPES.pastGames]: {
    load: loadPastGames,
    clear: clearPastGames,
    select: selectPastGames,
  },
  [GAME_TYPES.nextGames]: {
    load: loadTableTabNextGames,
    clear: clearTableTabNextGames,
    select: selectTableTabNextGames,
  },
};
const LIMIT = {
  mobile: 4,
  desktop: 8,
};

function GamesList({
  Component,
  hasWrapper = false,
  wrapperProps,
  type,
  placeholder,
  teamMKCId,
  gamesWrapper,
  isFinal = false,
  season,
  ...props
}) {
  const dispatch = useStoreDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(LIMIT.mobile);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    data: {games, pages},
    isLoading,
    isLoad,
    error,
  } = useStore(GAMES_API[type].select);

  const [{groupName}, _] = useQueryString();

  useEffect(() => {
    const defaultRequest = GAMES_API[type].load({
      teamId: teamMKCId,
      page: isMobile ? 1 : page,
      limit: isMobile ? limit : LIMIT.desktop,
      isFinal,
      season,
    });
    const requestWithGroupName = GAMES_API[type].load({
      teamId: teamMKCId,
      page: isMobile ? 1 : page,
      limit: isMobile ? limit : LIMIT.desktop,
      isFinal,
      season,
      groupName: encodeURIComponent(groupName),
    });
    if (teamMKCId && !groupName) {
      dispatch(defaultRequest);
    }
    if (teamMKCId && groupName) {
      dispatch(requestWithGroupName);
    }
  }, [type, page, limit, isMobile, teamMKCId, isFinal, season, groupName]);

  useEffect(() => {
    return () => {
      dispatch(GAMES_API[type].clear());
    };
  }, []);
  if ((isLoad && error) || (isLoad && !games?.length) || !teamMKCId) {
    return (
      <Stack alignItems="center" height={300} justifyContent="center" width="100%">
        <Typography variant="caption1">{placeholder}</Typography>
      </Stack>
    );
  }
  return (
    <Wrapper height={isLoading || props.isLoading ? 300 : ''} position="relative" {...gamesWrapper}>
      {(!isMobile && isLoading) || (isMobile && !games?.length && isLoading) || props.isLoading ? (
        <LocalPreloader visible={true} />
      ) : (
        <ItemsWrapper hasWrapper={hasWrapper} {...wrapperProps}>
          {games?.map((item, index, arr) => (
            <Component game={item} isLastItem={index === arr.length - 1} key={index} />
          ))}
        </ItemsWrapper>
      )}

      {pages > 1 && (
        <Box mt={{xxs: 2, sm: 7}}>
          {pages &&
            (isMobile ? (
              pages !== 1 && (
                <Stack direction="row" justifyContent="center" width="100%">
                  <LoadingButton
                    color="secondary"
                    loading={isLoading}
                    variant="text"
                    onClick={() => setLimit((prev) => prev + LIMIT.mobile)}
                  >
                    показать еще
                  </LoadingButton>
                </Stack>
              )
            ) : (
              <Pagination count={pages} justifyContent="unset" page={page} onChange={(_, page) => setPage(page)} />
            ))}
        </Box>
      )}
    </Wrapper>
  );
}

export default React.memo(GamesList);

function ItemsWrapper({hasWrapper, children, ...props}) {
  return hasWrapper ? (
    <Stack direction="row" {...props}>
      {children}
    </Stack>
  ) : (
    <>{children}</>
  );
}
