import {DIAGRAM_TYPES, GREEN, RED, YELLOW} from '@components/apps/StatisticsWithMkcUral/constants';
import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

const COLORS = {
  [DIAGRAM_TYPES.GG]: [GREEN, GREEN],
  [DIAGRAM_TYPES.GY]: [GREEN, YELLOW],
  [DIAGRAM_TYPES.RY]: [RED, YELLOW],
  [DIAGRAM_TYPES.RR]: [RED, RED],
  [DIAGRAM_TYPES.NP]: [grey['24'], grey['24']],
};

export const MiniDiagram = styled(Box)`
  width: ${({size}: {size?: number}) => size || 8}px;
  height: ${({size}: {size?: number}) => size || 8}px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  background: ${({type}) =>
    `linear-gradient(90deg, ${COLORS?.[type]?.[0] || GREEN} 50%, ${COLORS?.[type]?.[1] || GREEN} 50%)`};
  transition: 0.3s ease;
`;
