import useDeepEffect from '@hooks/useDeepEffect';
import {Box} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import React, {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

const PsychoTabs = (props) => {
  const {onChange} = props;

  const groups = useSelector((state) =>
    state.groups.groupsMap
      .toArray()
      .filter((group) => group.schoolId === 'ITfD4PL0oy')
      .sort((a, b) => a.birthYear - b.birthYear),
  );

  const formatedGroups = useMemo(() => {
    if (groups) {
      return groups?.map((group) => ({label: group.name, value: group.id}));
    }
    return [];
  }, [groups]);

  useDeepEffect(() => {
    if (formatedGroups[0]?.value) {
      onChange(formatedGroups[0]?.value);
    }
  }, [formatedGroups]);

  const handelChange = (newValue) => {
    onChange && onChange(formatedGroups[newValue]?.value);
  };

  return (
    groups?.length > 0 && (
      <Box sx={{'.TabsWrapper .MuiBox-root .MuiBox-root': {pb: '0px'}}}>
        <TabsNew
          sx={{
            '.MuiTabs-scroller': {minHeight: 40},
          }}
          tabs={formatedGroups?.map((group) => ({label: group?.label}))}
          onChange={handelChange}
        />
      </Box>
    )
  );
};

export default memo(PsychoTabs);
