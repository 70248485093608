export const black = {
  100: '#1A1A1A',
  78: '#4C4C4C',
  66: '#686868',
  55: '#818181',
  36: '#ACACAC',
  26: '#C4C4C4',
  14: '#DFDFDF',
  8: '#EDEDED',
  2: '#FAFAFA',
};
export const yellowV2 = {
  100: '#FFC633',
};
