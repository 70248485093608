import {VideoPlayer} from '@common/VideoPlayer';
import date from '@helpers/date';
import {Box, Chip, Grid, Skeleton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

VideoInfo.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

function VideoInfo({data, isLoading = true}) {
  const videoDescription = useMemo(
    () => [
      {
        label: 'Описание',
        value: data?.description,
      },
      {
        label: 'Команда',
        value: data.groups?.[0].name,
      },
      {
        label: 'Турнир',
        value: data?.tournament,
      },
      {
        label: 'Соперник',
        value: data?.opponent,
      },
      {
        label: 'Тренеры',
        value: data.teachers?.map(({name}) => name).join(', ') || '',
      },
      {
        label: 'Игроки',
        value: data.students?.map(({name}) => name).join(', ') || '',
      },
    ],
    [data],
  );
  return (
    <>
      {data?.link && (
        <>
          {data?.name && (
            <Typography {...{isLoading}} mb={1} preloaderProps={{width: '100%'}} variant="h2">
              {data.name}
            </Typography>
          )}
          {data.createdAt && (
            <Typography {...{isLoading}} preloaderProps={{width: 150}} variant="body1">
              {date(data.createdAt).format('DD.MM.YYYY')}
            </Typography>
          )}
        </>
      )}
      <Stack alignItems="flex-start" direction="column" my={3} spacing={2}>
        <Box width="100%">
          <Video>
            {data?.link ? (
              <Skeleton isLoading={isLoading} sx={{transform: 'none'}} width="100%">
                <VideoPlayer controls={true} style={{maxWidth: 750, maxHeight: '50vw'}} url={data.link} width="100%" />
              </Skeleton>
            ) : (
              <Stack alignItems="center" height={350} justifyContent="center" width="100%">
                <Typography variant="caption1">Видео недоступно</Typography>
              </Stack>
            )}
          </Video>
        </Box>
        <Box minWidth={{xxs: '100%', sm: '35%'}}>
          {data?.link && (
            <>
              {data?.tag?.name && (
                <Box mb={3}>
                  <Skeleton isLoading={isLoading} width="40%">
                    <Chip label={data.tag.name} variant="outlined" />
                  </Skeleton>
                </Box>
              )}
              <Grid container mb={2} spacing={3}>
                {videoDescription.map(({label, value}, index) =>
                  value ? (
                    <Grid
                      item
                      key={index}
                      sx={{width: {xxs: '100%', xs: 'auto'}}}
                      xs={index === 0 || index === 1 ? 12 : 6}
                    >
                      <Box>
                        <Typography color={grey['54']} variant="caption1">
                          {label}
                        </Typography>
                        <Typography
                          {...{isLoading}}
                          preloaderProps={{sx: {maxWidth: '100%'}}}
                          preloaderWidthBetween={[100, 1000]}
                          variant="body2"
                        >
                          {value}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null,
                )}
              </Grid>
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}

export default VideoInfo;

const Video = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;
