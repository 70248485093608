import {Typography} from '@mui/material';
import moment from 'moment';
import React from 'react';

export const columns = [
  {
    accessorKey: 'createdAt',
    header: 'Дата отчета',
    cell: ({getValue}) => {
      const value = getValue();
      return <Typography>{value ? moment(value).format('DD.MM.YYYY') : '-'}</Typography>;
    },
  },
  {
    accessorKey: 'advantages',
    header: 'Преимущества',
    cell: ({getValue}) => {
      const value = getValue();
      return <Typography>{value}</Typography>;
    },
  },
  {
    accessorKey: 'focus',
    header: 'Фокус развития',
    cell: ({getValue}) => {
      const value = getValue();
      return <Typography>{value}</Typography>;
    },
  },
];
