import ConditionSkeleton from '@components/modules/common/ConditionSkeleton';
import {useRandomNumberBetween} from '@hooks/useRandomNumberBetween';
import {Typography as TypographyMUI} from '@mui/material';
import {CustomTypographyProps} from '@src/types/uikit/typography';
import React, {ForwardedRef, forwardRef} from 'react';

import Skeleton from '../Skeleton';

const Typography = forwardRef(function Typography(
  {
    children,
    withoutWrapper,
    preloaderProps = {},
    preloaderWidthBetween = [0, 0],
    isLoading,
    useAvailableWidth = false,
    ...props
  }: CustomTypographyProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const memoRandomWidth = useRandomNumberBetween(preloaderWidthBetween);
  return (
    <ConditionSkeleton
      variant="text"
      {...preloaderProps}
      isLoading={isLoading}
      sx={{
        ...(preloaderProps?.sx || {}),
        width: useAvailableWidth ? 'fill-available' : preloaderWidthBetween?.length ? memoRandomWidth : void 0,
        maxWidth: preloaderWidthBetween?.length ? memoRandomWidth : void 0,
        backgroundColor: withoutWrapper ? 'rgba(255, 255, 255, .3)' : void 0,
      }}
    >
      <TypographyMUI ref={ref} {...props}>
        {isLoading ? <Skeleton {...preloaderProps} /> : children}
      </TypographyMUI>
    </ConditionSkeleton>
  );
});
export default Typography;
