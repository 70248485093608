import ChildrenField from '@common/ChildrenField';
import {useCreateProspectGame} from '@components/modules/prospects/queries/prospect-game.query';
import {useSeasons} from '@components/modules/season/queries/seasons.query';
import {useGroup} from '@hooks/filters';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Button, DatePicker, Stack, TextField} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import moment from 'moment';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {GameReport} from '../constants/games-report-table.constant';
import {reportDrawer} from '../models/drawer.model';

const formatToScore = (input: string) => {
  // Remove all non-numeric and non-colon characters
  let cleanedInput = input.replace(/[^0-9:]/g, '');

  // If cleaned input ends with a colon or is empty, return an empty string
  if (cleanedInput.endsWith(':') || cleanedInput.length === 0) {
    return '';
  }

  // Reduce any consecutive numbers to their first digit
  cleanedInput = cleanedInput.replace(/\d+/g, (match) => match[0]);
  // Split by colon and parse each part as integers
  const parts = cleanedInput
    .split(':')
    .map((part) => parseInt(part, 10))
    .filter(Number.isInteger);

  // If there are no valid parts, return an empty string
  if (parts.length === 0) {
    return '';
  }

  // Extract the first digit and the last digit from the input
  const firstDigit = parseInt(input[0], 10); // First digit from the original input
  const lastDigit = parseInt(input[input.length - 1], 10); // Last digit from the original input

  // Return the format first:last
  return `${firstDigit}:${lastDigit}`;
};

export const GamesReportDrawer = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const seasonsQuery = useSeasons();
  const groups = useGroup({});

  const seasons = seasonsQuery.data ?? [];
  const form = useForm<GameReport>({
    defaultValues: null,
  });
  const createMutation = useCreateProspectGame();

  const handleClose = () => {
    reportDrawer.close();
    form.reset();
  };

  const handleSave = async (data: GameReport) => {
    const formatted = {
      ...data,
      season: data?.season?.id,
      date: moment(data.date).format('YYYY-MM-DD'),
      groupId: data?.team?.id,
      userId: playerId,
    };

    await createMutation.mutateAsync(formatted);
    handleClose();
  };

  return (
    <FormProvider {...form}>
      <Drawer
        buttons={
          <Stack direction="row" justifyContent="space-between">
            <Button
              color="primary"
              disabled={!form.formState.isValid}
              variant="contained"
              onClick={form.handleSubmit(handleSave)}
            >
              Сохранить
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Отменить
            </Button>
          </Stack>
        }
        open={reportDrawer.isOpen}
        title={reportDrawer.isEdit ? 'Редактирование отчета' : 'новый отчет по игре, Анжанкин Николай'}
        onClose={handleClose}
      >
        <Stack mt={5} spacing={3}>
          <Stack alignItems="center" direction="row" spacing={4}>
            <ChildrenField isRequired name="season">
              <Autocomplete
                multiple={false}
                options={seasons}
                renderInput={(params) => <TextField {...params} label="Сезон" />}
                wrapperProps={{width: '140px'}}
              />
            </ChildrenField>
            <ChildrenField isRequired name="date">
              <DatePicker renderInput={(params) => <TextField {...params} label="Дата" sx={{maxWidth: 140}} />} />
            </ChildrenField>
          </Stack>
          <Stack direction="row" spacing={4}>
            <ChildrenField isRequired name="team">
              <Autocomplete
                multiple={false}
                options={groups.options}
                renderInput={(params) => <TextField {...params} label="Команда" />}
                wrapperProps={{width: '100%'}}
              />
            </ChildrenField>

            <ChildrenField isRequired name="opponent">
              <TextField label="Соперник" />
            </ChildrenField>
          </Stack>
          <ChildrenField
            isRequired
            formatter={(v) => {
              const formatted = formatToScore(v as string);
              console.log(v, formatted);
              return formatted;
            }}
            name="score"
          >
            <TextField label="Счет" sx={{maxWidth: 130}} />
          </ChildrenField>

          <ChildrenField name="comment">
            <TextField label="Комментарий" />
          </ChildrenField>
        </Stack>
      </Drawer>
    </FormProvider>
  );
};
