import ReactTableComponent from '@common/ReactTableComponent';
import {Box} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {HAND_MAP, POTENTIAL_ASSOCIATIONS, ROLE_MODEL_ASSOCIATIONS} from '../../../../../constants';
import {ShowButton} from '../../../../common/ScoutReport/CommonScoutReport';
import PlayersHorizontalCard from '../../../../students/tools/PlayersHorizontalCard';
import PlayersTableTooltip from '../PlayersTableTooltip';

const InnerPlayersTable = ({students}) => {
  const columns = [
    {
      id: 'player',
      accessorKey: 'player',
      header: 'Игрок',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => {
        const player = getValue();
        if (window.innerWidth <= 360) {
          player.firstName = player.firstName[0] + '.';
        }
        return (
          <Box alignItems="center" display="flex">
            <PlayersHorizontalCard student={player} />
          </Box>
        );
      },
      sortingFn: 'defaultSorting',
    },
    {
      accessorKey: 'birthDate',
      header: 'Дата рождения',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
    },
    {
      accessorKey: 'hand',
      header: 'Хват',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
    },
    {
      accessorKey: 'potential',
      header: 'Потенциал',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => <PlayersTableTooltip associations={POTENTIAL_ASSOCIATIONS} data={getValue()} />,
    },
    {
      accessorKey: 'roleModel',
      header: 'Ролевая модель',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => <PlayersTableTooltip associations={ROLE_MODEL_ASSOCIATIONS} data={getValue()} />,
    },
    {
      id: 'action',
      accessorKey: 'actions',
      header: 'Действиe',
      headerStyle: {
        textAlign: 'right',
        boxShadow: 'none !important',
      },
      cellStyle: {
        textAlign: 'right',
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => {
        return (
          <ShowButton>
            <Link className="BreakLinkStyle" to={`/trainer_report/inner/users/${getValue()}`}>
              Смотреть
            </Link>
          </ShowButton>
        );
      },
    },
  ];

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);
  return (
    <ReactTableComponent
      columns={columns}
      data={students?.map((student, index) => {
        return {
          id: `${student.id}.id ${index}`,
          player: student,
          birthDate: moment(student.birthday).format('DD.MM.YYYY'),
          hand: HAND_MAP[student.hand],
          potential: student.potential,
          roleModel: student.roleModel,
          actions: student.id,
        };
      })}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
};

InnerPlayersTable.propTypes = {
  role: PropTypes.string,
  group: PropTypes.array,
};

export default InnerPlayersTable;
