import {shallowEqual} from '@helpers/shallowEqual';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import {selectMenuItems} from '@selectors/menu';
import {MENU_CATEGORIES_LABELS} from '@src/constants/menu';
import {MENU_CATEGORIES_ENUM} from '@src/types/menu';
import {Box} from '@ui/MUI';
import {values} from 'lodash';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import UserPanel from '../UserPanel';
import {OptionItemTitle, OptionsListContainer, OptionsListItem, Wrapper} from './MenuContent';

const menuCategories = values(MENU_CATEGORIES_ENUM);

interface MenuContentProps {
  isMobile?: boolean;
  active: string;
}
function MenuContent({active, isMobile}: MenuContentProps) {
  const {currentUser, isLoading} = useStore((state) => {
    const currentUser = state?.users?.currentUser;
    const isLoading = state?.users?.loading;
    return {currentUser, isLoading};
  }, shallowEqual);
  const menuItems = useStore(selectMenuItems);
  const {pathname} = useLocation();
  const [ref, {availableHeight}] = useElementSize();
  return (
    <Wrapper height={availableHeight} ref={ref}>
      <Box sx={{overflowY: 'auto', height: '100%'}}>
        <OptionsListContainer>
          {currentUser?.userRole &&
            menuCategories?.map((category, index) => {
              const links = menuItems[category];
              const categotyName = MENU_CATEGORIES_LABELS[category];

              if (!links?.length) return null;

              return (
                <React.Fragment key={categotyName + index}>
                  {categotyName && <OptionItemTitle>{categotyName}</OptionItemTitle>}
                  {links.map((link, index) => {
                    const {label, url, path, name, Icon} = link;
                    const isSelected = active === name || pathname === url;

                    return (
                      <OptionsListItem
                        component={Link}
                        key={label + index}
                        selected={isSelected}
                        startIcon={<Icon /> ?? null}
                        to={path || url}
                      >
                        {label}
                      </OptionsListItem>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </OptionsListContainer>
      </Box>

      <UserPanel isLoading={isLoading} isMobile={isMobile} user={currentUser} />
    </Wrapper>
  );
}

export default MenuContent;
