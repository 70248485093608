import styled from 'styled-components';

export const Form = styled.form`
  padding-bottom: 100px;
`;
export const FormContent = styled.div`
  padding: 16px 0px 10px 0px;
`;
export const FormContentItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px;
  box-sizing: border-box;

  .CustomFilter {
    padding: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0;
    flex: 0 0 100%;
    max-width: 100%;

    &:not(:last-child) {
      padding-right: 14px;
    }

    label {
      display: block;
      font: normal normal 14px/16px 'Proxima Nova';
      color: #747474;
      text-transform: none;
    }
  }

  .filters-container {
    .filter-container {
      margin-top: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    & > .CustomFilter {
      flex: 0 0 50%;
      max-width: 50%;

      @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
`;
export const FormContentItem = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tox-tinymce {
    border-color: #f3f3f3 !important;
  }
`;
export const FormContentItemFull = styled(FormContentItem)`
  flex: 0 0 100%;
  max-width: 100%;
`;
export const FormContentItemShort = styled(FormContentItem)`
  flex: 0 0 30%;
  max-width: 30%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const FormContentItemLong = styled(FormContentItem)`
  flex: 0 0 60%;
  max-width: 60%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const FormContentItemHalf = styled(FormContentItem)`
  flex: 0 0 50%;
  max-width: 50%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const InfoText = styled.p`
  font: normal 600 14px/16px 'Proxima Nova';
  color: #2c2c2c;
`;
export const InfoBlock = styled.div`
  margin-top: 25px;
  margin-right: 50px;
  min-width: 150px;
  :last-child {
    margin-right: 0;
  }
`;
export const InfoWrapper = styled.div`
  padding-bottom: 60px;
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
  > input {
    margin-top: 4px;
    margin-bottom: 0;
    border-color: #f3f3f3;
    font: normal normal 15px/25px 'Proxima Nova';
    color: #2c2c2c;
    &::placeholder {
      font: normal normal 15px/25px 'Proxima Nova';
      color: #c4c4c4;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const BottomPanel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
`;
export const Buttons = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
