import {Player} from '@components/apps/A1TagAddingRosters';
import {a1tagViewModel, updateRoasters} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {useSignals} from '@preact/signals-react/runtime';

import {A1TableColumnDef} from '../a1Table.types';

export const useCancelSubstitution = () => {
  useSignals();

  const cancelSubstitution = (row: A1TableColumnDef) => {
    const rosters = a1tagViewModel.rosters.value;
    const homeTeamPlayers = [...rosters.homeTeam.players.fieldPlayers, ...rosters.homeTeam.players.goalies];
    const awayTeamPlayers = [...rosters.awayTeam.players.fieldPlayers, ...rosters.awayTeam.players.goalies];
    const isTeamHome = !!homeTeamPlayers?.find((player) => player?.avangardId === row?.player_id);
    const firstEl =
      homeTeamPlayers?.find((player) => player?.avangardId === row?.player_id) ||
      awayTeamPlayers?.find((player) => player?.avangardId === row?.player_id);
    const secondEl =
      homeTeamPlayers?.find((player) => player?.avangardId === row?.opponent_id) ||
      awayTeamPlayers?.find((player) => player?.avangardId === row?.opponent_id);
    const updatedFirstEl = {...firstEl, onIce: secondEl?.onIce};
    const updatedSecondEl = {...secondEl, onIce: firstEl?.onIce};
    const updatePlayers = (players: Player[]) => {
      return players?.map((player) => {
        if (player.avangardId === firstEl?.avangardId) {
          return updatedFirstEl;
        }
        if (player.avangardId === secondEl?.avangardId) {
          return updatedSecondEl;
        }
        return player;
      });
    };
    const updatedRosters = isTeamHome
      ? {
          ...rosters,
          homeTeam: {
            ...rosters?.homeTeam,
            players: {
              ...rosters?.homeTeam?.players,
              fieldPlayers: updatePlayers(rosters?.homeTeam?.players?.fieldPlayers),
              goalies: updatePlayers(rosters?.homeTeam?.players?.goalies),
            },
          },
        }
      : {
          ...rosters,
          awayTeam: {
            ...rosters?.awayTeam,
            players: {
              ...rosters?.awayTeam?.players,
              fieldPlayers: updatePlayers(rosters?.awayTeam?.players?.fieldPlayers),
              goalies: updatePlayers(rosters?.awayTeam?.players?.goalies),
            },
          },
        };
    updateRoasters(updatedRosters);
  };

  return {cancelSubstitution};
};
