import {A1TagLayout} from '@components/modules/A1TagLayout';
import Layout from '@components/modules/Layout';
import ChessTemplate from '@components/templates/ChessTemplate';
import React from 'react';

interface ThemeLayoutProps {
  isNew?: boolean;
  isA1Tag?: boolean;
  active: string;
  children: React.ReactNode;
}
function ThemeLayout({isNew, isA1Tag, active, children}: ThemeLayoutProps) {
  return isNew ? (
    <Layout active={active}>{children}</Layout>
  ) : isA1Tag ? (
    <A1TagLayout>{children}</A1TagLayout>
  ) : (
    <ChessTemplate active={active}>{children}</ChessTemplate>
  );
}

export default ThemeLayout;
