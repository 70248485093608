import {Player} from '@components/apps/A1TagAddingRosters';
import {Team} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  a1tagViewModel,
  appendStatistic,
  updateIsTimerStart,
  updateRoasters,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {
  getFormattedStatistics,
  openModal,
  updatePayload,
} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, IconButton, Tooltip, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import {chunk} from 'lodash';
import React, {useEffect, useState} from 'react';

import {Line, PlayerPosition} from './A1tagPlayersLinesBlock';

const UnionSVG = () => {
  return (
    <svg fill="none" height="114" viewBox="0 0 98 114" width="98" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M92 0H93V0.0829585C95.8377 0.559036 98 3.027 98 6V108C98 110.973 95.8377 113.441 93 113.917V114H92H0V113H92V1H0V0H92Z"
        fill="#4D4D4D"
        fillRule="evenodd"
      />
    </svg>
  );
};
const UnionSVGThird = () => {
  return (
    <svg fill="none" height="96" viewBox="0 0 36 96" width="36" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6 0C2.68629 0 0 2.68629 0 6V90C0 93.3137 2.68629 96 6 96H36V95H6V1H36V0H6Z"
        fill="#4D4D4D"
        fillRule="evenodd"
      />
    </svg>
  );
};

const UnionSVGSecond = () => {
  return (
    <svg fill="none" height="66" viewBox="0 0 36 66" width="36" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H36V1H6V65H36V66H6C2.68629 66 0 63.3137 0 60V6Z"
        fill="#4D4D4D"
        fillRule="evenodd"
      />
    </svg>
  );
};

type A1tagPlayersLinesBlockProps = {isTeamHome: boolean; team: Team};

export const A1tagPlayersLinesBlock = ({isTeamHome, team}: A1tagPlayersLinesBlockProps) => {
  useSignals();

  const playersPositions = ['LW', 'C', 'RW', 'LD', 'RD'];

  const [swapElements, setSwapElements] = useState<Player[]>([]);

  const payload = modalViewModel?.payload?.value ?? null;

  const hasOpponent = payload?.eventName === 'Face-off' || payload?.eventName === 'Shot';

  const isEventForOnePlayer =
    payload?.eventName === 'Face-off' ||
    payload?.eventName === 'Give away' ||
    payload?.eventName === 'Shot' ||
    payload?.eventName === 'Shootout' ||
    payload?.eventName === 'Breakout' ||
    payload?.eventName === 'Entry' ||
    payload?.eventName === 'Posession take' ||
    payload?.eventName === 'Penalty';

  const isEventForTwoPlayers =
    payload?.eventName === 'Hit' ||
    payload?.eventName === 'Take away' ||
    payload?.eventName === 'Deking' ||
    payload?.eventName === 'Puck battle' ||
    payload?.eventName === 'Key pass';

  useEffect(() => {
    if (swapElements?.length === 2) {
      const firstEl = swapElements?.[0];
      const secondEl = swapElements?.[1];
      const updatedFirstEl = {...firstEl, onIce: secondEl?.onIce};
      const updatedSecondEl = {...secondEl, onIce: firstEl?.onIce};
      const rosters = a1tagViewModel.rosters.value;
      const updatePlayers = (players: Player[]) => {
        return players?.map((player) => {
          if (player.avangardId === firstEl?.avangardId) {
            return updatedFirstEl;
          }
          if (player.avangardId === secondEl?.avangardId) {
            return updatedSecondEl;
          }
          return player;
        });
      };
      const updatedRosters = isTeamHome
        ? {
            ...rosters,
            homeTeam: {
              ...rosters?.homeTeam,
              players: {
                ...rosters?.homeTeam?.players,
                fieldPlayers: updatePlayers(rosters?.homeTeam?.players?.fieldPlayers),
                goalies: updatePlayers(rosters?.homeTeam?.players?.goalies),
              },
            },
          }
        : {
            ...rosters,
            awayTeam: {
              ...rosters?.awayTeam,
              players: {
                ...rosters?.awayTeam?.players,
                fieldPlayers: updatePlayers(rosters?.awayTeam?.players?.fieldPlayers),
                goalies: updatePlayers(rosters?.awayTeam?.players?.goalies),
              },
            },
          };
      const statistics = getFormattedStatistics();
      const player = swapElements?.find((el: Player & {onIce: boolean}) => el?.onIce);
      const opponent = swapElements?.find((el: Player & {onIce: boolean}) => !el?.onIce);
      const formattedStatistics = {
        ...statistics,
        id: Math.floor(Math.random() * Date.now()).toString(12),
        action_id: 11130,
        action_name: `${player?.position} substitution`,
        player_name: `${player?.firstName} ${player?.lastName}`,
        player_id: player?.avangardId,
        team_name: player?.group?.name,
        jersey_player: player?.playerNumber,
        opponent_name: `${opponent?.firstName} ${opponent?.lastName}`,
        opponent_id: opponent?.avangardId,
        opponent_team_name: opponent?.group?.name,
        jersey_opponent: opponent?.playerNumber,
      };
      if (!a1tagViewModel?.isGodMode?.value) {
        appendStatistic(formattedStatistics);
      }
      updateRoasters(updatedRosters);
    }
  }, [swapElements?.[0], swapElements?.[1]]);

  useEffect(() => {
    if (isEventForTwoPlayers && payload?.player && payload?.opponent && !modalViewModel.isEdit.value) {
      updateIsTimerStart(false);
      openModal({eventName: payload?.eventName, ...payload});
    }
  }, [payload?.player, payload?.opponent, modalViewModel?.isEdit?.value, isEventForTwoPlayers]);

  const getFivesWithPositions = (players: Player[]) =>
    chunk(players, 5)?.map((five) => {
      return five?.map((player, index) => {
        return {...player, position: playersPositions?.[index]};
      });
    });

  const goalies = team?.players?.goalies?.map((player) => ({...player, position: 'G'}));

  const {homeTeam, awayTeam} = a1tagViewModel?.rosters?.value ?? {};

  const selectPlayerOpponent = (player: Player) => {
    const playersFromOpponentTeam = isTeamHome
      ? getFivesWithPositions(awayTeam?.players.fieldPlayers)?.flat()
      : getFivesWithPositions(homeTeam?.players.fieldPlayers)?.flat();
    const opponentsOnIce = playersFromOpponentTeam?.filter((player) => player?.onIce);
    const selectedPlayerOpponent = hasOpponent
      ? opponentsOnIce?.find((onIcePlayer) => onIcePlayer.position === player.position)
      : null;
    return selectedPlayerOpponent
      ? {
          ...selectedPlayerOpponent,
          value: selectedPlayerOpponent?.avangardId,
          label: `${selectedPlayerOpponent?.playerNumber}.${selectedPlayerOpponent?.lastName} ${selectedPlayerOpponent?.firstName}`,
        }
      : null;
  };

  const isSelectedPlayerFromHomeTeam =
    homeTeam?.players?.fieldPlayers?.map((item) => item?.avangardId)?.includes(payload?.player?.avangardId) ||
    homeTeam?.players?.goalies?.map((item) => item?.avangardId)?.includes(payload?.player?.avangardId);

  const setIsPlayerPositionDisabledForKeyPass = (player: Player) => {
    return (
      (isSelectedPlayerFromHomeTeam ? !isTeamHome : isTeamHome) ||
      !player?.onIce ||
      player?.avangardId === payload?.player?.avangardId
    );
  };
  const setIsPlayerPositionDisabledDefault = (player: Player) => {
    return (isSelectedPlayerFromHomeTeam ? isTeamHome : !isTeamHome) || !player?.onIce;
  };
  const setIsPlayerPositionDisabled = (player: Player) => {
    if (swapElements?.[0] && !swapElements?.[0]?.onIce) {
      return !player?.onIce;
    }
    if (isEventForTwoPlayers) {
      return (
        (payload?.eventName === 'Key pass'
          ? setIsPlayerPositionDisabledForKeyPass(player)
          : setIsPlayerPositionDisabledDefault(player)) && payload?.player
      );
    }
    if (
      swapElementsForChangeLinesPlayerMode?.length &&
      (swapElementsForChangeLinesPlayerMode?.[0]?.position !== 'G' ||
        swapElementsForChangeLinesPlayerMode?.[1]?.position !== 'G')
    ) {
      return player.position === 'G';
    }
  };

  useEffect(() => {
    const handleEsc = (event: {key: string}) => {
      if (event.key === 'Escape') {
        setSwapElements([]);
        setSwapElementsForChangeLinesPlayerMode([]);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const [isChangeLinesPlayersMode, setIsChangeLinesPlayersMode] = useState(false);
  const [swapElementsForChangeLinesPlayerMode, setSwapElementsForChangeLinesPlayerMode] = useState<Player[]>([]);

  const changePlayersAtChangeLinesPlayersMode = (player: Player) => {
    setSwapElementsForChangeLinesPlayerMode([player]);
    if (swapElementsForChangeLinesPlayerMode.length === 1) {
      setSwapElementsForChangeLinesPlayerMode([...swapElementsForChangeLinesPlayerMode, player]);
    }
    if (swapElementsForChangeLinesPlayerMode.length === 2) {
      setSwapElementsForChangeLinesPlayerMode([player]);
    }
  };

  useEffect(() => {
    if (swapElementsForChangeLinesPlayerMode?.length === 2) {
      const firstEl = swapElementsForChangeLinesPlayerMode?.[0];
      const secondEl = swapElementsForChangeLinesPlayerMode?.[1];
      const rosters = a1tagViewModel.rosters.value;

      const setElPosition = (el: Player) => {
        const isGoaltender = el?.position === 'G';
        const teamHomeFindIndex = isGoaltender
          ? rosters?.homeTeam?.players?.goalies?.findIndex((player) => player?.avangardId === el?.avangardId)
          : rosters?.homeTeam?.players?.fieldPlayers?.findIndex((player) => player?.avangardId === el?.avangardId);
        const teamAwayFindIndex = isGoaltender
          ? rosters?.awayTeam?.players?.goalies?.findIndex((player) => player?.avangardId === el?.avangardId)
          : rosters?.awayTeam?.players?.fieldPlayers?.findIndex((player) => player?.avangardId === el?.avangardId);
        const elIndex = isTeamHome ? teamHomeFindIndex : teamAwayFindIndex;
        return {elIndex: elIndex, isGoaltender};
      };

      const firstElPosition = setElPosition(firstEl);
      const secondElPosition = setElPosition(secondEl);

      const updatePlayersLines = (players: Player[]) => {
        const formattedPlayers = players;
        [formattedPlayers[firstElPosition.elIndex], formattedPlayers[secondElPosition.elIndex]] = [
          formattedPlayers[secondElPosition.elIndex],
          formattedPlayers[firstElPosition.elIndex],
        ];
        return formattedPlayers;
      };

      const isNotGoalies = !firstElPosition?.isGoaltender && !secondElPosition?.isGoaltender;

      const updatedRosters = isTeamHome
        ? {
            ...rosters,
            homeTeam: {
              ...rosters?.homeTeam,
              players: {
                ...rosters?.homeTeam?.players,
                fieldPlayers: updatePlayersLines(rosters?.homeTeam?.players?.fieldPlayers),
                goalies: isNotGoalies
                  ? rosters?.homeTeam?.players?.goalies
                  : updatePlayersLines(rosters?.homeTeam?.players?.goalies),
              },
            },
          }
        : {
            ...rosters,
            awayTeam: {
              ...rosters?.awayTeam,
              players: {
                ...rosters?.awayTeam?.players,
                fieldPlayers: updatePlayersLines(rosters?.awayTeam?.players?.fieldPlayers),
                goalies: isNotGoalies
                  ? rosters?.awayTeam?.players?.goalies
                  : updatePlayersLines(rosters?.awayTeam?.players?.goalies),
              },
            },
          };
      updateRoasters(updatedRosters);
    }
  }, [swapElementsForChangeLinesPlayerMode?.[0], swapElementsForChangeLinesPlayerMode?.[1]]);

  return (
    <Box
      sx={{
        position: 'relative',
        py: 2,
        px: 3,
        backgroundColor: black[10],
        borderRight: `1px solid ${black[20]}`,
        borderLeft: `1px solid ${black[20]}`,
        width: '100%',
        maxWidth: 150,
      }}
    >
      <Tooltip
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [1, -14],
                },
              },
            ],
            sx: {maxWidth: 140},
          },
        }}
        title={(!isChangeLinesPlayersMode ? 'Включить' : 'Выключить') + ' режим изменения состава пятерок'}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: isTeamHome ? '-2px' : 'auto',
            left: isTeamHome ? 'auto' : '0px',
            display: 'block',
            width: '32px',
            zIndex: 3,
          }}
        >
          <IconButton
            onClick={() => {
              setIsChangeLinesPlayersMode(!isChangeLinesPlayersMode);
            }}
          >
            <ChangeCircleOutlinedIcon />
          </IconButton>
        </Box>
      </Tooltip>
      {getFivesWithPositions(team?.players?.fieldPlayers)?.map((five, index) => {
        return (
          <Line key={index}>
            <Box
              sx={{
                position: 'relative',
                minHeight: 114,
                '& > svg': {
                  position: 'absolute',
                  right: isTeamHome ? 12 : 2,
                  bottom: 0,
                  transform: !isTeamHome ? 'rotate(180deg)' : 'none',
                },
              }}
            >
              <UnionSVG />
              <Box
                sx={{
                  position: 'relative',
                  minHeight: 114,
                  '& > svg': {
                    position: 'absolute',
                    right: isTeamHome ? 26 : 'auto',
                    left: isTeamHome ? 'auto' : 30,
                    bottom: 8,
                    transform: !isTeamHome ? 'rotate(180deg)' : 'none',
                  },
                }}
              >
                <UnionSVGThird />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: isTeamHome ? 14 : 'auto',
                    left: isTeamHome ? 'auto' : 30,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    gap: 0,
                    pr: 2,
                  }}
                >
                  {chunk(five, 3)?.[0]?.map((player) => {
                    return (
                      <PlayerPosition
                        awayColor={awayTeam?.color}
                        className={player?.onIce && !isChangeLinesPlayersMode && 'active'}
                        disabled={setIsPlayerPositionDisabled(player)}
                        homeColor={homeTeam?.color}
                        isSwapped={
                          (swapElements?.length < 2 &&
                            swapElements?.map((el) => el.avangardId)?.includes(player.avangardId)) ||
                          (swapElementsForChangeLinesPlayerMode?.length < 2 &&
                            swapElementsForChangeLinesPlayerMode
                              ?.map((el) => el.avangardId)
                              ?.includes(player.avangardId))
                        }
                        isTeamHome={isTeamHome}
                        key={player?.avangardId}
                        onClick={() => {
                          if (!isChangeLinesPlayersMode) {
                            if (!payload?.eventName) {
                              setSwapElements([player]);
                              if (swapElements.length === 1) {
                                setSwapElements([...swapElements, player]);
                              }
                              if (swapElements.length === 2) {
                                setSwapElements([player]);
                              }
                            } else {
                              setSwapElements([]);
                              if (isEventForOnePlayer) {
                                updatePayload({
                                  ...payload,
                                  player: {
                                    ...player,
                                    value: player.avangardId,
                                    label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                  },
                                  opponent: !selectPlayerOpponent(player) ? null : selectPlayerOpponent(player),
                                });
                                updateIsTimerStart(false);
                                openModal({
                                  eventName: payload?.eventName,
                                  player: {
                                    ...player,
                                    value: player.avangardId,
                                    label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                  },
                                  opponent: !selectPlayerOpponent(player) ? null : selectPlayerOpponent(player),
                                  ...payload,
                                });
                              }
                              if (isEventForTwoPlayers) {
                                if (!payload?.player) {
                                  updatePayload({
                                    ...payload,
                                    player: {
                                      ...player,
                                      value: player.avangardId,
                                      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                    },
                                  });
                                } else {
                                  updatePayload({
                                    ...payload,
                                    opponent: {
                                      ...player,
                                      value: player.avangardId,
                                      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                    },
                                  });
                                }
                              }
                            }
                          }
                          if (isChangeLinesPlayersMode) {
                            changePlayersAtChangeLinesPlayersMode(player);
                          }
                        }}
                      >
                        <Typography display="block" variant="overline2">
                          {player?.playerNumber}
                        </Typography>
                      </PlayerPosition>
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  '& > svg': {
                    position: 'absolute',
                    left: isTeamHome ? 12 : 'auto',
                    right: isTeamHome ? 'auto' : 8,
                    bottom: 24,
                    transform: !isTeamHome ? 'rotate(180deg)' : 'none',
                  },
                }}
              >
                <UnionSVGSecond />
                <Box
                  sx={{
                    position: 'absolute',
                    transform: 'translateY(-50%)',
                    left: isTeamHome ? 22 : 'auto',
                    right: isTeamHome ? 'auto' : 8,
                    bottom: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    gap: 0,
                    pr: isTeamHome ? 2 : 1,
                  }}
                >
                  {chunk(five, 3)?.[1]?.map((player) => {
                    return (
                      <PlayerPosition
                        awayColor={awayTeam?.color}
                        className={player?.onIce && !isChangeLinesPlayersMode && 'active'}
                        disabled={setIsPlayerPositionDisabled(player)}
                        homeColor={homeTeam?.color}
                        isSwapped={
                          swapElements?.length < 2 &&
                          swapElements?.map((el) => el.avangardId)?.includes(player.avangardId)
                        }
                        isTeamHome={isTeamHome}
                        key={player?.avangardId}
                        onClick={() => {
                          if (!isChangeLinesPlayersMode) {
                            if (!payload?.eventName) {
                              setSwapElements([player]);
                              if (swapElements.length === 1) {
                                setSwapElements([...swapElements, player]);
                              }
                              if (swapElements.length === 2) {
                                setSwapElements([player]);
                              }
                            } else {
                              setSwapElements([]);
                              if (isEventForOnePlayer) {
                                updatePayload({
                                  ...payload,
                                  player: {
                                    ...player,
                                    value: player.avangardId,
                                    label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                  },
                                  opponent: !selectPlayerOpponent(player) ? null : selectPlayerOpponent(player),
                                });
                                updateIsTimerStart(false);
                                openModal({
                                  eventName: payload?.eventName,
                                  player: {
                                    ...player,
                                    value: player.avangardId,
                                    label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                  },
                                  opponent: !selectPlayerOpponent(player) ? null : selectPlayerOpponent(player),
                                  ...payload,
                                });
                              }
                              if (isEventForTwoPlayers) {
                                if (!payload?.player) {
                                  updatePayload({
                                    ...payload,
                                    player: {
                                      ...player,
                                      value: player.avangardId,
                                      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                    },
                                  });
                                } else {
                                  updatePayload({
                                    ...payload,
                                    opponent: {
                                      ...player,
                                      value: player.avangardId,
                                      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
                                    },
                                  });
                                }
                              }
                            }
                          }
                          if (isChangeLinesPlayersMode) {
                            changePlayersAtChangeLinesPlayersMode(player);
                          }
                        }}
                      >
                        <Typography display="block" variant="overline2">
                          {player?.playerNumber}
                        </Typography>
                      </PlayerPosition>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Line>
        );
      })}
      <Line>
        <Typography display="block" textAlign="center" textTransform="uppercase" variant="overline2" width="100%">
          Goalies
        </Typography>
        <Box display="flex" gap={0} justifyContent="center" mt={2}>
          {goalies?.map((player) => {
            return (
              <PlayerPosition
                awayColor={awayTeam?.color}
                className={player?.onIce && !isChangeLinesPlayersMode && 'active'}
                disabled={setIsPlayerPositionDisabled(player)}
                homeColor={homeTeam?.color}
                isSwapped={
                  swapElements?.length < 2 && swapElements?.map((el) => el.avangardId)?.includes(player.avangardId)
                }
                isTeamHome={isTeamHome}
                key={player?.playerNumber}
                onClick={() => {
                  if (!isChangeLinesPlayersMode) {
                    setSwapElements([player]);
                    if (swapElements.length === 1) {
                      setSwapElements([...swapElements, player]);
                    }
                    if (swapElements.length === 2) {
                      setSwapElements([player]);
                    }
                  }
                  if (isChangeLinesPlayersMode) {
                    changePlayersAtChangeLinesPlayersMode(player);
                  }
                }}
              >
                <Typography display="block" variant="overline2">
                  {player?.playerNumber}
                </Typography>
              </PlayerPosition>
            );
          })}
        </Box>
      </Line>
    </Box>
  );
};
