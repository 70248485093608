import {HockeyRoles} from '@src/types/student';

import {Player} from '../A1TagAddingRosters';
import {a1tagViewModel, updateChooseTeamPayload} from '../A1TagMainWindow/viewmodels/a1tag.view-model';
import {updatePayload} from '../A1TagMainWindow/viewmodels/helpers/modal-view-model';

export const addPlayer = ({
  isMainWindow,
  isHomeTeam,
  payload,
  player,
}: {
  isMainWindow: boolean;
  isHomeTeam: boolean;
  payload: any;
  player: Player;
}) => {
  if (!isMainWindow) {
    const updatedPayload = isHomeTeam
      ? {...payload, homeTeam: {...payload.homeTeam, players: [...payload.homeTeam.players, player]}}
      : {...payload, awayTeam: {...payload.awayTeam, players: [...payload.awayTeam.players, player]}};
    updateChooseTeamPayload(updatedPayload);
  } else {
    const isGoaltender = player?.hockeyRole === HockeyRoles.goaltender;
    const updatedPayload = isHomeTeam
      ? {
          ...payload,
          rosters: {
            ...payload.rosters,
            homeTeam: {
              ...payload.rosters.homeTeam,
              players: {
                ...payload.rosters.homeTeam.players,
                fieldPlayers: !isGoaltender
                  ? [...payload.rosters.homeTeam.players.fieldPlayers, player]
                  : payload.rosters.homeTeam.players.fieldPlayers,
                goalies: isGoaltender
                  ? [...payload.rosters.homeTeam.players.goalies, player]
                  : payload.rosters.homeTeam.players.goalies,
              },
            },
          },
        }
      : {
          ...payload,
          rosters: {
            ...payload.rosters,
            awayTeam: {
              ...payload.rosters.awayTeam,
              players: {
                ...payload.rosters.awayTeam.players,
                fieldPlayers: !isGoaltender
                  ? [...payload.rosters.awayTeam.players.fieldPlayers, player]
                  : payload.rosters.awayTeam.players.fieldPlayers,
                goalies: isGoaltender
                  ? [...payload.rosters.awayTeam.players.goalies, player]
                  : payload.rosters.awayTeam.players.goalies,
              },
            },
          },
        };
    updatePayload(updatedPayload);
  }
};
export const deletePlayerByAvangardId = ({
  isMainWindow,
  isHomeTeam,
  payload,
  avangardId,
}: {
  isMainWindow: boolean;
  isHomeTeam: boolean;
  payload: any;
  avangardId: string;
}) => {
  if (!isMainWindow) {
    const updatedPayload = isHomeTeam
      ? {
          ...payload,
          homeTeam: {
            ...payload.homeTeam,
            players: payload.homeTeam.players.filter((player: Player) => player?.avangardId !== avangardId),
          },
        }
      : {
          ...payload,
          awayTeam: {
            ...payload.awayTeam,
            players: payload.awayTeam.players.filter((player: Player) => player?.avangardId !== avangardId),
          },
        };
    updateChooseTeamPayload(updatedPayload);
  } else {
    const isGoaltender = isHomeTeam
      ? a1tagViewModel.rosters.value.homeTeam.players.goalies.map((player) => player.avangardId).includes(avangardId)
      : a1tagViewModel.rosters.value.awayTeam.players.goalies.map((player) => player.avangardId).includes(avangardId);
    const updatedPayload = isHomeTeam
      ? {
          ...a1tagViewModel.rosters.value,
          homeTeam: {
            ...a1tagViewModel.rosters.value.homeTeam,
            players: {
              ...a1tagViewModel.rosters.value.homeTeam.players,
              fieldPlayers: !isGoaltender
                ? a1tagViewModel.rosters.value.homeTeam.players.fieldPlayers.filter(
                    (player) => player.avangardId !== avangardId,
                  )
                : a1tagViewModel.rosters.value.homeTeam.players.fieldPlayers,
              goalies: isGoaltender
                ? a1tagViewModel.rosters.value.homeTeam.players.goalies.filter(
                    (player) => player.avangardId !== avangardId,
                  )
                : a1tagViewModel.rosters.value.homeTeam.players.goalies,
            },
          },
        }
      : {
          ...a1tagViewModel.rosters.value,
          awayTeam: {
            ...a1tagViewModel.rosters.value.awayTeam,
            players: {
              ...a1tagViewModel.rosters.value.awayTeam.players,
              fieldPlayers: !isGoaltender
                ? a1tagViewModel.rosters.value.awayTeam.players.fieldPlayers.filter(
                    (player) => player.avangardId !== avangardId,
                  )
                : a1tagViewModel.rosters.value.awayTeam.players.fieldPlayers,
              goalies: isGoaltender
                ? a1tagViewModel.rosters.value.awayTeam.players.goalies.filter(
                    (player) => player.avangardId !== avangardId,
                  )
                : a1tagViewModel.rosters.value.awayTeam.players.goalies,
            },
          },
        };
    updatePayload({...payload, rosters: updatedPayload});
  }
};
