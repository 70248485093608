export interface Goal {
  goal: string;
  expectedResult: string;
  startDate: string;
  endDate: string;
  finalResult?: string;
  finalResultDate?: string;
}

export const goals: Goal[] = [
  {
    goal: 'Тщательней следить за исполнением техники катания',
    expectedResult: 'Повысить эффективность выполнения техники катания',
    startDate: '2024-09-20',
    endDate: '2024-10-20',
    finalResult: 'Показатель 54',
    finalResultDate: '2024-10-20',
  },
  {
    goal: 'Тщательней следить за исполнением техники катания',
    expectedResult: 'Повысить эффективность выполнения техники катания',
    startDate: '2024-09-20',
    endDate: '2024-10-20',
    finalResult: 'Показатель 54',
    finalResultDate: '2024-10-20',
  },
  {
    goal: 'Тщательней следить за исполнением техники катания',
    expectedResult: 'Повысить эффективность выполнения техники катания',
    startDate: '2024-09-20',
    endDate: '2024-10-20',
    finalResult: 'Показатель 54',
    finalResultDate: '2024-10-20',
  },
];
