import NoData from '@components/modules/common/NoData';
import {Link, Stack, Typography} from '@ui/MUI';
import React, {useMemo} from 'react';

import {maxItemsLimit} from '../../constants';
import {useCurrentBreakPoint} from '../../hooks';
import {FileCard, ImageCard, VideoCard} from '../Card';

const ListTitle = ({name, type, count}) => {
  const breakPoint = useCurrentBreakPoint();
  const shouldShowLink = count > maxItemsLimit[type][breakPoint];

  return (
    <Stack direction="row" justifyContent="space-between" sx={{alignItems: 'center'}}>
      <Typography variant="h3">{name}</Typography>
      {shouldShowLink && <Link to={`/materials/section/${type}`}>Показать все</Link>}
    </Stack>
  );
};

const ImageList = ({materials}) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap="40px 16px">
      {materials.map((material) => (
        <ImageCard key={material.id} {...material} />
      ))}
    </Stack>
  );
};

const FileList = ({materials}) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap="24px 16px">
      {materials.map((material) => (
        <FileCard key={material.id} {...material} />
      ))}
    </Stack>
  );
};

const VideoList = ({materials}) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap="32px 16px">
      {materials.map((material) => (
        <VideoCard key={material.id} {...material} />
      ))}
    </Stack>
  );
};

const sectionListComponent = {
  links_and_files: FileList,
  images: ImageList,
  video: VideoList,
};

const List = ({type, materials, name, count, shouldShowTitle = true}) => {
  const ListComponentByType = useMemo(() => sectionListComponent[type], [type]);
  return (
    <Stack spacing={4}>
      {shouldShowTitle && <ListTitle count={count} name={name} type={type} />}
      {count === 0 ? <NoData wrapperProps={{flex: 1}} /> : <ListComponentByType key={type} materials={materials} />}
    </Stack>
  );
};

export default List;
