import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {TableCellFlexContainer} from '@components/apps/FunctionalTesting/components/Report/ReportTable/ReportTable';
import {actions} from '@components/apps/FunctionalTesting/constants';
import {
  formatStatesOptions,
  hideEmptyPlayersInFunctionalStateDynamic,
} from '@components/apps/FunctionalTesting/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import date from '@helpers/date';
import {useSeasons} from '@hooks/seasonsHooks';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {FormControlLabel, Icon, useMediaQuery} from '@mui/material';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {fetchStates} from '@slices/functionalTesting';
import {longDash} from '@src/constants';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import ButtonDefault from '@ui/MUI/Button';
import CheckboxDefault from '@ui/MUI/Checkbox';
import Typography from '@ui/MUI/Typography';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import {FunctionalStateIndicator} from '../../ReportsInformation/ReportsInformationTable/ReportsInformation';
import {InfoBlock} from '../Report';
import {EmptyValue, TableContainer} from './FunctionalStateTable';

const FunctionalStateTable = ({canEdit, isStagedTesting = false, tableData, selectedSeason, setSelectedSeason}) => {
  const history = useHistory();
  const {teamId, reportDate} = useParams();
  const currentDate = date().valueOf();
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);
  const [bottomBlockRef, {height}] = useElementSize();

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  const seasons = useSeasons();

  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const {states} = useStore(selectTableEditCreate);
  const functionalStateOptions = states && formatStatesOptions(states.functional);

  const [isEmptyPlayersHide, setIsEmptyPlayersHide] = useState(true);

  const uniqueDates = [
    ...new Set(
      tableData
        ?.map((item) => {
          return item.params.map((param) => param.date);
        })
        .flat()
        .sort(function (a, b) {
          if (a > b) {
            return -1;
          }
          if (b > a) {
            return 1;
          }
          return 0;
        }),
    ),
  ];

  const columns = [
    {
      accessorKey: 'player',
      header: (
        <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
          Игрок
        </ToggledHeader>
      ),
      cell: (info) => {
        return (
          <TableCellFlexContainer isToggled={isToggled}>
            <PlayersHorizontalCard
              hasImage={!isToggled}
              isShortName={isSmResolution}
              onlyNumber={isToggled}
              student={info.getValue()}
            />
          </TableCellFlexContainer>
        );
      },
      sortingFn: 'defaultSorting',
      sticky: {left: true},
    },
    ...uniqueDates.map((dateOfTesting) => {
      return {
        accessorKey: date(dateOfTesting).format('YYYY-MM-DD'),
        header: () => {
          return (
            <>
              <span>{date(dateOfTesting).format('DD.MM.YYYY')} </span>
              {canEdit && (
                <Icon
                  component={EditIcon}
                  fontSize="small"
                  onClick={() => {
                    history.push(
                      `/functional_testing_create/staged_testing/${teamId}/${actions.actionEdit}/${date(
                        dateOfTesting,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }}
                />
              )}
            </>
          );
        },
        headerStyle: {
          paddingRight: canEdit && `40px !important`,
        },
        cell: (info) => {
          return info.getValue() ? (
            <FunctionalStateIndicator type={info.getValue()} />
          ) : (
            <EmptyValue>{longDash}</EmptyValue>
          );
        },
        cellStyle: {
          textAlign: isSmResolution && 'center',
        },
        sortable: false,
      };
    }),
  ];

  const formattedTableData = tableData.map((item) => {
    const dates = item.params.map((param) => param.date);
    const formattedDates = dates?.reduce((res, date) => {
      res[date] = item.params.find((param) => param.date === date).functional;
      return res;
    }, {});
    return {
      player: {
        id: item.params[0].playerId,
        firstName: item.firstName,
        lastName: item.lastName,
        playerNumber: item.playerNumber,
        avatar: item.avatar,
      },
      ...formattedDates,
    };
  });

  return (
    <TableContainer isStagedTesting={isStagedTesting}>
      <InfoBlock sx={{alignItems: isSmResolution && 'center !important'}}>
        <AutocompleteDefault
          isSelectableAll
          label="Сезон"
          limitTags={1}
          name="season"
          options={seasons?.options}
          value={selectedSeason}
          onChange={(_, newValue) => {
            setSelectedSeason(newValue);
          }}
        />
        <FormControlLabel
          control={
            <CheckboxDefault
              checked={isEmptyPlayersHide}
              size="small"
              onChange={() => setIsEmptyPlayersHide(!isEmptyPlayersHide)}
            />
          }
          label="Скрыть игроков без данных"
          labelPlacement="end"
          value="isPlayersHidden"
        />
        {isStagedTesting && canEdit && (
          <ButtonDefault
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push(
                `/functional_testing_create/staged_testing/${teamId}/${actions.actionAdd}/${reportDate || currentDate}`,
              );
            }}
          >
            Добавить отчет
          </ButtonDefault>
        )}
      </InfoBlock>
      {!hideEmptyPlayersInFunctionalStateDynamic(isEmptyPlayersHide, formattedTableData)?.length ? (
        <TableEmptyBlock text="Недостаточно данных для формирования динамики." />
      ) : (
        <ReactTableComponent
          bottomBlockHeight={!isSmResolution ? height + 32 : height + 100}
          columns={columns}
          data={hideEmptyPlayersInFunctionalStateDynamic(isEmptyPlayersHide, formattedTableData)}
          isAvailableHeight={true}
          setSorting={setSorting}
          sorting={sorting}
          tableBodyCellProps={{
            sx: {
              boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
              '@media screen and (max-width: 767px)': {
                width: !isToggled ? '170px !important' : '56px !important',
                maxWidth: !isToggled ? 170 : 56,
                fontSize: '12px !important',
                '&:not(:first-child)': {
                  width: '120px !important',
                  maxWidth: 120,
                },
              },
              '&:last-child': {
                boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
              },
              '&.lastRowCell': {
                boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
              },
            },
          }}
          tableHeaderCellProps={{
            sx: {
              '&:last-child': {
                boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
              },
              '&:first-child': {
                display: 'flex',
              },
              '@media screen and (max-width: 767px)': {
                width: !isToggled ? '170px !important' : '56px !important',
                maxWidth: !isToggled ? 170 : 56,
                fontSize: '12px !important',
                '&:not(:first-child)': {
                  width: '120px !important',
                  maxWidth: 120,
                },
              },
            },
          }}
        />
      )}
      <InfoBlock ref={bottomBlockRef}>
        <Typography variant="subtitle2" weight={600}>
          Значения индикаторов
        </Typography>
        {functionalStateOptions?.map((option) => {
          return (
            <Typography key={option.value} variant="body2" weight={400}>
              <FunctionalStateIndicator type={option.value} />
              {option.label}
            </Typography>
          );
        })}
      </InfoBlock>
    </TableContainer>
  );
};

export default FunctionalStateTable;

FunctionalStateTable.propTypes = {
  canEdit: PropTypes.bool,
  isStagedTesting: PropTypes.bool,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      params: PropTypes.array.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      playerNumber: PropTypes.string,
    }),
  ).isRequired,
  selectedSeason: PropTypes.array,
  setSelectedSeason: PropTypes.func,
};
