import {signal} from '@preact/signals-react';

import {A1TagModalViewModel} from './a1tag.view-mode.types';

export const modalViewModel: A1TagModalViewModel = {
  isOpen: signal<boolean>(false),
  isEdit: signal<boolean>(false),
  type: signal<string>(''),
  payload: signal<any>(null),
};
