import {deleteVideo} from '@api/videoAnalytics';
import DeleteModal from '@common/DeleteModal';
import AddEditModal from '@components/apps/TechnicalSkillsVideoEstimation/components/AddEditModal';
import ThemeTitle from '@components/modules/common/ThemeTitle';
import {shallowEqual} from '@helpers/shallowEqual';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectVideo} from '@selectors/videoAnalytics';
import {clearVideo, loadVideo} from '@slices/videoAnalytics';
import {StudentSubroles} from '@src/types';
import {Box, Button, Stack} from '@ui/MUI';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

import VideoInfo from './components/VideoInfo';
import {mock} from './mock';

export default function VideoPanel({isStudent, isPreview, studentId}) {
  const dispatch = useStoreDispatch();
  const {schoolId, groupId, videoId} = useParams();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [videoBlockRef] = useElementSize();

  const {data, isLoad, isLoading, error} = useStore(selectVideo, shallowEqual);
  const isDisabled = useMemo(() => error || isLoading, [error, isLoading]);

  useEffect(() => {
    dispatch(loadVideo(videoId));
    return () => {
      dispatch(clearVideo());
    };
  }, [videoId]);

  const {breadCrumbs} = useMemo(() => {
    let student = null;
    if (isStudent && data) {
      return {
        breadCrumbs: [
          {label: 'Главная', path: '/'},
          {label: 'Видеоаналитика', path: '/video'},
          {label: data.name, path: null, isDynamic: false},
        ],
        student: null,
      };
    }

    if (studentId) {
      student = data?.students?.find((student) => student._id === studentId);
      const fullName = student ? `${student.lastName} ${student.firstName}` : 'Игрок';

      return {
        breadCrumbs: [
          {label: 'Главная', path: '/'},
          {label: 'Видеоаналитика', path: '/video'},
          {label: fullName, path: `/student/${student?._id}`},
          {label: data.name || 'Видео', path: null},
        ],
        student: {...student, fullName},
      };
    }

    return {
      breadCrumbs: [
        {label: 'Главная', path: '/'},
        {label: 'Видеоаналитика', path: '/video'},
        {
          label: `${data.groups ? `${data.groups[0]?.name}` : 'Команда'}`,
          path: `/videos/${schoolId}/${groupId}`,
        },
        {label: student?.fullName || data.name || 'Видео', path: null},
      ],
      student: null,
    };
  }, [data, isStudent, isPreview]);

  const removeVideo = (videoId) => {
    deleteVideo(videoId).then(() => {
      setModalOpen(false);
      history.goBack();
    });
  };

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  return (
    <>
      <ThemeTitle breadCrumbs={breadCrumbs} isNew={isAmateur} title="Видеоаналитика" />
      <>
        <BlockWrapper p={{xxs: 2, sm: 4}} ref={videoBlockRef} width="100%">
          <VideoInfo data={isLoading ? mock : data} isLoad={isLoad} isLoading={isLoading} />
          <Stack direction="row" justifyContent="space-between">
            {!isStudent && !isPreview && (
              <Button
                color="primary"
                disabled={isDisabled}
                text="Редактировать"
                variant="outlined"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Редактировать
              </Button>
            )}
            {!isStudent && !isPreview && (
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                Удалить
              </Button>
            )}
          </Stack>
          <DeleteModal
            isVisible={isDeleteModalVisible}
            text="Вы уверены, что хотите удалить видео?"
            title="Удаление видео"
            onCancel={() => setIsDeleteModalVisible(false)}
            onDelete={() => {
              removeVideo(videoId);
              setIsDeleteModalVisible(false);
            }}
          />
        </BlockWrapper>
        {data && (
          <AddEditModal
            isEdit
            isOpen={modalOpen}
            title="РЕДАКТИРОВАНИЕ ВИДЕО"
            video={data}
            onClose={() => setModalOpen(false)}
          />
        )}
      </>
    </>
  );
}

const BlockWrapper = styled(Box)`
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;
