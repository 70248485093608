import {ModalMode} from '@components/modules/common/modal-view-model';
import {useSchools} from '@components/modules/school/queries/schools.query';
import {School} from '@components/modules/school/types/school.types';
import {Add} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {roleOptions} from '@src/constants';
import {Autocomplete, Box, Button, Stack} from '@ui/MUI';
import React from 'react';

import {tableFilters, userDrawerModal} from '../models/main.view-model';

export const Filters = () => {
  useSignals();
  const schoolsQuery = useSchools();
  const openAddUserDrawer = () => userDrawerModal.open(ModalMode.ADD);

  return (
    <Stack alignItems="center" borderRadius={1} direction="row" px={4} py={2} sx={{background: 'white'}}>
      <Stack direction="row" flex={1} spacing={4}>
        <Box flex={0.2}>
          <Autocomplete
            isLoading={schoolsQuery.isLoading}
            label="Школа"
            multiple={false}
            // @ts-expect-error TODO: поправить типы в Autocomplete
            options={schoolsQuery.data ?? []}
            // @ts-expect-error TODO: поправить типы в Autocomplete
            value={tableFilters.school}
            onChange={(_, value) => tableFilters.updateSchool(value as unknown as School)}
          />
        </Box>
        <Box flex={0.2}>
          {/* @ts-expect-error TODO: поправить типы в Autocomplete */}
          <Autocomplete
            label="Роль"
            multiple={false}
            options={roleOptions}
            value={tableFilters.role ?? null}
            // @ts-expect-error TODO: поправить типы в Autocomplete
            onChange={(_, value) => tableFilters.updateRole(value as Option)}
          />
        </Box>
      </Stack>
      <Button color="primary" size="small" startIcon={<Add />} variant="contained" onClick={openAddUserDrawer}>
        Добавить пользователя
      </Button>
    </Stack>
  );
};
