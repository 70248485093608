import RadioRange from '@common/TrainingControl/RadioRange';
import {Header} from '@components/modules/prospects/components/Header';
import {useCreateProspectSkills} from '@components/modules/prospects/mutation/create-prospect-skill.mutation';
import {useProspectSkills, useProspectSkillTypes} from '@components/modules/prospects/queries/prospect-skill.query';
import {useStudent} from '@components/modules/student/queries';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {longDash} from '@src/constants';
import {SkillDto} from '@src/types/generated';
import Drawer from '@ui/MUI/DrawerNew';
import {chunk, uniqBy} from 'lodash';
import React, {useEffect} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {mainModal} from './modal/modal.view-modal';

const TableTopContent = () => {
  const {playerId} = useParams<{teamId: string; playerId: string}>();
  const {data} = useProspectSkills(playerId);
  const skills = data ?? [];
  const firstSkill = skills.at(0);

  return (
    <Header>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h4">оценка игрока</Typography>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Typography variant="body2">
            Дата последнего контроля:{' '}
            <Typography component="span" variant="subtitle2">
              {/* 20.02.2022 - нет данных с бэка */}
              {!firstSkill && longDash}
            </Typography>
          </Typography>
          <Button color="primary" size="small" variant="contained" onClick={() => mainModal.open()}>
            Выставить новые оценки
          </Button>
        </Stack>
      </Stack>
    </Header>
  );
};

export interface FormData {
  skills: Omit<SkillDto, 'id' | 'prospect'>[];
}

const FormContent = () => {
  useSignals();
  const form = useForm<FormData>({
    defaultValues: {
      skills: [],
    },
  });

  const {playerId} = useParams<{teamId: string; playerId: string}>();
  const {data: student} = useStudent(playerId);
  const skillTypeQuery = useProspectSkillTypes({
    isConstant: true,
  });
  const prospectSkillsMutation = useCreateProspectSkills(playerId);
  const skills = skillTypeQuery.data ?? [];
  const lastReport = skills.at(0);
  const {fields} = useFieldArray({
    control: form.control,
    name: 'skills',
  });

  const modal = mainModal;

  useEffect(() => {
    if (skillTypeQuery.data?.length) {
      form.setValue(
        'skills',
        skillTypeQuery.data.map((item) => ({
          startScore: 0,
          endScore: 0,
          skillType: item,
        })),
      );
    }
  }, [skillTypeQuery.data]);

  const onSubmit = async (data: FormData) => {
    // TODO: move formatting to helpers
    const formatted = data.skills.map((item) => ({
      prospectId: playerId,
      startScore: item.startScore,
      skillTypeId: item.skillType.id,
    }));

    await prospectSkillsMutation.mutateAsync(formatted);
    modal.close();
  };

  return (
    <Drawer
      buttons={
        <Stack direction="row" justifyContent="space-between">
          <Button color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
            Сохранить
          </Button>
          <Button variant="contained" onClick={() => modal.close()}>
            Отменить
          </Button>
        </Stack>
      }
      open={modal.isOpen}
      title={`Оценка игрока. ${student?.lastName} ${student?.firstName}`}
      onClose={() => modal.close()}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Stack spacing={4}>
        <Typography>
          Дата последнего контроля: <Typography component="span">{!lastReport && longDash}</Typography>
        </Typography>
        <Divider />
        <Stack spacing={5}>
          {fields.map((skill, index) => {
            return (
              <Stack key={skill.id} spacing={4}>
                <Typography variant="subtitle2">{skill.skillType.name}</Typography>
                <Stack direction="row" spacing={3}>
                  <Typography>Актуальная оценка</Typography>
                  <Controller
                    control={form.control}
                    name={`skills.${index}.startScore`}
                    render={({field}) => <RadioRange isOutlined max={10} {...field} />}
                  />
                </Stack>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Typography>Последняя оценка</Typography>
                  <Box alignItems="center" display="flex" justifyContent="center" px="10px" py="4px">
                    <Typography variant="subtitle2">-</Typography>
                  </Box>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Drawer>
  );
};
export const MainTab = () => {
  const {playerId} = useParams<{teamId: string; playerId: string}>();
  const {data: skills} = useProspectSkills({
    userId: playerId,
    isConstant: true,
  });

  const data = skills ?? [];
  const formattedData = data.map((item) => {
    const prevScore = data.find((prev) => prev.skillType.id === item.skillType.id && item.id !== prev.id);
    return {
      ...item,
      startScore: prevScore?.startScore ?? 0,
      endScore: item?.startScore ?? 0,
    };
  });

  const uniqBySkillType = uniqBy(formattedData, (skill) => skill.skillType.id);

  const chunked = chunk(uniqBySkillType, 4);
  const isEmpty = !data.length;
  return (
    <Box sx={{background: 'white'}}>
      <FormContent />
      <TableTopContent />
      <Stack direction="row" justifyContent={isEmpty ? 'center' : 'flex-start'} px={4} py={4} spacing={8}>
        {isEmpty && <Typography>Нет данных</Typography>}
        {chunked.map((chunk, index) => {
          const isLast = index === chunked.length - 1;
          return (
            <Stack direction="row" flex={1} justifyContent="space-between" key={index} spacing={8}>
              <Stack flex={1} spacing={2}>
                {chunk.map((item) => {
                  const isPlus = item.endScore > item.startScore;
                  return (
                    <Stack direction="row" flex="0 1 0%" justifyContent="space-between" key={item.id}>
                      <Typography variant="body1">{item.skillType.name}</Typography>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        {isPlus ? (
                          <ArrowUpward color="success" sx={{fontSize: '20px'}} />
                        ) : (
                          <ArrowDownward color="error" sx={{fontSize: '20px'}} />
                        )}
                        <Typography variant="subtitle2">{item.startScore}</Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
              {!isLast && <Divider orientation="vertical" />}
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};
