import PhysicalTestingAPI from '@api/PhysicalTestingAPI';
import UsersAPI from '@api/UsersAPI';
import useQueryString from '@hooks/useQueryString';
import {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import CommonHelper from '../../helpers/CommonHelper';
import StateHelper from '../../helpers/StateHelper';

const isSimple = (value) => {
  if (Array.isArray(value)) {
    return value.every((item) => typeof item !== 'object');
  }

  return typeof value !== 'object';
};

const getValue = (options, role, type, value, isArray, isFirstOnly) => {
  if (!options.length) return null;

  const isSingle = options.length === 1;
  const option = {value: null, isDisabled: isSingle};

  if (value) {
    if (isArray && !Array.isArray(value)) {
      console.warn(`value is not array but property isArray is true`);
    }

    if (isSimple(value)) {
      option.value = isArray
        ? options.filter((option) => value.includes(option.value))
        : options.find((option) => option.value === value);

      return option;
    }

    option.value = isArray
      ? options.filter((option) => value.map((item) => item.value).includes(option.value))
      : options.find((option) => option.value === value.value);
    return option;
  }

  if (isArray) {
    option.value = isFirstOnly ? [options[0]] : options;
    return option;
  }

  if (role === 'admin') {
    return option;
  }

  if ((type === 'group' && role === 'teacher') || role === 'student') {
    option.value = options[0];
  }

  if (type === 'student' && role === 'student') {
    option.value = options[0];
  }

  if ((role !== 'student' || role !== 'teacher') && type === 'school') {
    option.value = options[0];
  }

  return option;
};

/**@deprecated использовать useSchoolsV2**/
export const useSchool = ({
  value,
  isArray,
  isFirstOnly,
  keepOriginalValues = false,
  allSchoolsPermissionRoles = [],
} = {}) => {
  const options = useSelector((state) => StateHelper.getSchools(state, allSchoolsPermissionRoles), shallowEqual);
  const role = useSelector((state) => state.users.currentUser.userRole);
  const isLoading = useSelector((state) => state.schools.loading);

  const sortedOptions = useMemo(() => {
    return CommonHelper.getDropDownItemsByNamedList(options);
  }, [options]);

  const formated = useMemo(() => {
    if (keepOriginalValues) {
      return sortedOptions.map((option) => ({name: option.label, id: option.value}));
    }
    return sortedOptions;
  }, [keepOriginalValues, sortedOptions]);

  const defaultValue = useMemo(
    () => getValue(sortedOptions, role, 'school', value, isArray, isFirstOnly),
    [sortedOptions, role, value, isArray, isFirstOnly],
  );

  return {options: formated, ...defaultValue, isLoading};
};

/**@deprecated использовать useGroupV2**/
export const useGroup = ({value, connectorValue, isArray, isFirstOnly, isGetAll, keepOriginalValues = false}) => {
  const options = useSelector((state) => StateHelper.getGroups(state, connectorValue, isGetAll), shallowEqual);

  const role = useSelector((state) => state.users.currentUser.userRole);
  const isLoading = useSelector((state) => state.groups.loading);
  const sortedOptions = useMemo(() => CommonHelper.getDropDownTeamsByNamedList(options), [options]);

  const formated = useMemo(() => {
    if (keepOriginalValues) {
      return sortedOptions.map((option) => ({name: option.label, id: option.value}));
    }
    return sortedOptions;
  }, [keepOriginalValues, sortedOptions, connectorValue]);

  const defaultValue = useMemo(
    () => getValue(sortedOptions, role, 'group', value, isArray, isFirstOnly),
    [sortedOptions, role, value, isArray, isFirstOnly],
  );

  return {options: formated, ...defaultValue, isLoading};
};

export const useStudent = ({value, connectorValue, isArray, isFirstOnly}) => {
  const options = useSelector((state) => StateHelper.getGroupStudents(state, connectorValue), shallowEqual);
  const role = useSelector((state) => state.users.currentUser.userRole);
  const isLoading = useSelector((state) => state.users.loading);

  const sortedOptions = useMemo(() => CommonHelper.getDropDownItemsByUsers(options), [options]);

  const defaultValue = useMemo(
    () => getValue(sortedOptions, role, 'student', value, isArray, isFirstOnly),
    [sortedOptions, role, value, isArray, isFirstOnly],
  );

  return {options: sortedOptions, ...defaultValue, isLoading};
};

export const useTeacher = ({value, connectorValue, isArray, isFirstOnly, filteredRoles = []}) => {
  const options = useSelector((state) => StateHelper.getGroupTeachers(state, connectorValue), shallowEqual);
  const isLoading = useSelector((state) => state.users.loading);

  const sortedOptions = useMemo(() => {
    const filteredOptions = !filteredRoles?.length
      ? options
      : options.filter((option) => filteredRoles?.includes(option?.teacherRole));
    return CommonHelper.getDropDownItemsByUsers(filteredOptions);
  }, [options, filteredRoles]);
  const role = useSelector((state) => state.users.currentUser.userRole);

  const defaultValue = useMemo(
    () => getValue(sortedOptions, role, 'student', value, isArray, isFirstOnly),
    [sortedOptions, role, value, isArray, isFirstOnly],
  );

  return {options: sortedOptions, ...defaultValue, isLoading};
};

export const usePeriod = ({connectorValue, handleChange}) => {
  const period = useMemo(() => {
    if (connectorValue) {
      const seasonRange = CommonHelper.getSeasonRange(connectorValue);
      return {
        value: [seasonRange.start.valueOf(), seasonRange.end.valueOf()],
      };
    }
    return {};
  }, [connectorValue]);
  useEffect(() => {
    if (period?.value) {
      handleChange(period.value);
    }
  }, [period?.value]);
};

export const useStageOptions = () => {
  const [qs] = useQueryString();
  const {groupId} = qs;

  const [stageOptions, setStageOptions] = useState([]);

  useEffect(() => {
    PhysicalTestingAPI.getPhysicalTestingStagesForComparison(groupId).then(({data}) => {
      setStageOptions(data?.map((item) => ({label: `Этап ${item.stage}, сезон ${item.season}`, value: item})));
    });
  }, [groupId]);
  return {options: stageOptions};
};

export const useUsers = (roles = []) => {
  const users = useSelector((state) => StateHelper.getUsers(state, roles), shallowEqual);

  const sortedOptions = useMemo(() => CommonHelper.getDropDownItemsByUsers(users), [users]);

  return {options: sortedOptions};
};

export const useUsersWithRequest = (roles = [], q) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    UsersAPI.getUsersByQueryV2({userRole: roles, limit: 999, page: 1, q: q || ''}).then(({data}) => {
      setUsers(data?.result);
    });
  }, []);

  const sortedOptions = useMemo(() => CommonHelper.getDropDownItemsByUsers(users), [users]);

  return {options: sortedOptions};
};
