import {getSeasons} from '@api/SeasonsAPI';
import {useQuery} from '@tanstack/react-query';

import {Season} from '../types/season.type';

export const useSeasons = () => {
  return useQuery({
    queryKey: ['season'],
    queryFn: () => {
      return getSeasons().then((res) => res.data) as Promise<Season[]>;
    },
  });
};
