import ContentWrapper from '@common/ContentWrapper';
import FullHeightWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import {FormData, PlayoffItem, PlayoffItems} from '@components/apps/Tournaments/components/TournamentView/types';
import NoData from '@components/modules/common/NoData';
import useDeepMemo from '@hooks/useDeepMemo';
import {longDash} from '@src/constants';
import {TeamPreviewDto} from '@src/types/generated';
import {Stack, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import uniqBy from 'lodash/uniqBy';
import React, {useMemo} from 'react';

type Row = TeamPreviewDto & {games: {[key: string]: PlayoffItem[]}};
function ChessTable({
  isLoading,
  tournaments = [],
  data = [],
}: {
  isLoading: boolean;
  tournaments: [];
  data: FormData['group'];
}) {
  const {teams, rows} = useDeepMemo<{teams: TeamPreviewDto[]; rows: Row[]}, [PlayoffItems[]]>(() => {
    const group1Teams = data?.[0]?.[0]?.group?.teams;
    const group2Teams = data?.[1]?.[0]?.group?.teams;
    const teams: TeamPreviewDto[] = [...(group1Teams ?? []), ...(group2Teams ?? [])];

    const rows = teams?.map((team) => {
      const games = {};
      data?.flat(1)?.forEach((game) => {
        const firstTeamId = game?.team1?.id;
        const secondTeamId = game?.team2?.id;
        if (firstTeamId === team.id) {
          games[secondTeamId] = [...(games[secondTeamId]?.length ? games[secondTeamId] : []), game];
        }

        if (secondTeamId === team.id) {
          games[firstTeamId] = [...(games[firstTeamId]?.length ? games[firstTeamId] : []), game];
        }
      });
      return {...team, games};
    });

    return {teams: uniqBy(teams, 'id'), rows};
  }, [data]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Команды',
        sticky: {left: true},
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Stack alignItems="center" direction="row" gap={1}>
              <TeamLogo src="" width={20} />
              <Typography isLoading={isLoading} preloaderWidthBetween={[90, 100]} variant="body2">
                {value}
              </Typography>
            </Stack>
          );
        },
        headerStyle: {width: 260},
        cellStyle: {width: 260},
      },
      {
        accessorKey: 'puckDifference',
        header: <Tooltip title="Разница шайб">РШ</Tooltip>,
        cell: ({getValue}) => getValue() ?? 0,
      },
      {
        accessorKey: 'score',
        header: <Tooltip title="Очки">О</Tooltip>,
        cell: ({getValue}) => getValue() ?? 0,
      },
    ];
  }, [isLoading, teams]);

  const formatData = (standings, groupTeams) => {
    const teamIds = groupTeams.map((team) => team.id);
    const filteredStandings = standings?.filter((standing) => teamIds.includes(standing?.team?.id));
    return isLoading
      ? [...Array(10)].fill({
          id: 'nIbpspx6HJ8j',
          name: 'Омские Ястребы ',
          games: {},
        })
      : filteredStandings?.map((standing) => {
          const games = rows.find((row) => row?.id === standing?.team?.id)?.games;
          return {
            ...standing,
            name: standing?.team?.name,
            games,
          };
        });
  };

  const getFormattedColumns = (teams, standings) => {
    const data = isLoading ? [...Array(10)] : teams;
    const sortedByScore = data.sort((a, b) => {
      if (!standings) {
        return 0;
      }

      const aScore = standings.find((standing) => standing?.team?.id === a?.id)?.score;
      const bScore = standings.find((standing) => standing?.team?.id === b?.id)?.score;

      return bScore - aScore;
    });

    return sortedByScore.map((team, index) => {
      return {
        id: index + 'team',
        accessorFn: (params) => {
          return params?.games?.[team?.id] || null;
        },
        header: () => (
          <Tooltip title={team?.name}>
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[90, 100]}
              sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
              variant="subtitle2"
            >
              {team?.name}
            </Typography>
          </Tooltip>
        ),
        headerStyle: {
          textAlign: 'center',
          minWidth: '130px',
          maxWidth: '130px',
        },
        cell: ({getValue}) => {
          const value: PlayoffItem[] = getValue();
          if (value) {
            return (
              <Stack alignItems="center" direction="row" gap={0} justifyContent="space-around" width="100%">
                {(isLoading ? [...Array(4)] : value)?.map((game) => {
                  const isHome = game?.team1?.id === team?.id;
                  const s1 = isHome ? game?.score2 : game?.score1 || 0;
                  const s2 = isHome ? game?.score1 : game.score2 || 0;
                  return (
                    <Stack alignItems="center" direction="row" key={game.id}>
                      <Typography color={s1 < s2 && grey['54']} variant="caption2">
                        {s1}
                      </Typography>
                      <Typography variant="caption2">:</Typography>
                      <Typography color={s2 < s1 && grey['54']} variant="caption2">
                        {s2}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            );
          }
          return longDash;
        },
      };
    });
  };

  return (
    <>
      {!isLoading && !teams?.length ? (
        <ContentWrapper p="0">
          <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
            <NoData />
          </FullHeightWrapper>
        </ContentWrapper>
      ) : (
        <>
          {tournaments?.map((tournament) => {
            const groupTeams = teams.filter((team) => {
              return tournament.group.teams.some((groupTeam) => groupTeam.id === team.id);
            });
            const formattedColumns = [...columns, ...getFormattedColumns(groupTeams, tournament.standings)];
            const data = formatData(tournament.standings, groupTeams);
            return (
              <>
                <Typography
                  sx={{
                    '&:not(:first-child)': {
                      mt: 2,
                    },
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  {tournament.group.name}
                </Typography>
                <ContentWrapper key={tournament.group.id} p="0">
                  <ReactTableComponent
                    columns={formattedColumns}
                    data={data}
                    tableBodyCellProps={{
                      sx: (theme) => ({
                        padding: theme.spacing(2, 3) + '!important',
                        textAlign: 'center',
                        height: 'auto !important',
                        '&:nth-child(n+4)': {
                          boxShadow: `${grey['12']} 0 -1px 0 0 inset`,
                        },
                      }),
                    }}
                    tableHeaderCellProps={{
                      sx: (theme) => ({
                        padding: theme.spacing(2, 3) + '!important',
                        textAlign: 'center',
                        height: 'auto !important',
                        '&:nth-child(n+4)': {
                          boxShadow: `${grey['12']} 0 -1px 0 0 inset`,
                        },
                      }),
                    }}
                  />
                </ContentWrapper>
              </>
            );
          })}
        </>
      )}
    </>
  );
}

export default ChessTable;
