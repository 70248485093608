import './App.css';

import {store} from '@src/utils/store';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';

import ParseAPI from './api/ParseAPI';
import RouterApp from './components/apps/RouterApp';
import {NOTIFICATION_TYPES} from './constants/config';
import AuthHelpers from './helpers/AuthHelper';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  success?: ((response: AxiosResponse) => {title: string; message: string}) | {title: string; message: string};
  failed?: ((response: AxiosError) => {title: string; message: string}) | {title: string; message: string};
}

const token = AuthHelpers.getToken() ? AuthHelpers.getToken() : '';
axios.defaults.headers.Authorization = `Bearer ${token}`;

axios.interceptors.response?.use(
  (res) => {
    const config = res.config as ExtendedAxiosRequestConfig;
    if (config?.success) {
      const {title, message} = typeof config.success === 'function' ? config.success(res) : config.success;

      ReactComponentNotification(NOTIFICATION_TYPES.success, message, title);
    }
    return res;
  },
  (err: AxiosError) => {
    const config = err.config as ExtendedAxiosRequestConfig;
    if (config?.failed) {
      const {title, message} = typeof config.failed === 'function' ? config.failed(err) : config.failed;

      ReactComponentNotification(NOTIFICATION_TYPES.error, message, title);
    }
    return Promise.reject(err);
  },
);

const client = new QueryClient();

ParseAPI.initParse();
const App = () => {
  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={client}>
        <RouterApp />
      </QueryClientProvider>
    </ReduxProvider>
  );
};

export default App;
