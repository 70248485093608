import useQueryString from '@hooks/useQueryString';
import TablePaginationDefault from '@ui/MUI/TablePagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import UsersAPI from '../../../api/UsersAPI';
import LocalPreloader from '../../preloader/LocalPreloader';
import ChessTemplate from '../../templates/ChessTemplate';
import TableDefault from '../../ui/TableDefault';
import UsersFilters from './components/UsersFilters';
import UsersTableContent from './components/UsersTableContent';

const breadCrumbs = [
  {label: 'Главная', path: '/'},
  {label: 'Пользователи', path: '/users'},
];

const Users = () => {
  const [qs, setQs] = useQueryString();

  const [filters, setFilters] = useState({
    schoolId: null,
    q: null,
    userRole: null,
    limit: 25,
    page: 1,
  });

  const [{users, count}, setUsers] = useState({users: [], count: 0});
  const [isLoading, setIsLoading] = useState(false);

  const [filterData, setFilterData] = useState({selectedPage: 0});
  const [selectedCount, setSelectedCount] = useState(25);
  const userRole = useSelector((state) => state.users.currentUser.userRole);
  const schoolsIds = useSelector((state) => state.users.currentUser.schoolsIds);

  useEffect(() => {
    if (filters?.schoolId) {
      setQs({...qs, schoolId: filters?.schoolId});
    }
    if (!filters?.schoolId) {
      setQs({...qs, schoolId: null});
    }
  }, [filters?.schoolId]);

  useEffect(() => {
    if (filters?.userRole) {
      setQs({...qs, userRole: filters?.userRole});
    }
    if (!filters?.userRole) {
      setQs({...qs, userRole: null});
    }
  }, [filters?.userRole]);

  useEffect(() => {
    setQs({...qs, page: (filterData?.selectedPage + 1)?.toString(), count: selectedCount?.toString()});
  }, [filterData?.selectedPage, selectedCount]);

  useEffect(() => {
    if (qs?.userRole || qs?.schoolId) {
      localStorage.setItem('qs', JSON.stringify(qs));
    }
  }, [qs?.userRole, qs?.schoolId]);

  useEffect(() => {
    if (localStorage?.qs) {
      const parsedFromStorageQS = JSON.parse(localStorage.qs);
      setQs({...qs, ...parsedFromStorageQS});
    }
  }, [localStorage?.qs]);

  const getUsers = async (filters) => {
    setIsLoading(true);
    try {
      const {data} = await UsersAPI.getUsersByQueryV2(filters);
      setUsers({users: data.result, count: data.total});
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleChange = (value, name) => {
    setFilters((prev) => ({...prev, [name]: value}));
  };

  useEffect(() => {
    if (qs?.count) {
      setSelectedCount(Number(qs?.count));
      setFilters({...filters, limit: Number(qs?.count)});
    }
    if (qs?.page) {
      setFilterData({...filterData, selectedPage: Number(qs?.page) - 1});
    }
  }, []);

  useEffect(() => {
    const parsedFromStorageQS = localStorage?.qs ? JSON?.parse(localStorage?.qs) : {};
    const formattedFilters = {
      ...filters,
      schoolId: parsedFromStorageQS?.schoolId || qs?.schoolId || filters?.schoolId,
      userRole: parsedFromStorageQS?.userRole || qs?.userRole || filters?.userRole,
    };
    getUsers(formattedFilters);
  }, [localStorage?.qs, qs?.schoolId, qs?.userRole, filters]);

  const headlineContent = useMemo(
    () => <UsersFilters userRole={userRole} onChange={handleChange} />,
    [handleChange, userRole],
  );
  return (
    <ChessTemplate active="users">
      <PageTitleDefault breadCrumbs={breadCrumbs} text="Пользователи" />
      <StyledTableDefault
        withOutOverFlow
        content={
          <Container loading={isLoading}>
            <LocalPreloader visible={isLoading} />
            {headlineContent}
            <UsersTableContent items={users} schoolsIds={schoolsIds} userRole={userRole} />
            <TablePaginationDefault
              count={count}
              page={filterData?.selectedPage}
              rowsPerPage={selectedCount}
              onPageChange={(event, newPage) => {
                setFilterData((prev) => {
                  return {
                    ...prev,
                    selectedPage: newPage,
                  };
                });
                setFilters({...filters, page: newPage + 1});
              }}
              onRowsPerPageChange={(e) => {
                setSelectedCount(e.target.value);
                setFilters({...filters, limit: e.target.value});
                setFilterData({selectedPage: 0});
              }}
            />
          </Container>
        }
        title="Пользователи платформы"
        onChange={handleChange}
      />
    </ChessTemplate>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 500px;
  max-height: ${(props) => (props.loading ? '60vh' : '100%')};
  border-radius: ${(props) => (props.loading ? '0 0 6px 6px' : '6px')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};

  .ScrollbarsCustom {
    max-height: none;
  }
`;

const StyledTableDefault = styled(TableDefault)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export default Users;
