import {uploadFile} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {Button} from '@mui/material';
import {Stack} from '@ui/MUI';
import React, {useRef} from 'react';

export const VideoUpload = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Stack alignItems="center" height="100%" justifyContent="center">
      <input accept="video/mp4" ref={fileInputRef} style={{display: 'none'}} type="file" onChange={handleFileUpload} />
      <Button variant="contained" onClick={handleButtonClick}>
        Upload Video
      </Button>
    </Stack>
  );
};
