import NoDataFullHeight from '@common/NoDataFullHeight';
import StatisticsCard from '@components/apps/StatisticsWithMkcUral/components/StatisticsCard';
import Wrapper from '@components/apps/StatisticsWithMkcUral/components/Wrapper';
import {preloderCardsMockData} from '@components/apps/StatisticsWithMkcUral/constants';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import ThemeTitle from '@components/modules/common/ThemeTitle';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectGamesCards} from '@selectors/gameStatisticsMkc';
import {clearGamesCards, loadGamesCards} from '@slices/statisticsMKC';
import {StudentSubroles} from '@src/types';
import {Autocomplete, Box} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import React, {useEffect, useState} from 'react';

function StatisticsWithMkcUral() {
  const dispatch = useStoreDispatch();
  const [season, setSeason] = useState(null);
  const {data, isLoading, isLoad, error} = useStore(selectGamesCards);
  const {options, currentSeason, ...seasons} = useSeasons(
    false,
    ({currentSeason}) => {
      setSeason(currentSeason);
    },
    true,
  );

  useEffect(() => {
    if (season?.value) dispatch(loadGamesCards({season: season?.value}));
    return () => {
      dispatch(clearGamesCards());
    };
  }, [season?.value]);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  return (
    <ThemeLayout active="statistics" isNew={isAmateur}>
      <ThemeTitle
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Статистика', path: null},
        ]}
        isNew={isAmateur}
        title="Статистика"
      />
      <Wrapper mb={{xxs: 2, sm: 3}}>
        <Box width={{xxs: '100%', xs: 200}}>
          <Autocomplete
            isLoading={seasons?.isLoading}
            label="Сезон"
            multiple={false}
            options={options}
            value={season}
            onChange={(e, v) => setSeason(v)}
          />
        </Box>
      </Wrapper>
      <Stack direction="row" flexWrap="wrap" gap={{xxs: 2, sm: 3}} pb={{xxs: 4, xs: '0px'}}>
        {(isLoad && (error || !data?.length)) || (!season && options?.length) ? (
          <NoDataFullHeight>{!season ? 'Выберите сезон' : 'Нет данных по играм'}</NoDataFullHeight>
        ) : (
          (isLoading || seasons.isLoading ? preloderCardsMockData : data).map((item, index) => (
            <StatisticsCard
              isLoading={isLoading || seasons.isLoading}
              key={index}
              {...item}
              isV2={isAmateur}
              season={season?.value}
            />
          ))
        )}
      </Stack>
    </ThemeLayout>
  );
}

export default StatisticsWithMkcUral;
