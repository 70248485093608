import ContentWrapper from '@common/ContentWrapper';
import {calcWidth} from '@helpers/theme';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Divider, useMediaQuery} from '@mui/material';
import {longDash} from '@src/constants';
import {parseFormToGetValue} from '@src/helpers';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import FieldMap from '../FieldMap';
import Gate from '../Gate';
import {Count, MetricDescription, Wrapper} from './GoaltendersStatistics';

const mock = [
  {
    name: 'Броски в створ',
    value: '33',
  },
  {
    name: 'Пропущенные голы',
    value: '5',
  },
  {
    name: '% сейвов',
    value: '84.85',
  },
  {
    name: '% сейвов из опасной зоны',
    value: '70.59',
  },
  {
    name: '% сейвов из неопасной зоны',
    value: '100',
  },
  {
    name: '% точности передач',
    value: '100',
  },
  {
    name: 'Средний угол отскока',
    value: '49.68°',
  },
  {
    name: 'Средняя дистанция отскока',
    value: '5.59м',
  },
];

GoaltendersStatistics.propTypes = {
  loading: PropTypes.bool,
  statisticsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectData: PropTypes.func.isRequired,
  selectFilters: PropTypes.func.isRequired,
  loader: PropTypes.func.isRequired,
  cleaner: PropTypes.func,
};
function GoaltendersStatistics({
  loading,
  statisticsIds,
  selectData = () => {},
  selectFilters = () => {},
  loader = () => {},
  cleaner = () => {},
  isV2 = false,
}) {
  const isLgResolution = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useStoreDispatch();

  const {
    data: {gatesMap, topStats, shotsByZones},
    isLoad = false,
    error,
    ...goaltendersData
  } = useStore(selectData);
  const filters = useStore(selectFilters);

  const isLoading = useMemo(() => goaltendersData?.isLoading || loading, [goaltendersData?.isLoading, loading]);
  const metrics = useDeepMemo(() => (isLoading ? mock : topStats), [topStats, isLoading]);
  useDeepEffect(() => {
    statisticsIds?.length &&
      dispatch(
        loader({
          ...parseFormToGetValue(filters),
          statisticsIds,
        }),
      );
    return () => {
      dispatch(cleaner());
    };
  }, [filters, statisticsIds]);

  if (isLoad && (error || (isEmpty(gatesMap) && isEmpty(topStats) && isEmpty(shotsByZones)))) {
    return (
      <Wrapper alignItems="center" height="75vh" justifyContent="center">
        <TypographyDefault variant="subtitle2">{error || 'Статистика по вратарям отсутствует'}</TypographyDefault>
      </Wrapper>
    );
  }
  return (
    <>
      <ContentWrapper backgroundColor={{xxs: 'transparent', xs: '#fff'}} p="0">
        {isLoading || topStats?.length ? (
          <Stack
            direction="row"
            divider={!isLgResolution && <Divider flexItem orientation="vertical" />}
            flexWrap={{xxs: 'wrap', lg: 'nowrap'}}
            gap={{xxs: 1, xs: 2, lg: 4}}
            justifyContent="space-between"
            overflow="auto"
            p={{xxs: '0', xs: 4}}
          >
            {metrics.slice(0, metrics?.length - 3).map((item, index, arr) => (
              <CardWrapper
                className="top"
                count={arr?.length}
                key={index}
                p={{xxs: 2, xs: '0'}}
                width={(theme) => ({
                  xxs: calcWidth(2, theme.spacing(1)),
                  xs: calcWidth(3, theme.spacing(2)),
                  lg: calcWidth(arr.length, `${theme.spacing(2)} * 2 - 1px`),
                })}
              >
                <Count color="primary" isLoading={isLoading} mb={0} preloaderWidthBetween={[20, 50]}>
                  {!item.value || item?.value?.includes('-1') ? longDash : item.value}
                </Count>
                <MetricDescription mb={0}>{item.name}</MetricDescription>
                {item?.engName === 'Games played by player' && item?.additionalValue && (
                  <Stack alignItems="flex-end" direction="row" gap={0}>
                    <TypographyDefault color="primary" sx={{fontSize: '18px', lineHeight: '24px'}} variant="subtitle2">
                      {item?.additionalValue}
                    </TypographyDefault>
                    <TypographyDefault variant="subtitle2">минут</TypographyDefault>
                  </Stack>
                )}
              </CardWrapper>
            ))}
          </Stack>
        ) : (
          <ContentWrapper p="0">
            <TypographyDefault py={4} textAlign="center" variant="subtitle1">
              Нет данных
            </TypographyDefault>
          </ContentWrapper>
        )}
      </ContentWrapper>

      <Stack direction={{xxs: 'column', md: 'row'}} spacing={1}>
        <Wrapper width={{xxs: '100%', md: '50%'}}>
          <TypographyDefault
            mb={{xxs: 2, sm: 6}}
            textAlign={{xxs: 'center', sm: 'left'}}
            textTransform={isV2 ? 'none' : 'uppercase'}
            variant={isV2 ? 'h2' : 'h3'}
          >
            Зоны ворот
          </TypographyDefault>
          <Gate gatesMap={gatesMap} />
        </Wrapper>
        <Wrapper width={{xxs: '100%', md: '50%'}}>
          <TypographyDefault
            mb={{xxs: 2, sm: 6}}
            textAlign={{xxs: 'center', sm: 'left'}}
            textTransform={isV2 ? 'none' : 'uppercase'}
            variant={isV2 ? 'h2' : 'h3'}
          >
            Броски по зонам
          </TypographyDefault>
          <FieldMap shotsByZones={shotsByZones} width="auto" />
        </Wrapper>
      </Stack>

      <ContentWrapper backgroundColor={{xxs: 'transparent', xs: '#fff'}} p="0">
        {isLoading || topStats?.length ? (
          <Stack
            alignItems="center"
            direction={{xxs: 'column', sm: 'row'}}
            divider={!isSmResolution && <Divider flexItem orientation="vertical" />}
            flexWrap={{sm: 'wrap', lg: 'nowrap'}}
            justifyContent={{xxs: 'space-between', lg: 'initial'}}
            p={{xxs: '0', xs: 3}}
            spacing={{xxs: 1, sm: 2, lg: 5}}
          >
            {metrics.slice(metrics?.length - 3, metrics?.length).map((item, index) => (
              <CardWrapper
                key={index}
                p={{xxs: 2, xs: '0'}}
                width={(theme) => ({
                  xxs: calcWidth(1, theme.spacing(1)),
                  sm: calcWidth(3, `${theme.spacing(2)} * 2 - 1px`),
                  lg: calcWidth(3, `${theme.spacing(5)} * 2 - 1px`),
                })}
              >
                <Count color="primary" isLoading={isLoading} mb={0} preloaderWidthBetween={[20, 50]}>
                  {!item.value || item?.value?.includes('-1') ? longDash : item.value}
                </Count>
                <MetricDescription>{item.name}</MetricDescription>
              </CardWrapper>
            ))}
          </Stack>
        ) : (
          <ContentWrapper p="0">
            <TypographyDefault py={4} textAlign="center" variant="subtitle1">
              Нет данных
            </TypographyDefault>
          </ContentWrapper>
        )}
      </ContentWrapper>
    </>
  );
}

GoaltendersStatistics.propTypes = {
  loading: PropTypes.bool,
};

export default GoaltendersStatistics;

const CardWrapper = styled(ContentWrapper)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  &.top {
    &:last-child {
      margin-right: auto;
      ${({theme, count}) => ({
        [theme.breakpoints.down('xs')]: {
          width: count % 2 > 0 ? '100%' : '',
        },
      })}
    }
  }
`;
