import {getGroupByStatisticId} from '@api/StatsAPI';
import {useQuery} from '@tanstack/react-query';

import {TeamInfo} from './statistic.types';

export const useGetGroupByStatisticId = (statisticId: string) => {
  return useQuery({
    queryKey: ['statistic', 'group', statisticId],
    queryFn: () => getGroupByStatisticId(statisticId).then((res) => res.data) as Promise<TeamInfo | undefined>,
    enabled: !!statisticId,
  });
};
