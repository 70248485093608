import DividerMui from '@mui/material/Divider';
import {DividerProps} from '@mui/material/Divider/Divider';
import {grey} from '@ui/MUI/colors';
import React from 'react';

const Divider = ({
  sx = {},
  borderWidth = 1,
  borderColor = grey['24'],
  ...props
}: {
  borderWidth?: number | string;
  borderColor?: string;
} & DividerProps) => {
  return (
    <DividerMui sx={{...sx, borderWidth: borderWidth, borderColor, '&::before, &::after': {borderColor}}} {...props} />
  );
};

export default Divider;
