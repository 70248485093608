import {PageTitleProps} from '@src/types/uikit/pageTitle';
import PageTitleDefault from '@ui/PageTitleDefault';
import PageTitle from '@ui/v2/PageTitle';
import React from 'react';

type ThemeTitleProps = {
  isNew?: boolean;
} & PageTitleProps;
export default function ThemeTitle({isNew, title, ...props}: ThemeTitleProps) {
  return isNew ? <PageTitle title={title} {...props} /> : <PageTitleDefault text={title} {...props} />;
}
