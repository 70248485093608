import {useCurrentUserRole} from '@hooks/user';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import ChessTemplate from '../../templates/ChessTemplate';
import TeamClubs from './components/TeamClubs';
import {tabs} from './constants';

export default function ScoutReport() {
  const history = useHistory();
  const {type} = useParams();

  const typeIndex = tabs.findIndex((item) => item.link === type);

  const role = useCurrentUserRole();

  const isAdmin = role === 'admin';

  useEffect(() => {
    if (typeIndex < 0) {
      return history.push('/trainer_report/inner/teams');
    }
  }, [type]);

  return (
    <ChessTemplate active="trainer_report">
      <PageTitleDefault text="Характеристика игрока" />
      <TabsNew
        withoutWrapper
        tabs={[
          {
            label: 'внутренний',
            value: <TeamClubs key="0" onClick={(id) => history.push(`/trainer_report/inner/teams/${id}`)} />,
          },
          isAdmin && {
            label: 'Архив',
            value: <TeamClubs key="1" onClick={(id) => history.push(`/trainer_report/inner/teams/${id}`)} />,
          },
        ]}
        value={typeIndex >= 0 ? typeIndex : 0}
        onChange={(e) => {
          history.push(`/trainer_report/${tabs[e].link}/teams`);
        }}
      />
    </ChessTemplate>
  );
}
