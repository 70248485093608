import {Stack, Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import {olive} from '@ui/MUI/colors';
import React from 'react';

const PlayerInfo = ({
  avatar,
  firstName,
  lastName,
  playerNumber,
  playerColor,
  playerVariant = 'subtitle2',
  ...props
}) => {
  const fullName = `${lastName} ${firstName}`;

  return (
    <Stack alignItems="center" direction="row" overflow="hidden" spacing={1} {...props}>
      {avatar ? (
        <Avatar src={avatar} width={32} />
      ) : (
        <Avatar width={32}>
          {firstName?.[0]}
          {lastName?.[0]}
        </Avatar>
      )}
      <Stack direction="row" spacing="4px">
        {playerNumber && (
          <Typography color={olive[85]} variant="subtitle2">
            {playerNumber}
          </Typography>
        )}
        <Typography color={playerColor} variant={playerVariant}>
          {fullName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PlayerInfo;
