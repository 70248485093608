export enum HOT_KEYS_SLUG {
  PREVIOUS_FRAME = 'previous-frame',
  NEXT_FRAME = 'next-frame',
  REWIND = 'rewind',
  FAST_FORWARD = 'fast-forward',
}

export const HOT_KEYS = [
  {
    name: 'Previous frame',
    defaultKey: 'q',
    slug: HOT_KEYS_SLUG.PREVIOUS_FRAME,
  },
  {
    name: 'Next frame',
    defaultKey: 'e',
    slug: HOT_KEYS_SLUG.NEXT_FRAME,
  },
  {
    name: 'Rewind',
    defaultKey: 'a',
    slug: HOT_KEYS_SLUG.REWIND,
  },
  {
    name: 'Fast forward',
    defaultKey: 'd',
    slug: HOT_KEYS_SLUG.FAST_FORWARD,
  },
];
