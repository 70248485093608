import {getProspects} from '@api/prospect.api';
import {ProspectUserDto} from '@src/types/generated';
import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

export const useProspectsQuery = (
  teamId?: string,
  params?: UseQueryOptions<ProspectUserDto[]>,
): UseQueryResult<ProspectUserDto[]> => {
  return useQuery({
    staleTime: 5 * 60 * 1000, // 5 min
    ...params,
    queryKey: ['prospect', teamId],
    queryFn: () => getProspects({teamId}),
  });
};
