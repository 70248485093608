import {a1tagViewModel, updateCleanTime} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {Stack, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {computed} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import React, {memo, useEffect} from 'react';

import {TableButtons} from './components/buttons/buttons.component';
import {Filters} from './components/filters/filters.component';
import {columns} from './constants/a1table.constants';
import {filtersViewModel} from './models/filters.view-model';

const filteredData = computed(() => {
  const player = filtersViewModel.filters.player.value?.value;
  const event = filtersViewModel.filters.event.value?.value;
  const teamName = filtersViewModel.filters.teamName.value?.value;
  let filteredData = a1tagViewModel.statistics?.value;

  if (event) {
    filteredData = filteredData.filter((statistic) => statistic.action_id === event);
  }

  if (player) {
    filteredData = filteredData.filter(
      (statistics) => statistics.player_id === player || statistics.opponent_id === player,
    );
  }

  if (teamName) {
    filteredData = filteredData.filter((statistics) => statistics.team_name === teamName);
  }

  return filteredData;
});

export const A1TagTable = memo(() => {
  useSignals();
  const table = useReactTable({columns, data: filteredData.value, getCoreRowModel: getCoreRowModel()});

  useEffect(() => {
    if (!a1tagViewModel?.statistics?.value?.length) {
      updateCleanTime(0);
    }
  }, [a1tagViewModel?.statistics?.value?.length]);

  return (
    <Stack borderTop="1px solid rgb(51, 51, 51)">
      <Stack alignItems="center" direction="row" justifyContent="space-between" p={3}>
        <Filters />
        <TableButtons />
      </Stack>
      <Table
        sx={{
          '& .MuiTableCell-root': {
            border: '1px solid #333333',
            padding: '0px 8px',
            fontSize: 12,
            lineHeight: 1,
            color: '#FFFFFF',
          },

          '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              padding: '2px 8px',
              fontWeight: 'bold',
              fontSize: 12,
              lineHeight: 1.5,
              textTransform: 'uppercase',
              color: '#BFBFBF',
            },
          },

          '& .MuiTableBody-root': {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: '#1F1F1F',
            },
            '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: '#1A1A1A',
            },
          },
        }}
      >
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {!a1tagViewModel?.statistics?.value?.length && (
            <TableRow sx={{height: 24}}>
              <TableCell align="center" colSpan={columns.length}>
                NO DATA TO DISPLAY
              </TableCell>
            </TableRow>
          )}
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
});

A1TagTable.displayName = 'A1TagTable';
