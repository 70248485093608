import {createModalViewModel} from '@components/modules/common/modal-view-model';
import {School} from '@components/modules/school/types/school.types';
import {signal} from '@preact/signals-react';

export interface Option {
  label: string;
  value: string;
}
export const createFilters = () => {
  return {
    _school: signal<School | null>(null),
    _role: signal<Option | null>(null),

    get school() {
      return this._school.value;
    },

    get role() {
      return this._role.value;
    },

    updateSchool(value: School | null) {
      this._school.value = value;
    },

    updateRole(value: Option) {
      this._role.value = value;
    },

    reset() {
      this._school.value = null;
      this._role.value = null;
    },
  };
};
export const tableFilters = createFilters();
export const userDrawerFilters = createFilters();
export const tab = signal<string>('');

export interface ModalPayload {
  activeSubSectionsIds: string[];
  userId: string;
}

export const addSectionDrawer = createModalViewModel();
export const userDrawerModal = createModalViewModel<ModalPayload>();
export const confirmationModal = createModalViewModel();
