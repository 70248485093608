import {signal} from '@preact/signals-react';

import {Goal} from '../mock/goals.mock';

export const createGoalDrawer = <T>() => {
  return {
    _payload: signal<T | null>(null),
    _isOpen: signal(false),

    get isOpen() {
      return this._isOpen.value;
    },

    set isOpen(value: boolean) {
      this._isOpen.value = value;
    },

    close() {
      this.isOpen = false;
      this._payload.value = null;
    },

    open(payload?: T) {
      this.isOpen = true;
      this._payload.value = payload ?? null;
    },

    get payload() {
      return this._payload.value;
    },
  };
};

export const newGoal = createGoalDrawer();
export type CompletedGoalPayload = Pick<Goal, 'goal' | 'expectedResult'> & {id: string};
export const completedGoal = createGoalDrawer<CompletedGoalPayload>();
