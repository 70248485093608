import date from '@helpers/date';
import useStore from '@hooks/useStore';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import {roleOptions, rolesWithSubrolesMap, userRoles} from '@src/constants';
import {FACELESS_AVATAR, NOTIFICATION_TYPES, TEACHERS_ROLES_OPTIONS} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import {StudentSubroles, UserRoles} from '@src/types';
import {Autocomplete, Button, TextField} from '@ui/MUI';
import DatePickerDefault from '@ui/MUI/DatePicker';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import SchoolsApi from '../../../../api/SchoolsAPI';
import UsersAPI from '../../../../api/UsersAPI';
import CommonHelper from '../../../../helpers/CommonHelper';
import StateHelper from '../../../../helpers/StateHelper';
import {HANDS_OPTIONS, HOCKEY_ROLES_OPTIONS} from '../../../students/constants';
import ReactComponentModal from '../../../ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import TableDefault from '../../../ui/TableDefault';
import ModalCropUploadPanel from '../../../upload/ModalCropUploadPanel';

const mailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const UserForm = ({isUserAdmin, user, isCreate, getUser, setIsEdit, setIsLoading, isFromTable}) => {
  const history = useHistory();

  const [schools, setSchools] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isCrop, setIsCrop] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [childrens, setChildrens] = useState([]);

  const {control, watch, handleSubmit, setValue, reset} = useForm();
  const role = watch('role')?.value;
  const subRole = watch('subRole');
  const subRolesOptions = useMemo(
    () =>
      Object.entries(rolesWithSubrolesMap?.[role]?.subRole || {})?.map(([value, label]) => ({
        value,
        label,
      })),
    [role],
  );
  // roles
  const isStudent = role === UserRoles.STUDENT;
  const isDoctor = role === UserRoles.DOCTOR;
  const isFranchisee = role === UserRoles.FRANCHISEE;
  const isTeacher = role === UserRoles.TEACHER;
  const isPsycho = role === UserRoles.PSYCHO;
  const isSchoolAdmin = role === UserRoles.SCHOOLS_ADMIN;
  const isParent = role === UserRoles.PARENT;
  const isVideoAnalyst = role === UserRoles.VIDEO_ANALYST;

  const formatedForForm = useMemo(() => {
    if (user) {
      const userSchool = user.schools
        ? user.schools.map((school) => ({label: school.name, value: school.id}))
        : user.school
          ? [{label: user?.school?.name, value: user?.school?.id}]
          : [];
      const userGroup = user.groups
        ? user.groups.map((group) => ({label: group.name, value: group.id}))
        : user.group
          ? [{label: user?.group?.name, value: user?.group?.id}]
          : [];

      return {
        ...user,
        role: roleOptions.find((role) => role.value === user?.userRole) || null,
        school: userSchool,
        group: userGroup,
        amplua: HOCKEY_ROLES_OPTIONS.find((role) => role.value === user?.hockeyRole) || null,
        hand: HANDS_OPTIONS.find((hand) => hand.value === user?.hand) || null,
        userRole: TEACHERS_ROLES_OPTIONS.find((role) => role.value === user?.teacherRole) || null,
        subRole: !isTeacher
          ? subRolesOptions?.find((subRole) => subRole.value === user?.subRole)?.value || null
          : subRolesOptions?.find((subRole) => subRole.value === user?.teacherRole)?.value || null,
      };
    }

    return {};
  }, [user, subRolesOptions]);

  const userSchool = useStore((state) => state.users.currentUser.schoolsIds[0]);

  useEffect(() => {
    if (user) {
      reset(formatedForForm);
      getGroupsBySchoolId(formatedForForm.school);
    }
  }, [formatedForForm, userSchool]);

  useEffect(() => {
    if (formatedForForm.children && formatedForForm.children.length) {
      reset({
        ...formatedForForm,
        childrens: formatedForForm.children.map((element) => ({
          label: element.firstName + ' ' + element.lastName,
          value: element.id,
        })),
      });
    }
  }, [formatedForForm, userSchool]);

  useEffect(() => {
    if (!user && schools.length && !isUserAdmin) {
      const currentSchool = schools.find((school) => school.value === userSchool);
      setValue('school', currentSchool);
      getGroupsBySchoolId(currentSchool);
    }
  }, [schools, userSchool, isUserAdmin]);

  const [filters, setFilters] = useState({
    userRole: UserRoles.STUDENT,
    q: null,
    limit: 25,
    page: 1,
    active: true,
  });

  const getSchools = async () => {
    const schools = await SchoolsApi.getAllSchools();
    setSchools(schools.map((school) => ({label: school.name, value: school.id})));
  };

  const getChildrens = async (value) => {
    setIsLoading(true);
    try {
      const {data} = await UsersAPI.getUsersByQueryV2(value);
      setChildrens(
        data.result.map((element) => ({
          label: element.firstName + ' ' + element.lastName,
          value: element.id,
        })),
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getChildrens(filters);
  }, [filters]);

  const handleChange = (value, name) => {
    setFilters((prev) => ({...prev, [name]: value}));
  };

  const [handleSearch] = useDebouncedCallback(
    (value) => {
      handleChange(value, 'q');
    },
    300,
    [],
  );

  const getGroupsBySchoolId = async (schools) => {
    let groups = null;

    if (Array.isArray(schools)) {
      groups = await Promise.all(
        schools.map(async (school) => {
          return await StateHelper.getGroupsBySchoolId(school.value);
        }),
      );
    } else {
      if (schools?.value) {
        groups = await StateHelper.getGroupsBySchoolId(schools?.value);
      }
    }

    setGroups(CommonHelper.getDropDownTeamsByNamedList(groups?.flat()));
  };

  useEffect(() => {
    getSchools();
  }, []);

  const handelSave = async (data) => {
    const selectedRole = data?.role?.value;
    let currentSchool = null;
    let currentGroup = null;
    if (selectedRole === 'student') {
      currentSchool = Array.isArray(data.school)
        ? {name: data?.school[0]?.label, id: data?.school[0]?.value}
        : {name: data?.school?.label, id: data?.school?.value};
      currentGroup = Array.isArray(data.group)
        ? {name: data?.group[0]?.label, id: data?.group[0]?.value}
        : {name: data?.group?.label, id: data?.group?.value};
    } else {
      currentSchool = Array.isArray(data?.school) ? data?.school.map((school) => school.value) : [data?.school?.value];
      currentGroup = Array.isArray(data?.group) ? data.group.map((group) => group.value) : [data?.group?.value];
    }

    const formatedData = {
      hockeyRole: data?.amplua?.value,
      groupId: selectedRole === userRoles.student ? currentGroup?.id : null,
      groupsIds: selectedRole !== userRoles.student ? currentGroup : null,
      hand: data?.hand?.value,
      [selectedRole === userRoles.teacher ? 'teacherRole' : 'subRole']: data?.subRole,
      schoolId: (selectedRole === userRoles.student && currentSchool?.id) || null,
      schoolsIds: (selectedRole !== userRoles.student && currentSchool) || null,
      birthdayTimestamp: data?.birthdayTimestamp,
      email: data?.email?.toLowerCase(),
      childrenIds: data?.childrens?.map((el) => el.value) || [],
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      middleName: data?.middleName?.trim(),
      password: data?.password,
      phone: data.phone,
      playerNumber: data?.playerNumber,
    };

    setIsLoading(true);
    try {
      if (isCreate) {
        const {data} = await UsersAPI.createUserV2(formatedData, role?.replace('_', '-'));

        if (data) {
          history.push(`/users/${data._id}`);
        }
        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Пользователь создан`);
      } else {
        const {data} = await UsersAPI.updateUserV2(
          {...formatedData, userRole: role},
          user.userRole?.replace('_', '-'),
          user.id,
        );

        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Пользователь сохранен`);
        if (data) {
          setIsEdit(false);
          getUser(user.id);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
          }
        }
      }
      setIsLoading(false);
    }
  };
  return (
    <form autoComplete="new-form" onSubmit={handleSubmit(handelSave)}>
      <ReactComponentModal
        buttons={
          <React.Fragment>
            <Button
              className={'DialogButton DialogButton_no'}
              color="primary"
              variant="contained"
              onClick={() => {
                setIsAlert(false);
              }}
            >
              Отменить
            </Button>
            <Button
              className={'DialogButton DialogButton_yes'}
              color="primary"
              variant="outlined"
              onClick={() => {
                if (isCreate) {
                  history.goBack();
                } else {
                  if (isFromTable) {
                    history.goBack();
                    return;
                  }
                  setIsEdit(false);
                }
              }}
            >
              Закрыть
            </Button>
          </React.Fragment>
        }
        className="ReactComponentModalDefault"
        content={
          <>
            <p>Все введенные данные не будут сохранены - вы уверены?</p>
          </>
        }
        title={!isCreate ? 'Редактирование' : 'Создание'}
        visible={isAlert}
        onClose={() => {
          setIsAlert(false);
        }}
      />
      <ModalCropUploadPanel
        cropModalState={isCrop}
        onChange={(url) => {
          setValue('avatar', url);
        }}
        onClose={() => {
          setIsCrop(false);
        }}
      />
      <TableDefault
        withOutOverFlow
        withSave
        content={
          <>
            <Container>
              <HeaderTitle>Личные данные</HeaderTitle>
              <Wrapper>
                <Controller
                  control={control}
                  name="avatar"
                  render={({field}) => (
                    <AvatarContainer
                      onClick={() => {
                        setIsCrop(true);
                      }}
                    >
                      <Avatar src={field.value ? convertImgTo200(field.value) : FACELESS_AVATAR} />
                      <BadgeContainer
                        onClick={() => {
                          setIsCrop(true);
                        }}
                      >
                        <BadgeIcon>
                          {field.value ? (
                            <CreateIcon sx={{color: '#ffff', width: 12, height: 12}} />
                          ) : (
                            <AddIcon sx={{color: '#ffff', width: 12, height: 12}} />
                          )}
                        </BadgeIcon>
                      </BadgeContainer>
                    </AvatarContainer>
                  )}
                  rules={{required: false}}
                />
              </Wrapper>
              <CellsContainer>
                {isCreate && (
                  <Cell>
                    <Label>Роль</Label>
                    <Controller
                      control={control}
                      name="role"
                      render={({field, fieldState}) => {
                        return (
                          <Autocomplete
                            error={fieldState?.error}
                            helperText={fieldState?.error && 'Обязательное поле'}
                            multiple={false}
                            options={isUserAdmin ? roleOptions : roleOptions.filter((role) => role.value !== 'admin')}
                            textFieldVariant="outlined"
                            value={field.value}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              handleChange(newValue?.value, 'userRole');
                              setValue('school', null);
                              setValue('group', null);
                            }}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                <Cell>
                  <Label>Имя</Label>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({field, fieldState}) => {
                      return (
                        <TextField
                          error={fieldState.error}
                          helperText={fieldState?.error && 'Обязательное поле'}
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: true}}
                  />
                </Cell>
                <Cell>
                  <Label>Фамилия</Label>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({field, fieldState}) => {
                      return (
                        <TextField
                          error={fieldState.error}
                          helperText={fieldState?.error && 'Обязательное поле'}
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: true}}
                  />
                </Cell>
                <Cell>
                  <Label>Отчество</Label>
                  <Controller
                    control={control}
                    name="middleName"
                    render={({field, fieldState}) => {
                      return (
                        <TextField
                          error={fieldState?.error}
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: false}}
                  />
                </Cell>
                <Cell>
                  <Label>Дата рождения</Label>
                  <Controller
                    control={control}
                    name="birthdayTimestamp"
                    render={({field: {value, onChange, ...field}, fieldState}) => {
                      return (
                        <DatePickerDefault
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          inputFormat="DD.MM.yyyy"
                          maxDate={date()}
                          size="small"
                          {...field}
                          value={value}
                          variant="outlined"
                          onChange={(newBirthdayTimestamp) => {
                            onChange(newBirthdayTimestamp ? +new Date(newBirthdayTimestamp) : null);
                          }}
                        />
                      );
                    }}
                    rules={{required: subRole === StudentSubroles.AMATEUR ? false : 'Обязательное поле'}}
                  />
                </Cell>
                <Cell>
                  <Label>Телефон</Label>
                  <Controller
                    control={control}
                    name="phone"
                    render={({field, fieldState}) => {
                      return (
                        <InputMask mask="9 (999) 999-99-99" value={field.value} onChange={field.onChange}>
                          {(inputProps) => (
                            <TextField
                              {...inputProps}
                              error={fieldState?.error}
                              helperText={fieldState?.error && 'Поле заполнено неверно'}
                              variant="outlined"
                            />
                          )}
                        </InputMask>
                      );
                    }}
                    rules={{
                      required: false,
                      validate: (v) => {
                        if (!v) return true;
                        return v?.replace(/\D+/g, '')?.length === 11;
                      },
                    }}
                  />
                </Cell>
                {isParent && (
                  <>
                    <Cell>
                      <Label>ДЕТИ</Label>
                      <Controller
                        control={control}
                        name="childrens"
                        render={({field, fieldState}) => {
                          return (
                            <Autocomplete
                              error={fieldState.error}
                              helperText={fieldState.error && 'Обязательное поле'}
                              limitTags={1}
                              options={childrens}
                              placeholder="Выберите"
                              sx={{minWidth: 250}}
                              textFieldVariant="outlined"
                              value={field.value}
                              onChange={(e, newValue) => {
                                handleSearch(e.target.value);
                                field.onChange(newValue);
                                handleChange(
                                  newValue?.map((item) => item?.value),
                                  'childrens',
                                );
                              }}
                            />
                          );
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </Cell>
                  </>
                )}
                {(isPsycho ||
                  isDoctor ||
                  isTeacher ||
                  isSchoolAdmin ||
                  isStudent ||
                  isFranchisee ||
                  isVideoAnalyst) && (
                  <Cell>
                    <Label>Школа</Label>
                    <Controller
                      control={control}
                      name="school"
                      render={({field, fieldState}) => {
                        const multiple = (() => {
                          if (isUserAdmin) {
                            return !isStudent;
                          }

                          if (field?.value?.length > 1) {
                            return true;
                          }

                          return false;
                        })();
                        return (
                          <Autocomplete
                            disabled={!isUserAdmin}
                            error={fieldState.error}
                            helperText={fieldState?.error && 'Обязательное поле'}
                            limitTags={1}
                            multiple={multiple}
                            options={schools}
                            placeholder="Выберите"
                            textFieldVariant="outlined"
                            value={!multiple ? field?.value?.[0] || field?.value : field?.value}
                            onChange={(_, newValue) => {
                              setValue('group', null);
                              getGroupsBySchoolId(newValue);
                              field.onChange(newValue);
                              if (multiple) {
                                handleChange(newValue, 'school');
                              } else {
                                handleChange(newValue?.value, 'school');
                              }
                            }}
                          />
                        );
                      }}
                      rules={{
                        required: true,
                      }}
                    />
                  </Cell>
                )}
                {(isTeacher || isStudent) && (
                  <Cell>
                    <Label>Команда</Label>
                    <Controller
                      control={control}
                      name="group"
                      render={({field, fieldState}) => {
                        const multiple = isTeacher;
                        return (
                          <Autocomplete
                            error={fieldState.error}
                            helperText={field?.error && 'Обязательное поле'}
                            limitTags={1}
                            multiple={multiple}
                            options={groups}
                            placeholder="Выберите"
                            textFieldVariant="outlined"
                            value={!multiple ? field?.value?.[0] || field?.value : field?.value}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              if (isTeacher) {
                                handleChange(newValue, 'group');
                              } else {
                                handleChange(newValue?.value, 'group');
                              }
                            }}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                {(isTeacher || isStudent) && (
                  <Cell>
                    <Label>Тип</Label>
                    <Controller
                      control={control}
                      name="subRole"
                      render={({field, fieldState}) => {
                        return (
                          <Autocomplete
                            error={fieldState.error}
                            helperText={fieldState?.error && 'Обязательное поле'}
                            multiple={false}
                            options={subRolesOptions}
                            textFieldVariant="outlined"
                            value={subRolesOptions?.find((option) => option.value === field.value)}
                            onChange={(_, newValue) => {
                              field.onChange(newValue?.value);
                              handleChange(newValue?.value, 'subRole');
                            }}
                          />
                        );
                      }}
                      rules={{required: isTeacher}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Амплуа</Label>
                    <Controller
                      control={control}
                      name="amplua"
                      render={({field, fieldState}) => {
                        return (
                          <Autocomplete
                            error={fieldState.error}
                            helperText={fieldState?.error && 'Обязательное поле'}
                            multiple={false}
                            options={HOCKEY_ROLES_OPTIONS}
                            textFieldVariant="outlined"
                            value={field.value}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              handleChange(newValue?.value, 'amplua');
                            }}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Хват</Label>
                    <Controller
                      control={control}
                      name="hand"
                      render={({field, fieldState}) => {
                        return (
                          <Autocomplete
                            error={fieldState.error}
                            multiple={false}
                            options={HANDS_OPTIONS}
                            textFieldVariant="outlined"
                            value={field.value}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              handleChange(newValue?.value, 'hand');
                            }}
                          />
                        );
                      }}
                      rules={{required: false}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Игровой номер</Label>
                    <Controller
                      control={control}
                      name="playerNumber"
                      render={({field, fieldState}) => {
                        return (
                          <TextField
                            error={fieldState?.error}
                            type="number"
                            value={field.value}
                            variant="outlined"
                            onChange={field.onChange}
                          />
                        );
                      }}
                      rules={{required: false}}
                    />
                  </Cell>
                )}
              </CellsContainer>
            </Container>
            <Container>
              <HeaderTitle>Данные для входа</HeaderTitle>
              <CellsContainer pb="5px" pt="5px">
                <Cell>
                  <Label>Логин</Label>
                  <Controller
                    control={control}
                    name="email"
                    render={({field, fieldState}) => {
                      return (
                        <TextField
                          autoComplete="new-login" // выключение autocomplete на форме...
                          error={fieldState?.error}
                          helperText={
                            fieldState?.error &&
                            (fieldState?.error?.type === 'pattern' ? fieldState?.error?.message : 'Обязательное поле')
                          }
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{
                      required: true,
                      pattern: {
                        value: mailRegExp,
                        message: 'Введите корректный e-mail',
                      },
                    }}
                  />
                </Cell>
                <Cell>
                  <Label>{isCreate ? 'Пароль' : 'Новый пароль'}</Label>
                  <Controller
                    control={control}
                    name="password"
                    render={({field, fieldState}) => {
                      return (
                        <TextField
                          autoComplete="new-password"
                          error={fieldState?.error}
                          helperText={fieldState?.error && 'Обязательное поле'}
                          type="password"
                          value={field.value}
                          variant="outlined"
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: isCreate}}
                  />
                </Cell>
              </CellsContainer>
            </Container>
          </>
        }
        title="Анкета пользователя"
        onClose={() => {
          setIsAlert(true);
        }}
      />
    </form>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px 34px;
`;

const Avatar = styled.div`
  width: 65px;
  height: 65px;
  background-color: #e7e7e7;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
`;

const BadgeContainer = styled.div`
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  background-color: #990011;
  border: 1px solid white;
  border-radius: 50%;
  right: -5px;
  bottom: 4px;
`;

const BadgeIcon = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  padding: 15px 34px;
  border-bottom: 1.3px solid #dddad4;
  border-top: 1.3px solid #dddad4;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  font-size: 20px;
`;

const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
`;
const Cell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 22%;
  min-width: 21%;
  padding: 10px;

  @media (max-width: 1300px) {
    min-width: 30%;
    max-width: 30%;
  }

  @media (max-width: 700px) {
    min-width: 40%;
    max-width: 40%;
  }
  @media (max-width: 550px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const CellsContainer = styled.div`
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  display: flex;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  flex-wrap: wrap;
  padding-left: 34px;
`;

const Label = styled.div`
  margin-top: 0;
  margin-bottom: 4px;
  font: 12px / 18px 'Proxima Nova';
  color: rgb(116, 116, 116);
  text-transform: uppercase;
`;

export default UserForm;
