import {useMediaQuery} from '@mui/material';
import {Divider, Stack, Typography} from '@ui/MUI';
import {grey, primary, yellow} from '@ui/MUI/colors';
import {yellowV2} from '@ui/MUI/colorsV2';
import React from 'react';
import styled from 'styled-components';

const colorsAnnotationItems = [
  {label: 'Обычная ', color: grey['24']},
  {label: 'Переигранная', color: yellow['60']},
  {label: 'Переигранная + пропущен гол', color: yellow['100']},
];
const colorsAnnotationItemsV2 = [
  {label: 'Обычная ', color: grey['24']},
  {label: 'Переигранная', color: yellow['60']},
  {label: 'Переигранная + пропущен гол', color: yellowV2['100']},
];
const spacing = {xxs: 3, sm: 4};
function Annotation({isAmateur}) {
  const isLgResolution = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <AnnotationWrapper direction={{xxs: 'column', lg: 'row'}} gap={4} p={4} px={{xxs: 3, xs: 4}} py={2}>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={spacing}>
        <Typography variant="subtitle2">Значения цвета смены</Typography>
        <Stack alignItems="center" direction="row" flexWrap="wrap" gap={spacing}>
          {(isAmateur ? colorsAnnotationItemsV2 : colorsAnnotationItems)?.map(({label, color}, index) => (
            <Stack alignItems="center" direction="row" key={index} spacing={1}>
              <Square bgc={color} />
              <Typography variant="body2">{label}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {!isLgResolution && <Divider orientation="vertical" sx={{height: 'inherit'}} />}
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={spacing}>
        <Typography variant="subtitle2">Значения цвета смены</Typography>
        <Stack direction="row" flexWrap="wrap" gap={spacing}>
          <FillItemWrapper bgc={primary['10']} variant="body2">
            Большинство
          </FillItemWrapper>
          <FillItemWrapper variant="body2">Меньшинство</FillItemWrapper>
        </Stack>
      </Stack>
    </AnnotationWrapper>
  );
}

export default Annotation;

const Square = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-color: ${({bgc}) => bgc || grey['24']};
  border-radius: 2px;
`;

const FillItemWrapper = styled(Typography)`
  ${({theme, bgc}) => ({
    padding: theme.spacing(0, 2),
    backgroundColor: bgc || grey['8'],
    borderRadius: '5px',
  })};
`;

const AnnotationWrapper = styled(Stack)`
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  flex-wrap: wrap;
`;
