import {SkillDto} from '@src/types/generated';
import {ColumnDef} from '@tanstack/react-table';

export const columns: ColumnDef<SkillDto>[] = [
  {
    accessorFn: (row) => row.skillType.name,
    headerStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    cellStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    header: 'Навык',
  },
  {
    accessorFn: (row) => row.skillType.description,
    headerStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    cellStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    header: 'Описание',
  },
];
