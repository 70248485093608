import {getMKCTournamentById} from '@api/mkcStatisticsAPI';
import {getTournamentById} from '@api/TournamentsAPI';
import useDeepEffect from '@hooks/useDeepEffect';
import {useCurrentUserRole} from '@hooks/user';
import {useMediaQuery} from '@mui/material';
import {Box} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import React, {useEffect, useState} from 'react';

import {ROOMS, userRoles} from '../../../../constants';
import {NOTIFICATION_TYPES} from '../../../../constants/config';
import StateHelper from '../../../../helpers/StateHelper';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {ReactComponentNotification} from '../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {parseLessonForEdit} from '../../helper';
import LessonForm from './components/LessonForm';
import LessonView from './components/LessonView';
import {Form} from './style';

const Sidebar = (props) => {
  const {
    sidebarVisible,
    setSidebarVisible,
    onFormSubmit = () => {},
    updateLesson = () => {},
    allAmplua,
    lockerRooms,
    schools,
    isAdd,
    selectedLesson,
    defaultValue,
    handleDeleteLessonFrom,
  } = props;

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const userRole = useCurrentUserRole();

  const [fullLessonInformation, setFullLessonInformation] = useState({
    groups: [],
    players: [],
  });
  const [lessonTournament, setLessonTournament] = useState();
  useDeepEffect(() => {
    if (selectedLesson?.tournamentId) {
      getTournamentById(selectedLesson?.tournamentId).then((res) => {
        setLessonTournament(res.data);
      });
    }
    if (selectedLesson?.mkcTournamentId) {
      getMKCTournamentById(selectedLesson?.mkcTournamentId).then((res) => {
        setLessonTournament(res.data);
      });
    }
  }, [selectedLesson]);

  useEffect(() => {
    setIsEdit(false);
    if (selectedLesson) {
      setLoading(true);
      if (selectedLesson.groupId) {
        const requests =
          userRole !== userRoles?.student
            ? [
                StateHelper.getGroupsBySchoolId(selectedLesson.schoolId),
                StateHelper.getStudentsByTeamId(selectedLesson.groupId),
              ]
            : [StateHelper.getGroupsBySchoolId(selectedLesson.schoolId)];

        Promise.all(requests)
          .then((res) => {
            const fullLessonInformation =
              userRole !== userRoles?.student
                ? {
                    groups: res[0],
                    players: res[1].map((item) => {
                      return {
                        id: item.id,
                        ...item.student,
                      };
                    }),
                  }
                : {
                    groups: res[0],
                  };
            setFullLessonInformation(fullLessonInformation);
            setLoading(false);
          })
          .catch((error) => {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}, попробуйте позже`);
            setLoading(false);
          });
      } else {
        setFullLessonInformation({
          groups: [],
          players: selectedLesson.students,
        });
        setLoading(false);
      }
    }
  }, [selectedLesson?.id, lessonTournament?.id]);
  const SidebarContent = () => {
    return isAdd ? (
      <>
        <LocalPreloader visible={loading} />
        <LessonForm
          isAdd
          allAmplua={allAmplua}
          defaultValues={defaultValue}
          lockerRooms={lockerRooms}
          rooms={ROOMS}
          schools={schools}
          setLoading={setLoading}
          onCloseButtonClick={() => {
            setSidebarVisible({...sidebarVisible, right: false});
          }}
          onFormSubmit={onFormSubmit}
        />
      </>
    ) : isEdit ? (
      <>
        <LocalPreloader visible={loading} />
        <LessonForm
          allAmplua={allAmplua}
          defaultGroups={fullLessonInformation.groups}
          defaultPlayers={fullLessonInformation.players}
          defaultValues={parseLessonForEdit(
            selectedLesson,
            lessonTournament,
            schools,
            fullLessonInformation.groups,
            selectedLesson.students,
          )}
          lockerRooms={lockerRooms}
          rooms={ROOMS}
          schools={schools}
          setLoading={setLoading}
          onCloseButtonClick={() => {
            handleDeleteLessonFrom(selectedLesson.id);
          }}
          onFormSubmit={updateLesson}
        />
      </>
    ) : (
      <>
        {loading && (
          <Box position="relative" sx={{minHeight: 500}}>
            <LocalPreloader visible={true} />
          </Box>
        )}
        {!loading && (
          <LessonView
            lessonTournament={lessonTournament}
            selectedLesson={selectedLesson}
            onCLoseButtonClick={() => {
              setIsEdit(false);
              setSidebarVisible({...sidebarVisible, right: false});
            }}
            onEditButtonClick={() => {
              setIsEdit(true);
            }}
          />
        )}
      </>
    );
  };

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const constructTitle = () => {
    let result = 'Просмотр мероприятия';
    if (isAdd) result = 'Добавить мероприятие';
    if (isEdit) result = 'Редактирование мероприятия';

    return result;
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Drawer
        hasButtons={false}
        open={sidebarVisible?.right}
        title={constructTitle()}
        width={isSmResolution ? '100%' : '60%'}
        onClose={() => setSidebarVisible({...sidebarVisible, right: false})}
      >
        <SidebarContent />
      </Drawer>
    </Form>
  );
};

export default Sidebar;
