import {useUserSubSections} from '@components/modules/accesses/queries/section.query';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import {useGroup} from '@components/modules/group/group.module';
import {useStudent} from '@components/modules/student/student.module';
import {User} from '@components/modules/user/types/user.type';
import {useCurrentUser} from '@hooks/user';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useState} from 'react';
import {useParams} from 'react-router';

import {CompetitiveActivitiesTab} from './tabs/competitive-activities/competitive-activities.tab';
import {GamesReportTab} from './tabs/games-report/games-report.tab';
import {MainTab} from './tabs/main/main.tab';
import {PlayerReportTab} from './tabs/player-report/player-report.tab';
import {SkillAnalyticsTab} from './tabs/skill-analytics/skill-analytics.tab';

const tabs = [
  {label: 'Главное', value: <MainTab />, id: 'main'},
  {label: 'отчет по игроку', value: <PlayerReportTab />, id: 'player-report'},
  {label: 'анализ навыков', value: <SkillAnalyticsTab />, id: 'skill-analytics'},
  {label: 'соревновательная деятельность', value: <CompetitiveActivitiesTab />, id: 'сompetitive-activities'},
  {label: 'отчет по играм', value: <GamesReportTab />, id: 'games-report'},
];

export const ProspectPlayerPage = () => {
  const currentUser = useCurrentUser() as User;
  const userSubSectionsQuery = useUserSubSections(currentUser.id);
  const subSections = userSubSectionsQuery.data ?? [];
  const {teamId, playerId} = useParams<{teamId: string; playerId: string}>();
  const {data: student, isLoading: isStudentLoading} = useStudent(playerId);
  const {data: group, isLoading: isGroupLoading} = useGroup(teamId);
  const studentFullName = student ? `${student.firstName} ${student.lastName}` : '';
  const [tab, setValue] = useState<number>(0);
  const filteredTabs = tabs.filter((tab) => subSections.some((subSection) => subSection.url === tab.id));

  return (
    <ThemeLayout active="prospects">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Проспекты', path: '/prospects'},
          {label: group?.name || '', path: `/prospects/${teamId}`, isLoading: isGroupLoading},
          {label: studentFullName, path: null, isLoading: isStudentLoading},
        ]}
        isLoading={isStudentLoading}
      >
        {studentFullName}
      </PageTitleDefault>
      <TabsNew withoutWrapper tabs={filteredTabs} value={tab} onChange={(v) => typeof v === 'number' && setValue(v)} />
    </ThemeLayout>
  );
};
