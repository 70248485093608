import {signal} from '@preact/signals-react';

export const prospectModal = {
  _isOpen: signal<boolean>(false),

  open() {
    this._isOpen.value = true;
  },

  close() {
    this._isOpen.value = false;
  },

  get isOpen() {
    return this._isOpen.value;
  },
};
