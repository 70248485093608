import {longDash} from '@src/constants';
import {get} from 'lodash';

export const groupEstimatesByPlayers = (estimates, players) => {
  return players.map((player) => {
    const playerEstimates = estimates.filter((estimate) => estimate.playerId === player.id);
    return {
      ...player,
      estimates: playerEstimates,
    };
  });
};

export const groupEstimatesByCategories = (estimates, categories) => {
  return categories.map((category) => {
    const categoryEstimates = estimates.filter((estimate) => estimate.skill.category.id === category.id);
    return {
      ...category,
      estimates: categoryEstimates,
    };
  });
};

export const joinEstimatesProperty = (estimates, property) => {
  const items = estimates.flatMap((estimate) => {
    const value = get(estimate, property);
    if (Array.isArray(value) && value.length) {
      return value.map((item) => `${item?.lastName} ${item?.firstName}`).join(', ');
    }
    return value;
  });

  if (!items.length) {
    return longDash;
  }

  return items.join(', ');
};

export const generateRangeOfNumbers = (start, end) => {
  return Array.from({length: end - start + 1}, (_, i) => start + i);
};

export const getFullName = (user) => {
  if (!user) return '';
  const isArray = Array.isArray(user);
  if (isArray) {
    return user.map((item) => `${item.lastName} ${item.firstName}`).join(', ');
  }
  return `${user.lastName} ${user.firstName}`;
};
