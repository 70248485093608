import {CellDef} from '@components/A1Table/a1Table.types';
import {useCancelSubstitution} from '@components/A1Table/hooks/table.hooks';
import {A1TagEventContextMenu} from '@components/A1TagEventContextMenu';
import {A1tagStatistic} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  a1tagViewModel,
  deleteStatistic,
  updateIsTimerStart,
  updateScore,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {openModal} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {longDash} from '@src/constants';
import {Box} from '@ui/MUI';
import React, {memo, useState} from 'react';

export const EventCell = memo(({getValue, row}: CellDef<number>) => {
  useSignals();

  const isStopGame = row.original.action_name === 'Stoppage of play';
  const isEndOfPeriod = row.original.action_name === 'End of period';
  const isIcing = row.original.action_name === 'Icing';
  const isAssist = row.original.action_name.includes('assist');
  const isKeyPass = row.original.action_name === 'Key pass';
  const isSubstitute =
    row?.original?.action_name?.includes('substitute') || row?.original?.action_name?.includes('substitution');

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const {homeTeam, awayTeam} = a1tagViewModel?.rosters?.value ?? {};

  const updateGoals = (statistics: A1tagStatistic) => {
    if (statistics?.team_name === homeTeam?.name) {
      updateScore('home', a1tagViewModel.homeScore.value > 0 ? a1tagViewModel.homeScore.value - 1 : 0);
    }
    if (statistics?.team_name === awayTeam?.name) {
      updateScore('away', a1tagViewModel.awayScore.value > 0 ? a1tagViewModel.awayScore.value - 1 : 0);
    }
  };

  const {cancelSubstitution} = useCancelSubstitution();

  return (
    <>
      <Box
        onContextMenu={(e) => {
          e.preventDefault();
          setContextMenu(
            contextMenu === null
              ? {
                  mouseX: e.clientX - 14,
                  mouseY: e.clientY - 70,
                }
              : null,
          );
        }}
      >
        {getValue() ?? longDash}
      </Box>
      <A1TagEventContextMenu
        contextMenu={contextMenu}
        hasEdit={!isStopGame && !isEndOfPeriod && !isIcing && !isAssist && !isSubstitute}
        setContextMenu={setContextMenu}
        onDelete={() => {
          deleteStatistic(row.original.id);
          if (row.original.action_name === 'Goal') {
            updateGoals(row.original);
          }
          if (row.original.action_name?.includes('substitution')) {
            cancelSubstitution(row.original);
          }
          setContextMenu(null);
        }}
        onEdit={() => {
          const isEdit = true;
          if (!isStopGame && !isKeyPass) {
            updateIsTimerStart(false);
            openModal(row.original, isEdit);
          }

          if (isKeyPass) {
            updateIsTimerStart(false);
            openModal({eventName: 'Key pass', ...row.original}, isEdit);
          }

          setContextMenu(null);
        }}
      />
    </>
  );
});

EventCell.displayName = 'EventCell';
