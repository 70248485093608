import {createProspectGame, getProspectGames} from '@api/prospect.api';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export const useProspectGame = (userId: string, season?: string) => {
  console.log(season);
  return useQuery({
    staleTime: 5 * 60 * 1000, // 5 min
    queryKey: ['prospect', 'game', userId, season],
    queryFn: () => getProspectGames({userId, season}),
  });
};

export const useCreateProspectGame = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => createProspectGame(data),
    onSuccess: () => {
      client.invalidateQueries({queryKey: ['prospect', 'game']});
    },
  });
};
