import {Button, Typography} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import Paper from '@ui/MUI/Paper';
import React from 'react';

export function A1TagDeletePaper({onDelete}: {onDelete: () => void}) {
  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 12,
        left: 12,
        minWidth: 56,
        minHeight: 30,
        backgroundColor: black[20],
        boxShadow: `0px 1px 24px 0px #00000042`,
        lineHeight: '30px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Button sx={{textTransform: 'none', color: white?.main}} variant="text" onClick={onDelete}>
        <Typography variant="caption2">Delete</Typography>
      </Button>
    </Paper>
  );
}
