import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

export function getOuterUsers(groupId, params) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/outer/student/${groupId}`, {params});
}

export function getInnerUsers(groupId) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/inner/student/${groupId}`);
}

export function createOuterReport(data) {
  return axios.post(`${API_ENDPOINT}/v1/scout-report/outer/student`, data);
}

export function getOuterUserReports(studentId, params) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/outer/report/${studentId}`, {params});
}

export function getInnerUserReports(studentId, params) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/inner/report/${studentId}`, {params});
}

export function createUserReport(reportType, data) {
  return axios.post(`${API_ENDPOINT}/v1/scout-report/${reportType}`, data);
}

export function getReport(reportId) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/${reportId}`);
}

export function deleteReport(reportId) {
  return axios.delete(`${API_ENDPOINT}/v1/scout-report/${reportId}`);
}

export function editReport(reportId, data) {
  return axios.put(`${API_ENDPOINT}/v1/scout-report/${reportId}`, data);
}

export function getGroupReports(params) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/group/`, {params});
}

export function createGroupReport(data) {
  return axios.post(`${API_ENDPOINT}/v1/scout-report/group/`, data);
}

export function getGroupReport(reportId) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/group/${reportId}`);
}

export function deleteGroupReport(reportId) {
  return axios.delete(`${API_ENDPOINT}/v1/scout-report/group/${reportId}`);
}

export function editGroupReport(reportId, data) {
  return axios.put(`${API_ENDPOINT}/v1/scout-report/group/${reportId}`, data);
}

export function getOuterSchool(params = {limit: 12, page: 1}) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/outer/school`, {params});
}

export function getOuterSchoolGroup(schoolId) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/outer/group/${schoolId}`);
}

export function getInnerGroup(params = {limit: 12, page: 1}) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/inner/group`, {params});
}

export function getLastUserReport(playerId) {
  return axios.get(`${API_ENDPOINT}/v1/scout-report/inner/${playerId}/last-report`);
}

export function getPlayerScoutReportStatistic({playerId, params}) {
  return axios.get(
    `${API_ENDPOINT}/v1/scout-report/statistic/${playerId}`,
    {params},
    {
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при получении данных'};
      },
    },
  );
}

export function getPlayerTeamsForAllCareerForScoutReport(playerId) {
  return axios.get(
    `${API_ENDPOINT}/v1/scout-report/student/groups/${playerId}`,
    {},
    {
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при получении данных'};
      },
    },
  );
}
