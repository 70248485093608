import {CircularProgress} from '@mui/material';
import MUITextField from '@mui/material/TextField';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import React, {forwardRef} from 'react';
import styled from 'styled-components';

import {Box} from '..';

type CustomTextFieldProps = {
  display?: string;
  isLoading?: boolean;
  alignCenter?: boolean;
  readOnly?: boolean;
} & TextFieldProps;

function TextField(
  {variant = 'standard', size = 'small', onChange = () => {}, alignCenter = false, ...props}: CustomTextFieldProps,
  ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) {
  return (
    <Wrapper display={props?.display}>
      <MUITextField
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <>
              {props.isLoading && <CircularProgress color="inherit" size={20} />}
              {props?.InputProps?.endAdornment}
            </>
          ),
        }}
        {...props}
        alignCenter={alignCenter}
        ref={ref}
        size={size}
        sx={props.sx ? props.sx : {input: {textAlign: alignCenter ? 'center' : ''}}}
        variant={variant}
        onChange={onChange}
      />
    </Wrapper>
  );
}
export default forwardRef(TextField);

const Wrapper = styled(Box)`
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInput {
    &-root {
      width: 100%;
      &:after {
        border-color: rgba(0, 0, 0, 0.42) !important;
      }
    }
  }
  .Mui-error {
    &:before,
    &:after {
      border-color: #d32f2f !important;
    }
  }
`;
