import {getSkillTrainingStickerByLessonId} from '@api/skill';
import {SkillTrainingStickerResponse} from '@src/types/generated';
import {useQuery} from '@tanstack/react-query';

const acceptedRoomsIds = ['kv4C2oiCJZ', 'cCi7HYAl5E'];

export const useSkillTrainingStickerByLessonId = (lessonId?: string, roomId?: string) => {
  return useQuery({
    queryKey: ['skill-estimate', 'sticker', lessonId],
    queryFn: () => getSkillTrainingStickerByLessonId(lessonId) as Promise<SkillTrainingStickerResponse[]>,
    enabled: !!lessonId && acceptedRoomsIds.includes(roomId),
  });
};
