import Box from '@ui/MUI/Box';
import styled from 'styled-components';

export const StatsBlockWrapper = styled.div`
  position: relative;
  padding: 38px 64px;
  height: ${(props) => (props.isLoading === true ? '75vh' : 'initial')};
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  ${({theme}) => theme.breakpoints.down('xs')} {
    padding: 12px 12px 20px;
  }
`;
export const Wrapper = styled.div`
  ${StatsBlockWrapper} {
    &:not(:last-child) {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .MuiTabs-root {
    margin-bottom: 16px;
  }
`;

export const BoxWrapper = styled(Box)`
  padding: ${({theme}) => theme.spacing(3, 4)};
  background-color: #fff;
  border-radius: 6px;
  padding-left: ${({pl}) => pl + '!important'};
  padding-top: ${({pt}) => pt + '!important'};
  padding-right: ${({pr}) => pr + '!important'};
  ${({theme}) => ({
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  })}
`;
