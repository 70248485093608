import {Box} from '@ui/MUI';
import styled from 'styled-components';

export const FiltersWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
