import RadioRange from '@common/TrainingControl/RadioRange';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {longDash} from '@src/constants';
import {SkillDto, SkillTypeDto} from '@src/types/generated';
import {ColumnDef} from '@tanstack/react-table';
import {Box, Typography} from '@ui/MUI';
import React from 'react';
import {Controller} from 'react-hook-form';

import {columns} from '../constants/table.constant';

export const toSkills = (skillTypes: SkillTypeDto[], skills: SkillDto[]) => {
  return skillTypes.map((skillType) => {
    const skill = skills.find((s) => s.skillType.id === skillType.id);
    return {startScore: skill?.startScore, endScore: skill?.endScore, skillType};
  });
};

export const createColumns = (isEditMode: boolean) => {
  const formatted: ColumnDef<SkillDto>[] = [
    ...columns,
    {
      accessorFn: (row) => row.startScore,
      header: 'Начало сезона',
      cell: (row) => {
        const value = row.getValue();
        return isEditMode && !value ? (
          <Controller
            name={`skills.${row.row.index}.startScore`}
            render={({field}) => {
              return <RadioRange isOutlined={true} max={10} {...field} />;
            }}
            shouldUnregister={true}
          />
        ) : (
          <Typography>{row.getValue() ?? longDash}</Typography>
        );
      },
    },
    {
      accessorFn: (row) => row.endScore,
      header: 'Конец сезона',
      headerStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 250, minWidth: 150},
      cellStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 250, minWidth: 150},
      cell: (row) => {
        const value = row.getValue();
        return isEditMode && !value ? (
          <Controller
            name={`skills.${row.row.index}.endScore`}
            render={({field}) => {
              return <RadioRange isOutlined={true} max={10} {...field} />;
            }}
            shouldUnregister={true}
          />
        ) : (
          <Typography>{row.getValue() ?? longDash}</Typography>
        );
      },
    },
    !isEditMode && {
      // динамика
      accessorFn: (row) => row.endScore - row.startScore,
      header: 'Динамика',
      cell: (row) => {
        const value = row.getValue() as number;
        const endScore = row.row.original.endScore;
        const getComponent = () => {
          if (value === 0 || !endScore) return <Typography>{longDash}</Typography>;
          if (value > 0) return <ArrowUpward color="success" fontSize="small" />;
          return <ArrowDownward color="error" fontSize="small" />;
        };

        return (
          <Box alignItems="center" display="flex" justifyContent="center">
            {getComponent()}
          </Box>
        );
      },
    },
  ].filter(Boolean);

  return formatted;
};
