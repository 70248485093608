import {Theme} from '@mui/material';
import {BoxProps} from '@mui/material/Box/Box';
import {CustomTypographyProps} from '@src/types/uikit/typography';
import {Box, Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import React from 'react';
import styled from 'styled-components';

type PlayerNumberAvatarProps = {
  isLoading: boolean;
  //TODO добавить dto из generated/
  student: object;
};
const circleSize = {xxs: 23, xs: 30, sm: 41};
function PlayerNumberAvatar({student, isLoading}: PlayerNumberAvatarProps) {
  return (
    <Box position="relative">
      <StyledAvatar isLoading={isLoading} src={student?.avatar} width={55} />
      <PlayerNumber height={circleSize} width={circleSize}>
        <NumberText isLoading={isLoading} p={0} preloaderWidthBetween={[10, 20]} textTransform="uppercase">
          {student?.playerNumber || '00'}
        </NumberText>
      </PlayerNumber>
    </Box>
  );
}

export default PlayerNumberAvatar;

const PlayerNumber = styled(Box)<BoxProps>`
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 #00000026;
`;

const NumberText = styled(Typography)<{theme: Theme} & CustomTypographyProps>`
  text-transform: uppercase;
  ${({theme}) => theme.typography.h2}
  ${({theme}) => ({
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.caption,
      textTransform: 'none',
    },
  })}
`;

const StyledAvatar = styled(Avatar)<{theme: Theme}>`
  ${({theme}) => ({
    [theme.breakpoints.up('xxs')]: {
      width: 55,
      height: 55,
    },
    [theme.breakpoints.up(400)]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('smallLaptop')]: {
      width: 148,
      height: 148,
    },
  })}
`;
