import {Typography} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import styled from 'styled-components';

export const GameBlock = styled.div`
  text-align: center;
  .MuiButton {
    &-root {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
`;

export const GameBlockTeamColor = styled.span`
  display: block;
  background-color: ${(props) => props.color};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-shrink: 0;

  &:last-child {
    margin-left: ${({theme}) => theme.spacing(2)};
  }
  &:first-child {
    margin-right: ${({theme}) => theme.spacing(2)};
  }
`;
export const GameBlockScore = styled(Stack)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: ${(props) => props.pt};

  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.breakpoints.down('sm')} {
    font-size: 30px;
    line-height: 37px;
  }
`;
export const Score = styled(Typography)`
  color: ${(props) => props.color};
  font-family: 'Proxima Nova', serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  &::before {
    position: absolute;
    content: '${(props) => props.OTTitle}';
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    ${({theme}) => theme.typography.body2};
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
    font-size: 32px;
    &::before {
      top: -8px;
      ${({theme}) => theme.typography.caption1};
    }
  }
`;

export const TeamName = styled(Typography)`
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 14px;
  }
`;
