import {userRoles} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/SchoolsActions';
import {loadTeachers} from '../../../redux/actions/UsersActions';
import AllStudentsPanel from '../../admin/panels/AllStudentsPanel';
import SchoolLessonsPanel from '../../lessons/panels/SchoolLessonsPanel';
import FullPagePreloader from '../../preloader/FullPagePreloader';

export default function SchoolPanel(props) {
  const {id} = props;

  const school = useSelector((state) => state.schools.schoolsMap.get(id));
  const currentUserId = useSelector((state) => state.users.currentUser.id);
  const currentUserIsTeacher = useSelector((state) => Boolean(state.users.currentUser.userRole === userRoles.teacher));
  const {name, description} = school;
  let tabs = [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTeachers());
  }, []);

  useEffect(() => {
    dispatch(actions.loadAllOpposingTeams());
  }, []);

  if (!id || !school) {
    return <FullPagePreloader visible={true} />;
  }

  if (currentUserIsTeacher) {
    tabs = [
      {
        label: 'Расписание',
        value: (
          <>
            <PageTitleDefault>{name}</PageTitleDefault>
            <SchoolLessonsPanel
              hasFilter
              className={
                currentUserIsTeacher === true ? 'SchoolLessonsPanel SchoolLessonsPanel_teacher' : 'SchoolLessonsPanel'
              }
              schoolId={id}
              teacherId={currentUserIsTeacher ? currentUserId : undefined}
            />
          </>
        ),
      },
    ];
  } else {
    tabs = [
      {
        label: 'Расписание',
        value: (
          <>
            <PageTitleDefault>{name}</PageTitleDefault>
            <SchoolLessonsPanel
              hasFilter
              schoolId={id}
              teacherId={currentUserIsTeacher === true ? currentUserId : undefined}
            />
          </>
        ),
      },
      {
        label: 'Игроки',
        value: (
          <>
            <PageTitleDefault>{name}</PageTitleDefault>
            <AllStudentsPanel
              defaultFilterData={{
                selectedSchoolId: school.id,
              }}
              titleVisible={false}
            />
          </>
        ),
      },
    ];
  }
  return (
    <Wrapper key={id}>
      <ContentPlaceholder>
        <TopPlaceholder className={!description ? 'TopPlaceholder' : 'TopPlaceholder TopPlaceholder_with-desc'}>
          <DescriptionPlaceholder>{description}</DescriptionPlaceholder>
        </TopPlaceholder>
        <TabsNew tabs={tabs} />
      </ContentPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .Wrapper {
    margin-bottom: 20px;
  }
`;

const TopPlaceholder = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 101;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    position: relative;
    top: auto;
    left: auto;
    padding: 20px 20px 0;
    text-align: left;
    margin-bottom: -50px;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }
  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

const DescriptionPlaceholder = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #fffaed;
`;

const ContentPlaceholder = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 991px) {
    position: relative;
    top: auto;
  }
`;
