import {signal} from '@preact/signals-react';

export enum States {
  EDIT = 'edit',
  VIEW = 'view',
  CREATE = 'create',
  DELETE = 'delete',
}
export const createModal = () => {
  return {
    _state: signal<States>(States.VIEW),

    _isOpen: signal<boolean>(false),

    open(state?: States) {
      if (state) {
        this._state.value = state;
      }
      return (this._isOpen.value = true);
    },

    close() {
      this._isOpen.value = false;
      this._state.value = States.VIEW;
    },

    get isOpen() {
      return this._isOpen.value;
    },

    get state() {
      return this._state.value;
    },
  };
};

export const mainModal = createModal();
