import UsersAPI from '@api/UsersAPI';
import SearchInput from '@common/SearchInput';
import {Player} from '@components/apps/A1TagAddingRosters';
import {addPlayer, deletePlayerByAvangardId} from '@components/apps/A1TagAddingRosters/utils';
import {a1tagViewModel, chooseTeamModalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {MODAL_HEAD_STYLE, MODAL_STYLE} from '@components/modules/A1TagLayout/constants';
import {CloseRounded, SearchRounded} from '@mui/icons-material';
import {Modal} from '@mui/material';
import {Box} from '@mui/system';
import {HOCKEY_ROLES_EN, longDash} from '@src/constants';
import {Button, IconButton, Typography} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import React, {useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

type A1TagAddPlayerModalProps = {
  isMainWindow?: boolean;
  isA1TagAddPlayerModalShowed: boolean;
  isHomeTeam: boolean;
  playerPosition: string;
  onClose: () => void;
};

export function A1TagAddPlayerModal({
  isMainWindow,
  isA1TagAddPlayerModalShowed,
  isHomeTeam,
  playerPosition,
  onClose,
}: A1TagAddPlayerModalProps) {
  const [searchString, setSearchString] = useState('');

  const [searchedPlayers, setSearchedPlayers] = useState([]);

  const clearSearch = () => {
    setSearchString('');
    setSearchedPlayers([]);
  };

  const payload = chooseTeamModalViewModel?.payload?.value ?? {};
  const payloadForMainWindow = {...modalViewModel.payload.value, rosters: a1tagViewModel?.rosters?.value || []};

  const getPlayersBySearch = async (searchString: string, playerPosition: string) => {
    const players = await UsersAPI.getStudentsV2({
      active: true,
      hockeyRole: playerPosition,
      q: searchString,
      page: 1,
      limit: 999,
    });
    setSearchedPlayers(players?.result || []);
  };

  useEffect(() => {
    if (searchString?.length > 0 && playerPosition) {
      getPlayersBySearch(searchString, playerPosition);
    } else {
      setSearchedPlayers([]);
    }
  }, [searchString, playerPosition]);

  const [debouncedSearch] = useDebouncedCallback(
    (a) => {
      setSearchString(a);
    },
    200,
    [],
  );
  return (
    <Modal
      open={isA1TagAddPlayerModalShowed}
      onClose={() => {
        onClose();
        clearSearch();
      }}
    >
      <Box sx={{...MODAL_STYLE, maxWidth: 426}}>
        <Box sx={MODAL_HEAD_STYLE}>
          <Typography color={black[75]} textTransform="uppercase" variant="overline2">
            Adding a {HOCKEY_ROLES_EN?.[playerPosition] || 'player'}
          </Typography>
          <IconButton
            sx={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}}
            onClick={() => {
              onClose();
              clearSearch();
            }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={{p: 3, height: 464, overflowY: 'auto'}}>
          <Box mb={3} position="relative">
            <SearchInput
              InputProps={{
                sx: {
                  borderColor: black[30],
                  color: white.main,
                  '&::placeholder': {color: black[60]},
                },
              }}
              inputProps={{variant: 'outlined'}}
              placeholder="Search surname"
              onChange={(value) => {
                debouncedSearch(value);
              }}
            />
            <SearchRounded sx={{position: 'absolute', top: 8.5, right: 14, width: 24, height: 24, color: black[60]}} />
          </Box>
          {!searchedPlayers?.length && searchString?.length ? (
            <Typography color={black[60]} marginTop="158px" sx={{textAlign: 'center'}} variant="body1">
              No results
            </Typography>
          ) : (
            <>
              {searchedPlayers?.map((player: Player) => {
                const players = isHomeTeam ? payload?.homeTeam?.players : payload?.awayTeam?.players;
                const isSearchedPlayerInTeam = players
                  ?.map(({avangardId}: {avangardId: string | number}) => avangardId)
                  ?.includes(player?.avangardId);
                return (
                  <Box
                    key={player?.id}
                    p={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: 1,
                      backgroundColor: black[15],
                      '&:not(:last-child)': {mb: 1},
                    }}
                  >
                    <Box>
                      <Typography sx={{mb: 0, color: black[75]}} variant="subtitle1">
                        {player?.lastName} {player?.firstName}
                      </Typography>
                      <Typography sx={{mt: 0, color: black[60]}} variant="caption2">
                        {player?.group?.name || longDash}
                      </Typography>
                    </Box>
                    {!isSearchedPlayerInTeam ? (
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          addPlayer({
                            isMainWindow: isMainWindow,
                            isHomeTeam: isHomeTeam,
                            payload: isMainWindow ? payloadForMainWindow : payload,
                            player: player,
                          });
                          onClose();
                        }}
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          deletePlayerByAvangardId({
                            isMainWindow: isMainWindow,
                            isHomeTeam: isHomeTeam,
                            payload: payload,
                            avangardId: player?.avangardId,
                          });
                          onClose();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
