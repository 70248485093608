import {formatStatsFieldPlayers, formatStatsGoalies} from '@components/A1Table/helpers/table.helper';
import {useCancelSubstitution} from '@components/A1Table/hooks/table.hooks';
import {a1TableViewModel} from '@components/A1Table/models/table.view-mode';
import {downloadContentAsCSVFile} from '@components/apps/A1TagMainWindow/helpers';
import {a1tagViewModel, deleteStatisticsByIds} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {Delete, Save} from '@mui/icons-material';
import {Button, Stack} from '@mui/material';
import {signal} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import Dialog from '@ui/MUI/Modals/Dialog/Dialog';
import React from 'react';
// move to shared
const createModal = () => {
  return {
    _isOpen: signal<boolean>(false),

    open() {
      this._isOpen.value = true;
    },

    close() {
      this._isOpen.value = false;
    },

    get isOpen() {
      return this._isOpen.value;
    },
  };
};

const dialog = createModal();
export const TableButtons = () => {
  useSignals();
  const selectedRows = a1TableViewModel.selectedRows;

  const {homeTeam, awayTeam} = a1tagViewModel?.rosters?.value ?? {};
  const substitutes = [
    ...(homeTeam ? formatStatsFieldPlayers(homeTeam) : []),
    ...(homeTeam ? formatStatsGoalies(homeTeam) : []),
    ...(homeTeam ? formatStatsFieldPlayers(awayTeam) : []),
    ...(homeTeam ? formatStatsGoalies(awayTeam) : []),
  ];

  const {cancelSubstitution} = useCancelSubstitution();

  const handleDelete = () => {
    const ids = selectedRows.map((row) => row.id);
    dialog.close();
    deleteStatisticsByIds(ids);
    a1TableViewModel.removeSelectedRowsByIds(ids);
    selectedRows?.forEach((row) => {
      if (row?.action_name?.includes('substitution')) {
        cancelSubstitution(row);
      }
    });
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        disabled={!a1tagViewModel.statistics.value.length}
        size="medium"
        startIcon={<Save />}
        variant="contained"
        onClick={() => {
          if (selectedRows.length > 0) {
            const selectedRowsIds = selectedRows.map((row) => row.id);
            const selectedStats = a1tagViewModel.statistics.value.filter((stat) => selectedRowsIds.includes(stat.id));

            downloadContentAsCSVFile([...substitutes, ...selectedStats]);
            return;
          }
          downloadContentAsCSVFile([...substitutes, ...a1tagViewModel.statistics.value]);
        }}
      >
        Save
      </Button>
      <>
        <Button
          disabled={!selectedRows.length}
          size="medium"
          startIcon={<Delete />}
          variant="contained"
          onClick={() => {
            dialog.open();
          }}
        >
          Delete
        </Button>
      </>

      <Dialog
        actionText="Удалить"
        content="Вы уверены, что хотите удалить выбранные элементы?"
        open={dialog.isOpen}
        title="Подтверждение"
        onActionButtonClick={handleDelete}
        onCloseDefault={() => dialog.close()}
      />
    </Stack>
  );
};
