import ReactTableComponent from '@common/ReactTableComponent';
import {NoData} from '@components/modules/prospects/components/NoData';
import {useProspectGoals} from '@components/modules/prospects/queries/prospect-goals.query';
import {colors, Stack, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Box, DatePicker} from '@ui/MUI';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';

import {goalsTableColumns} from '../constants/table.constant';
import {goalsHistoryModel} from '../models/history.model';

const Filters = () => {
  useSignals();
  const filters = goalsHistoryModel.filters;

  return (
    <Stack
      alignItems="center"
      borderRadius={2}
      direction="row"
      justifyContent="space-between"
      px={4}
      py={3}
      sx={{background: 'white'}}
    >
      <Stack direction="row" spacing={4}>
        <Box maxWidth={160}>
          <DatePicker
            label="Дата поставновки"
            value={filters.startDate}
            onChange={(v) => {
              filters.updateStartDate(v as string);
            }}
          />
        </Box>
        <Box maxWidth={140}>
          <DatePicker
            label="Дата закрытия"
            value={filters.endDate}
            onChange={(v) => {
              filters.updateEndDate(v as string);
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const GoalsTable = () => {
  useSignals();
  const filters = goalsHistoryModel.filters;
  const {playerId} = useParams<{playerId: string}>();
  const date = filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : null;
  const finalDate = filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null;
  const prospectGoalsQuery = useProspectGoals(playerId, {
    isCompleted: true,
    date,
    finalDate,
  });
  const data = prospectGoalsQuery.data ?? [];

  if (!data.length) return <NoData />;

  return <ReactTableComponent columns={goalsTableColumns} data={data} />;
};

export const GoalsHistory = () => {
  return (
    <Stack spacing={3}>
      <Typography color={colors.grey[50]} variant="h3">
        история целей
      </Typography>
      <Stack spacing={1}>
        <Filters />
        <GoalsTable />
      </Stack>
    </Stack>
  );
};
