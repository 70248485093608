import {black} from '@ui/MUI/colorsA1Tag';
import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-width: 1920px;
  margin: 0px auto;
  overflow: auto;
  background-color: ${black[8]};
`;

export const Head = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({theme}) => theme.spacing(0, 1)};
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  margin-right: ${({theme}) => theme.spacing(0)};
  margin-left: 0;
  width: 32px;
  height: 32px;
`;

export const Menu = styled.div`
  position: relative;
  padding: ${({theme}) => theme.spacing(0, 1)};
  display: flex;
  gap: ${({theme}) => theme.spacing(0)};
  border-top: 1px solid ${black[20]};
  border-bottom: 1px solid ${black[20]};
`;

export const AdditionalMenuBlock = styled.div`
  margin-left: auto;
`;
