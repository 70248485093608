import LoadingBox from '@common/LoadingBox';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {WrapperTableContainer} from '@components/apps/PhysicalTesting/components/CompareStages/components/CompareStagesTable/CompareStagesTable';
import {mock} from '@components/apps/PhysicalTesting/components/CompareStages/components/mock';
import {
  numberSign,
  ofpTestsExercisesOrdersMatrix,
  sfpTestsExercisesOrdersMatrix,
  vectors,
} from '@components/apps/PhysicalTesting/constants';
import {sortColumn} from '@components/apps/PhysicalTesting/helpers';
import {fetchOverallMapper} from '@components/apps/PhysicalTesting/helpers/mappers/fetchOverallMapper';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';

const isNumberNegative = (number) => {
  if (!number) {
    return numberSign.zero;
  }
  if (number > 0) {
    return numberSign.positive;
  }
  return numberSign.negative;
};

const CompareStagesTable = ({filters, tableData, isLoading}) => {
  const history = useHistory();
  const [isToggled, setToggleUser] = useState(false);
  const [sorting, setSorting] = useState([{id: 'player', asc: true}]);
  const exercises = tableData
    ?.map((item) => item.params)
    ?.flat()
    .reduce((a, c) => (a.map((e) => e.name).includes(c.name) || a.push(c), a), []);
  const sortedExercises = exercises
    ?.map((ex) => ({
      ...ex,
      order: ofpTestsExercisesOrdersMatrix[ex?.exercise] || sfpTestsExercisesOrdersMatrix[ex?.exercise],
    }))
    ?.sort((a, b) => a.order - b.order);
  const columns = useMemo(
    () => [
      {
        header: (
          <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
            Игрок
          </ToggledHeader>
        ),
        headerStyle: {
          '.MuiBox-root': {
            display: 'flex',
            boxShadow: 'none',
            padding: '0px',
          },
        },
        id: 'player',
        accessorKey: 'lastName',
        enableRowSpan: true,
        sticky: {left: true},
        columns: [
          {
            header: 'Not visible header',
            accessorFn: ({params, ...data}) => {
              return data;
            },
            headerStyle: {display: 'none !important'},
            cellStyle: {height: 'auto !important'},
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{cursor: 'pointer'}}
                  onClick={() => (student?.playerId ? history?.push(`/student/${student?.playerId}`) : void 0)}
                >
                  <PlayersHorizontalCard isLoading={isLoading} onlyNumber={isToggled} student={student} />
                </Stack>
              );
            },
          },
        ],
        rowSpan: '2',
        sortingFn: sortColumn,
      },
      ...(isLoading ? mock.exercises : sortedExercises).map((param) => {
        return {
          id: param.exercise,
          header: (
            <Typography
              fontWeight={600}
              isLoading={isLoading}
              preloaderWidthBetween={[120, 140]}
              sx={{zIndex: 2}}
              variant="caption1"
            >
              {param.name}
            </Typography>
          ),
          headerStyle: {
            height: 'auto !important',
            textAlign: 'center',
          },
          cellStyle: {
            height: 'auto !important',
            textAlign: 'center',
          },
          columns: [
            {value: 'valueForComparison', label: `${filters?.season?.value} (этап ${filters.stage?.value})`},
            {
              value: 'value',
              label: `${filters.stageSeasons?.value?.season} (этап ${filters.stageSeasons?.value?.stage})`,
            },
            {value: 'delta', label: 'измен.'},
          ].map(({label, value}) => {
            return {
              accessorFn: ({params}) => {
                return params[param.exercise];
              },
              id: `params.${param?.exercise}.${value}`,
              header: label,
              headerStyle: {
                height: 'auto !important',
                boxShadow:
                  value === 'delta'
                    ? 'rgb(224, 224, 224) -1px -1px 0px 0px inset !important'
                    : 'rgb(224, 224, 224) 0px -1px 0px 0px inset !important',
                textAlign: 'center',
              },
              cellStyle: {
                height: 'auto !important',
                boxShadow: value === 'delta' ? 'rgb(224, 224, 224) -1px 0px 0px 0px inset !important' : 'none',
                textAlign: 'center',
              },
              cell: ({getValue}) => {
                const cellData = getValue();
                return (
                  (
                    <Box
                      component="span"
                      sx={{color: value === 'delta' ? isNumberNegative(cellData?.delta) : 'inherit'}}
                    >
                      <Typography isLoading={isLoading} preloaderWidthBetween={[40, 50]} variant="body2">
                        {cellData?.[value] || longDash}
                      </Typography>
                    </Box>
                  ) || '-'
                );
              },
            };
          }),
        };
      }),
      {
        header: 'Общий вектор',
        id: 'vector',
        accessorKey: 'vector',
        enableRowSpan: true,
        sticky: {right: true},
        columns: [
          {
            header: 'Not visible header2',
            accessorFn: ({params, ...data}) => {
              return data;
            },
            headerStyle: {
              display: 'none !important',
            },
            cellStyle: {
              height: 'auto !important',
              textAlign: 'center',
            },
            sticky: {right: true},
            cell: (params) => {
              const vectorsMatrix = {
                [vectors.equal.value]: '=',
                [vectors.bigger.value]: '+',
                [vectors.less.value]: longDash,
              };
              return (
                <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} sx={{zIndex: 1}} variant="body2">
                  {vectorsMatrix[params?.getValue()?.vector] || ''}
                </Typography>
              );
            },
          },
        ],
        rowSpan: '2',
        sortingFn: sortColumn,
      },
    ],
    [tableData, isLoading],
  );
  return (
    <WrapperTableContainer canEdit data-testid="report-table">
      <LoadingBox isLoading={isLoading}>
        <ReactTableComponent
          columns={columns}
          data={isLoading ? mock.data : fetchOverallMapper(tableData)}
          isAvailableHeight={true}
          setSorting={setSorting}
          sorting={sorting}
          tableBodyCellProps={{
            sx: {
              py: {xxs: 1, lg: 3},
              px: {xxs: 2, lg: 4},
              '&.lastRowCell': {
                boxShadow: 'none',
              },
            },
          }}
          tableHeaderCellProps={{
            sx: {
              py: {xxs: '0', lg: 3},
              px: {xxs: 2, lg: 4},
            },
          }}
        />
      </LoadingBox>
    </WrapperTableContainer>
  );
};

CompareStagesTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  filters: PropTypes.shape({
    group: PropTypes.string,
    school: PropTypes.string,
    season: PropTypes.string,
    stage: PropTypes.string,
    stageSeasons: PropTypes.shape({
      season: PropTypes.string,
      stage: PropTypes.string,
    }),
    typeOfTests: PropTypes.string,
  }).isRequired,
};

export default CompareStagesTable;
