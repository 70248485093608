import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {getZone} from '@components/A1TagEventModal/utils';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {coords, maxX, maxY, updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {FormGroup} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsA1Tag';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import RadioGroup from '@ui/MUI/RadioGroup';
import Switch from '@ui/MUI/Switch';
import React from 'react';

const types = [
  {label: 'None', value: 'None'},
  {label: 'Boarding', value: 'Boarding'},
  {label: 'Contact to the head', value: 'Contact to the head'},
  {label: 'Delay of game', value: 'Delay of game'},
  {label: 'Roughing', value: 'Roughing'},
  {label: 'High sticking', value: 'High sticking'},
  {label: 'Holding', value: 'Holding'},
  {label: 'Interference', value: 'Interference'},
  {label: 'Unsportsmanlike conduct', value: 'Unsportsmanlike conduct'},
  {label: 'Elbowing', value: 'Elbowing'},
  {label: 'Charging', value: 'Charging'},
  {label: 'Cross-checking', value: 'Cross-checking'},
  {label: 'Slashing', value: 'Slashing'},
  {label: 'Obstruction', value: 'Obstruction'},
  {label: 'Kneeing', value: 'Kneeing'},
  {label: 'Holding the stick', value: 'Holding the stick'},
  {label: 'Hooking', value: 'Hooking'},
  {label: 'Tripping', value: 'Tripping'},
];

const time = [
  {label: 'None', value: 0},
  {label: 'Minor', value: 2},
  {label: 'Double minor', value: 4},
  {label: 'Major', value: 5},
];

const misconduct = [
  {label: 'None', value: 0},
  {label: '10 minutes', value: 10},
  {label: 'Match', value: 60},
];

export function A1TagPenaltyModalContent() {
  useSignals();

  const {currentTime} = useCurrentTime();

  const payload = modalViewModel.payload.value ?? {};

  const {x, y} = coords.getCoords();

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const homePlayersOptions =
    uniqHomePlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const awayPlayersOptions =
    uniqAwayPlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const isSelectedPlayerHome = homePlayersOptions?.map((option) => option.value)?.includes(payload?.player?.avangardId);

  return (
    <Box>
      <Box sx={{m: -4, pb: 1, borderBottom: `1px solid ${black[25]}`}}>
        <Box pt={2} px={4} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={280} pl={2}>
            <Autocomplete
              multiple={false}
              options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
              size="small"
              sx={{
                width: '100%',
                '.MuiAutocomplete-inputRoot': {
                  minHeight: '28px',
                },
              }}
              textFieldVariant="outlined"
              value={payload?.player ?? null}
              width="100%"
              onChange={(_, v: {value: string; label: string}) => {
                const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };
                updatePayload({
                  ...payload,
                  player: v ? merged : null,
                });
              }}
            />
          </Box>
          <Typography sx={{px: 1, color: black[60]}} variant="subtitle2">
            Fouled
          </Typography>
          <Box minWidth={250}>
            <Autocomplete
              multiple={false}
              options={isSelectedPlayerHome ? awayPlayersOptions : homePlayersOptions}
              size="small"
              sx={{
                width: '100%',
                '.MuiAutocomplete-inputRoot': {
                  minHeight: 28,
                },
              }}
              textFieldVariant="outlined"
              value={payload?.opponent ?? null}
              width="100%"
              onChange={(_, v: {value: string; label: string}) => {
                const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                const merged = {
                  ...v,
                  ...player,
                };
                updatePayload({
                  ...payload,
                  opponent: v ? merged : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{m: -4}}>
        <Box p={4}>
          <Box display="flex" gap={2} sx={{pb: 0, borderBottom: `1px solid ${black[25]}`}}>
            <Typography color={grey[54]} pb={1} pt={0} variant="subtitle2">
              Type
            </Typography>
            <RadioGroup
              checkboxes={types}
              color="primary"
              formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
              name="type"
              size="small"
              sx={{
                maxHeight: 156,
                '.MuiRadio-root': {
                  py: 0,
                },
              }}
              value={payload.penalty_index_text ?? null}
              onChange={(_, v) => {
                updatePayload({...payload, penalty_index_text: v ? v : null});
              }}
            />
          </Box>
          <Box sx={{borderBottom: `1px solid ${black[25]}`}}>
            <Box display="flex" gap={2}>
              <Box sx={{pr: 2, borderRight: `1px solid ${black[25]}`}}>
                <Typography color={grey[54]} pt={0} variant="subtitle2">
                  Time
                </Typography>
                <RadioGroup
                  checkboxes={time}
                  color="primary"
                  formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
                  name="type"
                  row={true}
                  size="small"
                  sx={{maxHeight: 156}}
                  value={payload?.penalty_time ?? null}
                  onChange={(_, v) => {
                    updatePayload({...payload, penalty_time: v ? v : null});
                  }}
                />
              </Box>
              <Box sx={{pl: 2}}>
                <Typography color={grey[54]} pt={0} variant="subtitle2">
                  Misconduct
                </Typography>
                <RadioGroup
                  checkboxes={misconduct}
                  color="primary"
                  formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
                  name="type"
                  row={true}
                  size="small"
                  sx={{maxHeight: 156}}
                  value={payload?.penalty_add_time ?? null}
                  onChange={(_, v) => {
                    updatePayload({...payload, penalty_add_time: v ? v : null});
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box pt={0} px={1}>
            <FormGroup row sx={{gap: 4}}>
              <FormControlLabel
                control={<Switch checked={false} name="penalty_shot" size="small" onChange={() => {}} />}
                label="Penalty shot"
                slotProps={{typography: {variant: 'body2', sx: {color: black[75]}}}}
              />
              <FormControlLabel
                control={<Switch checked={false} name="pk" size="small" onChange={() => {}} />}
                label="PK"
                slotProps={{typography: {variant: 'body2', sx: {color: black[75]}}}}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={payload?.has_another_player || false}
                    name="another_player"
                    size="small"
                    onChange={(_, v) => {
                      updatePayload({...payload, has_another_player: v ? v : false});
                    }}
                  />
                }
                label="Another player"
                slotProps={{typography: {variant: 'body2', sx: {color: black[75]}}}}
              />
              {payload?.has_another_player && (
                <Box minWidth={280}>
                  <Autocomplete
                    multiple={false}
                    options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
                    size="small"
                    sx={{
                      width: '100%',
                      '.MuiAutocomplete-inputRoot': {
                        minHeight: 28,
                      },
                    }}
                    textFieldVariant="outlined"
                    value={payload.another_player ?? null}
                    width="100%"
                    onChange={(_, v: {value: string; label: string}) => {
                      const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                      const merged = {
                        ...v,
                        ...player,
                      };
                      updatePayload({
                        ...payload,
                        another_player: v ? merged : null,
                      });
                    }}
                  />
                </Box>
              )}
            </FormGroup>
            <Box sx={{position: 'relative'}}>
              <Box
                px={4}
                py={1}
                sx={{
                  position: 'relative',
                  maxWidth: 550,
                }}
              >
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '45%',
                    left: -24,
                    transform: 'translateY(-50%) rotate(-90deg)',
                    mb: 2,
                    color: black[75],
                    textTransform: 'uppercase',
                  }}
                  variant="overline2"
                >
                  {homeTeam?.name}
                </Typography>
                <ArenaSchema
                  eclipses={coords.getEclipses()}
                  onClick={(e) => {
                    const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                    const eclipse = {
                      realX,
                      realY,
                      x: cartesianX,
                      y: cartesianY,
                    };
                    coords.updateEclipses([eclipse]);
                    const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                    const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                    if (!zone?.length) {
                      updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY});
                    } else {
                      updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY, zone_name: zone});
                    }
                  }}
                  onMouseMove={(e) => {
                    const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                    coords.updateCoords(cartesianX, cartesianY);
                  }}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '45%',
                    right: -24,
                    transform: 'translateY(-50%) rotate(90deg)',
                    mb: 2,
                    color: black[75],
                    textTransform: 'uppercase',
                  }}
                  variant="overline2"
                >
                  {awayTeam?.name}
                </Typography>
                <Typography sx={{pb: 0, textAlign: 'center'}} variant="subtitle1">
                  ({x.toFixed(1)}, {y.toFixed(1)})
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
