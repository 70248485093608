import {
  cleanPsychotestProfile,
  loadPlayerPsychoTestingForm,
  postPlayerPsychoTestingForm,
} from '@actions/PsychoTestingActions';
import {useStudent} from '@hooks/filters';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import {UserRoles} from '@src/types';
import {Autocomplete, Box, Button, Stack} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import PsychoTestingAPI from '../../../api/PsychoTestingAPI';
import LocalPreloader from '../../preloader/LocalPreloader';
import PsychoTestFormItem from '../tools/PsychoTestFormItem';

const Filters = ({filter, setFilter}) => {
  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [UserRoles.DOCTOR, UserRoles.PSYCHO],
  });
  const groups = useGroupsV2({schoolIds: filter?.school?.value ? [filter?.school?.value] : []});
  const students = useStudent({connectorValue: filter?.group?.value});

  const [isLoadingReportDates, setIsloadingReportDates] = useState(false);
  const [reportDatesOptions, setReportDatesOptions] = useState([]);

  useEffect(() => {
    if (filter?.student?.value) {
      setIsloadingReportDates(true);
      PsychoTestingAPI.getPlayerReportDates({student: filter?.student?.value}).then((data) => {
        setReportDatesOptions(data.data?.dates.map((date) => ({label: date, value: date})) || []);
        setIsloadingReportDates(false);
      });
    }
  }, [filter?.student?.value]);
  return (
    <Box
      mb={1}
      px={{xxs: 2, sm: 4}}
      py={{xxs: 1, sm: 3}}
      sx={{backgroundColor: '#fff', borderRadius: '6px', overflow: 'hidden'}}
    >
      <Stack alignItems="flex-end" direction="row" flexWrap={{xxs: 'wrap', sm: 'nowrap'}} gap={3}>
        <Box width={{xxs: '100%', sm: 250}}>
          <Autocomplete
            disabled={schools?.isDisabled || groups?.isLoading}
            isLoading={schools?.isLoading}
            label="Школа"
            multiple={false}
            options={schools.options}
            value={filter?.school}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                school: v,
                group: '',
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 250}}>
          <Autocomplete
            disabled={groups?.isDisabled || schools?.isLoading}
            isLoading={groups.isLoading}
            label="Команда"
            multiple={false}
            options={groups.options}
            value={filter?.group}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                group: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 250}}>
          <Autocomplete
            disabled={schools?.isDisabled || groups?.isDisabled || students?.isDisabled}
            isLoading={students.isLoading}
            label="Игрок"
            multiple={false}
            options={students.options}
            value={filter?.student}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                student: v,
              }));
            }}
          />
        </Box>
        {filter?.student?.value && (
          <Box width={{xxs: '100%', sm: 250}}>
            <Autocomplete
              disabled={students?.isDisabled || isLoadingReportDates}
              isLoading={isLoadingReportDates}
              label="Дата отчета"
              multiple={false}
              options={reportDatesOptions}
              value={filter?.report_dates}
              onChange={(__, v) => {
                setFilter((prevState) => ({
                  ...prevState,
                  report_dates: v,
                }));
              }}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default function IndividualPlanEstimationPanel() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    group: null,
    school: null,
    student: null,
    report_dates: null,
  });

  useEffect(() => {
    dispatch(cleanPsychotestProfile());
  }, []);

  const loading = useSelector((state) => state.psychoTesting.loading);
  const testingForm = useSelector((state) => state.psychoTesting.testingForm || {});

  useEffect(() => {
    if (filter?.report_dates?.value && filter?.student?.value) {
      const request = {
        playerId: filter?.student?.value,
        dates: filter?.report_dates?.value,
      };
      dispatch(loadPlayerPsychoTestingForm(request));
    }
  }, [filter?.report_dates?.value, filter?.student?.value]);

  const formDataValidation = () => {
    dispatch(postPlayerPsychoTestingForm(filter, testingForm));
  };

  const getTabs = () => {
    let content = (
      <TableContent loading={loading}>
        <LocalPreloader visible={loading} />
        <TableHeadline>
          <TableColumn>
            <ColumnName>Навык психологического профиля</ColumnName>
          </TableColumn>
          <TableColumn>
            <ColumnName>Оценка</ColumnName>
          </TableColumn>
        </TableHeadline>

        <TableBody>
          {testingForm.parameters?.map((skill, index) => {
            return <PsychoTestFormItem filter={filter} key={index} skill={skill} skillIndex={index} tab={'default'} />;
          })}
        </TableBody>

        <BottomPanel>
          <Buttons>
            <ButtonsBlock>
              <Button
                color="primary"
                mr={3}
                variant="contained"
                onClick={() => {
                  formDataValidation();
                }}
              >
                Сохранить
              </Button>
            </ButtonsBlock>
            <ButtonsBlock>
              <Button
                color="primary"
                onClick={() => {
                  history.push('/');
                }}
              >
                Закрыть
              </Button>
            </ButtonsBlock>
          </Buttons>
        </BottomPanel>
      </TableContent>
    );
    return content;
  };

  return (
    <Wrapper>
      <PageTitleDefault text="Психологический профиль" />
      <Filters filter={filter} setFilter={setFilter} />

      {Object.values(testingForm).length ? (
        getTabs()
      ) : (
        <TableEmptyBlock text={'Недостаточно данных. Для отображения формы выберите школу, команду и игрока.'} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .TabNamesItems {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-left: 34px;
    background: #fffaed;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px 6px 0px 0px;
    .TabName {
      margin-right: 24px;
      color: #990011;
      text-transform: uppercase;
    }
    @media screen and (max-width: 991px) {
      padding-top: 10px;
    }
  }
  .Tab {
    .ContentPlaceholder {
      width: 100%;
    }
  }
`;

const TableContent = styled.div`
  position: relative;
  max-height: ${(props) => (props.loading ? '55vh' : 'none')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  border-radius: ${(props) => (props.loading ? '6px' : 0)};
`;

const TableHeadline = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 30px;
  background-color: white;
  border-top: 1px solid #dddad4;
  border-bottom: 1px solid #dddad4;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 576px) {
    display: none;
  }
`;
const TableColumn = styled.div`
  box-sizing: border-box;
  padding-left: 34px;
  padding-right: 30px;
  &:last-child {
    padding-left: 0;
  }
  &:first-child {
    flex: 0 0 30%;
    max-width: 30%;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
  &:nth-child(2) {
    flex: 0 0 70%;
    max-width: 70%;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
`;
const ColumnName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/30px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
  @media screen and (max-width: 768px) {
    line-height: 14px;
  }
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 18px 0 34px;
  margin: 0 -30px;
  background-color: #fafafa;

  :nth-child(even) {
    background-color: white;
  }
`;
const TableBody = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  ${TableRow} {
    ${TableColumn} {
      margin-bottom: 14px;
    }
  }
`;
const BottomPanel = styled.div`
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
`;
const ButtonsBlock = styled.div`
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Buttons = styled.div`
  padding: 10px 20px;
  padding-left: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding: 20px 20px 5px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  > ${ButtonsBlock} ${Button} {
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 220px;
      margin-right: 0 !important;
    }
  }
`;
