import {Filter, filtersViewModel, updateFilter} from '@components/A1Table/models/filters.view-model';
import {allPlayers} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, TextField} from '@ui/MUI';
import React from 'react';

export const PlayerFilter = () => {
  useSignals();
  const {player} = filtersViewModel.filters;
  const options = allPlayers.value.map((player) => ({
    label: `${player?.lastName} ${player?.firstName}`,
    value: player?.avangardId,
  }));
  return (
    <Box width={220}>
      <Autocomplete
        multiple={false}
        options={options}
        renderInput={(params) => <TextField {...params} label="Player" variant="outlined" />}
        value={player.value}
        width="100%"
        onChange={(_, v) => updateFilter('player', v as Filter)}
      />
    </Box>
  );
};
