import {getPlayerScoutReportStatistic, getPlayerTeamsForAllCareerForScoutReport} from '@api/ScoutReportAPI';
import ReactTableComponent from '@common/ReactTableComponent';
import PlayerInfo from '@components/apps/PlayerPersonalFile/components/MainInfo/components/PlayerInfo';
import {TitleWrapper} from '@components/apps/PlayerPersonalFile/components/TrainerReport/TrainerReport';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import useStore from '@hooks/useStore';
import {longDash} from '@src/constants';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {HockeyRoles} from '@src/types/student';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import Switch from '@ui/MUI/Switch';
import TabsNew from '@ui/MUI/TabsNew';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useEffect, useMemo, useState} from 'react';

import ScoutReport from './components/ScoutReport';

function TrainerReport() {
  const [isLoading, setIsLoading] = useState(false);

  const {student, loading} = useStore((state) => state.users);
  const isGoaltender = student.hockeyRole === HockeyRoles.goaltender;

  const statsColumnsForFieldPlayers = useMemo(
    () => [
      {
        accessorKey: 'season',
        header: 'Сезон',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography fontWeight={700} isLoading={isLoading} preloaderWidthBetween={[30, 50]} variant="subtitle2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'games',
        header: 'Игры',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'toi',
        header: 'Время',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'goals',
        header: 'Голы',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'assists',
        header: 'Пасы',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'score',
        header: 'Очки',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'scoreMajority',
        header: '% очков в блш',
        headerStyle: {maxWidth: 128},
        cellStyle: {maxWidth: 128},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[40, 60]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'plusMinus',
        header: '+/-',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'corsiForPercent',
        header: 'CORSI',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[35, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
    ],
    [isLoading],
  );

  const statsColumnsForGoaltenders = useMemo(
    () => [
      {
        accessorKey: 'season',
        header: 'Сезон',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88, fontWeight: '700 !important'},
        cell: ({getValue}) => {
          return (
            <Typography fontWeight={700} isLoading={isLoading} preloaderWidthBetween={[30, 50]} variant="subtitle2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'games',
        header: 'Игры',
        headerStyle: {maxWidth: 88},
        cellStyle: {maxWidth: 88},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'shotsOnGoal',
        header: 'Отраж. броски',
        headerStyle: {maxWidth: 180},
        cellStyle: {maxWidth: 180},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'concededGoals',
        header: 'Пропущ. голы',
        headerStyle: {maxWidth: 130},
        cellStyle: {maxWidth: 130},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'savesPercent',
        header: '% отраженных бросков',
        headerStyle: {maxWidth: 200},
        cellStyle: {maxWidth: 200},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'averageGoalsAgainst',
        header: 'Ср. кол-во голов за игру',
        headerStyle: {
          maxWidth: 152,
        },
        cellStyle: {maxWidth: 152},
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[25, 50]} variant="body2">
              {getValue() || longDash}
            </Typography>
          );
        },
      },
    ],
    [isLoading],
  );

  const [teams, setTeams] = useState([]);

  const tabs =
    teams?.map((team) => ({
      id: team?._id,
      label: team?.name,
    })) || [];

  const [isTopThree, setIsTopThree] = useState(false);

  useEffect(() => {
    if (student?.id) {
      setIsLoading(true);
      getPlayerTeamsForAllCareerForScoutReport(student?.id)
        .then((res) => {
          setTeams(res?.data);
        })
        .catch((err) => {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], err.response.data.message);
        })
        .finally(() => setIsLoading(false));
    }
  }, [student?.id]);

  const {value, onChange} = useQueryStringTabs({tabs: tabs, queryKey: 'subTab'});

  const [reportsList, setReportsList] = useState([]);

  const formattedReportsList =
    reportsList
      ?.map((item) => {
        return item?.tournaments?.map((tournament) => {
          return {...tournament, season: item.season};
        });
      })
      ?.flat() || [];

  useEffect(() => {
    if (student?.id && teams?.[value]?._id) {
      setIsLoading(true);
      getPlayerScoutReportStatistic({
        playerId: student?.id,
        params: {groupId: teams?.[value]?._id, isTopThree: isTopThree},
      })
        .then((res) => {
          setReportsList(res?.data || []);
        })
        .catch((err) => {
          if (err.response.status !== 404) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], err.response.data.message);
          }
          setReportsList([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [student?.id, teams?.[value]?._id, isTopThree]);

  return (
    <Stack direction="column" gap={3}>
      <Stack direction={{xxs: 'column', sm: 'row'}} gap={3}>
        <Box>
          <PlayerInfo isLoading={loading} student={student} />
        </Box>

        <Wrapper p="0" position="relative" width="fill-available">
          <TitleWrapper alignItems="center" display="flex" justifyContent="space-between" px={3} py={3}>
            <Typography mr={2} variant="h4">
              Статистика
            </Typography>
            <Box
              sx={{
                '& .MuiTabs-root': {
                  paddingTop: '0px !important',
                  '.MuiTabScrollButton-root': {
                    color: 'rgba(0, 0, 0, 0.87) !important',
                  },
                },
                '& .TabsWrapper': {
                  pl: 3,
                  maxWidth: {xl: 'none', lg: 400},
                  borderLeft: `1px solid ${grey[24]}`,
                  '.MuiBox-root >  .MuiBox-root': {
                    paddingBottom: '0px !important',
                  },
                },
              }}
            >
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isTopThree}
                      color="primary"
                      size="small"
                      sx={{mx: '0px'}}
                      onChange={(_, v) => {
                        setIsTopThree(v);
                      }}
                    />
                  }
                  label={<Typography variant="body2">Против ТОП 3</Typography>}
                  sx={{mx: '0px', pr: 3, whiteSpace: 'nowrap'}}
                />
                <TabsNew
                  withoutWrapper
                  sx={{'.MuiTab-root': {color: 'rgba(0, 0, 0, 0.87) !important'}}}
                  tabs={tabs}
                  value={value}
                  variant="scrollable"
                  onChange={onChange}
                />
              </Box>
            </Box>
          </TitleWrapper>
          <Box height="100%">
            {!formattedReportsList?.length ? (
              <Typography
                left="50%"
                position={{xxs: 'relative', sm: 'absolute'}}
                py={4}
                sx={{transform: 'translate(-50%, 0%)'}}
                textAlign="center"
                top="50%"
                variant="subtitle1"
              >
                Нет данных
              </Typography>
            ) : (
              <ReactTableComponent
                columns={!isGoaltender ? statsColumnsForFieldPlayers : statsColumnsForGoaltenders}
                customScrollBarHeight={190}
                data={formattedReportsList}
                tableBodyCellProps={{
                  sx: {px: 3, boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset', textAlign: 'center'},
                }}
                tableHeaderCellProps={{
                  sx: {
                    px: 3,
                    boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
                    textAlign: 'center',
                    whiteSpace: {lg: 'normal !important', smallLaptop: 'wrap !important'},
                    overflow: {lg: 'initial !important', smallLaptop: 'hidden !important'},
                    textOverflow: {lg: 'initial !important', smallLaptop: 'ellipsis !important'},
                  },
                }}
              />
            )}
          </Box>
        </Wrapper>
      </Stack>
      <ScoutReport />
    </Stack>
  );
}

export default TrainerReport;
