import {a1tagViewModel} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {computed, signal} from '@preact/signals-react';

export interface Filter {
  value: string | number | null;
  label: string;
}
export interface A1TagTableFilters {
  player?: Filter | null;
  event?: Filter | null;
  teamName?: Filter | null;
}

export const filtersViewModel = {
  filters: {
    player: signal(null),
    event: signal(null),
    teamName: signal(null),
  },
};

export const updateFilter = <T extends keyof A1TagTableFilters, V extends A1TagTableFilters[T]>(
  filter: T,
  value: V,
) => {
  filtersViewModel.filters[filter].value = value;
};

export const filteredData = computed(() => {
  const player = filtersViewModel.filters.player.value?.value;
  const event = filtersViewModel.filters.event.value?.value;
  const teamName = filtersViewModel.filters.teamName.value?.value;
  let filteredData = a1tagViewModel.statistics?.value;

  if (event) {
    filteredData = filteredData.filter((statistic) => statistic.action_id === event);
  }

  if (player) {
    filteredData = filteredData.filter(
      (statistics) => statistics.player_id === player || statistics.opponent_id === player,
    );
  }

  if (teamName) {
    filteredData = filteredData.filter((statistics) => statistics.team_name === teamName);
  }

  return filteredData;
});
