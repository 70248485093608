import styled from 'styled-components';

export const TabsWrapper = styled.div`
  .TableEmptyBlock {
    flex-direction: column;
    .MuiButton {
      &-root {
        margin-left: 0;
        margin-top: ${({theme}) => theme.spacing(3)};
      }
    }
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 0 24px 0;
  margin-top: 18px;
  margin-bottom: 24px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(3)};
  ${({theme}) => theme.breakpoints.down('xs')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  ${(props) => props?.sx}
  .MuiButton {
    &-root {
      ${({theme}) => theme.breakpoints.down('xs')} {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: ${({theme}) => theme.spacing(2)};
        ${({theme}) => theme.breakpoints.down('xs')} {
          margin-right: 0;
          margin-bottom: ${({theme}) => theme.spacing(1)};
        }
      }
      &:not(:first-child) {
        margin-left: ${({theme}) => theme.spacing(2)};
        ${({theme}) => theme.breakpoints.down('xs')} {
          margin-left: 0;
        }
      }
    }
  }
`;

export const LocalPreloaderWrapper = styled.div`
  margin-right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: ${(props) => props.padding || '24px'};
  overflow: hidden;
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;
