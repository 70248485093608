import * as usersActions from '@actions/UsersActions';
import UpdateUserInfoForm from '@common/UpdateUserInfoForm';
import ModalCropUploadPanel from '@components/upload/ModalCropUploadPanel';
import CommonHelper from '@helpers/CommonHelper';
import {getRoleLabel} from '@helpers/roles';
import useStoreDispatch from '@hooks/useStoreDispatch';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import {StudentSubroles} from '@src/types';
import Avatar from '@ui/MUI/Avatar';
import Popover from '@ui/MUI/Popover';
import Typography from '@ui/MUI/Typography';
import React, {useState} from 'react';

import {Name, PopoverButton, PopoverContent, StyledButton, Wrapper} from './UserPanel';

interface UserPanelProps {
  isMobile?: boolean;
  isLoading?: boolean;
  user: {
    id?: string;
    firstName: string;
    lastName: string;
    avatar: string;
    userRole: string;
    subRole: string;
    teacherRole: string;
  };
}
function UserPanel({user, isLoading = false, isMobile = false}: UserPanelProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const id = anchorEl ? 'simple-popover' : undefined;
  const dispatch = useStoreDispatch();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [imageCropModalState, setImageCropModalState] = useState<boolean>(false);
  return (
    <Wrapper>
      <Avatar
        height={40}
        src={user?.avatar}
        sx={{cursor: 'pointer'}}
        width={40}
        onClick={() => setImageCropModalState(true)}
      />
      <Name>
        <Typography noWrap variant="body2">
          {CommonHelper.getShortNameByUser(user)}
        </Typography>
        <Typography noWrap variant="caption">
          {user.subRole !== StudentSubroles.AMATEUR &&
            getRoleLabel(user.userRole, user?.subRole || user?.teacherRole, '')}
        </Typography>
      </Name>
      <StyledButton aria-describedby={id} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreHorizRoundedIcon fontSize="inherit" />
      </StyledButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        id={id}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: isMobile ? 'right' : 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverContent>
          <PopoverButton
            variant="text"
            onClick={() => {
              setAnchorEl(null);
              setIsEditModalOpen(true);
            }}
          >
            Настройки
          </PopoverButton>
          <PopoverButton
            variant="text"
            onClick={() => {
              setAnchorEl(null);
              dispatch(usersActions.logOut());
            }}
          >
            Выйти
          </PopoverButton>
        </PopoverContent>
      </Popover>
      <ModalCropUploadPanel
        cropModalState={imageCropModalState}
        onClose={() => {
          setImageCropModalState(false);
        }}
      />
      <UpdateUserInfoForm
        isEditModalOpen={isEditModalOpen}
        isLoading={isLoading}
        setIsEditModalOpen={setIsEditModalOpen}
        user={user}
      />
    </Wrapper>
  );
}

export default React.memo(UserPanel);
