import UsersAPI from '@api/UsersAPI';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';

import {User} from '../types/user.type';

export interface UsersQuery {
  schoolId: string;
  userRole: string;
  limit?: number;
  active?: boolean;
}

export const useUsers = (query?: UsersQuery, options?: UseQueryOptions<User[]>) => {
  return useQuery({
    ...options,
    staleTime: 5 * 60 * 1000, // 5 min
    queryKey: ['users', query],
    queryFn: () => {
      return UsersAPI.getUsersByQueryV2(query).then((res) => res?.data?.result ?? []) as Promise<User[]>;
    },
  });
};
