import React from 'react';

import {BoxShadow} from '../BoxShadow';

interface PropsWithChildren {
  children: React.ReactNode;
}

export const Header = ({children}: PropsWithChildren) => {
  return (
    <BoxShadow px={4} py="20px">
      {children}
    </BoxShadow>
  );
};
