import ReactTableComponent from '@common/ReactTableComponent';
import {black} from '@ui/MUI/colorsA1Tag';
import styled from 'styled-components';

export const TableContainer = styled.div`
  .TableWrapper {
    background-color: transparent;
    border: 1px solid ${black[20]};
  }
`;
export const StyledTable = styled(ReactTableComponent)`
  .MuiTableHead {
    &-root {
      background-color: transparent;
    }
  }
`;
