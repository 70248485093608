import ThemeLayout from '@components/modules/common/ThemeLayout';
import useStore from '@hooks/useStore';
import {StudentSubroles} from '@src/types';
import React from 'react';

import VideoList from './components/VideoList';

type VideoListAppProps = {
  isStudent?: boolean;
};
export default function VideoListApp({isStudent = false}: VideoListAppProps) {
  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;
  return (
    <ThemeLayout active="video" isNew={isAmateur}>
      <VideoList isPersonalFile={false} isStudent={isStudent} />
    </ThemeLayout>
  );
}
