import {useSchool} from '@hooks/filters';
import {TextField} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import ButtonDefault from '@ui/MUI/Button';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import UsersAPI from '../../../../../api/UsersAPI';
import {NOTIFICATION_TYPES} from '../../../../../constants/config';
import * as groupsActions from '../../../../../redux/actions/GroupsActions';
import * as schoolActions from '../../../../../redux/actions/SchoolsActions';
import DrawerDefault from '../../../../ui/MUI/Modals/Drawer/Drawer';
import {ReactComponentNotification} from '../../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {formatGroup} from '../../../helper';
import {
  ButtonsBlock,
  GroupModalButtonsContainer,
  GroupModalContent,
  GroupModalForm,
  GroupModalFormField,
  GroupModalTitle,
} from '../modals/styles';

export default function CreateModal(props) {
  const {modalState, setModalState, toggleModal = () => {}, getGroupsList = () => {}} = props;

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const [isTeachersLoading, setIsTeachersLoading] = useState(false);
  const [allSchoolTeachers, setAllSchoolTeachers] = useState([]);

  useEffect(() => {
    dispatch(schoolActions.loadAllSchools());
  }, []);

  const {
    handleSubmit,
    formState: {},
    control,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      schoolId: '',
      birthYear: '',
      instatId: '',
      teachers: [],
    },
  });

  const school = useSchool({});
  const schoolId = watch('schoolId');

  const getAllSchoolTeachers = async () => {
    setIsTeachersLoading(true);
    const schoolTeachers = await UsersAPI.getTeachersBySchool(schoolId.value);
    setAllSchoolTeachers(schoolTeachers.data);
    setIsTeachersLoading(false);
  };

  useEffect(() => {
    if (schoolId) {
      getAllSchoolTeachers();
    }
  }, [schoolId]);

  const onSubmitCreateGroupForm = (group) => {
    dispatch(groupsActions.createGroup(formatGroup(group))).then((r) => {
      if (r?.status === 201) {
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Команда успешно создана.');
        toggleModal('right', false);
        getGroupsList();
      } else {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Произошла ошибка при создании команды.');
        toggleModal('right', false);
      }
    });
  };

  return (
    <>
      <DrawerDefault
        buttons={
          <GroupModalButtonsContainer>
            <ButtonDefault color="primary" form="create_form" text="Добавить" type="submit" variant="contained" />
            <ButtonsBlock>
              <ButtonDefault
                color="neutral"
                text="Отменить"
                variant="contained"
                onClick={() => {
                  toggleModal('right', false);
                }}
              />
            </ButtonsBlock>
          </GroupModalButtonsContainer>
        }
        content={
          <GroupModalContent>
            <GroupModalTitle>Создание команды</GroupModalTitle>
            <GroupModalForm id="create_form" onSubmit={handleSubmit(onSubmitCreateGroupForm)}>
              <Controller
                control={control}
                name="name"
                render={({field: {onChange, value}}) => (
                  <GroupModalFormField>
                    <TextField
                      id="team_name"
                      label="Название команды"
                      required={true}
                      value={value}
                      onChange={(newName) => {
                        onChange(newName);
                      }}
                    />
                  </GroupModalFormField>
                )}
              />
              <Controller
                control={control}
                name="schoolId"
                render={({field: {value, onChange}}) => {
                  return (
                    <GroupModalFormField>
                      <AutocompleteDefault
                        isLoading={school.isLoading}
                        label="Школа"
                        multiple={false}
                        options={school.options}
                        value={value}
                        onChange={(_, v) => onChange(v)}
                      />
                    </GroupModalFormField>
                  );
                }}
              />
              <Controller
                control={control}
                name="birthYear"
                render={({field: {onChange, value}}) => (
                  <GroupModalFormField>
                    <TextField
                      id="birthYear"
                      label="Год рождения"
                      required={true}
                      value={value}
                      onChange={(newBirthYear) => {
                        onChange(newBirthYear);
                      }}
                    />
                  </GroupModalFormField>
                )}
              />
              <Controller
                control={control}
                name="instatId"
                render={({field: {onChange, value}}) => (
                  <GroupModalFormField>
                    <TextField
                      id="instat_id"
                      label="ID Команды (Instat)"
                      required={false}
                      value={value}
                      onChange={(newId) => {
                        onChange(newId);
                      }}
                    />
                  </GroupModalFormField>
                )}
              />
              <Controller
                control={control}
                name="teachers"
                render={({field: {onChange, value}}) => {
                  return (
                    <GroupModalFormField>
                      <AutocompleteDefault
                        getOptionLabel={(option) => option.label}
                        id="teachers"
                        label="Тренеры"
                        options={allSchoolTeachers.map((teacher) => {
                          return {
                            value: teacher.id,
                            label: `${teacher.lastName} ${teacher.firstName}`,
                          };
                        })}
                        placeholder="Поиск..."
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                      />
                    </GroupModalFormField>
                  );
                }}
              />
            </GroupModalForm>
          </GroupModalContent>
        }
        drawerState={modalState}
        hasFullWidth={false}
        setDrawerState={setModalState}
      />
    </>
  );
}
