import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import StateHelper from '../../../helpers/StateHelper';
import {cleanLinesForm, getLinesForm} from '../../../redux/actions/LinesActions';
import NextGame from '../../widgets/NextGame';
import TodaySchedule from '../../widgets/TodaySchedule/TodaySchedule';

export default function ParentProfilePanel(props) {
  const {id, student, isMobile, nextGame, schedule, groups, hockeyRole} = props;

  const dispatch = useDispatch();

  const loadingForPreloader = useSelector((state) => state?.individualPlan?.loading);
  const linesForm = useSelector((state) => state.lines.linesForm || undefined);
  const state = useSelector((state) => state);

  useEffect(() => {
    if (id) {
      dispatch(getLinesForm({team: group?.id})).catch(() => {
        dispatch(cleanLinesForm());
      });
    }
  }, [id, groups]);

  const group = useMemo(() => {
    if (groups) {
      return StateHelper.getStudentGroup(state, id);
    }
  }, [groups]);

  const mobileLayOut = isMobile && (
    <>
      <ItemWrapper>
        <TodaySchedule group={group} todaySchedule={schedule} />
      </ItemWrapper>
      <ItemWrapper>
        <NextGame
          currentUserId={id}
          group={group}
          hockeyRole={hockeyRole}
          id={id}
          linesForm={linesForm}
          nextGame={nextGame}
          student={student}
        />
      </ItemWrapper>
    </>
  );

  return (
    <Wrapper loading={loadingForPreloader}>
      <StudentPanelBlocks>
        {isMobile ? (
          mobileLayOut
        ) : (
          <>
            <Half id={'LeftBlock'}>
              <ItemWrapper>
                <TodaySchedule group={group} todaySchedule={schedule} />
              </ItemWrapper>
            </Half>

            <Half>
              <ItemWrapper>
                <NextGame
                  currentUserId={id}
                  group={group}
                  hockeyRole={hockeyRole}
                  id={id}
                  linesForm={linesForm}
                  nextGame={nextGame}
                  student={student}
                />
              </ItemWrapper>
            </Half>
          </>
        )}
      </StudentPanelBlocks>
    </Wrapper>
  );
}

const Half = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 0 2px;
  @media screen and (max-width: 1512px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 8px 0 0;
  }
  &:first-child {
    @media screen and (max-width: 1512px) {
      padding-top: 0;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
`;

const ItemWrapper = styled.div`
  margin-top: 8px;
  margin-right: 2px;
`;

const StudentPanelBlocks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -2px;
  @media (max-width: 1512px) {
    flex-direction: column;
  }
`;
