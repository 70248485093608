import CloseIcon from '@mui/icons-material/Close';
import {ButtonProps} from '@mui/material/Button/Button';
import DialogMUI, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {IconButton, Stack, Typography} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import React from 'react';
import styled from 'styled-components';

import ButtonDefault from '../../Button';

type CustomDialogProps = {
  isLoading?: boolean;
  handleClickOpen?: () => void;
  onActionButtonClick?: () => void;
  onCloseDefault?: DialogProps['onClose'];
  content?: React.ReactNode;
  actionText?: string;
  closeText?: string;
  actionButtonProps?: ButtonProps;
  closeButtonProps?: ButtonProps;
} & DialogProps;

const CustomizedDialog = styled(DialogMUI)<CustomDialogProps>``;
export default function Dialog({
  open,
  isLoading,
  handleClickOpen = () => {},
  onActionButtonClick = () => {},
  onCloseDefault = () => {},
  title = '',
  content,
  actionText = 'Сохранить',
  closeText = 'Закрыть',
  actionButtonProps = {},
  closeButtonProps = {},
  sx,
  ...props
}: CustomDialogProps) {
  const defaultStyles = {
    '.MuiDialog-paper': {
      p: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, .1)',
      borderRadius: '6px',
      minWidth: '428px',
      '@media screen and (max-width: 480px)': {
        minWidth: '300px',
      },
    },
    '.MuiDialogContent-root': {
      py: 3,
      px: '0px',
    },
    '.MuiDialogActions-root': {
      p: '0px',
      justifyContent: 'space-between',
    },
  };

  return (
    <CustomizedDialog
      {...props}
      aria-labelledby="customized-dialog-title"
      handleClickOpen={handleClickOpen}
      open={open}
      sx={{...defaultStyles, ...sx}}
      onClose={onCloseDefault}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="h4">{title}</Typography>
        <IconButton
          aria-label="close"
          size="medium"
          sx={{padding: '0px'}}
          onClick={(event) => {
            onCloseDefault(event, 'escapeKeyDown');
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <LoadingButton
          {...actionButtonProps}
          color="primary"
          loading={isLoading}
          variant="contained"
          onClick={() => {
            onActionButtonClick();
          }}
        >
          {actionText}
        </LoadingButton>
        <ButtonDefault
          {...closeButtonProps}
          color={closeButtonProps.color ?? 'primary'}
          disabled={isLoading}
          text={closeText}
          variant={closeButtonProps.variant ?? 'outlined'}
          onClick={(event) => {
            onCloseDefault(event, 'escapeKeyDown');
          }}
        />
      </DialogActions>
    </CustomizedDialog>
  );
}
