import BMIItemChart from '@components/apps/PlayerPersonalFile/components/MainInfo/components/BMIItemChart';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Box, CircularProgress, Stack, Typography} from '@ui/MUI';
import {darkRed, green, grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

BMIItem.propTypes = {
  seriesData: PropTypes.array,
  unit: PropTypes.string,
  color: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  title: PropTypes.string,
  dates: PropTypes.array,
  isLoading: PropTypes.bool,
  minValue: PropTypes.number,
};

function BMIItem({
  seriesData,
  unit,
  title,
  color = {primary: darkRed['100'], secondary: darkRed['20']},
  dates,
  isLoading,
  minValue,
  id,
}) {
  const lastItem = useDeepMemo(() => seriesData?.[seriesData?.length - 1] || {}, [seriesData]);
  const diff = useMemo(
    () => (lastItem?.value - seriesData?.[0]?.value)?.toFixed(2) || 0,
    [seriesData?.[0]?.value, lastItem?.value],
  );
  const currentValue = lastItem?.value || 0;
  const [{bmiTab, ...qs}, set] = useQueryString();
  return (
    <Wrapper
      flex={1}
      minWidth={180}
      p={3}
      onClick={() => {
        set({...qs, tab: 'bmi', bmiTab: id});
      }}
    >
      {seriesData?.length || isLoading ? (
        <Stack direction="column" height="100%" justifyContent="space-between">
          <Box>
            <Typography mb={0} variant="subtitle1">
              {title}
            </Typography>
            {(currentValue || currentValue === 0) && (
              <Stack alignItems="flex-end" direction="row" gap={0}>
                <Value isLoading={isLoading} preloaderWidthBetween={[50, 70]}>
                  {currentValue}
                </Value>
                <Typography variant="h3"> {unit}</Typography>
              </Stack>
            )}
            <Stack alignItems="center" direction="row">
              {!isLoading && (
                <ExpandLessIcon
                  fontSize="20px"
                  sx={{fill: green['100'], transform: diff < 0 ? 'rotate(180deg)' : 'none'}}
                />
              )}
              <Typography
                color={green['100']}
                isLoading={isLoading}
                preloaderWidthBetween={[50, 70]}
                variant="overline1"
                verticalAlign="center"
              >
                {Math.abs(diff)} {unit}
              </Typography>
            </Stack>
          </Box>
          {isLoading ? (
            <Stack alignItems="center" height={120} justifyContent="center">
              <CircularProgress size={20} />
            </Stack>
          ) : (
            <BMIItemChart color={color} dates={dates} minValue={minValue} seriesData={seriesData} />
          )}
          <Stack direction="row" gap={0}>
            <Typography color={grey['68']} mt="auto" variant="caption1">
              Дата контроля:
            </Typography>
            <Typography
              color={grey['68']}
              isLoading={isLoading}
              mt="auto"
              preloaderProps={{sx: {width: 50}}}
              variant="caption1"
            >
              {date(lastItem?.id).format('DD.MM.YYYY')}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Box height="100%">
          <Typography isLoading={isLoading} mb={0} preloaderWidthBetween={[80, 100]} variant="subtitle1">
            {title}
          </Typography>
          <Typography my="25%" textAlign="center" variant="subtitle1">
            Замеров пока нет
          </Typography>
        </Box>
      )}
    </Wrapper>
  );
}

export default BMIItem;

const Value = styled(Typography)`
  font-family:
    Proxima Nova,
    serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
`;
