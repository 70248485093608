import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Notification from 'rc-notification';
import React from 'react';
import styled from 'styled-components';

const NotificationTypeDefaultTitle = (type) => {
  switch (type) {
    case 'Success':
      return 'Успех';
    case 'Error':
      return 'Ошибка';
    case 'Warning':
      return 'Предупреждение';
    case 'Info':
      return 'Информация';
    default:
      return '';
  }
};

const icons = {
  Success: <CheckCircleOutlineIcon />,
  Error: <ErrorOutlineIcon />,
  Warning: <ErrorOutlineIcon />,
  Info: <InfoOutlinedIcon />,
};

export const ReactComponentNotification = (type, text, title) => {
  Notification.newInstance({}, (notification) => {
    notification.notice({
      content: (
        <NotificationContent className={`NotificationContent NotificationContent_${type}`}>
          <NotificationContainer>
            {icons?.[type] || <CheckCircleOutlineIcon />}
            <NotificationTitle>{title ? title : NotificationTypeDefaultTitle(type)}</NotificationTitle>
            <NotificationText>{text}</NotificationText>
          </NotificationContainer>
        </NotificationContent>
      ),
      onClose: () => {
        notification.destroy();
      },
      duration: 4,
      closable: true,
    });
  });
};

const NotificationContent = styled.div`
  position: relative;
  min-height: 48px;
  height: 100%;
  border-left: 3px solid transparent;
  &.NotificationContent {
    border-radius: 6px;
    &_Success {
      background-color: #e1ffd2;

      svg {
        path {
          fill: #598855;
        }
      }

      h2 {
        color: #628e5e;
      }
    }
    &_Error {
      background-color: #ffe0e0;

      svg {
        path {
          fill: #990011;
        }
      }

      h2 {
        color: #990011;
      }
    }
    &_Warning {
      background-color: #fff4e5;

      svg {
        path {
          fill: #886633;
        }
      }
    }
    &_Info {
      background-color: #d9f1ff;

      svg {
        path {
          fill: #014361;
        }
      }

      h2 {
        color: #004360;
      }
    }
  }

  svg {
    position: absolute;
    top: 20px;
    left: 12px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
  }
`;
const NotificationContainer = styled.div`
  padding: 8px 15px 8px 32px;
  min-height: 48px;
`;
const NotificationTitle = styled.h2`
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1px;
  margin-bottom: 0px;
`;
const NotificationText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c2c2c;
  line-height: 22px;
`;
