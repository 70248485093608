import {convertSecondsToHHMMSS} from '@components/apps/A1TagMainWindow';
import {A1TAG_EVENTS_LIST} from '@components/apps/A1TagMainWindow/constants';
import {EventType} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  a1tagViewModel,
  appendStatistic,
  updateEvents,
  updateIsGameStopped,
  updateIsTimerStart,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {pause, playerViewModel} from '@components/apps/A1TagMainWindow/viewmodels/player.view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {A1TagEventDto} from '@src/types/generated';
import {Button} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {memo, useEffect, useState} from 'react';

export const A1TagMainWindowActionsButtons = memo(() => {
  useSignals();
  const [events, setEvents] = useState<A1TagEventDto[]>([]);
  useEffect(() => {
    updateEvents(
      EventType.Events,
      A1TAG_EVENTS_LIST?.filter((event) => event?.name !== 'Goalie exit'),
    );
    setEvents(A1TAG_EVENTS_LIST?.filter((event) => event?.name !== 'Goalie exit'));
    return () => {
      setEvents([]);
    };
  }, []);

  const {period, lines, homeScore, awayScore, isGodMode, cleanTime} = a1tagViewModel;
  const {currentVideoTime, isPlaying} = playerViewModel;

  const defaultEventWithoutModal = {
    half: period?.value,
    second_clear: cleanTime?.value,
    plrs_team1: lines?.value?.[0],
    plrs_team2: lines?.value?.[lines?.value?.length - 1],
    goals_team1: homeScore?.value,
    goals_team2: awayScore?.value,
    time: convertSecondsToHHMMSS(cleanTime?.value),
    video_time_start: convertSecondsToHHMMSS(currentVideoTime?.value),
    video_time_end: convertSecondsToHHMMSS(currentVideoTime?.value),
  };

  const payload = modalViewModel.payload.value ?? null;

  useEffect(() => {
    const hasFaceOff = a1tagViewModel?.statistics?.value?.map((item) => item?.action_name)?.includes('Face-off');
    if (!isPlaying?.value) {
      updateIsTimerStart(false);
    }
    if (hasFaceOff && isPlaying?.value) {
      updateIsTimerStart(true);
    }
  }, [isPlaying?.value]);

  useEffect(() => {
    const handleEsc = (event: {key: string}) => {
      if (event.key === 'Escape') {
        updateIsGameStopped(true);
        updatePayload({});
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      {events?.map((event: A1TagEventDto) => {
        return (
          <Button
            className={payload?.eventName === event?.name ? 'active' : ''}
            color="secondary"
            disabled={isGodMode?.value && event?.name !== 'Penalty'}
            key={event.id}
            size="small"
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              '&.active': {
                backgroundColor: black[75],
                color: black[10],
              },
            }}
            variant="contained"
            onClick={() => {
              updateIsTimerStart(false);
              pause();
              if (event.name === 'Stop game') {
                updateIsGameStopped(true);
                appendStatistic({
                  id: Math.floor(Math.random() * Date.now()).toString(12),
                  action_id: a1tagViewModel.events.value.find(({name}) => name === 'Stop game')?.id,
                  action_name: 'Stoppage of play',
                  ...defaultEventWithoutModal,
                });
              }
              if (event.name === 'End of period') {
                updateIsGameStopped(true);
                appendStatistic({
                  id: Math.floor(Math.random() * Date.now()).toString(12),
                  action_id: a1tagViewModel.events.value.find(({name}) => name === 'End of period')?.id,
                  action_name: 'End of period',
                  ...defaultEventWithoutModal,
                });
              }
              if (event.name === 'Icing') {
                updateIsGameStopped(true);
                appendStatistic({
                  id: Math.floor(Math.random() * Date.now()).toString(12),
                  action_id: a1tagViewModel.events.value.find(({name}) => name === 'Icing')?.id,
                  action_name: 'Icing',
                  ...defaultEventWithoutModal,
                });
              }
              if (event.name !== 'Stop game' && event.name !== 'End of period' && event.name !== 'Icing') {
                updateIsGameStopped(false);
                updatePayload({...payload, eventName: event.name});
              }
            }}
          >
            {event.name}
          </Button>
        );
      })}
    </>
  );
});

A1TagMainWindowActionsButtons.displayName = 'A1TagMainWindowActionsButtons';
