import GroupsAPI from '@api/GroupsAPI';
import {useQuery} from '@tanstack/react-query';

import {Group} from '../types/group.types';

export const useGroup = (id?: string) => {
  return useQuery<Group>({
    queryKey: ['group', id],
    queryFn: async () => {
      return GroupsAPI.getGroupById(id as string).then((res) => res.data) as Promise<Group>;
    },
    enabled: !!id,
  });
};
