import {Group} from '../types/group.types';

export const createGroup = (): Group => {
  const randomIdString = Math.random().toString(36).substring(2, 15);
  return {
    id: randomIdString,
    name: `Группа ${randomIdString}`,
    description: '',
    franchiseeId: '',
    assistanId: '',
    icebergId: 0,
    achievement: '',
    birthYear: '14',
    instatId: '',
    avangardId: 0,
    active: true,
    studentsCount: 0,
    averageAge: 0,
    ageGroup: '',
    resident: 0,
    nonResident: 0,
    school: {
      id: randomIdString,
      name: `Школа ${randomIdString}`,
    },
    students: [],
    teachers: [],
    mkcId: 0,
  };
};
