import {useUserSections} from '@components/modules/accesses/queries/section.query';
import ThemeLayout from '@components/modules/common/ThemeLayout';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import {setMenuItems} from '@slices/menu';
import {homePage} from '@src/constants/menu';
import {indexRoutes, routes} from '@src/routes';
import {UserRoles} from '@src/types';
import Theme from '@ui/MUI/Theme';
import PageTitleDefault from '@ui/PageTitleDefault';
import PageTitle from '@ui/v2/PageTitle';
import {groupBy} from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import UsersAPI from '../../api/UsersAPI';
import * as groupsActions from '../../redux/actions/GroupsActions';
import * as schoolsActions from '../../redux/actions/SchoolsActions';
import * as usersActions from '../../redux/actions/UsersActions';
import * as types from '../../redux/ActionTypes';
import FullPagePreloader from '../preloader/FullPagePreloader';

const metricsRole = {
  [UserRoles.FRANCHISEE]: 'vhod_manager_franchisee',
  [UserRoles.TEACHER]: 'teacher_visit',
  [UserRoles.STUDENT]: 'player_visit',
  [UserRoles.SCHOOLS_ADMIN]: 'manager_visit',
  [UserRoles.DOCTOR]: 'doctor_visit',
  [UserRoles.PSYCHO]: 'vhod_psihologa',
  [UserRoles.ADMIN]: 'admin_visit',
  [UserRoles.PARENT]: 'parent_visit',
};

const excludeMainItemRoles = [UserRoles.VIDEO_ANALYST, UserRoles.METHODIST];

export default function RouterApp() {
  const currentUser = useStore((state) => state.users.currentUser, shallowEqual);
  const initialized = useStore((state) => state.users.initialized, shallowEqual);
  const dispatch = useDispatch();
  const userSectionQuery = useUserSections(currentUser?.id);

  const {routesByCurrentUser, menuItems} = useMemo(() => {
    const role = currentUser?.userRole;
    const subRole = currentUser?.teacherRole || currentUser?.subRole || '';
    const userSections = userSectionQuery.data;

    const filteredRoutes = routes.filter((route) => {
      if (typeof route.accessRoles === 'function') {
        return route.accessRoles(userSections?.sections ?? []);
      }

      if (route.accessRoles.includes(role) || route.accessRoles === 'all') {
        if (subRole && route.subRoles?.[role]) {
          return route.subRoles[role]?.includes(subRole);
        }
        return true;
      }
    });
    const routesByCurrentUser = filteredRoutes.map((route) => {
      const componentByRole = route.component[role] || route.component['all'];
      if (componentByRole) {
        return {...route, component: componentByRole};
      }
      return route;
    });

    const menuItems = filteredRoutes
      .filter((route) => route?.menuItem)
      .map((route) => ({path: route?.path, ...route.menuItem}))
      .sort((a, b) => a.label.localeCompare(b.label));
    if (!excludeMainItemRoles.includes(currentUser?.userRole)) menuItems.unshift(homePage);

    return {routesByCurrentUser, menuItems};
  }, [currentUser?.userRole, currentUser?.teacherRole, userSectionQuery.data]);

  const indexRoute = useMemo(() => {
    if (currentUser) {
      const route = indexRoutes[currentUser.userRole];
      const subRole = currentUser?.subRole || currentUser?.teacherRole;
      if (route?.[subRole]) {
        return route?.[currentUser?.subRole] || route[currentUser.teacherRole];
      } else if (route.others) {
        return route.others;
      }

      return route;
    }
    return indexRoutes['guest'];
  }, [currentUser?.userRole, currentUser?.teacherRole]);

  useEffect(() => {
    dispatch(usersActions.initializeAuthorization()).then((pld) => {
      if (pld.type === types.INITIALIZE_AUTH_FAIL) {
        UsersAPI.forceLogout();
        window.location.reload();
        return;
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userRole !== UserRoles.STUDENT) {
        dispatch(usersActions.loadAllUsers());
      }
      dispatch(schoolsActions.loadAllSchools());
      dispatch(groupsActions.loadAllGroups());
    }

    const role = metricsRole?.[currentUser?.userRole];
    role && window.ym(80266396, 'reachGoal', role);
  }, [currentUser?.userRole]);

  useDeepEffect(() => {
    if (menuItems?.length) dispatch(setMenuItems(groupBy(menuItems, 'category')));
  }, [menuItems]);

  return !initialized || userSectionQuery.isFetching ? (
    <Theme>
      <FullPagePreloader visible />
    </Theme>
  ) : (
    <div className="LayoutContainer">
      <BrowserRouter>
        <Switch>
          {routesByCurrentUser.map((route, index) => {
            return (
              <Route exact={route.isExact} key={index} path={route.path}>
                <Theme isA1Tag={route?.isA1Tag} isNewUI={route?.isNewUI}>
                  {route.chessTemplate ? (
                    <ThemeLayout active={route.chessTemplate?.active} isA1Tag={route?.isA1Tag} isNew={route?.isNewUI}>
                      {route.pageTitle &&
                        (route?.isNewUI ? (
                          <PageTitle breadCrumbs={route.breadCrumbs} title={route.pageTitle.title} />
                        ) : (
                          <PageTitleDefault breadCrumbs={route.breadCrumbs} text={route.pageTitle.title} />
                        ))}
                      {route.component}
                    </ThemeLayout>
                  ) : (
                    route.component
                  )}
                </Theme>
              </Route>
            );
          })}
          <Route exact path="/">
            <Theme isNewUI={indexRoute?.isNewUI}>{indexRoute?.component}</Theme>
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
