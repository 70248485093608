import {Box, Button} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import styled from 'styled-components';

export const Line = styled(Box)`
  padding-top: 0;
  padding-bottom: 0;
  &:not(:last-child) {
    margin-bottom: ${({theme}) => theme.spacing(1)};
  }
`;

export const PlayerPosition = styled(Button)`
  padding: 6px 0;
  display: block;
  min-width: 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-color: ${(props) => {
    return props.isSwapped ? white.main : 'transparent';
  }};
  background-color: ${black[20]};
  color: ${black[95]};
  text-align: center;
  cursor: pointer;

  &:hover {
    border-color: ${white.main};
  }

  &:disabled {
    background-color: ${black[20]};
    cursor: not-allowed;
    border-color: ${(props) => {
      return props.isSwapped ? white.main : 'transparent';
    }};
  }

  &.active {
    background-color: ${(props) => {
      return props.isTeamHome ? props.homeColor : props.awayColor;
    }};
    &:disabled {
      background-color: ${black[20]};
      cursor: not-allowed;
    }
  }
`;
