import {black, white} from '@ui/MUI/colorsA1Tag';
import Paper from '@ui/MUI/Paper';
import styled from 'styled-components';

export const ColorsPaper = styled(Paper)``;

export const ColorContainer = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  border: 1px solid ${black[30]};
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    border-color: ${white.main};
  }
  &:hover {
    border-color: ${black[12]};
  }
`;

export const Color = styled.div`
  ${(props) => props.sx};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
