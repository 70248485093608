import {getCurrentSeason} from '@api/SeasonsAPI';
import {useQuery} from '@tanstack/react-query';

import {Season} from '../types/season.type';

export const useCurrentSeason = () => {
  return useQuery({
    queryKey: ['season', 'current-season'],
    queryFn: () => {
      return getCurrentSeason().then((res) => res.data) as Promise<Season>;
    },
  });
};
