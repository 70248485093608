import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {cleanGroups, getGroups} from '../../../../../redux/actions/ScoutReportActions';
import ChessTemplate from '../../../../templates/ChessTemplate';
import TeamCards from '../TeamCards';

export default function TeamYears() {
  const dispatch = useDispatch();
  const {teamid} = useParams();
  const history = useHistory();
  const {groups} = useSelector(({scoutReport}) => ({groups: scoutReport.groups}));

  useEffect(() => {
    if (teamid) dispatch(getGroups(teamid));
    return () => dispatch(cleanGroups());
  }, [teamid]);

  return (
    <ChessTemplate active="trainer_report">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Характеристика игрока', path: '/trainer_report/outer/teams'},
          {label: groups?.school.name || 'Команда', path: ''},
        ]}
        text="Характеристика игрока"
      />
      <>
        <TeamCards cards={groups} onClick={(id) => history.push(`/trainer_report/outer/teams/${id}`)} />
      </>
    </ChessTemplate>
  );
}
