import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectTableTabTable} from '@selectors/gameStatisticsMkc';
import {clearTableTabTable, loadTableTabTable} from '@slices/statisticsMKC';
import {STATISTICS_TABLE_TYPES} from '@src/constants';
import {useEffect} from 'react';
import {shallowEqual} from 'react-redux';
import {useParams} from 'react-router-dom';

export function useTableTabTableData(type, isFinal, season) {
  const {teamMKCId} = useParams();
  const [{groupName}, _] = useQueryString();
  const dispatch = useStoreDispatch();
  useEffect(() => {
    if (!groupName) {
      dispatch(
        loadTableTabTable(
          {teamMKCId, type: STATISTICS_TABLE_TYPES?.[type], isFinal, season},
          STATISTICS_TABLE_TYPES?.[type],
        ),
      );
    } else {
      dispatch(
        loadTableTabTable(
          {teamMKCId, type: STATISTICS_TABLE_TYPES?.[type], isFinal, season, groupName},
          STATISTICS_TABLE_TYPES?.[type],
        ),
      );
    }
    return () => {
      dispatch(clearTableTabTable());
    };
  }, [teamMKCId, groupName, isFinal, season]);

  return useStore(selectTableTabTable, shallowEqual);
}
