import '@lourenci/react-kanban/dist/styles.css';

import {
  cleanMedicalProfileForm,
  getMedicalProfileRecommendationsForCard,
  handleChangeForm,
  handleMedicalProfileBoard,
  postPlayerMedicalProfileForm,
  savePlayerMedicalProfileRecommendation,
} from '@actions/MedicalProfileActions';
import {emptyBoard, generateBoardByUserData} from '@helpers/MedicalProfileHelper';
import useStore from '@hooks/useStore';
import Board, {moveCard} from '@lourenci/react-kanban';
import {useMediaQuery} from '@mui/material';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {Button, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import TabsNew from '@ui/MUI/TabsNew';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {shallowEqual, useDispatch} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import Consultation from '../modals/Consultation';
import GeneralInfo from '../modals/GeneralInfo';
import MedicalHistory from '../modals/MedicalHistory';
import Nutrition from '../modals/Nutrition';
import Recommendations from '../modals/Recommendations';
import Card from '../tools/MedicalProfileCard';

export default function MedicalProfileBoard(props) {
  const dispatch = useDispatch();
  const {updateUserData = () => {}} = props;
  const {medicalProfileUserData, medicalProfileBoard, medicalProfileForm, medicalProfileRecommendation, state} =
    useStore((state) => {
      const medicalProfileBoard = state.medicalProfile.medicalProfileBoard
        ? state.medicalProfile.medicalProfileBoard
        : emptyBoard();
      const medicalProfileUserData = state.medicalProfile.medicalProfileUserData
        ? state.medicalProfile.medicalProfileUserData
        : [];
      const medicalProfileForm = state.medicalProfile.medicalProfileForm ? state.medicalProfile.medicalProfileForm : {};
      const medicalProfileRecommendation = state.medicalProfile.medicalProfileRecommendation || {
        comment: '',
      };
      return {
        medicalProfileBoard: medicalProfileBoard,
        medicalProfileUserData: medicalProfileUserData,
        medicalProfileForm: medicalProfileForm,
        medicalProfileRecommendation,
        state: state,
      };
    }, shallowEqual);

  useEffect(() => {
    if (medicalProfileUserData) {
      const updatedBoard = generateBoardByUserData(medicalProfileUserData, state);
      dispatch(handleMedicalProfileBoard(updatedBoard));
    }
  }, [medicalProfileUserData]);

  useEffect(() => {
    if (medicalProfileForm.objectId) {
      dispatch(getMedicalProfileRecommendationsForCard(medicalProfileForm.objectId));
    }
  }, [medicalProfileForm.objectId]);

  const [editTherapyModalVisible, setEditTherapyModalVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [deleteCardModalState, setDeleteCardModalState] = useState(false);
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const editTherapyModalContent = () => {
    return (
      <TabsNew
        withoutPx
        tabs={[
          {
            label: 'Общая информация',
            value: <GeneralInfo control={control} errors={errors} setValue={setValue} />,
          },
          {
            label: 'История',
            value: <MedicalHistory />,
          },
          // {
          //   label: 'Тренировка',
          //   content: <Training />,
          // },
          {
            label: 'Посещение/Консультация',
            value: <Consultation />,
          },
          {
            label: 'Питание',
            value: <Nutrition />,
          },
          {
            label: 'Рекомендации',
            value: <Recommendations playerId={medicalProfileForm.playerId} />,
          },
        ]}
      />
    );
  };

  const handleInitCard = (id) => {
    const card = medicalProfileUserData.find((card) => card.objectId === id);
    dispatch(handleChangeForm(card));
    setEditTherapyModalVisible({...editTherapyModalVisible, right: true});
  };
  function handleCardMove(_card, source, destination) {
    let updatedBoard = moveCard(medicalProfileBoard, source, destination);
    //сохранение единообразия данных в карточках доски
    //и карточках user data
    const updatedCard = medicalProfileUserData.find((card) => card.objectId === _card.id);
    const updatedCardIndex = medicalProfileUserData.findIndex((card) => card.objectId === _card.id);
    let cardWithUpdatedStatus = {...updatedCard, status: destination.toColumnId};
    medicalProfileUserData[updatedCardIndex] = cardWithUpdatedStatus;

    //если карточка перемещается на столбец "выздоровел"
    //назначаем автоматически текущую дату как дату выздоровления
    let newRecoveryDate = '';

    if (destination.toColumnId === 'recovered') {
      newRecoveryDate = new Date().getTime();
      cardWithUpdatedStatus.recoveryDate = newRecoveryDate;
    } else {
      delete cardWithUpdatedStatus.recoveryDate;
    }

    let updatedColumn = updatedBoard.columns.find((column) => column.id === destination.toColumnId);
    const updatedCardIndexInColumn = updatedColumn.cards.findIndex((i) => i.id === cardWithUpdatedStatus.objectId);
    const updatedColumnIndexInBoard = updatedBoard.columns.findIndex((i) => i.id === updatedColumn.id);
    updatedColumn.cards[updatedCardIndexInColumn] = {..._card, recoveryDate: newRecoveryDate};
    updatedBoard.columns[updatedColumnIndexInBoard] = updatedColumn;

    dispatch(postPlayerMedicalProfileForm(cardWithUpdatedStatus));
    dispatch(handleMedicalProfileBoard(updatedBoard));
  }

  const addRecommendation = () => {
    let requestObject = {
      playerId: medicalProfileForm.playerId,
      comment: medicalProfileRecommendation.comment,
      taskGroups: [],
    };
    if (medicalProfileForm.playerId) {
      dispatch(savePlayerMedicalProfileRecommendation(medicalProfileForm.objectId, requestObject));
    }
  };

  const deleteCard = () => {
    MedicalProfileAPI.deleteMedicalProfileCard(medicalProfileForm.objectId)
      .then(() => {
        let updatedMedicalProfileUserData = medicalProfileUserData.filter(
          (card) => card.objectId !== medicalProfileForm.objectId,
        );
        let updatedBoard = generateBoardByUserData(updatedMedicalProfileUserData, state);
        dispatch(handleMedicalProfileBoard(updatedBoard));
      })
      .then(() => {
        setDeleteCardModalState(false);
        setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
        dispatch(cleanMedicalProfileForm(''));
        updateUserData();
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Карточка успешно удалена');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
            }
          }
        }
      });
  };

  const isValidMedicalForm = (medicalProfileForm) => {
    let result = true;

    if (
      !medicalProfileForm.school ||
      !medicalProfileForm.group ||
      !medicalProfileForm.description ||
      !medicalProfileForm.playerId ||
      !medicalProfileForm.type ||
      !medicalProfileForm.status
    ) {
      result = false;
    }

    return result;
  };
  return (
    <Wrapper onSubmit={handleSubmit()}>
      <Board
        disableColumnDrag
        renderCard={(a, {dragging}) => (
          <Card
            a={a}
            cardOnClick={(id) => {
              handleInitCard(id);
            }}
            dragging={dragging}
          />
        )}
        onCardDragEnd={handleCardMove}
      >
        {medicalProfileBoard}
      </Board>
      <Drawer
        buttons={
          <ModalButtonsContainer>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (!isValidMedicalForm(medicalProfileForm)) return;
                const updatedData = medicalProfileUserData.map((card) => {
                  if (card.objectId === medicalProfileForm.objectId) return {...medicalProfileForm};
                  return card;
                });

                const updatedBoard = generateBoardByUserData(updatedData, state);

                dispatch(handleMedicalProfileBoard(updatedBoard));
                dispatch(postPlayerMedicalProfileForm(medicalProfileForm))
                  .then(() => {
                    setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
                    updateUserData();
                  })
                  .then(() => {
                    addRecommendation();
                  })
                  .then(() => {
                    if (medicalProfileForm.objectId) {
                      dispatch(getMedicalProfileRecommendationsForCard(medicalProfileForm.objectId));
                    }
                  });
              }}
            >
              Сохранить
            </Button>
            <ButtonsWrapper>
              <Button
                color="primary"
                sx={{mr: 3}}
                variant="contained"
                onClick={() => {
                  setDeleteCardModalState(true);
                }}
              >
                Удалить
              </Button>
              <Button
                color="neutral"
                variant="contained"
                onClick={() => {
                  dispatch(cleanMedicalProfileForm({}));
                  setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
                }}
              >
                Закрыть
              </Button>
            </ButtonsWrapper>
          </ModalButtonsContainer>
        }
        open={editTherapyModalVisible?.right}
        title="Изменить лечение"
        width={isSmResolution ? '100%' : 'calc(100% - 275px)'}
        onClose={() => {
          setEditTherapyModalVisible({
            ...editTherapyModalVisible,
            right: !editTherapyModalVisible.right,
          });
          dispatch(handleChangeForm({}));
        }}
      >
        {editTherapyModalContent()}
      </Drawer>
      <DialogDefault
        actionText="Удалить"
        closeText={window.innerWidth >= 480 ? 'Вернуться к карточке' : 'Вернуться'}
        content={<Typography variant="body1">Вы действительно хотите удалить карточку?</Typography>}
        open={deleteCardModalState}
        title="Удалить карточку"
        onActionButtonClick={() => deleteCard()}
        onCloseDefault={() => setDeleteCardModalState(false)}
      />
    </Wrapper>
  );
}
const Wrapper = styled.form`
  .react-kanban {
    &-board {
      position: relative;
      padding: 0;
      background-color: #e5e5e5;
      border-radius: 6px;
      margin-bottom: 20px;

      &::-webkit-scrollbar-track {
        height: 8px;
        background-color: ${grey['12']} !important;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        height: 8px;
        background-color: ${grey['54']} !important;
        border-radius: 2px;
      }
    }
    &-column {
      padding: 0;
      margin: 0;
      background-color: #e5e5e5;
      min-height: 80px !important;
      min-width: calc(100% / 6);
      flex: 0 0 calc(100% / 6);
      box-sizing: border-box;

      &:after {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 2px;
        height: 100%;
        background-color: #dddad4;
        z-index: 1;
        content: '';
      }

      :first-of-type {
        :after {
          display: none;
        }
      }

      > div {
        padding: 10px 13px 0 34px;
        :first-of-type {
          background: #fffaed;
        }
        > div > div {
          display: block !important;
        }
      }
      > div:nth-child(2) {
        max-height: 550px;
        overflow-y: auto;
        ::-webkit-scrollbar-thumb {
          height: 8px;
          background-color: #686868;
          border-radius: 2px;
        }
      }
      &-header {
        padding-bottom: 0;
        height: 50px;
        font: 700 normal 18px/50px 'Bebas Neue';
        color: #2c2c2c;
        :focus {
          outline: none;
        }
      }
    }
  }
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonsWrapper = styled.div``;
