import {createProspects} from '@api/prospect.api';
import {useMutation, useQueryClient} from '@tanstack/react-query';

export const useCreateProspectMutation = (onSuccess: () => void) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (ids: string[]) => {
      await createProspects(ids);
    },
    onSuccess: () => {
      client.invalidateQueries({queryKey: ['prospect']});
      onSuccess();
    },
  });
};
