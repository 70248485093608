import ReactTableComponent from '@common/ReactTableComponent';
import {Header} from '@components/modules/prospects/components/Header';
import {NoData} from '@components/modules/prospects/components/NoData';
import {
  useCurrentProspectReportQuery,
  useProspectReportQuery,
} from '@components/modules/prospects/queries/prospect-report.query';
import {Box, Stack} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Button} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';
import {useParams} from 'react-router';

import {States} from '../main/modal/modal.view-modal';
import {Filters} from './components/player-report-achive-filters.component';
import {FormModal} from './components/player-report-drawer.component';
import {columns} from './constants/player-report-table.constant';
import {filtersViewModal} from './modals/filters.view-modal';
import {playerReportModal} from './modals/modal.view-modal';
import {Tab, tabViewModal} from './modals/tab-view-modal';

export const HistoryReportTab = () => {
  useSignals();
  const filters = filtersViewModal;

  const {playerId} = useParams<{playerId: string}>();
  const prospectReportQuery = useProspectReportQuery(
    {
      enabled: filters.dateRange.length === 2,
    },
    playerId,
    {
      startDate: filters.dateRange[0]?.format('YYYY.MM.DD'),
      endDate: filters.dateRange[1]?.format('YYYY.MM.DD'),
      season: filters.seasonId,
    },
  );

  const reports = prospectReportQuery.data ?? [];
  const isEmpty = !reports.length;

  return (
    <>
      <Filters />
      <Box borderTop="1px solid #E0E0E0">
        {isEmpty ? <NoData /> : <ReactTableComponent columns={columns} data={reports} />}
      </Box>
    </>
  );
};

export const ActualReportTab = () => {
  const {playerId} = useParams<{playerId: string}>();
  const currentReportQuery = useCurrentProspectReportQuery(playerId);
  const data = currentReportQuery.data ? [currentReportQuery.data] : [];

  if (!data.length) {
    return <NoData />;
  }

  return (
    <Box borderTop="1px solid #E0E0E0">
      <ReactTableComponent columns={columns} data={data} />
    </Box>
  );
};

export const PlayerReportTab = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const currentReportQuery = useCurrentProspectReportQuery(playerId);

  return (
    <>
      <FormModal />
      <Stack borderRadius={1} spacing={2} sx={{background: 'white'}}>
        <Header>
          <Stack direction="row" justifyContent="space-between">
            <TabsNew
              withoutWrapper
              labelColor="black"
              tabs={tabViewModal.tabs}
              value={tabViewModal.tabIndex}
              onChange={(_, v) => {
                tabViewModal.updateTab(v as Tab);
              }}
            />
            <Stack direction="row" spacing={2}>
              <Button
                color="primary"
                disabled={!currentReportQuery.data || currentReportQuery.isLoading}
                size="small"
                variant="outlined"
                onClick={() => {
                  playerReportModal.open(States.EDIT);
                }}
              >
                рЕДАКТИРОВАТЬ актуальный
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => {
                  playerReportModal.open(States.CREATE);
                }}
              >
                ДОБАВИТь новый
              </Button>
            </Stack>
          </Stack>
        </Header>
        {tabViewModal.isArchive ? <HistoryReportTab /> : <ActualReportTab />}
      </Stack>
    </>
  );
};
