import React from 'react';
import styled from 'styled-components';

export default function Table(props) {
  const {title = '', table = React.ReactNode, children} = props;

  return (
    <TableWrapper>
      {title && <TableTitle>{title}</TableTitle>}
      <TableContainer>{table || children}</TableContainer>
    </TableWrapper>
  );
}

const TableContainer = styled.div`
  position: relative;
  .TableColumnLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 transparent;
    }
    .LinesAvailablePlayerAvatar {
      width: 40px;
      height: 40px;
      margin-left: 0;
    }
  }
  .BreakLinkStyle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
  }
`;
const TableWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const TableTitle = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  font: 600 normal 18px/24px 'Proxima Nova';
  color: #2c2c2c;
  text-transform: uppercase;
`;
