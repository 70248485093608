import {A1TagTable} from '@components/A1Table';
import {A1TagBreakoutModalContent} from '@components/A1TagBreakoutModalContent';
import {A1TagDekingModalContent} from '@components/A1TagDekingModalContent';
import {A1TagEditRostersModalContent} from '@components/A1TagEditRostersModalContent';
import {A1TagEntryModalContent} from '@components/A1TagEntryModalContent';
import {A1TagEventModal} from '@components/A1TagEventModal';
import {A1TagFaceOffModalContent} from '@components/A1TagFaceOffModalContent';
import {A1TagGiveawayModalContent} from '@components/A1TagGiveawayModalContent';
import {A1TagGoalieExitModalContent} from '@components/A1TagGoalieExitModalContent';
import {A1TagHitModalContent} from '@components/A1TagHitModalContent';
import {A1TagKeyPassModalContent} from '@components/A1TagKeyPassModalContent';
import {A1TagMainWindowAdditionalMenu} from '@components/A1TagMainWindowAdditionalMenu';
import {A1TagMainWindowMenu} from '@components/A1TagMainWindowMenu';
import {A1tagPlayersMainWindowTopBlock} from '@components/A1TagMainWindowTopBlock';
import {A1TagPenaltyModalContent} from '@components/A1TagPenaltyModalContent';
import {A1tagPlayersLinesBlock} from '@components/A1TagPlayersLinesBlock';
import {A1TagPuckBattleModalContent} from '@components/A1TagPuckBattleModalContent';
import {A1TagShotModalContent} from '@components/A1TagShotModalContent';
import {A1TagTakeawayModalContent} from '@components/A1TagTakeawayModalContent';
import {A1TagVideoPlayerHotKeys} from '@components/A1TagVideoPlayerHotKeys';
import {A1TagLayout} from '@components/modules/A1TagLayout';
import date from '@helpers/date';
import {useSignals} from '@preact/signals-react/runtime';
import {Box} from '@ui/MUI';
import React from 'react';

import {A1TagPossessionTakeModalContent} from '../A1TagPossessionTakeModalContent';
import {A1TagShootOutModalContent} from '../A1TagShootOutModalContent';
import {a1tagViewModel} from './viewmodels/a1tag.view-model';
import {modalViewModel} from './viewmodels/modal-view-model';

const customWidthMatrix = {
  'Face-off': 650,
  Penalty: 850,
  Hit: 745,
  'Take away': 745,
  Breakout: 745,
  Entry: 745,
  'Posession take': 745,
  Deking: 745,
  'Key pass': 745,
  Shootout: 900,
  Shot: 900,
  'Goalie exit': 745,
};

export const convertSecondsToHHMMSS = (seconds: number) => {
  return date.utc(seconds * 1000).format('HH:mm:ss');
};

export function A1TagMainWindow() {
  useSignals();
  const roasters = a1tagViewModel.rosters.value;
  const {payload} = modalViewModel;

  const eventModalContentMatrix = {
    'Face-off': <A1TagFaceOffModalContent />,
    Shot: <A1TagShotModalContent />,
    Penalty: <A1TagPenaltyModalContent />,
    Hit: <A1TagHitModalContent />,
    'Give away': <A1TagGiveawayModalContent />,
    'Take away': <A1TagTakeawayModalContent />,
    Breakout: <A1TagBreakoutModalContent />,
    Entry: <A1TagEntryModalContent />,
    'Key pass': <A1TagKeyPassModalContent />,
    'Posession take': <A1TagPossessionTakeModalContent />,
    Deking: <A1TagDekingModalContent />,
    'Puck battle': <A1TagPuckBattleModalContent />,
    Shootout: <A1TagShootOutModalContent />,
    'Goalie exit': <A1TagGoalieExitModalContent />,
    [`Edit ${roasters?.homeTeam?.name}`]: <A1TagEditRostersModalContent />,
    [`Edit ${roasters?.awayTeam?.name}`]: <A1TagEditRostersModalContent />,
  };

  return (
    <A1TagLayout additionalMenu={A1TagMainWindowAdditionalMenu}>
      <Box sx={{position: 'relative'}}>
        <Box sx={{minHeight: 58}}>
          <A1TagMainWindowMenu />
        </Box>
        <Box sx={{mx: 'auto', maxWidth: 'calc(100% - 62px)'}}>
          <Box display="flex" justifyContent="space-between">
            <A1tagPlayersLinesBlock isTeamHome={true} team={roasters?.homeTeam} />
            <A1tagPlayersMainWindowTopBlock
              awayTeamName={roasters?.awayTeam?.name}
              homeTeamName={roasters?.homeTeam?.name}
            />
            <A1tagPlayersLinesBlock isTeamHome={false} team={roasters?.awayTeam} />
          </Box>
        </Box>
        <A1TagVideoPlayerHotKeys />
        <A1TagEventModal
          maxWidth={customWidthMatrix?.[payload?.value?.eventName] && customWidthMatrix?.[payload?.value?.eventName]}
        >
          {eventModalContentMatrix?.[payload?.value?.eventName]}
        </A1TagEventModal>
      </Box>
      <A1TagTable />
    </A1TagLayout>
  );
}
