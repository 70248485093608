import {ZONES} from '@components/A1TagEventModal/constants';
import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {A1TAG_HORIZONTAL_ARENA_SCHEMA} from '@components/apps/A1TagMainWindow/constants';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {CompareArrowsOutlined} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Divider, IconButton, Typography} from '@ui/MUI';
import {black, white} from '@ui/MUI/colorsA1Tag';
import React, {useState} from 'react';

export function A1TagFaceOffModalContent() {
  useSignals();

  const payload = modalViewModel.payload.value ?? {};
  const homePlayersOptions =
    uniqHomePlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const awayPlayersOptions =
    uniqAwayPlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const isSelectedPlayerHome = homePlayersOptions?.map((option) => option.value).includes(payload?.player?.avangardId);

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const {currentTime} = useCurrentTime();

  const [activeZone, setActiveZone] = useState(
    ZONES?.find(
      ({name, posX, posY}) => name === payload?.zone_name && posX === payload?.pos_x && posY === payload?.pos_y,
    ) ?? null,
  );

  const getIsActiveZone = ({name, posX, posY}: {name: string; posX: number; posY: number}) => {
    if (name === activeZone?.name && posX === activeZone?.posX && posY === activeZone?.posY) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      <Typography sx={{mb: 4, color: black[60]}} variant="subtitle1">
        Time:
        <Typography component="span" sx={{pl: 1}} variant="subtitle1">
          {currentTime || '00:00:00'}
        </Typography>
      </Typography>
      <Box alignItems="flex-end" display="flex" sx={{mx: -4, mb: 4}}>
        <Box sx={{px: 4, flex: '0 0 calc(50% - 18px)', maxWidth: 'calc(50% - 18px)'}}>
          <Typography sx={{mb: 1, color: black[60]}} variant="subtitle1">
            Win Player
          </Typography>
          <Autocomplete
            multiple={false}
            options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
            sx={{width: '100%'}}
            textFieldVariant="outlined"
            value={payload.player ?? null}
            width="100%"
            onChange={(_, v: {value: string; label: string}) => {
              const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
              const merged = {
                ...v,
                ...player,
              };
              updatePayload({
                ...payload,
                player: v ? merged : null,
              });
            }}
          />
        </Box>
        <IconButton
          disabled={!payload.player || !payload.opponent}
          onClick={() => {
            if (payload.player && payload.opponent) {
              updatePayload({
                ...payload,
                player: payload.opponent,
                opponent: payload.player,
              });
            }
          }}
        >
          <CompareArrowsOutlined />
        </IconButton>
        <Box sx={{px: 4, flex: '0 0 calc(50% - 18px)', maxWidth: 'calc(50% - 18px)'}}>
          <Typography sx={{mb: 1, color: black[60]}} variant="subtitle1">
            Lose Player
          </Typography>
          <Autocomplete
            multiple={false}
            options={isSelectedPlayerHome ? awayPlayersOptions : homePlayersOptions}
            sx={{width: '100%'}}
            textFieldVariant="outlined"
            value={payload.opponent ?? null}
            width="100%"
            onChange={(_, v: {value: string; label: string}) => {
              const player = allPlayers.value.find((p) => p.avangardId === v?.value);
              const merged = {
                ...v,
                ...player,
              };

              updatePayload({
                ...payload,
                opponent: v ? merged : null,
              });
            }}
          />
        </Box>
      </Box>
      <Divider borderColor={black[25]} sx={{my: 4}} />
      <Box sx={{position: 'relative', minHeight: 296}}>
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            left: -22,
            transform: 'translateY(-50%) rotate(-90deg)',
            mb: 2,
            color: black[75],
            textTransform: 'uppercase',
          }}
          variant="overline2"
        >
          {homeTeam?.name}
        </Typography>
        <Box
          px={4}
          sx={{
            position: 'relative',
            '& > svg': {
              width: 550,
              height: 296,
              '& > g': {
                'rect:nth-child(2)': {fill: black[30], opacity: 0.8},
              },
              path: {
                stroke: black[15],
                '&:nth-child(9), &:nth-child(16), &:nth-child(21), &:nth-child(28), &:nth-child(34), &:nth-child(35), &:nth-child(36), &:nth-child(37)':
                  {
                    fill: black[15],
                  },
              },
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '7%',
              left: 70,
              display: 'block',
              width: 95,
              height: 95,
              backgroundColor: !getIsActiveZone({name: ZONES[0].name, posX: ZONES[0].posX, posY: ZONES[0].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[0].name, posX: ZONES[0].posX, posY: ZONES[0].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[0].name, posX: ZONES[0].posX, posY: ZONES[0].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[0].name,
                pos_x: ZONES[0].posX,
                pos_y: ZONES[0].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '7%',
              left: 70,
              display: 'block',
              width: 95,
              height: 95,
              backgroundColor: !getIsActiveZone({name: ZONES[1].name, posX: ZONES[1].posX, posY: ZONES[1].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[1].name, posX: ZONES[1].posX, posY: ZONES[1].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[1].name, posX: ZONES[1].posX, posY: ZONES[1].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[1].name,
                pos_x: ZONES[1].posX,
                pos_y: ZONES[1].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: 37,
              left: '34%',
              display: 'block',
              width: 78,
              height: 64,
              backgroundColor: !getIsActiveZone({name: ZONES[2].name, posX: ZONES[2].posX, posY: ZONES[2].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[2].name, posX: ZONES[2].posX, posY: ZONES[2].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[2].name, posX: ZONES[2].posX, posY: ZONES[2].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[2].name,
                pos_x: ZONES[2].posX,
                pos_y: ZONES[2].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 37,
              left: '34%',
              display: 'block',
              width: 78,
              height: 64,
              backgroundColor: !getIsActiveZone({name: ZONES[3].name, posX: ZONES[3].posX, posY: ZONES[3].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[3].name, posX: ZONES[3].posX, posY: ZONES[3].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[3].name, posX: ZONES[3].posX, posY: ZONES[3].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[3].name,
                pos_x: ZONES[3].posX,
                pos_y: ZONES[3].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'block',
              width: 94,
              height: 94,
              backgroundColor: !getIsActiveZone({name: ZONES[4].name, posX: ZONES[4].posX, posY: ZONES[4].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[4].name, posX: ZONES[4].posX, posY: ZONES[4].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[4].name, posX: ZONES[4].posX, posY: ZONES[4].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[4].name,
                pos_x: ZONES[4].posX,
                pos_y: ZONES[4].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: 37,
              right: '34%',
              display: 'block',
              width: 78,
              height: 64,
              backgroundColor: !getIsActiveZone({name: ZONES[5].name, posX: ZONES[5].posX, posY: ZONES[5].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[5].name, posX: ZONES[5].posX, posY: ZONES[5].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[5].name, posX: ZONES[5].posX, posY: ZONES[5].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[5].name,
                pos_x: ZONES[5].posX,
                pos_y: ZONES[5].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 37,
              right: '34%',
              display: 'block',
              width: 78,
              height: 64,
              backgroundColor: !getIsActiveZone({name: ZONES[6].name, posX: ZONES[6].posX, posY: ZONES[6].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[6].name, posX: ZONES[6].posX, posY: ZONES[6].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[6].name, posX: ZONES[6].posX, posY: ZONES[6].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[6].name,
                pos_x: ZONES[6].posX,
                pos_y: ZONES[6].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: '7%',
              right: 68,
              display: 'block',
              width: 95,
              height: 95,
              backgroundColor: !getIsActiveZone({name: ZONES[7].name, posX: ZONES[7].posX, posY: ZONES[7].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[7].name, posX: ZONES[7].posX, posY: ZONES[7].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[7].name, posX: ZONES[7].posX, posY: ZONES[7].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[7].name,
                pos_x: ZONES[7].posX,
                pos_y: ZONES[7].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '7%',
              right: 68,
              display: 'block',
              width: 95,
              height: 95,
              backgroundColor: !getIsActiveZone({name: ZONES[8].name, posX: ZONES[8].posX, posY: ZONES[8].posY})
                ? black[60]
                : white.main,
              opacity: !getIsActiveZone({name: ZONES[8].name, posX: ZONES[8].posX, posY: ZONES[8].posY}) ? 0.25 : 0.5,
              transition: 'all .33s',
              cursor: 'pointer',
              zIndex: 2,
              '&:hover': {
                backgroundColor: white.main,
                opacity: 0.5,
              },
            }}
            onClick={() => {
              setActiveZone({name: ZONES[8].name, posX: ZONES[8].posX, posY: ZONES[8].posY});
              updatePayload({
                ...payload,
                zone_name: ZONES[8].name,
                pos_x: ZONES[8].posX,
                pos_y: ZONES[8].posY,
              });
              document.getElementById('a1tag_event_save_button').click();
            }}
          ></Box>
          {A1TAG_HORIZONTAL_ARENA_SCHEMA}
        </Box>
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            right: -22,
            transform: 'translateY(-50%) rotate(90deg)',
            mb: 2,
            color: black[75],
            textTransform: 'uppercase',
          }}
          variant="overline2"
        >
          {awayTeam?.name}
        </Typography>
      </Box>
    </Box>
  );
}
