import {useTimer} from '@components/A1TagEventModal/hooks';
import {A1TagMainWindowActionsButtons} from '@components/A1TagMainWindowActionsButtons';
import {A1TAG_EVENTS_LIST_ADDITIONAL} from '@components/apps/A1TagMainWindow/constants';
import {a1tagViewModel, updateIsTimerStart} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {openModal} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {ExitToAppRounded, FlagRounded, ModeEditOutlineRounded} from '@mui/icons-material';
import {AppBar, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {A1TagEventDto} from '@src/types/generated';
import {Box, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {useEffect} from 'react';

export const A1TagMainWindowMenu = () => {
  useSignals();
  const roasters = a1tagViewModel.rosters.value;

  const {startTimer, pauseTimer} = useTimer();

  useEffect(() => {
    if (a1tagViewModel?.isTimerStart?.value) {
      startTimer();
    } else {
      pauseTimer();
    }
  }, [a1tagViewModel?.isTimerStart?.value]);

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          top: 0,
          width: `calc(100% - 32px)`,
          ml: `32px`,
          '&.MuiPaper-root': {ml: '0px', backgroundColor: black[10]},
        }}
      >
        <Toolbar sx={{borderBottom: `1px solid ${black[20]}`}}>
          <Box display="flex" flexWrap="wrap" gap={0} minHeight={58} py={2}>
            <A1TagMainWindowActionsButtons />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={true}
        sx={{
          width: 32,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            position: 'absolute',
            top: 0,
            width: 32,
            backgroundColor: black[10],
            borderRight: `1px solid ${black[20]}`,
            boxSizing: 'border-box',
            overflow: 'hidden',
          },
        }}
        variant="permanent"
      >
        <List sx={{paddingBottom: '29px', maxWidth: 32}}>
          <ListItem disablePadding sx={{display: 'block', mb: '20px'}}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 1,
                py: 3,
                maxWidth: 32,
                minHeight: 100,
                backgroundColor: black[10],
              }}
            >
              <Typography
                sx={{position: 'absolute', transform: 'rotate(-90deg)', color: black[75]}}
                textTransform="uppercase"
                variant="overline2"
              >
                Events
              </Typography>
            </Box>
          </ListItem>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
              onClick={() => {
                openModal({eventName: `Edit ${roasters?.homeTeam?.name}`, isHomeTeam: true});
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
              >
                <ModeEditOutlineRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
              >
                <FlagRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
                onClick={() => {
                  updateIsTimerStart(false);
                  openModal({eventName: 'Goalie exit'});
                }}
              >
                <ExitToAppRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{paddingTop: '29px', maxWidth: 32, my: -1}}>
          {A1TAG_EVENTS_LIST_ADDITIONAL?.map((event: A1TagEventDto) => {
            return (
              <ListItem disablePadding key={event.id} sx={{display: 'block', py: 1}}>
                <ListItemButton
                  sx={{
                    backgroundColor: black[15],
                    borderRadius: 2,
                    minHeight: 78,
                    width: 24,
                    justifyContent: 'center',
                    py: 1,
                    px: 0,
                    mx: 'auto',
                  }}
                >
                  <ListItemText
                    sx={{
                      m: '0px',
                      mr: 0,
                      transform: 'rotate(-90deg)',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{color: black[75]}} variant="caption">
                      {event.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Drawer
        anchor="right"
        open={true}
        sx={{
          '.MuiPaper-root': {
            position: 'absolute',
            top: 95,
            maxHeight: '85%',
            backgroundColor: black[10],
            borderLeft: `1px solid ${black[20]}`,
            borderBottom: `1px solid ${black[20]}`,
          },
        }}
        variant="permanent"
      >
        <List sx={{paddingBottom: '29px', maxWidth: 31}}>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
                onClick={() => {
                  openModal({eventName: `Edit ${roasters?.awayTeam?.name}`, isHomeTeam: false});
                }}
              >
                <ModeEditOutlineRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
              >
                <FlagRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
                onClick={() => {
                  updateIsTimerStart(false);
                  openModal({eventName: 'Goalie exit'});
                }}
              >
                <ExitToAppRounded sx={{color: black[60]}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{paddingTop: '29px', maxWidth: 32, my: -1}}>
          {A1TAG_EVENTS_LIST_ADDITIONAL?.map((event: A1TagEventDto) => {
            return (
              <ListItem disablePadding key={event.id} sx={{display: 'block', py: 1}}>
                <ListItemButton
                  sx={{
                    backgroundColor: black[15],
                    borderRadius: 2,
                    minHeight: 78,
                    width: 24,
                    justifyContent: 'center',
                    py: 1,
                    px: 0,
                    mx: 'auto',
                  }}
                >
                  <ListItemText
                    sx={{
                      m: '0px',
                      ml: 0,
                      transform: 'rotate(90deg)',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{color: black[75]}} variant="caption">
                      {event.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};
