import styled from 'styled-components';

import {LinesMobileTableTd, LinesMobileTableTh} from '../LinesMobile';

export const ModalWrapper = styled.div`
  ${LinesMobileTableTh},
  ${LinesMobileTableTd} {
    &:first-child {
      position: initial;
      left: initial;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
    }
  }
`;
