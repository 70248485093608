import * as usersActions from '@actions/UsersActions';
import UpdateUserInfoForm from '@common/UpdateUserInfoForm';
import {ErrorBoundary} from '@components/ErrorBoundary';
import TutorialButton from '@components/templates/TutorialButton';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectIsMenuCollapsed} from '@selectors/menu';
import {StudentSubroles} from '@src/types';
import {Box} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import MySidebar from './MySidebar/';

export const leftWidth = 275;
export const leftWidthCollapsed = 56;

function ChessTemplate(props) {
  const history = useHistory();
  const {children = <></>, active, onScroll = () => {}} = props;
  const dispatch = useStoreDispatch();

  const {currentUser, loading} = useStore((state) => state.users, shallowEqual);
  const isMenuCollapsed = useStore(selectIsMenuCollapsed);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <Wrapper className={props.className ? props.className + ' ChessTemplate' : 'ChessTemplate'}>
      <Left className="Left">
        <MySidebar
          active={active}
          currentUser={currentUser}
          loading={loading}
          onCurrentUserClick={() => {
            setIsEditModalOpen(true);
          }}
          onLogout={() => {
            dispatch(usersActions.logOut()).then(() => {
              history.push('/');
              setTimeout(() => {
                window.location.reload();
              }, 100);
            });
          }}
        />
      </Left>

      <Right
        className="Right"
        width={`calc(100% - ${isMenuCollapsed ? leftWidthCollapsed : leftWidth}px)`}
        onScroll={(e) => {
          onScroll(e.currentTarget);
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
        {currentUser?.subRole === StudentSubroles.AMATEUR && <TutorialButton />}
      </Right>
      <UpdateUserInfoForm
        isEditModalOpen={isEditModalOpen}
        isLoading={loading}
        setIsEditModalOpen={setIsEditModalOpen}
        user={currentUser}
      />
    </Wrapper>
  );
}

ChessTemplate.propTypes = {
  active: PropTypes.string.isRequired,
  onScroll: PropTypes.func,
  children: PropTypes.node,
};

export default ChessTemplate;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  *:not(.ScrollbarsCustom.native, .ScrollbarsCustom.native *)::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  *:not(.ScrollbarsCustom.native, .ScrollbarsCustom.native *)::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #e0e0e0;
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    .ScrollbarsCustom.native::-webkit-scrollbar-thumb,
    .ScrollbarsCustom.native *::-webkit-scrollbar-thumb {
      background: transparent;
    }
    .ScrollbarsCustom.native::-webkit-scrollbar,
    .ScrollbarsCustom.native *::-webkit-scrollbar {
      width: unset;
      height: unset;
    }
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    width: 100%;
    height: 100%;
  }
`;

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  height: 100vh;

  ${({theme}) => theme.breakpoints.between(481, 'xs')} {
    width: 195px;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    width: 100%;
    height: unset;
  }
`;

const Right = styled(Box)`
  display: inline-block;
  vertical-align: top;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden auto;
  box-sizing: border-box;
  padding: 0 ${({theme}) => theme.spacing(5)} ${({theme}) => theme.spacing(5)};
  ${({theme}) => theme.breakpoints.between(481, 'xs')} {
    width: calc(100% - 195px);
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    height: calc(100vh - 80px);
    width: 100%;
    padding: ${({theme}) => theme.spacing(3, 2)};
  }
`;
