import PsychoTestingAPI from '../../api/PsychoTestingAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке игры';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function handleChangeForm(newObj) {
  return (dispatch) => {
    dispatch({
      type: types.PSYCHO_TESTING_CHANGE_FORM,
      testingForm: newObj,
    });
  };
}

export function loadPlayerPsychoTestingForm(request) {
  return (dispatch) => {
    dispatch({
      type: types.PSYCHO_TESTING_LOADING_START,
    });
    return PsychoTestingAPI.getPlayerPsychoTestReport(request)
      .then((data) => {
        dispatch({
          type: types.PSYCHO_TESTING_LOADING_FORM,
          testingForm: data.data.dates[0],
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.PSYCHO_TESTING_LOADING_FINISH,
        });
      });
  };
}

export function postPlayerPsychoTestingForm(filter, psychoTestingForm) {
  return (dispatch) => {
    dispatch({
      type: types.PSYCHO_TESTING_LOADING_START,
    });
    return PsychoTestingAPI.postPsychoTestingForm(filter, psychoTestingForm)
      .then(() => {
        PsychoTestingAPI.getPlayerPsychoTestReport({
          playerId: filter?.student?.value,
          dates: filter?.report_dates?.value,
        }).then((data) => {
          dispatch({
            type: types.PSYCHO_TESTING_LOADING_FORM,
            testingForm: data.data.dates[0],
          });
        });
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Оценка сохранена');
      })
      .catch(function (err) {
        dispatch({
          type: types.PSYCHO_TESTING_LOADING_FINISH,
        });
        let errorText = 'Ошибка при сохранении';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export const loadPlayerPsychotestReport = (request) => (dispatch) => {
  dispatch({
    type: types.PSYCHO_TESTING_LOADING_START,
  });
  return PsychoTestingAPI.getPlayerPsychoTestReport(request).then((data) => {
    const playerPsychotestReportData = data.data;
    dispatch({
      type: types.PSYCHO_TESTING_LOAD_REPORT,
      playerPsychotestReport: playerPsychotestReportData.dates,
    });
  });
};

export function loadPlayerTypologicalProfileReport(request) {
  return (dispatch) => {
    dispatch({
      type: types.TYPOLOGICAL_PROFILE_LOADING_START,
    });
    return PsychoTestingAPI.getPlayerTypologicalProfileReport(request)
      .then((data) => {
        dispatch({
          type: types.TYPOLOGICAL_PROFILE_LOAD_REPORT,
          playerTypologicalProfileReport: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.TYPOLOGICAL_PROFILE_LOAD_REPORT,
          playerTypologicalProfileReport: {},
        });
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function cleanPsychoTestingReport() {
  return (dispatch) => {
    dispatch({
      type: types.PSYCHO_TESTING_LOAD_REPORT,
      playerPsychotestReport: undefined,
    });
  };
}

export const cleanPsychotestProfile = () => (dispatch) => {
  dispatch({
    type: types.PSYCHO_TESTING_LOADING_FORM,
    testingForm: {},
  });
};

export function cleanTypologicalProfileReport() {
  return (dispatch) => {
    dispatch({
      type: types.TYPOLOGICAL_PROFILE_LOAD_REPORT,
      playerTypologicalProfileReport: undefined,
    });
  };
}
