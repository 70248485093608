import {Box} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import styled from 'styled-components';

export const TitleText = styled(TypographyDefault)`
  font-size: 20px;
  line-height: 22px;
`;

export const BoxShadow = styled(Box)`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

export const Wrapper = styled.div`
  padding-top: 8px;
`;

export const Half = styled(Stack)`
  width: calc(50% - 4px);
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
