import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {getZone} from '@components/A1TagEventModal/utils';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {
  coords,
  gatesCoords,
  gatesMaxX,
  gatesMaxY,
  gatesMinX,
  gatesMinY,
  maxX,
  maxY,
  updatePayload,
} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {GatesSchema} from '@components/GatesSchema';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Divider, Typography} from '@ui/MUI';
import Checkbox from '@ui/MUI/Checkbox';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsA1Tag';
import FormControlLabel from '@ui/MUI/FormControlLabel';
import RadioGroup from '@ui/MUI/RadioGroup';
import Switch from '@ui/MUI/Switch';
import React, {useEffect, useState} from 'react';

interface actionNamesInterface {
  Missed: string;
  Blocked: string;
  'Shot on goal': string;
  Goal: string;
  Unsuccessful: string;
}

const actionNames: actionNamesInterface = {
  Missed: 'Shot wide',
  Blocked: 'Blocked shot',
  'Shot on goal': 'Shot on goal',
  Goal: 'Goal',
  Unsuccessful: 'Unsuccessful shot attempt',
};

interface actionTypesInterface {
  None: string;
  Slap: string;
  Wrist: string;
  Snap: string;
  Backhand: string;
  'Wrap around': string;
  'Tip-in': string;
  Deflection: string;
}

const actionNameOptions = [
  {value: 'Missed', label: 'Missed'},
  {value: 'Blocked', label: 'Blocked'},
  {value: 'Shot on goal', label: 'Shot on goal'},
  {value: 'Goal', label: 'Goal'},
  {value: 'Unsuccessful', label: 'Unsuccessful'},
];
const actionTypeOptions = [
  {value: 'None', label: 'None'},
  {value: 'Slap', label: 'Slap'},
  {value: 'Wrist', label: 'Wrist'},
  {value: 'Snap', label: 'Snap'},
  {value: 'Backhand', label: 'Backhand'},
  {value: 'Wrap around', label: 'Wrap around'},
  {value: 'Tip-in', label: 'Tip-in'},
  {value: 'Deflection', label: 'Deflection'},
];

export function A1TagShotModalContent() {
  useSignals();

  const payload = modalViewModel.payload.value ?? {};

  const {x, y} = coords.getCoords();

  const gatesX = gatesCoords.getCoords()?.x;
  const gatesY = gatesCoords.getCoords()?.y;

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const homePlayersOptions =
    uniqHomePlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const awayPlayersOptions =
    uniqAwayPlayers?.value?.map((player) => ({
      label: `${player.playerNumber}.${player.lastName} ${player.firstName}`,
      value: player.avangardId,
    })) || [];
  const isSelectedPlayerHome = homePlayersOptions?.map((option) => option.value)?.includes(payload?.player?.avangardId);

  const {currentTime} = useCurrentTime();

  const [isFixed, setIsFixed] = useState(true);

  const isShotOnGoal = payload.action_name === 'Shot on goal';
  const isGoal = payload.action_name === 'Goal';

  useEffect(() => {
    if (isFixed) {
      coords.updateEclipses(coords?.getEclipses()?.[0] ? [coords?.getEclipses()?.[0]] : []);
    }
  }, [isFixed]);

  return (
    <Box m={-4}>
      <Box
        sx={{
          px: 4,
          pt: 2,
          pb: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          backgroundColor: black[12],
          borderBottom: `1px solid ${black[25]}`,
        }}
      >
        <Typography sx={{color: black[60]}} variant="subtitle1">
          Time:
          <Typography component="span" sx={{pl: 1}} variant="subtitle1">
            {currentTime}
          </Typography>
        </Typography>
        <Box width={220}>
          <Autocomplete
            multiple={false}
            options={isSelectedPlayerHome ? homePlayersOptions : awayPlayersOptions}
            size="small"
            sx={{width: '100%'}}
            textFieldVariant="outlined"
            value={payload.player ?? null}
            width="100%"
            onChange={(_, v: {value: string; label: string}) => {
              const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
              const merged = {
                ...v,
                ...player,
              };
              updatePayload({
                ...payload,
                player: v ? merged : null,
              });
            }}
          />
        </Box>
      </Box>
      <Box sx={{px: 4, pb: 0}}>
        <Box display="flex">
          <Box sx={{flex: '0 0 60%', maxWidth: '60%'}}>
            <Typography color={grey[54]} py={0} variant="subtitle2">
              Type 1
            </Typography>
            <RadioGroup
              row
              checkboxes={actionNameOptions}
              color="primary"
              formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
              name="type"
              size="small"
              sx={{
                width: '100%',
                '.MuiFormControlLabel-root': {
                  mr: 2,
                },
              }}
              value={
                payload?.action_name
                  ? Object?.entries(actionNames)?.find((item) => item?.includes(payload?.action_name))?.[0]
                  : null
              }
              onChange={(_, v: keyof actionNamesInterface) => {
                if (v !== 'Blocked') {
                  updatePayload({
                    ...payload,
                    action_name: v ? actionNames?.[v] : null,
                    opponent: null,
                    opponent_id: null,
                    opponent_name: null,
                    opponent_team_name: null,
                    jersey_opponent: null,
                  });
                } else {
                  updatePayload({...payload, action_name: v ? actionNames?.[v] : null});
                }
              }}
            />
            {isGoal && (
              <>
                <Divider borderColor={black[25]} sx={{my: 0, mr: 4}} />
                <Box sx={{display: 'flex', gap: 2, pt: 0, pb: 2}}>
                  <Box width={220}>
                    <Typography sx={{mb: 0, color: black[60]}} variant="subtitle2">
                      Assistant 1
                    </Typography>
                    <Autocomplete
                      multiple={false}
                      options={
                        isSelectedPlayerHome
                          ? homePlayersOptions?.filter((option) => option?.value !== payload?.player?.avangardId)
                          : awayPlayersOptions?.filter((option) => option?.value !== payload?.player?.avangardId)
                      }
                      size="small"
                      sx={{width: '100%'}}
                      textFieldVariant="outlined"
                      value={payload.assistant1 ?? null}
                      width="100%"
                      onChange={(_, v: {value: string; label: string}) => {
                        const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                        const merged = {
                          ...v,
                          ...player,
                        };
                        updatePayload({
                          ...payload,
                          assistant1: v ? merged : null,
                        });
                      }}
                    />
                  </Box>
                  <Box width={220}>
                    <Typography sx={{mb: 0, color: black[60]}} variant="subtitle2">
                      Assistant 2
                    </Typography>
                    <Autocomplete
                      multiple={false}
                      options={
                        isSelectedPlayerHome
                          ? homePlayersOptions?.filter((option) => option?.value !== payload?.player?.avangardId)
                          : awayPlayersOptions?.filter((option) => option?.value !== payload?.player?.avangardId)
                      }
                      size="small"
                      sx={{width: '100%'}}
                      textFieldVariant="outlined"
                      value={payload.assistant2 ?? null}
                      width="100%"
                      onChange={(_, v: {value: string; label: string}) => {
                        const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                        const merged = {
                          ...v,
                          ...player,
                        };
                        updatePayload({
                          ...payload,
                          assistant2: v ? merged : null,
                        });
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box sx={{flex: '0 0 40%', maxWidth: '40%'}}>
            {(isShotOnGoal || isGoal) && (
              <Box sx={{pt: 2, display: 'flex', gap: 2}}>
                <GatesSchema
                  eclipses={gatesCoords.getEclipses()}
                  onClick={(e) => {
                    const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(
                      e,
                      gatesMaxX,
                      gatesMaxY,
                      gatesMinX,
                      gatesMinY,
                    );
                    const eclipse = {
                      realX,
                      realY,
                      x: cartesianX,
                      y: cartesianY,
                    };
                    gatesCoords.updateEclipses([eclipse]);
                    updatePayload({...payload, goal_spot_x: cartesianX, goal_spot_y: cartesianY});
                  }}
                  onMouseMove={(e) => {
                    const {cartesianX, cartesianY} = getCoordsFromEvent(e, gatesMaxX, gatesMaxY, gatesMinX, gatesMinY);
                    gatesCoords.updateCoords(cartesianX, cartesianY);
                  }}
                />
                <Box>
                  {isShotOnGoal && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isFixed}
                          color="primary"
                          size="small"
                          sx={{mx: '0px'}}
                          onChange={(_, v) => {
                            setIsFixed(v);
                          }}
                        />
                      }
                      label="Fixed"
                      sx={{ml: '0px'}}
                    />
                  )}
                  <Typography sx={{mt: 1, ml: 0, textAlign: 'center'}} variant="subtitle1">
                    ({gatesX.toFixed(2)}, {gatesY.toFixed(2)})
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {Object?.entries(actionNames)?.find((item) => item?.includes(payload?.action_name))?.[0] === 'Blocked' && (
          <Box alignItems="center" display="flex" gap={1} pb={1}>
            <Typography sx={{color: black[60]}} variant="subtitle2">
              Shot blocked by
            </Typography>
            <Box width={220}>
              <Autocomplete
                multiple={false}
                options={isSelectedPlayerHome ? awayPlayersOptions : homePlayersOptions}
                size="small"
                sx={{width: '100%'}}
                textFieldVariant="outlined"
                value={payload.opponent ?? null}
                width="100%"
                onChange={(_, v: {value: string; label: string}) => {
                  const player = allPlayers.value.find((p) => p?.avangardId === v?.value);
                  const merged = {
                    ...v,
                    ...player,
                  };
                  updatePayload({
                    ...payload,
                    opponent: v ? merged : null,
                  });
                }}
              />
            </Box>
          </Box>
        )}
        <Divider borderColor={black[25]} />
        <Box display="flex" gap={3}>
          <Typography color={grey[54]} py={1} variant="subtitle2" whiteSpace="nowrap">
            Type 2
          </Typography>
          <RadioGroup
            row
            checkboxes={actionTypeOptions}
            color="primary"
            formControlLabelProps={{slotProps: {typography: {variant: 'body2'}}}}
            name="type"
            size="small"
            sx={{width: '100%'}}
            value={payload.action_type ?? null}
            onChange={(_, v: keyof actionTypesInterface) => {
              updatePayload({...payload, action_type: v ? v : null});
            }}
          />
        </Box>
        <Divider borderColor={black[25]} sx={{mb: 0}} />
        <Box display="flex" gap={3}>
          <Typography color={grey[54]} maxWidth={48} py={1} variant="subtitle2">
            Marks
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={2} width="100%">
            <Checkbox
              label="Onetimer"
              size="small"
              onChange={(_, v) => {
                updatePayload({...payload, one_timer: !v ? null : v});
              }}
            />
            <Checkbox
              label="Goalie view"
              size="small"
              onChange={(_, v) => {
                updatePayload({...payload, goalie_view: !v ? null : v});
              }}
            />
            <Checkbox
              label="Rebound"
              size="small"
              onChange={(_, v) => {
                updatePayload({...payload, from_rebound: !v ? null : v});
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{px: 4}}>
        <Box sx={{position: 'relative', mb: 0}}>
          <Box
            mx="auto"
            px={4}
            py={1}
            sx={{
              position: 'relative',
              maxWidth: 550,
            }}
          >
            <Typography
              sx={{
                position: 'absolute',
                top: '45%',
                left: -44,
                transform: 'translateY(-50%) rotate(-90deg)',
                mb: 2,
                color: black[75],
                textTransform: 'uppercase',
              }}
              variant="overline2"
            >
              {homeTeam?.name}
            </Typography>
            <ArenaSchema
              eclipses={coords.getEclipses()}
              isShotOnGoal={isShotOnGoal}
              onClick={(e) => {
                const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                const eclipse = {
                  realX,
                  realY,
                  x: cartesianX,
                  y: cartesianY,
                };
                const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                if (coords?.getEclipses()?.length <= 1) {
                  coords.updateEclipses([eclipse]);
                } else {
                  coords.updateEclipses([eclipse, coords?.getEclipses()?.[1]]);
                }
                if (!zone?.length) {
                  updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY});
                } else {
                  updatePayload({...payload, pos_x: cartesianX, pos_y: cartesianY, zone_name: zone});
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                if (isShotOnGoal && !isFixed) {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };
                  const isHomeTeam = payload?.player?.group?.name === homeTeam?.name;
                  const zone = getZone({isHomeTeam: isHomeTeam, posX: cartesianX, posY: cartesianY});
                  coords.updateEclipses([coords.getEclipses()?.[0], eclipse]);

                  if (!zone?.length) {
                    updatePayload({...payload, opponent_pos_x: cartesianX, opponent_pos_y: cartesianY});
                  } else {
                    updatePayload({
                      ...payload,
                      opponent_pos_x: cartesianX,
                      opponent_pos_y: cartesianY,
                      zone_name: zone,
                    });
                  }
                }
              }}
              onMouseMove={(e) => {
                const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                coords.updateCoords(cartesianX, cartesianY);
              }}
            />
            <Typography sx={{pb: 0, textAlign: 'center'}} variant="subtitle1">
              ({x?.toFixed(1)}, {y?.toFixed(1)})
            </Typography>
            <Typography
              sx={{
                position: 'absolute',
                top: '45%',
                right: -24,
                transform: 'translateY(-50%) rotate(90deg)',
                mb: 2,
                color: black[75],
                textTransform: 'uppercase',
              }}
              variant="overline2"
            >
              {awayTeam?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
