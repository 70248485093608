import date from '@helpers/date';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {userRoles} from '@src/constants';
import {Box} from '@ui/MUI';
import LinkDefault from '@ui/MUI/Link';
import TooltipDefault from '@ui/MUI/Tooltip';
import React from 'react';
import styled from 'styled-components';

import DashboardsBlock from '../dashboards/trainers-dashboard/components/DashboardsBlock';
import TableEmptyBlock from '../ui/TableEmptyBlock';

const PastGamesOfSeason = (props) => {
  const {
    pastGames = [],
    hasHeadlineBlock = true,
    isWithoutButtons = false,
    isTeacherWhichCanEstimateGames,
    isTeacherWhichCanNotEstimateGames,
    isStudent = false,
    isMobile,
  } = props;

  const isFranchisee = useRoleAccess([userRoles.franchisee]);
  const canWatchLines = useRoleAccess([userRoles.franchisee, userRoles.teacher]);
  const canWatchStat = useRoleAccess([userRoles.franchisee, userRoles.psycho, userRoles.teacher, userRoles.student]);

  //ToDO: убрать и прикрутить новый бэк с нормальной структурой данных
  const calculateTimeEnd = (timeStart, duration) => {
    return timeStart + duration;
  };
  const formatGameName = (game) => {
    let name = '';
    if (game.type !== 'away_game') {
      name = `${game.group.name || ''} - ${game.opponent || ''}`;
    } else {
      name = `${game.opponent || ''} - ${game.group.name || ''}`;
    }
    return name;
  };

  return (
    <Box sx={{borderRadius: '6px', overflow: 'hidden'}}>
      <Wrapper className="PastGamesOfSeason">
        <DashboardsBlock
          withLargePadding
          buttonsBlockType="link"
          content={
            <ContentWrapper>
              {pastGames && pastGames?.length > 0 ? (
                <>
                  {hasHeadlineBlock && (
                    <BoxShadow>
                      <Headline>
                        <Column>
                          <HeadlineColumnTitle>Дата и время</HeadlineColumnTitle>
                        </Column>
                        <Column>
                          <HeadlineColumnTitle>Команды</HeadlineColumnTitle>
                        </Column>
                      </Headline>
                    </BoxShadow>
                  )}
                  <ContentBody isMobile={true}>
                    {pastGames?.map((game) => {
                      return (
                        <PastGame alignItems={!isStudent ? 'start' : 'center'} key={game.objectId || game._id}>
                          <Column>
                            <PastGameDate>
                              {date(game.timeStart || game.startTimestamp).format('D')}
                              <span>
                                {date(game.timeStart || game.startTimestamp).format('MMM') !== 'май'
                                  ? date(game.timeStart || game.startTimestamp)
                                      .format('MMM')
                                      .substring(
                                        0,
                                        date(game.timeStart || game.startTimestamp).format('MMM').length - 1,
                                      )
                                  : `${date(game.timeStart || game.startTimestamp)
                                      .format('MMM')
                                      .substring(
                                        0,
                                        date(game.timeStart || game.startTimestamp).format('MMM').length - 1,
                                      )}я`}
                              </span>
                            </PastGameDate>
                          </Column>
                          <Column alignSelf={isStudent && game.statusEstimateGame === 'new' ? 'baseline' : 'center'}>
                            <ContentText>
                              <span>
                                {date(game.timeStart || game.startTimestamp).format('HH:mm')} -{' '}
                                {date(
                                  game.timeEnd || calculateTimeEnd(game.startTimestamp, game.rooms[0].duration),
                                ).format('HH:mm')}
                              </span>
                            </ContentText>
                            {canWatchStat && (
                              <TooltipDefault
                                title={game.statisticsIsReady ? '' : 'Статистика для данной игры пока не готова'}
                              >
                                <LinkDefault
                                  disabled={!game.statisticsIsReady}
                                  to={
                                    game?.groupId
                                      ? `/reports/stats_report/match_info/${game?.statisticId || ''}?groupId=${game?.groupId}`
                                      : `/reports/stats_report/match_info/${game?.statisticId || ''}`
                                  }
                                >
                                  Посмотреть статистику
                                </LinkDefault>
                              </TooltipDefault>
                            )}
                          </Column>
                          <Column>
                            <ContentText>{game.teams || formatGameName(game) || ''}</ContentText>
                            {(isFranchisee || isTeacherWhichCanNotEstimateGames) &&
                            game.statusEstimateGame !== 'new' ? (
                              <LinkDefault
                                data-event-name={game.teams || formatGameName(game) || ''}
                                to={`/game_estimation_summary/${game.objectId || game._id}`}
                              >
                                Посмотреть оценки
                              </LinkDefault>
                            ) : null}
                            {(isTeacherWhichCanEstimateGames || isStudent) && game?.isCompletedEstimating && (
                              <>
                                <LinkDefault
                                  data-event-name={game.teams || formatGameName(game) || ''}
                                  to={`/game_estimation_summary/${game?.objectId || game?._id}`}
                                >
                                  Посмотреть оценки
                                </LinkDefault>
                                <LinkDefault to={`/game_estimation/${game?.objectId || game?._id}`}>
                                  Изменить оценки
                                </LinkDefault>
                              </>
                            )}
                            {canWatchLines && (
                              <LinkDefault
                                data-event-name={`${game?.teams || formatGameName(game) || ''}`}
                                to={`/lines?school=${game.schoolId}&team=${game.groupId}&history=true&gameId=${game.objectId}`}
                              >
                                Посмотреть состав
                              </LinkDefault>
                            )}
                            {game.streamingURL && (
                              <LinkDefault
                                component="a"
                                href={game.streamingURL}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Посмотреть трансляцию
                              </LinkDefault>
                            )}
                          </Column>
                        </PastGame>
                      );
                    })}
                  </ContentBody>
                </>
              ) : (
                <TableEmptyBlock text="В сезоне отсутствуют прошедшие игры" />
              )}
            </ContentWrapper>
          }
          isWithoutButtons={isWithoutButtons}
          title={hasHeadlineBlock && !isMobile ? 'Прошедшие игры сезона' : null}
          withLargeBorder={isMobile}
          withLargeBorderBottom={isMobile}
        />
      </Wrapper>
    </Box>
  );
};

const Wrapper = styled.div`
  max-height: 355px;
  overflow-y: auto;
  .PageTitleDefault {
    margin-top: 0;
    margin-bottom: 2px;
  }
  .EventModal {
    max-width: 60%;
    @media screen and (max-width: 767px) {
      max-width: none;
      width: 100% !important;
    }
    .sidebar__header {
      border-bottom: 0;
    }
    .Heading {
      @media screen and (max-width: 767px) {
        top: -8px;
      }
    }
  }
  .LocalPreloader {
    @media screen and (max-width: 767px) {
      top: 20px;
    }
  }
`;

const ContentText = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;

const ContentWrapper = styled.div`
  .TableEmptyBlock {
    padding-left: 34px;
    padding-right: 24px;
    border-top: 1px solid #dddad4;
    border-bottom: 1px solid #dddad4;
    justify-content: flex-start;
    min-height: 80px;
    p {
      @media screen and (max-width: 992px) {
        text-align: left;
      }
    }
  }
`;
const Headline = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 4px 24px;
  padding-left: 34px;
  background-color: white;
  border-top: 1px solid #dddad4;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;
const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  align-self: ${(props) => props.alignSelf || 'center'};
  &:first-child {
    ${ContentText} {
      &:last-child {
        padding-left: 12px;
      }
    }
  }
  & > a {
    &:last-child {
      padding-left: 0;
    }
    &:not(:last-child) {
      padding-right: 8px;
    }
  }
`;
const HeadlineColumnTitle = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
const ContentBody = styled.div`
  background-color: white;
  border-radius: ${(props) => props.isMobile && '15px'};
`;
const PastGame = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: ${(props) => props.alignItems};
  background-color: #f3f3f350;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
  &:nth-child(even) {
    background-color: #ffffff50;
  }
  ${Column} {
    padding: 12px 0;
    flex: 0 0 42%;
    max-width: 42%;
    &:first-child {
      flex: 0 0 8%;
      max-width: 8%;
      border-right: 2px solid #dddad4;
      @media screen and (max-width: 480px) {
        position: absolute;
        top: 0;
        left: 0;
        flex: initial;
        padding: 8px 0;
        min-width: 42px;
        max-width: none;
        height: 100%;
      }
      @media screen and (max-width: 360px) {
        left: 4px;
      }
    }
    &:nth-child(2) {
      padding-left: 16px;
      padding-right: 16px;
      @media screen and (max-width: 480px) {
        padding: 4px 16px 2px 70px;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &:last-child {
      flex: 0 0 50%;
      max-width: 50%;
      @media screen and (max-width: 480px) {
        padding: 2px 16px 12px 70px;
        flex: 0 0 100%;
        max-width: 100%;
      }
      ${ContentText} {
        @media screen and (max-width: 480px) {
          margin-bottom: 8px;
        }
      }
    }
  }
`;
const PastGameDate = styled.p`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 20px/1 'Bebas Neue';
  color: #2c2c2c;
  text-transform: uppercase;
  text-align: center;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 100%;
  }
  & > span {
    display: block;
    margin-top: 2px;
    margin-bottom: 0;
    min-width: 34px;
    font: 600 normal 11px/14px 'Proxima Nova';
    color: #686868;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
`;

const BoxShadow = styled.div`
  position: relative;
  @media screen and (max-width: 480px) {
    display: none;
  }
  &:after {
    display: block;
    width: 100%;
    height: 2px;
    box-shadow: 0 5px 5px -5px #333;
    content: '';
  }
`;

export default PastGamesOfSeason;
