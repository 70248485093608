import {signal} from '@preact/signals-react';

export const a1TagVideoPlayerHotKeysModal = {
  _isOpen: signal(false),

  get isOpen() {
    return this._isOpen.value;
  },

  close() {
    this._isOpen.value = false;
  },

  open() {
    this._isOpen.value = true;
  },
};

export const a1TagVideoPlayerHotKeysViewModel = {
  _currentKey: signal<string | null>(null),
  _currentSlug: signal<string | null>(null),

  get currentKey() {
    return this._currentKey.value;
  },

  setCurrentKey(key: string) {
    this._currentKey.value = key;
  },

  get currentSlug() {
    return this._currentSlug.value;
  },

  setCurrentSlug(slug: string) {
    this._currentSlug.value = slug;
  },
};
