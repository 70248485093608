import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {black} from '@ui/MUI/colorsA1Tag';
import React from 'react';

type A1TagEventContextMenuProps = {
  hasEdit: boolean;
  contextMenu: {
    mouseX: number;
    mouseY: number;
  };
  setContextMenu: React.Dispatch<
    React.SetStateAction<{
      mouseX: number;
      mouseY: number;
    }>
  >;
  onEdit: () => void;
  onDelete: () => void;
};
export function A1TagEventContextMenu({
  hasEdit = true,
  contextMenu,
  setContextMenu,
  onEdit,
  onDelete,
}: A1TagEventContextMenuProps) {
  const handleClose = () => {
    setContextMenu(null);
  };
  return (
    <Menu
      anchorPosition={contextMenu !== null ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined}
      anchorReference="anchorPosition"
      open={contextMenu !== null}
      sx={{'.MuiList-root': {backgroundColor: black[20]}}}
      onClose={handleClose}
    >
      {hasEdit && <MenuItem onClick={onEdit}>Edit</MenuItem>}
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Menu>
  );
}
