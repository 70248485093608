import SearchInput from '@common/SearchInput';
import {Box, Button} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import * as actions from '../../../redux/actions/SchoolsActions';
import UpdateSchoolForm from '../../schools/forms/UpdateSchoolForm';

export default function AdminAllSchoolsPanel(props) {
  const dispatch = useDispatch();
  const {id, schoolsIds = [], canAddSchool = true} = props;
  const [searchString, setSearchString] = useState('');
  const [addModalVisible, setAddModalVisible] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let {schools, trainers} = useSelector((state) => {
    let currentUser = state.users.usersMap.get(state.users.currentUserId);
    let userRole = currentUser?.userRole;
    let franchisees = state.users.usersMap.toArray().filter((u) => u.userRole === 'franchisee');
    let trainers = state.users.usersMap.toArray().filter((u) => u.userRole === 'teacher');
    if (canAddSchool === false && schoolsIds.length > 0) {
      schools = schools.filter((a) => schoolsIds.indexOf(a.id) > -1);
    }
    return {
      userRole: userRole,
      currentUser: state.users.usersMap.get(state.users.currentUserId),
      currentUserId: state.users.currentUserId,
      loading: state.users.loading,
      schools: state.schools.schoolsMap.toArray().sort((a, b) => +b.timestamp - +a.timestamp),
      franchisees: franchisees,
      trainers: trainers,
    };
  }, shallowEqual);
  const [debouncedSearch] = useDebouncedCallback(
    (a) => {
      setSearchString(a);
    },
    300,
    [],
  );

  const sortedSchools = schools
    .filter((a) => {
      let {name} = a;
      let s = `${name}`;
      if (searchString === undefined || searchString.trim() === '') {
        return true;
      }
      return s.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
    })
    .sort((a) => {
      let s1 = a.franchiseeId;
      let s2 = a.franchiseeId;
      if (s1 > s2) {
        return 1;
      }
      if (s1 < s2) {
        return -1;
      }
      return 0;
    });

  return (
    <Wrapper>
      <PageTitleDefault text={'Школы'} />
      <SchoolsBlock>
        <Heading>Список школ ({schools.length})</Heading>

        <TopSearchPlaceholder>
          <SearchInput
            inputProps={{variant: 'outlined'}}
            onChange={(value) => {
              debouncedSearch(value);
            }}
          />
        </TopSearchPlaceholder>

        <SchoolsList>
          {sortedSchools.map((c) => {
            let {id, name} = c;
            return (
              <SchoolItem key={id}>
                <Name>{name}</Name>
              </SchoolItem>
            );
          })}
        </SchoolsList>
        {canAddSchool && (
          <BtmPlaceholder p={3}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddModalVisible({...addModalVisible, right: true});
              }}
            >
              Добавить школу
            </Button>
          </BtmPlaceholder>
        )}
      </SchoolsBlock>
      <UpdateSchoolForm
        addModalVisible={addModalVisible}
        allTrainers={trainers}
        setAddModalVisible={setAddModalVisible}
        onSubmit={(d) => {
          d.franchiseeId = id;
          dispatch(actions.createSchool(d)).then(() => {
            setAddModalVisible(false);
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .AddSchool {
    border-radius: 6px 0px 0px 6px;
    .sidebar__header {
      margin-bottom: 0;
      min-height: 36px;
    }
  }
`;

const SchoolsBlock = styled.div`
  width: calc(100% - 4px);
  margin-top: 4px;
  margin-left: 2px;
  border-radius: 4px;
`;

const SchoolsList = styled.div``;

const SchoolItem = styled.div`
  padding: 0px 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #626262;
  background: #f3f3f3;
  :first-child {
    padding-top: 0px;
  }
  :last-child {
    border-radius: 0 0 4px 4px;
  }
  :nth-child(odd) {
    background-color: white;
  }
`;

const Name = styled.div`
  font-weight: bold;
  width: 50%;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;

const Heading = styled.div`
  padding: 20px 34px;
  border-radius: 4px 4px 0 0;
  font: bold 16px/20px 'Proxima Nova';
  text-transform: uppercase;
  color: #2c2c2c;
  background: #fffaed;
  letter-spacing: 0.02em;
`;

const TopSearchPlaceholder = styled.div`
  padding: 24px 20px;
  background: white;
`;

const BtmPlaceholder = styled(Box)`
  background: white;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px -9px 10px -7px rgba(0, 0, 0, 0.1);
`;
