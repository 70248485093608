import PersonalFileVideoList from '@components/apps/PersonalFileVideoList';
import BodyMassIndex from '@components/apps/PlayerPersonalFile/components/BodyMassIndex';
import PersonalFileDevelopmentJournal from '@components/apps/PlayerPersonalFile/components/DevelopmentJournal';
import FunctionalTesting from '@components/apps/PlayerPersonalFile/components/FunctionalTesting';
import MainInfo from '@components/apps/PlayerPersonalFile/components/MainInfo';
import OFP from '@components/apps/PlayerPersonalFile/components/OFP';
import OtherTab from '@components/apps/PlayerPersonalFile/components/OtherTab';
import Statistics from '@components/apps/PlayerPersonalFile/components/Statistics';
import TrainerReport from '@components/apps/PlayerPersonalFile/components/TrainerReport';
import {userRoles as ur} from '@src/constants';
import React from 'react';

export const MAIN_TABS = [
  {value: <MainInfo />, id: 'main', label: 'Главное'},
  {value: <PersonalFileDevelopmentJournal />, id: 'developmentJournal', label: 'Журнал развития'},
  {
    label: 'Видеопортфолио',
    id: 'videoanalytics',
    value: <PersonalFileVideoList />,
  },
  {
    label: 'Антропометрия',
    id: 'bmi',
    value: <BodyMassIndex />,
  },
  {
    label: 'Статистика',
    id: 'statistics',
    value: <Statistics />,
  },
  {label: 'ФТ', id: 'functionalTesting', value: <FunctionalTesting />},
  {label: 'ОФП/СФП', id: 'ofp', value: <OFP />},
  {
    label: 'Характеристика игрока',
    id: 'trainerReport',
    value: <TrainerReport />,
    roleAccess: [ur.franchisee, ur.teacher, ur.scout, ur.psycho, ur.admin],
  },
  {label: 'Общее', id: 'common', value: <OtherTab />},
];
