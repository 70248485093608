import {createSkillTypes, deleteSkillTypes, getProspectSkillsByUserId, getProspectSkillTypes} from '@api/prospect.api';
import {CreateSkillTypeDto} from '@src/types/generated';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export const useProspectSkills = (query: {userId?: string; season?: string; isConstant?: boolean}) => {
  return useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['prospect', 'skills', query],
    queryFn: () => getProspectSkillsByUserId(query),
    enabled: !!query.userId,
  });
};

export const useProspectSkillTypes = (params: {isConstant?: boolean}) => {
  return useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['prospect', 'skill-types', params],
    queryFn: () => getProspectSkillTypes(params),
  });
};

export const useMutateCreateProspectSkillTypes = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {skills: CreateSkillTypeDto[]}) => createSkillTypes(userId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'skill-types']});
      queryClient.invalidateQueries({queryKey: ['prospect', 'skills']});
    },
  });
};

export const useMutateDeleteProspectSkillTypes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteSkillTypes(id),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['prospect', 'skill-types']});
      queryClient.invalidateQueries({queryKey: ['prospect', 'skills']});
    },
  });
};
