import {useSeasons} from '@components/modules/season/queries/seasons.query';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Button, Stack} from '@ui/MUI';
import React from 'react';

import {gamesReportModel} from '../models/games-report.model';
import {reportDrawer} from '../models/drawer.model';

export const Filters = () => {
  useSignals();
  const seasonQuery = useSeasons();
  const filters = gamesReportModel.filters;
  const seasons = seasonQuery.data ?? [];
  const seasonId = filters.seasonId;
  const season = seasons.find((s) => s.id === seasonId);

  const handleOpenDrawer = () => {
    reportDrawer.open('create');
  };

  const handleChangeSeason = (v: unknown) => {
    const id = (v as {id: string} | null)?.id ?? null;
    filters.updateSeasonId(id);
  };

  return (
    <Stack
      alignItems="center"
      borderRadius={2}
      direction="row"
      justifyContent="space-between"
      px={4}
      py={3}
      sx={{background: 'white'}}
    >
      <Autocomplete
        label="Сезон"
        multiple={false}
        options={seasons}
        sx={{maxWidth: '120px'}}
        value={season}
        onChange={(_, v) => {
          handleChangeSeason(v);
        }}
      />
      <Button color="primary" variant="contained" onClick={handleOpenDrawer}>
        Добавить отчет
      </Button>
    </Stack>
  );
};
