import ReactTableComponent from '@common/ReactTableComponent';
import RadioRange from '@common/TrainingControl/RadioRange';
import {useProspectSkills, useProspectSkillTypes} from '@components/modules/prospects/queries/prospect-skill.query';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {longDash} from '@src/constants';
import {SkillCreateDto, SkillDto, SkillTypeDto} from '@src/types/generated';
import {ColumnDef} from '@tanstack/react-table';
import {Box, Button, Stack, Typography} from '@ui/MUI';
import React, {useEffect, useMemo} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {skillAnalyticsModel} from '../models/skill-analytics.model';
import {createColumns, toSkills} from '../helpers/table.helper';
import {useCreateProspectSkills} from '@components/modules/prospects/mutation/create-prospect-skill.mutation';
import LoadingButton from '@ui/MUI/LoadingButton';
import {NoData} from '@components/modules/prospects/components/NoData';
// react-table
const columns: ColumnDef<SkillDto>[] = [
  {
    accessorFn: (row) => row.skillType.name,
    headerStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    cellStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    header: 'Навык',
  },
  {
    accessorFn: (row) => row.skillType.description,
    headerStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    cellStyle: {whiteSpace: 'normal', alignItems: 'flex-start', maxWidth: 210, minWidth: 210},
    header: 'Описание',
  },
  {
    accessorFn: (row) => row.startScore,
    header: 'Начало сезона',
  },
];

const mock = [
  {
    id: '1',
    startScore: 0,
    endScore: 3,
    skillType: {
      id: '1',
      name: 'Скорость / Ускорение',
      description:
        'Движение игрока: умение оторваться от соперника или догнать его, умение набрать максимальную скорость , умение ускориться по ходу движения',
    },
  },
];

export interface FormData {
  skills: SkillDto[];
}

export const Table = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const form = useForm<FormData>({
    defaultValues: {
      skills: [],
    },
  });

  const isEditMode = skillAnalyticsModel.table.isEditMode;
  const seasonId = skillAnalyticsModel.filters.seasonId;

  const columnsWithSaving = useMemo(() => {
    return createColumns(isEditMode);
  }, [isEditMode]);

  const skillsQuery = useProspectSkills({
    userId: playerId,
    isConstant: false,
    season: seasonId,
  });

  const skillTypesQuery = useProspectSkillTypes({
    isConstant: false,
  });

  const skills = toSkills(skillTypesQuery.data ?? [], skillsQuery.data ?? []);

  const skillsMutation = useCreateProspectSkills(playerId);
  const onSubmit = async (data: FormData) => {
    await skillsMutation.mutateAsync(
      data.skills.map((skill) => ({
        ...skill,
        prospectId: playerId,
        skillTypeId: skill.skillType.id,
      })),
    );

    skillAnalyticsModel.table.isEditMode = false;
  };

  useEffect(() => {
    if (skills.length) {
      form.setValue('skills', skills);
    }
  }, [skills]);

  const isEmpty = !skills.length;

  return (
    <FormProvider {...form}>
      <Stack>
        <Box borderTop="1px solid #E0E0E0">
          {isEmpty ? <NoData /> : <ReactTableComponent columns={columnsWithSaving} data={skills} />}
        </Box>
        {isEditMode && (
          <Stack direction="row" justifyContent="space-between" px={5} py={3}>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={skillsMutation.isLoading}
            >
              Сохранить
            </LoadingButton>
            <Button
              variant="contained"
              onClick={() => {
                skillAnalyticsModel.table.isEditMode = false;
                // form.setValue('skills', mock);
              }}
            >
              Отменить
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
};
