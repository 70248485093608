import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import React from 'react';

export const columns = [
  {
    accessorKey: 'name',
    header: 'Тренировка',
    cell: ({getValue}) => <Typography variant="body2">{getValue() || longDash}</Typography>,
  },
  {
    accessorKey: 'formatedDate',
    header: 'Дата',
    cell: ({getValue}) => <Typography variant="body2">{getValue() || longDash}</Typography>,
  },
  {
    accessorKey: 'fullTime',
    header: 'Время',
    cell: ({getValue}) => <Typography variant="body2">{getValue() || longDash}</Typography>,
  },
  {
    accessorKey: 'author',
    header: 'Тренер',
    cell: ({getValue}) => <Typography variant="body2">{getValue()?.name || longDash}</Typography>,
  },
];
