import {Stack} from '@mui/material';
import React from 'react';

import {ActiveGoals} from './components/active-goals.component';
import {CompleteGoalDrawer} from './components/complete-goal-drawer.component';
import {GoalsHistory} from './components/goals-history.component';
import {NewGoalDrawer} from './components/new-goal-drawer.component';

export const CompetitiveActivitiesTab = () => {
  return (
    <>
      <NewGoalDrawer />
      <CompleteGoalDrawer />
      <Stack spacing={4}>
        <ActiveGoals />
        <GoalsHistory />
      </Stack>
    </>
  );
};
