import {Box} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: ${(props) => props.padding || '24px'};
  overflow: hidden;
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;

export const StyledPageTitleDefault = styled((props) => <PageTitleDefault {...props} />)`
  padding-right: 34px;
  padding-left: 34px;
  margin-left: 0;
  margin-bottom: ${(props) => props.marginBottom || '12px'};
  @media screen and (max-width: 1024px) {
    margin-left: 24px;
  }
`;

export const StyledButton = styled(ButtonDefault)`
  margin-top: 12px !important;
  margin-bottom: 16px !important;
  margin: ${(props) => props?.margin} !important;

  &:first-child {
    margin-left: 0;
  }

  .plus {
    width: 10px;
    height: 10px;
    position: relative;
    margin-right: 10px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: #fff;
    }
  }
`;

export const ShowButton = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #2c2c2c;
  cursor: pointer;
  .BreakLinkStyle {
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const FixedInput = styled.div`
  max-width: ${(props) => props?.maxWidth};
  overflow: hidden;
  .label {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #747474;
    display: block;
  }
  .text {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #2c2c2c;
    display: block;
  }
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  align-items: ${(props) => props?.align || 'center'};
  justify-content: ${(props) => props.justify};
  gap: ${(props) => props?.gap || '24px'};
  margin: ${(props) => props?.margin};
`;

export const InputWrapper = styled.div`
  flex: ${(props) => props?.flex};
`;

export const Half = styled.div`
  width: calc(50% - 24px / 2);
`;

export const NoData = styled.div`
  width: 100%;
  height: 100px;
  max-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.minHeight || '250px'};
`;

export const ModalTitle = styled.p`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #2c2c2c;
  margin: 0;
  margin-bottom: 24px;
`;

export const Cell = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
`;
