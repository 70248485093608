export const ZONES = [
  {name: 'OZ', posX: 50, posY: 7.5},
  {name: 'OZ', posX: 50, posY: 20.5},
  {name: 'NZ', posX: 37.3, posY: 7.5},
  {name: 'NZ', posX: 37.3, posY: 20.5},
  {name: 'NZ', posX: 30, posY: 14},
  {name: 'NZ', posX: 22.9, posY: 7.5},
  {name: 'NZ', posX: 22.9, posY: 20.5},
  {name: 'DZ', posX: 10.1, posY: 7.5},
  {name: 'DZ', posX: 10.1, posY: 20.5},
];

export const ZONES_COORIDINATES = [
  {minX: 0, maxX: 10.1, minY: 0, maxY: 7.5},
  {minX: 0, maxX: 10.1, minY: 7.5, maxY: 20.5},
  {minX: 10.1, maxX: 22.9, minY: 0, maxY: 7.5},
  {minX: 10.1, maxX: 22.9, minY: 7.5, maxY: 20.5},
  {minX: 22.9, maxX: 30, minY: 0, maxY: 14},
  {minX: 30, maxX: 37.3, minY: 0, maxY: 7.5},
  {minX: 30, posX: 37.3, minY: 7.5, maxY: 20.5},
  {minX: 37.3, maxX: 60, minY: 0, maxY: 7.5},
  {minX: 37.3, maxX: 60, minY: 7.5, maxY: 20.5},
];
