import ReactTableComponent from '@common/ReactTableComponent';
import useEvent from '@hooks/useEvent';
import {useRoleAccess} from '@hooks/useRoleAccess';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import {userRoles} from '@src/constants';
import {Box, Button, Typography} from '@ui/MUI';
import React, {useEffect, useMemo, useState} from 'react';

import {useNotEstimatedTraining} from '../../hooks/api';
import LoadingHolder from '../LoadingHolder';
import Filters from './components/Filters';
import TrainingForm from './components/TrainingForm';
import {columns} from './constants';
import {NoData} from './Control';

const Control = () => {
  const [filters, setFilters] = useState({
    school: null,
    group: null,
    period: [null, null],
  });

  const {data, isLoading} = useNotEstimatedTraining();
  const dispatch = useStoreDispatch();
  const toggle = useToggle();

  const fetcher = useEvent(() => {
    const [startDate, endDate] = filters.period;
    if (startDate && !endDate) return;
    const payload = {
      school: filters.school?.value,
      group: filters.group?.value,
      startDate: startDate?.startOf('day')?.valueOf(),
      endDate: endDate?.endOf('day')?.valueOf(),
    };
    dispatch(fetcherTrainingControl('training_not_estimated', payload));
  });

  useEffect(() => {
    fetcher();
  }, [filters]);

  const openForm = useEvent((id) => {
    dispatch(fetcherTrainingControl('training', id));
    toggle.on();
  });

  const canEdit = useRoleAccess([userRoles.teacher, userRoles.methodist]);

  const component = useMemo(() => {
    if (isLoading) return <LoadingHolder />;

    if (data.length)
      return (
        <ReactTableComponent
          columns={
            canEdit
              ? [
                  ...columns,
                  {
                    id: 'action',
                    accessorKey: 'actions',
                    header: '',
                    cell: ({row}) => (
                      <Button color="primary" size="small" variant="text" onClick={() => openForm(row.original.id)}>
                        оценить
                      </Button>
                    ),
                  },
                ]
              : [...columns]
          }
          data={data}
        />
      );

    return (
      <NoData>
        <Typography>Данные не найдены</Typography>
      </NoData>
    );
  }, [isLoading, data, openForm, toggle, fetcher]);

  return toggle.isToggle ? (
    <TrainingForm close={toggle.off} refetch={fetcher} />
  ) : (
    <>
      <Filters filters={filters} updateFilters={setFilters} />
      <Box bgcolor="#fff" borderRadius="6px" mt={1}>
        {component}
      </Box>
    </>
  );
};

export default Control;
