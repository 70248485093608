import ThemeLayout from '@components/modules/common/ThemeLayout';
import useStore from '@hooks/useStore';
import {StudentSubroles} from '@src/types';
import qs from 'query-string';
import React from 'react';
import {useHistory} from 'react-router-dom';

import VideoPanel from '../../VideoPanel';

export default function VideoApp() {
  const {location} = useHistory();
  const {isStudent, studentId, isPreview} = qs.parse(location.search);

  const currentUser = useStore((state) => state.users.currentUser);
  const isAmateur = currentUser?.subRole === StudentSubroles.AMATEUR;

  return (
    <ThemeLayout active="video" isNew={isAmateur}>
      <VideoPanel isPreview={isPreview} isStudent={isStudent} studentId={studentId} />
    </ThemeLayout>
  );
}
