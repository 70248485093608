import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {actions} from '@components/apps/FunctionalTesting/constants';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import date from '@helpers/date';
import {useSeasons} from '@hooks/seasonsHooks';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {Box, FormControlLabel, Icon, useMediaQuery} from '@mui/material';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {fetchStates} from '@slices/functionalTesting';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import ButtonDefault from '@ui/MUI/Button';
import Checkbox from '@ui/MUI/Checkbox';
import Typography from '@ui/MUI/Typography';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import {formatStatesOptions, hideEmptyPlayersInFunctionalStateDynamic} from '../../helpers';
import {EmptyValue, TableContainer, TableWrapper} from '../Report/FunctionalStateTable/FunctionalStateTable';
import {InfoBlock} from '../Report/Report';
import {TableCellFlexContainer} from '../Report/ReportTable/ReportTable';
import {ButtonsContainer} from '../ReportsInformation/ReportsInformation';
import {FunctionalStateIndicator} from '../ReportsInformation/ReportsInformationTable/ReportsInformation';

const FunctionalStateTableInStagedTesting = ({canEdit, tableData, selectedSeason, setSelectedSeason}) => {
  const history = useHistory();
  const {teamId, reportDate} = useParams();
  const currentDate = date().format('YYYY-MM-DD');

  const seasons = useSeasons();

  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const {states} = useStore(selectTableEditCreate);
  const functionalStateOptions = states && formatStatesOptions(states.functional);

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);

  const [bottomBlockRef, {height}] = useElementSize();

  const [isEmptyPlayersHide, setIsEmptyPlayersHide] = useState(true);

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  const uniqueDates = [
    ...new Set(
      tableData
        ?.map((item) => {
          return item.functionalStates?.map((param) => param.date);
        })
        .flat()
        .sort(function (a, b) {
          if (a > b) {
            return -1;
          }
          if (b > a) {
            return 1;
          }
          return 0;
        }),
    ),
  ];

  const columns = [
    {
      accessorKey: 'player',
      header: (
        <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
          Игрок
        </ToggledHeader>
      ),
      cell: (info) => {
        return (
          <TableCellFlexContainer isToggled={isToggled}>
            <PlayersHorizontalCard
              hasImage={!isToggled}
              isShortName={isSmResolution}
              onlyNumber={isToggled}
              student={info.getValue()}
            />
          </TableCellFlexContainer>
        );
      },
      sortingFn: 'defaultSorting',
      sticky: {left: true},
    },
    ...uniqueDates.map((dateOfTesting) => {
      return {
        accessorKey: date(dateOfTesting).format('YYYY-MM-DD'),
        header: () => {
          return (
            <>
              <span>{date(dateOfTesting).format('DD.MM.YYYY')}</span>
              {canEdit && (
                <Icon
                  component={EditIcon}
                  fontSize="small"
                  sx={{cursor: 'pointer'}}
                  onClick={() => {
                    history.push(
                      `/functional_testing_create/staged_testing/${teamId}/${actions.actionEdit}/${date(
                        dateOfTesting,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }}
                />
              )}
            </>
          );
        },
        sortable: false,
        cell: (info) => {
          return info.getValue() ? <FunctionalStateIndicator type={info.getValue()} /> : <EmptyValue>–</EmptyValue>;
        },
      };
    }),
  ];

  const formattedTableData = tableData?.map((item) => {
    const dates = item?.functionalStates?.map((param) => {
      return date(param.date).format('YYYY-MM-DD');
    });
    const formattedDates = dates?.reduce((res, dateOfTesting) => {
      res[dateOfTesting] = item?.functionalStates?.find((param) => {
        return date(param.date).format('YYYY-MM-DD') === dateOfTesting;
      })?.functional;
      return res;
    }, {});
    return {
      player: {
        id: item.playerId,
        firstName: item.firstName,
        lastName: item.lastName,
        playerNumber: item.playerNumber,
        avatar: item.avatar,
      },
      ...formattedDates,
    };
  });

  return (
    <TableContainer>
      <InfoBlock sx={{justifyContent: 'space-between'}}>
        <AutocompleteDefault
          isSelectableAll
          label="Сезон"
          limitTags={1}
          name="season"
          options={seasons?.options}
          value={selectedSeason}
          onChange={(_, newValue) => {
            setSelectedSeason(newValue);
          }}
        />
        <Box display="flex" flexWrap="wrap" width={isSmResolution ? '100%' : 'auto'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEmptyPlayersHide}
                size="small"
                onChange={() => setIsEmptyPlayersHide(!isEmptyPlayersHide)}
              />
            }
            label="Скрыть игроков без данных"
            labelPlacement="end"
            value="isPlayersHidden"
          />
          {canEdit && (
            <ButtonsContainer sx={{width: isSmResolution ? '100%' : 'auto'}}>
              <ButtonDefault
                color="primary"
                size="medium"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => {
                  history.push(
                    `/functional_testing_create/staged_testing/${teamId}/${actions.actionAdd}/${
                      reportDate || currentDate
                    }`,
                  );
                }}
              >
                Добавить отчет
              </ButtonDefault>
            </ButtonsContainer>
          )}
        </Box>
      </InfoBlock>
      {tableData?.length > 0 ? (
        <TableWrapper>
          <ReactTableComponent
            bottomBlockHeight={height + 32}
            columns={columns}
            data={hideEmptyPlayersInFunctionalStateDynamic(isEmptyPlayersHide, formattedTableData)}
            isAvailableHeight={true}
            setSorting={setSorting}
            sorting={sorting}
            tableBodyCellProps={{
              sx: {
                boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
                minWidth: 240,
                width: '100%',
                '&:first-child.sticky-left': {
                  width: '300px',
                },
                '&:nth-child(2)': {
                  boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
                  '&.lastRowCell': {
                    boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
                  },
                },
                '@media screen and (max-width: 767px)': {
                  minWidth: 'initial',
                  width: !isToggled ? '300px !important' : '56px !important',
                  maxWidth: !isToggled ? 300 : 56,
                  fontSize: '12px !important',
                  '&:not(:first-child)': {
                    minWidth: 'initial',
                    width: '160px !important',
                    maxWidth: 160,
                  },
                },
              },
            }}
            tableHeaderCellProps={{
              sx: {
                minWidth: 240,
                width: '100%',
                '&:first-child.sticky-left': {
                  display: 'flex',
                  width: '300px',
                },
                '&:nth-child(2)': {
                  boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset',
                },
                '@media screen and (max-width: 767px)': {
                  width: !isToggled ? '300px !important' : '56px !important',
                  minWidth: 'initial',
                  maxWidth: !isToggled ? 300 : 56,
                  fontSize: '12px !important',
                  '&:not(:first-child)': {
                    minWidth: 'initial',
                    width: '160px !important',
                    maxWidth: 160,
                  },
                },
              },
            }}
          />
        </TableWrapper>
      ) : (
        <TableEmptyBlock text="Отсутствуют данные за выбранные сезоны." />
      )}
      <InfoBlock ref={bottomBlockRef}>
        <Typography variant="subtitle2" weight={600}>
          Значения индикаторов
        </Typography>
        {functionalStateOptions?.map((option) => {
          return (
            <Typography key={option.value} variant="body2" weight={400}>
              <FunctionalStateIndicator type={option.value} />
              {option.label}
            </Typography>
          );
        })}
      </InfoBlock>
    </TableContainer>
  );
};

export default FunctionalStateTableInStagedTesting;

FunctionalStateTableInStagedTesting.propTypes = {
  canEdit: PropTypes.bool,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      functional: PropTypes.number,
      groupId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      player: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        playerNumber: PropTypes.string,
        avatar: PropTypes.string,
      }),
    }),
  ).isRequired,
  selectedSeason: PropTypes.array,
  setSelectedSeason: PropTypes.func,
};
