import {Box} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as groupsActions from '../../../redux/actions/GroupsActions';
import AboutGroup from '../../groups/components/group/about-group/index';
import PlayersComposition from '../../groups/components/group/players-composition/index';
import GroupScoutReport from '../components/group/GroupScoutReport';

export default function GroupPanel(props) {
  let {id} = props;

  const dispatch = useDispatch();

  const group = useSelector((state) => state.groups.group);
  const loading = useSelector((state) => state.groups.loading);
  useEffect(() => {
    if (id) {
      dispatch(groupsActions.getGroupById(id));
    }
  }, [id]);

  const groupsTabs = [
    {id: 'about', label: 'О команде', value: <AboutGroup group={group} loading={loading} />},
    {id: 'composition', label: 'Состав', value: <PlayersComposition group={group} loading={loading} />},
    {id: 'trainer_report', label: 'Характеристика игрока', value: <GroupScoutReport groupId={group?.id} />},
  ];

  return (
    <Box>
      <PageTitleDefault>{group?.name ? group.name : 'Команда'}</PageTitleDefault>
      <TabsNew withoutWrapper tabs={groupsTabs} />
    </Box>
  );
}
