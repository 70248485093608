import date from '@helpers/date';
import BarChartIcon from '@mui/icons-material/BarChart';
import {Box} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

function GameField({
  score,
  diagram,
  opponents,
  gameDate,
  statisticId = '',
  teamMKCId = '',
  isFinal,
  season = '',
  isLastItem,
  isV2,
}: {
  score: number[];
  diagram: React.ReactChildren | React.ReactNode | null;
  opponents: string;
  gameDate: string;
  statisticId: string;
  teamMKCId: string;
  isFinal: boolean | '';
  season: string;
  isLastItem: boolean;
  isV2?: boolean;
}) {
  return (
    <Box display="table-row">
      <Typography sx={{display: 'table-cell', width: '1%', pr: 0}} variant="body2">
        {gameDate ? date(gameDate).format('DD.MM.YY') : date().format('DD.MM.YY')}
      </Typography>

      <Box display="table-cell">
        <Box borderLeft={`1px solid ${grey[24]}`} height="100%" pl={0} width="100%">
          <Typography display="table-cell" variant={isV2 ? 'subtitle2' : 'body2'}>
            {opponents}
          </Typography>
        </Box>
      </Box>
      <Box display="table-cell" sx={{pb: isLastItem ? '0' : 2}} textAlign="right" width="1%">
        <Stack alignItems="center" direction="row" display="inline-flex" justifyContent="space-between" width="100%">
          {diagram}
          <Stack alignItems="center" direction="row" ml={0} mr={2}>
            <Typography color={score?.[0] < score?.[1] && grey[54]} component="span" variant="subtitle2">
              {score?.[0]}
            </Typography>
            <Typography color={grey[54]} component={'span'} variant="subtitle2">
              :
            </Typography>
            <Typography color={score?.[0] > score?.[1] && grey[54]} component={'span'} variant="subtitle2">
              {score?.[1]}
            </Typography>
          </Stack>

          <StyledButton
            color="primary"
            component={Link}
            disabled={!statisticId}
            size="small"
            sx={{opacity: statisticId ? 1 : 0}}
            to={`/reports/stats_report/match_info/${statisticId}?teamMKCId=${teamMKCId}&isFinal=${isFinal}&season=${season}`}
            variant="outlined"
          >
            <BarChartIcon fontSize="inherit" />
          </StyledButton>
        </Stack>
      </Box>
    </Box>
  );
}

export default GameField;
const StyledButton = styled(ButtonDefault)`
  padding: ${({theme}) => theme.spacing(0)};
  min-width: unset;
`;
