import {groupEstimatesByCategories, groupEstimatesByPlayers} from '@common/DevelopmentJournal/helpers';
import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

import GroupsAPI from './GroupsAPI';

const baseUrl = `${API_ENDPOINT}/v1/skill`;
const defaultErrorMessage = 'Что-то пошло не так';

export const getCategories = () => axios.get(`${baseUrl}/category`).then((res) => res.data);

export const getEstimates = (params) =>
  axios.get(`${baseUrl}/estimate`, {params}).then((res) => {
    return {
      ...res.data,
      estimates: res.data.estimates.map((estimate) => {
        return {...estimate, specialists: estimate.specialists.filter((specialist) => specialist !== null)};
      }),
    };
  });

export const getEstimatesGroupedByPlayers = async ({groupsIds, categoriesIds, specialistsIds}) => {
  const estimatesPromise = getEstimates({groupsIds, categoriesIds, specialistsIds, isCompleted: false});
  const studentsPromise = GroupsAPI.getGroupsStudents(groupsIds);

  const [{estimates}, students] = await Promise.all([estimatesPromise, studentsPromise]);

  const grouped = groupEstimatesByPlayers(estimates, students);
  const defaultEstimates = grouped?.map((student) => {
    const playerEstimates = estimates?.filter((item) => item.student.id === student.id);
    const countOfPlayersEstimates = playerEstimates?.length;
    return {
      ...student,
      countOfPlayersEstimates,
    };
  });
  if (
    (!categoriesIds?.length && specialistsIds?.length > 0) ||
    (categoriesIds?.length > 0 && !specialistsIds?.length)
  ) {
    return defaultEstimates?.filter((student) => student?.estimates?.length > 0);
  } else {
    return defaultEstimates;
  }
};

export const getEstimatesGroupedByCategories = async (params) => {
  const estimatesPromise = getEstimates(params);
  const categoriesPromise = getCategories();

  const [{estimates}, categories] = await Promise.all([estimatesPromise, categoriesPromise]);
  const grouped = groupEstimatesByCategories(estimates, categories);

  return grouped;
};

export const updateEstimate = (id, data) =>
  axios
    .put(`${baseUrl}/estimate/${id}`, data, {
      success: {message: 'Оценка успешно обновлена'},
      failed: (err) => ({message: err.data.message || defaultErrorMessage}),
    })
    .then((res) => res.data);

export const createEstimate = (data) =>
  axios
    .post(`${baseUrl}/estimate`, data, {
      success: {message: 'Оценка успешно создана'},
      failed: (err) => ({message: err.data.message || defaultErrorMessage}),
    })
    .then((res) => res.data);

export const deleteEstimate = (id) =>
  axios
    .delete(
      `${baseUrl}/estimate/${id}`,
      {},
      {
        success: {message: 'Оценка успешно удалена'},
        failed: (err) => ({message: err.data.message || defaultErrorMessage}),
      },
    )
    .then((res) => res.data);

export const getPersonalDevelopmentJournalPlayerSkills = (id) =>
  axios.get(
    `${baseUrl}/player/${id}/profile`,
    {},
    {
      failed: (err) => ({message: err.data.message || defaultErrorMessage}),
    },
  );

export const getSkillTrainingStickerByLessonId = (lessonId) =>
  axios.get(`${baseUrl}/training-sticker/${lessonId}`).then((res) => res.data);
