import date from '@helpers/date';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import React from 'react';

export const columns = [
  {
    accessorKey: 'school',
    header: 'Школа',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue()?.name || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'group',
    header: 'Команда',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue()?.name || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'date',
    header: 'Дата',
    cell: ({getValue}) => {
      return <Typography variant="body2">{date(getValue()).format('DD.MM.YYYY') || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'fullTime',
    header: 'Время',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'name',
    header: 'Тренировка',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'author',
    header: 'Тренер',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue()?.shortName || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'averageQualityEstimate',
    header: 'Ср.оц. по упр.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'connectionEstimate',
    header: 'Оценка согласов.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'structureEstimate',
    header: 'Оценка структ.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'skillEstimate',
    header: 'Оценка исп. нав.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'timeMatch',
    header: 'Вр.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
  {
    accessorKey: 'repeatMatch',
    header: 'Повт.',
    cell: ({getValue}) => {
      return <Typography variant="body2">{getValue() || longDash}</Typography>;
    },
  },
];
