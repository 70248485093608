import AddIcon from '@mui/icons-material/Add';
import {Button} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import TrainingPlan from './TrainingPlanItem';

const TaskList = (props) => {
  //ToDo
  //Rename components && variables

  const {
    tasks = [],
    onChange,
    formatDateTime = (dateTime) => moment(dateTime).format('DD.MM.YYYY'),
    parseDateTime = (dateTime) => moment(dateTime, 'DD.MM.YYYY').toDate(),
    showErrorMessage = true,
  } = props;

  const emptyLesson = {
    date: formatDateTime(new Date()),
  };

  const emptyTrainingPlan = {
    title: '',
    tasks: [{...emptyLesson}],
  };

  const addNewTrainingPlan = () => {
    onChange((prev) => [...prev, {...emptyTrainingPlan}]);
  };

  const deleteTrainingPlan = (index) => {
    onChange((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleTrainingPlanChange = (index, name, value) => {
    onChange((prev) => {
      prev[index][name] = value;
      return [...prev];
    });
  };

  return (
    <TrainingPlansWrapper>
      <TrainingPlansContainer>
        {tasks.length ? (
          tasks.map((trainingPlan, index) => (
            <TrainingPlan
              addNewTrainingPlan={addNewTrainingPlan}
              deleteTrainingPlan={deleteTrainingPlan}
              emptyLesson={emptyLesson}
              formatDateTime={formatDateTime}
              handleTrainingPlanChange={handleTrainingPlanChange}
              index={index}
              key={index}
              parseDateTime={parseDateTime}
              showErrorMessage={showErrorMessage}
              trainingPlan={trainingPlan}
            />
          ))
        ) : (
          <Button
            startIcon={<AddIcon />}
            sx={{mt: 2, borderColor: grey[8], color: grey[54]}}
            onClick={addNewTrainingPlan}
          >
            Добавить задачу
          </Button>
        )}
      </TrainingPlansContainer>
    </TrainingPlansWrapper>
  );
};

export default TaskList;

const TrainingPlansWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
`;
const TrainingPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
