import {ZONES_COORIDINATES} from './constants';

export const getZone = ({isHomeTeam, posX, posY}: {isHomeTeam: boolean; posX: number; posY: number}) => {
  let result = '';
  const getZoneByIndex = (index: number) => {
    return (
      posX >= ZONES_COORIDINATES?.[index]?.minX &&
      posX <= ZONES_COORIDINATES?.[index]?.maxX &&
      posY >= ZONES_COORIDINATES?.[index]?.minY &&
      posY <= ZONES_COORIDINATES?.[index]?.maxY
    );
  };
  const isFirst = getZoneByIndex(0);
  const isSecond = getZoneByIndex(1);
  const isThird = getZoneByIndex(2);
  const isFourth = getZoneByIndex(3);
  const isFifth = getZoneByIndex(4);
  const isSixth = getZoneByIndex(5);
  const isSeventh = getZoneByIndex(6);
  const isEighth = getZoneByIndex(7);
  const isNineth = getZoneByIndex(8);

  const isOZ = isHomeTeam ? isFirst || isSecond : isEighth || isNineth;
  const isNZ = isThird || isFourth || isFifth || isSixth || isSeventh;
  const isDZ = isHomeTeam ? isEighth || isNineth : isFirst || isSecond;
  if (isOZ) {
    result = 'OZ';
  }
  if (isNZ) {
    result = 'NZ';
  }
  if (isDZ) {
    result = 'DZ';
  }
  return result;
};
