import useElementSize from '@hooks/useElementSize';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {SxProps, Table, TableBody, Theme} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import {
  CoreOptions,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {Box, IconButton} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Scrollbar from '@ui/Scrollbar';
import React, {memo, useMemo} from 'react';

import {StyledTableCell, StyledTableHead, StyledTableRow, TableWrapper} from './ReactTableComponent';

interface Sticky {
  left?: boolean;
  right?: boolean;
}

export type CellType = {
  sx: ((...args: any[]) => void) | SxProps;
  isWithOutOverflow?: boolean;
  cellbackground?: React.CSSProperties['background'];
  colSpan?: number;
  rowSpan?: number;
} & TableCellProps;

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  interface ColumnDefBase<TData extends RowData, TValue> {
    headerStyle?: SxProps | ((...args: any[]) => void);
    cellStyle?: SxProps | ((...args: any[]) => void);
    sticky?: Sticky;
    sortable?: boolean;
    rowSpan?: number;
    cellbackground?: React.CSSProperties['background'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    headerStyle?: SxProps | (() => void);
    cellStyle?: SxProps | (() => void);
    sticky?: Sticky;
    sortable?: boolean;
    rowSpan?: number;
    cellbackground?: React.CSSProperties['background'];
  }
}

type ReactTableComponentProps = CoreOptions<object> & {
  ['data-testid']?: string;
  className?: string;
  customScrollBarHeight?: React.CSSProperties['height'] | number;
  columnWidth?: React.CSSProperties['height'] | number;
  bottomBlockHeight?: number;
  tableHeaderCellProps?: CellType;
  tableBodyCellProps?: CellType;
  isAvailableHeight?: boolean;
  displayHeader?: boolean;
  updateData?: () => void;
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState>;
};

const sortIndicators = {
  asc: (
    <IconButton size="small" sx={{ml: 0}}>
      <ArrowUpwardIcon fontSize="inherit" />
    </IconButton>
  ),
  desc: (
    <IconButton size="small" sx={{ml: 0}}>
      <ArrowDownwardIcon fontSize="inherit" />
    </IconButton>
  ),
};

export const initialCellStyle: SxProps = {
  py: {xxs: 1, sm: 3},
  px: {xxs: 2, sm: 4},
  boxShadow: `inset -1px -1px 0px 0px ${grey[12]}`,
  border: 'none',
  '&:last-child': {
    boxShadow: `inset 0px -1px 0px 0px ${grey[12]}`,
  },
  '&.lastRowCell': {
    boxShadow: `inset -1px 0px 0px 0px ${grey[12]}`,
    '&:last-child': {
      boxShadow: 'none',
    },
  },
};

const setClassName = (sticky?: Sticky | unknown): string => {
  let className = '';
  if (sticky) {
    if (sticky?.left) {
      className = 'sticky-left';
    }
    if (sticky?.right) {
      className = 'sticky-right';
    }
  }
  return className;
};

const setBackground = (background: React.CSSProperties['background']) => {
  if (background) {
    return background;
  } else {
    return 'transparent';
  }
};

function ReactTableComponent({
  bottomRow,
  columnWidth,
  customScrollBarHeight,
  bottomBlockHeight = 0,
  defaultColumn,
  data,
  tableHeaderCellProps = {
    sx: {...initialCellStyle},
  },
  tableBodyCellProps = {
    sx: {...initialCellStyle},
  },
  scrollbarProps = {},
  columns,
  updateData,
  sorting,
  setSorting,
  isAvailableHeight,
  displayHeader = true,
  getSubRows = (row: {subRows: any}) => {
    if (row?.subRows) return row?.subRows;
  },
  onExpandedChange = () => {},
  ...props
}: ReactTableComponentProps) {
  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: {...(props?.meta || {}), updateData},
    getSubRows,
    onExpandedChange,
    ...props,
    getCoreRowModel: getCoreRowModel(),
    state: {
      ...(props?.state || {}),
      sorting: sorting,
    },
    sortingFns: {
      defaultSorting: (rowA, rowB, columnId) => {
        return rowA.getValue(columnId)?.lastName < rowB.getValue(columnId)?.lastName ? 1 : -1;
      },
    },
  });
  const [tableRef, {offsetParent, height, width, availableHeight}] = useElementSize();
  const isContentOverflow = width > offsetParent?.offsetWidth;
  const noScrollX = useMemo(
    () => offsetParent?.offsetWidth + 7.5 > offsetParent?.scrollWidth,
    [offsetParent?.offsetWidth, offsetParent?.scrollWidth],
  ); //исправляет мерцание скролла на разрешении 1178-1282px

  const scrollbarHeight = useMemo(() => {
    if (customScrollBarHeight) {
      return customScrollBarHeight;
    }
    if (availableHeight) {
      if (availableHeight - bottomBlockHeight > height) {
        return height;
      }
      if (availableHeight - bottomBlockHeight < 250) {
        return 250;
      }
      return availableHeight - bottomBlockHeight;
    }
    return height;
  }, [height, availableHeight, bottomBlockHeight, customScrollBarHeight, isContentOverflow]);

  return (
    <TableWrapper className="TableWrapper">
      <Scrollbar
        contentProps={{style: {display: 'block'}}}
        height={scrollbarHeight}
        isContentOverflow={isContentOverflow}
        noScrollX={noScrollX}
        scrollDetectionThreshold={500}
        scrollWidth={100}
        trackXProps={{style: {marginTop: '10px'}}}
        translateContentSizeYToHolder={!isAvailableHeight}
        {...scrollbarProps}
      >
        <Table className={props.className} data-testid={props?.['data-testid'] || 'table'} ref={tableRef}>
          {displayHeader && (
            <StyledTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <StyledTableRow key={headerGroup?.id} role="row">
                  {headerGroup.headers.map((header) => {
                    const columnDef = header.column.columnDef;
                    const column = header.column;
                    const sortable = columnDef?.sortable !== false && sorting;
                    return (
                      <StyledTableCell
                        cellbackground={setBackground(columnDef.headerCellBackground)}
                        className={setClassName(columnDef?.sticky)}
                        colSpan={header.colSpan}
                        key={header.id}
                        role="cell"
                        rowSpan={columnDef?.rowSpan || `${column.rowSpan ?? 1}` || 1}
                        style={{width: columnWidth}}
                        sx={(theme: Theme) => ({
                          ...initialCellStyle,
                          ...(typeof tableHeaderCellProps?.sx === 'function'
                            ? tableHeaderCellProps?.sx?.(theme, header.id)
                            : tableHeaderCellProps?.sx),
                          ...(typeof columnDef?.headerStyle === 'function'
                            ? columnDef?.headerStyle(theme)
                            : columnDef?.headerStyle),
                          cursor: sortable ? 'pointer' : '',
                        })}
                        onClick={sortable ? column.getToggleSortingHandler() : () => {}}
                      >
                        <Box
                          sx={{
                            all: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'contents',
                            alignItems: 'center',
                          }}
                        >
                          {header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}
                          {sortable && (sortIndicators?.[column.getIsSorted()] ?? null)}
                        </Box>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </StyledTableHead>
          )}
          <TableBody>
            {table.getRowModel().rows.map((row, index, rows) => {
              return (
                <StyledTableRow key={row.id} role="row">
                  {row.getVisibleCells().map((cell) => {
                    const columnDef = cell.column.columnDef;
                    const cellStyle =
                      typeof columnDef?.cellStyle === 'function'
                        ? (theme: Theme) => {
                            return columnDef?.cellStyle(theme, index, rows);
                          }
                        : columnDef?.cellStyle;
                    const isWithoutWhiteSpace = cellStyle?.whiteSpace !== 'nowrap';

                    return (
                      <StyledTableCell
                        cellbackground={setBackground(cell.column.columnDef.cellbackground)}
                        className={setClassName(cell.column.columnDef.sticky)}
                        colSpan={cell.colSpan}
                        isWithOutOverflow={cell.column.columnDef.isWithOutOverflow}
                        isWithoutWhiteSpace={isWithoutWhiteSpace}
                        key={cell.id}
                        role="cell"
                        style={{width: columnWidth}}
                        sx={(theme: Theme) => ({
                          ...initialCellStyle,
                          ...(typeof tableBodyCellProps?.sx === 'function'
                            ? tableBodyCellProps?.sx?.(theme, index, rows)
                            : tableBodyCellProps?.sx),
                          ...(typeof cell.column.columnDef?.cellStyle === 'function'
                            ? cell.column.columnDef?.cellStyle(theme, index, rows)
                            : cell.column.columnDef?.cellStyle),
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
            {bottomRow && bottomRow([StyledTableRow, StyledTableCell])}
          </TableBody>
        </Table>
        <Box height={isContentOverflow ? 11 : 0} />
      </Scrollbar>
    </TableWrapper>
  );
}

export default memo(ReactTableComponent);
