import NoDataFullHeight from '@common/NoDataFullHeight';
import Protocol from '@common/Statistics/PlayerStatistics/ScorePage/components/Protocol';
import {mock} from '@common/Statistics/PlayerStatistics/ScorePage/mock';
import {GAMES_API} from '@components/apps/StatisticsWithMkcUral/components/GamesList';
import {GAME_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Theme, useMediaQuery} from '@mui/material';
import {PERIOD_VALUES, TEAM_COLORS} from '@src/constants';
import {StatisticsGetMatchIndexPageInfoResponseDto} from '@src/types/generated';
import {Box, Button, Stack, Typography} from '@ui/MUI';
import {chunk} from 'lodash';
import React, {memo, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import GameInfo from './components/GameInfo';
import {
  GameInfoWrapper,
  MainStatsParameterLine,
  MainStatsParameterLineContainer,
  MainStatsParameterLineItem,
  MainStatsParameterLineTitleBlock,
} from './ScorePage';

type ScorePageProps = {
  isV2?: boolean;
  hasOT?: boolean;
  winByOT?: boolean;
  winByBuls?: boolean;
  fetchFunction: ({}) => void;
  fetchParams: {mkcTeamId?: string; statisticId: string};
  gameResults: StatisticsGetMatchIndexPageInfoResponseDto;
  isLoading: boolean;
  handlePeriodChange: ({}) => void;
  periodValue?: string;
  placeholder?: string;
};

const chunkData = (data) => {
  const chunks = chunk(data || [], data?.length / 2);
  if (!chunks?.[0]?.length || !chunks?.[1]?.length) return [];
  return [[...chunks[0], ...(chunks?.[2] || [])], chunks[1]];
};
const ScorePage = ({
  isV2,
  hasOT,
  winByOT,
  winByBuls,
  fetchFunction = () => {},
  fetchParams,
  gameResults,
  isLoading,
  handlePeriodChange,
  periodValue = PERIOD_VALUES.all,
  placeholder = 'Данные отсутствуют',
}: ScorePageProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const switchButtons = useMemo(() => {
    const buttons = [
      {
        name: PERIOD_VALUES.all,
        text: 'Все',
      },
      {
        name: PERIOD_VALUES[1],
        text: isMobile ? '1 пер.' : '1 период',
      },
      {
        name: PERIOD_VALUES[2],
        text: isMobile ? '2 пер.' : '2 период',
      },
      {
        name: PERIOD_VALUES[3],
        text: isMobile ? '3 пер.' : '3 период',
      },
      {
        name: PERIOD_VALUES.OT,
        text: isMobile ? 'от' : 'Овертайм',
      },
    ];
    return hasOT ? buttons : buttons.slice(0, 4);
  }, [isMobile, hasOT]);
  const data = useDeepMemo(() => (isLoading ? mock : gameResults), [isLoading, gameResults]);
  const colors = useMemo(() => (data.weAtHome ? [...TEAM_COLORS].reverse() : [...TEAM_COLORS]), [data.weAtHome]);
  const [periodFilter, setPeriodFilter] = useState(PERIOD_VALUES.all);
  useDeepEffect(() => {
    fetchFunction({half: periodFilter, ...fetchParams});
  }, [periodFilter, fetchParams]);

  const [qs, _] = useQueryString();

  const dispatch = useStoreDispatch();

  useEffect(() => {
    qs?.teamMKCId &&
      dispatch(
        GAMES_API[GAME_TYPES.pastGames].load({
          teamId: qs?.teamMKCId,
          page: 1,
          limit: 999,
          isFinal: qs?.isFinal,
          season: qs?.season,
        }),
      );
  }, [isMobile, qs?.teamMKCId, qs?.isFinal, qs?.season]);

  useEffect(() => {
    return () => {
      dispatch(GAMES_API[GAME_TYPES.pastGames].clear());
    };
  }, []);

  return (
    <Box borderRadius="6px" mb={1} overflow="hidden" position="relative">
      {data?.metrics?.length ? (
        <StatsBlockWrapper p={4} pb={7}>
          <GameInfoWrapper direction="column" mb={5}>
            <GameInfo gameResults={data} isLoading={isLoading} isV2={isV2} {...{winByOT, winByBuls}} />
            {(data?.protocol?.home?.length || data?.protocol?.away?.length) && (
              <Protocol isV2={isV2} linkToVideo={data?.linkToVideo} weAtHome={data?.weAtHome} {...data.protocol} />
            )}
          </GameInfoWrapper>

          <Stack direction="row" justifyContent="center" width="100%">
            {switchButtons.map((item, index) => {
              const isActive = (handlePeriodChange ? periodValue : periodFilter) === item.name;
              return (
                <Button
                  color={isActive ? 'primary' : 'secondary'}
                  disabled={isLoading}
                  key={index}
                  sx={{flexShrink: '0'}}
                  variant={isActive ? 'contained' : 'text'}
                  onClick={() => (handlePeriodChange ? handlePeriodChange(item.name) : setPeriodFilter(item.name))}
                >
                  {item.text}
                </Button>
              );
            })}
          </Stack>
          <Stack
            alignItems={{xxs: 'center', sm: 'flex-start'}}
            direction={{xxs: 'column', sm: 'row'}}
            justifyContent="center"
            mt={6}
            mx="auto"
            spacing={{xxs: 5, sm: '120px'}}
            width="100%"
          >
            {chunkData(data?.metrics)?.map((chunk, index) => (
              <Stack
                direction="column"
                key={index}
                maxWidth={{xxs: '100%', xs: 350, md: 320}}
                spacing={{xxs: 5, sm: 8}}
                width="100%"
              >
                {chunk.map(({data, name}, index) => (
                  <MainStatsParameterLineContainer key={index} width="100%">
                    <MainStatsParameterLineTitleBlock mb={1}>
                      <Typography
                        isLoading={isLoading}
                        preloaderWidthBetween={[20, 30]}
                        variant={isV2 ? 'subtitle2' : 'h4'}
                      >
                        {data.home}
                      </Typography>
                      <Typography
                        isLoading={isLoading}
                        preloaderWidthBetween={[80, 130]}
                        variant={isV2 ? 'subtitle2' : 'subtitle1'}
                      >
                        {name}
                      </Typography>
                      <Typography
                        isLoading={isLoading}
                        preloaderWidthBetween={[20, 30]}
                        variant={isV2 ? 'subtitle2' : 'h4'}
                      >
                        {data.away}
                      </Typography>
                    </MainStatsParameterLineTitleBlock>
                    <Stack direction="row" gap={0} width="100%">
                      <MainStatsParameterLine>
                        <MainStatsParameterLineItem bg={colors[0]} right={0} width={data.homePercent} />
                      </MainStatsParameterLine>
                      <MainStatsParameterLine>
                        <MainStatsParameterLineItem bg={colors[1]} left={0} width={data.awayPercent} />
                      </MainStatsParameterLine>
                    </Stack>
                  </MainStatsParameterLineContainer>
                ))}
              </Stack>
            ))}
          </Stack>
        </StatsBlockWrapper>
      ) : (
        <NoDataFullHeight>{placeholder}</NoDataFullHeight>
      )}
    </Box>
  );
};

export default memo(ScorePage);

const StatsBlockWrapper = styled(Box)`
  position: relative;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
`;
