import {A1TagVideoPlayer} from '@components/A1TagVideoPlayer';
import {convertSecondsToHHMMSS} from '@components/apps/A1TagMainWindow';
import {Lines, Period} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {
  a1tagViewModel,
  updateLines,
  updatePeriod,
  updateScore,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {ArrowDropDownOutlined, ArrowDropUpOutlined} from '@mui/icons-material';
import {useSignals} from '@preact/signals-react/runtime';
import {longDash} from '@src/constants';
import {Autocomplete, Box, IconButton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {black} from '@ui/MUI/colorsA1Tag';
import React, {memo} from 'react';

import {ScoreTextField} from './A1TagMainWindowTopBlock';
import {LINES_OPTIONS, PERIOD_OPTIONS} from './constants';

type A1tagPlayersMainWindowTopBlockProps = {
  homeTeamName: string;
  awayTeamName: string;
};

export const A1tagPlayersMainWindowTopBlock = memo(
  ({homeTeamName, awayTeamName}: A1tagPlayersMainWindowTopBlockProps) => {
    useSignals();
    const period = a1tagViewModel.period.value;
    const lines = a1tagViewModel.lines.value;

    const cleanTime = a1tagViewModel.cleanTime.value;

    const isScoreAndPeriodUpdateDisabled = !a1tagViewModel?.isGodMode?.value;

    return (
      <Box sx={{width: '100%'}}>
        <Box
          sx={{
            pt: 1,
            pb: 0,
            px: 7,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '12px',
            borderBottom: `1px solid ${black[20]}`,
          }}
        >
          <Box sx={{flex: '0 0 24%', maxWidth: '24%'}}>
            <Typography color={black[60]} component="p" mr="36px" textAlign="right" variant="subtitle1">
              Time:
              <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle1">
                {convertSecondsToHHMMSS(cleanTime)}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{flex: '0 0 44%', maxWidth: '44%'}}>
            <Box alignItems="center" display="flex" justifyContent="center">
              <Typography color={black[60]} pr={1} variant="subtitle1">
                {homeTeamName}
              </Typography>
              <Box alignItems="center" display="flex" justifyContent="center" px={1}>
                <ScoreTextField
                  InputProps={{
                    endAdornment: (
                      <Stack>
                        <IconButton
                          disabled={isScoreAndPeriodUpdateDisabled}
                          size="small"
                          sx={{width: 16, height: 16, color: black[30]}}
                          onClick={() => {
                            const score = a1tagViewModel.homeScore.value + 1;
                            updateScore('home', score);
                          }}
                        >
                          <ArrowDropUpOutlined />
                        </IconButton>
                        <IconButton
                          disabled={isScoreAndPeriodUpdateDisabled}
                          size="small"
                          sx={{width: 16, height: 16, color: black[30]}}
                          onClick={() => {
                            const score = a1tagViewModel.homeScore.value - 1;
                            if (score < 0) return;
                            updateScore('home', score);
                          }}
                        >
                          <ArrowDropDownOutlined />
                        </IconButton>
                      </Stack>
                    ),
                  }}
                  min={0}
                  sx={{input: {maxWidth: 46, color: black[95], textAlign: 'center'}}}
                  type="number"
                  value={a1tagViewModel.homeScore.value}
                />
                <Typography
                  sx={{
                    px: 1,
                    fontWeight: 700,
                    fontSize: 20,
                    lineHeight: '20px',
                    fontFamily: 'Bebas Neue',
                    color: grey[54],
                  }}
                >
                  {longDash}
                </Typography>
                <ScoreTextField
                  InputProps={{
                    startAdornment: (
                      <Stack>
                        <IconButton
                          disabled={isScoreAndPeriodUpdateDisabled}
                          size="small"
                          sx={{width: 16, height: 16, color: black[30]}}
                          onClick={() => {
                            const score = a1tagViewModel.awayScore.value + 1;
                            updateScore('away', score);
                          }}
                        >
                          <ArrowDropUpOutlined />
                        </IconButton>
                        <IconButton
                          disabled={isScoreAndPeriodUpdateDisabled}
                          size="small"
                          sx={{width: 16, height: 16, color: black[30]}}
                          onClick={() => {
                            const score = a1tagViewModel.awayScore.value - 1;
                            if (score < 0) return;
                            updateScore('away', score);
                          }}
                        >
                          <ArrowDropDownOutlined />
                        </IconButton>
                      </Stack>
                    ),
                  }}
                  min={0}
                  sx={{input: {maxWidth: 46, color: black[95], textAlign: 'center'}}}
                  type="number"
                  value={a1tagViewModel.awayScore.value}
                />
              </Box>
              <Typography color={black[60]} pl={1} variant="subtitle1">
                {awayTeamName}
              </Typography>
            </Box>
          </Box>
          <Box sx={{flex: '0 0 28%', maxWidth: '28%'}}>
            <Box display="flex" mx={-2} width="100%">
              <Box px={2} sx={{flex: '0 0 50%', maxWidth: '50%'}}>
                <Box alignItems="center" display="flex">
                  <Typography color={black[60]} mr={0} variant="subtitle2">
                    Period:
                  </Typography>
                  <Autocomplete
                    disableClearable
                    disabled={isScoreAndPeriodUpdateDisabled}
                    multiple={false}
                    options={PERIOD_OPTIONS}
                    size="small"
                    sx={{
                      maxWidth: 80,
                      '.MuiAutocomplete-inputRoot': {
                        minHeight: '24px',
                      },
                    }}
                    textFieldVariant="outlined"
                    value={PERIOD_OPTIONS?.find((option) => option.value === period)}
                    width="100%"
                    onChange={(_, v: {value: Period; label: Period}) => {
                      updatePeriod(v?.value);
                    }}
                  />
                </Box>
              </Box>
              <Box px={2} sx={{flex: '0 0 50%', maxWidth: '50%'}}>
                <Box alignItems="center" display="flex">
                  <Typography color={black[60]} mr={0} variant="subtitle2">
                    Lines:
                  </Typography>
                  <Autocomplete
                    disableClearable
                    multiple={false}
                    options={LINES_OPTIONS}
                    size="small"
                    sx={{
                      '.MuiAutocomplete-inputRoot': {
                        minHeight: '24px',
                      },
                    }}
                    textFieldVariant="outlined"
                    value={LINES_OPTIONS?.find((option) => option.value === lines)}
                    width="100%"
                    onChange={(_, v: {value: Lines; label: Lines}) => {
                      updateLines(v?.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{width: '100%', height: '100%', maxHeight: 'calc(100% - 24px)'}}>
          <A1TagVideoPlayer />
        </Box>
      </Box>
    );
  },
);

A1tagPlayersMainWindowTopBlock.displayName = 'A1tagPlayersMainWindowTopBlock';
