import {useSelector} from 'react-redux';

export const useCurrentUserRole = () => {
  const userRole = useSelector((state) => state?.users?.currentUser?.userRole);
  return userRole;
};

export const useCurrentUser = () => {
  const currentUser = useSelector((state) => state?.users?.currentUser);
  return currentUser;
};
