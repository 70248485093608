import SearchInput from '@common/SearchInput';
import {useSchoolsV2} from '@hooks/filtersV2';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {roleOptions} from '@src/constants';
import {UserRoles} from '@src/types';
import {Autocomplete, Button} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

const UsersFilters = (props) => {
  const history = useHistory();

  const [school, setSchool] = useState(null);
  const [userRoleValue, setUserRoleValue] = useState(null);

  const isLoading = useStore((state) => state.schools.loading);
  const {options} = useSchoolsV2({allSchoolsPermissionRoles: [UserRoles.ADMIN, UserRoles.SCHOOLS_ADMIN]});
  const userSchool = useStore((state) => state.users.currentUser.schoolsIds[0]);
  const isAdmin = useStore((state) => state.users.currentUser.userRole === UserRoles.ADMIN);

  const [qs, _] = useQueryString();

  useEffect(() => {
    if (qs?.schoolId) {
      setSchool(options?.find((option) => option?.value === qs?.schoolId));
    }
  }, [qs?.schoolId, options?.find((option) => option?.value === qs?.schoolId)?.value]);

  const [handleSearch] = useDebouncedCallback(
    (value) => {
      props.onChange(value, 'q');
    },
    1000,
    [],
  );
  const formatedRole = useMemo(() => {
    return props.userRole === 'admin' ? roleOptions : roleOptions.filter((role) => role.value !== 'admin');
  }, [roleOptions, props.userRole]);

  useEffect(() => {
    if (qs?.userRole) {
      setUserRoleValue(formatedRole?.find((option) => option?.value === qs?.userRole));
    }
  }, [qs?.userRole]);

  return (
    <Wrapper>
      <Container>
        <FilterContainer>
          <InputContainer>
            <SearchInput
              InputProps={{sx: {pl: 5, fontSize: 14}}}
              inputProps={{variant: 'outlined', placeholder: 'Введите ФИО для поиска...'}}
              onChange={(value) => {
                handleSearch(value);
              }}
            />
            <IconContainer>
              <SearchOutlinedIcon sx={{color: grey[24]}} />
            </IconContainer>
          </InputContainer>
        </FilterContainer>
        <FilterContainer>
          <Autocomplete
            defaultValue={userSchool && options?.length && options.find((school) => school.value === userSchool)}
            disabled={!isAdmin}
            isLoading={isLoading}
            label="Школа"
            multiple={false}
            options={options}
            placeholder="Выберите"
            textFieldVariant="outlined"
            value={school}
            onChange={(_, newValue) => {
              setSchool(newValue);
              props.onChange(newValue?.value, 'schoolId');
            }}
          />
        </FilterContainer>
        <FilterContainer>
          <Autocomplete
            label="Роль"
            multiple={false}
            options={formatedRole}
            placeholder="Выберите"
            textFieldVariant="outlined"
            value={userRoleValue}
            onChange={(_, newValue) => {
              setUserRoleValue(newValue);
              props.onChange(newValue?.value, 'userRole');
            }}
          />
        </FilterContainer>
        <CreateButtonContainer>
          <Button color="primary" size="small" variant="contained" onClick={() => history.push('/users/create')}>
            Добавить пользователя
          </Button>
        </CreateButtonContainer>
      </Container>
    </Wrapper>
  );
};

const CreateButtonContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(0)};
  align-self: flex-end;
  flex: 1;
  min-width: 187px;
  text-align: right;

  @media (max-width: 1130px) {
    margin-top: 10px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  left: 15px;
`;

const Wrapper = styled.div`
  padding: 12px 34px 20px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const FilterContainer = styled.div`
  flex: 1;
  min-width: 200px;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;
`;

export default UsersFilters;
