import {useCurrentTime} from '@components/A1TagEventModal/hooks';
import {
  a1tagViewModel,
  allPlayers,
  uniqAwayPlayers,
  uniqHomePlayers,
} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {coords, maxX, maxY, updatePayload} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {modalViewModel} from '@components/apps/A1TagMainWindow/viewmodels/modal-view-model';
import {ArenaSchema} from '@components/ArenaSchema';
import {formatPlayer} from '@helpers/a1tag.helper';
import {getCoordsFromEvent} from '@helpers/coords.helper';
import {FormControlLabel, Switch} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsA1Tag';
import React from 'react';

export const A1TagKeyPassModalContent = () => {
  useSignals();
  const payload = modalViewModel.payload.value ?? {};
  const {x, y, x2, y2} = coords.getCoords();

  const {homeTeam, awayTeam} = a1tagViewModel.rosters.value;

  const homePlayers = uniqHomePlayers?.value || [];
  const awayPlayers = uniqAwayPlayers?.value || [];
  const isSelectedPlayerHome = homePlayers
    ?.map((homePlayer) => homePlayer?.avangardId)
    .includes(payload?.player?.avangardId);

  const options = isSelectedPlayerHome ? homePlayers?.map(formatPlayer) : awayPlayers?.map(formatPlayer);

  const {currentTime} = useCurrentTime();

  return (
    <Box>
      <Box sx={{mx: -4, borderBottom: `1px solid ${black[25]}`}}>
        <Box pb={4} pt={1} px={4} sx={{display: 'flex', alignItems: 'center', backgroundColor: black[12]}}>
          <Typography sx={{pr: 2, color: black[60]}} variant="subtitle2">
            Time:
            <Typography component="span" sx={{pl: 1, color: black[95]}} variant="subtitle2">
              {currentTime}
            </Typography>
          </Typography>
          <Box minWidth={218} pl={2}>
            <Autocomplete
              multiple={false}
              options={options}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.player ?? null}
              width="100%"
              onChange={(_, v) => {
                const player = allPlayers.value.find((p) => p.avangardId === v?.value);

                updatePayload({
                  ...payload,
                  player: player ? formatPlayer(player) : null,
                });
              }}
            />
          </Box>
          <Box minWidth={218} pl={2}>
            <Autocomplete
              multiple={false}
              options={options}
              size="small"
              sx={{width: '100%'}}
              textFieldVariant="outlined"
              value={payload.opponent ?? null}
              width="100%"
              onChange={(_, v) => {
                const player = allPlayers.value.find((p) => p.avangardId === v?.value);

                updatePayload({
                  ...payload,
                  opponent: player ? formatPlayer(player) : null,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box ml={1} mt={1}>
        <FormControlLabel
          control={
            <Switch
              checked={payload.action_type === 'Successfull'}
              name="penalty_shot"
              size="small"
              onChange={(_, v) => {
                const value = v ? 'Successfull' : 'Unsuccessfull';
                updatePayload({
                  ...payload,
                  action_type: value,
                });
              }}
            />
          }
          label="Penalty shot"
          slotProps={{typography: {variant: 'body2', sx: {color: black[75]}}}}
        />
      </Box>
      <Box mx={-4}>
        <Box p={4}>
          <Box sx={{position: 'relative', mt: 3, mb: 2}}>
            <Box
              mx="auto"
              px={4}
              sx={{
                position: 'relative',
                maxWidth: 650,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: -44,
                  transform: 'translateY(-50%) rotate(-90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {homeTeam?.name}
              </Typography>
              <ArenaSchema
                eclipses={coords.getEclipses()}
                onClick={(e) => {
                  const {cartesianX, cartesianY, realX, realY} = getCoordsFromEvent(e, maxX, maxY);
                  const eclipse = {
                    realX,
                    realY,
                    x: cartesianX,
                    y: cartesianY,
                  };

                  if (coords.eclipses.value.length <= 1) {
                    coords.updateEclipses([...coords.eclipses.value, eclipse]);
                    updatePayload({
                      ...payload,
                      opponent_pos_x: cartesianX,
                      opponent_pos_y: cartesianY,
                    });
                  } else {
                    coords.updateEclipses([eclipse]);
                    updatePayload({
                      ...payload,
                      pos_x: cartesianX,
                      pos_y: cartesianY,
                      opponent_pos_x: null,
                      opponent_pos_y: null,
                    });
                  }
                }}
                onMouseMove={(e) => {
                  const {cartesianX, cartesianY} = getCoordsFromEvent(e, maxX, maxY);
                  if (coords.eclipses.value.length === 0 || coords.eclipses.value.length === 2) {
                    coords.updateCoords(cartesianX, cartesianY);
                  } else {
                    coords.updateSecondCoords(cartesianX, cartesianY);
                  }
                }}
              />
              <Typography sx={{mt: 1, textAlign: 'center'}} variant="subtitle1">
                ({x.toFixed(1)}, {y.toFixed(1)}) ({x2.toFixed(1)}, {y2.toFixed(1)})
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '45%',
                  right: -22,
                  transform: 'translateY(-50%) rotate(90deg)',
                  mb: 2,
                  color: black[75],
                  textTransform: 'uppercase',
                }}
                variant="overline2"
              >
                {awayTeam?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
