import ReactTableComponent from '@common/ReactTableComponent';
import {NoData} from '@components/modules/prospects/components/NoData';
import {useProspectGame} from '@components/modules/prospects/queries/prospect-game.query';
import {useSignals} from '@preact/signals-react/runtime';
import React from 'react';
import {useParams} from 'react-router';

import {gamesReportTableColumns} from '../constants/games-report-table.constant';
import {gamesReportModel} from '../models/games-report.model';

export const GamesReportTable = () => {
  useSignals();
  const {playerId} = useParams<{playerId: string}>();
  const seasonId = gamesReportModel.filters.seasonId;
  const prospectGamesQuery = useProspectGame(playerId, seasonId);
  const data = prospectGamesQuery.data ?? [];

  if (!data?.length) {
    return <NoData />;
  }

  return <ReactTableComponent columns={gamesReportTableColumns} data={data} />;
};
