import {Typography} from '@ui/MUI';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import React from 'react';

export default function returnFromArchiveModal(props) {
  let {returnFromArchiveModalState = false, onClose = () => {}, onSave = () => {}} = props;

  return (
    <DialogDefault
      actionText="Восстановить"
      closeText="Отменить"
      content={<Typography variant="body2">Вы уверены, что хотите восстановить игрока из архива?</Typography>}
      open={returnFromArchiveModalState}
      title="Восстановить игрока"
      onActionButtonClick={onSave}
      onCloseDefault={onClose}
    />
  );
}
