import {CellDef} from '@components/A1Table/a1Table.types';
import {a1TableViewModel} from '@components/A1Table/models/table.view-mode';
import {Checkbox} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import React from 'react';

export const CheckBoxCell = ({row}: CellDef<undefined>) => {
  const originalRow = row.original;
  const id = originalRow.id;
  useSignals();

  const isSelected = Boolean(a1TableViewModel.isSelected(id));
  const handleChange = () => {
    if (isSelected) {
      a1TableViewModel.removeSelectedRow(id);
    } else {
      a1TableViewModel.appendSelectedRow(originalRow);
    }
  };

  return <Checkbox checked={isSelected} onChange={handleChange} />;
};
