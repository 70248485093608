import {
  handleChangeMedicalProfileRecommendation,
  postPlayerMedicalProfileForm,
  savePlayerMedicalProfileRecommendation,
} from '@actions/MedicalProfileActions';
import {useSchoolsV2} from '@hooks/filtersV2';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import AddIcon from '@mui/icons-material/Add';
import {useMediaQuery} from '@mui/material';
import {UserRoles} from '@src/types';
import {Autocomplete, Box, Button} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';

import CommonHelper from '../../../helpers/CommonHelper';
import StateHelper from '../../../helpers/StateHelper';
import {TYPES_OPTIONS} from '../constants';
import Consultation from '../modals/Consultation';
import GeneralInfo from '../modals/GeneralInfo';
import Nutrition from '../modals/Nutrition';
import Recommendations from '../modals/Recommendations';

export default function MedicalProfileFilter(props) {
  let {onChange = () => {}, updateUserData = () => {}} = props;
  const [addModalVisible, setAddModalVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: {errors},
    reset,
  } = useForm();
  const medicalProfileForm = useStore((state) => state.medicalProfile.medicalProfileForm || {});
  const players = useStore((state) => state.medicalProfile.medicalProfileFiltersPlayers);
  const medicalProfileRecommendation = useStore(
    (state) =>
      state.medicalProfile.medicalProfileRecommendation || {
        comment: '',
      },
  );

  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(
      handleChangeMedicalProfileRecommendation({
        comment: '',
        taskGroups: [],
      }),
    );
  }, [medicalProfileForm.objectId]);

  const addModalContent = () => {
    return (
      <Box mt={3}>
        <TabsNew
          withoutPx
          tabs={[
            {
              label: 'Общая информация',
              value: <GeneralInfo addCard={addCard} control={control} errors={errors} setValue={setValue} />,
            },
            // {
            //   label: 'Тренировки',
            //   value: <Training />,
            // },
            {
              label: 'Посещение/Консультация',
              value: <Consultation />,
            },
            {
              label: 'Питание',
              value: <Nutrition />,
            },
            {
              label: 'Рекомендации',
              value: <Recommendations value={medicalProfileRecommendation} />,
            },
          ]}
        />
      </Box>
    );
  };

  const schools = useSchoolsV2({allSchoolsPermissionRoles: [UserRoles.DOCTOR]});
  const [selectedSchool, setSelectedSchool] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [playersOptions, setPlayersOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    if (players?.length > 0) {
      setPlayersOptions(players);
    }
  }, [players]);

  useEffect(() => {
    if (selectedSchool?.value) {
      StateHelper.getGroupsBySchoolId(selectedSchool?.value).then((options) => {
        setGroupOptions(options);
      });
    }
  }, [selectedSchool?.value]);

  const getFilterObject = () => {
    let result = {};
    result.group = selectedGroup ? selectedGroup.value : '';
    result.school = selectedSchool ? selectedSchool.value : '';
    result.playerId = selectedPlayer ? selectedPlayer.value : '';
    result.type = selectedType ? selectedType.value : '';
    return result;
  };

  const isValidMedicalForm = (medicalProfileForm) => {
    let result = true;

    if (
      !medicalProfileForm.school ||
      !medicalProfileForm.group ||
      !medicalProfileForm.description ||
      !medicalProfileForm.playerId ||
      !medicalProfileForm.type ||
      !medicalProfileForm.status
    ) {
      result = false;
    }

    return result;
  };

  useEffect(() => {
    const filterObject = getFilterObject();
    onChange({...filterObject});
  }, []);

  const onSubmit = () => {};
  const [addCard, setAddCard] = useState(false);

  useEffect(() => {
    if (!addModalVisible) {
      setAddCard(false);
    }
  }, [addModalVisible]);

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Wrapper className={'SkillAssessmentFilter'} id="medical_form" onSubmit={handleSubmit(onSubmit)}>
      <Filter className={'Filter'}>
        <Column className={'Column'}>
          <Autocomplete
            label="Школа"
            multiple={false}
            options={schools?.options}
            placeholder={'Выберите школу'}
            value={selectedSchool}
            onChange={(_, newValue) => {
              const filterObject = getFilterObject();
              onChange({...filterObject, school: newValue?.value});
              setSelectedSchool(newValue);
              setSelectedGroup('');
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Autocomplete
            isLoading={selectedSchool && !groupOptions.length > 0}
            label="Команда"
            multiple={false}
            options={CommonHelper.getDropDownTeamsByNamedList(groupOptions)}
            placeholder={'Выберите команду'}
            value={selectedGroup}
            onChange={(_, newValue) => {
              setSelectedGroup(newValue);
              setSelectedPlayer('');
              const filterObject = getFilterObject();
              onChange({...filterObject, group: newValue?.value || '', playerId: ''});
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Autocomplete
            label="Тип"
            multiple={false}
            options={TYPES_OPTIONS}
            placeholder="Выберите тип"
            value={selectedType}
            onChange={(_, newValue) => {
              setSelectedType(newValue);
              const filterObject = getFilterObject();
              onChange({...filterObject, type: newValue?.value || ''});
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Autocomplete
            label="Игрок"
            multiple={false}
            options={CommonHelper.getDropDownItemsByUsers(playersOptions)}
            placeholder="Выберите игрока"
            value={selectedPlayer}
            onChange={(_, newValue) => {
              setSelectedPlayer(newValue);

              const filterObject = getFilterObject();
              onChange({...filterObject, playerId: newValue?.value});
            }}
          />
        </Column>
        <ButtonWrapper>
          <ButtonContainer>
            <Button
              className={'ButtonWithIcon MedicalProfileAddButton'}
              color="primary"
              startIcon={<AddIcon />}
              type="button"
              variant="contained"
              onClick={() => {
                setAddModalVisible({...addModalVisible, right: true});
                setAddCard(true);
              }}
            >
              Добавить
            </Button>
          </ButtonContainer>
        </ButtonWrapper>
      </Filter>
      <Drawer
        buttons={
          <ModalButtonsContainer>
            <Button
              color="primary"
              form="medical_form"
              type="submit"
              variant="contained"
              onClick={() => {
                if (!isValidMedicalForm(medicalProfileForm)) return;
                dispatch(postPlayerMedicalProfileForm(medicalProfileForm, false)).then((data) => {
                  if (!data) {
                    return;
                  }
                  const requestObject = {
                    playerId: medicalProfileForm.playerId,
                    comment: medicalProfileRecommendation.comment,
                    taskGroups: medicalProfileRecommendation.taskGroups || [],
                  };
                  if (medicalProfileForm.playerId) {
                    dispatch(savePlayerMedicalProfileRecommendation(data.data.objectId, requestObject))
                      .then(() => {
                        dispatch({
                          type: 'MEDICAL_PROFILE_LOADING_FORM',
                          medicalProfileForm: {},
                        });
                        setAddModalVisible({...addModalVisible, right: false});
                        updateUserData();
                      })
                      .finally(() => {
                        reset({});
                      });
                  }
                });
              }}
            >
              Сохранить
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setAddModalVisible({...addModalVisible, right: false});
                reset({});
              }}
            >
              Закрыть
            </Button>
          </ModalButtonsContainer>
        }
        open={addModalVisible?.right}
        title="Добавить"
        width={isSmResolution ? '100%' : 'calc(100% - 275px)'}
        onClose={() => {
          setAddModalVisible({...addModalVisible, right: false});
          reset({});
        }}
      >
        {addModalContent()}
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  padding: 20px 34px 20px;
  margin-top: 20px;
  margin-bottom: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  @media screen and (max-width: 480px) {
    padding-right: 18px;
    padding-left: 18px;
  }

  .TabItemsBlock {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 -20px;
    justify-content: flex-start;
    background-color: #fffaed;
    box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);

    @media (min-width: 769px) and (max-width: 1200px) {
      height: 60px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 8px;
      padding-bottom: 8px;
      flex-direction: column;
      height: auto;
    }

    .TabName {
      margin-right: 24px;
      color: #990011;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        :last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .AddComment {
    .sidebar__inner {
      padding-right: 20px;
      padding-left: 20px;

      .sidebar__header {
        .sidebar__closer {
          right: 20px;
        }
      }

      .sidebar__content {
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }

  .EditTherapy {
    .sidebar__inner {
      .sidebar__content {
        padding-bottom: 0;
      }
    }
  }
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -14px;
  margin-left: -14px;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }
  @media screen and (max-width: 480px) {
    margin-right: 0;
    margin-left: 0;
  }
`;
const Column = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    :nth-child(4),
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
const ButtonWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 14px;
  padding-right: 14px;
  @media screen and (max-width: 1024px) {
    padding-top: 14px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 992px) {
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 360px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const ButtonContainer = styled.div`
  @media screen and (min-width: 1025px) {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
