import {useSkillTrainingStickerByLessonId} from '@components/modules/skill-estimate/skill.estimate.queries';
import {Typography} from '@mui/material';
import {longDash} from '@src/constants';
import {Button, Stack} from '@ui/MUI';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import UsersAPI from '../../../../../api/UsersAPI';
import {NOTIFICATION_TYPES} from '../../../../../constants/config';
import StateHelper from '../../../../../helpers/StateHelper';
import {ReactComponentNotification} from '../../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {ADDITIONAL_EDUCATION_LESSONS, ARCHIVED_SCHOOL_LESSONS, EVENT_TYPES, SCHOOL_LESSONS} from '../../../constants';
import {BottomPanel, Buttons, FlexContainer, InfoBlock, InfoText, InfoWrapper, Label} from '../style';

const LessonView = (props) => {
  const {selectedLesson, lessonTournament, isButtonsShowed = true, onEditButtonClick, onCLoseButtonClick} = props;
  const currentUser = useSelector((state) => state.users?.currentUser);
  const teachersGroups = useSelector((state) => StateHelper.getTeacherGroups(state, currentUser.id));
  const teachersGroupsIds = teachersGroups.map((group) => group.id);
  const {lockerRoom, opponent, streamingURL} = selectedLesson;
  const playerRole = selectedLesson.playerRole?.map((amplua) => amplua.label).join(',');
  const selectedGroup = selectedLesson.group;
  const isTeachersGroupsIdsIncludesSelectedGroup =
    currentUser?.userRole === 'teacher' && teachersGroupsIds?.includes(selectedGroup?.id);
  let selectedPlayers = selectedLesson.students.map((player) => `${player.lastName} ${player.firstName}`) || [];
  const playersIds = selectedLesson.studentsIds;
  const selectedPlayersIdsFromAnotherTeam = playersIds?.length
    ? selectedLesson.studentsIds.filter((p) => playersIds?.indexOf(p) === -1)
    : [];
  const type = EVENT_TYPES.find((t) => t.value === selectedLesson.type)?.label || '';
  const getFormattedDate = (date) => moment(date).format('DD.MM.YYYY');
  const getFormattedTime = (date) => moment(date).format('HH:mm');
  const room = selectedLesson.rooms[0];
  const name =
    selectedLesson.type === 'educational_event' || selectedLesson.type === 'master' ? selectedLesson.name : null;
  const schoolLesson =
    selectedLesson.type === 'additional_education' ||
    selectedLesson.type === 'school_lessons' ||
    selectedLesson.type === 'group_consultation' ||
    selectedLesson.type === 'personal_consultation'
      ? SCHOOL_LESSONS?.find((l) => l.value === selectedLesson.description)?.label ||
        ADDITIONAL_EDUCATION_LESSONS?.find((l) => l.value === selectedLesson.description)?.label ||
        ARCHIVED_SCHOOL_LESSONS?.find((l) => l.value === selectedLesson.description)?.label
      : null;

  const [anotherPlayers, setAnotherPlayers] = useState([]);
  const skillTrainingStickerQuery = useSkillTrainingStickerByLessonId(selectedLesson.id, room.roomId);

  useEffect(() => {
    if (selectedPlayersIdsFromAnotherTeam.length) {
      Promise.all(selectedPlayersIdsFromAnotherTeam.map((userId) => UsersAPI.getPlayerById(userId)))
        .then((res) => {
          res.map((student) => {
            setAnotherPlayers((oldArray) => [...oldArray, student.firstName + ' ' + student.lastName]);
          });
        })
        .catch((error) => {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}, попробуйте позже`);
        });
    }
  }, []);

  return (
    <InfoWrapper>
      <FlexContainer>
        <InfoBlock>
          <Label>Тип мероприятия</Label>
          <InfoText>{type}</InfoText>
        </InfoBlock>

        {name && (
          <InfoBlock>
            <Label>Название</Label>
            <InfoText>{name}</InfoText>
          </InfoBlock>
        )}

        {schoolLesson && (
          <InfoBlock>
            <Label>Предмет</Label>
            <InfoText>{schoolLesson}</InfoText>
          </InfoBlock>
        )}
      </FlexContainer>

      <FlexContainer>
        <InfoBlock>
          <Label>Дата начала</Label>
          <InfoText>{getFormattedDate(room.startTimestamp)}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <Label>Время начала</Label>
          <InfoText>{getFormattedTime(room.startTimestamp)}</InfoText>
        </InfoBlock>
      </FlexContainer>

      <FlexContainer>
        <InfoBlock>
          <Label>Дата окончания</Label>
          <InfoText>{getFormattedDate(room.startTimestamp + room.duration)}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <Label>Время окончания</Label>
          <InfoText>{getFormattedTime(room.startTimestamp + room.duration)}</InfoText>
        </InfoBlock>
      </FlexContainer>

      {(selectedLesson.type === 'game' || selectedLesson.type === 'away_game') && lessonTournament?.name && (
        <FlexContainer>
          <InfoBlock>
            <Label>Турнир</Label>
            <InfoText>{lessonTournament?.name}</InfoText>
          </InfoBlock>
        </FlexContainer>
      )}

      <FlexContainer>
        {selectedGroup && (
          <InfoBlock>
            <Label>Команда</Label>
            <InfoText>
              {selectedGroup?.name ||
                selectedGroup
                  ?.map(({name}) => {
                    return name;
                  })
                  ?.join(',') ||
                longDash}
            </InfoText>
          </InfoBlock>
        )}

        {opponent && (
          <InfoBlock>
            <Label>Команда соперника</Label>
            <InfoText>{opponent}</InfoText>
          </InfoBlock>
        )}
      </FlexContainer>

      {selectedLesson.type !== 'room_inaccessibility' && selectedPlayers.length ? (
        <InfoBlock>
          <Label>Игроки</Label>
          <InfoText>
            {selectedPlayers.join(', ')}
            {', ' + anotherPlayers.join(', ')}
          </InfoText>
        </InfoBlock>
      ) : null}

      {selectedLesson.type === 'room_inaccessibility' && selectedLesson?.description ? (
        <InfoBlock>
          <Label>Описание</Label>
          <InfoText>{selectedLesson?.description}</InfoText>
        </InfoBlock>
      ) : null}

      {playerRole && (
        <InfoBlock>
          <Label>Амплуа</Label>
          <InfoText>{playerRole}</InfoText>
        </InfoBlock>
      )}

      <FlexContainer>
        {room.name && (
          <InfoBlock>
            <Label>Место проведения</Label>
            <InfoText>{room.name}</InfoText>
          </InfoBlock>
        )}

        {lockerRoom ? (
          <InfoBlock>
            <Label>Раздевалка</Label>
            <InfoText>{lockerRoom}</InfoText>
          </InfoBlock>
        ) : null}
      </FlexContainer>

      {streamingURL && (
        <InfoBlock>
          <Label>Ссылка</Label>
          <a href={streamingURL} rel="noopener noreferrer" target="_blank">
            {streamingURL}
          </a>
        </InfoBlock>
      )}

      {skillTrainingStickerQuery?.data?.length && (
        <InfoBlock>
          <Label>Актуальные навыки</Label>
          <InfoText>
            <Stack spacing={4}>
              {skillTrainingStickerQuery.data.map((sticker, index) => {
                const name = `${sticker.student.lastName} ${sticker.student.firstName}`;
                return (
                  <Stack direction="row" key={sticker.id}>
                    <Typography flex={0.2}>
                      {index + 1}. {name}
                    </Typography>
                    <Typography flex={0.2}>{sticker.student.birthday}</Typography>
                    <Typography flex={0.2}>{sticker.skillName}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          </InfoText>
        </InfoBlock>
      )}

      <BottomPanel className={'BottomPanel'}>
        {isButtonsShowed && (
          <Buttons className={'Buttons'}>
            <Button color="secondary" variant="contained" onClick={onCLoseButtonClick}>
              Закрыть
            </Button>
            {currentUser.userRole !== 'student' && currentUser.userRole !== 'teacher' && (
              <Button color="primary" onClick={onEditButtonClick}>
                Редактировать
              </Button>
            )}
            {currentUser.userRole === 'teacher' && isTeachersGroupsIdsIncludesSelectedGroup && (
              <Button color="primary" onClick={onEditButtonClick}>
                Редактировать
              </Button>
            )}
          </Buttons>
        )}
      </BottomPanel>
    </InfoWrapper>
  );
};

export default LessonView;
