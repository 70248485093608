import {
  gatesCoords,
  gatesMaxX,
  gatesMaxY,
  gatesMinX,
  gatesMinY,
} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {convertToPixels} from '@helpers/coords.helper';
import useElementSize from '@hooks/useElementSize';
import {Box} from '@ui/MUI';
import {white} from '@ui/MUI/colorsA1Tag';
import React, {SVGProps, useEffect, useMemo} from 'react';

export interface GatesSchemaProps extends SVGProps<SVGSVGElement> {
  eclipses?: Array<{x: number; y: number}>;
}

export const GatesSchema = ({eclipses, ...props}: GatesSchemaProps) => {
  useEffect(() => {
    return () => {
      gatesCoords.cleanUp();
    };
  }, []);

  const [ref, {width, height}] = useElementSize();

  const formattedEclipses = useMemo(() => {
    if (width && height) {
      return eclipses?.map((eclipse) => {
        const {clickX, clickY} = convertToPixels(
          eclipse?.x,
          eclipse?.y,
          width,
          height,
          gatesMinX,
          gatesMaxX,
          gatesMinY,
          gatesMaxY,
        );
        return {...eclipse, realX: clickX, realY: clickY};
      });
    }
    return [];
  }, [eclipses, width, height]);

  return (
    <Box ref={ref} sx={{position: 'relative'}}>
      {formattedEclipses?.map((eclipse, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            bottom: eclipse.realY,
            left: eclipse.realX,
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: white.main,
            '& > svg': {
              maxWidth: 180,
            },
          }}
        />
      ))}
      <svg {...props} fill="none" height="138" viewBox="0 0 222 138" width="222" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 134H19V3H22V134Z" fill="#333333" />
        <path d="M201 134H198V3H201V134Z" fill="#333333" />
        <path d="M199.696 11.1995H20.5117V8.20914H199.696V11.1995Z" fill="#333333" />
        <path d="M199.696 18H20.5117V15.0097H199.696V18Z" fill="#333333" />
        <path d="M199.696 25H20.5117V22.0098H199.696V25Z" fill="#333333" />
        <path d="M199.696 32H20.5117V29.0097H199.696V32Z" fill="#333333" />
        <path d="M199.696 39H20.5117V36.0097H199.696V39Z" fill="#333333" />
        <path d="M199.696 46H20.5117V43.0097H199.696V46Z" fill="#333333" />
        <path d="M199.696 53H20.5117V50.0097H199.696V53Z" fill="#333333" />
        <path d="M199.696 60H20.5117V57.0097H199.696V60Z" fill="#333333" />
        <path d="M199.696 67H20.5117V64.0097H199.696V67Z" fill="#333333" />
        <path d="M199.696 74H20.5117V71.0098H199.696V74Z" fill="#333333" />
        <path d="M199.696 81H20.5117V78.0097H199.696V81Z" fill="#333333" />
        <path d="M199.696 88H20.5117V85.0067H199.696V88Z" fill="#333333" />
        <path d="M199.696 95H20.5117V92.0127H199.696V95Z" fill="#333333" />
        <path d="M199.696 102H20.5117V99.0067H199.696V102Z" fill="#333333" />
        <path d="M199.696 109H20.5117V106.01H199.696V109Z" fill="#333333" />
        <path d="M201 116H19V113H201V116Z" fill="#333333" />
        <path d="M210 123H10V120H210V123Z" fill="#333333" />
        <path d="M217 130H3V127H217V130Z" fill="#333333" />
        <path d="M32 133H29V1H32V133Z" fill="#333333" />
        <path d="M41 133H38V1H41V133Z" fill="#333333" />
        <path d="M49 133H46V1H49V133Z" fill="#333333" />
        <path d="M58 133H55V1H58V133Z" fill="#333333" />
        <path d="M67 133H64V1H67V133Z" fill="#333333" />
        <path d="M75 133H72V1H75V133Z" fill="#333333" />
        <path d="M84 133H81V1H84V133Z" fill="#333333" />
        <path d="M93 133H90V1H93V133Z" fill="#333333" />
        <path d="M101 133H98V1H101V133Z" fill="#333333" />
        <path d="M110 133H107V1H110V133Z" fill="#333333" />
        <path d="M116 133H113V1H116V133Z" fill="#333333" />
        <path d="M123 133H120V1H123V133Z" fill="#333333" />
        <path d="M132 133H129V1H132V133Z" fill="#333333" />
        <path d="M141 133H138V1H141V133Z" fill="#333333" />
        <path d="M149 133H146V1H149V133Z" fill="#333333" />
        <path d="M158 133H155V1H158V133Z" fill="#333333" />
        <path d="M167 133H164V1H167V133Z" fill="#333333" />
        <path d="M175 133H172V1H175V133Z" fill="#333333" />
        <path d="M184 133H181V1H184V133Z" fill="#333333" />
        <path d="M193 133H190V1H193V133Z" fill="#333333" />
        <path d="M2.89527 120.861L1.20312 118.392L19.665 105.683L21.3601 108.148L2.89527 120.861Z" fill="#333333" />
        <path d="M2.89527 128.178L1.20312 125.71L19.665 113L21.3601 115.466L2.89527 128.178Z" fill="#333333" />
        <path d="M2.89527 114.505L1.20312 112.039L19.665 99.3265L21.3601 101.792L2.89527 114.505Z" fill="#333333" />
        <path d="M2.89527 108.148L1.20312 105.683L19.665 92.9702L21.3601 95.4388L2.89527 108.148Z" fill="#333333" />
        <path d="M2.89527 101.792L1.20312 99.3264L19.665 86.6168L21.3601 89.0824L2.89527 101.792Z" fill="#333333" />
        <path d="M2.89527 95.4388L1.20312 92.9703L19.665 80.2607L21.3601 82.7263L2.89527 95.4388Z" fill="#333333" />
        <path d="M2.89527 89.0825L1.20312 86.6169L19.665 73.9044L21.3601 76.37L2.89527 89.0825Z" fill="#333333" />
        <path d="M2.89527 82.7263L1.20312 80.2607L19.665 67.5511L21.3601 70.0137L2.89527 82.7263Z" fill="#333333" />
        <path d="M2.89527 76.37L1.20312 73.9044L19.665 61.1948L21.3601 63.6574L2.89527 76.37Z" fill="#333333" />
        <path d="M2.89527 70.0137L1.20312 67.5511L19.665 54.8385L21.3601 57.3041L2.89527 70.0137Z" fill="#333333" />
        <path d="M2.89527 63.6573L1.20312 61.1947L19.665 48.4822L21.3601 50.9478L2.89527 63.6573Z" fill="#333333" />
        <path d="M2.89527 57.3041L1.20312 54.8385L19.665 42.1259L21.3601 44.5915L2.89527 57.3041Z" fill="#333333" />
        <path d="M2.89527 50.9479L1.20312 48.4823L19.665 35.7727L21.3601 38.2383L2.89527 50.9479Z" fill="#333333" />
        <path d="M2.89527 44.5916L1.20312 42.126L19.665 29.4164L21.3601 31.882L2.89527 44.5916Z" fill="#333333" />
        <path d="M2.89527 38.2382L1.20312 35.7727L19.665 23.0631L21.3601 25.5257L2.89527 38.2382Z" fill="#333333" />
        <path d="M2.89527 31.8821L1.20312 29.4165L19.665 16.7039L21.3601 19.1695L2.89527 31.8821Z" fill="#333333" />
        <path d="M2.89527 25.5258L1.20312 23.0631L19.665 10.3506L21.3601 12.8162L2.89527 25.5258Z" fill="#333333" />
        <path d="M216.911 120.861L198.449 108.148L200.141 105.683L218.603 118.392L216.911 120.861Z" fill="#333333" />
        <path d="M217.5 128.767L198.449 115.466L200.141 113L218.603 125.71L217.5 128.767Z" fill="#333333" />
        <path d="M216.911 114.505L198.449 101.792L200.141 99.3265L218.603 112.039L216.911 114.505Z" fill="#333333" />
        <path d="M216.911 108.148L198.449 95.4388L200.141 92.9702L218.603 105.683L216.911 108.148Z" fill="#333333" />
        <path d="M216.911 101.792L198.449 89.0824L200.141 86.6168L218.603 99.3264L216.911 101.792Z" fill="#333333" />
        <path d="M216.911 95.4388L198.449 82.7263L200.141 80.2607L218.603 92.9703L216.911 95.4388Z" fill="#333333" />
        <path d="M216.911 89.0825L198.449 76.37L200.141 73.9044L218.603 86.6169L216.911 89.0825Z" fill="#333333" />
        <path d="M216.911 82.7263L198.449 70.0137L200.141 67.5511L218.603 80.2607L216.911 82.7263Z" fill="#333333" />
        <path d="M216.911 76.37L198.449 63.6574L200.141 61.1948L218.603 73.9044L216.911 76.37Z" fill="#333333" />
        <path d="M216.911 70.0137L198.449 57.3041L200.141 54.8385L218.603 67.5511L216.911 70.0137Z" fill="#333333" />
        <path d="M216.911 63.6573L198.449 50.9478L200.141 48.4822L218.603 61.1947L216.911 63.6573Z" fill="#333333" />
        <path d="M216.911 57.3041L198.449 44.5915L200.141 42.1259L218.603 54.8385L216.911 57.3041Z" fill="#333333" />
        <path d="M216.911 50.9479L198.449 38.2383L200.141 35.7727L218.603 48.4823L216.911 50.9479Z" fill="#333333" />
        <path d="M216.911 44.5916L198.449 31.882L200.141 29.4164L218.603 42.126L216.911 44.5916Z" fill="#333333" />
        <path d="M216.911 38.2382L198.449 25.5257L200.141 23.0631L218.603 35.7727L216.911 38.2382Z" fill="#333333" />
        <path d="M216.911 31.8821L198.449 19.1695L200.141 16.7039L218.603 29.4165L216.911 31.8821Z" fill="#333333" />
        <path d="M216.911 25.5258L198.449 12.8162L200.141 10.3506L218.603 23.0631L216.911 25.5258Z" fill="#333333" />
        <path
          clipRule="evenodd"
          d="M30.4582 6.89546C30.5124 6.89312 30.5667 6.89079 30.621 6.88849C30.5667 6.89079 30.5125 6.89312 30.4583 6.89545L30.4582 6.89545M197.189 5.76429C195.266 5.26013 193.276 4.96314 191.235 4.88462C190.887 4.85795 190.534 4.85802 190.265 4.85806L31.764 4.85806C31.2821 4.85806 30.8013 4.87879 30.3724 4.89728L30.2158 4.904L30.1982 4.90474L30.1807 4.90579C27.6383 5.05823 25.199 5.5635 22.8884 6.37299C21.8339 6.74256 20.7653 7.173 19.7081 7.71642C15.3648 9.91319 11.6798 13.2636 9.06912 17.3454C7.91721 19.1288 6.95931 21.0766 6.27636 23.156C5.87662 24.288 5.55162 25.4742 5.32 26.6883C5.04468 28.0553 4.89448 29.4603 4.84601 30.8677C4.82003 31.2361 4.82007 31.6082 4.8201 31.8852L4.8201 135.581C4.8201 136.925 3.74032 138 2.39688 138C1.08343 138 0 136.934 0 135.581V31.9124C0 14.3105 14.259 0 31.764 0H190.233C207.738 0 222 14.3107 222 31.9124V135.581C222 136.934 220.915 138 219.603 138C218.259 138 217.183 136.928 217.183 135.581V31.9124C217.183 30.7575 217.108 29.6226 216.957 28.5086C216.831 27.387 216.628 26.2687 216.321 25.1621C215.868 23.3374 215.213 21.6019 214.414 19.9736L214.412 19.9708C211.503 14.0705 206.508 9.35585 200.373 6.83133L200.36 6.82569L200.346 6.82025C199.322 6.41639 198.28 6.07234 197.226 5.77437L197.208 5.76916L197.189 5.76429Z"
          fill="#999999"
          fillRule="evenodd"
        />
      </svg>
    </Box>
  );
};
