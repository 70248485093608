import {useUpdateProspectGoals} from '@components/modules/prospects/queries/prospect-goals.query';
import {Box, TextField, Typography} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {Button, DatePicker, Stack} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import moment from 'moment';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {completedGoal} from '../models/drawer.models';

interface FormData {
  finalResult: string | null;
  finalResultDate: string | null;
}
export const CompleteGoalDrawer = () => {
  const {playerId} = useParams<{playerId: string}>();
  const form = useForm<FormData>({
    defaultValues: {
      finalResult: null,
      finalResultDate: null,
    },
  });

  useSignals();

  const handleClose = () => {
    completedGoal.close();
    form.reset();
  };

  const updateMutation = useUpdateProspectGoals(playerId);

  const handleSubmit = async (data: FormData) => {
    const formatted = {
      ...data,
      finalDate: moment(data.finalResultDate).format('YYYY-MM-DD'),
      userId: playerId,
      id: completedGoal.payload?.id,
    };

    await updateMutation.mutateAsync(formatted);
    handleClose();
  };

  return (
    <Drawer
      buttons={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            color="primary"
            disabled={!form.formState.isValid}
            variant="contained"
            onClick={form.handleSubmit(handleSubmit)}
          >
            Сохранить
          </Button>
          <Button variant="contained">Отменить</Button>
        </Stack>
      }
      open={completedGoal.isOpen}
      title="Завершение цели"
      onClose={handleClose}
    >
      <Stack mt={5} spacing={3}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Цель:</Typography>
          <Typography>{completedGoal.payload?.goal}</Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Ожидаемый результат:</Typography>
          <Typography>{completedGoal.payload?.expectedResult}</Typography>
        </Stack>
        <Box maxWidth={230}>
          <Controller
            control={form.control}
            name="finalResultDate"
            render={({field, fieldState}) => (
              <DatePicker
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="Дата завершения"
              />
            )}
            rules={{required: 'Поле обязательно для заполнения'}}
          />
        </Box>
        <Controller
          control={form.control}
          name="finalResult"
          render={({field, fieldState}) => (
            <TextField
              {...field}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label="Фактический результат"
              variant="standard"
            />
          )}
          rules={{required: 'Поле обязательно для заполнения'}}
        />
      </Stack>
    </Drawer>
  );
};
