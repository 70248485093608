import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import {StackProps} from '@mui/material/Stack/Stack';
import {Stack, Typography} from '@ui/MUI';
import {black} from '@ui/MUI/colorsV2';
import React from 'react';

function NoData({text = 'Данные отсутствуют', wrapperProps}: {wrapperProps?: StackProps; text?: string}) {
  return (
    <Stack alignItems="center" direction="column" justifyContent="center" {...wrapperProps}>
      <SportsHockeyIcon sx={{fill: black['66'], width: 44, height: 44}} />
      <Typography color={black['66']} variant="body1">
        {text}
      </Typography>
    </Stack>
  );
}

export default NoData;
