import UsersAPI from '@api/UsersAPI';
import {useQuery} from '@tanstack/react-query';
import {Student} from '../types/student.types';

export const useStudent = (id?: string) => {
  return useQuery<Student>({
    queryKey: ['student', id],
    queryFn: async () => {
      return UsersAPI.getStudentById(id as string) as Promise<Student>;
    },
    enabled: !!id,
  });
};
