import PlayerProgressFilters from '@components/apps/PhysicalTesting/components/PlayerProgress/components/PlayerProgressFilters';
import PlayerProgressTable from '@components/apps/PhysicalTesting/components/PlayerProgress/components/PlayerProgressTable';
import isObjectEmpty from '@helpers/isObjectEmpty';
import {useStudent} from '@hooks/filters';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Box, useMediaQuery} from '@mui/material';
import {ESTIMATION_STAGES, TYPE_OF_TESTS} from '@src/constants/config';
import {clearPhysicalPlayerState, fetchPlayerProgressData} from '@src/redux/slices/PhysicalTestingPlayerProgress';
import {UserRoles} from '@src/types';
import {Autocomplete, Stack} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import {uniqBy} from 'lodash';
import React, {useEffect, useState} from 'react';

import {exercisesForMobileTabs, ofpTestsExercisesOrdersMatrix, sfpTestsExercisesOrdersMatrix} from '../../constants';
import PlayerProgressChart from './components/PlayerProgressChart';

const Filters = ({filter, setFilter, isStudent}) => {
  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [
      UserRoles.ADMIN,
      UserRoles.TEACHER,
      UserRoles.SCOUT,
      UserRoles.FRANCHISEE,
      UserRoles.STUDENT,
      UserRoles.SCHOOLS_ADMIN,
      UserRoles.PSYCHO,
      UserRoles.METHODIST,
    ],
  });
  const groups = useGroupsV2({schoolIds: filter?.school?.value ? [filter?.school?.value] : []});
  const students = useStudent({connectorValue: filter?.group?.value});
  const seasons = useSeasons(true, ({currentSeason}) => {
    setFilter({season: [{value: currentSeason?.value, label: currentSeason?.label}]});
  });

  return (
    <Box mb={1} px={3} py={2} sx={{backgroundColor: '#fff', borderRadius: '6px', overflow: 'hidden'}}>
      <Stack alignItems="flex-end" direction="row" flexWrap={{xxs: 'wrap', sm: 'nowrap'}} gap={3}>
        <Box width={{xxs: '100%', sm: 185}}>
          <Autocomplete
            isSelectableAll
            isLoading={seasons.isLoading}
            label="Сезон"
            limitTags={1}
            options={seasons.options}
            value={filter?.season}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                season: v,
              }));
            }}
          />
        </Box>
        {!isStudent && (
          <Box width={{xxs: '100%', sm: 185}}>
            <Autocomplete
              disabled={schools?.isDisabled || groups?.isLoading}
              isLoading={schools.isLoading}
              label="Школа"
              multiple={false}
              options={schools.options}
              value={filter?.school}
              onChange={(__, v) => {
                setFilter((prevState) => ({
                  ...prevState,
                  school: v,
                  group: '',
                }));
              }}
            />
          </Box>
        )}
        {!isStudent && (
          <Box width={{xxs: '100%', sm: 185}}>
            <Autocomplete
              disabled={groups?.isDisabled || schools?.isLoading}
              isLoading={groups.isLoading}
              label="Команда"
              multiple={false}
              options={groups.options}
              value={filter?.group}
              onChange={(__, v) => {
                setFilter((prevState) => ({
                  ...prevState,
                  group: v,
                }));
              }}
            />
          </Box>
        )}
        {!isStudent && (
          <Box width={{xxs: '100%', sm: 185}}>
            <Autocomplete
              isLoading={students.isLoading}
              label="Игрок"
              multiple={false}
              options={students.options}
              value={filter?.student}
              onChange={(__, v) => {
                setFilter((prevState) => ({
                  ...prevState,
                  student: v,
                }));
              }}
            />
          </Box>
        )}
        <Box width={{xxs: '100%', sm: 185}}>
          <Autocomplete
            isSelectableAll
            label="Этап"
            limitTags={1}
            options={ESTIMATION_STAGES}
            value={filter?.stage}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                stage: v,
              }));
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 185}}>
          <Autocomplete
            label="Виды тестов"
            multiple={false}
            options={TYPE_OF_TESTS}
            value={filter?.typeOfTests}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                typeOfTests: v,
              }));
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

const PlayerProgress = (props) => {
  const dispatch = useStoreDispatch();
  const {items, isLoading} = useStore((state) => state.physicalTestingPlayerProgress.playerProgressInfo);
  const isStudent = useStore((state) => state.users.currentUser.userRole === UserRoles.STUDENT);
  const currentUserId = useStore((state) => state.users.currentUserId);
  const currentUserGroupId = useStore((state) => state.users.currentUser?.groups?.[0]?.objectId);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [filtersState, setFiltersState] = useState({});

  const allExercisesResults = Object.keys(items)
    ?.reduce((res, currentSeason) => {
      res.push(items[currentSeason]);
      return res;
    }, [])
    .flat()
    ?.sort();
  const uniqueExercises = uniqBy(allExercisesResults, (item) => item.exercise)
    .map((item) => ({
      id: item.exercise,
      order: ofpTestsExercisesOrdersMatrix[item.exercise] || sfpTestsExercisesOrdersMatrix[item.exercise],
    }))
    .sort((a, b) => a.order - b.order);
  const formattedInfoForStudentMobileVersion = uniqueExercises.reduce((res, current) => {
    const exercise = allExercisesResults?.filter((result) => {
      return result.exercise === current?.id;
    });
    res[current?.id] = exercise;
    return res;
  }, {});

  const fetchData = () => {
    if (
      (filtersState?.student?.value || currentUserId) &&
      filtersState?.season?.length &&
      filtersState?.stage?.length &&
      filtersState?.typeOfTests?.value
    ) {
      const data = {
        groupId: filtersState?.group?.value || currentUserGroupId,
        playerId: isStudent ? currentUserId : filtersState?.student?.value,
        season: filtersState?.season?.map((item) => item.value),
        stage: filtersState?.stage?.map((item) => item.value),
        type: filtersState?.typeOfTests?.value,
      };
      dispatch(fetchPlayerProgressData(data));
    }
  };

  const [mobileTabs, setMobileTabs] = useState([]);

  useDeepEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalPlayerState());
    };
  }, [filtersState]);

  useEffect(() => {
    if (isMobile && filtersState?.typeOfTests?.value) {
      const constructExerciseMarkUp = (tab) =>
        tab?.exerciseIds
          .map((id) => {
            if (formattedInfoForStudentMobileVersion[id]) {
              return (
                <PlayerProgressChart
                  colors={tab.colors}
                  data={formattedInfoForStudentMobileVersion[id] || []}
                  key={id}
                />
              );
            }
          })
          ?.filter((item) => item);
      setMobileTabs(
        exercisesForMobileTabs[filtersState?.typeOfTests?.value]?.map((tab) => {
          return {
            label: tab.name,
            value: !constructExerciseMarkUp(tab)?.length ? (
              <TableEmptyBlock
                data-testid="no-data"
                text="Отсутствуют результаты тестов по выбранному типу упражнений."
              />
            ) : (
              <Box>{constructExerciseMarkUp(tab)}</Box>
            ),
          };
        }),
      );
    }
  }, [isMobile, filtersState?.typeOfTests?.value, isObjectEmpty(formattedInfoForStudentMobileVersion)]);
  return (
    <>
      <Box mb={2}>
        {!isStudent ? (
          <Filters filter={filtersState} isStudent={isStudent} setFilter={setFiltersState} />
        ) : (
          <PlayerProgressFilters filtersState={filtersState} isStudent={isStudent} setFiltersState={setFiltersState} />
        )}
      </Box>
      {isObjectEmpty(items) && !isLoading ? (
        <TableEmptyBlock
          data-testid="no-data"
          text={
            (filtersState.student || currentUserId) &&
            filtersState.season?.length &&
            filtersState.stage?.length &&
            filtersState.typeOfTests
              ? 'Нет данных'
              : 'Выберите вид тестов, сезон и этап, чтобы посмотреть результаты тестирования'
          }
        />
      ) : isStudent && isMobile && mobileTabs?.length > 0 ? (
        <TabsNew withoutWrapper tabs={mobileTabs} />
      ) : (
        <PlayerProgressTable isLoading={isLoading} tableData={items} />
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: setFiltersState})}
    </>
  );
};

export default PlayerProgress;
