import {getPlayerDiaryAsExcelFile} from '@api/playerDiary';
import DayOrMonthFilter, {switchButtons} from '@common/DevelopmentJournal/components/DayOrMonthFilter';
import {usePlayerDiaryAverage} from '@common/DevelopmentJournal/hooks';
import Table from '@components/apps/DevelopmentJournalDetails/components/Table';
import {getTeamName, unDuplicateArrayObjects} from '@helpers/CommonHelper';
import date from '@helpers/date';
import StateHelper from '@helpers/StateHelper';
import useQueryString from '@hooks/useQueryString';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {FilePresentRounded} from '@mui/icons-material';
import {IconButton, useMediaQuery} from '@mui/material';
import {selectPlayerDiaryReport} from '@selectors/playerDiary';
import {loadPlayerDiaryReport} from '@slices/playerDiary';
import {DEFAULT_DATE_SEND_FORMAT} from '@src/constants';
import {QUESTIONNAIRE_ITEMS} from '@src/constants/playerDiary';
import {Box, Button} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import QuestionnaireStatistics from '../DevelopmentJournal/components/PlayersDiary/components/QuestionnaireStatistics';
import {mock} from '../DevelopmentJournal/components/PlayersDiary/mock';

function DevelopmentJournalDetails() {
  const history = useHistory();
  const [qs, setQs] = useQueryString();
  const [datePickerValue, setDatePickerValue] = useState(qs?.reportDate);

  const [players, setPlayers] = useState([]);
  useEffect(() => {
    if (qs?.group) {
      StateHelper.getStudentsByTeamId(qs?.group).then((players) => setPlayers(players?.map(({student}) => student)));
    }
  }, [qs?.group]);

  const [selectedViewType, setSelectedViewType] = useState(switchButtons?.[0]?.name);

  const isMonth = selectedViewType === 'month';
  const isDay = selectedViewType === 'day';

  const [activeTypeOfView, setActiveTypeOfView] = useState('table');

  const defaultSelectedMonthInterval = [
    date(qs?.reportDate).startOf('month').format(DEFAULT_DATE_SEND_FORMAT),
    date(qs?.reportDate).endOf('month').format(DEFAULT_DATE_SEND_FORMAT),
  ];

  const [activeMonthInterval, setActiveMonthInterval] = useState(defaultSelectedMonthInterval);
  useEffect(() => {
    if (isMonth) {
      const activeMonth = date(activeMonthInterval?.[0]).locale('en').format('MMMM').toLowerCase();
      setQs({...qs, month: activeMonth, reportDate: null});
    }
  }, [isMonth, activeMonthInterval?.[0]]);

  const {data, isLoading} = usePlayerDiaryAverage({
    filters: {interval: isDay ? [datePickerValue, datePickerValue] : activeMonthInterval},
    groupId: qs?.group,
  });

  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (activeTypeOfView === 'chart') {
      dispatch(
        loadPlayerDiaryReport({
          startDate: isDay ? datePickerValue : activeMonthInterval?.[0],
          endDate: isDay ? datePickerValue : activeMonthInterval?.[1],
          groupId: qs?.group,
        }),
      );
    }
  }, [activeTypeOfView, qs?.group, isDay, datePickerValue, activeMonthInterval?.[0], activeMonthInterval?.[1]]);

  const chartData = useStore(selectPlayerDiaryReport);

  const formattedData = players?.map((player) => {
    return {
      student: player,
      ...data?.students?.find((item) => {
        return item?.student?.id === player?.id;
      }),
    };
  });

  const [teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamName(qs?.group).then((res) => {
      setTeamName(res);
    });
  }, [qs?.group]);

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getExcelFile = () => {
    return getPlayerDiaryAsExcelFile({
      groupId: qs?.group,
      startDate: activeMonthInterval?.[0],
      endDate: activeMonthInterval?.[1],
    }).then((res) => {
      const content = new Blob([res.data], {type: res.request?.['content-type']});
      const encodedUri = window.URL.createObjectURL(content);
      const link = document.createElement('a');

      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Development-Journal-${Date.now()}.xlsx`);

      link.click();
    });
  };

  const tabs = [
    {
      id: 'average',
      label: 'Среднее по анкетам',
      value: <Table data={formattedData} isLoading={isLoading} isMonth={isMonth} reportDate={qs?.reportDate} />,
    },
    {
      id: 'occupancyPercent',
      label: 'Процент заполняемости',
      value: (
        <Table
          data={formattedData}
          isLoading={isLoading}
          isMonth={isMonth}
          isPercentsTab={true}
          reportDate={qs?.reportDate}
          teamAverage={data?.team}
        />
      ),
    },
  ];

  const {value, onChange} = useQueryStringTabs({tabs, queryKey: 'subTab'});

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Журнал развития',
            path: `/development_journal${history?.location?.search}`,
          },
          {
            label: `Журнал ${teamName}`,
          },
        ]}
      >
        Журнал {teamName}
      </PageTitleDefault>
      <DayOrMonthFilter
        activeMonthInterval={activeMonthInterval}
        activeTypeOfView={activeTypeOfView}
        datePickerValue={datePickerValue}
        selectedViewType={selectedViewType}
        setActiveMonthInterval={setActiveMonthInterval}
        setActiveTypeOfView={setActiveTypeOfView}
        setDatePickerValue={setDatePickerValue}
        setSelectedViewType={setSelectedViewType}
      />
      {activeTypeOfView === 'table' &&
        (isDay ? (
          <Table data={formattedData} isLoading={isLoading} reportDate={qs?.reportDate} />
        ) : (
          <>
            <Box sx={{'.TabsWrapper': {mt: qs?.subTab === 'occupancyPercent' ? '-36px' : '-4px'}}}>
              {qs?.subTab === 'occupancyPercent' && (
                <>
                  {!isSmResolution ? (
                    <Box display="flex" justifyContent="flex-end" position="relative" width="100%" zIndex={2}>
                      <Button
                        size="small"
                        sx={{
                          height: '32px',
                        }}
                        type="link"
                        variant="contained"
                        onClick={getExcelFile}
                      >
                        Выгрузить в Эксель
                      </Button>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <IconButton
                        color="secondary"
                        sx={{
                          width: '24px',
                          height: '24px',
                        }}
                        variant="contained"
                      >
                        <FilePresentRounded />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
              <TabsNew
                withoutWrapper
                sx={{maxWidth: 'calc(100% - 32px)'}}
                tabs={tabs}
                value={value}
                onChange={onChange}
              />
            </Box>
          </>
        ))}
      {activeTypeOfView === 'chart' &&
        QUESTIONNAIRE_ITEMS?.map(({id, ...item}) => {
          const uniqueChartData = chartData?.data?.[id]
            ? Object?.keys(chartData?.data?.[id])?.reduce((res, key) => {
                if (key === 'dayOffPlayers' || key === 'notCompleted') {
                  res[key] = unDuplicateArrayObjects(chartData?.data?.[id]?.[key], 'id');
                } else {
                  res[key] = chartData?.data?.[id]?.[key];
                }
                return res;
              }, {})
            : {};
          return (
            <Box key={id} sx={{mb: 1}}>
              <QuestionnaireStatistics
                data={isLoading ? mock[id] : uniqueChartData || {}}
                dateInterval={isDay ? [datePickerValue, datePickerValue] : activeMonthInterval}
                isLoading={chartData?.isLoading}
                {...item}
              />
            </Box>
          );
        })}
    </>
  );
}

export default DevelopmentJournalDetails;
