export const primary = {
  main: '#DB2222',
  dark: '#8C1A1A',
  light: '#FF9999',
};

export const secondary = {
  main: '#E75151',
  dark: {
    light: '#E75151',
    dark: '#A23939',
  },
  yellow: {
    light: '#F69B2D',
    dark: '#AD6D20',
  },
  green: {
    light: '#329474',
    dark: '#236851',
  },
  blue: {
    light: '#236851',
    dark: '#355B93',
  },
  purple: {
    light: '#A74EBE',
    dark: '#753785',
  },
};

export const black = {
  8: '#141414',
  10: '#1A1A1A',
  12: '#1F1F1F',
  15: '#262626',
  20: '#333333',
  25: '#404040',
  30: '#4D4D4D',
  60: '#999999',
  75: '#BFBFBF',
  95: '#F1F1F3',
};

export const white = {
  main: '#ffffff',
};
