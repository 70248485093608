import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import {Button} from '@ui/MUI';
import React from 'react';
import {createGlobalStyle} from 'styled-components';

import {
  DrawerButtonsBlock,
  DrawerButtonsBlockContent,
  DrawerCloser,
  DrawerContentContainer,
  DrawerInner,
} from '../Drawer/styles';
/**@deprecated не использовать этот компонент, заменить на ./DrawerNew**/
export default function DrawerDefault(props) {
  const {
    anchor = 'right',
    content = React.ReactNode,
    isOpen = false,
    drawerState = {},
    setDrawerState,
    isButtonsShowed = true,
    buttons = React.ReactNode,
    closeButtonText = 'Закрыть',
    onClose,
    width,
  } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!drawerState) {
      setDrawerState(false);
      return;
    }
    if (isOpen) {
      setDrawerState(false);
    } else {
      setDrawerState({...drawerState, [anchor]: open});
    }
  };

  return (
    <>
      <GlobalStyle width={width} />
      <Drawer
        anchor={anchor}
        open={isOpen || drawerState[anchor]}
        transitionDuration={330}
        onClose={onClose || toggleDrawer(anchor, false)}
      >
        <DrawerCloser onClick={onClose || toggleDrawer(anchor, false)}>
          <CloseIcon sx={{width: 18, height: 18}} />
        </DrawerCloser>
        <DrawerInner>
          <DrawerContentContainer>{content}</DrawerContentContainer>
          {isButtonsShowed && (
            <>
              {buttons ? (
                <DrawerButtonsBlock>
                  <DrawerButtonsBlockContent isWithButtons={buttons.closeButton && buttons.saveButton}>
                    {((buttons.closeButton || buttons.saveButton) && (
                      <>
                        {buttons.saveButton && (
                          <Button
                            disableElevation
                            color="primary"
                            disabled={buttons.saveButton?.isDisabled}
                            form={buttons?.saveButton?.form && buttons.saveButton.form}
                            text={buttons.saveButton?.label}
                            type={buttons?.saveButton?.form && 'submit'}
                            variant="contained"
                          />
                        )}
                        {buttons.closeButton && (
                          <Button
                            disableElevation
                            color={buttons?.closeButton?.color}
                            text={buttons?.closeButton?.label}
                            variant="contained"
                            onClick={buttons.closeButton.onClick}
                          />
                        )}
                      </>
                    )) ||
                      buttons}
                  </DrawerButtonsBlockContent>
                </DrawerButtonsBlock>
              ) : (
                <DrawerButtonsBlock>
                  <DrawerButtonsBlockContent>
                    <Button color="secondary" variant="contained" onClick={onClose || toggleDrawer(anchor, false)}>
                      {closeButtonText}
                    </Button>
                  </DrawerButtonsBlockContent>
                </DrawerButtonsBlock>
              )}
            </>
          )}
        </DrawerInner>
      </Drawer>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  .MuiDrawer {
    &-paper {
      width: 100%;
      max-width: ${(props) => props.width || '640px'};
      box-shadow: -4px 0 12px rgba(0, 0, 0, .15);
      border-radius: 0 0 0 6px;
      box-sizing: border-box;
      
      @media screen and (max-width:1024px) {
        width: 100%;
      }
    }
  } 
`;
