import ContentWrapper from '@common/ContentWrapper';
import {BoxProps} from '@mui/material/Box/Box';
import React from 'react';

function DashboardBlock({children, ...props}: BoxProps) {
  return (
    <ContentWrapper borderRadius={3} boxShadow="0 2px 16px 0 #00000014" {...props}>
      {children}
    </ContentWrapper>
  );
}

export default DashboardBlock;
