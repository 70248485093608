import {computed, signal} from '@preact/signals-react';
import {A1TagEventDto} from '@src/types/generated';

import {
  A1TagChooseTeamModalViewModal,
  A1tagStatistic,
  A1tagViewModel,
  Event,
  EventType,
  Lines,
  Period,
  Roasters,
  Team,
} from './a1tag.view-mode.types';

const defaultRosters = JSON.parse(localStorage.getItem('a1TagRoasters')) as Roasters | null;
const defaultStatistics = (JSON.parse(localStorage.getItem('a1TagStatistics')) ?? []) as A1tagStatistic[];
export const a1tagViewModel: A1tagViewModel = {
  isTimerStart: signal<boolean>(false),
  isGodMode: signal<boolean>(false),
  isGameStopped: signal<boolean>(true),
  homeScore: signal<number>(0),
  awayScore: signal<number>(0),
  cleanTime: signal<number>(0),
  period: signal<Period>('1'),
  lines: signal<Lines>('5-5'),
  rosters: signal<Roasters | null>(defaultRosters),
  statistics: signal<A1tagStatistic[]>(defaultStatistics),
  events: signal<Event[]>([]),
};

export const chooseTeamModalViewModel: A1TagChooseTeamModalViewModal = {
  payload: signal<any>(null),
};

const grabUniqPlayers = (team: Team) => {
  const players = [...(team?.players?.goalies || []), ...(team?.players?.fieldPlayers || [])];
  return Array.from(new Set(players));
};

export const uniqHomePlayers = computed(() => {
  const rosters = a1tagViewModel.rosters.value;
  if (!rosters) return [];
  return grabUniqPlayers(rosters.homeTeam);
});

export const uniqAwayPlayers = computed(() => {
  const rosters = a1tagViewModel.rosters.value;
  if (!rosters) return [];

  return grabUniqPlayers(rosters.awayTeam);
});

export const allPlayers = computed(() => {
  return [...uniqHomePlayers.value, ...uniqAwayPlayers.value];
});

export const allTeams = computed(() => {
  const rosters = a1tagViewModel.rosters.value;
  if (!rosters) return [];

  return [rosters.homeTeam, rosters.awayTeam];
});

export const updateChooseTeamPayload = (payload: any) => {
  chooseTeamModalViewModel.payload.value = payload;
};

export const updateGodMode = (isGodMode: boolean) => {
  a1tagViewModel.isGodMode.value = isGodMode;
};

export const updateScore = (team: 'home' | 'away', score: number) => {
  if (team === 'home') {
    a1tagViewModel.homeScore.value = score;
  }

  if (team === 'away') {
    a1tagViewModel.awayScore.value = score;
  }
};

export const updateIsTimerStart = (isTimerStart: boolean) => {
  a1tagViewModel.isTimerStart.value = isTimerStart;
};

export const updateIsGameStopped = (isGameStopped: boolean) => {
  a1tagViewModel.isGameStopped.value = isGameStopped;
};

export const updateCleanTime = (cleanTime: number) => {
  a1tagViewModel.cleanTime.value = cleanTime;
};

export const updatePeriod = (period: Period) => {
  a1tagViewModel.period.value = period;
};

export const updateLines = (lines: Lines) => {
  a1tagViewModel.lines.value = lines;
};

export const updateRoasters = (rosters: Roasters) => {
  a1tagViewModel.rosters.value = rosters;
  localStorage.setItem('a1TagRoasters', JSON.stringify(rosters));
};

export const appendStatistic = (statistic: A1tagStatistic) => {
  if (!statistic) return;
  a1tagViewModel.statistics.value = [statistic, ...a1tagViewModel.statistics.value];
  localStorage.setItem('a1TagStatistics', JSON.stringify(a1tagViewModel.statistics.value));
};

export const updateStatistic = (statistic: A1tagStatistic) => {
  const statistics = a1tagViewModel.statistics.value;
  const findedStatistic = statistics.find((item) => item.id === statistic.id);

  if (!findedStatistic) return;

  const updatedStatistics = statistics.map((item) =>
    item.id === statistic.id
      ? {
          ...item,
          ...statistic,
        }
      : item,
  );
  a1tagViewModel.statistics.value = updatedStatistics;
  localStorage.setItem('a1TagStatistics', JSON.stringify(updatedStatistics));
};

export const deleteStatistic = (id: string) => {
  const statistics = a1tagViewModel.statistics.value;
  const filteredStatistic = statistics.filter((item) => item.id !== id);
  a1tagViewModel.statistics.value = filteredStatistic;
  localStorage.setItem('a1TagStatistics', JSON.stringify(filteredStatistic));
};

export const cleanUpStatistics = () => {
  a1tagViewModel.statistics.value = [];
  localStorage.setItem('a1TagStatistics', JSON.stringify([]));
};

// TODO: add caching
// add here a function that will take Event and cache in localStorage
// if time >= 1 day, remove it from cache
// if time < 1 day, update it in cache
export const updateEvents = (type: EventType, events: A1TagEventDto[]): void => {
  const eventsWithTypes = events.map((event) => ({...event, type}));

  a1tagViewModel.events.value = [...a1tagViewModel.events.value, ...eventsWithTypes];
};

export const getEventByName = (name: string) => {
  return a1tagViewModel.events.value.find((event) => event.name === name);
};

export const deleteStatisticsByIds = (ids: string[]) => {
  const statistics = a1tagViewModel.statistics.value;
  const filteredStatistics = statistics.filter((item) => !ids.includes(item.id));
  a1tagViewModel.statistics.value = filteredStatistics;
  localStorage.setItem('a1TagStatistics', JSON.stringify(filteredStatistics));
};
