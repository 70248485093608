import {signal} from '@preact/signals-react';

export const goalsHistoryModel = {
  filters: {
    _startDate: signal<string | null>(null),
    _endDate: signal<string | null>(null),

    get startDate() {
      return this._startDate.value;
    },

    get endDate() {
      return this._endDate.value;
    },

    updateStartDate(date: string) {
      this._startDate.value = date;
    },

    updateEndDate(date: string) {
      this._endDate.value = date;
    },
  },
};
