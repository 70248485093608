import ReactTableComponent from '@common/ReactTableComponent';
import ChessTableCell from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/ChessTableCell';
import SimpleLoadingCell from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/SimpleLoadingCell';
import TeamNameCell from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/TeamNameCell';
import {useTableTabTableData} from '@components/apps/StatisticsWithMkcUral/hooks/useTableTabTableData';
import {useMediaQuery} from '@mui/material';
import {longDash, STATISTICS_TABLE_TYPES} from '@src/constants';
import {Box, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import Switch from '@ui/MUI/Switch';
import {cloneDeep, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {memo, useMemo, useState} from 'react';

ChessTable.propTypes = {
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};

function ChessTable({isFinal = false, season}) {
  const [isChecked, setIsChecked] = useState(true);

  const {
    [STATISTICS_TABLE_TYPES.chess]: {isLoading, isLoad, error, data},
  } = useTableTabTableData(STATISTICS_TABLE_TYPES.chess, isFinal, season);
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const columns = useMemo(() => {
    return [
      {
        id: 'team',
        accessorKey: 'team',
        header: 'Команды',
        sticky: {left: true},
        cell: ({getValue}) => {
          const {isNext, value} = getValue();
          return <TeamNameCell isLoading={isLoading} isNext={isNext} value={value} />;
        },
      },
      {
        id: 'puckDifference',
        accessorKey: 'puckDifference',
        header: <Tooltip title="Разница шайб">РШ</Tooltip>,
        cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
      },
      {
        id: 'score',
        accessorKey: 'score',
        header: <Tooltip title="Очки">О</Tooltip>,
        cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
      },
      ...(isLoading ? [...Array(10)] : data.columns || []).map((item, id) => {
        return {
          id: id + 'team',
          accessorFn: (params) => {
            return params?.[item?.id];
          },
          header: () => (
            <Tooltip title={item?.name}>
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[90, 100]}
                sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                variant="subtitle2"
              >
                {item?.name}
              </Typography>
            </Tooltip>
          ),
          headerStyle: {
            minWidth: '130px',
            maxWidth: '130px',
          },
          cell: ({getValue}) => {
            const value = getValue();
            return <ChessTableCell data={value?.value || []} isLoading={isLoading} scoreView={isChecked} />;
          },
        };
      }),
    ];
  }, [isLoading, isSmResolution, data?.columns, isChecked]);

  const rows = useMemo(
    () =>
      (isLoading ? [...Array(10)] : data?.rows)?.map((item, id) => {
        const games = item?.games ? cloneDeep(item?.games) : {};
        if (!isEmpty(games)) {
          Object.keys(games).forEach((gameId) => {
            games[gameId] = {
              value: games?.[gameId]?.data,
              isNext: item?.isNext || false,
              isOurTeam: item?.isOurTeam,
              underline: item?.underline || 'none',
            };
          });
        }
        return {
          id,
          ...item,
          team: {
            value: {teamName: item?.team?.name || longDash, teamLogo: item?.team?.teamLogo || ''},
            isNext: item?.isNext || false,
            isOurTeam: item?.isOurTeam,
          },
          ...games,
        };
      }),
    [isLoading, data?.rows],
  );

  if ((isLoad && error) || (isLoad && !data?.columns?.length && !data?.rows?.length)) {
    return (
      <Stack alignItems="center" height={350} justifyContent="center" position="relative">
        <Typography variant="body1">Данные по играм отсутствуют</Typography>
      </Stack>
    );
  }
  return (
    <Box width="100%">
      <Stack
        alignItems="center"
        borderBottom={`1px solid ${grey['24']}`}
        direction="row"
        px={{xxs: 2, sm: 4}}
        py={2}
        spacing={1}
      >
        <Typography color={isChecked ? grey['54'] : 'primary.main'} variant="subtitle2">
          Форма
        </Typography>
        <Switch checked={isChecked} color="primary" disabled={isLoading} onChange={() => setIsChecked(!isChecked)} />
        <Typography color={!isChecked ? grey['54'] : 'primary.main'} variant="subtitle2">
          Счет
        </Typography>
      </Stack>

      <ReactTableComponent
        columns={columns}
        data={rows}
        tableBodyCellProps={{
          sx: (theme) => ({
            padding: theme.spacing(2, 3) + '!important',
            textAlign: 'center',
            height: 'auto !important',
            '&:last-child, &:first-child': {
              textAlign: 'left',
            },
            '&:nth-child(n+4)': {
              boxShadow: `${grey['12']} 0 -1px 0 0 inset`,
            },
          }),
        }}
        tableHeaderCellProps={{
          sx: (theme) => ({
            padding: theme.spacing(2, 3) + '!important',
            textAlign: 'center',
            height: 'auto !important',
            '&:last-child, &:first-child': {
              textAlign: 'left',
            },
            '&:nth-child(n+4)': {
              boxShadow: `${grey['12']} 0 -1px 0 0 inset`,
            },
          }),
        }}
      ></ReactTableComponent>
    </Box>
  );
}

export default memo(ChessTable);
