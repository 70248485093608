import {API_ENDPOINT} from '@src/constants/config';
import {
  CreateProspectBodyDto,
  CreateReportDto,
  CreateSkillTypeDto,
  ProspectTeamDto,
  ProspectUserDto,
  ReportDto,
  SkillCreateDto,
  SkillDto,
  SkillTypeDto,
} from '@src/types/generated';
import axios from 'axios';

export const getProspectGroup = async (): Promise<ProspectTeamDto[]> => {
  return axios.get<ProspectTeamDto[]>(`${API_ENDPOINT}/v1/prospect/groups`).then((res) => res.data);
};

export const createProspect = async (body: CreateProspectBodyDto): Promise<ProspectUserDto> => {
  return axios
    .post<ProspectUserDto>(`${API_ENDPOINT}/v1/prospect/prospects`, body, {
      success: {message: 'Проспект успешно сохранен'},
      failed: {message: 'Ошибка при сохранении проспекта'},
    })
    .then((res) => res.data);
};

export const createProspects = async (ids: string[]): Promise<ProspectUserDto[]> => {
  return axios
    .post<ProspectUserDto[]>(
      `${API_ENDPOINT}/v1/prospect/prospects`,
      {ids},
      {
        success: {message: 'Проспекты успешно сохранены'},
        failed: {message: 'Ошибка при сохранении проспектов'},
      },
    )
    .then((res) => res.data);
};

export const getProspectSkillsByUserId = async (query: {
  userId?: string;
  season?: string;
  isConstant?: boolean;
}): Promise<SkillDto[]> => {
  return axios
    .get<SkillDto[]>(`${API_ENDPOINT}/v1/prospect/skill/${query.userId}`, {params: query})
    .then((res) => res.data);
};

export const getProspectSkillTypes = async (params: {isConstant?: boolean}): Promise<SkillTypeDto[]> => {
  return axios.get<SkillTypeDto[]>(`${API_ENDPOINT}/v1/prospect/skill/type`, {params}).then((res) => res.data);
};

export const createSkillTypes = async (
  userId: string,
  data: {types: CreateSkillTypeDto[]},
): Promise<SkillTypeDto[]> => {
  return axios
    .post<SkillTypeDto[]>(`${API_ENDPOINT}/v1/prospect/skill/type/types/${userId}`, data, {
      success: {message: 'Навыки успешно сохранены'},
      failed: {message: 'Ошибка при сохранении навыков'},
    })
    .then((res) => res.data);
};

export const deleteSkillTypes = async (id: string): Promise<void> => {
  return axios
    .delete<void>(`${API_ENDPOINT}/v1/prospect/skill/type/${id}`, {
      success: {message: 'Навык успешно удален'},
      failed: {message: 'Ошибка при удаление навыка'},
    })
    .then((res) => res.data);
};

export const getProspects = async (query: {teamId?: string}): Promise<ProspectUserDto[]> => {
  return axios.get<ProspectUserDto[]>(`${API_ENDPOINT}/v1/prospect`, {params: query}).then((res) => res.data);
};

export const createProspectSkills = async (data: Omit<SkillCreateDto, 'endScore'>[]): Promise<SkillDto[]> => {
  return axios
    .post<SkillDto[]>(`${API_ENDPOINT}/v1/prospect/skills`, data, {
      success: {message: 'Навык проспекта успешно сохранены'},
      failed: {message: 'Ошибка при сохранении навыка проспекта'},
    })
    .then((res) => res.data);
};

export const createProspectReport = async (data: CreateReportDto): Promise<ReportDto> => {
  return axios
    .post<ReportDto>(`${API_ENDPOINT}/v1/prospect/report`, data, {
      success: {message: 'Отчет проспекта успешно сохранены'},
      failed: {message: 'Ошибка при сохранении отчета проспекта'},
    })
    .then((res) => res.data);
};

export interface GetProspectReportQuery {
  startDate?: string;
  endDate?: string;
  season?: string;
}

export const getProspectReport = async (playerId: string, query: GetProspectReportQuery): Promise<ReportDto[]> => {
  return axios
    .get<ReportDto[]>(`${API_ENDPOINT}/v1/prospect/report/${playerId}`, {params: query})
    .then((res) => res.data);
};

export const getCurrentProspectReport = async (playerId: string): Promise<ReportDto> => {
  return axios.get<ReportDto>(`${API_ENDPOINT}/v1/prospect/report/${playerId}/latest`).then((res) => res.data);
};

export const createProspectGoals = async (data: any[]): Promise<any> => {
  return axios
    .post<any>(`${API_ENDPOINT}/v1/prospect/goals`, data, {
      success: {message: 'Цели проспекта успешно сохранены'},
      failed: {message: 'Ошибка при сохранении целей проспекта'},
    })
    .then((res) => res.data);
};

export const getProspectGoals = async (
  prospectId: string,
  query: {
    date?: string;
    finalDate?: string;
    isCompleted?: boolean;
    seasonId?: string;
  },
): Promise<any> => {
  return axios.get<any>(`${API_ENDPOINT}/v1/prospect/goal/${prospectId}`, {params: query}).then((res) => res.data);
};

export const updateProspectGoal = async (data: any): Promise<any> => {
  return axios
    .put<any>(`${API_ENDPOINT}/v1/prospect/goal`, data, {
      success: {message: 'Цели проспекта успешно обновлены'},
      failed: {message: 'Ошибка при обновление целей проспекта'},
    })
    .then((res) => res.data);
};

export const getProspectGames = async (query: {userId: string; season?: string}): Promise<any> => {
  return axios.get<any>(`${API_ENDPOINT}/v1/prospect/game/report`, {params: query}).then((res) => res.data);
};

export const createProspectGame = async (data: any): Promise<any> => {
  return axios
    .post<any>(`${API_ENDPOINT}/v1/prospect/game/report`, data, {
      success: {message: 'Игра проспекта успешно сохранена'},
      failed: {message: 'Ошибка при сохраненей игры проспекта'},
    })
    .then((res) => res.data);
};
