import {Filter, filtersViewModel, updateFilter} from '@components/A1Table/models/filters.view-model';
import {a1tagViewModel} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-model';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, TextField} from '@ui/MUI';
import React from 'react';

export const EventFilter = () => {
  useSignals();
  const {event} = filtersViewModel.filters;
  const options = a1tagViewModel.events.value.map((event) => ({
    label: event.name,
    value: event.id,
  }));

  return (
    <Box display="flex" flex={1} maxWidth={180}>
      <Autocomplete
        multiple={false}
        options={options}
        renderInput={(params) => <TextField {...params} label="Event" variant="outlined" />}
        value={event.value}
        width="100%"
        onChange={(_, value) => {
          updateFilter('event', value as Filter);
        }}
      />
    </Box>
  );
};
