import * as usersActions from '@actions/UsersActions';
import Field from '@common/FormField';
import PhoneField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/PhoneField';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Button, Stack} from '@ui/MUI';
import DrawerNew from '@ui/MUI/DrawerNew';
import React, {useCallback, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import styled from 'styled-components';

type UpdateUserInfoFormProps = {
  isLoading?: boolean;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (_state: boolean) => void;
  user: {
    id?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    userName?: string;
    email?: string;
    password?: string;
  };
};
export default function UpdateUserInfoForm({isEditModalOpen, setIsEditModalOpen, user}: UpdateUserInfoFormProps) {
  const dispatch = useStoreDispatch();
  const form = useForm();
  const {reset, handleSubmit} = form;

  useEffect(() => {
    user?.id && reset(user);
  }, [user?.id]);

  const onSave = useCallback(() => {
    handleSubmit((data) => {
      const d = {
        id: user.id,
        ...data,
      };
      dispatch(usersActions.updateUser(d)).then(() => setIsEditModalOpen(false));
    })();
  }, [user?.id]);

  return (
    <DrawerNew
      anchor="right"
      buttons={
        <ButtonsWrapper>
          <Button color="primary" variant="contained" onClick={onSave}>
            Cохранить
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            Отменить
          </Button>
        </ButtonsWrapper>
      }
      open={isEditModalOpen}
      title="Редактирование профиля"
      onClose={(state) => setIsEditModalOpen(state)}
    >
      <FormProvider {...form}>
        <Stack direction="column" gap={4} mt={5}>
          <Field label="Имя" name="firstName" type="input" variant="outlined" width="100%" />
          <Field label="Фамилия" name="lastName" type="input" variant="outlined" width="100%" />
          <PhoneField isEdit label="Телефон" name="phone" />
          <Field label="Логин" name="userName" type="input" variant="outlined" width="100%" />
          <Field label="Email" name="email" type="input" variant="outlined" width="100%" />
          <Field inputType="password" label="Пароль" name="password" type="input" variant="outlined" width="100%" />
        </Stack>
      </FormProvider>
    </DrawerNew>
  );
}

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
