import {black} from '@ui/MUI/colorsA1Tag';

export const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  mx: 'calc(-1 * var(--ModalDialog-padding))',
  px: 'var(--ModalDialog-padding)',
  minWidth: 454,
  width: '100%',
  maxHeight: '98%',
  bgcolor: `${black[10]}`,
  border: `1px solid ${black[25]}`,
  borderRadius: 2,
  boxShadow: '0px 0px 50px 0px #0000004D',
  overflow: 'scroll',
};

export const MODAL_HEAD_STYLE = {
  position: 'sticky',
  top: 0,
  p: 2,
  backgroundColor: black[8],
  borderBottom: `1px solid ${black[20]}`,
  zIndex: 2,
};
export const MODAL_BODY_STYLE = {
  py: 3,
  px: 4,
};
export const MODAL_FOOTER_STYLE = {
  position: 'sticky',
  bottom: 0,
  p: 3,
  borderTop: `1px solid ${black[20]}`,
  backgroundColor: black[8],
  zIndex: 2,
};
