import {FiltersWrapper} from '@components/apps/PhysicalTesting/components/CompareStages/CompareStages';
import stateHelper from '@helpers/StateHelper';
import {useStageOptions} from '@hooks/filters';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {FormControlLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {selectCompareInfo} from '@selectors/physicalTesting';
import {ESTIMATION_STAGES} from '@src/constants/config';
import {clearPhysicalCompareState, fetchCompareData} from '@src/redux/slices/PhysicalTestingCompare';
import {UserRoles} from '@src/types';
import {Autocomplete, Box} from '@ui/MUI';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router';

import {typeOfTestsOptions} from '../../constants';
import CompareStagesTable from './components/CompareStagesTable';

const Filters = ({filter, setFilter, isStudent, isPlayersHide, setHidePlayers}) => {
  const history = useHistory();

  const schools = useSchoolsV2({
    allSchoolsPermissionRoles: [],
  });
  const groups = useGroupsV2({schoolIds: filter?.school?.value ? [filter?.school?.value] : []});
  const stages = useStageOptions();

  const seasons = useSeasons(true, ({currentSeason}) => {
    setFilter({season: {value: currentSeason?.value, label: currentSeason?.label}});
  });

  return (
    <>
      {!isStudent && (
        <Box width={{xxs: '100%', sm: '23%'}}>
          <Autocomplete
            disabled={schools?.isDisabled || groups?.isLoading}
            isLoading={schools.isLoading}
            label="Школа"
            multiple={false}
            options={schools.options}
            value={filter?.school}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                school: v,
                group: '',
              }));
            }}
          />
        </Box>
      )}
      {!isStudent && (
        <Box width={{xxs: '100%', sm: '23%'}}>
          <Autocomplete
            disabled={groups?.isDisabled || schools?.isLoading}
            isLoading={groups.isLoading}
            label="Команда"
            multiple={false}
            options={groups.options}
            value={filter?.group}
            onChange={(__, v) => {
              setFilter((prevState) => ({
                ...prevState,
                group: v,
              }));
              history.push(`/physical_testing_new/compare_stages?groupId=${v?.value}`);
            }}
          />
        </Box>
      )}
      <Box width={{xxs: '100%', sm: '23%'}}>
        <Autocomplete
          label="Виды тестов"
          multiple={false}
          options={typeOfTestsOptions}
          value={filter?.typeOfTests}
          onChange={(__, v) => {
            setFilter((prevState) => ({
              ...prevState,
              typeOfTests: v,
            }));
          }}
        />
      </Box>
      <Box width={{xxs: '100%', sm: '23%'}}>
        <Autocomplete
          isLoading={seasons?.isLoading}
          label="Сезон"
          multiple={false}
          options={seasons?.options}
          value={filter?.season}
          onChange={(__, v) => {
            setFilter((prevState) => ({
              ...prevState,
              season: v,
            }));
          }}
        />
      </Box>
      <Box width={{xxs: '100%', sm: '23%'}}>
        <Autocomplete
          label="Этап"
          multiple={false}
          options={ESTIMATION_STAGES}
          value={filter?.stage}
          onChange={(__, v) => {
            setFilter((prevState) => ({
              ...prevState,
              stage: v,
            }));
          }}
        />
      </Box>
      <Box width={{xxs: '100%', sm: '23%'}}>
        <Autocomplete
          disabled={!stages?.options?.length}
          label="Этап сравнения"
          multiple={false}
          options={stages?.options}
          value={filter?.stageSeasons}
          onChange={(__, v) => {
            setFilter((prevState) => ({
              ...prevState,
              stageSeasons: v,
            }));
          }}
        />
      </Box>
      <Box sx={{alignSelf: 'flex-end'}}>
        <FormControlLabel
          checked={isPlayersHide}
          control={<Checkbox />}
          label="Скрыть игроков без данных"
          labelPlacement="end"
          value="isPlayersHidden"
          onChange={() => setHidePlayers((prev) => !prev)}
        />
      </Box>
    </>
  );
};

const CompareStages = (props) => {
  const dispatch = useStoreDispatch();

  const isStudent = useStore((state) => state.users.currentUser.userRole === UserRoles.STUDENT);
  const {items, isLoading} = useStore(selectCompareInfo);
  const state = useStore((state) => state);
  const [isPlayersHide, setHidePlayers] = useState(false);
  const [filters, setFilters] = useState({});
  const studentGroup = stateHelper.getGroups(state);

  const fetchData = () => {
    if (
      (filters?.group?.value || studentGroup) &&
      filters?.stageSeasons?.value &&
      filters?.stage?.value &&
      filters?.season?.value &&
      filters?.typeOfTests?.value
    ) {
      const season = filters?.stageSeasons?.value?.season;
      const seasonForComparsing = filters?.season?.value;
      const stage = filters.stageSeasons?.value?.stage;
      const stageForComparsing = filters?.stage?.value;

      dispatch(
        fetchCompareData({
          groupId: isStudent ? studentGroup[0].id : filters?.group?.value,
          season: season,
          stage: Number(stage),
          stageForComparsing: Number(stageForComparsing),
          seasonForComparsing: seasonForComparsing,
          type: filters?.typeOfTests?.value,
        }),
      );
    }
  };

  const isAllFiltersNotEmpty =
    !!filters?.group?.value &&
    !!filters?.school?.value &&
    !!filters?.season?.value &&
    !!filters?.stage?.value &&
    !!filters?.stageSeasons?.value &&
    !!filters?.typeOfTests?.value;

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalCompareState());
    };
  }, [filters]);

  const formattedTableData = useMemo(() => {
    return items?.filter((player) => {
      if (isPlayersHide) {
        return player?.vector !== null && player?.params?.length;
      }
      return true;
    });
  }, [isPlayersHide, items]);

  return (
    <>
      <>
        <FiltersWrapper gap={3} px={3} py={2}>
          <Filters
            filter={filters}
            isPlayersHide={isPlayersHide}
            isStudent={isStudent}
            setFilter={setFilters}
            setHidePlayers={setHidePlayers}
          />
        </FiltersWrapper>
        {!isAllFiltersNotEmpty ? (
          <TableEmptyBlock text="Выберите вид тестов, сезон и этап, чтобы посмотреть результаты тестирования." />
        ) : (
          <>
            {!isLoading && !items?.length ? (
              <TableEmptyBlock text="Нет данных." />
            ) : (
              <CompareStagesTable filters={filters} isLoading={isLoading} tableData={formattedTableData} />
            )}
          </>
        )}
      </>
      {props?.children && React.cloneElement(props.children, {setParentState: setFilters})}
    </>
  );
};

export default CompareStages;
