import {Wrapper} from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/components/PlayersTable/PlayersTable';
import {
  EllipsisTypography,
  ShiftTimeProgress,
  Table,
  TableWrapper,
  Td,
  Th,
  ToggleWrapper,
} from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/ShiftsTimeline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useMediaQuery} from '@mui/material';
import {IconButton, Tooltip, Typography} from '@ui/MUI';
import {grey, olive, primary} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';

PlayersTable.propTypes = {
  isMobile: PropTypes.bool,
  players: PropTypes.array,
  availableHeight: PropTypes.number,
};

function PlayersTable({isMobile, players, availableHeight, isSidebarToggled, setIsSidebarToggled}) {
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <Wrapper direction="row">
      <TableWrapper direction="row">
        <Table cellPadding="0" cellSpacing="0">
          <tr>
            <Th className="sticky" colSpan={isSidebarToggled && isMobile ? 2 : ''} width={!isSidebarToggled && 80}>
              <Stack direction="row" gap={1}>
                <EllipsisTypography variant="subtitle2">
                  Игроки
                  {isMobile && ', время'}
                  {isMobile && isSidebarToggled && ', смены'}
                </EllipsisTypography>
                <Tooltip
                  arrow={false}
                  placement="right"
                  sx={{width: 128, textAlign: 'center'}}
                  title="Желтый цвет - % переигранного времени игрока"
                >
                  <ErrorOutlineIcon fontSize="16px" sx={{fill: grey['42'], transform: 'rotate(180deg)'}} />
                </Tooltip>
              </Stack>
            </Th>
            {!isMobile && isSidebarToggled && (
              <>
                <Th className="sticky">
                  <TypographyDefault variant="subtitle2">Время</TypographyDefault>
                </Th>
                <Th className="sticky" width={62}>
                  <TypographyDefault variant="subtitle2">Смены</TypographyDefault>
                </Th>
              </>
            )}
          </tr>

          {players.map((player) => {
            return (
              <>
                <tr key={player.playerId}>
                  <Td colSpan={isSidebarToggled && isMobile ? 2 : ''}>
                    <Stack direction="row">
                      <Typography color={olive['100']} mr={0} variant="subtitle2">
                        {player.playerNumber}
                      </Typography>
                      <EllipsisTypography sx={{maxWidth: {xxs: 120, xs: 'none'}}} variant="subtitle2">
                        {isSidebarToggled &&
                          (!isMobile
                            ? `${player.lastName} ${player.firstName}`
                            : `${player.lastName} ${player.firstName?.[0]}.`)}
                      </EllipsisTypography>
                    </Stack>
                    <Tooltip
                      placement={isXsResolution ? 'top' : 'right'}
                      title={`Переигранная смена: ${player?.percentageReplayedTime}%`}
                    >
                      <ShiftTimeProgress time={player?.percentageReplayedTime} />
                    </Tooltip>
                  </Td>
                  {!isMobile && isSidebarToggled && (
                    <>
                      <Td>
                        <TypographyDefault variant="body2">{player.totalTime}</TypographyDefault>
                      </Td>
                      <Td>
                        <TypographyDefault variant="body2">{player.shiftsCount}</TypographyDefault>
                      </Td>
                    </>
                  )}
                </tr>

                <tr>
                  {isMobile && (
                    <Td py="0">
                      <TypographyDefault variant="body2">{player.totalTime}</TypographyDefault>
                    </Td>
                  )}
                  {isMobile && isSidebarToggled && (
                    <Td py="0">
                      <EllipsisTypography variant="body2">{player.shiftsCount}</EllipsisTypography>
                    </Td>
                  )}
                </tr>
              </>
            );
          })}
        </Table>
      </TableWrapper>

      <ToggleWrapper maxHeight={availableHeight}>
        <IconButton size="inherit" sx={{width: 20, height: 20}}>
          <ArrowForwardIosIcon
            fontSize="inherit"
            sx={{fill: primary.main, transform: isSidebarToggled ? 'rotate(180deg)' : ''}}
            onClick={() => setIsSidebarToggled(!isSidebarToggled)}
          />
        </IconButton>
      </ToggleWrapper>
    </Wrapper>
  );
}

export default PlayersTable;
