import {FACELESS_AVATAR} from '@components/students/constants';
import {getRoleLabel} from '@helpers/roles';
import {HAND_MAP, HOCKEY_ROLES, rolesWithSubrolesMap} from '@src/constants';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import {Typography} from '@ui/MUI';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import moment from 'moment';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import UsersAPI from '../../../../api/UsersAPI';
import LocalPreloader from '../../../preloader/LocalPreloader';
import TableDefault from '../../../ui/TableDefault';

const UserView = (props) => {
  const [isAlert, setIsAlert] = useState(false);
  const history = useHistory();
  const {user, isLoading, setIsEdit, isEditable} = props;
  const role = user?.userRole;
  const isStudent = role === 'student';
  const isDoctor = role === 'doctor';
  const isFranchisee = role === 'franchisee';
  const isTeacher = role === 'teacher';
  const isPsycho = role === 'psycho';
  const isSchoolAdmin = role === 'schools_admin';
  const isParent = role === 'parent';

  const onDelete = async () => {
    try {
      await UsersAPI.deleteUser(user.id);
      ReactComponentNotification(NOTIFICATION_TYPES['success'], `Пользователь удалён`);
      history.push('/users');
    } catch (error) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}`);
    }
  };

  return (
    <>
      <DialogDefault
        actionText="Удалить"
        closeText="Отменить"
        content={<Typography variant="body2">Вы уверены что хотите удалить пользователя?</Typography>}
        open={isAlert}
        title="Удаление пользователя"
        onActionButtonClick={() => {
          onDelete();
        }}
        onCloseDefault={() => setIsAlert(false)}
      />
      <TableDefault
        closeButtonText="Удалить"
        content={
          <Container isLoading={isLoading}>
            <LocalPreloader visible={isLoading} />
            <Avatar img={user?.avatar ? convertImgTo200(user.avatar) : FACELESS_AVATAR} />
            <Items>
              <Item>
                <ItemLabel>Логин</ItemLabel>
                <ItemDescription>{user?.email || '-'}</ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Роль</ItemLabel>
                <ItemDescription>{getRoleLabel(user?.userRole)}</ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Имя</ItemLabel>
                <ItemDescription>{user?.firstName}</ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Фамилия</ItemLabel>
                <ItemDescription>{user?.lastName}</ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Отчество</ItemLabel>
                <ItemDescription>{user?.middleName || '-'}</ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Дата рождения</ItemLabel>
                <ItemDescription>
                  {user?.birthdayTimestamp ? moment(user.birthdayTimestamp).format('DD.MM.YYYY') : '-'}
                </ItemDescription>
              </Item>
              <Item>
                <ItemLabel>Телефон</ItemLabel>
                <ItemDescription>{user?.phone || '-'}</ItemDescription>
              </Item>
              {(isPsycho || isDoctor || isTeacher || isSchoolAdmin || isStudent || isFranchisee) && (
                <Item>
                  <ItemLabel>Школа</ItemLabel>
                  <ItemDescription>
                    {Array.isArray(user?.schools) && user.schools.length
                      ? user.schools.map((school) => school.name).join(', ')
                      : user?.school?.name || '-'}
                  </ItemDescription>
                </Item>
              )}
              {(isTeacher || isStudent) && (
                <Item>
                  <ItemLabel>Команда</ItemLabel>
                  <ItemDescription>
                    {user?.groups ? user?.groups?.map((group) => group.name)?.join(', ') : user?.group?.name || '-'}
                  </ItemDescription>
                </Item>
              )}
              {isParent && (
                <Item>
                  <ItemLabel>Дети</ItemLabel>
                  <ItemDescription>
                    {user?.children?.map((child) => child.firstName + ' ' + child.lastName)?.join(', ')}
                  </ItemDescription>
                </Item>
              )}
              {(isTeacher || isStudent) && (
                <Item>
                  <ItemLabel>Тип</ItemLabel>
                  <ItemDescription>
                    {rolesWithSubrolesMap?.[role]?.subRole?.[user?.teacherRole] ||
                      rolesWithSubrolesMap?.[role]?.subRole?.[user?.subRole] ||
                      '-'}
                  </ItemDescription>
                </Item>
              )}
              {isStudent && (
                <>
                  <Item>
                    <ItemLabel>Амплуа</ItemLabel>
                    <ItemDescription>{HOCKEY_ROLES[user?.hockeyRole] || '-'}</ItemDescription>
                  </Item>
                  <Item>
                    <ItemLabel>Хват</ItemLabel>
                    <ItemDescription>{HAND_MAP[user?.hand] || '-'}</ItemDescription>
                  </Item>
                  <Item>
                    <ItemLabel>Номер игрока</ItemLabel>
                    <ItemDescription>{user?.playerNumber || '-'}</ItemDescription>
                  </Item>
                </>
              )}
            </Items>
          </Container>
        }
        saveButtonText="Редактировать"
        title="Информация о пользователе"
        withOutButtons={!isEditable}
        onClose={() => setIsAlert(true)}
        onSave={() => setIsEdit(true)}
      />
    </>
  );
};

const Item = styled.div`
  padding: 10px 5px;
  max-width: 19%;
  min-width: 19%;
  @media (max-width: 1300px) {
    max-width: 30%;
    min-width: 30%;
  }

  @media (max-width: 650px) {
    max-width: 40%;
    min-width: 40%;
  }

  @media (max-width: 500px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  height: ${(props) => (props.isLoading ? '55vh' : 'auto')};
  overflow: ${(props) => (props.isLoading ? 'hidden' : 'visible')};
  padding-left: 34px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: 'Proxima Nova', sans-serif;
`;

const Avatar = styled.div`
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
  background-color: #e7e7e7;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ItemLabel = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  color: rgb(116, 116, 116);
`;

const ItemDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: rgb(44, 44, 44);
  white-space: pre-line;
`;

export default UserView;
