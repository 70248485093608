import {getPlayerStatTournaments} from '@api/mkcStatisticsAPI';
import ReactTableComponent from '@common/ReactTableComponent';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import useStore from '@hooks/useStore';
import BarChartIcon from '@mui/icons-material/BarChart';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {HockeyRoles} from '@src/types/student';
import {Box, Button, Stack, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

function Tournaments() {
  const {id} = useParams();
  const [tournamentsData, setTournamentsData] = useState([]);

  useEffect(() => {
    if (id) {
      getPlayerStatTournaments(id)
        .then((res) => {
          setTournamentsData(res?.data || []);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setTournamentsData([]);
          } else {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
          }
        });
    }
  }, [id]);

  const {student} = useStore((state) => state.users);

  const isGoaltender = student?.hockeyRole === HockeyRoles.goaltender;

  const fieldPlayersColumns = [
    {
      accessorKey: 'season',
      header: () => <Typography variant="caption1">Сезон</Typography>,
      headerStyle: {pl: '24px !important', width: 90, textAlign: 'left'},
      cell: ({getValue, row}) => {
        const seasons = tournamentsData?.seasons?.map((item) => item?.season);
        if (!seasons?.includes(row?.original?.season)) {
          return <Typography variant="caption1">{getValue()}</Typography>;
        } else {
          return (
            <Typography
              sx={{
                py: 3,
                px: 4,
                my: -3,
                mx: -4,
                display: 'block',
                backgroundColor: '#DBDBDB',
                minHeight: 38,
              }}
              variant="overline1"
            >
              {getValue()}
            </Typography>
          );
        }
      },
      cellStyle: {pl: '24px !important', width: 90, textAlign: 'left'},
    },
    {
      accessorKey: 'tournament',
      header: () => (
        <Typography sx={{width: 220}} variant="caption1">
          Турнир
        </Typography>
      ),
      headerStyle: {textAlign: 'left'},
      cell: ({getValue, row}) => {
        const seasons = tournamentsData?.seasons?.map((item) => item?.season);
        if (!seasons?.includes(row?.original?.season)) {
          return (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography variant="caption1">{getValue()}</Typography>
              <Tooltip arrow title="Статистика за турнир">
                <StyledButton
                  color="primary"
                  component={Link}
                  disabled={!row?.original?.teamMKCId && row?.original?.teamMKCId !== 0}
                  size="small"
                  sx={{opacity: row?.original?.teamMKCId >= 0 ? 1 : 0}}
                  target="_blank"
                  to={`/statistics/${row?.original?.teamMKCId}?isFinal=${row?.original?.isFinal}&season=${row?.original?.seasonForLink}&groupName=${row?.original?.groupName}`}
                  variant="outlined"
                >
                  <BarChartIcon fontSize="inherit" />
                </StyledButton>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{py: 3, px: 4, my: -3, mx: -4, display: 'block', backgroundColor: '#DBDBDB', minHeight: 38}}
              variant="overline1"
            >
              {getValue()}
            </Typography>
          );
        }
      },
      cellStyle: {width: 245, textAlign: 'left'},
    },
    {
      accessorKey: 'teamName',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Команда
        </Typography>
      ),
      headerStyle: {width: 150, textAlign: 'center'},
      cellStyle: {width: 150, textAlign: 'center'},
    },
    {
      accessorKey: 'score',
      header: () => <Typography variant="caption1">Очки</Typography>,
      headerStyle: {width: 60, textAlign: 'center'},
      cellStyle: {width: 60, textAlign: 'center'},
    },
    {
      accessorKey: 'goals',
      header: () => <Typography variant="caption1">Голы</Typography>,
      headerStyle: {width: 70, textAlign: 'center'},
      cellStyle: {width: 70, textAlign: 'center'},
    },
    {
      accessorKey: 'assists',
      header: () => <Typography variant="caption1">Передачи</Typography>,
      headerStyle: {width: 70, maxWidth: 70, textAlign: 'center'},
      cellStyle: {width: 70, maxWidth: 70, textAlign: 'center'},
    },
    {
      accessorKey: 'plusMinus',
      header: () => <Typography variant="caption1">+/-</Typography>,
      headerStyle: {width: 60, textAlign: 'center'},
      cellStyle: {width: 60, textAlign: 'center'},
    },
    {
      accessorKey: 'toi',
      header: () => (
        <Typography sx={{maxWidth: 70}} variant="caption1">
          Время
        </Typography>
      ),
      headerStyle: {width: 70, textAlign: 'center'},
      cellStyle: {width: 70, textAlign: 'center'},
    },
    {
      accessorKey: 'shotsOnGoal',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} variant="caption1">
          Броски в створ
        </Typography>
      ),
      headerStyle: {
        width: 110,
        maxWidth: 110,
        textAlign: 'center',
      },
      cellStyle: {width: 110, maxWidth: 110, textAlign: 'center'},
    },
    {
      accessorKey: 'blockedShots',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} variant="caption1">
          Блокшоты
        </Typography>
      ),
      headerStyle: {width: 80, maxWidth: 80, textAlign: 'center'},
      cellStyle: {width: 80, maxWidth: 80, textAlign: 'center'},
    },
    {
      accessorKey: 'powerStruggle',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} variant="caption1">
          Единоборства %
        </Typography>
      ),
      headerStyle: {width: 120, maxWidth: 120, textAlign: 'center'},
      cellStyle: {width: 120, maxWidth: 120, textAlign: 'center'},
    },
    {
      accessorKey: 'corsiForPercent',
      header: () => <Typography variant="caption1">Corsi</Typography>,
      headerStyle: {width: 75, maxWidth: 75, textAlign: 'center'},
      cellStyle: {width: 75, maxWidth: 75, textAlign: 'center'},
    },
  ].map((column) => {
    return {
      ...column,
      headerStyle: {...column?.headerStyle, px: {xl: 2, lg: 1}, boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset'},
      cellStyle: {
        ...column?.cellStyle,
        py: 1,
        px: {xl: 2, lg: 0},
        boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
      },
      cell: column?.cell
        ? column?.cell
        : ({getValue, row}) => {
            const seasons = tournamentsData?.seasons?.map((item) => item.season);
            if (seasons?.includes(row.original.season)) {
              return (
                <Typography
                  sx={{py: 3, px: 4, my: -3, mx: -4, display: 'block', backgroundColor: '#DBDBDB', minHeight: 38}}
                  variant="overline1"
                >
                  {getValue()}
                </Typography>
              );
            } else {
              return (
                <Typography sx={{color: grey[68]}} variant="caption1">
                  {getValue()}
                </Typography>
              );
            }
          },
    };
  });

  const goaltendersColumns = [
    {
      accessorKey: 'season',
      id: 'season',
      header: () => <Typography variant="caption1">Сезон</Typography>,
      headerStyle: {pl: '24px !important', width: 90, textAlign: 'left'},
      cell: ({getValue, row}) => {
        const seasons = tournamentsData?.seasons?.map((item) => item?.season);
        if (!seasons?.includes(row?.original?.season)) {
          return <Typography variant="caption1">{getValue()}</Typography>;
        } else {
          return (
            <Typography
              sx={{
                py: 3,
                px: 4,
                my: -3,
                mx: -4,
                display: 'block',
                backgroundColor: '#DBDBDB',
                minHeight: 38,
              }}
              variant="overline1"
            >
              {getValue()}
            </Typography>
          );
        }
      },
      cellStyle: {pl: '24px !important', width: 90, textAlign: 'left'},
    },
    {
      accessorKey: 'tournament',
      id: 'tournament',
      header: () => (
        <Typography sx={{width: 220}} variant="caption1">
          Турнир
        </Typography>
      ),
      headerStyle: {textAlign: 'left'},
      cell: ({getValue, row}) => {
        const seasons = tournamentsData?.seasons?.map((item) => item?.season);
        if (!seasons?.includes(row?.original?.season)) {
          return (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography variant="caption1">{getValue()}</Typography>
              <Tooltip arrow title="Статистика за турнир">
                <StyledButton
                  color="primary"
                  component={Link}
                  disabled={!row?.original?.teamMKCId && row?.original?.teamMKCId !== 0}
                  size="small"
                  sx={{opacity: row?.original?.teamMKCId >= 0 ? 1 : 0}}
                  target="_blank"
                  to={`/statistics/${row?.original?.teamMKCId}?isFinal=${row?.original?.isFinal}&season=${row?.original?.seasonForLink}&groupName=${row?.original?.groupName}`}
                  variant="outlined"
                >
                  <BarChartIcon fontSize="inherit" />
                </StyledButton>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{py: 3, px: 4, my: -3, mx: -4, display: 'block', backgroundColor: '#DBDBDB', minHeight: 38}}
              variant="overline1"
            >
              {getValue()}
            </Typography>
          );
        }
      },
      cellStyle: {width: 245, textAlign: 'left'},
    },
    {
      id: 'teamName',
      accessorKey: 'teamName',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Команда
        </Typography>
      ),
      headerStyle: {width: 150, textAlign: 'center'},
      cellStyle: {width: 150, textAlign: 'center'},
    },
    {
      id: 'games',
      accessorKey: 'games',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Игры
        </Typography>
      ),
      headerStyle: {width: 55, textAlign: 'center'},
      cellStyle: {width: 55, textAlign: 'center'},
    },
    {
      id: 'wins',
      accessorKey: 'wins',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Победы
        </Typography>
      ),
      headerStyle: {width: 70, textAlign: 'center'},
      cellStyle: {width: 70, textAlign: 'center'},
    },
    {
      id: 'goals',
      accessorKey: 'goals',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Голы
        </Typography>
      ),
      headerStyle: {width: 55, textAlign: 'center'},
      cellStyle: {width: 55, textAlign: 'center'},
    },
    {
      id: 'shotsOnGoal',
      accessorKey: 'shotsOnGoal',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          Броски
        </Typography>
      ),
      headerStyle: {width: 65, textAlign: 'center'},
      cellStyle: {width: 65, textAlign: 'center'},
    },
    {
      id: 'saves',
      accessorKey: 'saves',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          % Сейвов
        </Typography>
      ),
      headerStyle: {width: 140, textAlign: 'center'},
      cellStyle: {width: 140, textAlign: 'center'},
    },
    {
      id: 'dangerousSaves',
      accessorKey: 'dangerousSaves',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          % Сейвов из опасной зоны
        </Typography>
      ),
      headerStyle: {width: 170, textAlign: 'center'},
      cellStyle: {width: 170, textAlign: 'center'},
    },
    {
      id: 'nonDangerousSaves',
      accessorKey: 'nonDangerousSaves',
      header: () => (
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}} variant="caption1">
          % Сейвов из не опасной зоны
        </Typography>
      ),
      headerStyle: {width: 190, textAlign: 'center'},
      cellStyle: {width: 190, textAlign: 'center'},
    },
  ].map((column) => {
    return {
      ...column,
      headerStyle: {...column?.headerStyle, px: {xl: 2, lg: 1}, boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset'},
      cellStyle: {
        ...column?.cellStyle,
        py: 1,
        px: {xl: 2, lg: 0},
        boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
      },
      cell: column?.cell
        ? column?.cell
        : ({getValue, row}) => {
            const seasons = tournamentsData?.seasons?.map((item) => item.season);
            if (seasons?.includes(row.original.season)) {
              return (
                <Typography
                  sx={{py: 3, px: 4, my: -3, mx: -4, display: 'block', backgroundColor: '#DBDBDB', minHeight: 38}}
                  variant="overline1"
                >
                  {getValue()}
                </Typography>
              );
            } else {
              return (
                <Typography sx={{color: grey[68]}} variant="caption1">
                  {getValue()}
                </Typography>
              );
            }
          },
    };
  });

  const formattedTournamentsDataForFieldsPlayers =
    tournamentsData?.seasons
      ?.map((item) => {
        const formattedTournaments =
          item?.tournaments?.map(({name, corsiForPercent, ...tournamentFields}) => {
            return {
              seasonForLink: item?.season?.replace('/', '-'),
              tournament: name,
              corsiForPercent: `${corsiForPercent || 0}%`,
              ...tournamentFields,
            };
          }) || [];
        return [
          {...item?.total, season: item?.season, tournament: '', corsiForPercent: '', teamName: ''},
          ...formattedTournaments,
        ];
      })
      ?.flat() || [];

  const formattedTournamentsDataForGoaltenders =
    tournamentsData?.seasons
      ?.map((item) => {
        const formattedTournaments =
          item?.tournaments?.map(({name, corsiForPercent, ...tournamentFields}) => {
            return {
              seasonForLink: item?.season?.replace('/', '-'),
              tournament: name,
              ...tournamentFields,
            };
          }) || [];
        return [{...item?.total, season: item?.season, tournament: '', teamName: ''}, ...formattedTournaments];
      })
      ?.flat() || [];

  return (
    <Wrapper p="0">
      <Box
        p={{xxs: 3, xs: 4}}
        pb={0}
        sx={{
          '.TableWrapper': {
            mx: -4,
            width: 'calc(100% + 48px)',
            '.ScrollbarsCustom-Scroller': {
              '@media screen and (max-width:1440px)': {
                mr: '-4px !important',
              },
            },
          },
        }}
      >
        <Box
          sx={{
            mx: {xxs: -3, xs: -4},
            px: {xxs: 3, xs: 4},
            pb: 3,
            boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
          }}
        >
          <Typography variant="h4">Сводная таблица турниров</Typography>
        </Box>
        {tournamentsData?.seasons?.length ? (
          <ReactTableComponent
            bottomRow={([Row, Cell]) => {
              return (
                <Row sx={{backgroundColor: '#DBDBDB'}}>
                  <Cell sx={{pl: '24px'}}>
                    <Typography variant="overline1">Всего</Typography>
                  </Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                  {Object.keys(tournamentsData?.total).map((key) => {
                    return (
                      <Cell key={key} sx={{textAlign: 'center'}}>
                        <Typography variant="overline1">{tournamentsData?.total?.[key]}</Typography>
                      </Cell>
                    );
                  })}
                </Row>
              );
            }}
            columns={!isGoaltender ? fieldPlayersColumns : goaltendersColumns}
            customScrollBarHeight={(!isGoaltender ? formattedTournamentsDataForFieldsPlayers : [])?.length * 38 + 76}
            data={!isGoaltender ? formattedTournamentsDataForFieldsPlayers : formattedTournamentsDataForGoaltenders}
          />
        ) : (
          <Stack alignItems="center" height={200} justifyContent="center" p={4}>
            <Typography variant="subtitle1">Турниров пока не было.</Typography>
          </Stack>
        )}
      </Box>
    </Wrapper>
  );
}

export default Tournaments;
const StyledButton = styled(Button)`
  padding: ${({theme}) => theme.spacing(0)};
  min-width: unset;
  cursor: pointer;
`;
