import {PaletteColor, PaletteColorOptions, Theme, useMediaQuery} from '@mui/material';
import MUIButton from '@mui/material/Button';
import {ButtonProps} from '@mui/material/Button/Button';
import React, {forwardRef, useMemo} from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    secondaryInverse: PaletteColor;
  }

  interface PaletteOptions {
    secondaryInverse?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    secondaryInverse: true;
  }
}

function ButtonDefault(props: {text?: React.ReactNode} & ButtonProps, ref: React.Ref<unknown> | undefined) {
  const isSmResolution = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const {
    variant = 'outlined',
    type = 'button',
    size = isSmResolution ? 'small' : 'medium',
    color = 'secondary',
    disableElevation = true,
    text = '',
  } = props;
  const buttonColor = useMemo(
    () => (color === 'secondary' && (variant === 'text' || variant === 'outlined') ? 'secondaryInverse' : color),
    [color, variant],
  );
  return (
    <MUIButton
      {...props}
      color={buttonColor}
      disableElevation={disableElevation}
      ref={ref}
      size={size}
      type={type}
      variant={variant}
    >
      {props?.children || text}
    </MUIButton>
  );
}

export default forwardRef(ButtonDefault);
