import {Stack} from '@ui/MUI';
import React from 'react';

import {GamesReportDrawer} from './components/games-report-drawer.component';
import {Filters} from './components/games-report-filters.component';
import {GamesReportTable} from './components/games-report-table.component';

export const GamesReportTab = () => {
  return (
    <>
      <GamesReportDrawer />
      <Stack spacing={1}>
        <Filters />
        <GamesReportTable />
      </Stack>
    </>
  );
};
