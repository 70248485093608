import 'react-image-crop/dist/ReactCrop.css';

import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactCrop from 'react-image-crop';

export default function ImageCropPanel(props) {
  let {onChange = () => {}} = props;
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 200,
    aspect: 1,
    x: 40,
    y: 20,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    canvas.toBlob(
      (blob) => {
        const fd = new FormData();
        fd.append('file', blob);
        onChange(fd);
      },
      'image/jpeg',
      1,
    );
  }, [completedCrop]);

  return (
    <div className="App">
      <div>
        <input accept="image/*" className={'crop-input'} type="file" onChange={onSelectFile} />
      </div>
      <ReactCrop
        locked
        crop={crop}
        src={upImg}
        style={{maxWidth: '400px'}}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        onImageLoaded={onLoad}
      />
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: 0,
            height: 0,
          }}
        />
      </div>
    </div>
  );
}
