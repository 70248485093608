import React from 'react';
import ReactPlayer from 'react-player';

import {VkontakteInterface} from './interfaces/vkontakte.interface.component';
import {isVkVideoUrl} from './video-player.helpers';
import {VideoPlayerProps} from './video-player.types';

export const VideoPlayer = (props: VideoPlayerProps) => {
  const isVk = isVkVideoUrl(props?.url);
  return isVk ? (
    <VkontakteInterface
      config={props?.config?.vkontakte}
      height={props.height}
      style={props.style}
      url={props.url as string}
      width={props.width}
    />
  ) : (
    <ReactPlayer {...props} />
  );
};
