import {signal} from '@preact/signals-react';

const tabs = [
  {label: 'Актуальный', id: 'active'},
  {label: 'Архив', id: 'archive'},
];

export interface Tab {
  label: string;
  id: string;
}
export const tabViewModal = {
  _currentTab: signal<Tab>(tabs[0]),

  updateTab(tab: Tab) {
    this._currentTab.value = tab;
  },

  get currentTab() {
    return this._currentTab.value;
  },

  get tabIndex() {
    return tabs.findIndex((tab) => tab.id === this.currentTab.id);
  },

  get tabs() {
    return tabs;
  },

  get isArchive() {
    return this.currentTab.id === 'archive';
  },
};
