import {SkeletonProps} from '@mui/material/Skeleton/Skeleton';
import Skeleton from '@ui/MUI/Skeleton';
import React from 'react';

function ConditionSkeleton({
  children,
  isLoading,
  ...props
}: {children: React.ReactNode; isLoading: boolean} & SkeletonProps) {
  return isLoading ? (
    <Skeleton variant="circular" {...props}>
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );
}
export default ConditionSkeleton;
