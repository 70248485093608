// https://vk.com/video-22822305_456241864 - ok
export const isVkVideoUrl = (url: unknown) => {
  if (typeof url !== 'string') {
    return false;
  }

  return url.includes('vk.com/video');
};
// https://vk.com/video-22822305_456241864 => -22822305
export const grabOId = (url: string) => {
  const match = url.match(/video(-?\d+)_(\d+)/);
  if (match) {
    return match[1];
  }
  return null;
};

// https://vk.com/video-22822305_456241864 => 456241864
export const grabAId = (url: string) => {
  const match = url.match(/video(-?\d+)_(\d+)/);
  if (match) {
    return match[2];
  }
  return null;
};

// 120px => 120
export const grabNumber = (value?: string | number) => {
  if (!value) return null;

  if (typeof value === 'number') {
    return value;
  }

  return parseInt(value.replace('px', ''), 10);
};
