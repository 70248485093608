import ContentWrapper from '@common/ContentWrapper';
import Stage from '@components/apps/Tournaments/components/TournamentView/components/Stage';
import {CurrentTournament, FormData} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {selectCurrentTournament} from '@selectors/tournaments';
import {consolationMatchesStages} from '@src/constants/tournament';
import {ConsolationStages} from '@src/types/tournament';
import {Box, Typography} from '@ui/MUI';
import {entries} from 'lodash';
import uniqBy from 'lodash/uniqBy';
import React, {memo} from 'react';
import {FormProvider, useFormContext} from 'react-hook-form';

function ConsolationMatches({games}: {games: FormData}) {
  const form = useFormContext<FormData>();
  const {isLoading, teams} = useStore<CurrentTournament>(selectCurrentTournament);
  const matches = useDeepMemo<{name: string; label: string; maxLength: number}[]>(() => {
    const stagesObjEntries = entries(consolationMatchesStages);
    return stagesObjEntries?.map(([name, label], index) => {
      const result = {
        name,
        label,
        maxLength: 7,
      };

      if (name === ConsolationStages.fiveEight) {
        result.maxLength = 14;
      }

      return result;
    });
  }, []);

  return (
    <FormProvider {...form}>
      <ContentWrapper>
        <Typography variant="h3">Утешительные матчи</Typography>
        {matches.map(({name, label, maxLength}, index) => {
          return (
            <Box key={`consolationMatchesItem_${name}_${index}`} mt={4}>
              <Stage
                games={games}
                increment={1}
                index={index}
                isLoading={isLoading}
                maxLength={maxLength}
                minLength={1}
                name={name}
                teams={uniqBy(teams, 'id')}
                title={label}
              />
            </Box>
          );
        })}
      </ContentWrapper>
    </FormProvider>
  );
}

export default memo(ConsolationMatches);
