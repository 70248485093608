import {Stack, Typography} from '@ui/MUI';
import React, {useMemo} from 'react';
import styled from 'styled-components';

const RadioButton = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  max-width: 28px;
  max-height: 28px;
  border: ${(props) => {
    if (props.isOutlined && !props.isSelected) {
      return '1px solid #C2C2C2';
    }
  }};
  background: ${(props) => {
    if (props.isOutlined && !props.isSelected) {
      return;
    }
    return props.isSelected ? '#990011' : '#e3e3e3';
  }};
  border-radius: 5px;
  color: ${(props) => (props.isSelected ? '#FFFFFF' : '#2C2C2C')};
  cursor: pointer;
`;

const RadioRange = ({max, gap = 1, value, onChange, isOutlined}) => {
  const buttons = useMemo(() => {
    return [...Array(max).keys()].map((key) => key + 1);
  }, []);

  const handleClick = (number) => {
    onChange && onChange(number);
  };

  return (
    <Stack direction="row" gap={gap}>
      {buttons.map((number) => (
        <RadioButton
          isOutlined={isOutlined}
          isSelected={number === value}
          key={number}
          onClick={() => handleClick(number)}
        >
          <Typography variant="subtitle2">{number}</Typography>
        </RadioButton>
      ))}
    </Stack>
  );
};

export default RadioRange;
