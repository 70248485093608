import useStoreDispatch from '@hooks/useStoreDispatch';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

import {StyledButton} from '../../../../common/ScoutReport/CommonScoutReport';
import {PERMISSIONS} from '../../../../common/ScoutReport/constants';
import GroupReportList from '../../../../common/ScoutReport/GroupReportList';

function GroupScoutReport({groupId}) {
  const {groupReports} = useStoreDispatch(({scoutReport}) => ({
    groupReports: scoutReport.groupReports,
  }));

  const [isModalOpen, setIsModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  return (
    <Wrapper>
      {groupReports?.permissions?.[PERMISSIONS?.canCreate] && (
        <StyledButton
          color="primary"
          margin="0 0 24px 0"
          variant="contained"
          onClick={() => setIsModalOpen({...isModalOpen, right: !isModalOpen.right})}
        >
          <span className="plus" />
          Добавить отчет
        </StyledButton>
      )}
      <GroupReportList
        groupId={groupId}
        isOpen={isModalOpen}
        perView={8}
        reportType={'inner'}
        setIsOpen={(state) =>
          setIsModalOpen({
            ...isModalOpen,
            right: typeof state === 'boolean' ? state : !isModalOpen.right,
          })
        }
      />
    </Wrapper>
  );
}

GroupScoutReport.propTypes = {
  groupId: PropTypes.string,
};

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  margin-top: 18px;
  margin-bottom: 18px;
  border-radius: 6px;
`;

export default GroupScoutReport;
