import {Autocomplete} from '@ui/MUI';
import uniqBy from 'lodash/uniqBy';
import React, {useEffect, useRef, useState} from 'react';

import {NOTIFICATION_TYPES} from '../../../../constants/config';
import CommonHelper from '../../../../helpers/CommonHelper';
import StateHelper from '../../../../helpers/StateHelper';
import useStateCallback from '../../../../hooks/useStateCallBack';
import {ReactComponentNotification} from '../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {filterUsersConfig} from '../../constants';
import {SPORT_EVENTS} from '../fullcalendar/components/PrintTable/hooks/useFormatedLessons';
import {Column, Filter, Wrapper} from './style';

/*
 TODO: Перенести запросы schools, rooms в этот компонент
 обернуть onChange в useCallBack
 */

const CalendarFilter = (props) => {
  const {
    schools = [],
    rooms = [],
    onChange = () => {},
    currentUser = {
      userRole: 'guest',
    },
    isSchoolLoading = false,
    setStudentsIds = () => {},
  } = props;

  const ref = useRef(null);

  const [filterOptions, setFilterOptions] = useState({
    groupOption: {isLoading: false, groups: []},
    studentOption: {isLoading: false, students: []},
  });

  const [selectedFilters, setSelectedFilters] = useStateCallback({
    groupIds: [],
    studentIds: [],
    roomIds: [],
    teacherIds: [],
    schoolId: '',
  });

  useEffect(() => {
    setSelectedFilters({
      ...selectedFilters,
      groupIds: null,
    });
  }, [selectedFilters?.schoolId]);

  const selectFilter = (filter, action) => {
    const {name} = action;
    if (!name) {
      throw new Error(`У селекта отсутствует обязательное поле name`);
    }

    if (name === 'roomIds' && filter?.some((item) => item.value === 'sport')) {
      const prevFilters = filter;
      const filteredRooms = rooms
        .filter((room) => SPORT_EVENTS.includes(room.id))
        .map((room) => ({label: room.name, value: room.id}));

      setSelectedFilters(
        {
          ...selectedFilters,
          [name]: uniqBy([...prevFilters, ...filteredRooms], 'value'),
        },
        (state) => onChange(state),
      );
      return;
    }

    if (name === 'schoolId') {
      if (selectedFilters[name]?.value === filter?.value) {
        return;
      }
      if (filter) {
        setSelectedFilters({...selectedFilters, [name]: filter}, async (state) => {
          setFilterOptions({...filterOptions, groupOption: {isLoading: true, groups: []}});
          let groups = [];
          try {
            groups = await StateHelper.getGroupsBySchoolId(filter?.value);
          } catch (error) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке игроков: ${error}`);
          }
          setFilterOptions({...filterOptions, groupOption: {isLoading: false, groups}});
          onChange(state);
        });
      } else {
        setSelectedFilters({...selectedFilters, [name]: filter, groupIds: [], studentIds: []}, async (state) => {
          setFilterOptions({...filterOptions, groupOption: {isLoading: false, groups: []}});
          onChange(state);
        });
      }
      return;
    }

    setSelectedFilters({...selectedFilters, [name]: filter}, async (state) => {
      if (name === 'groupIds') {
        setFilterOptions({...filterOptions, studentOption: {isLoading: true, students: []}});
        let students = [];
        try {
          students = await Promise.all(filter.map((player) => StateHelper.getStudentsByTeamId(player.value)));
        } catch (error) {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке игроков: ${error}`);
        }
        setFilterOptions({
          ...filterOptions,
          studentOption: {isLoading: false, students: students.flat()},
        });
        setStudentsIds(
          students.flat().map((player) => ({
            value: player.id,
            label: `${player.student.lastName} ${player.student.firstName}`,
          })),
        );
      }

      onChange(state);
    });
  };

  const setStudentsByFilterGroups = async () => {
    const students = await Promise.all(
      selectedFilters?.groupIds?.map((groupId) => StateHelper.getStudentsByTeamId(groupId?.value)),
    );
    setFilterOptions((state) => ({
      ...state,
      studentOption: {isLoading: false, students: students.flat()},
    }));
  };

  useEffect(() => {
    if (selectedFilters?.groupIds?.length > 0) {
      setStudentsByFilterGroups();
    }
  }, [selectedFilters?.groupIds?.length]);

  return (
    <>
      {filterUsersConfig[currentUser.userRole]?.hasFilter && (
        <Wrapper filterHeight={ref?.current?.offsetHeight} ref={ref}>
          <Filter className={'Filter'}>
            <Column className={'Column'}>
              <Autocomplete
                label="Школа"
                multiple={false}
                name="schoolId"
                options={CommonHelper.getDropDownItemsByNamedList(schools)}
                value={selectedFilters?.schoolId}
                onChange={(_, newValue) => selectFilter(newValue, {name: 'schoolId'})}
              />
            </Column>
            <Column className={'Column'}>
              <Autocomplete
                isLoading={filterOptions.groupOption.isLoading}
                label="Команда"
                limitTags={1}
                name="groupIds"
                options={CommonHelper.getDropDownTeamsByNamedList(filterOptions.groupOption.groups)}
                value={selectedFilters.groupIds}
                onChange={(_, newValue) => selectFilter(newValue, {name: 'groupIds'})}
              />
            </Column>

            <Column className={'Column'}>
              <Autocomplete
                isLoading={filterOptions.studentOption.isLoading}
                label="Игрок"
                limitTags={1}
                name="studentIds"
                options={CommonHelper.getDropDownItemsByNamedList(filterOptions.studentOption.students)}
                value={selectedFilters.studentIds}
                onChange={(_, newValue) => selectFilter(newValue, {name: 'studentIds'})}
              />
            </Column>

            <Column className={'Column'}>
              <Autocomplete
                isLoading={isSchoolLoading}
                label="Помещение"
                limitTags={1}
                name="roomIds"
                options={[
                  ...CommonHelper.getDropDownItemsByNamedList(rooms),
                  {label: 'Спортивные залы', value: 'sport'},
                ]}
                value={selectedFilters.roomIds}
                onChange={(_, newValue) => selectFilter(newValue, {name: 'roomIds'})}
              />
            </Column>
          </Filter>
        </Wrapper>
      )}
    </>
  );
};

export default CalendarFilter;
