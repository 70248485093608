import {getTournamentsList} from '@api/TournamentsAPI';
import UsersAPI from '@api/UsersAPI';
import CommonHelper from '@helpers/CommonHelper';
import date from '@helpers/date';
import StateHelper from '@helpers/StateHelper';
import {useSeasons} from '@hooks/seasonsHooks';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {Autocomplete, Button, TextField, Typography} from '@ui/MUI';
import DateTimePicker from '@ui/MUI/DateTimePicker';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';

import {ADDITIONAL_EDUCATION_LESSONS, EVENT_TYPES, SCHOOL_LESSONS} from '../../../constants';
import {getNearest15Minutes} from '../../../helper';
import {
  BottomPanel,
  Buttons,
  Form,
  FormContent,
  FormContentItem,
  FormContentItemHalf,
  FormContentItemLong,
  FormContentItems,
} from '../style';

const LessonForm = ({
  allAmplua,
  rooms,
  lockerRooms,
  setLoading,
  onFormSubmit,
  defaultValues,
  isAdd,
  onCloseButtonClick,
  defaultPlayers,
  schools,
}) => {
  const seasons = useSeasons(true);

  const [tournaments, setTournaments] = useState([]);
  const tournamentsOptions =
    tournaments?.map((tournament) => {
      return {
        value: tournament?.id,
        label: tournament?.name,
      };
    }) || [];

  useEffect(() => {
    if (seasons?.currentSeason?.id) {
      getTournamentsList({season: seasons?.currentSeason?.id})?.then((res) => {
        setTournaments(res?.data || []);
      });
    }
  }, [seasons?.currentSeason?.id]);

  const schoolsOptions = schools?.map((school) => {
    return {
      value: school?.id,
      label: school?.name,
    };
  });

  const nearest15MinutesDate = getNearest15Minutes();
  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
    control,
    setValue,
    getValues,
    watch,
    ...rest
  } = useForm({
    defaultValues: defaultValues || {
      startTime: nearest15MinutesDate,
      endTime: new Date(nearest15MinutesDate.getTime() + 900000),
    },
  });

  const [playersState, setPlayersState] = useState({
    isLoading: false,
    options: isAdd ? [] : CommonHelper.getDropDownItemsByUsersWithCustomProps(defaultPlayers, 'hockeyRole'),
  });

  const [deleteModalState, setDeleteModalState] = useState(false);

  const watchEventType = watch('type');
  const watchLesson = watch('lesson');
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');
  const watchRoom = watch('room');
  const watchSchool = watch('school');
  const watchPlayerRole = watch('playerRole');
  const watchGroup = watch('team');

  const [groupsOptions, setGroupsOptions] = useState([]);

  useEffect(() => {
    if (watchSchool?.value) {
      StateHelper.getGroupsBySchoolId(watchSchool?.value)?.then((res) => {
        setGroupsOptions(
          res?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          }),
        );
      });
    }
  }, [watchSchool?.value]);

  const isGroupRequired = () => {
    let res = true;
    if (
      watchEventType?.value === 'training' ||
      watchEventType?.value === 'training_gpt' ||
      watchLesson?.value === 'academy_board'
    ) {
      res = false;
    }
    if (watchLesson?.value === 'academy_board') {
      res = false;
    }
    return res;
  };
  const renderLessonCondition =
    watchEventType?.value === 'school_lessons' ||
    watchEventType?.value === 'group_consultation' ||
    watchEventType?.value === 'personal_consultation';
  const renderAdditionalEducationLessonCondition = watchEventType?.value === 'additional_education';
  const renderNameCondition = watchEventType?.value === 'educational_event' || watchEventType?.value === 'master';
  const isGame = watchEventType?.value === 'away_game' || watchEventType?.value === 'game';

  const isOpponentTraining =
    watchEventType?.value === 'training_opponent' || watchEventType?.value === 'home_games_vhl';

  const renderSchoolTeamPlayersCondition =
    watchEventType?.value !== 'room_inaccessibility' &&
    watchEventType?.value !== 'training_vhl' &&
    watchEventType?.value !== 'training_tkg' &&
    watchEventType?.value !== 'training_cherry' &&
    watchEventType?.value !== 'training_opponent' &&
    watchEventType?.value !== 'rehabilitation_vhl' &&
    watchEventType?.value !== 'training_trainers_ha';

  const inputCommand = watchEventType?.value === 'training_tkg';

  const renderPlayersCondition =
    watchEventType?.value !== 'room_inaccessibility' &&
    watchEventType?.value !== 'riding_technique' &&
    watchEventType?.value !== 'rehabilitation_on_ice' &&
    watchEventType?.value !== 'training_vhl' &&
    watchEventType?.value !== 'training_tkg' &&
    watchEventType?.value !== 'training_cherry' &&
    watchEventType?.value !== 'training_opponent' &&
    watchEventType?.value !== 'rehabilitation_vhl' &&
    watchEventType?.value !== 'training_trainers_ha';

  const renderRoomCondition = watchEventType?.value !== 'away_game' && watchEventType?.value !== 'exit_from_academy';

  const renderDescriptionCondition = watchEventType?.value === 'room_inaccessibility';

  const renderLockerRoomCondition =
    (watchRoom?.value === 'QTLaWG5PUv' ||
      watchRoom?.value === 'QvUwFTZqfA' ||
      watchRoom?.value === '1VQxx2Dje1' ||
      watchRoom?.value === 'RnanlyjQOi' ||
      watchRoom?.value === 'PbTHwI0YJW' ||
      watchRoom?.value === '6CuPCIvPCd' ||
      watchRoom?.value === 'NIrZtOBThI') &&
    watchEventType?.value !== 'room_inaccessibility';

  const selectRef = React.useRef();

  const {newCancelToken} = useCancelToken({
    cancelDeps: [],
  });

  const setPlayersBySearch = useCallback(
    async ({hockeyRole, ...params} = {}, groups) => {
      setPlayersState((prevState) => ({...prevState, isLoading: true}));
      await UsersAPI.getStudentsV2('', {
        ...params,
        cancelToken: newCancelToken(),
      })
        .then(({result}) => {
          let players = result
            ?.filter((item) => {
              if (groups?.length && hockeyRole?.length) {
                return groups.includes(item?.group?.id) && hockeyRole.includes(item.hockeyRole);
              }
              if (groups?.length) return groups.includes(item?.group?.id);
              if (hockeyRole?.length) return hockeyRole.includes(item.hockeyRole);
              else return true;
            })
            .map((player) => ({
              ...player,
              value: player?.id,
              label: CommonHelper.getShortNameByUser(player),
            }));
          setPlayersState(() => {
            return {
              options: players,
              isLoading: false,
            };
          });
          setValue('players', players);
        })
        .catch(() => {
          setPlayersState((prev) => ({...prev, isLoading: false}));
        });
    },
    [newCancelToken],
  );

  const onSubmitReset = async (data) => {
    setLoading(true);
    try {
      await onFormSubmit(data);
      reset({
        startTime: nearest15MinutesDate,
        endTime: new Date(nearest15MinutesDate.getTime() + 900000),
      });
    } catch (error) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка: ${error}, попробуйте позже`);
    }
    setLoading(false);
  };

  useDeepEffect(() => {
    const params = {
      schoolId: watchSchool?.value,
      hockeyRole: watchPlayerRole?.map((item) => item?.value),
      active: true,
      limit: 100000,
    };

    const formatGroups = () => {
      let result = [];
      if (watchGroup?.value) {
        result = [watchGroup?.value];
      }
      if (watchGroup?.length > 0) {
        result = watchGroup?.map((item) => item.value);
      }
      return result;
    };
    if (watchSchool?.value) {
      setPlayersBySearch(params);
    }
    if (formatGroups()?.length > 0) {
      setPlayersBySearch(params, formatGroups());
    }
  }, [watchPlayerRole, watchSchool?.value, watchGroup]);
  return (
    <FormProvider
      control={control}
      getValues={getValues}
      handleSubmit={handleSubmit}
      register={register}
      reset={reset}
      setValue={setValue}
      watch={watch}
      {...rest}
    >
      <Form onSubmit={handleSubmit(onSubmitReset)}>
        <FormContent>
          <FormContentItems>
            <FormContentItemLong>
              <Controller
                control={control}
                name="type"
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    error={errors?.type}
                    helperText={errors?.type && 'Обязательное поле'}
                    label="Тип мероприятия"
                    multiple={false}
                    options={EVENT_TYPES}
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                  />
                )}
                rules={{required: true}}
              />
            </FormContentItemLong>

            {renderLessonCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="lesson"
                  render={({field: {value, onChange}}) => (
                    <Autocomplete
                      error={errors.lesson}
                      helperText={errors?.lesson && 'Обязательное поле'}
                      label="Предмет"
                      multiple={false}
                      options={SCHOOL_LESSONS}
                      value={value}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderAdditionalEducationLessonCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="lesson"
                  render={({field: {value, onChange}}) => (
                    <Autocomplete
                      error={errors.lesson}
                      helperText={errors?.lesson && 'Обязательное поле'}
                      label="Предмет"
                      multiple={false}
                      options={ADDITIONAL_EDUCATION_LESSONS}
                      value={value}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderNameCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="name"
                  render={({field: {value, onChange}}) => (
                    <TextField
                      InputProps={{sx: {minHeight: 38}}}
                      error={errors?.name}
                      helperText={errors?.name && 'Обязательное поле'}
                      label="Название мероприятия"
                      placeholder="Введите название мероприятия"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}
          </FormContentItems>

          <FormContentItems>
            <FormContentItemHalf>
              <Controller
                control={control}
                name="startTime"
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <DateTimePicker
                    DialogProps={{sx: {zIndex: 999999}}}
                    PopperProps={{sx: {zIndex: 999999}}}
                    disablePast={false}
                    error={error}
                    helperText={error?.message}
                    label="Время начала"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (!date(value).isValid()) {
                      return 'Введите корректную дату и время';
                    }
                    if (date(watchEndTime).isBefore(value)) {
                      return 'Время начала не может быть больше времени окончания';
                    }
                  },
                }}
              />
            </FormContentItemHalf>

            <FormContentItemHalf>
              <Controller
                control={control}
                name="endTime"
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <DateTimePicker
                    DialogProps={{sx: {zIndex: 999999}}}
                    PopperProps={{sx: {zIndex: 999999}}}
                    disablePast={false}
                    error={error}
                    helperText={error?.message}
                    label="Время окончания"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (!date(value).isValid()) {
                      return 'Введите корректную дату и время';
                    }
                    if (date(watchStartTime).isAfter(value)) {
                      return 'Время окончания не может быть меньше времени начала';
                    }
                  },
                }}
              />
            </FormContentItemHalf>
          </FormContentItems>

          <FormContentItems>
            {renderSchoolTeamPlayersCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="school"
                  render={({field: {onChange, value}}) => {
                    return (
                      <Autocomplete
                        error={errors.school}
                        helperText={errors?.school && 'Обязательное поле'}
                        label="Школа"
                        multiple={false}
                        options={schoolsOptions}
                        value={value}
                        onChange={(_, newValue) => {
                          setValue('team', null);
                          setValue('players', null);
                          setGroupsOptions([]);
                          onChange(newValue);
                        }}
                      />
                    );
                  }}
                  rules={{required: true}}
                />
              </FormContentItemHalf>
            )}

            {renderPlayersCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="team"
                  render={({field: {value, onChange}}) => {
                    return (
                      <Autocomplete
                        multiple
                        error={errors.team}
                        helperText={errors?.team && 'Обязательное поле'}
                        label="Команда"
                        options={groupsOptions}
                        value={value?.value ? [value] : value}
                        onChange={(_, newValue) => {
                          onChange(newValue);
                        }}
                      />
                    );
                  }}
                  rules={{required: isGroupRequired()}}
                />
              </FormContentItemHalf>
            )}
            {isGame && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="tournamentId"
                  render={({field: {value, onChange}}) => {
                    return (
                      <Autocomplete
                        error={errors.type}
                        label="Турнир"
                        multiple={false}
                        options={tournamentsOptions}
                        value={value}
                        onChange={(_, newValue) => {
                          onChange(newValue);
                        }}
                      />
                    );
                  }}
                  rules={{required: false}}
                />
              </FormContentItemHalf>
            )}
            {(isGame || isOpponentTraining) && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="rival"
                  render={({field: {value, onChange}}) => (
                    <TextField
                      InputProps={{sx: {minHeight: 38}}}
                      error={errors?.rival}
                      helperText={errors?.rival && 'Обязательное поле'}
                      label="Команда соперника"
                      placeholder="Введите название команды соперника"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}
            {inputCommand && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="rival"
                  render={({field: {value, onChange}}) => (
                    <TextField
                      InputProps={{sx: {minHeight: 38}}}
                      error={errors?.rival}
                      helperText={errors?.rival && 'Обязательное поле'}
                      label="Команда"
                      placeholder="Введите название команды"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {(watchEventType?.value === 'training' || watchEventType?.value === 'training_gpt') && (
              <FormContentItem>
                <Controller
                  control={control}
                  name="playerRole"
                  render={({field: {value, onChange, ref}}) => (
                    <Autocomplete
                      isLoading={!allAmplua}
                      label="Амплуа"
                      options={allAmplua}
                      ref={ref}
                      value={value}
                      onChange={(_, amplua) => {
                        onChange(amplua);
                      }}
                    />
                  )}
                  shouldUnregister={true}
                />
              </FormContentItem>
            )}
            {renderSchoolTeamPlayersCondition && (
              <FormContentItem>
                <Controller
                  control={control}
                  name="players"
                  render={({field: {value, onChange}}) => {
                    return (
                      <Autocomplete
                        error={errors.players}
                        helperText={errors.players && 'Обязательное поле'}
                        isLoading={playersState.isLoading}
                        label="Игроки"
                        options={playersState.options}
                        ref={selectRef}
                        value={value}
                        onChange={(_, players) => {
                          onChange(players);
                        }}
                      />
                    );
                  }}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItem>
            )}
          </FormContentItems>
          <FormContentItems>
            {renderRoomCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="room"
                  render={({field: {value, onChange}}) => (
                    <Autocomplete
                      error={errors.room}
                      helperText={errors?.room && 'Обязательное поле'}
                      label="Место проведения"
                      multiple={false}
                      options={CommonHelper.getDropDownItemsByNamedList(rooms)}
                      value={value}
                      onChange={(_, newValue) => {
                        if (watchEventType?.value === 'room_inaccessibility' && newValue?.value) {
                          const selectedRoomSchoolId = rooms?.find((room) => room?.id === newValue?.value)?.schoolId;
                          setValue(
                            'school',
                            schoolsOptions?.find((option) => option?.value === selectedRoomSchoolId),
                          );
                        }
                        onChange(newValue);
                      }}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderLockerRoomCondition && (
              <FormContentItemHalf>
                <Controller
                  control={control}
                  name="lockerRoom"
                  render={({field: {value, onChange}}) => (
                    <Autocomplete
                      error={errors.lockerRoom}
                      helperText={errors?.lockerRoom && 'Обязательное поле'}
                      label="Раздевалка"
                      options={CommonHelper.getDropDownItemsByNamedListCustomProps(lockerRooms, 'name', 'name')}
                      value={value}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}
          </FormContentItems>
          {renderDescriptionCondition && (
            <FormContentItems>
              <FormContentItem>
                <Controller
                  control={control}
                  name="description"
                  render={({field: {value, onChange}}) => (
                    <TextField
                      InputProps={{sx: {minHeight: 38}}}
                      error={errors?.description}
                      helperText={errors?.description && 'Введите корректное описание'}
                      label="Описание мероприятия"
                      placeholder="Например укажите причины недоступности площадки"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{required: false}}
                  shouldUnregister={true}
                />
              </FormContentItem>
            </FormContentItems>
          )}
        </FormContent>

        <FormContentItems>
          {isGame && (
            <FormContentItemHalf>
              <Controller
                control={control}
                name="streamingURL"
                render={({field: {value, onChange}}) => (
                  <TextField
                    InputProps={{sx: {minHeight: 38}}}
                    error={errors?.streamingURL}
                    helperText={errors?.streamingURL && 'Некорректная ссылка'}
                    label="Ссылка на игру"
                    placeholder="Введите ссылку на игру"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: false,
                  pattern: {
                    value:
                      /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-/])*)?/,
                  },
                }}
                shouldUnregister={true}
              />
            </FormContentItemHalf>
          )}
        </FormContentItems>

        <DialogDefault
          actionText="Да"
          closeText="Нет"
          content={<Typography variant="body1">Вы уверены что хотите удалить мероприятие?</Typography>}
          open={deleteModalState}
          sx={{zIndex: 9999}}
          title="Удаление"
          onActionButtonClick={() => {
            onCloseButtonClick();
            setDeleteModalState(false);
          }}
          onCloseDefault={() => setDeleteModalState(false)}
        />

        <BottomPanel className={'BottomPanel'}>
          <Buttons className={'Buttons'}>
            {isAdd ? (
              <Button color="primary" variant="outlined" onClick={() => onCloseButtonClick()}>
                Закрыть
              </Button>
            ) : (
              <Button onClick={() => setDeleteModalState(true)}>Удалить</Button>
            )}
            <Button color="primary" type="submit" variant="contained">
              {isAdd ? 'Добавить' : 'Сохранить'}
            </Button>
          </Buttons>
        </BottomPanel>
      </Form>
    </FormProvider>
  );
};

export default memo(LessonForm);
