import {A1tagStatistic} from '../viewmodels/a1tag.view-mode.types';

export const downloadContentAsCSVFile = (content: A1tagStatistic[]) => {
  const headers = [
    'action_name',
    'player_name',
    'player_id',
    'team_name',
    'opponent_name',
    'opponent_id',
    'opponent_team_name',
    'half',
    'second_clear',
    'zone_name',
    'opponent_zone_name',
    'pos_x',
    'pos_y',
    'opponent_pos_x',
    'opponent_pos_y',
    'plrs_team1',
    'plrs_team2',
    'goals_team1',
    'goals_team2',
    'action_type',
    'goalie_view',
    'one_timer',
    'from_rebound',
    'goal_spot_x',
    'goal_spot_y',
    'penalty_time',
    'penalty_add_time',
    'penalty_index_text',
    'jersey_player',
    'jersey_opponent',
    'video_time_start',
    'video_time_end',
  ];
  const csvRows = [headers.join(';'), ...content.map((row) => headers.map((header) => row[header]).join(';'))].join(
    '\n',
  );

  // Add BOM for UTF-8 encoding
  const bom = '\uFEFF';
  const csvContent = bom + csvRows;

  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  const randomNumber = Math.floor(Math.random() * 1000000);
  a.download = `a1tag-statistics-${randomNumber}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};
