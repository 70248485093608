import DefensemanIcon from '@common/Icons/DefensemanIcon';
import GoaltenderIcon from '@common/Icons/GoaltenderIcon';
import WingerIcon from '@common/Icons/WingerIcon';
import {longDash} from '@src/constants';
import {pluralize} from '@src/helpers';
import Avatar from '@ui/MUI/Avatar';
import moment from 'moment';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import ColoredElement from '../../../ColoredElement/ColoredElement';
import Grid from '../../../Grid/Grid';
import {HAND, HOCKEY_ROLES} from '../../constants';

const hockeyRoleIcon = {
  defenseman: <DefensemanIcon sx={{fontSize: 45}} />,
  winger: <WingerIcon sx={{fontSize: 32}} />,
  goaltender: <GoaltenderIcon sx={{fontSize: 45}} />,
};

const ProfileBlock = ({isAmateur, ...props}) => {
  const {
    firstName,
    lastName,
    hockeyRole,
    height,
    weight,
    team,
    birthdayTimestamp,
    hand,
    playerNumber,
    dateJoinedAcademy,
    previousCommands,
    avatar,
  } = props.student;
  const roleIcon = useMemo(() => {
    return hockeyRoleIcon[hockeyRole] || null;
  }, [hockeyRole]);

  const birthDay = useMemo(() => {
    if (birthdayTimestamp) {
      const age = moment().diff(birthdayTimestamp, 'years', false);
      const fullDate = moment(birthdayTimestamp).format('DD.MM.YYYY');
      return {age, fullDate};
    }
    return {age: '', fullDate: ''};
  }, [birthdayTimestamp]);

  const inGameSince = useMemo(() => {
    let year = null;
    if (previousCommands?.length) {
      year = previousCommands[0].startYear;
    } else {
      year = moment(dateJoinedAcademy).format('YYYY');
    }
    return year;
  }, [dateJoinedAcademy, previousCommands]);

  return (
    <Container>
      <PlayerNumber>{playerNumber}</PlayerNumber>
      <Grid align="center" zIndex={1}>
        <Avatar src={avatar} sx={{mr: {xxs: 2, sm: 5}}} width={{xxs: 60, sm: 90}} />
        <Grid direction="column" marginTop="2px">
          <PlayerName>
            {lastName} {firstName}
          </PlayerName>
          <Grid>
            <Grid align="center">
              <Grid marginRight="10px">{roleIcon}</Grid>
              <PlayerRole>
                {HOCKEY_ROLES[hockeyRole] || ''}, №{playerNumber}
              </PlayerRole>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid direction="column" justify="center" marginLeft="15px" zIndex={1}>
        {!isAmateur && (
          <>
            <Grid>
              <Label>Рост:</Label>
              <Value>{height ? height + ' см' : longDash}</Value>
            </Grid>
            <Grid>
              <Label>Вес:</Label>
              <Value>{weight ? weight + ' кг' : longDash}</Value>
            </Grid>
          </>
        )}
        <Grid>
          <Label>В игре:</Label>
          <Value>{inGameSince ? `с ${inGameSince} года` : '-'}</Value>
        </Grid>

        <Grid>
          <Label>Команда:</Label>
          <Value>{team?.name?.replace('-', ' ') || longDash}</Value>
        </Grid>
        {!isAmateur && (
          <Grid>
            <Label>Возраст:</Label>
            <Value>
              {birthDay.age} {pluralize(birthDay.age, ['год', 'года', 'лет'])}{' '}
              <ColoredElement color="#686868">/ {birthDay.fullDate}</ColoredElement>
            </Value>
          </Grid>
        )}
        <Grid>
          <Label>Хват:</Label>
          <Value>{HAND[hand] || ''}</Value>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileBlock;

const PlayerNumber = styled.div`
  position: absolute;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 136px;
  color: #f2edde;
  right: 10px;
  z-index: 0;
`;

const PlayerName = styled.div`
  font-family: 'Bebas Neue';
  font-weight: bold;
  font-size: 28px;
  max-width: 150px;
`;

const PlayerRole = styled.div`
  color: #686868;
  font-weight: bold;
  max-width: 206px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const Label = styled.div`
  color: #686868;
  margin-right: 5px;
`;
const Value = styled.div``;

const Container = styled.div`
  padding: 16px 24px;
  padding-left: 34px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: white;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  border-radius: 6px;
  height: 100%;
`;
