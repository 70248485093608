import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import {BreadcrumbsProps} from '@mui/material/Breadcrumbs/Breadcrumbs';
import {TypographyProps} from '@mui/material/Typography/Typography';
import {Breadcrumb} from '@src/types/uikit/breadcrumbs';
import {Link, Typography} from '@ui/MUI';
import {CustomLinkProps} from '@ui/MUI/Link';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

type CustomBreadcrumbsProps = {
  breadCrumbs: Breadcrumb[];
  lastLinkProps?: TypographyProps;
  linkProps?: CustomLinkProps;
} & BreadcrumbsProps;
function Breadcrumbs({
  linkProps = {} as CustomBreadcrumbsProps['linkProps'],
  lastLinkProps = {},
  breadCrumbs = [],
  children,
  ...props
}: CustomBreadcrumbsProps) {
  return (
    <MuiBreadcrumbs aria-label="breadcrumbs" {...props}>
      {children ||
        breadCrumbs?.map((item, index) => {
          if (index === breadCrumbs.length - 1) {
            return (
              <Typography key={index} variant="body1" {...lastLinkProps}>
                {item.label || '...'}
              </Typography>
            );
          }
          return (
            <Link
              color="text.other"
              component={RouterLink}
              key={index}
              to={item.path || ''}
              underline="hover"
              variant="body1"
              {...linkProps}
            >
              {item.label}
            </Link>
          );
        })}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
