import {Team} from '@components/apps/A1TagMainWindow/viewmodels/a1tag.view-mode.types';
import {getFormattedStatistics} from '@components/apps/A1TagMainWindow/viewmodels/helpers/modal-view-model';
import {chunk} from 'lodash';

const playersPositions = ['LW', 'C', 'RW', 'LD', 'RD'];
const defaultStatsForSubstitute = {
  goals_team1: 0,
  goals_team2: 0,
  half: '1',
  second_clear: 0,
  plrs_team1: '6',
  plrs_team2: '6',
  time: '0:00:00',
  video_time_start: '0:00:00',
  video_time_end: '0:00:00',
};

export const formatStatsFieldPlayers = (team: Team) => {
  return (
    chunk(team?.players?.fieldPlayers, 5)
      ?.map((line, lineIndex) => {
        const onBenchSubstituteIndexMatrix = {
          0: 'first',
          1: 'second',
          2: 'third',
          3: 'fourth',
          4: 'fifth',
        };
        return line?.map((player, playerIndex) => {
          const statistics = getFormattedStatistics();
          return {
            ...statistics,
            id: Math.floor(Math.random() * Date.now()).toString(12),
            action_id: 11130,
            action_name: `${playersPositions?.[playerIndex]} (${onBenchSubstituteIndexMatrix?.[lineIndex]} substitute)`,
            player_name: `${player?.firstName} ${player?.lastName}`,
            player_id: player?.avangardId,
            team_name: player?.group?.name,
            jersey_player: player?.playerNumber,
            ...defaultStatsForSubstitute,
          };
        });
      })
      ?.flat() || []
  );
};

export const formatStatsGoalies = (team: Team) => {
  return (
    team?.players?.goalies?.map((player, index) => {
      const goaliesSubstituteIndexMatrix = {
        0: 'first',
        1: 'second',
        2: 'third',
      };
      return {
        id: Math.floor(Math.random() * Date.now()).toString(12),
        action_id: 11130,
        action_name: `G ${goaliesSubstituteIndexMatrix?.[index]} substitute`,
        player_name: `${player?.firstName} ${player?.lastName}`,
        player_id: player?.avangardId,
        team_name: player?.group?.name,
        jersey_player: player?.playerNumber,
        ...defaultStatsForSubstitute,
      };
    }) || []
  );
};
