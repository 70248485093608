import {grey} from '@ui/MUI/colors';
import React from 'react';
import {Scrollbar as CustomScrollbar} from 'react-scrollbars-custom';
import {ScrollbarProps} from 'react-scrollbars-custom/dist/types/Scrollbar';
import styled from 'styled-components';

type CustomScrollbarProps = {
  withoutWrapper?: boolean;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
} & ScrollbarProps;
function Scrollbar({style, width, height, withoutWrapper, ...props}: CustomScrollbarProps) {
  return <StyledScrollbar {...props} style={{...style, width, height}} withoutwrapper={withoutWrapper} />;
}

export default Scrollbar;

const StyledScrollbar = styled(CustomScrollbar)<{withoutWrapper?: boolean}>`
  .ScrollbarsCustom-Thumb {
    background: ${({withoutwrapper}) => grey[withoutwrapper ? 54 : 68]} !important;
  }
  .ScrollbarsCustom-Track {
    background: ${grey['26']} !important;
  }
`;
