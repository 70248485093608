import EstimateControlDate from '@common/DevelopmentJournal/components/EstimateControlDate';
import PlayerInfo from '@common/DevelopmentJournal/components/PlayerInfo';
import {joinEstimatesProperty} from '@common/DevelopmentJournal/helpers';
import {useModal} from '@hooks/skill';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {Add, Visibility} from '@mui/icons-material';
import {longDash, userRoles} from '@src/constants';
import {IconButton, Stack, Tooltip} from '@ui/MUI';
import React from 'react';
import {useHistory} from 'react-router-dom';

const TableActions = ({row}) => {
  const {open} = useModal('create');
  const history = useHistory();
  const create = () => {
    open({
      student: {
        id: row.id,
        firstName: row.firstName,
        lastName: row.lastName,
      },
    });
  };

  const view = () => {
    history.push(`/development_journal/player/${row.id}`);
  };
  const isPsycho = useRoleAccess([userRoles.psycho]);
  const canView = isPsycho && row.estimates.length > 0;
  const canCreate = row.countOfPlayersEstimates < 2;
  const canCreateAccess = useRoleAccess([userRoles.admin, userRoles.teacher]);

  const viewToolTipTitle = canView
    ? 'Просмотреть'
    : !isPsycho
      ? 'Эти данные доступны только психологу'
      : 'Нет данных для просмотра';
  const createToolTipTitle = canCreate ? 'Добавить' : 'Максимальное количество навыков – 2';

  return (
    <Stack direction="row" justifyContent="space-between">
      <Tooltip title={viewToolTipTitle}>
        <div>
          <IconButton color="primary" disabled={!canView} onClick={view}>
            <Visibility fontSize="inherit" />
          </IconButton>
        </div>
      </Tooltip>
      {canCreateAccess && (
        <Tooltip title={createToolTipTitle}>
          <div>
            <IconButton color="primary" disabled={!canCreate} onClick={create}>
              <Add fontSize="inherit" />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Stack>
  );
};

export const columns = [
  {
    header: 'Игрок',
    accessorFn: (row) => row,
    headerStyle: {width: 300, maxWidth: 300},
    cellStyle: {width: 300, maxWidth: 300},
    cell: (info) => {
      const value = info.getValue();
      return (
        <PlayerInfo
          avatar={value?.avatar ?? ''}
          firstName={value.firstName}
          lastName={value.lastName}
          playerNumber={value.playerNumber}
        />
      );
    },
  },
  {
    header: 'Команда',
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 150},
    accessorFn: (row) => row?.group?.name || longDash,
  },
  {
    header: 'Улучшаемый навык',
    accessorFn: (row) => (row.estimates.length ? joinEstimatesProperty(row.estimates, 'skill.name') : 'Не выбран'),
  },
  {
    header: 'Дата контроля',
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 150},
    accessorFn: (row) => row.estimates,
    cell: (info) => {
      const value = info.getValue();
      if (!value.length) {
        return longDash;
      }

      const dates = value.map((estimate) => estimate.controlDate);
      return dates.map((date, index) => <EstimateControlDate date={date} key={index} />);
    },
  },
  {
    header: 'Специалисты',
    accessorFn: (row) => (row.estimates.length ? joinEstimatesProperty(row.estimates, 'specialists') : longDash),
  },
  {
    header: 'Действия',
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 150},
    accessorFn: (row) => row,
    cell: (info) => {
      const value = info.getValue();
      return <TableActions row={value} />;
    },
  },
];
