import TreeColumn from '@common/Statistics/PlayOff/components/TreeColumn';
import {Stages} from '@common/Statistics/PlayOff/types';
import {ConsolationStages, StagesEnum} from '@src/types/tournament';
import React, {memo} from 'react';

function DesktopViewStages({
  stages = [],
  isLoading,
  isConsolationMatches = false,
}: {
  stages: Stages;
  stagesArr: StagesEnum[] | ConsolationStages[];
  isLoading?: boolean;
  isConsolationMatches?: boolean;
}) {
  return isLoading
    ? stages.map((item, index) => (
        <TreeColumn
          isLoading
          cellsCount={item.cellsCount}
          columnIndex={index}
          games={[]}
          isConsolationMatches={isConsolationMatches}
          key={index}
          stage={item.stage}
        />
      ))
    : stages.map((item, index, arr) => (
        <TreeColumn
          cellsCount={item.cellsCount}
          columnIndex={index}
          columnsCount={arr?.length}
          games={item.games}
          isConsolationMatches={isConsolationMatches}
          key={item.stage + index}
          stage={item.stage}
        />
      ));
}

export default memo(DesktopViewStages);
