import avatar from '@assets/img/man.svg';
import {TeacherSubroles} from '@src/types';

export const PARSE_APP_ID = process.env.REACT_APP_PARSE_APP_ID;
export const PARSE_JS_KEY = process.env.REACT_APP_PARSE_JS_KEY;
export const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const FACELESS_AVATAR = avatar;

export const UPLOAD_DO_ENDPOINT_URL = process.env.REACT_APP_UPLOAD_DO_ENDPOINT_URL;
export const UPLOAD_DO_ENDPOINT_URL_V2 = process.env.REACT_APP_UPLOAD_DO_ENDPOINT_URL_V2;

export const TEACHERS_ROLES_OPTIONS = [
  {
    label: 'Главный тренер',
    value: TeacherSubroles.MAIN,
  },
  {
    label: 'Тренер',
    value: TeacherSubroles.DEFAULT,
  },
  {
    label: 'Тренер защитников',
    value: TeacherSubroles.DEFENSEMENS_TRAINER,
  },
  {
    label: 'Тренер нападающих',
    value: TeacherSubroles.WINGERS_TRAINER,
  },
  {
    label: 'Тренер вратарей',
    value: TeacherSubroles.GOALTENDER,
  },
  {
    label: 'Тренер ОФП',
    value: TeacherSubroles.PHYSICAL,
  },
];
export const TYPE_OF_TESTS = [
  {label: 'ОФП', value: 'ofp'},
  {label: 'СФП', value: 'sfp'},
];
export const ESTIMATION_STAGES = [
  {
    label: 'Этап №1',
    value: '1',
  },
  {
    label: 'Этап №2',
    value: '2',
  },
  {
    label: 'Этап №3',
    value: '3',
  },
];

export const ESTIMATION_STAGES_REPORT = [
  {
    label: 'Этап №1',
    value: '1',
  },
  {
    label: 'Этап №2',
    value: '2',
  },
  {
    label: 'Этап №3',
    value: '3',
  },
];

export const NOTIFICATION_TYPES = {
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
};
export const seasonRange = {
  start: '01.05',
  end: '31.05',
};

export const injuryTypes = [
  {
    label: 'Верхняя часть тела',
    value: 'upper_body',
  },
  {
    label: 'Нижняя часть тела',
    value: 'lower_body',
  },
];

export const injuryPlaces = [
  {
    label: 'На игре',
    value: 'in_game',
  },
  {
    label: 'На тренировке на льду',
    value: 'in_ice_training',
  },
  {
    label: 'На тренировки в зале',
    value: 'in_room_training',
  },
  {
    label: 'Вне академии',
    value: 'outside_academy',
  },
];
