import {useSeasons} from '@components/modules/season/queries/seasons.query';
import date from '@helpers/date';
import {useSignals} from '@preact/signals-react/runtime';
import {Autocomplete, Box, RangePicker, Stack} from '@ui/MUI';
import {Moment} from 'moment';
import React, {useEffect, useRef} from 'react';

import {filtersViewModal} from '../modals/filters.view-modal';

export const Filters = () => {
  useSignals();
  const isInited = useRef(false);

  const seasonsQuery = useSeasons();
  const seasons = seasonsQuery.data ?? [];
  const currentSeason = seasons.at(0);
  const season = seasons.find((s) => s.id === filtersViewModal.seasonId);

  useEffect(() => {
    if (currentSeason?.id && !isInited.current) {
      filtersViewModal.updateSeasonId(currentSeason?.id ?? null);
      isInited.current = true;
    }

    return () => {
      isInited.current = false;
    };
  }, [currentSeason?.id, isInited.current]);

  return (
    <Stack alignItems="center" direction="row" px={4} spacing={4}>
      <Box minWidth={132}>
        <Autocomplete
          label="Сезоны"
          limitTags={1}
          multiple={false}
          options={seasons}
          value={season}
          isLoading={seasonsQuery.isLoading}
          onChange={(_, value) => filtersViewModal.updateSeasonId(value?.id ?? null)}
        />
      </Box>
      <RangePicker
        endLabel="До"
        startLabel="От"
        value={filtersViewModal.dateRange}
        variant="standard"
        onChange={(value) => {
          filtersViewModal.updateDateRange(value as Moment[]);
        }}
      />
    </Stack>
  );
};
