import ChildrenField from '@common/ChildrenField';
import {
  useMutateCreateProspectSkillTypes,
  useMutateDeleteProspectSkillTypes,
  useProspectSkillTypes,
} from '@components/modules/prospects/queries/prospect-skill.query';
import {Add, DeleteRounded} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useSignals} from '@preact/signals-react/runtime';
import {CreateSkillTypeDto} from '@src/types/generated';
import {Box, Button, Divider, Stack, TextField} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import React, {useEffect, useRef} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {useParams} from 'react-router';

import {skillAnalyticsModel} from '../models/skill-analytics.model';

export interface FormData {
  skills: {name: string; description?: string; id?: string}[];
}
export const SkillDrawer = () => {
  useSignals();
  const isClose = useRef(true);
  const {isOpen} = skillAnalyticsModel.modal;
  const {playerId} = useParams<{teamId: string; playerId: string}>();

  const {data: skillTypes} = useProspectSkillTypes({
    isConstant: false,
  });

  const {mutateAsync: createSkillTypes, isLoading: isCreateLoading} = useMutateCreateProspectSkillTypes(playerId);
  const {mutateAsync: deleteSkillTypes, isLoading: isDeleteLoading} = useMutateDeleteProspectSkillTypes();

  const form = useForm<FormData>({
    defaultValues: {
      skills: [{name: '', description: ''}],
    },
  });
  const {fields, append, remove} = useFieldArray({
    control: form.control,
    name: 'skills',
    keyName: '_id',
  });

  const createSkill = () => {
    append({name: ''});
  };

  const removeSkill = async (index: number, id?: string) => {
    if (id) {
      await deleteSkillTypes(id);
      remove(index);
      return;
    }

    remove(index);
  };

  const handleSave = async (data: FormData) => {
    await createSkillTypes({types: data.skills as CreateSkillTypeDto[]});
    skillAnalyticsModel.modal.close();
  };

  const handleClose = () => {
    form.reset();
    skillAnalyticsModel.modal.close();
    isClose.current = true;
  };

  useEffect(() => {
    if (isOpen && skillTypes.length) {
      form.setValue('skills', skillTypes ?? []);
      return;
    }
  }, [isOpen]);

  return (
    <FormProvider {...form}>
      <Drawer
        buttons={
          <Stack direction="row" justifyContent="space-between">
            <Button
              color="primary"
              disabled={!form.formState.isValid || isCreateLoading || isDeleteLoading}
              size="small"
              variant="contained"
              onClick={form.handleSubmit(handleSave)}
            >
              Сохранить
            </Button>
            <Button size="small" variant="contained" onClick={handleClose}>
              Отменить
            </Button>
          </Stack>
        }
        open={isOpen}
        title="Список навыков"
        onClose={handleClose}
      >
        <Stack mt={5} spacing={5}>
          <Box>
            <Button
              color="primary"
              disabled={!form.formState.isValid}
              size="small"
              startIcon={<Add />}
              variant="text"
              onClick={createSkill}
            >
              Добавить новый навый
            </Button>
          </Box>
          <Divider />
          <Stack spacing={7}>
            {fields.map((item, index) => {
              return (
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  key={item._id}
                  spacing={4}
                >
                  <Stack spacing={3} width="100%">
                    <ChildrenField isRequired name={`skills.${index}.name`}>
                      <TextField label="Название" />
                    </ChildrenField>
                    <ChildrenField isRequired name={`skills.${index}.description`}>
                      <TextField label="Описание" />
                    </ChildrenField>
                  </Stack>
                  <IconButton onClick={() => removeSkill(index, item.id)}>
                    <DeleteRounded />
                  </IconButton>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Drawer>
    </FormProvider>
  );
};
