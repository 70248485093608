import ReactTableComponent from '@common/ReactTableComponent';
import date from '@helpers/date';
import {grey} from '@ui/MUI/colors';
import TablePaginationDefault from '@ui/MUI/TablePagination';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {POTENTIAL_ASSOCIATIONS, ROLE_MODEL_ASSOCIATIONS} from '../../../../constants';
import {getRoleLabel} from '../../../../helpers/roles';
import {
  cleanCurrentGroupReport,
  cleanCurrentGroupReports,
  createGroupNewReport,
  deleteCurrentGroupReport,
  editCurrentGroupReport,
  getCurrentGroupReport,
  getCurrentGroupReports,
} from '../../../../redux/actions/ScoutReportActions';
import Table from '../../../groups/components/group/table';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {NoData, ShowButton, Wrapper} from '../CommonScoutReport';
import GroupReportCreateModal from '../GroupReportCreateModal';
import GroupReportViewModal from '../GroupReportViewModal';
import {AuthorWrapper} from './GroupReportList';

function GroupReportList({isOpen, setIsOpen, groupId, perView = 8}) {
  const dispatch = useDispatch();
  const {reports} = useSelector(({scoutReport}) => ({reports: scoutReport.groupReports}));
  const {report} = useSelector(({scoutReport}) => ({report: scoutReport.groupReport}));
  const [page, setPage] = useState(1);
  const [isViewModalOpen, setIsViewModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (groupId) {
      getReports();
    }
    return () => {
      dispatch(cleanCurrentGroupReports());
    };
  }, [groupId, page]);

  function getReports() {
    dispatch(getCurrentGroupReports({groupId, limit: perView, page}));
  }

  const columns = [
    {
      accessorKey: 'date',
      header: 'Дата создания',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
    },
    {
      accessorKey: 'author',
      header: 'Автор',
      headerStyle: {
        boxShadow: 'none !important',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
      },
      cell: ({getValue}) => (
        <AuthorWrapper>
          <span className="authorWrapper__name">{`${getValue()?.firstName} ${getValue()?.lastName}`}</span>
          <span className="authorWrapper__role">{getRoleLabel(getValue()?.userRole, getValue()?.teacherRole)}</span>
        </AuthorWrapper>
      ),
    },
    {
      id: 'action',
      accessorKey: 'actions',
      header: 'Действиe',
      headerStyle: {
        boxShadow: 'none !important',
        textAlign: 'right',
      },
      cellStyle: {
        boxShadow: 'none !important',
        borderTop: `1px solid ${grey[12]}`,
        textAlign: 'right',
      },
      cell: ({getValue}) => {
        return (
          <ShowButton
            onClick={() => {
              dispatch(getCurrentGroupReport(getValue().id));
              setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right});
            }}
          >
            Смотреть
          </ShowButton>
        );
      },
    },
  ];

  return (
    <>
      <Wrapper isLoading={reports.loading} padding="0 24px">
        {reports.loading ? (
          <LocalPreloader visible={true} />
        ) : reports.data?.length ? (
          <Table>
            <ReactTableComponent
              columns={columns}
              data={reports.data?.map(({createdAt, author, potential, roleModel, id}) => {
                return {
                  id,
                  date: date(createdAt).format('DD.MM.YYYY'),
                  author,
                  potential: POTENTIAL_ASSOCIATIONS[potential?.toUpperCase()] || '-',
                  roleModel: ROLE_MODEL_ASSOCIATIONS[roleModel?.toUpperCase()] || '-',
                  actions: id,
                };
              })}
            />
            {reports?.total > perView && (
              <TablePaginationDefault
                count={reports?.total}
                page={page - 1}
                rowsPerPage={perView}
                rowsPerPageOptions={[]}
                onPageChange={(event, page) => setPage(page + 1)}
              />
            )}
          </Table>
        ) : (
          <NoData>Отчеты отсутствуют</NoData>
        )}
      </Wrapper>
      <GroupReportCreateModal
        group={reports?.group}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onCreate={(data, callback) => dispatch(createGroupNewReport(data, getReports, callback))}
      />
      <GroupReportViewModal
        isOpen={isViewModalOpen}
        report={report}
        setIsOpen={() => setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right})}
        onClose={() => {
          setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right});
          dispatch(cleanCurrentGroupReport());
        }}
        onDelete={(reportId) =>
          dispatch(
            deleteCurrentGroupReport(reportId, () => {
              reports.data?.length === 1 && Number(page) > 1 ? setPage(page - 1) : getReports();
            }),
          )
        }
        onEdit={(reportId, data) => dispatch(editCurrentGroupReport(reportId, data))}
      />
    </>
  );
}

GroupReportList.propTypes = {
  isOpen: PropTypes.shape({
    top: PropTypes.bool,
    left: PropTypes.bool,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
  }),
  setIsOpen: PropTypes.func,
  groupId: PropTypes.string.isRequired,
};

export default GroupReportList;
