import * as locales from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {black, primary, secondary, white} from '@ui/MUI/colorsA1Tag';

const themeA1TagOptions = {
  spacing: [4, 8, 12, 16, 24, 32, 40, 48, 64, 80, 96],
  breakpoints: {
    values: {
      xxs: 0,
      xs: 576,
      sm: 768,
      md: 1024,
      smallLaptop: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {...primary, contrastText: white.main},
    secondary: {...secondary},
    secondaryInverse: {...secondary},
    text: {...black},
  },
  borderRadiuses: [4, 6, 8, 12, 16],
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          borderRadius: theme.borderRadiuses[0],
          fontFamily: 'Inter',
          ...(ownerState.size === 'large' && {
            padding: theme.spacing(1, 4),
            height: 42,
            fontSize: 16,
            lineHeight: '24px',
          }),
          ...(ownerState.size === 'medium' && {
            padding: theme.spacing(1, 3),
            height: 36,
            fontSize: 14,
            lineHeight: '16px',
          }),
          ...(ownerState.size === 'small' && {
            padding: theme.spacing(0, 1),
            height: 28,
            fontSize: 12,
            lineHeight: '14px',
          }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: black[95],
              fontWeight: 600,
              color: black[20],
              '&:focus': {
                backgroundColor: primary.dark,
                color: white.main,
              },
              '&:hover': {
                backgroundColor: primary.main,
                color: white.main,
              },
              '&:disabled': {
                backgroundColor: black[60],
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: black[25],
              fontWeight: 600,
              color: black[95],
              '&:focus': {
                backgroundColor: black[20],
              },
              '&:hover': {
                backgroundColor: black[75],
                color: black[10],
              },
              '&:disabled': {
                backgroundColor: black[60],
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: white.main,
              '&:focus': {
                color: primary.main,
              },
              '&:hover': {
                color: primary.main,
              },
              '&:disabled': {
                backgroundColor: black[60],
              },
            }),
          ...(ownerState.icon_button_type === 'icon' && {
            minWidth: 'unset',
            boxShadow: 'none !important',
            ...(ownerState.size === 'large' && {
              width: 42,
              height: 42,
              fontSize: 21,
            }),
            ...(ownerState.size === 'medium' && {
              width: 36,
              height: 36,
              fontSize: 18,
            }),
            ...(ownerState.size === 'small' && {
              width: 28,
              height: 28,
              fontSize: 15,
            }),

            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              margin: 0,
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          color: black[60],
          '&:focus': {
            color: black[75],
          },
          '&:hover': {
            color: white.main,
          },
          '&:disabled': {
            color: black[25],
          },
          ...(ownerState.size === 'large' && {
            width: 42,
            height: 42,
            fontSize: 21,
          }),
          ...(ownerState.size === 'medium' && {
            width: 36,
            height: 36,
            fontSize: 18,
          }),
          ...(ownerState.size === 'small' && {
            width: 28,
            height: 28,
            fontSize: 15,
          }),
          ...(ownerState.variant === 'contained' && {
            'MuiIcon-root': {
              borderRadius: theme.borderRadiuses[1],
              backgroundColor: black[20],
              color: white.main,
            },
          }),
        }),
      },
      variants: [
        {
          props: {variant: 'contained'},
          style: {},
        },
        {
          props: {variant: 'outlined'},
          style: {},
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTab': {
            '&-root': {
              color: `${white.main} !important`,
              font: "600 normal 14px/16px 'Inter'",
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
            },
          },
          '.Mui-selected': {
            color: `${black[60]} !important`,
          },
        },
        indicator: {
          backgroundColor: white.main,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ownerState}) => ({
          fontFamily: 'Inter',
          ...(ownerState.color === 'primary' && {
            '& .MuiSwitch-thumb': {
              backgroundColor: black[95],
            },
            '& .MuiSwitch-track': {
              backgroundColor: black[75],
            },
            '& .MuiSwitch-switchBase': {
              '&:focus': {
                '& .MuiSwitch-thumb': {
                  backgroundColor: black[95],
                },
                '& .MuiSwitch-track': {
                  backgroundColor: black[30],
                },
                '&.Mui-checked': {
                  '& + .MuiSwitch-track': {
                    backgroundColor: primary.dark,
                  },
                  '& + .MuiSwitch-thumb': {
                    backgroundColor: black[95],
                  },
                },
              },
              '&:hover': {
                '& .MuiSwitch-thumb': {
                  backgroundColor: white.main,
                },
                '& .MuiSwitch-track': {
                  backgroundColor: black[60],
                },
                '&.Mui-checked': {
                  '& + .MuiSwitch-track': {
                    backgroundColor: primary.main,
                  },
                  '& + .MuiSwitch-thumb': {
                    backgroundColor: white.main,
                  },
                },
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: black[75],
                cursor: 'not-allowed',
              },
              '&.Mui-disabled + .MuiSwitch-thumb': {
                backgroundColor: black[30],
                cursor: 'not-allowed',
              },
              '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                  backgroundColor: primary.dark,
                },
                '& + .MuiSwitch-thumb': {
                  backgroundColor: white.main,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  backgroundColor: black[75],
                  cursor: 'not-allowed',
                },
                '&.Mui-disabled + .MuiSwitch-thumb': {
                  backgroundColor: black[30],
                  cursor: 'not-allowed',
                },
              },
            },
            '& + .MuiFormControlLabel-label.Mui-disabled': {
              color: white.main,
            },
          }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.size === 'small' && {
            '& .MuiInputBase-sizeSmall': {
              paddingTop: '0px !important',
              paddingBottom: '0px !important',
              '& .MuiIconButton-root': {
                width: 24,
                height: 24,
              },
            },
          }),
          '& fieldset': {
            borderColor: black[30],
          },
          '& .MuiInputBase-root': {
            color: black[60],
          },
          '&:focus': {
            '& fieldset': {
              borderColor: white.main,
            },
          },
          '&.MuiAutocomplete-root.Mui-focused': {
            '& fieldset': {
              borderColor: white.main,
            },
            '& .MuiFormLabel-root': {
              color: white.main,
            },
          },
          '&:hover': {
            '& fieldset': {
              borderColor: black[75],
            },
          },
          '&.MuiAutocomplete-root': {
            '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
              '-webkit-text-fill-color': 'inherit !important',
            },
          },
          '&.MuiAutocomplete-root.Mui-disabled': {
            '& fieldset': {
              borderColor: black[20],
            },
            '& .MuiInputBase-root': {
              color: black[60],
            },
            '& .MuiFormLabel-root': {
              color: white.main,
            },
            cursor: 'not-allowed',
          },
          '&:disabled': {
            '& fieldset': {
              borderColor: black[20],
              '-webkit-text-fill-color': 'inherit',
            },
            cursor: 'not-allowed',
          },
        }),
        paper: {
          marginTop: 8,
          backgroundColor: black[20],
          '& li:nth-child(even)': {
            backgroundColor: black[30],
          },
          '& li:nth-child(odd)': {
            backgroundColor: black[20],
          },
          '& .MuiAutocomplete-noOptions': {
            color: white.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.color === 'primary' && {
            color: black[60],
            '.MuiSvgIcon-root': {
              '&:first-child': {borderRadius: '50%'},
            },
            '&:hover': {
              color: black[95],
            },
            '&:focus': {
              color: black[95],
              '.MuiSvgIcon-root': {
                '&:first-child': {
                  backgroundColor: '#F1F1F31A',
                  color: '#F1F1F31A',
                },
              },
              '&:disabled': {
                color: black[30],
              },
            },
            '&.Mui-checked': {
              color: black[95],
              '&:hover': {
                color: white.main,
                '.MuiSvgIcon-root': {
                  '&:last-child': {
                    color: primary.main,
                  },
                  '&:first-child': {
                    backgroundColor: white.main,
                    color: white.main,
                  },
                },
                '&:focus': {
                  color: white.main,
                  '.MuiSvgIcon-root': {
                    '&:last-child': {
                      color: white.main,
                    },
                    '&:first-child': {
                      backgroundColor: '#FFFFFF66',
                      color: '#FFFFFF66',
                    },
                  },
                },
                '&:disabled': {
                  color: black[30],
                  '.MuiSvgIcon-root': {
                    '&:last-child': {
                      color: black[15],
                    },
                    '&:first-child': {
                      backgroundColor: black[30],
                      color: black[30],
                    },
                  },
                },
              },
              '.MuiSvgIcon-root': {
                '&:last-child': {
                  color: primary.dark,
                },
                '&:first-child': {
                  backgroundColor: black[95],
                  color: black[95],
                },
              },
            },
          }),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.color === 'primary' && {
            color: black[60],
            '&:hover': {
              color: black[95],
            },
            '&:focus': {
              color: black[95],
              '&:disabled': {
                color: black[30],
              },
            },
            '&.Mui-checked': {
              color: black[95],
              '&:hover': {
                color: white.main,
                '&:focus': {
                  color: white.main,
                },
                '&:disabled': {
                  color: black[30],
                },
              },
            },
          }),
        }),
      },
    },
  },
};

export const themeConfA1Tag = createTheme(themeA1TagOptions, locales['ruRU']);
const smRes = themeConfA1Tag.breakpoints.down('sm');
themeConfA1Tag.typography = {
  ...themeConfA1Tag.typography,
  fontFamily: 'Inter, Arial, sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 13,
      lineHeight: '17px',
    },
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  overline0: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  overline1: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  overline2: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Inter',
    color: black[95],
  },
  caption: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 11,
      lineHeight: '12px',
    },
  },
  caption2: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: black[95],
    [smRes]: {
      fontSize: 11,
      lineHeight: '12px',
    },
  },
};
