import '@lourenci/react-kanban/dist/styles.css';

import date from '@helpers/date';
import React from 'react';
import styled from 'styled-components';

import {injuryTypes} from '../../../constants/config';

export default function Card(props) {
  return (
    <>
      <MedicalProfileCard
        a={props.a}
        className={!props.dragging ? 'MedicalProfileCard' : 'MedicalProfileCard_dragging'}
        dragging={props.dragging}
        onClick={() => {
          props.cardOnClick(props.a.id);
        }}
      >
        <MedicalProfileCardContent>
          <MedicalProfileCardDatesContainer>
            <MedicalProfileCardDate>{date(props.a.timestamp).format('DD.MM.YYYY')}</MedicalProfileCardDate>
            {!props.a.status !== 'recovered' ? null : (
              <MedicalProfileCardDateHealthyContainer>
                <span>-</span>
                <MedicalProfileCardDateHealthy>
                  {date(props.a.recoveryDate).format('DD.MM.YYYY')}
                </MedicalProfileCardDateHealthy>
              </MedicalProfileCardDateHealthyContainer>
            )}
          </MedicalProfileCardDatesContainer>

          <MedicalProfileCardPlayer>
            <MedicalProfileCardPlayerNumber>{props.a.number}</MedicalProfileCardPlayerNumber>
            <MedicalProfileCardPlayerInfo>
              <MedicalProfileCardPlayerName>{props.a.name}</MedicalProfileCardPlayerName>
              <MedicalProfileCardPlayerDesc>
                {props.a.crew}, {props.a.amplua}
              </MedicalProfileCardPlayerDesc>
            </MedicalProfileCardPlayerInfo>
          </MedicalProfileCardPlayer>
          <MedicalProfileCardTitle>
            {props.a.title
              ? props.a.title.length > 55
                ? `${props.a.title.substr(0, 55)}...`
                : props.a.title
              : injuryTypes[props.a.injuryType]?.label || ''}
          </MedicalProfileCardTitle>
          <MedicalProfileCardDesc>
            {props.a.description.length > 55 ? `${props.a.description.substr(0, 55)}...` : props.a.description}
          </MedicalProfileCardDesc>
        </MedicalProfileCardContent>
      </MedicalProfileCard>
    </>
  );
}

const MedicalProfileCardContent = styled.div`
  position: relative;
  padding: 12px;
  max-width: 225px;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: transform 0.33s;
  z-index: 1;
`;
const MedicalProfileCard = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
  &.MedicalProfileCard {
    &_dragging {
      ${MedicalProfileCardContent} {
        margin-top: 32px;
        transform: rotate(3deg);
      }
    }
  }
`;
const MedicalProfileCardDate = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 8px;
  box-sizing: border-box;
  background-color: #990011;
  border-radius: 12px;
  font: bold normal 14px/25px 'Proxima Nova';
  color: white;
`;
const MedicalProfileCardDatesContainer = styled.div`
  display: flex;
`;
const MedicalProfileCardDateHealthy = styled(MedicalProfileCardDate)`
  background-color: #598855;
`;
const MedicalProfileCardDateHealthyContainer = styled.div`
  position: relative;
  padding-left: 16px;
  > span {
    position: absolute;
    left: 0;
    padding-right: 4px;
    padding-left: 4px;
  }
`;
const MedicalProfileCardPlayer = styled.div`
  display: flex;
  margin: 0 -12px;
  padding: 8px 10px;
  border-bottom: 1px solid #f3f3f3;
`;
const MedicalProfileCardPlayerNumber = styled.p`
  margin: 0 2px 0 0;
  font: 600 normal 16px/18px Proxima Nova;
  color: #747474;
  letter-spacing: 0.02em;
`;
const MedicalProfileCardPlayerInfo = styled.div``;
const MedicalProfileCardPlayerName = styled(MedicalProfileCardPlayerNumber)`
  margin-right: 0;
  margin-left: 2px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #2c2c2c;
  word-break: break-word;
`;
const MedicalProfileCardPlayerDesc = styled(MedicalProfileCardPlayerNumber)`
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  color: #747474;
`;
const MedicalProfileCardTitle = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  font: bold normal 14px/1 Proxima Nova;
  color: #2c2c2c;
  word-break: break-word;
`;
const MedicalProfileCardDesc = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font: normal normal 14px/1 Proxima Nova;
  color: #747474;
  word-break: break-word;
`;
