import {signal} from '@preact/signals-react';

export const createCoordsModel = () => {
  return {
    x: signal(0),
    y: signal(0),
    x2: signal(0),
    y2: signal(0),
    eclipses: signal<{x: number; y: number; realX?: number; realY?: number}[]>([]),
    updateSecondCoords(x: number, y: number) {
      this.x2.value = x;
      this.y2.value = y;
    },
    updateCoords(x: number, y: number) {
      this.x.value = x;
      this.y.value = y;
    },
    updateEclipses(eclipses: {x: number; y: number; realX?: number; realY?: number}[]) {
      this.eclipses.value = eclipses;
    },

    // getter
    getEclipses() {
      return this.eclipses.value;
    },

    // getter
    getCoords() {
      return {
        x: this.x.value,
        y: this.y.value,
        x2: this.x2.value,
        y2: this.y2.value,
      };
    },

    cleanUp() {
      this.x.value = 0;
      this.y.value = 0;
      this.x2.value = 0;
      this.y2.value = 0;
      this.eclipses.value = [];
    },
  };
};
