import {Section} from '../accesses.type';

export const getNotCreatedSections = (clientSections: Section[], serverSections: Section[]) => {
  return clientSections.filter((section) => !serverSections.some((serverSection) => serverSection.url === section.url));
};

export const isHaveAccessToSection = (sections: Section[], sectionUrl: string) => {
  const section = sections.find((section) => section.url === sectionUrl);
  return section && section.subsections.length > 0;
};

export const isHaveAccessToSubSection = (sectionUrl: string, subsectionUrl: string, sections: Section[]) => {
  const section = sections.find((section) => section.url === sectionUrl);
  return section && section.subsections.some((subsection) => subsection.url === subsectionUrl);
};
