import date from '@helpers/date';
import {useGroupsV2, useSchoolsV2} from '@hooks/filtersV2';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {UserRoles} from '@src/types';
import {Autocomplete, Box, TextField} from '@ui/MUI';
import DatePickerDefault from '@ui/MUI/DatePicker';
import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {shallowEqual, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {injuryPlaces, injuryTypes, NOTIFICATION_TYPES} from '../../../constants/config';
import CommonHelper from '../../../helpers/CommonHelper';
import StateHelper from '../../../helpers/StateHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {TYPES_OPTIONS} from '../constants';

export default function GeneralInfo({errors = {}, control = {}, setValue, ...props}) {
  const {userRole, allGroups, allPlayers, medicalProfileForm, generalInformation} = useSelector((state) => {
    let currentUser = StateHelper.getCurrentUser(state);
    let userRole = currentUser?.userRole;
    const allGroups = state.groups.groupsMap.toArray();
    const allPlayers = state.users.usersMap.toArray().filter((u) => u.userRole === 'student');

    let medicalProfileForm =
      state.medicalProfile.medicalProfileForm && Object.keys(state.medicalProfile.medicalProfileForm).length
        ? state.medicalProfile.medicalProfileForm
        : {
            training: [],
            consultations: [],
            nutrition: [],
          };
    const generalInformation = medicalProfileForm ? medicalProfileForm : {};
    return {
      userRole: userRole,
      loading: state.users.loading,
      usersMap: state.users.usersMap,
      allGroups: allGroups,
      allPlayers: allPlayers,
      medicalProfileForm,
      generalInformation,
      state,
    };
  }, shallowEqual);

  const schools = useSchoolsV2({allSchoolsPermissionRoles: [UserRoles.DOCTOR]});

  const selectedSchool = () => {
    if (medicalProfileForm.school) {
      const schoolOption = schools?.options?.find((school) => school.value === medicalProfileForm.school);
      return schoolOption;
    } else {
      return '';
    }
  };

  const groups = useGroupsV2({schoolIds: selectedSchool()?.value ? [selectedSchool()?.value] : []});

  const defaultSelectedGroup = () => {
    if (medicalProfileForm.group) {
      const group = allGroups.find((group) => group.id === medicalProfileForm.group);
      const groupOption = CommonHelper.getDropDownItemsByNamedList([group]);

      return groupOption[0];
    } else {
      return '';
    }
  };

  const [selectedGroup, setSelectedGroup] = useState(defaultSelectedGroup());

  const defaultSelectedPlayer = () => {
    if (medicalProfileForm?.playerId) {
      const player = allPlayers?.find((player) => player?.id === medicalProfileForm?.playerId);
      const playerOption = {
        label: player?.lastName + ' ' + player?.firstName,
        value: player?.id,
      };

      return playerOption;
    } else {
      return '';
    }
  };

  const [selectedPlayer, setSelectedPlayer] = useState(defaultSelectedPlayer());

  const selectedDate = () => {
    const newDateUT = new Date().getTime();
    if (medicalProfileForm.date) {
      return new Date(medicalProfileForm.date);
    } else {
      handleGeneralInfoChange('date', newDateUT);
      return new Date();
    }
  };

  const selectedType = () => {
    if (medicalProfileForm.type) {
      return TYPES_OPTIONS.find((type) => type.value === medicalProfileForm.type);
    } else {
      return '';
    }
  };

  const selectedStage = () => {
    if (medicalProfileForm.status) {
      const stage = medicalProfileStages.find((stage) => stage.value === medicalProfileForm.status);
      return stage;
    } else {
      return '';
    }
  };

  const selectedInjuryType = () => {
    if (medicalProfileForm.injuryType) {
      const injuryType = injuryTypes.find((ijt) => ijt.value === medicalProfileForm.injuryType);
      return injuryType;
    }
    return '';
  };

  const selectedInjuryPlace = () => {
    if (medicalProfileForm.injuryPlace) {
      const injuryPlace = injuryPlaces.find((ijp) => ijp.value === medicalProfileForm.injuryPlace);
      return injuryPlace;
    }
    return '';
  };

  const selectedRecoveryDate = () => {
    const newDateUT = new Date().getTime();
    if (medicalProfileForm.recoveryDate) {
      return new Date(medicalProfileForm.recoveryDate);
    } else {
      handleGeneralInfoChange('recoveryDate', newDateUT);
      return new Date();
    }
  };

  let [description, setDescription] = useState(medicalProfileForm.description ? medicalProfileForm.description : '');

  const dispatch = useStoreDispatch();

  let [medicalProfileStages, setMedicalProfileStages] = useState([]);

  const [studentsOptions, setStudentsOptions] = useState([]);

  useEffect(() => {
    if (medicalProfileForm?.group) {
      StateHelper.getStudentsByTeamId(medicalProfileForm?.group).then((result) => {
        const options = result
          .map((player) => {
            return {
              value: player.id,
              label: player.name,
            };
          })
          .sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        setStudentsOptions(options);
      });
    }
  }, [medicalProfileForm?.group]);

  useEffect(() => {
    MedicalProfileAPI.getMedicalProfileStages()
      .then((data) => {
        setMedicalProfileStages(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке стадий');
      });
  }, []);

  useEffect(() => {
    setValue('school', generalInformation.school);
    setValue('group', generalInformation.group);
    setValue('description', generalInformation.description);
    setValue('playerId', generalInformation.playerId);
    setValue('diseaseName', generalInformation.diseaseName);
    setValue('type', generalInformation.type);
    setValue('status', generalInformation.status);
  }, [generalInformation]);

  const handleGeneralInfoChange = (name, value) => {
    const currentGeneralInfo = medicalProfileForm ? medicalProfileForm : {};

    dispatch(handleChangeForm({...currentGeneralInfo, [name]: value}));
  };
  const handleMedicalProfileChange = (name, value) => {
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(handleChangeForm({...currentMedicalProfileForm, [name]: value}));
  };
  const handleMedicalProfileFormChange = (name, value) => {
    const currentMedicalProfileForm = medicalProfileForm ? medicalProfileForm : {};
    dispatch(handleChangeForm({...currentMedicalProfileForm, [name]: value}));
  };

  const isInjury = medicalProfileForm?.type === 'injury';

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (userRole === 'doctor' && !props.addCard) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [userRole]);

  return (
    <Box py={3} sx={{pb: {xxs: '120px', sm: 3}}}>
      <Title>Информация</Title>
      <FieldsContainer>
        <Fields>
          <FieldsWrapper>
            <Field>
              <Controller
                control={control}
                name="school"
                render={() => {
                  return (
                    <Autocomplete
                      disabled={!canEdit}
                      error={errors.school}
                      helperText={errors?.school && 'Обязательное поле для заполнения'}
                      label="Школа"
                      multiple={false}
                      options={schools?.options}
                      placeholder="Выберите школу"
                      value={selectedSchool()}
                      onChange={(_, school) => {
                        handleMedicalProfileFormChange('group', '');
                        setSelectedGroup(null);
                        handleMedicalProfileFormChange('playerId', null);
                        setSelectedPlayer(null);
                        handleGeneralInfoChange('school', school?.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Controller
                control={control}
                name="group"
                render={() => {
                  return (
                    <Autocomplete
                      disabled={!canEdit}
                      error={errors.group}
                      helperText={errors.group && 'Обязательное поле для заполнения'}
                      label="Команда"
                      multiple={false}
                      options={groups?.options}
                      placeholder="Выберите команду"
                      value={selectedGroup}
                      onChange={(_, group) => {
                        setSelectedPlayer(null);
                        handleMedicalProfileFormChange('playerId', null);
                        setSelectedGroup(group);
                        handleGeneralInfoChange('group', group?.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Controller
                control={control}
                name="playerId"
                render={() => {
                  return (
                    <Autocomplete
                      disabled={!canEdit}
                      error={errors?.playerId}
                      helperText={errors?.playerId && 'Обязательное поле для заполнения'}
                      label="Игрок"
                      multiple={false}
                      options={studentsOptions}
                      placeholder="Выберите игрока"
                      value={selectedPlayer}
                      onChange={(_, student) => {
                        handleMedicalProfileFormChange('playerId', student?.value);
                        setSelectedPlayer(student);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Label>Дата</Label>
              <DatePickerDefault
                disabled={!UserRoles.DOCTOR}
                inputFormat="DD.MM.yyyy"
                maxDate={date()}
                value={selectedDate()}
                onChange={(startDate) => {
                  const startDate_UT = new Date(startDate).getTime();
                  handleGeneralInfoChange('date', startDate_UT);
                }}
              />
            </Field>
          </FieldsWrapper>
        </Fields>
        <Fields>
          <FieldsWrapper>
            <Field>
              <Controller
                control={control}
                name="type"
                render={() => {
                  return (
                    <Autocomplete
                      disabled={!canEdit}
                      error={errors.type}
                      helperText={errors?.type && 'Обязательное поле для заполнения'}
                      label="Тип"
                      multiple={false}
                      options={TYPES_OPTIONS}
                      placeholder="Выберите тип"
                      value={selectedType()}
                      onChange={(_, type) => {
                        handleGeneralInfoChange('type', type?.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Controller
                control={control}
                name="status"
                render={() => {
                  return (
                    <Autocomplete
                      errors={errors.status}
                      helperText={errors?.status && 'Обязательное поле для заполнения'}
                      label="Стадия"
                      multiple={false}
                      options={medicalProfileStages}
                      placeholder="Выберите стадию"
                      value={selectedStage()}
                      onChange={(_, stage) => {
                        handleMedicalProfileChange('status', stage?.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            {isInjury && (
              <>
                <Field>
                  <Controller
                    control={control}
                    name="injuryType"
                    render={() => {
                      return (
                        <Autocomplete
                          disabled={!canEdit}
                          error={errors.status}
                          helperText={errors?.status && 'Обязательное поле для заполнения'}
                          label="Вид травмы"
                          multiple={false}
                          options={injuryTypes}
                          placeholder="Выберите вид травмы"
                          value={selectedInjuryType()}
                          onChange={(_, stage) => {
                            handleMedicalProfileChange('injuryType', stage?.value);
                          }}
                        />
                      );
                    }}
                    rules={{required: false}}
                    shouldUnregister={false}
                  />
                </Field>
                <Field>
                  <Controller
                    control={control}
                    name="injuryPlace"
                    render={() => {
                      return (
                        <Autocomplete
                          disabled={!canEdit}
                          error={errors.status}
                          helperText={errors?.status && 'Обязательное поле для заполнения'}
                          label="Где игрок получил травму"
                          multiple={false}
                          options={injuryPlaces}
                          placeholder="Выберите место, где игрок получил травму"
                          value={selectedInjuryPlace()}
                          onChange={(_, stage) => {
                            handleMedicalProfileChange('injuryPlace', stage.value);
                          }}
                        />
                      );
                    }}
                    rules={{required: true}}
                    shouldUnregister={false}
                  />
                </Field>
              </>
            )}
            {selectedStage() && selectedStage()?.value === 'recovered' ? (
              <Field>
                <Label>Примерная дата восстановления</Label>
                <DatePickerDefault
                  disabled={!UserRoles.DOCTOR}
                  inputFormat="DD.MM.yyyy"
                  value={selectedRecoveryDate()}
                  onChange={(recoveryDate) => {
                    const recoveryDate_UT = new Date(recoveryDate).getTime();
                    handleGeneralInfoChange('recoveryDate', recoveryDate_UT);
                  }}
                />
              </Field>
            ) : null}
            <Field>
              <Label>Дополнительное описание</Label>
              <Controller
                control={control}
                name={'description'}
                render={() => {
                  return (
                    <TextField
                      multiline
                      disabled={!canEdit}
                      errorText="Обязательное поле для заполнения"
                      isInvalid={errors.description}
                      placeholder="Травма получена в результате удара об борт во время силового приема."
                      sx={{mt: 2}}
                      value={description}
                      onChange={(a) => {
                        setDescription(a.target.value);
                        handleGeneralInfoChange('description', a.target.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
          </FieldsWrapper>
        </Fields>
      </FieldsContainer>
    </Box>
  );
}

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Fields = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  :first-of-type {
    padding-right: 60px;
    padding-left: 0;
    @media screen and (max-width: 991px) {
      padding-right: 30px;
      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }
  }

  :last-of-type {
    padding-right: 0;
    padding-left: 60px;
    @media screen and (max-width: 991px) {
      padding-left: 30px;
      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }
  }
`;
const FieldsWrapper = styled.div``;
const Field = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
`;
const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
