import {signal} from '@preact/signals-react';

import {A1TableColumnDef} from '../a1Table.types';

type TableItem = A1TableColumnDef & {isSaved?: boolean};

export const a1TableViewModel = {
  _selectedRows: signal<TableItem[]>([]),

  get selectedRows() {
    return this._selectedRows.value;
  },

  appendSelectedRow(row: A1TableColumnDef) {
    this._selectedRows.value = [...this.selectedRows, row];
  },

  removeSelectedRow(id: string) {
    this._selectedRows.value = this.selectedRows.filter((selectedRow) => selectedRow.id !== id);
  },

  isSelected(id: string) {
    return this.selectedRows.find((selectedRow) => selectedRow.id === id);
  },

  removeSelectedRowsByIds(ids: string[]) {
    this._selectedRows.value = this.selectedRows.filter((selectedRow) => !ids.includes(selectedRow.id));
  },

  updateRow(id: string, row: Partial<TableItem>) {
    const updatedRows = this.selectedRows.map((item) => (item.id === id ? {...item, ...row} : item));
    this._selectedRows.value = updatedRows;
  },

  updateRows(rows: Partial<(TableItem & {id: string})[]>) {
    this._selectedRows.value = this.selectedRows.map((row) => {
      const newRow = rows.find((row) => row.id === row.id);
      return newRow || row;
    });
  },
};
